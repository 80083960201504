import "core-js/modules/es.array.push.js";
import { validationMixin } from "vuelidate";
import loginComputedMixin from "@/components/login/login-computed";
import { required, email } from "vuelidate/lib/validators";
import { routeLogin } from "@/utils/endpoints";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import LoginRegistrationWrapper from "@/components/layouts/login-registration-wrapper.vue";
import resendCountdownMixin from "@/mixins/resend-countdown-mixin";
export default {
  mixins: [validationMixin, loginComputedMixin, resendCountdownMixin],
  validations: {
    userName: {
      required: required,
      email: email
    }
  },
  components: {
    Button: Button,
    LoginRegistrationWrapper: LoginRegistrationWrapper
  },
  data: function data() {
    return {
      userName: "",
      routeLogin: routeLogin
    };
  },
  computed: {
    userNameErrors: function userNameErrors() {
      var errors = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.required && errors.push("Email address is required.");
      !this.$v.userName.email && errors.push("Please enter the correct email address");
      return errors;
    },
    isFieldsInvalid: {
      get: function get() {
        return this.$v.$invalid;
      }
    },
    sentCurrentRequest: {
      get: function get() {
        return this.$store.getters.sentCurrentRequest;
      },
      set: function set(value) {
        this.$store.state.sentCurrentRequest = value;
      }
    }
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    this.resetResendStates();
    next();
  },
  methods: {
    submit: function submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.proceedToForgetPassword();
    },
    proceedToForgetPassword: function proceedToForgetPassword() {
      var _this = this;
      this.resendLoading = true;
      this.disableResendButton();
      var formData = new FormData();
      formData.append("username", this.userName);
      this.$store.dispatch("sendForgetPasswordRequest", formData).then(function (success) {
        if (success) {
          _this.checkCurrentRequestAndProceed();
        } else {
          _this.enableResendButton();
        }
        _this.resendLoading = false;
      });
    }
  }
};