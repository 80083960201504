//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getColorPreset } from '@/utils/helpers';
export default {
  props: ["fillColor", "initials"],
  methods: {
    getFillColor: function getFillColor() {
      return getColorPreset(this.initials);
    }
  }
};