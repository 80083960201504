import { VCard } from 'vuetify/lib/components/VCard';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogDisconnectIntegration, "max-width": "460" },
        on: {
          cancel: function ($event) {
            _vm.dialogDisconnectIntegration = false
          },
          proceed: function ($event) {
            return _vm.disconnectIntegration(_vm.currentPageType)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("integrationDisconnectWarningTitle")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "integrationDisconnectWarningDescription1"
                      )
                    ) +
                    " " +
                    _vm._s(_vm.disconnectTypeText) +
                    " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "integrationDisconnectWarningDescription2"
                      )
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "pt-14 px-9" }, [
        _c(
          "div",
          {
            staticClass: "pa-3",
            staticStyle: { background: "#fef4ee", color: "#f79351" },
          },
          [
            _c("span", { staticClass: "status-icon px-2" }, [
              _c("img", {
                attrs: { src: require("../../assets/eye-org.svg") },
              }),
            ]),
            _c(
              "span",
              [
                _vm._v(
                  _vm._s(_vm.$stringConstants("integrationNotifyStartText")) +
                    " "
                ),
                _c(
                  "router-link",
                  {
                    staticStyle: { color: "#f79351", "font-weight": "400" },
                    attrs: { to: _vm.routeCustomers },
                  },
                  [_vm._v("Customers")]
                ),
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("integrationNotifyEndText")) +
                    " "
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "pt-10 px-9",
          class: [_vm.containerLeftPad, _vm.containerRightPad],
        },
        [
          _c(
            "div",
            { staticClass: "py-2" },
            [
              _c("SubHeaderPrimary", {
                attrs: { textValue: _vm.$stringConstants("invoicingText") },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pt-1 pb-6" },
            [
              _c("DescriptionPrimary", {
                attrs: {
                  textValue: _vm.$stringConstants(
                    "integrationConnectionDiscription"
                  ),
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "px-9" },
        [
          _c("integrationCard", {
            attrs: {
              integrationAccount: _vm.account,
              imageUrl: "qb-thumb.png",
              integrationServiceTitle: _vm.$stringConstants(
                "integrationQuickBooksText"
              ),
              disconnectText: _vm.$stringConstants("integrationDisconnectText"),
              discriptionText1: "Online accounting software that",
              discriptionText2: "supports your business",
              loadingIntegration: _vm.loadingIntegration,
              loadingIntegrationBuffer: _vm.loadingIntegrationBuffer,
              showDisconnectBtn:
                _vm.quickBookAccess[_vm.quickbookAccessState] ==
                _vm.$constantValues("quickbookAccessStruct")["quickbookAccess"],
              isAnyIntegration: _vm.checkIntegration,
            },
            on: {
              connectService: function ($event) {
                return _vm.integrateQuickbooks()
              },
              disconnectService: function ($event) {
                return _vm.showDialogDisconnectIntegration("quickbooks")
              },
            },
          }),
          _c("integrationCard", {
            attrs: {
              integrationAccount: _vm.account,
              imageUrl: "xero-logo.png",
              integrationServiceTitle: _vm.$stringConstants(
                "integrationXeroText"
              ),
              disconnectText: _vm.$stringConstants("integrationDisconnectText"),
              discriptionText1: "Accounting software to do",
              discriptionText2: "our to-do",
              loadingIntegration: _vm.loadingIntegration,
              loadingIntegrationBuffer: _vm.loadingIntegrationBuffer,
              showDisconnectBtn:
                _vm.xeroAccess[_vm.xeroAccessState] ==
                _vm.$constantValues("xeroAccessStruct")["xeroAccess"],
              isAnyIntegration: _vm.checkIntegration,
            },
            on: {
              connectService: function ($event) {
                return _vm.integrateXero()
              },
              disconnectService: function ($event) {
                return _vm.showDialogDisconnectIntegration("xero")
              },
            },
          }),
          _c("integrationCard", {
            attrs: {
              integrationAccount: _vm.account,
              imageUrl: "sage-logo.png",
              integrationServiceTitle: _vm.$stringConstants(
                "integrationSageText"
              ),
              disconnectText: _vm.$stringConstants("integrationDisconnectText"),
              discriptionText1: "Accounting & business management",
              discriptionText2: "software",
              loadingIntegration: _vm.loadingIntegration,
              showDisconnectBtn:
                _vm.sageAccess[_vm.sageAccessState] ==
                _vm.$constantValues("sageAccessStruct")["sageAccess"],
              isAnyIntegration: _vm.checkIntegration,
            },
            on: {
              connectService: function ($event) {
                return _vm.integrateSage()
              },
              disconnectService: function ($event) {
                return _vm.showDialogDisconnectIntegration("sage")
              },
            },
          }),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }