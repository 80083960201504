import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-8" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(VCol, { attrs: { cols: "8" } }, [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("fieldUploadText")) + " "
              ),
            ]),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    attrs: { small: "" },
                    on: { click: _vm.closeBulkUploadFieldsForm },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form-scroll-view" },
      [
        _c(
          VContainer,
          {
            staticClass: "pb-0",
            class: [_vm.containerLeftPad, _vm.containerRightPad],
          },
          [
            _vm.loginUserRole == _vm.UserRoleType.Contractor ||
            (_vm.loginUserRole == _vm.UserRoleType.Manager &&
              _vm.ownerRole == "Contractor")
              ? _c(
                  "div",
                  { staticClass: "pt-4" },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("customer")) + "*"),
                    ]),
                    _c(VAutocomplete, {
                      staticClass: "pb-2",
                      attrs: {
                        items: _vm.fieldOwnerListForBulkUpload,
                        "search-input": _vm.fieldFarmerSearchTextInternal,
                        disabled: _vm.bulkUploadInProgress,
                        loading: _vm.loadingDropdown,
                        "item-text": "firstName",
                        attach: "",
                        "item-value": "id",
                        placeholder: _vm.loadingDropdown
                          ? "Loading..."
                          : _vm.$stringConstants("selectCustomerPlaceholder"),
                        "hide-no-data": "",
                        clearable: "",
                        outlined: "",
                        dense: "",
                      },
                      on: {
                        "update:searchInput": function ($event) {
                          _vm.fieldFarmerSearchTextInternal = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.fieldFarmerSearchTextInternal = $event
                        },
                        focus: _vm.resetPagingAndGetBulkUploadOwnerList,
                        keyup: _vm.applyBulkUploadCustomersFilterSearchTimer,
                        "click:clear":
                          _vm.applyBulkUploadCustomersFilterSearchTimer,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append-item",
                            fn: function () {
                              return [
                                _c("LoadMoreItem", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.bulkUploadOwner,
                                      expression: "!bulkUploadOwner",
                                    },
                                  ],
                                  attrs: {
                                    searchText:
                                      _vm.fieldFarmerSearchTextInternal,
                                    loadedListLength:
                                      _vm.fieldOwnerListForBulkUpload.length,
                                    moduleNamespace: _vm.$stringConstants(
                                      "fieldsBulkUploadFilterCustomersPaginationModule"
                                    ),
                                  },
                                  on: {
                                    loadMoreItems:
                                      _vm.appendMoreBulkUploadCustomers,
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2885962371
                      ),
                      model: {
                        value: _vm.bulkUploadOwner,
                        callback: function ($$v) {
                          _vm.bulkUploadOwner = $$v
                        },
                        expression: "bulkUploadOwner",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.loginUserRole == _vm.UserRoleType.Farmer ||
            (_vm.loginUserRole == _vm.UserRoleType.Manager &&
              _vm.ownerRole == "Farmer")
              ? _c("div", { staticClass: "pb-4" })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "pt-0" },
              [
                _c(VFileInput, {
                  staticClass: "fd-fl-pc fd-fl-pc-2 pb-2 text-center",
                  staticStyle: { "min-width": "240px" },
                  attrs: {
                    accept: ".zip",
                    filled: "",
                    placeholder: _vm.$stringConstants(
                      "selectShapeFilePlaceholder"
                    ),
                    dense: "",
                    disabled: _vm.bulkUploadInProgress,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.selectFiles($event)
                    },
                  },
                  model: {
                    value: _vm.compressedFile,
                    callback: function ($$v) {
                      _vm.compressedFile = $$v
                    },
                    expression: "compressedFile",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "note-bg" }, [
              _c("div", { staticClass: "fw-7 note-hd" }, [_vm._v("Note:")]),
              _c("div", { staticClass: "note-desc" }, [
                _c("div", { staticClass: "pb-2" }, [
                  _vm._v(
                    "1. " +
                      _vm._s(
                        _vm.$stringConstants("fieldsUploadDisclaimerShapeFile")
                      )
                  ),
                ]),
                _c("div", { staticClass: "pb-2" }, [
                  _vm._v(
                    "2. " +
                      _vm._s(_vm.$stringConstants("fieldsUploadDisclaimerKML"))
                  ),
                ]),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("fieldsUploadFootnote")) +
                    " "
                ),
              ]),
            ]),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "mt-6",
                  staticStyle: {
                    position: "relative",
                    opacity: "0",
                    "pointer-events": "none",
                  },
                },
                [
                  _c("div", {
                    staticClass: "job-details-map",
                    staticStyle: {
                      width: "580x",
                      height: "200px",
                      "border-radius": "12px",
                    },
                    attrs: { id: "bulk-upload-map" },
                  }),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "btns-bottom-box px-8 py-4" },
          [
            _c("Button", {
              class: { disabled: _vm.bulkUploadInProgress },
              attrs: {
                variant: "primary",
                color: "primary",
                disabled:
                  _vm.bulkUploadInProgress ||
                  ((_vm.loginUserRole == _vm.UserRoleType.Contractor ||
                    (_vm.loginUserRole == _vm.UserRoleType.Manager &&
                      _vm.ownerRole == "Contractor")) &&
                    _vm.bulkUploadOwner == null) ||
                  _vm.compressedFile == null,
                loading: _vm.bulkUploadInProgress,
                textValue: "Upload",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.uploadShapeFiles($event)
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }