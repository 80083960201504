import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VBtn,
    {
      staticClass: "vbtn-mini-prim",
      class: { disabled: _vm.disabled },
      attrs: {
        outlined: "",
        color: "primary",
        elevation: "0",
        tile: "",
        width: _vm.width ? _vm.width : "",
        height: _vm.height ? _vm.height : 40,
        disabled: _vm.disabled,
        loading: _vm.loading,
      },
    },
    [
      _c("span", { staticStyle: { opacity: "1" } }, [
        _vm._v(_vm._s(_vm.textValue)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }