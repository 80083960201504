import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapMutations } from 'vuex';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'jobsCalendarModule': ['jobsCalendarData', 'jobsCalendarStartDate', 'jobsCalendarEndDate', 'jobsCalendarLoader', 'jobsUnscheduledJobsData', 'jobsUnscheduledJobsSearchText'],
  'teamJobsListModule': ['teamJobsList', 'calendarViewJobs', 'jobViewType', 'calendarViewJobEvents', 'focusMonth', 'teamJobsListFilterStartDate', 'teamJobsListFilterEndDate'],
  'root': ['enumListProductUnits', 'noData', 'filtersApplied', 'listCount', 'enumActiveStatus', 'totalRowCount', 'snackbarShow', 'allItemsDeleted', 'selectedItems', 'listLoading']
};
export default {
  name: 'JobsCalendarComputed',
  computed: _objectSpread({}, getComputedProperties(computedProperties)),
  methods: _objectSpread({}, mapMutations({
    setClearFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  }))
};