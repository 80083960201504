import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "px-6", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "pl-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "ib" },
                        [
                          _c(VTextField, {
                            staticStyle: { width: "340px" },
                            attrs: {
                              label: "Search jobs",
                              outlined: "",
                              "prepend-inner-icon": "mdi-magnify",
                              dense: "",
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.applySearchJobsListFilter($event)
                              },
                            },
                            model: {
                              value: _vm.jobsForInvoiceSearchText,
                              callback: function ($$v) {
                                _vm.jobsForInvoiceSearchText = $$v
                              },
                              expression: "jobsForInvoiceSearchText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "ib" },
                        [
                          _c(VAutocomplete, {
                            staticClass: "pt-0 pl-3",
                            staticStyle: {
                              width: "340px",
                              transform: "translateY(-4px)",
                            },
                            attrs: {
                              id: "customersListForInvoice",
                              items: _vm.customersListForInvoice,
                              chips: "",
                              "deletable-chips": "",
                              "search-input": _vm.customersSearchText,
                              "item-text": "displayName",
                              "item-value": "id",
                              label: "Select customer",
                              outlined: "",
                              dense: "",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.customersSearchText = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.customersSearchText = $event
                              },
                              change: _vm.getJobsListFromCustomerId,
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.applySearchCustomersFilter($event)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.$stringConstants("showMiniLoadMoreButton")
                                  ? {
                                      key: "append-item",
                                      fn: function () {
                                        return [
                                          _c("LoadMoreItem", {
                                            attrs: {
                                              appendItemActionName:
                                                "appendMoreCustomers",
                                              showMiniLoader:
                                                _vm.customersListForInvoiceLoader,
                                              searchText:
                                                _vm.customersSearchText,
                                              listData:
                                                _vm.customersListForInvoice,
                                              listSize:
                                                _vm.customersListForInvoiceSize,
                                            },
                                            on: {
                                              appendMoreCustomers:
                                                _vm.appendMoreCustomers,
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.selectedCustomerIdForInvoice,
                              callback: function ($$v) {
                                _vm.selectedCustomerIdForInvoice = $$v
                              },
                              expression: "selectedCustomerIdForInvoice",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "far-list scroll-view-small jb-ls-fn-sz" }, [
        !_vm.noData && _vm.jobsListForInvoice.length > 0 && !_vm.invoiceLoader
          ? _c(
              "div",
              { staticClass: "far-list-cont" },
              [
                !_vm.noData
                  ? _c("div", { staticClass: "pb-4 list-heading" }, [
                      _c(
                        "div",
                        { staticClass: "px-6", staticStyle: { width: "100%" } },
                        [
                          _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("jobNo"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-15 pr-4" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("operation"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-15 pr-4" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("customer"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("completedOn"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("completedBy"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-13 pr-4" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("billableQuantity"))
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-19 pr-4" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("contact"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-8" }),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._l(_vm.jobsListForInvoice, function (job, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "lt-vw pt-3 pb-3",
                      staticStyle: { "font-size": "13px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "px-6 d-flex align-center",
                          staticStyle: { "font-size": "15px", width: "100%" },
                        },
                        [
                          _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                            _vm._v(
                              " " + _vm._s(_vm.getJobNumberPrefix(job.number))
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.getJobNumberSuffix(job.number)) + " "
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-15 pr-4" }, [
                            _vm._v(_vm._s(job.operation.title)),
                          ]),
                          _c("div", { staticClass: "ib width-perc-15 pr-4" }, [
                            _vm._v(
                              " " + _vm._s(job.customer.businessName) + " "
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  job.workCompletedOn
                                    ? _vm.getDateTimeFormat(job.workCompletedOn)
                                    : "-"
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                            _vm._v(_vm._s(_vm.getAssignedUserName(job))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-13 pr-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  job.operation.billingQuantities &&
                                    job.operation.billingQuantities[0]
                                    ? job.operation.billingQuantities[0]
                                        .billingQuantity
                                    : ""
                                ) +
                                " (" +
                                _vm._s(job.operation.billingUnit) +
                                ") "
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-19 pr-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  job.customer.phoneNumber
                                    ? job.customer.phoneNumber
                                    : "N/A"
                                ) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  job.customer.email
                                    ? job.customer.email
                                    : "N/A"
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-8 pr-4" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedJobsForInvoice,
                                  expression: "selectedJobsForInvoice",
                                },
                              ],
                              staticStyle: { transform: "scale(1.3)" },
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: job,
                                checked: Array.isArray(
                                  _vm.selectedJobsForInvoice
                                )
                                  ? _vm._i(_vm.selectedJobsForInvoice, job) > -1
                                  : _vm.selectedJobsForInvoice,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.selectedJobsForInvoice,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = job,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectedJobsForInvoice =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectedJobsForInvoice = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectedJobsForInvoice = $$c
                                    }
                                  },
                                  function ($event) {
                                    return _vm.updateSelectedJobs(
                                      _vm.selectedJobsForInvoice
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        !_vm.noData && _vm.invoiceLoader
          ? _c(
              "div",
              { staticClass: "px-6 text-center" },
              [_c("ListLoader")],
              1
            )
          : _vm._e(),
        !_vm.selectedCustomerIdForInvoice &&
        _vm.jobsListForInvoice.length == 0 &&
        !_vm.invoiceLoader
          ? _c("div", { staticClass: "px-6 fw-7" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$stringConstants("invoiceApproveNoJobText")) +
                  " "
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "pb-11" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }