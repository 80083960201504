import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import onboardComputedMixin from "@/components/onboard/_onboard-computed";
import { mapActions } from "vuex";
import { notify } from "@/utils/helpers";
import { isValidLatitude, isValidLongitude } from "@/utils/helpers/gmaps-helpers";
export default {
  mixins: [onboardComputedMixin],
  methods: _objectSpread(_objectSpread({}, mapActions({
    locateFieldAsync: "locateFieldReadOnly"
  })), {}, {
    isStringNumeric: function isStringNumeric(stringValue) {
      if (typeof stringValue != "string") return false;
      return !isNaN(stringValue) && !isNaN(parseFloat(stringValue));
    },
    intiatePlacesSearch: function intiatePlacesSearch(latitude, longitude) {
      this.$store.dispatch("navigateToCoordinates", [latitude, longitude]);
    },
    requestPlacesSearchAPI: function requestPlacesSearchAPI() {
      this.$store.dispatch("requestGooglePlacesSearch");
    },
    searchPlaces: function searchPlaces() {
      if (this.onboardMapSearchValue != "" && this.onboardMapSearchValue != null) {
        if (this.onboardMapSearchValue && this.onboardMapSearchValue.includes(",")) {
          var searchInputList = this.onboardMapSearchValue.split(",");
          var searchInputHasCoordinates = searchInputList.length == 2 && this.isStringNumeric(searchInputList[0]) && this.isStringNumeric(searchInputList[1]);
          if (searchInputHasCoordinates) {
            if (isValidLatitude(searchInputList[0]) && isValidLongitude(searchInputList[1])) {
              this.intiatePlacesSearch(searchInputList[0], searchInputList[1]);
            } else {
              notify(this.$stringConstants('onboardInvalidCoordinate'), "fail");
            }
          } else {
            this.requestPlacesSearchAPI();
          }
        } else {
          this.requestPlacesSearchAPI();
        }
      }
    }
  })
};