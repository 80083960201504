import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "px-9 pt-10", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants(
                    "accountDetailsPersonalPreferencesHeading1"
                  ),
                  textValue2: _vm.$stringConstants(
                    "accountDetailsPersonalPreferencesHeading2"
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "7" } },
            [
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("vehiclesTitle")) + " "
                ),
              ]),
              _c(VAutocomplete, {
                staticClass: "pre-auto-pt",
                attrs: {
                  items: _vm.vehiclesListForUserProfile,
                  "item-text": "title",
                  "item-value": "id",
                  label: "Select vehicle",
                  "search-input": _vm.vehiclesForUserProfileSearchText,
                  outlined: "",
                  dense: "",
                  attach: "",
                  chips: "",
                  "deletable-chips": "",
                  flat: "",
                  solo: "",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.vehiclesForUserProfileSearchText = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.vehiclesForUserProfileSearchText = $event
                  },
                },
                model: {
                  value: _vm.userProfile.defaultVehicleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.userProfile, "defaultVehicleId", $$v)
                  },
                  expression: "userProfile.defaultVehicleId",
                },
              }),
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("implementsTitle")) + " "
                ),
              ]),
              _c(VAutocomplete, {
                staticClass: "pre-auto-pt",
                attrs: {
                  items: _vm.implementsListForUserProfile,
                  "item-text": "title",
                  "item-value": "id",
                  label: "Select implement",
                  "search-input": _vm.implementsForUserProfileSearchText,
                  outlined: "",
                  dense: "",
                  attach: "",
                  chips: "",
                  "deletable-chips": "",
                  flat: "",
                  solo: "",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.implementsForUserProfileSearchText = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.implementsForUserProfileSearchText = $event
                  },
                },
                model: {
                  value: _vm.userProfile.defaultImplementId,
                  callback: function ($$v) {
                    _vm.$set(_vm.userProfile, "defaultImplementId", $$v)
                  },
                  expression: "userProfile.defaultImplementId",
                },
              }),
              _c(
                VRow,
                { staticClass: "pt-2" },
                [
                  _c(
                    VCol,
                    { staticClass: "pt-0 px-0 min-hd", attrs: { cols: "12" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$stringConstants("workTime")) + " "
                      ),
                    ]
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0 pl-0", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$stringConstants("startTime")) + " "
                        ),
                      ]),
                      _c("VueCtkDateTimePicker", {
                        staticClass: "sl-time",
                        attrs: {
                          color: "#1d34ad",
                          noKeyboard: "",
                          format: "HH:mm",
                          formatted: "HH:mm",
                          "append-icon": "mdi-clock-time-four-outline",
                          "no-label": "",
                          "only-time": "",
                          noClearButton: "",
                        },
                        model: {
                          value:
                            _vm.userProfile.settings.workingStartTimeConverted,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.userProfile.settings,
                              "workingStartTimeConverted",
                              $$v
                            )
                          },
                          expression:
                            "userProfile.settings.workingStartTimeConverted",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0 pr-0", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$stringConstants("endTime")) + " "
                        ),
                      ]),
                      _c("VueCtkDateTimePicker", {
                        staticClass: "sl-time",
                        attrs: {
                          color: "#1d34ad",
                          noKeyboard: "",
                          format: "HH:mm",
                          formatted: "HH:mm",
                          "append-icon": "mdi-clock-time-four-outline",
                          "no-label": "",
                          "only-time": "",
                          noClearButton: "",
                        },
                        model: {
                          value:
                            _vm.userProfile.settings.workingEndTimeConverted,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.userProfile.settings,
                              "workingEndTimeConverted",
                              $$v
                            )
                          },
                          expression:
                            "userProfile.settings.workingEndTimeConverted",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "pt-7 pl-0", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("breakTimeMinutes")) +
                            " "
                        ),
                      ]),
                      _c(VTextField, {
                        attrs: {
                          outlined: "",
                          palceholder: "Break time (minutes)",
                          dense: "",
                          type: "number",
                          required: "",
                        },
                        model: {
                          value:
                            _vm.userProfile.settings
                              .allowedBreakTimeADayInMinutes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.userProfile.settings,
                              "allowedBreakTimeADayInMinutes",
                              _vm._n($$v)
                            )
                          },
                          expression:
                            "userProfile.settings.allowedBreakTimeADayInMinutes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }