import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VAvatar,
    {
      style: { backgroundColor: _vm.getDisabledColor() },
      attrs: { rounded: "", size: "28" },
    },
    [
      _c("span", { staticClass: "white--text" }, [
        _vm._v(_vm._s(_vm.getInitials(_vm.initials))),
      ]),
      _c(VIcon, { staticClass: "icon-size", attrs: { color: "white" } }, [
        _vm._v(" mdi-account "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }