import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import { formFieldPb } from '@/utils/uiconstants';
import { validateRegexAlphaNumeric, validateRegexAlpha } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'usersModule': ['accountTabTexts', 'userProfile', 'usersLoader', 'accountBusinessProfile', 'accountMapSearchValue', 'vehiclesListForUserProfile', 'vehiclesForUserProfileSearchText', 'implementsListForUserProfile', 'implementsForUserProfileSearchText', 'startingLat', 'startingLng', 'accountFarmAddresses'],
  'root': ['areaMeasurementUnitsList', 'globalCurrenciesList', 'globalTaxesNameList', 'accountTab', 'isRoutedToAccountForTaxUpdate', 'globalCountriesList', 'globalTimeZonesList']
};
export default {
  name: 'accountComputedMixin',
  data: function data() {
    return {
      formFieldPb: formFieldPb
    };
  },
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    businessProfilePrefix: {
      get: function get() {
        return this.$store.getters.accountBusinessProfile.shortTitle;
      },
      set: function set(newVal) {
        this.$store.state.usersModule.accountBusinessProfile.shortTitle = newVal;
      }
    },
    userRole: {
      get: function get() {
        return localStorage.getItem('userRole');
      }
    }
  }),
  methods: {
    updatePrefix: function updatePrefix(event) {
      var shortenedBusinessName = this.getValidatedString(event.currentTarget.value).substring(0, ConstantValues.defaultBusinessProfilePrefixMinLength);
      if (shortenedBusinessName.length == ConstantValues.defaultBusinessProfilePrefixMinLength && (this.accountBusinessProfile == null || this.accountBusinessProfile && (this.accountBusinessProfile.shortTitle == null || this.accountBusinessProfile.shortTitle.length == 0))) {
        this.businessProfilePrefix = shortenedBusinessName;
      }
    },
    getValidatedString: function getValidatedString(rawString) {
      return rawString.toString().replace(/\s+/g, '').replace(/[0-9]/g, '').replace(/\./g, '').toUpperCase();
    },
    checkKeyDownAlpha: function checkKeyDownAlpha(event) {
      validateRegexAlpha(event);
    },
    checkKeyDownAlphaNumeric: function checkKeyDownAlphaNumeric(event) {
      validateRegexAlphaNumeric(event);
    }
  }
};