import { validateRegexAlphaNumeric } from "@/utils/helpers";
import InvoicesComputedMixin from "@/components/invoices/invoices-computed";
export default {
  name: "BusinessProfileForInvoiceAdd",
  mixins: [InvoicesComputedMixin],
  mounted: function mounted() {
    this.$store.dispatch("getBusinessProfileForInvoice", this.businessInfoUserId);
  },
  methods: {
    checkKeyDownAlphaNumeric: function checkKeyDownAlphaNumeric(event) {
      validateRegexAlphaNumeric(event);
    }
  }
};