import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import permissionsMixin from "@/mixins/permissions-mixin";
import { getCookie } from "@/utils/helpers";
import SidebarLink from '@/components/shared/sidebar-link.vue';
import routes from '@/router/sidebar-routes';
import superAdminRoutes from '@/router/superadmin-routes';
import { UserRoleType } from '@/enum/userRoleType';
import Avatar from '@/components/shared/avatars/avatar.vue';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
export default {
  mixins: [permissionsMixin],
  components: {
    SidebarLink: SidebarLink,
    Avatar: Avatar
  },
  data: function data() {
    return {
      routes: routes,
      superAdminRoutes: superAdminRoutes
    };
  },
  beforeMount: function beforeMount() {
    this.activeRouteIndex = null;
  },
  computed: {
    activeRouteIndex: {
      get: function get() {
        return this.$store.state.activeRouteIndex;
      },
      set: function set(newValue) {
        this.$store.state.activeRouteIndex = newValue;
      }
    },
    showSuperAdminSubMenu: {
      get: function get() {
        return this.$store.state.showSuperAdminSubMenu;
      },
      set: function set(newValue) {
        this.$store.state.showSuperAdminSubMenu = newValue;
      }
    },
    ownerRole: {
      get: function get() {
        var ownerRole = localStorage.getItem("ownerRole");
        if (ownerRole) {
          return ownerRole;
        } else {
          return null;
        }
      }
    },
    loginUserRole: {
      get: function get() {
        return this.$store.getters.loginUserRole;
        // return null;
      }
    },
    disabledNavigation: {
      get: function get() {
        return localStorage.getItem("disabledNavigation");
      }
    },
    visibleRoutes: function visibleRoutes() {
      return this.getVisibleRoutes();
    },
    visibleSuperAdminRoutes: function visibleSuperAdminRoutes() {
      return this.getSuperAdminVisibleRoutes();
    },
    collapsedNavigation: function collapsedNavigation() {
      return this.$store.state.collapsedNavigation;
    },
    isSuperAdminLoggedIn: function isSuperAdminLoggedIn() {
      return getLoggedInUserRole().isUserSuperAdminLoggedIn;
    }
  },
  watch: {
    $route: function $route(_ref) {
      var path = _ref.path;
      var pathIncludesSuperAdminPaths = ['/overview', '/users', '/businesses'];
      if (!pathIncludesSuperAdminPaths.includes(path)) {
        this.activeRouteIndex = this.visibleRoutes.findIndex(function (item, index) {
          return path.includes(item.route);
        });
      } else {
        this.showSuperAdminSubMenu = true;
        switch (path) {
          case '#':
            this.showSuperAdminSubMenu = !this.showSuperAdminSubMenu;
            break;
          case '/overview':
            this.activeRouteIndex = this.routes.length + 1;
            break;
          case '/users':
            this.activeRouteIndex = this.routes.length + 2;
            break;
          case '/businesses':
            this.activeRouteIndex = this.routes.length + 3;
            break;
          default:
            return;
        }
      }
    }
  },
  methods: {
    getSuperAdminVisibleRoutes: function getSuperAdminVisibleRoutes() {
      return this.superAdminRoutes;
    },
    getVisibleRoutes: function getVisibleRoutes() {
      var _this = this;
      this.routes = this.routes.filter(function (route) {
        return _this.checkRenderCondition(route);
      });
      return this.routes;
    },
    getLoggedInUserRole: function getLoggedInUserRole() {
      return localStorage.getItem("fullOwnerBusinessName") ? localStorage.getItem("fullOwnerBusinessName") : UserRoleType[parseInt(JSON.parse(getCookie("role")))];
    },
    checkRenderCondition: function checkRenderCondition(_ref2) {
      var renderCondition = _ref2.renderCondition;
      if (!renderCondition) return true;
      var primaryRole = renderCondition.primaryRole,
        secondaryRole = renderCondition.secondaryRole;
      if (this.loginUserRole == UserRoleType[primaryRole]) return true;
      if (this.loginUserRole == UserRoleType[secondaryRole] && this.ownerRole == primaryRole) return true;
      return false;
    },
    updateActiveRouteIndex: function updateActiveRouteIndex(index) {
      this.activeRouteIndex = index;
    },
    updateSuperAdminActiveRouteIndex: function updateSuperAdminActiveRouteIndex(index) {
      switch (index) {
        case 0:
          this.showSuperAdminSubMenu = !this.showSuperAdminSubMenu;
          this.activeRouteIndex = this.routes.length;
          break;
        case 1:
        case 2:
        case 3:
          this.activeRouteIndex = this.routes.length + index;
          break;
      }
    },
    handleCollapse: function handleCollapse() {
      this.$store.commit("setCollapsedNavigation", !this.collapsedNavigation);
    }
  }
};