import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getDateDifferenceInMinutes, getDateTimeFormattedLocal } from '@/utils/helpers';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'suBusinessesModule': ['businesses', 'businessCountry', 'businessType', 'businessSubscriptionStatus', 'businessesStartDate', 'businessesEndDate', 'businessesListFiltersApplied', 'businessesSearchText', 'businessTypesList', 'businessSubscriptionStatusTypes', 'businessesDetails'],
  'excelExportModule': ['exportData', 'exportDataLength', 'exportDataLoaded', 'exportDataTotal'],
  'root': ['isRoutedFromReferencePage', 'listLoading', 'enumMemberRoles', 'totalRowCount', 'showSuperAdminSubMenu', 'globalCountriesList']
};
export default {
  name: 'AdminBusinessesComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    currencySymbol: function currencySymbol() {
      return localStorage.getItem("currencySymbol");
    }
  }),
  methods: {
    getCancellationScheduledText: function getCancellationScheduledText(subsciption) {
      if (parseInt(getDateDifferenceInMinutes(subsciption.cancellationScheduledOn)) > 0) {
        return this.$stringConstants('cancellationScheduledOnText') + getDateTimeFormattedLocal(subsciption.cancellationScheduledOn);
      }
      return this.$stringConstants('cancelledOnText') + getDateTimeFormattedLocal(subsciption.cancellationScheduledOn);
    }
  }
};