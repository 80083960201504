import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "field-input-label pt-8" }, [
        _vm._v(" " + _vm._s(_vm.$stringConstants("latitude")) + " "),
      ]),
      _c(VTextField, {
        class: _vm.formFieldPb,
        attrs: { type: "number", min: "0", outlined: "", dense: "" },
        on: {
          keydown: function ($event) {
            return _vm.checkKeyDownNumeric($event)
          },
        },
        model: {
          value: _vm.teamJobAddressesDropPinLatitude,
          callback: function ($$v) {
            _vm.teamJobAddressesDropPinLatitude = _vm._n($$v)
          },
          expression: "teamJobAddressesDropPinLatitude",
        },
      }),
      _c("div", { staticClass: "field-input-label" }, [
        _vm._v(" " + _vm._s(_vm.$stringConstants("longitude")) + " "),
      ]),
      _c(VTextField, {
        class: _vm.formFieldPb,
        attrs: { type: "number", min: "0", outlined: "", dense: "" },
        on: {
          keydown: function ($event) {
            return _vm.checkKeyDownNumeric($event)
          },
        },
        model: {
          value: _vm.teamJobAddressesDropPinLongitude,
          callback: function ($$v) {
            _vm.teamJobAddressesDropPinLongitude = _vm._n($$v)
          },
          expression: "teamJobAddressesDropPinLongitude",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }