import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "field-input-label",
        class: { "pb-1 pt-2": _vm.isDetailsView },
      },
      [
        _vm._v(" " + _vm._s(_vm.$stringConstants("operation")) + " "),
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.teamJobsLoader,
            expression: "!teamJobsLoader",
          },
        ],
      },
      [
        _c(VAutocomplete, {
          class: {
            customAuto: _vm.isDetailsView,
            ownStyle:
              _vm.teamJobOperation != null &&
              _vm.teamJobOperation &&
              _vm.teamJobOperation.id != null &&
              _vm.isDetailsView,
            opInput:
              (_vm.teamJobOperation == null ||
                _vm.teamJobOperation.id == null) &&
              _vm.isDetailsView,
          },
          style: { minWidth: "400px" },
          attrs: {
            items: _vm.teamJobOperationsList,
            "item-text": "displayTitle",
            placeholder: _vm.$stringConstants("selectOperationPlaceholder"),
            "search-input": _vm.teamJobOperationsSearchText,
            disabled:
              _vm.teamJobsLoader ||
              !_vm.teamJobUser ||
              !_vm.teamJobViewPreference.operation.isEditable,
            loading: _vm.teamJobOperationsListLoader,
            outlined: "",
            dense: "",
            flat: "",
            attach: "",
            "return-object": "",
            solo: "",
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.teamJobOperationsSearchText = $event
            },
            "update:search-input": function ($event) {
              _vm.teamJobOperationsSearchText = $event
            },
            change: _vm.filterResourcesByOperation,
            focus: function ($event) {
              return _vm.refeatchOperationsList($event)
            },
          },
          nativeOn: {
            input: function ($event) {
              return _vm.applySearchJobOperationTimeout($event)
            },
          },
          scopedSlots: _vm._u(
            [
              _vm.isDetailsView
                ? {
                    key: "selection",
                    fn: function (data) {
                      return [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "ib",
                              class: {
                                ophigh: _vm.isDetailsView && !_vm.minimal,
                              },
                            },
                            [_vm._v(" " + _vm._s(data.item.displayTitle))]
                          ),
                        ]),
                      ]
                    },
                  }
                : null,
              {
                key: "append-item",
                fn: function () {
                  return [
                    _c("LoadMoreDropdown", {
                      attrs: {
                        moreData: _vm.hasMoreData,
                        loadInProgress: _vm.teamJobOperationsListLoader,
                        loadingListLength: _vm.teamJobOperationsList.length,
                      },
                      on: { appendMore: _vm.appendMoreOperations },
                    }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.teamJobOperation,
            callback: function ($$v) {
              _vm.teamJobOperation = $$v
            },
            expression: "teamJobOperation",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.teamJobsLoader,
            expression: "teamJobsLoader",
          },
        ],
        staticClass: "mb-10 mt-3",
      },
      [_c(VSkeletonLoader, { attrs: { height: "40", type: "card" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }