import "core-js/modules/es.number.constructor.js";
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import Avatar from '../shared/avatars/avatar.vue';
export default {
  name: "CalendarJob",
  props: {
    id: String,
    name: String,
    operation: String,
    customer: String,
    contractor: String,
    operators: Array,
    start: Date,
    end: Date,
    status: Number,
    color: Object,
    number: String,
    loading: Boolean,
    draggable: Boolean
  },
  computed: {
    isSingleDayJob: function isSingleDayJob() {
      return this.start.getFullYear() === this.end.getFullYear() && this.start.getMonth() === this.end.getMonth() && this.start.getDate() === this.end.getDate();
    },
    teamJobStatusLabel: function teamJobStatusLabel() {
      return TeamJobStatusType[this.status];
    },
    isContractorOrContractorManagerLoggedIn: function isContractorOrContractorManagerLoggedIn() {
      return getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn;
    },
    isFarmerOrFarmerManagerLoggedIn: function isFarmerOrFarmerManagerLoggedIn() {
      return getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn;
    }
  },
  components: {
    Avatar: Avatar
  },
  data: function data() {
    return {
      TeamJobStatusType: TeamJobStatusType
    };
  },
  methods: {
    handleDragStart: function handleDragStart(e) {
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('application/job-id', this.id);
    },
    emitJobMenu: function emitJobMenu(event) {
      this.$emit('show-job-menu', event);
    }
  }
};