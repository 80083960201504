import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { "content-class": "image-viewer-wrapper d-flex" },
      model: {
        value: _vm.showImagePreview,
        callback: function ($$v) {
          _vm.showImagePreview = $$v
        },
        expression: "showImagePreview",
      },
    },
    [
      _c(
        VCard,
        { staticClass: "py-5 px-4 d-flex justify-center image-viewer-layout" },
        [
          _c("img", { attrs: { src: _vm.imageUrl } }),
          _c(
            VBtn,
            {
              staticClass: "f-nn ac-bt ac-btn mt-4",
              attrs: { elevation: "0", width: "160" },
              on: { click: _vm.openImageInNewTab },
            },
            [_vm._v(" View original ")]
          ),
          _c(
            VCardActions,
            {
              staticClass: "px-4 py-5 position-absolute",
              staticStyle: { top: "0", right: "0" },
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "f-nn ac-bt ac-btn",
                  attrs: { elevation: "0" },
                  on: {
                    click: function ($event) {
                      _vm.showImagePreview = false
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }