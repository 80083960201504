import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'productsModule': ['productDetails', 'productsList', 'productSearchText', 'productStatus'],
  'root': ['enumListProductUnits', 'productLoader', 'editProductLoader', 'noData', 'filtersApplied', 'listCount', 'enumActiveStatus', 'totalRowCount', 'snackbarShow', 'allItemsDeleted', 'selectedItems', 'isPageRelated']
};
export default {
  name: 'ProductsComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    currencySymbol: function currencySymbol() {
      return localStorage.getItem("currencySymbol");
    }
  })
};