import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "no-decoration", attrs: { to: _vm.route } },
    [
      _c(
        VListItem,
        { staticClass: "sd-ht" },
        [
          _c(VListItemIcon, { class: { "ch-trns-x": _vm.isChildMenu } }, [
            _c("img", { attrs: { src: _vm.icon } }),
          ]),
          _c(
            VListItemContent,
            { class: { "ch-trns-x": _vm.isChildMenu } },
            [_c(VListItemTitle, [_vm._v(_vm._s(_vm.title))])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }