import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-4" },
    [
      _c("div", { attrs: { id: "overlay" } }),
      _c(
        VRow,
        {
          staticClass: "px-9 pl-6 py-0",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(
            VCol,
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c(
                VRow,
                { staticClass: "py-0" },
                [
                  _c(
                    VCol,
                    { staticClass: "pl-0 py-0", attrs: { cols: "6" } },
                    [
                      _c(VTextField, {
                        staticClass: "py-0",
                        attrs: {
                          label: "Search contacts",
                          outlined: "",
                          clearable: "",
                          "prepend-inner-icon": "mdi-magnify",
                          dense: "",
                        },
                        on: {
                          "click:clear": _vm.resetExternalContactsList,
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.applySearchContactsFilter($event)
                          },
                        },
                        model: {
                          value: _vm.contactSearchText,
                          callback: function ($$v) {
                            _vm.contactSearchText = $$v
                          },
                          expression: "contactSearchText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(VCol, { staticClass: "py-0", attrs: { cols: "6" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }