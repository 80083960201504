import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { persistent: "", "max-width": "560" },
      model: {
        value: _vm.dialogSelectedRows,
        callback: function ($$v) {
          _vm.dialogSelectedRows = $$v
        },
        expression: "dialogSelectedRows",
      },
    },
    [
      _c(
        VCard,
        { staticClass: "dlt-diag py-2 px-3" },
        [
          _vm.listActionType == _vm.listAction.Delete
            ? _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("listItemDelete")) +
                    " " +
                    _vm._s(
                      _vm.allItemsDeleted
                        ? _vm.totalRowCount
                        : _vm.selectedItems.length
                    ) +
                    " " +
                    _vm._s(_vm.itemName) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.listActionType == _vm.listAction.Delete
            ? _c(VCardText, [
                _vm._v(
                  _vm._s(_vm.$stringConstants("deletingText")) +
                    " " +
                    _vm._s(_vm.itemName) +
                    " " +
                    _vm._s(_vm.$stringConstants("permanentlyRemoveText"))
                ),
              ])
            : _vm._e(),
          _vm.listActionType == _vm.listAction.Delete
            ? _c(VCardText, [
                _vm._v(
                  _vm._s(_vm.$stringConstants("confirmationToDeleteText"))
                ),
              ])
            : _vm._e(),
          _vm.listActionType == _vm.listAction.Archive
            ? _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("archiveText")) +
                    " " +
                    _vm._s(
                      _vm.allItemsArchived
                        ? _vm.totalRowCount
                        : _vm.selectedItems.length
                    ) +
                    " " +
                    _vm._s(_vm.itemName) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.listActionType == _vm.listAction.Archive
            ? _c(VCardText, [
                _vm.itemName == "team members"
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("archivingTextTeamMembers")
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("archivingText")) +
                          " " +
                          _vm._s(_vm.itemName) +
                          " " +
                          _vm._s(_vm.$stringConstants("willRemoveThemText")) +
                          " "
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm.listActionType == _vm.listAction.Archive
            ? _c(VCardText, [
                _vm._v(
                  _vm._s(_vm.$stringConstants("confirmationToArchiveText"))
                ),
              ])
            : _vm._e(),
          _vm.listActionType == _vm.listAction.Restore
            ? _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  _vm._s(_vm.$stringConstants("restoreText")) +
                    " " +
                    _vm._s(
                      _vm.allItemsRestored
                        ? _vm.totalRowCount
                        : _vm.selectedItems.length
                    ) +
                    " " +
                    _vm._s(_vm.itemName) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.listActionType == _vm.listAction.Restore
            ? _c(VCardText, [
                _vm._v(
                  _vm._s(
                    _vm.allItemsRestored
                      ? _vm.totalRowCount
                      : _vm.selectedItems.length
                  ) +
                    " " +
                    _vm._s(_vm.itemName) +
                    " " +
                    _vm._s(_vm.$stringConstants("willRestoredText"))
                ),
              ])
            : _vm._e(),
          _vm.listActionType == _vm.listAction.Restore &&
          _vm.itemName == "team members"
            ? _c(VCardText, [
                _vm._v(
                  _vm._s(_vm.$stringConstants("confirmationToRestoreText")) +
                    " " +
                    _vm._s(_vm.$stringConstants("teamMemberRestoreBilling"))
                ),
              ])
            : _vm.listActionType == _vm.listAction.Restore
            ? _c(VCardText, [
                _vm._v(
                  _vm._s(_vm.$stringConstants("confirmationToRestoreText"))
                ),
              ])
            : _vm._e(),
          _c(
            VCardActions,
            { staticClass: "pt-9 px-6 pb-3" },
            [
              _c(VSpacer),
              _c("SecondaryButton", {
                attrs: {
                  width: 85,
                  textValue: _vm.fnDisagreeTite(_vm.listActionType),
                  variant: "secondary",
                  color: "primary",
                  outlined: "",
                  outlineColor: "primary",
                  text: "",
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.dialogSelectedRows = false
                  },
                },
              }),
              _c("SecondaryButton", {
                staticClass: "ml-3",
                attrs: {
                  width: 85,
                  textValue: _vm.fnAgreeTite(_vm.listActionType),
                  color: "primary",
                  variant: "secondary",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.proccedToAction(_vm.listActionType)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }