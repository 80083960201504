import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { validateRegexPhoneNumber } from '@/utils/helpers';
export default {
  name: 'SecondaryContactForm',
  mixins: [accountComputedMixin],
  components: {
    SectionLeftHeadings: SectionLeftHeadings
  },
  methods: {
    checkPhoneNumber: function checkPhoneNumber(event) {
      validateRegexPhoneNumber(event);
    }
  }
};