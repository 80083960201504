import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.function.name.js";
import { checkFieldsValidity } from '@/utils/helpers';
import { formFieldPb } from '@/utils/uiconstants';
import { mapMutations } from 'vuex';
import farmersComputedMixin from '@/components/farmers/farmers-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
export default {
  name: 'FarmerEditForm',
  mixins: [farmersComputedMixin],
  props: ['intgrationType'],
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      updateCustomerLoader: false
    };
  },
  components: {
    Button: Button
  },
  computed: {
    isFieldsValid: function isFieldsValid() {
      if (checkFieldsValidity([this.farmerDetails.name, this.farmerDetails.companyName, this.farmerDetails.address.addressLine1])) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFarmerFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    editfarmerDetails: function editfarmerDetails(intgrationType) {
      var _this = this;
      this.updateCustomerLoader = true;
      var farmerDto = {
        "name": this.farmerDetails.name,
        "companyName": this.farmerDetails.companyName,
        "emailAddress": this.farmerDetails.emailAddress,
        "phoneNumber": this.farmerDetails.phoneNumber,
        "address": this.farmerDetails.address,
        "id": this.farmerDetails.id,
        "customer": this.farmerDetails.customer
      };
      this.$store.dispatch('updateIntegratedCustomerDetails', [farmerDto, intgrationType]).then(function (res) {
        if (res != undefined) {
          _this.contactsIntegration[_this.contactsIntegration.findIndex(function (el) {
            return el.id === res.id;
          })] = res;
          _this.updateFormEnabled = false;
        }
        _this.updateCustomerLoader = false;
      });
    }
  })
};