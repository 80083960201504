import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.dialogCancelSubscription,
            callback: function ($$v) {
              _vm.dialogCancelSubscription = $$v
            },
            expression: "dialogCancelSubscription",
          },
        },
        [
          _c("div", { staticClass: "sb-cancel-dlg" }, [
            _c("div", { staticClass: "sb-cn-title px-4 py-4" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$stringConstants("subscriptionPlanCancelTitle")) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "px-4 pb-4" }, [
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("subscriptionCanclePromptText1")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "py-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("subscriptionCanclePromptText2")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "pt-1 pb-3 color-dgray" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("subscriptionCancelReasonLabel")
                    ) +
                    " "
                ),
              ]),
              _c(
                "div",
                [
                  _c(VTextarea, {
                    attrs: { outlined: "", label: "Type here", height: "100" },
                    model: {
                      value: _vm.subscriptionCancelReason,
                      callback: function ($$v) {
                        _vm.subscriptionCancelReason = $$v
                      },
                      expression: "subscriptionCancelReason",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "pr-4 pb-4",
                staticStyle: { "text-align": "right" },
              },
              [
                _c(
                  "div",
                  { staticClass: "ib pr-2" },
                  [
                    _c("Button", {
                      attrs: {
                        variant: "secondary",
                        text: "",
                        color: "primary",
                        textValue: "Cancel",
                      },
                      nativeOn: {
                        click: function ($event) {
                          _vm.dialogCancelSubscription = false
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ib" },
                  [
                    _c("Button", {
                      attrs: {
                        variant: "secondary",
                        color: "primary",
                        textValue: "Continue",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancelSubscription($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.showStartTrialWarning,
            callback: function ($$v) {
              _vm.showStartTrialWarning = $$v
            },
            expression: "showStartTrialWarning",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                background: "#fff",
                "min-height": "200px",
                "min-width": "460px",
                padding: "12px",
              },
            },
            [
              _c("div", { staticClass: "sb-cn-title px-4 py-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "subscriptionStartWithoutTrialOverTitle"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "px-4 pb-4" }, [
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants(
                          "subscriptionStartWithoutTrialOverDescription1"
                        )
                      ) +
                      " " +
                      _vm._s(_vm.getTrialEndDate) +
                      " " +
                      _vm._s(
                        _vm.$stringConstants(
                          "subscriptionStartWithoutTrialOverDescription2"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "pt-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants(
                          "subscriptionStartWithoutTrialOverQuery"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "pr-4 pb-4 pt-4",
                  staticStyle: { "text-align": "right" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ib pr-2" },
                    [
                      _c("Button", {
                        attrs: {
                          variant: "secondary",
                          text: "",
                          color: "primary",
                          textValue: "No",
                        },
                        nativeOn: {
                          click: function ($event) {
                            _vm.showStartTrialWarning = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "ib" },
                    [
                      _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          textValue: "Yes",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.createSubscription($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        VRow,
        { staticClass: "pt-6 pb-0 px-12" },
        [
          _c(
            VCol,
            {
              staticClass: "pr-0 position-relative sb-layout-wd",
              attrs: { cols: "12" },
            },
            [
              _vm.cardsList.length && _vm.subscriptionDetails == null
                ? _c(
                    "div",
                    { staticClass: "sb-renew-btn" },
                    [
                      _c("Button", {
                        attrs: {
                          variant: "list",
                          color: "primary",
                          text: "",
                          textValue: "Start subscription",
                          loading: _vm.subscriptionPlanLoader,
                          disabled: _vm.subscriptionPlanLoader,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.startSubscription()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSystemTrialPeriodOver &&
              _vm.cardsList.length &&
              _vm.subscriptionDetails != null &&
              _vm.subscriptionDetails.cancellationScheduledOn
                ? _c(
                    "div",
                    { staticClass: "sb-renew-btn" },
                    [
                      _c("Button", {
                        attrs: {
                          variant: "list",
                          color: "primary",
                          text: "",
                          textValue: "Don't cancel",
                          loading: _vm.subscriptionPlanLoader,
                          disabled: _vm.subscriptionPlanLoader,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.withdrawCancellation()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.subscriptionDetails &&
              !_vm.subscriptionDetails.cancellationScheduledOn
                ? _c("div", { staticClass: "sb-renew-btn" }, [
                    _c(
                      "div",
                      [
                        _c(
                          VMenu,
                          {
                            attrs: { bottom: "", "min-width": "120", left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "triple-dot-menu",
                                              attrs: {
                                                icon: "",
                                                loading:
                                                  _vm.subscriptionPlanLoader,
                                                disabled:
                                                  _vm.subscriptionPlanLoader,
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-dots-vertical"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1429599418
                            ),
                          },
                          [
                            _c(
                              VList,
                              [
                                _c(
                                  VListItem,
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogCancelSubscription = true
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VListItemTitle,
                                      [
                                        _c(
                                          VIcon,
                                          { staticClass: "icon-size pr-2" },
                                          [_vm._v("mdi-close")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "subscriptionPlanCancelBtn"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "sb-cs-plan" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("subscriptionCurrentPlanTitle")
                    ) +
                    " "
                ),
              ]),
              _vm.subscriptionPlanLoader
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "mt-6" },
                      [
                        _c(VSkeletonLoader, {
                          attrs: {
                            "max-width": "700",
                            height: "16",
                            type: "card",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("div", [
                    !_vm.subscriptionDetails && !_vm.isSystemTrialPeriodOver
                      ? _c("div", { staticClass: "pt-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants("subscriptionTrialText")
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    !_vm.subscriptionDetails && _vm.isSystemTrialPeriodOver
                      ? _c("div", { staticClass: "pt-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants(
                                  "subscriptionTrialExpiredText"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.subscriptionDetails
                      ? _c("div", { staticClass: "pt-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants(
                                  "subscriptionDetailsText1"
                                ) +
                                  _vm.$constantValues("defaultCurrencySymbols")
                                    .GBP +
                                  _vm.flatPrice +
                                  _vm.$stringConstants(
                                    "subscriptionDetailsText2"
                                  )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.subscriptionDetails &&
                    _vm.subscriptionDetails.cancellationScheduledOn
                      ? _c("div", { staticClass: "sb-cancel-txt" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getCancellationScheduledDateString(
                                  _vm.subscriptionDetails
                                    .cancellationScheduledOn
                                )
                              ) +
                              ". "
                          ),
                        ])
                      : _vm._e(),
                  ]),
              !_vm.subscriptionPlanLoader
                ? _c(
                    "div",
                    { staticClass: "sb-cs-plan-det position-relative mt-8" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ib position-relative",
                          staticStyle: { width: "100px" },
                        },
                        [
                          _c("div", { staticClass: "sb-icn-cont" }, [
                            _c("img", {
                              attrs: { src: require("../../assets/sbi.svg") },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "ib position-relative",
                          staticStyle: { width: "60%" },
                        },
                        [
                          _c("div", { staticClass: "sb-plan-det" }, [
                            _c("div", [
                              _c("div", { staticClass: "ib" }, [
                                _vm.subscriptionDetails
                                  ? _c(
                                      "div",
                                      { staticClass: "ib sb-plan-mem-cnt" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.subscriptionDetails.quantity
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "ib sb-plan-mem-cnt" },
                                      [_vm._v("0")]
                                    ),
                                _c("div", { staticClass: "ib pl-1" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "subscriptionPlanMembers"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _vm.isSystemTrialPeriodOver &&
                              _vm.subscriptionDetails == null
                                ? _c("div", { staticClass: "ib ml-8" }, [
                                    _c(
                                      "div",
                                      {
                                        class: [
                                          "sb-plan-status",
                                          _vm.getPlanStatusClass(4),
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getSubscriptionStatus(4)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.subscriptionDetails
                                ? _c("div", { staticClass: "ib ml-8" }, [
                                    _c(
                                      "div",
                                      {
                                        class: [
                                          "sb-plan-status",
                                          _vm.getPlanStatusClass(
                                            _vm.subscriptionDetails.status
                                          ),
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getSubscriptionStatus(
                                                _vm.subscriptionDetails.status
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "sb-plan-dur" }, [
                              !_vm.subscriptionDetails
                                ? _c("div", { staticClass: "ib pr-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("freeTrialText")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", { staticClass: "ib pr-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "monthlyBillingText"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                              _c("div", { staticClass: "ib" }, [
                                _c("div", { staticClass: "sb-plan-sprt" }),
                              ]),
                              !_vm.isSystemTrialPeriodOver &&
                              !_vm.subscriptionDetails
                                ? _c("div", { staticClass: "ib pl-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("EndsOnText")
                                        ) +
                                        " " +
                                        _vm._s(_vm.getTrialEndDate || "N/A") +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isSystemTrialPeriodOver &&
                              !_vm.subscriptionDetails
                                ? _c("div", { staticClass: "ib pl-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("EndedOnText")
                                        ) +
                                        " " +
                                        _vm._s(_vm.getTrialEndDate || "N/A") +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.subscriptionDetails
                                ? _c("div", { staticClass: "ib pl-2" }, [
                                    _vm.subscriptionDetails.status ==
                                    _vm.enumSubscriptionStatus.Trialing
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$stringConstants(
                                                  "trialingEndsOnText"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$stringConstants(
                                                  "nextPaymentOnText"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getLocalDate(
                                            _vm.subscriptionDetails
                                              .currentPeriodEnd
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      ),
                      _c("div", { staticStyle: { width: "auto" } }, [
                        _c("div", { staticClass: "sb-pricing-cont" }, [
                          _vm.subscriptionDetails &&
                          _vm.subscriptionDetails.upcomingInvoice
                            ? _c("div", { staticClass: "sb-plan-prc" }, [
                                _c("div", { staticClass: "ib" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$constantValues(
                                          "defaultCurrencySymbols"
                                        ).GBP
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.subscriptionDetails.upcomingInvoice
                                          .totalAmount
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "sb-plan-prc-txt pt-1" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("totalCostText")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm.subscriptionDetails &&
                              _vm.subscriptionDetails.latestInvoice
                            ? _c("div", { staticClass: "sb-plan-prc" }, [
                                _c("div", { staticClass: "ib" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$constantValues(
                                          "defaultCurrencySymbols"
                                        ).GBP
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.subscriptionDetails.latestInvoice
                                          .totalAmount
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "sb-plan-prc-txt pt-1" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("totalCostText")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.subscriptionPlanLoader &&
              _vm.subscriptionDetails &&
              _vm.subscriptionDetails.discount &&
              _vm.subscriptionDetails.discount.description
                ? _c(
                    "div",
                    {
                      staticClass:
                        "sb-cs-plan-promo position-relative py-2 px-4",
                    },
                    [
                      _c("span", { staticClass: "fw-6" }, [
                        _vm._v(
                          _vm._s(_vm.$stringConstants("promoCodeApplyText"))
                        ),
                      ]),
                      _c("span", { staticClass: "color-dgray pl-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.subscriptionDetails.discount.description
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.subscriptionPlanLoader
                ? _c(
                    "div",
                    { staticClass: "sb-cs-plan-det position-relative mt-8" },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-6 ml-4 ib sb-cir-loader" },
                        [
                          _c(VSkeletonLoader, {
                            attrs: { width: "62", height: "60", type: "card" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-6 ml-3 ib" },
                        [
                          _c(VSkeletonLoader, {
                            attrs: { width: "200", height: "60", type: "card" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }