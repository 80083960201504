import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { isSystemTrialPeriodOver as _isSystemTrialPeriodOver } from "@/utils/helpers/layout-helpers";
import { getComputedProperties } from "@/utils/helpers/computed-generator";
import moment from "moment";
var computedProperties = {
  'usersModule': ['userProfile'],
  'subscriptionsModule': ['showAddCardSubscriptionDialog', 'cardSourceToken', 'stripePublishKey', 'cardsLoader', 'subscriptionPlanTypes', 'subscriptionDetails', 'cardsList', 'noCards', 'showDefaultCardConfirmDialog', 'cardIdToSelect', 'cardBrandToSelect', 'cardLastFourToSelect', 'subscriptionMembersSize', 'subscriptionPlanLoader', 'subscriptionStatus', 'defaultTrialPeriodInDays', 'dialogCancelSubscription', 'cancellationScheduledOn', 'subscriptionPromocode', 'subscriptionPromocodeApplied', 'promoCodeLoader', 'promotionalCodeId', 'subscriptionSuccessDescription', 'pricingLoader', 'subscriptionCancelReason', 'enableCardAddButton', 'subscriptionInvoicesList'],
  'invoicesExternalModule': ['invoiceStatusEnum'],
  'root': ['enumSubscriptionStatus']
};
export default {
  name: 'SubscriptionsComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    isSystemTrialPeriodOver: function isSystemTrialPeriodOver() {
      return _isSystemTrialPeriodOver(this.userProfile.businessProfile.trialEnd);
    },
    flatPrice: function flatPrice() {
      return this.subscriptionPlanTypes[0].flatPrice;
    }
  }),
  methods: {
    getCancellationScheduledDateString: function getCancellationScheduledDateString(dateTime) {
      return this.$stringConstants('subscriptionPlanCancelDateText') + moment.utc(dateTime).local().format("DD MMM YYYY HH:mm");
    }
  }
};