import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'suUsersModule': ['usersList', 'usersListStartDate', 'usersListEndDate', 'usersFiltersApplied', 'usersListRoles', 'usersSearchText'],
  'excelExportModule': ['exportData', 'exportDataLength', 'exportDataLoaded', 'exportDataTotal'],
  'root': ['isRoutedFromReferencePage', 'listLoading', 'enumMemberRoles', 'totalRowCount']
};
export default {
  name: 'AdminUsersComputedMixin',
  computed: _objectSpread({}, getComputedProperties(computedProperties))
};