import { VAlert } from 'vuetify/lib/components/VAlert';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginRegistrationWrapper", [
    _c("div", { staticClass: "centered" }, [
      _c(
        "div",
        { staticClass: "resend-verification-box" },
        [
          _c("div", { staticClass: "logo-wrapper" }, [
            _c("img", {
              attrs: { src: require("../../assets/ag-drive-logo.svg") },
            }),
          ]),
          _c("span", { staticClass: "d-block title" }, [
            _vm._v(_vm._s(_vm.$stringConstants("verifyEmailText"))),
          ]),
          _c("span", { staticClass: "d-block subtitle" }, [
            _vm._v(_vm._s(_vm.$stringConstants("emailCheckText")) + " "),
            _c("span", { staticClass: "font-weight-medium" }, [
              _vm._v(_vm._s(_vm.emailAddress)),
            ]),
            _vm._v(_vm._s(_vm.$stringConstants("confirmAccountText"))),
          ]),
          _c(
            VAlert,
            {
              class: "f-12 my-2 visibility-" + _vm.showUnverifiedEmailMessage,
              attrs: {
                type: "warning",
                dense: "",
                text: "",
                icon: false,
                transition: "expand",
                origin: "in-out",
                width: "100%",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$stringConstants("userVerifyEmailMessage")) +
                  " "
              ),
            ]
          ),
          _c("Button", {
            staticClass: "mb-5",
            attrs: {
              variant: "primary",
              color: "primary",
              textValue:
                _vm.resendCountdown > 0
                  ? "Resend in " + _vm.resendCountdown
                  : "Resend",
              loading: _vm.resendLoading,
              disabled: _vm.resendButtonDisabled,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.submit($event)
              },
            },
          }),
          _c("div", { staticClass: "text-center" }, [
            _c(
              "div",
              { staticClass: "no-decoration d-flex justify-space-between" },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "font-weight-medium d-inline-block cursor-pointer grey--text",
                    on: { click: _vm.logoutUser },
                  },
                  [_vm._v("Logout")]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "\n              font-weight-medium\n              d-inline-block\n              cursor-pointer\n              primary--text\n            ",
                    on: {
                      click: _vm.checkIfEmailVerifiedAndRedirectToMainPage,
                    },
                  },
                  [_vm._v("Continue")]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }