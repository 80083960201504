import $ from 'jquery';
import { closeOverlay } from '@/utils/helpers';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import UsersFilterForm from '@/components/su-users/su-users-filter.vue';
import UsersList from './su-users-list.vue';
import usersComputedMixin from "./su-users-computed";
import superAdminAccessMixin from '@/mixins/super-admin-access-mixin';
export default {
  name: "SuperAdminUsers",
  components: {
    UsersFilterForm: UsersFilterForm,
    UsersList: UsersList
  },
  mixins: [requestsCancellationMixin, usersComputedMixin, superAdminAccessMixin],
  beforeCreate: function beforeCreate() {
    this.$store.state.dashboardTitle = this.$stringConstants('usersTitle');
  },
  mounted: function mounted() {
    $('body').on('click', '#overlay', function () {
      closeOverlay("usersFilterFormOverlay");
    });
  }
};