import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hide-scroll",
      staticStyle: { height: "calc(100vh - 70px)" },
    },
    [
      _c(
        "div",
        { staticClass: "px-10 py-8 jobs-max-width" },
        [
          _c("div", { attrs: { id: "overlay" } }),
          _c("TeamJobsComponents"),
          _c("div", { staticStyle: { "margin-bottom": "100px" } }),
        ],
        1
      ),
      _c("TeamJobsDetailsButtons"),
      _c(
        VSnackbar,
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
          staticClass: "px-4",
          attrs: {
            "multi-line": true,
            color: _vm.brandColor,
            timeout: -1,
            tile: "",
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    VBtn,
                    _vm._b(
                      {
                        attrs: { color: "white", text: "" },
                        on: { click: _vm.updateJobsDetailsAndCloseSnackbar },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Ok ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.teamJobDetailsUpdatedSnackbarVisible,
            callback: function ($$v) {
              _vm.teamJobDetailsUpdatedSnackbarVisible = $$v
            },
            expression: "teamJobDetailsUpdatedSnackbarVisible",
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("teamJobDetailsUpdatedWarning")) +
              " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }