import subscriptionsComputedMixin from '@/components/subscriptions/subscriptions-computed';
import { DeleteItemDialog as SetDefaultCardDialog } from '@/components/shared/dialogs/index';
export default {
  components: {
    SetDefaultCardDialog: SetDefaultCardDialog
  },
  mixins: [subscriptionsComputedMixin],
  methods: {
    confirmDefaultCardChange: function confirmDefaultCardChange() {
      this.showDefaultCardConfirmDialog = false;
      this.$store.dispatch("setDefaultCard", this.cardIdToSelect);
    },
    cancelDefaultCardChange: function cancelDefaultCardChange() {
      this.showDefaultCardConfirmDialog = false;
    }
  }
};