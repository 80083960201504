import { closeOverlay, checkFieldsValidity, validateRegexNumeric, validateRegexAlphaNumeric } from '@/utils/helpers';
import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import productsComputedMixin from '@/components/products/products-computed';
import { HeaderPrimary, SubHeaderPrimary, DescriptionPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { Product } from '@/data/models/Product';
export default {
  components: {
    ListLoader: ListLoader,
    SubHeaderPrimary: SubHeaderPrimary,
    DescriptionPrimary: DescriptionPrimary,
    Button: Button,
    HeaderPrimary: HeaderPrimary
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad
    };
  },
  mixins: [productsComputedMixin],
  computed: {
    isDisabledBtn: function isDisabledBtn() {
      if (this.productDetails != null) {
        if (checkFieldsValidity([this.productDetails.title, this.productDetails.unit, this.productDetails.cost])) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },
  methods: {
    closeProductEditForm: function closeProductEditForm() {
      closeOverlay("editProductFormOverlay");
    },
    checkKeyDownNumeric: function checkKeyDownNumeric(event) {
      validateRegexNumeric(event);
    },
    checkKeyDownAlphaNumeric: function checkKeyDownAlphaNumeric(event) {
      validateRegexAlphaNumeric(event);
    },
    editProductDetails: function editProductDetails() {
      var productDto = new Product().SetData(this.productDetails);
      this.$store.dispatch('addOrUpdateProduct', productDto);
    }
  }
};