export default {
  name: "Drawer",
  props: ["closeCallback"],
  data: function data() {
    return {
      previousDashboardTitle: null
    };
  },
  computed: {
    drawerIsOpen: function drawerIsOpen() {
      return this.$store.state.drawerOpen;
    }
  },
  watch: {
    drawerIsOpen: function drawerIsOpen() {
      if (!this.drawerIsOpen && this.drawerIsOpen === null) {
        this.closeCallback();
      }
      this.$store.commit("setCollapsedNavigation", this.drawerIsOpen);
    }
  },
  mounted: function mounted() {
    this.previousDashboardTitle = this.$store.state.dashboardTitle;
  },
  methods: {
    setDashboardTitleToPrevious: function setDashboardTitleToPrevious() {
      this.$store.commit("setDashboardTitle", this.previousDashboardTitle);
    }
  }
};