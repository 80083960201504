import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.slice.js";
import $ from 'jquery';
import InvoicesListComponent from '@/components/invoices/invoices-list/invoices-list.vue';
import InvoicesListExternalComponent from '@/components/invoices-external/invoices-external-list/invoices-external-list.vue';
import { brandColor } from '@/utils/uiconstants';
import { closeOverlay } from '@/utils/helpers';
import InvoiceJobsPreviewFlyout from '@/components/invoices/invoices-jobs-flyout/invoices-jobs-flyout.vue';
import InvoiceComputedMixin from '@/components/invoices/invoices-computed';
import { mapMutations } from 'vuex';
export default {
  data: function data() {
    return {
      activeInvoiceTab: 0,
      brandColor: brandColor
    };
  },
  components: {
    InvoicesListComponent: InvoicesListComponent,
    InvoicesListExternalComponent: InvoicesListExternalComponent,
    InvoiceJobsPreviewFlyout: InvoiceJobsPreviewFlyout
  },
  mixins: [InvoiceComputedMixin],
  watch: {
    activeInvoiceTab: function activeInvoiceTab() {
      var $internalInvoiceState = this.$store.state.invoicesModule;
      var $externalInvoiceState = this.$store.state.invoicesExternalModule;
      if (this.activeInvoiceTab == 0) {
        this.$store.dispatch("fnSetInvoicesFilter", [$internalInvoiceState.invoicesListOffset, $internalInvoiceState.invoicesListLimit]);
      } else if (this.activeInvoiceTab == 1) {
        this.$store.dispatch("fnSetInvoicesFilterExternal", [$externalInvoiceState.invoicesListExternalOffset, $externalInvoiceState.invoicesListExternalLimit]);
      }
    }
  },
  computed: {
    integrationName: function integrationName() {
      var _a, _b;
      return ((_a = this.integrationType) === null || _a === void 0 ? void 0 : _a.charAt(0).toUpperCase()) + ((_b = this.integrationType) === null || _b === void 0 ? void 0 : _b.slice(1).toLowerCase());
    }
  },
  methods: _objectSpread({}, mapMutations({
    resetPaging: "resetPaging"
  })),
  beforeMount: function beforeMount() {
    this.resetPaging();
  },
  mounted: function mounted() {
    var _this = this;
    $('body').on('click', '#overlay', function () {
      closeOverlay("invoiceJobsPreviewFlyout");
    });
    this.$store.state.dashboardTitle = 'Invoices';
    this.loadingIntegration = true;
    $('.v-tabs').hide();
    this.$store.dispatch("getExternalInvoicingServiceAccess").then(function () {
      _this.loadingIntegration = false;
      if (_this.xeroAccessState == 2) {
        _this.integrationType = 'xero';
      } else if (_this.quickbookAccessState == 2) {
        _this.integrationType = 'quickbooks';
      } else if (_this.sageAccessState == 2) {
        _this.integrationType = 'sage';
      }
      if (_this.integrationType) {
        if (_this.invoicesList.length !== 0) {
          $('.v-tabs').show();
        }
        _this.resetPaging();
        _this.enableExternalInvoicingExternal = true;
        _this.selectedInvoiceTabTextExternal = _this.integrationType.charAt(0).toUpperCase() + _this.integrationType.slice(1);
        _this.$nextTick(function () {
          return _this.activeInvoiceTab = 1;
        });
      }
    });
  }
};