import { VAvatar } from 'vuetify/lib/components/VAvatar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VAvatar,
    {
      class: {
        "rounded-avatar": _vm.isCircular,
        "theme-avatar": _vm.isThemeColor,
        "list-avatar": _vm.isListAvatar,
      },
      style: _vm.getAvatarStyles(),
      attrs: { rounded: !_vm.isCircular, size: "28" },
    },
    [
      _c(
        "span",
        {
          class:
            (!_vm.isThemeColor ? "white--text" : "") +
            " " +
            _vm.contentClass +
            " d-flex align-center",
        },
        [
          _vm._t("default", [
            _vm._v(" " + _vm._s(_vm.getInitials(_vm.initials)) + " "),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }