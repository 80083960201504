import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import { mapMutations } from "vuex";
import { getComputedProperties } from "@/utils/helpers/computed-generator";
var computedProperties = {
  'machinesModule': ['addmachineLoader', 'machineAddCheckListItems', 'showAddmachineCheckListItemFields', 'showAddmachineCheckListItemBtn', 'machineDetails', 'editmachineLoader', 'machineListFiltered', 'machineSearchText', 'machinesListColumnTitles', 'machinesLoader', 'machinesFilterType'],
  'root': ['enumOwnership', 'listCount', 'noData', 'filtersApplied', 'enumActiveStatus', 'totalRowCount', 'snackbarShow', 'allItemsDeleted', 'selectedItems', 'machineType']
};
export default {
  name: 'MachinesComputedMixin',
  mixins: [filterParamsMixin],
  computed: _objectSpread({}, getComputedProperties(computedProperties)),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearMachineDetailsState: "resetMachineDetails",
    clearMachineFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging",
    resetSnackbarDetails: "clearSnackbarDetails"
  })), {}, {
    removeLocalMachineParams: function removeLocalMachineParams() {
      this.machineSearchText = this.removeLocalParamsMx("machineSearchQuery", '');
      this.machinesFilterType = this.removeLocalParamsMx("machineFilterType", 0);
    },
    clearAndResetMachinesPagingStates: function clearAndResetMachinesPagingStates() {
      this.machineListFiltered = [];
      this.clearMachineDetailsState();
      this.clearMachineFilterOptions();
      this.removeLocalMachineParams();
      this.resetSnackbarDetails();
      this.resetPaging();
      this.filtersApplied = false;
    }
  })
};