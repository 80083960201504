var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ImportData", {
        attrs: {
          importListName: _vm.getMachineTypeName,
          importOverlayCloseFn: _vm.closeImportMachinesForm,
          importSampleData: _vm.getMachinesSampleData,
          importDispatchFn: _vm.getImportActionName,
          inprogressText1: "Import in progress",
          inprogressText2:
            "We're currently importing your " + _vm.getMachineTypeName + ".",
          uploadFileText: "Upload a file for " + _vm.getMachineTypeName,
          uploadNoteTitle: "Instructions",
          uploadNotes: _vm.getUploadNotes,
          importResourceType: _vm.getImportResourceType,
          requiredFields: _vm.getRequiredImportFields,
        },
        on: { "on-success-callback": _vm.refreshMachinesList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }