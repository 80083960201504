import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { mapMutations } from 'vuex';
import { getLoggedInUserRole, getOwnerId } from '@/utils/helpers/user-role-helpers';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import { TeamJobOperation } from '@/data/models/TeamJobOperation';
import OwnerAvatar from '@/components/shared/avatars/avatar.vue';
import { getListLengthAbsolute, getShortName } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
import LoadMoreDropdown from "@/components/shared/load-more-jobs-dropdown.vue";
export default {
  name: "TeamJobsOwners",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    OwnerAvatar: OwnerAvatar,
    LoadMoreDropdown: LoadMoreDropdown
  },
  data: function data() {
    return {
      searchTimer: null
    };
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFilterOptions: "setClearFilterOptions"
  })), {}, {
    updateSearchText: function updateSearchText(e) {
      var searchedText = e.target.value;
      if (searchedText.length == 0) {
        this.teamJobUserSearchText = null;
        this.applySearchJobUserTimeout();
      } else {
        this.teamJobUserSearchText = searchedText;
        this.applySearchJobUserTimeout();
      }
    },
    refeatchUserList: function refeatchUserList() {
      this.hasMoreData = true;
      if (this.teamJobUserSearchText != null && this.teamJobUser == null) {
        this.teamJobUserSearchText = null;
        this.searchUserForTeamJob();
      }
    },
    applySearchJobUserTimeout: function applySearchJobUserTimeout() {
      var _this = this;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(function () {
        _this.searchUserForTeamJob();
      }, ConstantValues.defaultSearchTimeout);
    },
    searchUserForTeamJob: function searchUserForTeamJob() {
      var _this2 = this;
      this.teamJobUsersList = [];
      this.clearFilterOptions();
      this.$store.dispatch('setTeamJobCustomerFilters').then(function (res) {
        _this2.$store.dispatch('getTeamJobsOwners');
        if (_this2.teamJobUserSearchText == '') {
          _this2.clearFilterOptions();
        }
      });
    },
    getShortBusinessName: function getShortBusinessName(data) {
      return getShortName(data, 60);
    },
    filterResourcesByUser: function filterResourcesByUser() {
      if (this.teamJobUser == null) {
        this.applySearchJobUserTimeout();
      }
      this.teamJobUserSearchText = null;
      if (!(getLoggedInUserRole().isUserOwnerFarmerLoggedIn && getOwnerId() != this.teamJobUser.id)) {
        this.showResources();
      } else {
        this.hideResources();
      }
      if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
        this.clearFilterOptions();
        this.teamJobOperation = new TeamJobOperation();
        this.teamJobOperators = [];
        this.teamJobProducts = new Array();
        this.teamJobOperationsList = [];
        this.teamJobOperationOwnerId = this.teamJobUser.id;
        this.$store.dispatch('getTeamJobOperations');
      }
      this.enableTeamJobDiscard();
    },
    appendMoreCustomers: function appendMoreCustomers() {
      var _this3 = this;
      this.clearFilterOptions();
      this.$store.dispatch('setTeamJobCustomerFilters', [getListLengthAbsolute().getListLengthWithoutOwner(this.teamJobUsersList), ConstantValues.defaultPageLimitSize]).then(function () {
        _this3.$store.dispatch('getTeamJobsOwners');
      });
    }
  })
};