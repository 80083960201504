import { containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import { closeOverlay } from '@/utils/helpers';
import farmersComputedMixin from '@/components/farmers/farmers-computed';
import SubHeaderPrimary from '@/components/shared/headers/subheader-primary.vue';
import DescriptionPrimary from '@/components/shared/headers/description-primary.vue';
import RecivedConReq from '@/components/farmers/farmers-connection-recived.vue';
import Tabs from '@/components/shared/tabs.vue';
export default {
  name: 'manageFarmer',
  mixins: [farmersComputedMixin],
  components: {
    SubHeaderPrimary: SubHeaderPrimary,
    DescriptionPrimary: DescriptionPrimary,
    RecivedConReq: RecivedConReq,
    Tabs: Tabs
  },
  data: function data() {
    return {
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      tab: null
    };
  },
  methods: {
    closeFarmerManageForm: function closeFarmerManageForm() {
      closeOverlay("manageFarmerFormOverlay");
    },
    onTabChange: function onTabChange(currentTab) {
      if (currentTab == 0) {
        this.$store.dispatch('getFarmersConReq');
      } else {
        this.$store.dispatch('getFarmersSendReq');
      }
    }
  }
};