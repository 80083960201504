import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { ApprovalStatusType } from "@/enum/approvalStatusType";
import { TeamJobStatusType, TeamJobStatusTypeLabel } from '@/enum/teamJobStatusType';
export default {
  name: "TeamJobsStatus",
  mixins: [TeamJobsComputed],
  data: function data() {
    return {
      TeamJobStatusType: TeamJobStatusType,
      TeamJobStatusTypeLabel: TeamJobStatusTypeLabel,
      ApprovalStatusType: ApprovalStatusType
    };
  }
};