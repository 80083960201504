var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "overlay" } }),
      _c("OperationsListView", {
        on: { setActiveFlyout: _vm.setActiveFlyout },
      }),
      _vm.shouldComponentDisplay(
        _vm.componentsPermissions["operationTypeUpdate"]
      )
        ? _c(
            "div",
            {
              staticClass:
                "sidebar-overlay position-relative manageOperationsFormOverlay",
              attrs: { id: "manageOperationsFormOverlay" },
            },
            [
              _vm.activeFlyout == "manage-form"
                ? _c("OperationsManageForm", {
                    on: {
                      noActiveFlyout: function ($event) {
                        return _vm.setActiveFlyout(null)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.shouldComponentDisplay(_vm.componentsPermissions["operationCreate"])
        ? _c(
            "div",
            {
              staticClass:
                "sidebar-overlay position-relative addSubOperationFormOverlay",
              attrs: { id: "addSubOperationFormOverlay" },
            },
            [
              _vm.activeFlyout == "add-form"
                ? _c("SubOperationAddForm", {
                    on: {
                      noActiveFlyout: function ($event) {
                        return _vm.setActiveFlyout(null)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.shouldComponentDisplay(_vm.componentsPermissions["operationUpdate"])
        ? _c(
            "div",
            {
              staticClass:
                "sidebar-overlay position-relative editSubOperationFormOverlay",
              attrs: { id: "editSubOperationFormOverlay" },
            },
            [
              _vm.activeFlyout == "edit-form"
                ? _c("SubOperationEditForm", {
                    on: {
                      noActiveFlyout: function ($event) {
                        return _vm.setActiveFlyout(null)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }