import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { TeamJobMapFieldDto } from '@/data/models/TeamJobMapFieldDto';
import ConstantValues from '@/plugins/constantValues';
import $ from 'jquery';
import Vue from 'vue';
import FieldLabelComponent from '@/components/shared/labels/fields-label-stroke.vue';
import FieldLabelComponentLight from '@/components/shared/labels/fields-label-stroke-light.vue';
import FieldInfoWindow from '@/components/fields/field-info-window.vue';
var fieldLabelConstructor = Vue.extend(FieldLabelComponent);
var fieldLabelLightConstructor = Vue.extend(FieldLabelComponentLight);
var fieldInfoWindowConstructor = Vue.extend(FieldInfoWindow);
export var setLabelsOnMap = function setLabelsOnMap(labelsArray, mapInstance) {
  if (labelsArray.length > 0) {
    labelsArray.map(function (label) {
      label.setMap(mapInstance);
    });
  }
};
export var setLabelsVisible = function setLabelsVisible(labelsArray) {
  if (labelsArray.length > 0) {
    labelsArray.map(function (label) {
      if (!label.getVisible()) {
        label.setVisible(true);
      }
    });
  }
};
export var setLabelsInvisible = function setLabelsInvisible(labelsArray) {
  if (labelsArray.length > 0) {
    labelsArray.map(function (label) {
      label.setVisible(false);
    });
  }
};
export var isValidLatitude = function isValidLatitude(latitude) {
  return parseFloat(latitude) >= -90 && parseFloat(latitude) <= 90;
};
export var isValidLongitude = function isValidLongitude(longitude) {
  return parseFloat(longitude) >= -180 && parseFloat(longitude) <= 180;
};
export var setupPolygonInfoWindow = function setupPolygonInfoWindow(state, rootState, fieldData, fieldPolygon) {
  var fieldInfo = formatFieldObjectForInfoWindow(fieldData);
  var fieldInfoWindowComponent = new fieldInfoWindowConstructor({
    propsData: {
      fieldInfo: fieldInfo
    }
  });
  fieldInfoWindowComponent.$mount();
  var fieldInfoWindowString = new XMLSerializer().serializeToString(fieldInfoWindowComponent.$el);
  google.maps.event.addListener(fieldPolygon, 'mouseover', function (event) {
    if (!rootState.drawerOpen && state.fieldsListMap.getZoom() > ConstantValues.defaultLabelVisibilityZoomLevel) {
      state.fieldsInfoWindowContent.setContent(fieldInfoWindowString);
      state.fieldsInfoWindowContent.setPosition(getPolygonCenter(fieldPolygon.getPath()));
      state.fieldsInfoWindowContent.open(state.fieldsListMap, this);
    }
  });
  google.maps.event.addListener(fieldPolygon, 'mouseout', function (event) {
    var _a;
    (_a = state.fieldsInfoWindowContent) === null || _a === void 0 ? void 0 : _a.close();
  });
};
export var setupPolygonClickListener = function setupPolygonClickListener(_, rootState, commit, fieldData, fieldPolygon) {
  google.maps.event.addListener(fieldPolygon, 'click', function (event) {
    if (!rootState.drawerOpen) {
      commit('setMapTriggeredEditFieldData', fieldData);
    }
  });
};
export var changeInfoWindowTheme = function changeInfoWindowTheme(mapIdentifier, themeType) {
  $("".concat(mapIdentifier, " .gm-style-iw")).css({
    transform: 'translate(-65px, -60px)',
    opacity: '0'
  });
  if (themeType) {
    switch (themeType) {
      case 'dark':
        $("".concat(mapIdentifier, " .gm-style-iw")).css({
          backgroundColor: 'rgba(0,0,0,0.8)',
          color: '#fff',
          border: '1px solid #fff'
        });
        break;
      case 'light':
        $("".concat(mapIdentifier, " .gm-style-iw")).css({
          backgroundColor: 'rgba(255,255,255,0.8)',
          color: '#000',
          border: '1px solid #000'
        });
        break;
      default:
        $("".concat(mapIdentifier, " .gm-style-iw")).css({
          backgroundColor: 'rgba(0,0,0,0.8)',
          color: '#fff',
          border: '1px solid #fff'
        });
    }
  }
  $("".concat(mapIdentifier, " .gm-style-iw-d")).css({
    overflow: 'hidden',
    width: '115px',
    height: '27px'
  });
  $("".concat(mapIdentifier, " .gm-style .gm-style-iw-t::after")).css({
    display: 'none'
  });
  $("".concat(mapIdentifier, " .gm-style-iw button")).css({
    display: 'none'
  });
  $("".concat(mapIdentifier, " .gm-style-iw-t")).removeClass().addClass('new-marker');
};
export var changePolygonOpacity = function changePolygonOpacity(polygonShape, opacityLevel) {
  polygonShape.setOptions({
    fillOpacity: opacityLevel
  });
};
export var createPolygonShapes = function createPolygonShapes(mapState, data) {
  var polygonLatLng = [];
  var cords = null;
  var cordColor = null;
  cords = data.polygon;
  for (var i = 0; i < cords.length; i++) {
    var x = {
      lat: cords[i].latitude,
      lng: cords[i].longitude
    };
    polygonLatLng.push(x);
  }
  cordColor = data.colour ? data.colour : "#1d34ad";
  var PolygonShape = new google.maps.Polygon({
    paths: polygonLatLng,
    //paths: GDouglasPeucker(polygonLatLng, 100),
    strokeColor: cordColor,
    map: mapState,
    strokeWeight: 3,
    fillColor: cordColor,
    fillOpacity: 0.1,
    zIndex: 99999
  });
  return PolygonShape;
};
export var createHighlightedPolyShape = function createHighlightedPolyShape(mapState, data) {
  var polygonLatLng = [];
  var cords = null;
  var cordColor = null;
  cords = data.polygon;
  for (var i = 0; i < cords.length; i++) {
    var x = {
      lat: cords[i].latitude,
      lng: cords[i].longitude
    };
    polygonLatLng.push(x);
  }
  cordColor = '#ffffff';
  var PolygonShape = new google.maps.Polygon({
    paths: polygonLatLng,
    strokeColor: cordColor,
    map: mapState,
    strokeWeight: 5,
    strokeOpacity: 1,
    fillColor: cordColor,
    fillOpacity: 0.8,
    zIndex: 999
  });
  return PolygonShape;
};
export var createPolyline = function createPolyline(polyData) {
  var lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1.0,
    scale: 4
  };
  var polygonLineLatLng = [];
  polyData.map(function (coord, idx) {
    var x = {
      lat: coord.latitude,
      lng: coord.longitude
    };
    polygonLineLatLng.push(x);
  });
  var polyLine = new google.maps.Polyline({
    path: polygonLineLatLng,
    geodesic: false,
    strokeColor: '#ffffff',
    strokeOpacity: 0,
    icons: [{
      icon: lineSymbol,
      offset: '0',
      repeat: "5px"
    }]
  });
  return polyLine;
};
export var polygonMouseHoverEvent = function polygonMouseHoverEvent(PolygonShape, rootState) {
  google.maps.event.addListener(PolygonShape, 'mouseover', function () {
    if (!rootState.drawerOpen) {
      changePolygonOpacity(PolygonShape, 0.6);
    }
  });
  google.maps.event.addListener(PolygonShape, 'mouseout', function () {
    if (!rootState.drawerOpen) {
      changePolygonOpacity(PolygonShape, 0.2);
    }
  });
};
export var boundMapOnCordinates = function boundMapOnCordinates(mapState, data) {
  var boundCords = [];
  for (var i = 0; i < data.length; i++) {
    var dataField = new TeamJobMapFieldDto(data[i]);
    if (dataField.polygon.length > 0) {
      for (var j = 0; j < dataField.polygon.length; j++) {
        var x = {
          lat: dataField.polygon[j].latitude,
          lng: dataField.polygon[j].longitude
        };
        boundCords.push(x);
      }
    }
  }
  var bounds = new google.maps.LatLngBounds();
  for (var _i = 0; _i < boundCords.length; _i++) {
    var LatLng = new google.maps.LatLng({
      "lat": parseFloat(boundCords[_i].lat),
      "lng": parseFloat(boundCords[_i].lng)
    });
    bounds.extend(LatLng);
  }
  mapState.fitBounds(bounds);
  var listener = google.maps.event.addListener(mapState, "idle", function () {
    mapState.fitBounds(bounds, ConstantValues.defaultMapPadding);
    google.maps.event.removeListener(listener);
  });
};
export var extendBoundsByLocations = function extendBoundsByLocations(locations, fields, mapState) {
  var boundCords = [];
  if (locations.length != 0) {
    for (var i = 0; i < locations.length; i++) {
      var x = {
        lat: locations[i].latitude,
        lng: locations[i].longitude
      };
      boundCords.push(x);
    }
  }
  if (fields.length != 0) {
    for (var _i2 = 0; _i2 < fields.length; _i2++) {
      if (fields[_i2].polygon.length > 0) {
        for (var j = 0; j < fields[_i2].polygon.length; j++) {
          var _x = {
            lat: fields[_i2].polygon[j].latitude,
            lng: fields[_i2].polygon[j].longitude
          };
          boundCords.push(_x);
        }
      }
    }
  }
  if (locations.length == 0 && fields.length == 0) {
    var newLat = localStorage.getItem('defaultStartingLat') ? parseFloat(localStorage.getItem('defaultStartingLat')) : ConstantValues.defaultBusinessLatitude;
    var newLng = localStorage.getItem('defaultStartingLng') ? parseFloat(localStorage.getItem('defaultStartingLng')) : ConstantValues.defaultBusinessLongitude;
    var tempBounds = new google.maps.LatLngBounds({
      "lat": newLat,
      "lng": newLng
    });
    mapState.fitBounds(tempBounds, ConstantValues.defaultMapPadding);
    mapState.setZoom(ConstantValues.defaultMapZoom);
    return;
  }
  var bounds = new google.maps.LatLngBounds();
  for (var _i3 = 0; _i3 < boundCords.length; _i3++) {
    var LatLng = new google.maps.LatLng({
      "lat": parseFloat(boundCords[_i3].lat),
      "lng": parseFloat(boundCords[_i3].lng)
    });
    bounds.extend(LatLng);
  }
  mapState.fitBounds(bounds);
};
export var hideMapControlButtons = function hideMapControlButtons() {
  var controlSet = document.querySelectorAll(".gmnoprint");
  controlSet.forEach(function (element) {
    element.style.opacity = "0";
  });
  var controlAttributesSet = document.querySelectorAll(".gm-style-cc");
  controlAttributesSet.forEach(function (element) {
    element.style.opacity = "0";
  });
  var activeControlSet = document.querySelectorAll(".gm-control-active");
  activeControlSet[0].style.opacity = 0;
};
export var hideScrollBars = function hideScrollBars(scrollerClass) {
  toggleScrollView(scrollerClass, false);
};
export var showScrollBars = function showScrollBars(scrollerClass) {
  toggleScrollView(scrollerClass, true);
};
export var toggleScrollView = function toggleScrollView(scrollerClass, enableScrollView) {
  if (scrollerClass) {
    var targetElements = Array.from(document.getElementsByClassName(scrollerClass));
    if (targetElements.length > 0) {
      for (var i = 0; i < targetElements.length; i++) {
        targetElements[i].style.overflow = enableScrollView ? 'auto' : 'hidden';
      }
    }
  } else {
    document.documentElement.style.overflow = enableScrollView ? 'auto' : 'hidden';
  }
};
export var toggleScrollBarsOnScrollView = function toggleScrollBarsOnScrollView(showScrollBars) {
  var scrollableElements = document.getElementsByClassName('hide-scroll');
  if (scrollableElements && scrollableElements.length) {
    for (var i = 0; i < scrollableElements.length; i++) {
      scrollableElements[i].style.overflow = showScrollBars ? 'auto' : 'hidden';
    }
  }
};
export var formatFieldObjectForInfoWindow = function formatFieldObjectForInfoWindow(field) {
  var _a, _b;
  return {
    ownerId: field.owner.id,
    fieldName: field.title,
    fieldId: field.id,
    fieldColor: field.colour || '#ffffff',
    fieldArea: field.areaInSquareMeter,
    fieldCrop: field.crop || {
      name: '',
      year: ''
    },
    fieldCustomer: field.owner.businessProfile ? field.owner.businessProfile.title : (field.owner.firstName ? field.owner.firstName : '') + ' ' + (field.owner.lastName ? field.owner.lastName : ''),
    fieldEditable: field.owner.isInternal || field.owner.id === localStorage.getItem(ConstantValues.ownerId),
    fieldAddress: field.address ? ((_a = field.address) === null || _a === void 0 ? void 0 : _a.addressLine1) + ", " + ((_b = field.address) === null || _b === void 0 ? void 0 : _b.addressLine2) : ""
  };
};
export var formatFieldObjectForLabel = function formatFieldObjectForLabel(field) {
  return {
    field: field,
    customer: field.owner.businessProfile ? field.owner.businessProfile.title : (field.owner.firstName ? field.owner.firstName : '') + ' ' + (field.owner.lastName ? field.owner.lastName : ''),
    ownerId: field.owner.id ? field.owner.id : null,
    fieldId: field.id,
    fieldColor: field.colour ? field.colour : '#ffffff'
  };
};
export var GDouglasPeucker = function GDouglasPeucker(source, kink) {
  //G Douglas Peucker
  var n_source = 0,
    n_stack,
    n_dest,
    start,
    end,
    i,
    sig;
  var dev_sqr, max_dev_sqr, band_sqr;
  var x12, y12, d12, x13, y13, d13, x23, y23, d23;
  var F = Math.PI / 180.0 * 0.5;
  var index = [];
  var sig_start = [];
  var sig_end = [];
  if (source.length < 3) return source;
  n_source = source.length;
  band_sqr = kink * 360.0 / (2.0 * Math.PI * 6378137.0);
  band_sqr *= band_sqr;
  n_dest = 0;
  sig_start[0] = 0;
  sig_end[0] = n_source - 1;
  n_stack = 1;
  while (n_stack > 0) {
    start = sig_start[n_stack - 1];
    end = sig_end[n_stack - 1];
    n_stack--;
    if (end - start > 1) {
      x12 = source[end].lng - source[start].lng;
      y12 = source[end].lat - source[start].lat;
      if (Math.abs(x12) > 180.0) x12 = 360.0 - Math.abs(x12);
      x12 *= Math.cos(F * (source[end].lat + source[start].lat));
      d12 = x12 * x12 + y12 * y12;
      for (i = start + 1, sig = start, max_dev_sqr = -1.0; i < end; i++) {
        x13 = source[i].lng - source[start].lng;
        y13 = source[i].lat - source[start].lat;
        if (Math.abs(x13) > 180.0) x13 = 360.0 - Math.abs(x13);
        x13 *= Math.cos(F * (source[i].lat + source[start].lat));
        d13 = x13 * x13 + y13 * y13;
        x23 = source[i].lng - source[end].lng;
        y23 = source[i].lat - source[end].lat;
        if (Math.abs(x23) > 180.0) x23 = 360.0 - Math.abs(x23);
        x23 *= Math.cos(F * (source[i].lat + source[end].lat));
        d23 = x23 * x23 + y23 * y23;
        if (d13 >= d12 + d23) dev_sqr = d23;else if (d23 >= d12 + d13) dev_sqr = d13;else dev_sqr = (x13 * y12 - y13 * x12) * (x13 * y12 - y13 * x12) / d12;
        if (dev_sqr > max_dev_sqr) {
          sig = i;
          max_dev_sqr = dev_sqr;
        }
      }
      if (max_dev_sqr < band_sqr) {
        index[n_dest] = start;
        n_dest++;
      } else {
        n_stack++;
        sig_start[n_stack - 1] = sig;
        sig_end[n_stack - 1] = end;
        n_stack++;
        sig_start[n_stack - 1] = start;
        sig_end[n_stack - 1] = sig;
      }
    } else {
      index[n_dest] = start;
      n_dest++;
    }
  }
  index[n_dest] = n_source - 1;
  n_dest++;
  var r = [];
  for (var _i4 = 0; _i4 < n_dest; _i4++) r.push(source[index[_i4]]);
  return r;
};
export var toGeoJSONCompatible = function toGeoJSONCompatible(coordinates) {
  return coordinates.map(function (_ref) {
    var latitude = _ref.latitude,
      longitude = _ref.longitude;
    return [longitude, latitude];
  });
};
export var getPolygonCenter = function getPolygonCenter(geometry) {
  var bounds = new google.maps.LatLngBounds();
  geometry.forEach(function (latLng) {
    return bounds.extend(latLng);
  });
  return bounds.getCenter();
};