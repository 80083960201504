import { BaseButton as Button } from "@/components/shared/buttons";
export default {
  name: "Notification",
  components: {
    Button: Button
  },
  computed: {
    notificationCallback: function notificationCallback() {
      var _a;
      return (_a = this.$store.state.notification) === null || _a === void 0 ? void 0 : _a.callback;
    },
    hasActionListener: function hasActionListener() {
      return Boolean(this.notificationCallback);
    },
    notificationTitle: function notificationTitle() {
      var _a;
      return (_a = this.$store.state.notification) === null || _a === void 0 ? void 0 : _a.title;
    },
    notificationMessage: function notificationMessage() {
      var _a;
      return (_a = this.$store.state.notification) === null || _a === void 0 ? void 0 : _a.message;
    },
    showNotification: {
      get: function get() {
        return Boolean(this.notificationMessage);
      },
      set: function set(visible) {
        if (!visible) this.$store.commit('cleanUpNotification');
        return;
      }
    },
    actionText: function actionText() {
      var _a;
      return (_a = this.$store.state.notification) === null || _a === void 0 ? void 0 : _a.actionText;
    },
    type: function type() {
      var _a;
      return (_a = this.$store.state.notification) === null || _a === void 0 ? void 0 : _a.type;
    }
  },
  methods: {
    dismissNotification: function dismissNotification() {
      this.$store.commit('cleanUpNotification');
    },
    executeNotificationCallback: function executeNotificationCallback() {
      this.notificationCallback();
      this.$store.commit('cleanUpNotification');
    }
  }
};