import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var JobOperatorTravelStatusType;
(function (JobOperatorTravelStatusType) {
  JobOperatorTravelStatusType[JobOperatorTravelStatusType["Started"] = 0] = "Started";
  JobOperatorTravelStatusType[JobOperatorTravelStatusType["Paused"] = 1] = "Paused";
  JobOperatorTravelStatusType[JobOperatorTravelStatusType["Completed"] = 2] = "Completed";
})(JobOperatorTravelStatusType || (JobOperatorTravelStatusType = {}));
export var JobOperatorTravelStatusTypeLabel = new Map([[JobOperatorTravelStatusType.Started, 'Travelling'], [JobOperatorTravelStatusType.Paused, 'Paused travel'], [JobOperatorTravelStatusType.Completed, 'Reached on location']]);