import "core-js/modules/es.error.cause.js";
import ConstantValues from '@/plugins/constantValues';
import exportFromJSON from 'export-from-json';
import { devLogger } from '../helpers';
import { generateRandomFilename } from '../helpers/generator-helpers';
import store from '@/store/index';
export var excelParser = function excelParser() {
  function exportDataFromJSON(data, newFileName, fileExportType) {
    if (!data) return;
    try {
      var fileName = newFileName || generateRandomFilename();
      var exportType = exportFromJSON.types[fileExportType || ConstantValues.defaultListExportFormat];
      exportFromJSON({
        data: data,
        fileName: fileName,
        exportType: exportType
      });
    } catch (e) {
      devLogger().logError(e);
      throw new Error('Parsing failed!');
    }
  }
  function saveDataToExcel() {
    store.commit('removeIdsFromExportableData');
    var exportData = store.state.excelExportModule.exportData;
    exportDataFromJSON(exportData, null, ConstantValues.defaultListExportFormat);
  }
  return {
    exportDataFromJSON: exportDataFromJSON,
    saveDataToExcel: saveDataToExcel
  };
};