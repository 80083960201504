import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-8" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(VCol, { staticClass: "oy-tl", attrs: { cols: "8" } }, [
              _vm._v(" " + _vm._s(_vm.$stringConstants("farmerInviteText"))),
            ]),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    staticClass: "oy-cl",
                    attrs: { small: "", id: "closeFarmerAddForm" },
                    on: { click: _vm.closeFarmerAddForm },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "pt-8",
        class: [_vm.containerLeftPad, _vm.containerRightPad],
      },
      [
        _c("div", { staticClass: "field-input-label" }, [
          _vm._v(" " + _vm._s(_vm.$stringConstants("emailAddress")) + " * "),
        ]),
        _c(VTextField, {
          class: _vm.formFieldPb,
          attrs: {
            "error-messages": _vm.generateValidationErrors(),
            placeholder: _vm.$stringConstants("emailPlaceholder"),
            outlined: "",
            dense: "",
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.proceedFarmerInvitation($event)
            },
            input: function ($event) {
              return _vm.$v.userInviteeEmail.$touch()
            },
            blur: function ($event) {
              return _vm.$v.userInviteeEmail.$touch()
            },
          },
          model: {
            value: _vm.userInviteeEmail,
            callback: function ($$v) {
              _vm.userInviteeEmail = $$v
            },
            expression: "userInviteeEmail",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btns-bottom-box px-8 py-6" },
      [
        _c("Button", {
          class: { disabled: _vm.fieldsInvalid },
          attrs: {
            variant: "primary",
            color: "primary",
            textValue: _vm.$stringConstants("sendInvitationText"),
            disabled: _vm.fieldsInvalid,
            loading: _vm.addFarmerLoader,
            rightIcon: "mdi-arrow-right",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.proceedFarmerInvitation()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }