import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var TeamJobStatusType;
(function (TeamJobStatusType) {
  TeamJobStatusType[TeamJobStatusType["Pending"] = 0] = "Pending";
  TeamJobStatusType[TeamJobStatusType["Ready"] = 1] = "Ready";
  TeamJobStatusType[TeamJobStatusType["InProgress"] = 2] = "InProgress";
  TeamJobStatusType[TeamJobStatusType["AwaitingCompletion"] = 3] = "AwaitingCompletion";
  TeamJobStatusType[TeamJobStatusType["PartCompleted"] = 4] = "PartCompleted";
  TeamJobStatusType[TeamJobStatusType["Completed"] = 5] = "Completed";
  TeamJobStatusType[TeamJobStatusType["Cancelled"] = 6] = "Cancelled";
})(TeamJobStatusType || (TeamJobStatusType = {}));
export var TeamJobStatusTypeLabel = new Map([[TeamJobStatusType.Pending, 'Pending'], [TeamJobStatusType.Ready, 'Ready'], [TeamJobStatusType.InProgress, 'In-progress'], [TeamJobStatusType.AwaitingCompletion, 'Awaiting completion'], [TeamJobStatusType.PartCompleted, 'Part completed'], [TeamJobStatusType.Completed, 'Completed'], [TeamJobStatusType.Cancelled, 'Cancelled']]);