import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import DescriptionPrimary from "@/components/shared/headers/description-primary.vue";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
export default {
  name: "Tabs",
  props: ["tabs", "tabContents", "listLoaders", "emptyListTexts", "handleTabChange"],
  components: {
    DescriptionPrimary: DescriptionPrimary,
    ListLoader: ListLoader
  },
  data: function data() {
    return {
      activeTab: null,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad
    };
  },
  mounted: function mounted() {
    this.observeVisibility();
  },
  methods: {
    observeVisibility: function observeVisibility() {
      var _this = this;
      var element = document.getElementById("tabs");
      if (window.IntersectionObserver) {
        this.intersectionObserver = new IntersectionObserver(function (entries) {
          if (entries[0].intersectionRatio) {
            _this.$refs.tabsRef.onResize();
          }
        });
        this.intersectionObserver.observe(element);
      }
    },
    unObserveVisibility: function unObserveVisibility() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.unObserveVisibility();
  }
};