import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "pt-10", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants("companyDetailsHeading1"),
                  textValue2: _vm.$stringConstants(
                    "customerBusinessInfoSubTitle"
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "5" } },
            [
              _c(
                VRow,
                {
                  staticClass: "pb-8",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants("companyName"),
                          detailsValue:
                            _vm.contactProfile.businessProfile.title,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants("companyJobCode"),
                          detailsValue:
                            _vm.contactProfile.businessProfile.shortTitle,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "pb-8",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants("vatNumber"),
                          detailsValue: _vm.contactProfile.businessProfile
                            .vatNumber
                            ? _vm.contactProfile.businessProfile.vatNumber
                            : "-",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants(
                            "companyRegistrationNumber"
                          ),
                          detailsValue: _vm.contactProfile.businessProfile
                            .registrationNumber
                            ? _vm.contactProfile.businessProfile
                                .registrationNumber
                            : "-",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "pb-8",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants("address"),
                          detailsValue: _vm.getAddress(
                            _vm.contactProfile.businessProfile.address
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(VCol, { attrs: { cols: "4" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }