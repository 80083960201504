var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "overlay" } }),
      _c("TeamMembersListView"),
      _vm.shouldComponentDisplay(_vm.componentsPermissions["invitationSend"])
        ? _c(
            "div",
            {
              staticClass:
                "sidebar-overlay position-relative addTeamMemberFormOverlay",
              attrs: { id: "addTeamMemberFormOverlay" },
            },
            [_c("TeamMembersAddForm")],
            1
          )
        : _vm._e(),
      _vm.shouldComponentDisplay(_vm.componentsPermissions["memberUpdate"])
        ? _c(
            "div",
            {
              staticClass:
                "sidebar-overlay position-relative editTeamMemberFormOverlay",
              attrs: { id: "editTeamMemberFormOverlay" },
            },
            [_c("TeamMembersEditForm")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }