import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { closeOverlay, checkFieldsValidity } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import moment from 'moment';
import timesheetComputedMixin from '@/components/timesheets/timesheets-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { TimeSheetType } from '@/enum/timeSheetType';
import ConstantValues from '@/plugins/constantValues';
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
export default {
  name: "TimeSheetEditListView",
  data: function data() {
    return {
      menuDate: false,
      TimeSheetType: TimeSheetType
    };
  },
  mixins: [timesheetComputedMixin],
  components: {
    HeaderPrimary: HeaderPrimary,
    Button: Button,
    VueCtkDateTimePicker: VueCtkDateTimePicker
  },
  computed: {
    isDisabledBtn: function isDisabledBtn() {
      if (checkFieldsValidity([this.timesheetDetails.date, this.timesheetDetails.startTime, this.timesheetDetails.endTime])) {
        return false;
      } else {
        return true;
      }
    },
    computedDateFormatted: function computedDateFormatted() {
      return this.timesheetDetails.date ? moment(this.timesheetDetails.date).format('DD MMM YYYY') : '';
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearTimeSheetFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    closeTimeSheetEditForm: function closeTimeSheetEditForm() {
      closeOverlay("editTimeSheetFormOverlay");
    },
    editTimeSheet: function editTimeSheet() {
      var _this = this;
      var timeSheetEndDate = this.getTimesheetEndDate(this.timesheetDetails.date, this.timesheetDetails.startTimeConverted, this.timesheetDetails.endTimeConverted);
      var startTimeConverted = moment.utc(moment(this.timesheetDetails.date + ' ' + this.timesheetDetails.startTimeConverted).utc()).format();
      var endTimeConverted = moment.utc(moment(timeSheetEndDate + ' ' + this.timesheetDetails.endTimeConverted).utc()).format();
      var timeSheetDto = {
        userId: this.timesheetDetails.userId,
        type: this.timesheetDetails.type,
        startTime: startTimeConverted,
        endTime: endTimeConverted,
        breakTimeInMinutes: this.timesheetDetails.breakTimeInMinutesConverted.hours * 60 + this.timesheetDetails.breakTimeInMinutesConverted.minute,
        travelTimeInMinutes: this.timesheetDetails.travelTimeInMinutesConverted.hours * 60 + this.timesheetDetails.travelTimeInMinutesConverted.minute,
        note: this.timesheetDetails.note
      };
      this.$store.dispatch('updateTimesheet', [timeSheetDto, this.timesheetDetails.id]).then(function (res) {
        _this.clearTimeSheetFilterOption();
        _this.resetPaging();
        if (res != undefined) {
          _this.$store.dispatch('setTimesheetsFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function (res) {
            _this.$store.dispatch('getTimesheetsList');
          });
        }
      });
    }
  })
};