import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "field-input-label" }, [
      _vm._v(_vm._s(_vm.$stringConstants("teamJobScheduleDateTitle"))),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.teamJobsLoader,
            expression: "!teamJobsLoader",
          },
        ],
        class:
          _vm.teamJobDetails &&
          (_vm.teamJobDetails.isApprovedForInvoicing ||
            !_vm.teamJobViewPreference.scheduledDate.isEditable)
            ? "unselectable"
            : "",
      },
      [
        _c("VueCtkDateTimePicker", {
          class: { sdDate: _vm.isDetailsView, sdDateAdd: !_vm.isDetailsView },
          attrs: {
            "aria-disabled":
              _vm.teamJobsLoader ||
              !_vm.teamJobViewPreference.scheduledDate.isEditable,
            disabled:
              _vm.teamJobsLoader ||
              !_vm.teamJobViewPreference.scheduledDate.isEditable,
            overlay: "",
            color: "#1d34ad",
            formatted: "DD-MM-YYYY HH:mm",
            format: "YYYY-MM-DD HH:mm:ss",
            "no-label": "",
          },
          on: {
            input: function ($event) {
              return _vm.onChangeScheduleDate()
            },
          },
          model: {
            value: _vm.teamJobScheduledDate,
            callback: function ($$v) {
              _vm.teamJobScheduledDate = $$v
            },
            expression: "teamJobScheduledDate",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.teamJobsLoader,
            expression: "teamJobsLoader",
          },
        ],
        staticClass: "mb-7",
      },
      [_c(VSkeletonLoader, { attrs: { height: "40", type: "card" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }