import $ from 'jquery';
import FarmersListView from './farmers-list.vue';
import inviteFarmer from './farmers-invite-form.vue';
import manageFarmer from './farmers-manage-form.vue';
import importFarmer from './farmers-import.vue';
import importIntegratedCustomer from './farmers-integrated-import.vue';
import { closeOverlay } from '@/utils/helpers';
import farmersComputedMixin from '@/components/farmers/farmers-computed';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';
import { DeleteItemDialog as InvitationDialog } from '@/components/shared/dialogs/index';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
export default {
  mixins: [farmersComputedMixin, requestsCancellationMixin, breadcrumbsMixin],
  components: {
    FarmersListView: FarmersListView,
    inviteFarmer: inviteFarmer,
    manageFarmer: manageFarmer,
    importFarmer: importFarmer,
    importIntegratedCustomer: importIntegratedCustomer,
    InvitationDialog: InvitationDialog
  },
  mounted: function mounted() {
    this.$store.state.dashboardTitle = this.$stringConstants('customersTitle');
    $('body').on('click', '#overlay', function () {
      closeOverlay("addFarmerFormOverlay");
      closeOverlay("editFarmerFormOverlay");
      closeOverlay("manageFarmerFormOverlay");
      closeOverlay("importCustomerFormOverlay");
    });
  },
  methods: {
    handleDialogCancel: function handleDialogCancel() {
      this.dialogInvitation = false;
    },
    handleDialogAction: function handleDialogAction() {
      if (this.dialogType == 'accept') {
        this.acceptConnection();
      } else {
        if (this.connectionRequestType == 'received') {
          this.declineConnection();
        } else {
          this.deleteSentConnection();
        }
      }
    }
  }
};