import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-8" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(VCol, { staticClass: "oy-tl", attrs: { cols: "8" } }, [
              _vm._v(_vm._s(_vm.$stringConstants("invoiceFlyoutTitle")) + " "),
              _vm.invoiceJobsForPreview && _vm.invoiceJobsForPreview.length != 0
                ? _c("span", [
                    _vm._v(
                      " (" + _vm._s(_vm.invoiceJobsForPreview.length) + ") "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    staticClass: "oy-cl",
                    attrs: { small: "", id: "closeProductAddForm" },
                    on: { click: _vm.closeJobsPreviewForm },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm.invoiceJobsPreviewLoader
      ? _c(
          "div",
          { staticStyle: { position: "absolute", top: "20%", left: "45%" } },
          [_c("ComponentLoader")],
          1
        )
      : _c("div", [
          _c(
            "div",
            [
              _c(
                VRow,
                {
                  staticClass: "px-6",
                  staticStyle: { "overflow-y": "scroll", height: "90vh" },
                },
                [
                  _c(VCol, { attrs: { cols: "12" } }, [
                    _vm.invoiceJobsForPreview.length != 0
                      ? _c(
                          "div",
                          { staticClass: "pt-4" },
                          _vm._l(
                            _vm.invoiceJobsForPreview,
                            function (job, jIndex) {
                              return _c(
                                "div",
                                {
                                  key: jIndex,
                                  staticClass: "pb-3 mb-6",
                                  staticStyle: {
                                    "border-bottom": "1px solid #e1e1e1",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("Button", {
                                        staticClass:
                                          "ib float-right btn-gr f-nn",
                                        attrs: {
                                          variant: "secondary",
                                          color: "primary",
                                          outlined: "",
                                          outlineColor: "primary",
                                          textValue:
                                            _vm.$stringConstants(
                                              "viewDetailsText"
                                            ),
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.routeToJobDetails(job.id)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "inv-fl-jbno jb-hd-ln mb-4",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$stringConstants("jobNo")
                                          ) +
                                          ". " +
                                          _vm._s(job.number) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("div", { staticClass: "ib pb-3 w-50" }, [
                                      _c("div", { staticClass: "inv-fl-sbt" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants("operation")
                                            ) +
                                            ": "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "inv-fl-mnt" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(job.operation.title) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "ib pb-3 ml-2 w-50" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "inv-fl-sbt" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$stringConstants(
                                                    "customer"
                                                  )
                                                ) +
                                                ": "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "inv-fl-mnt" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  job.customer.businessName
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", [
                                    _c("div", { staticClass: "ib pb-3 w-50" }, [
                                      _c("div", { staticClass: "inv-fl-sbt" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants("operators")
                                            ) +
                                            ": "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "inv-fl-mnt" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getAssignedOperator(job)
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "ib pb-3 w-50" }, [
                                      _c("div", { staticClass: "inv-fl-sbt" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "completedOn"
                                              )
                                            ) +
                                            ": "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "inv-fl-mnt" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getLocalDateFormat(
                                                job.completedOn
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }