import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    { attrs: { justify: "center" } },
    [
      _c("SetDefaultCardDialog", {
        attrs: { item: _vm.showDefaultCardConfirmDialog },
        on: {
          cancel: _vm.cancelDefaultCardChange,
          proceed: function ($event) {
            return _vm.confirmDefaultCardChange()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("subscriptionCardDefaultSelection")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "subscriptionCardDefaultSelectionTitle1"
                      )
                    ) +
                    " "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.cardBrandToSelect))]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "subscriptionCardDefaultSelectionTitle2"
                      )
                    ) +
                    " "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.cardLastFourToSelect))]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "subscriptionCardDefaultSelectionTitle3"
                      )
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }