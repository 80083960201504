import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var JobOperatorWorkStatusType;
(function (JobOperatorWorkStatusType) {
  JobOperatorWorkStatusType[JobOperatorWorkStatusType["Started"] = 0] = "Started";
  JobOperatorWorkStatusType[JobOperatorWorkStatusType["Paused"] = 1] = "Paused";
  JobOperatorWorkStatusType[JobOperatorWorkStatusType["Completed"] = 2] = "Completed";
})(JobOperatorWorkStatusType || (JobOperatorWorkStatusType = {}));
export var JobOperatorWorkStatusTypeLabel = new Map([[JobOperatorWorkStatusType.Started, 'Working'], [JobOperatorWorkStatusType.Paused, 'On a break'], [JobOperatorWorkStatusType.Completed, 'Finished working']]);