var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row no-gutters flex-nowrap" },
    [
      _c("div", { attrs: { id: "overlay" } }),
      _c("FieldList", { class: "" + (_vm.drawerIsOpen ? "col" : "col-12") }),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay position-relative fieldsBulkUploadFormOverlay",
          attrs: { id: "fieldsBulkUploadFormOverlay" },
        },
        [_c("FieldsBulkUploadForm")],
        1
      ),
      _c(
        "Drawer",
        {
          attrs: {
            closeCallback: function () {
              return
            },
          },
        },
        [
          _vm.showEditableFields
            ? _c("FieldForm")
            : _vm.fieldDetails.id
            ? _c("FieldDetails")
            : _vm._e(),
        ],
        1
      ),
      _c("BulkActionResponseDialog", {
        attrs: { hasBulkActionCallbackMethod: true },
        on: { "bulk-action-callback-method": _vm.resetFieldsPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }