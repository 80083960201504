var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "px-6 py-4" }, [
      _c("div", [_vm._v("Seed data")]),
      _c(
        "div",
        { staticClass: "py-3" },
        [
          _c("Button", {
            attrs: {
              textValue: "Seed vehicles",
              disabled: _vm.addVehicleLoader,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.seedVehicles($event)
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "py-3" }, [
        _vm._v(
          " " + _vm._s(_vm.vehiclesCounter) + " / " + _vm._s(_vm.seedSize) + " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "py-3" },
        [
          _c("Button", {
            attrs: {
              textValue: "Seed implements",
              disabled: _vm.addImplementLoader,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.seedImplements($event)
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "py-3" }, [
        _vm._v(
          " " +
            _vm._s(_vm.implementsCounter) +
            " / " +
            _vm._s(_vm.seedSize) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "py-3" },
        [
          _c("Button", {
            attrs: {
              textValue: "Seed products",
              disabled: _vm.addProductLoader,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.seedProducts($event)
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "py-3" }, [
        _vm._v(
          " " + _vm._s(_vm.productsCounter) + " / " + _vm._s(_vm.seedSize) + " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "py-3" },
        [
          _c("Button", {
            attrs: {
              textValue: "Seed operations",
              disabled: _vm.seedOperationsLoader,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.seedOperations($event)
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "py-3" }, [
        _vm._v(
          " " +
            _vm._s(_vm.operationsCounter) +
            " / " +
            _vm._s(_vm.seedSize) +
            " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }