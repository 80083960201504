export var ImportResourceType;
(function (ImportResourceType) {
  ImportResourceType[ImportResourceType["Vehicles"] = 0] = "Vehicles";
  ImportResourceType[ImportResourceType["Implements"] = 1] = "Implements";
  ImportResourceType[ImportResourceType["Products"] = 2] = "Products";
  ImportResourceType[ImportResourceType["Fields"] = 3] = "Fields";
  ImportResourceType[ImportResourceType["Operations"] = 4] = "Operations";
  ImportResourceType[ImportResourceType["Timesheets"] = 5] = "Timesheets";
  ImportResourceType[ImportResourceType["TeamMembers"] = 6] = "TeamMembers";
  ImportResourceType[ImportResourceType["Customers"] = 7] = "Customers";
  ImportResourceType[ImportResourceType["Jobs"] = 8] = "Jobs";
})(ImportResourceType || (ImportResourceType = {}));