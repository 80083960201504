import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ob-pad" }, [
    _c("div", [
      _c("div", { staticClass: "ob-maintitle" }, [
        _vm._v(_vm._s(_vm.$stringConstants("onboardInvoicingTitle"))),
      ]),
      _c("div", { staticClass: "ob-description pt-2 pb-6" }, [
        _vm._v(_vm._s(_vm.$stringConstants("onboardInvoicingSubTitle"))),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "ib width-perc-50",
        staticStyle: { "vertical-align": "top" },
      },
      [
        _c("div", { staticClass: "ob-subtitle" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("onboardDefaultInvoiceTitle")) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "mr-6", staticStyle: { "max-width": "425px" } },
          [
            _c(
              VRow,
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  VCol,
                  { attrs: { cols: "12" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("invoiceTermsHeading")) +
                          " "
                      ),
                    ]),
                    _c(VTextField, {
                      attrs: {
                        outlined: "",
                        dense: "",
                        type: "number",
                        required: "",
                      },
                      on: {
                        keydown: function ($event) {
                          return _vm.checkTermsNumber($event)
                        },
                      },
                      model: {
                        value:
                          _vm.userProfile.businessProfile.config
                            .invoiceTermsInDays,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.config,
                            "invoiceTermsInDays",
                            _vm._n($$v)
                          )
                        },
                        expression:
                          "userProfile.businessProfile.config.invoiceTermsInDays",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { attrs: { cols: "12" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("paymentInstruction")) +
                          " "
                      ),
                    ]),
                    _c(VTextarea, {
                      class: _vm.formFieldPb,
                      attrs: {
                        required: "",
                        "auto-grow": "",
                        outlined: "",
                        rows: "4",
                        "row-height": "30",
                        dense: "",
                      },
                      model: {
                        value:
                          _vm.userProfile.businessProfile.config
                            .invoiceInstructions,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.config,
                            "invoiceInstructions",
                            $$v
                          )
                        },
                        expression:
                          "userProfile.businessProfile.config.invoiceInstructions",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "ib width-perc-50" }, [
      _c(
        "div",
        { staticClass: "pt-10" },
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "py-0 pl-0 pr-2", attrs: { cols: "6" } },
                [
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("taxeTypeText"))),
                  ]),
                  _c(VCombobox, {
                    attrs: {
                      items: _vm.globalTaxesNameList,
                      "search-input": _vm.searchTaxType,
                      placeholder: _vm.$stringConstants(
                        "createNewTaxPlaceholder"
                      ),
                      outlined: "",
                      dense: "",
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.searchTaxType = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.searchTaxType = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c(VListItem, [
                              _c("span", { staticClass: "subheading" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$stringConstants("createNewTaxText")
                                  ) +
                                    ": " +
                                    _vm._s(_vm.searchTaxType) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value:
                        _vm.userProfile.businessProfile.config.salesTaxName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.userProfile.businessProfile.config,
                          "salesTaxName",
                          $$v
                        )
                      },
                      expression:
                        "userProfile.businessProfile.config.salesTaxName",
                    },
                  }),
                ],
                1
              ),
              _vm.userProfile.businessProfile.config.salesTaxName != null
                ? _c(
                    VCol,
                    { staticClass: "py-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.userProfile.businessProfile.config
                              .salesTaxName +
                              " " +
                              _vm.$stringConstants("RegNumText")
                          ) + " "
                        ),
                      ]),
                      _c(VTextField, {
                        class: _vm.formFieldPb,
                        attrs: {
                          placeholder: "eg. XVHJ67",
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value:
                            _vm.userProfile.businessProfile.config
                              .salesTaxRegistrationNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.userProfile.businessProfile.config,
                              "salesTaxRegistrationNumber",
                              $$v
                            )
                          },
                          expression:
                            "userProfile.businessProfile.config.salesTaxRegistrationNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.userProfile.businessProfile.config.salesTaxName != null
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "pt-0 px-0 min-hd" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$stringConstants("taxRateTitle")) + " "
                    ),
                  ]),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0 pl-0 pr-2", attrs: { cols: "6" } },
                        [
                          _c("div", { staticClass: "field-input-label" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("rateNameTitle"))
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        VCol,
                        { staticClass: "py-0 pl-2", attrs: { cols: "6" } },
                        [
                          _c("div", { staticClass: "field-input-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$stringConstants("ratePercentageTitle")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.userProfile.businessProfile.config.salesTaxRates,
                    function (tRate, idx) {
                      return _c(
                        "div",
                        { key: idx },
                        [
                          _c(
                            VRow,
                            { staticClass: "position-relative rate-list" },
                            [
                              _c(
                                VCol,
                                {
                                  staticClass: "py-0 pl-0 pr-2",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                    },
                                    model: {
                                      value: tRate.rateName,
                                      callback: function ($$v) {
                                        _vm.$set(tRate, "rateName", $$v)
                                      },
                                      expression: "tRate.rateName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  staticClass: "py-0 pl-2",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      type: "number",
                                      required: "",
                                    },
                                    model: {
                                      value: tRate.rateInPercentage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          tRate,
                                          "rateInPercentage",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "tRate.rateInPercentage",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "rm_tax_btn ib cursor-pointer",
                                  attrs: { title: "Remove tax rate" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeSelectedTaxRate(idx)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    { staticClass: "job-add-list-rm" },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _c(
                    VRow,
                    { staticClass: "pt-2" },
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0 pl-0 pr-2", attrs: { cols: "6" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              outlined: "",
                              dense: "",
                              placeholder: _vm.$stringConstants(
                                "rateNamePlaceholder"
                              ),
                              required: "",
                            },
                            model: {
                              value: _vm.tempRateName,
                              callback: function ($$v) {
                                _vm.tempRateName = $$v
                              },
                              expression: "tempRateName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "py-0 pl-2", attrs: { cols: "6" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              outlined: "",
                              dense: "",
                              placeholder: _vm.$stringConstants(
                                "ratePercentagePlaceholder"
                              ),
                              type: "number",
                              required: "",
                            },
                            model: {
                              value: _vm.tempRatePercentage,
                              callback: function ($$v) {
                                _vm.tempRatePercentage = _vm._n($$v)
                              },
                              expression: "tempRatePercentage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("Button", {
                        attrs: {
                          variant: "secondary",
                          outlined: "",
                          outlineColor: "gray",
                          color: "primary",
                          disabled: _vm.isValidTaxRate,
                          textValue: "Add",
                          leftIcon: "mdi-plus",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.appendTaxRate($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }