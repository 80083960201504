var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "drawer col-3 " + (_vm.drawerIsOpen ? "active" : "") },
    [_vm.drawerIsOpen ? _vm._t("default") : _vm._e()],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }