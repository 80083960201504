import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import { closeOverlay, checkFieldsValidity } from '@/utils/helpers';
import { mapMutations, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import contractorsComputedMixin from '@/components/contractors/contractors-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { UserRoleType } from '@/enum/userRoleType';
export default {
  name: 'ContractorAddForm',
  mixins: [validationMixin, contractorsComputedMixin],
  validations: {
    userInviteeEmail: {
      required: required,
      email: email
    }
  },
  components: {
    Button: Button
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      fieldsInvalid: true
    };
  },
  computed: {
    isFieldsInvalid: function isFieldsInvalid() {
      if (checkFieldsValidity([this.userInviteeEmail]) && !this.$v.userInviteeEmail.$invalid) {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted: function mounted() {
    this.setClearInviteeProfile();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapMutations({
    resetPaging: 'resetPaging',
    setClearFilterOptions: 'setClearFilterOptions',
    setClearInviteeProfile: 'setClearInviteeProfile'
  })), mapActions({
    sendInvite: 'sendUserInvite'
  })), {}, {
    proceedContractorInvitation: function proceedContractorInvitation() {
      var _this = this;
      var newContractorInviteeObj = {
        inviteeEmail: this.userInviteeEmail,
        inviteeRole: UserRoleType.Contractor
      };
      this.sendInvite(newContractorInviteeObj).then(function (res) {
        if (res) {
          if (_this.contractorFilterType == 1) {
            _this.refreshPendingContractorList();
          }
          closeOverlay('addContractorFormOverlay');
        }
      });
    },
    generateValidationErrors: function generateValidationErrors() {
      var errors = [];
      if (!this.$v.userInviteeEmail.$dirty) return errors;
      if (!this.$v.userInviteeEmail.required) {
        errors.push(this.$stringConstants('userRequiredEmailAddress'));
        this.fieldsInvalid = true;
      } else if (!this.$v.userInviteeEmail.email) {
        errors.push(this.$stringConstants('userFormatEmailAddress'));
        this.fieldsInvalid = true;
      } else {
        this.fieldsInvalid = false;
      }
      return errors;
    },
    closeContractorAddForm: function closeContractorAddForm() {
      closeOverlay("addContractorFormOverlay");
      this.resetContractorSearch();
      this.$v.$reset();
    }
  })
};