import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";
import moment from "moment";
export default {
  mixins: [subscriptionsComputedMixin],
  methods: {
    downloadInvoiceBill: function downloadInvoiceBill(downloadUrl) {
      window.open(downloadUrl, "_blank");
    },
    getLocalDate: function getLocalDate(invoiceDate) {
      return invoiceDate ? moment.utc(invoiceDate).local().format("DD MMM YYYY") : "";
    }
  },
  mounted: function mounted() {
    this.$store.dispatch("getInvoicesHistory");
  }
};