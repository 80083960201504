import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import LoginRegistrationWrapper from "@/components/layouts/login-registration-wrapper.vue";
import { routeLogin } from "@/utils/endpoints";
import resendCountdownMixin from "@/mixins/resend-countdown-mixin";
import { getCookie } from "@/utils/helpers";
import router from "@/router";
export default {
  name: "ResendEmailVerification",
  components: {
    Button: Button,
    LoginRegistrationWrapper: LoginRegistrationWrapper
  },
  mixins: [resendCountdownMixin],
  data: function data() {
    return {
      routeLogin: routeLogin,
      showUnverifiedEmailMessage: false
    };
  },
  computed: {
    userProfile: {
      get: function get() {
        return this.$store.getters.userProfile;
      },
      set: function set(value) {
        this.$store.state.usersModule.userProfile = value;
      }
    },
    sentCurrentRequest: {
      get: function get() {
        return this.$store.getters.sentCurrentRequest;
      },
      set: function set(value) {
        this.$store.state.sentCurrentRequest = value;
      }
    },
    emailAddress: {
      get: function get() {
        return this.$route.params.email || this.userProfile.email;
      }
    },
    userId: {
      get: function get() {
        return localStorage.getItem("userId");
      }
    }
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    this.resetResendStates();
    next();
  },
  beforeMount: function beforeMount() {
    this.sentCurrentRequest = false;
    if (this.userId) {
      this.getUserAccountDetails();
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.resendLoading = true;
      this.disableResendButton();
      this.$store.dispatch("resendEmailVerification").then(function (response) {
        if (response) {
          _this.checkCurrentRequestAndProceed();
        } else {
          _this.enableResendButton();
        }
        _this.resendLoading = false;
      });
    },
    logoutUser: function logoutUser() {
      this.$store.dispatch("logout");
    },
    getUserAccountDetails: function getUserAccountDetails() {
      var _this2 = this;
      return new Promise(function (resolve) {
        _this2.$store.dispatch("getUserProfileForOnboard").then(function (response) {
          if (response) {
            resolve(response);
          }
        });
      });
    },
    checkIfEmailVerifiedAndRedirectToMainPage: function checkIfEmailVerifiedAndRedirectToMainPage() {
      var _this3 = this;
      if (getCookie("tkn")) {
        this.getUserAccountDetails().then(function (response) {
          if (response.emailConfirmed) {
            _this3.$store.dispatch("navigateFlows", response);
          } else {
            var timeout = _this3.showUnverifiedEmailMessageAndSetInterval();
            clearInterval(timeout);
          }
        });
      } else {
        this.$store.dispatch("logout");
        router.push(routeLogin);
      }
    },
    showUnverifiedEmailMessageAndSetInterval: function showUnverifiedEmailMessageAndSetInterval() {
      var _this4 = this;
      this.showUnverifiedEmailMessage = true;
      return new Promise(function (resolve) {
        var timeoutId = setTimeout(function () {
          _this4.showUnverifiedEmailMessage = false;
          resolve(timeoutId);
        }, 5000);
      });
    }
  }
};