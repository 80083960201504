import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "pb-6" }, [
      _c("div", { staticClass: "su-buisiness-table-title" }, [
        _vm._v(" " + _vm._s(_vm.tableName) + " "),
      ]),
      _c(
        "div",
        { staticClass: "su-buisiness-details-header pl-6 py-3" },
        _vm._l(_vm.headerData, function (data, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "ib su-buisiness-details-header-st su-wbt",
              style: { width: parseInt(100 / _vm.headerData.length) + "%" },
            },
            [_vm._v(" " + _vm._s(data) + " ")]
          )
        }),
        0
      ),
      _c("div", { staticClass: "su-buisiness-details-container pl-6" }, [
        _vm.listLoading
          ? _c(
              "div",
              _vm._l(3, function (count, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "ib py-4 pr-4 su-buisiness-details-header-st su-wbt",
                    style: { width: "33%" },
                  },
                  [
                    _c(VSkeletonLoader, {
                      attrs: { width: "120", height: "30", type: "card" },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _c(
              "div",
              _vm._l(_vm.rowData, function (data, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "ib py-4 pr-4 su-buisiness-details-header-st su-wbt",
                    style: { width: parseInt(100 / _vm.rowData.length) + "%" },
                  },
                  [_vm._v(" " + _vm._s(data) + " ")]
                )
              }),
              0
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }