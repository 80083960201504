import { SubHeaderPrimary } from "@/components/shared/headers/index";
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsAddressesFlyoutAddressInstructions from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-addresses-instructions.vue';
import TeamJobsAddressesFlyoutAddressDropPinMap from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-drop-pin-map.vue';
import TeamJobsAddressesFlyoutAddressDropPinCoordinates from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-drop-pin-coordinates.vue';
export default {
  name: "TeamJobsAddressesDropPin",
  mixins: [TeamJobsComputed],
  components: {
    SubHeaderPrimary: SubHeaderPrimary,
    TeamJobsAddressesFlyoutAddressInstructions: TeamJobsAddressesFlyoutAddressInstructions,
    TeamJobsAddressesFlyoutAddressDropPinMap: TeamJobsAddressesFlyoutAddressDropPinMap,
    TeamJobsAddressesFlyoutAddressDropPinCoordinates: TeamJobsAddressesFlyoutAddressDropPinCoordinates
  }
};