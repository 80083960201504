import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "pb-6" },
        [
          _c("SubHeaderPrimary", {
            attrs: {
              textValue: _vm.$stringConstants("teamJobEnterAddressText"),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("div", { staticClass: "field-input-label" }, [
            _vm._v(" " + _vm._s(_vm.$stringConstants("addressesLine1")) + "* "),
          ]),
          _c(VTextField, {
            class: _vm.formFieldPb,
            attrs: { outlined: "", dense: "" },
            model: {
              value: _vm.teamJobCustomAddress.addressLine1,
              callback: function ($$v) {
                _vm.$set(_vm.teamJobCustomAddress, "addressLine1", $$v)
              },
              expression: "teamJobCustomAddress.addressLine1",
            },
          }),
          _c("div", { staticClass: "field-input-label" }, [
            _vm._v(" " + _vm._s(_vm.$stringConstants("addressesLine2")) + " "),
          ]),
          _c(VTextField, {
            class: _vm.formFieldPb,
            attrs: { outlined: "", dense: "" },
            model: {
              value: _vm.teamJobCustomAddress.addressLine2,
              callback: function ($$v) {
                _vm.$set(_vm.teamJobCustomAddress, "addressLine2", $$v)
              },
              expression: "teamJobCustomAddress.addressLine2",
            },
          }),
          _c(
            VRow,
            { attrs: { "no-gutters": "" } },
            [
              _c(
                VCol,
                { staticClass: "pr-2", attrs: { cols: "6" } },
                [
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(" " + _vm._s(_vm.$stringConstants("city")) + " "),
                  ]),
                  _c(VTextField, {
                    class: _vm.formFieldPb,
                    attrs: { outlined: "", dense: "" },
                    model: {
                      value: _vm.teamJobCustomAddress.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.teamJobCustomAddress, "city", $$v)
                      },
                      expression: "teamJobCustomAddress.city",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "pl-2", attrs: { cols: "6" } },
                [
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$stringConstants("stateAndCounty")) + " "
                    ),
                  ]),
                  _c(VTextField, {
                    class: _vm.formFieldPb,
                    attrs: { outlined: "", dense: "" },
                    model: {
                      value: _vm.teamJobCustomAddress.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.teamJobCustomAddress, "state", $$v)
                      },
                      expression: "teamJobCustomAddress.state",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { "no-gutters": "" } },
            [
              _c(
                VCol,
                { staticClass: "pr-2", attrs: { cols: "6" } },
                [
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(" " + _vm._s(_vm.$stringConstants("country")) + " "),
                  ]),
                  _c(VAutocomplete, {
                    attrs: {
                      items: _vm.globalCountriesList,
                      "item-text": "name",
                      "item-value": "name",
                      outlined: "",
                      dense: "",
                      flat: "",
                      attach: "",
                      solo: "",
                    },
                    model: {
                      value: _vm.teamJobCustomAddress.country,
                      callback: function ($$v) {
                        _vm.$set(_vm.teamJobCustomAddress, "country", $$v)
                      },
                      expression: "teamJobCustomAddress.country",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "pl-2", attrs: { cols: "6" } },
                [
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$stringConstants("postalCode")) + " "
                    ),
                  ]),
                  _c(VTextField, {
                    class: _vm.formFieldPb,
                    attrs: { outlined: "", dense: "" },
                    model: {
                      value: _vm.teamJobCustomAddress.postalCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.teamJobCustomAddress, "postalCode", $$v)
                      },
                      expression: "teamJobCustomAddress.postalCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("TeamJobsAddressesFlyoutAddressInstructions"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }