import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';
export default {
  name: 'MapDialogBox',
  props: ['item', 'btnText'],
  components: {
    SecondaryButton: SecondaryButton
  },
  computed: {
    itemToView: {
      get: function get() {
        return this.item;
      },
      set: function set(newVal) {
        this.$emit('change', newVal);
      }
    }
  }
};