import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldComponentDisplay(_vm.componentsPermissions["membersList"])
    ? _c(
        "div",
        { staticClass: "list-container" },
        [
          _c("Snackbar", {
            attrs: {
              deleteApiType: "teamMembers",
              status: _vm.memberFilterType,
              allItemsSelected: _vm.allTeamMembersSelected,
              someItemsSelected: _vm.someTeamMembersSelected,
              showArchiveOrDelete: true,
              showRoleChange: true,
              loaderState: _vm.teamMembersLoader,
            },
            on: { "change-role-callback": _vm.changeRole },
          }),
          _c("ActionListingDialog", {
            attrs: {
              itemName: "team members",
              onClickEvent: "proceedToActionOnTeamMembers",
              itemsPreference: this.memberFilterType,
            },
          }),
          _c("ItemDialog", {
            attrs: { item: _vm.dialogTeamMember },
            on: {
              cancel: function ($event) {
                return _vm.cancelActionOnTeamMember(_vm.actionType)
              },
              proceed: function ($event) {
                return _vm.proceedActionOnTeamMember(_vm.actionType)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.fnGetHeaderString(_vm.actionType)) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.fnGetBodyString(_vm.actionType)) + " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3378694121
            ),
          }),
          _c(
            VRow,
            {
              staticClass: "flex-grow-0 px-9 pt-4",
              attrs: { "no-gutters": "", cols: "12" },
            },
            [
              _c(
                VCol,
                { attrs: { cols: "6" } },
                [
                  _c(
                    VRow,
                    { staticStyle: { "max-width": "500px" } },
                    [
                      _c(
                        VCol,
                        { staticClass: "px-0", attrs: { cols: "9" } },
                        [
                          _c(VTextField, {
                            staticClass: "rd-rt-no agd-txtfield mr-4",
                            attrs: {
                              placeholder: _vm.$stringConstants(
                                "teamMemberSearchPlaceholder"
                              ),
                              outlined: "",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.applySearchTimer(
                                  _vm.applySearchTeamMembersFilter
                                )
                              },
                            },
                            model: {
                              value: _vm.teamMemberSearchText,
                              callback: function ($$v) {
                                _vm.teamMemberSearchText = $$v
                              },
                              expression: "teamMemberSearchText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "px-0", attrs: { cols: "3" } },
                        [
                          _c(VSelect, {
                            staticClass: "rd-lf-no agd-dropdown",
                            attrs: {
                              items: _vm.userStatusFilterTypeList,
                              "item-value": "value",
                              "item-text": "title",
                              placeholder:
                                _vm.$stringConstants("statusPlaceholder"),
                              dense: "",
                              outlined: "",
                              "append-icon": "mdi-chevron-down",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getMembersListByStatus()
                              },
                            },
                            model: {
                              value: _vm.memberFilterType,
                              callback: function ($$v) {
                                _vm.memberFilterType = $$v
                              },
                              expression: "memberFilterType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "6" } },
                [
                  _c(
                    VRow,
                    { staticClass: "text-right" },
                    [
                      _c(
                        VCol,
                        { staticClass: "px-0", attrs: { cols: "12" } },
                        [
                          _vm.shouldComponentDisplay(
                            _vm.componentsPermissions["invitationSend"]
                          )
                            ? _c("Button", {
                                attrs: {
                                  variant: "secondary",
                                  color: "primary",
                                  outlined: "",
                                  outlineColor: "primary",
                                  textValue: "Add member",
                                  leftIcon: "mdi-plus",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openAddTeamMemberForm()
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.memberFilterType === 0 || _vm.memberFilterType === 1
            ? _c("List", {
                key: "active-team-members",
                attrs: { listItems: _vm.teamMembersList, containsAction: "" },
                on: {
                  setFilterOptionsAndGetListData:
                    _vm.setFilterOptionsAndGetTeamMembers,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "list-heading",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "list-check width-perc-27" },
                            [
                              _c(
                                "div",
                                { staticClass: "ib" },
                                [
                                  _c(VCheckbox, {
                                    attrs: {
                                      indeterminate:
                                        _vm.someTeamMembersSelected,
                                      "input-value": _vm.allTeamMembersSelected,
                                    },
                                    on: { change: _vm.selectAllTeamMembers },
                                    model: {
                                      value: _vm.isChecked,
                                      callback: function ($$v) {
                                        _vm.isChecked = $$v
                                      },
                                      expression: "isChecked",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ib v-a-t pl-2" }, [
                                _vm._v(
                                  _vm._s(_vm.$stringConstants("businessName"))
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "width-perc-16" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("contact"))),
                          ]),
                          _c("div", { staticClass: "width-perc-16 pr-4" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("secondaryContact"))
                            ),
                          ]),
                          _c("div", { staticClass: "width-perc-16" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("workingHoursText"))
                            ),
                          ]),
                          _c("div", { staticClass: "width-perc-16" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("roleText"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "width-perc-9 text-right" },
                            [_vm._v(_vm._s(_vm.$stringConstants("action")))]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "list-item",
                      fn: function (ref) {
                        var listItem = ref.listItem
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "width-perc-27 center-text-y list-check",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ib" },
                                [
                                  _c(VCheckbox, {
                                    attrs: { value: listItem.id, multiple: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: _vm.selectedItems,
                                      callback: function ($$v) {
                                        _vm.selectedItems = $$v
                                      },
                                      expression: "selectedItems",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ib v-a-t pl-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "fw-6 d-flex align-center" },
                                  [
                                    _c("Avatar", {
                                      attrs: {
                                        initials:
                                          listItem.firstName +
                                          listItem.lastName,
                                        width: "28px",
                                        height: "28px",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "d-inline-block pl-1 pr-8",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(listItem.firstName) +
                                            " " +
                                            _vm._s(
                                              listItem.lastName != null
                                                ? listItem.lastName
                                                : ""
                                            )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "width-perc-16 center-text-y" },
                            [
                              _c("div", { staticClass: "br-word" }, [
                                _vm._v(_vm._s(listItem.email)),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "width-perc-16 pr-4" }, [
                            _c("div", [
                              _vm._v(_vm._s(listItem.primaryContactNumber)),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "width-perc-16 center-text-y" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getTimeInitials(
                                    listItem.settings.workingStartTime
                                  )
                                ) +
                                  " - " +
                                  _vm._s(
                                    _vm.getTimeInitials(
                                      listItem.settings.workingEndTime
                                    )
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "width-perc-16 center-text-y" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.showTeamMemberRoleFromRolesList(
                                    listItem.roles
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "width-perc-9 text-center pt-3 position-relative",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pt-1" },
                                [
                                  _c(
                                    VMenu,
                                    {
                                      attrs: {
                                        bottom: "",
                                        "min-width": "120",
                                        left: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "triple-dot-menu",
                                                        attrs: { icon: "" },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(VIcon, [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        VList,
                                        [
                                          _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openEditTeamMemberForm(
                                                    listItem.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-pencil")]
                                                  ),
                                                  _vm._v("Edit "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.memberFilterType == 0
                                            ? _c(
                                                VListItem,
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.promtArchiveTeamMember(
                                                        listItem.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VListItemTitle,
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "icon-size pr-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-archive-arrow-down"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("Archive "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.memberFilterType == 1
                                            ? _c(
                                                VListItem,
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.promtRestoreTeamMember(
                                                        listItem.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VListItemTitle,
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "icon-size pr-2",
                                                        },
                                                        [_vm._v("mdi-restore")]
                                                      ),
                                                      _vm._v("Restore "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "zero-state",
                      fn: function () {
                        return [
                          _vm.memberFilterType === 0
                            ? _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "mb-0 font-weight-bold pb-3" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "teamMembersListZeroStateTitle"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$stringConstants(
                                        "teamMembersListZeroStateSubtitle"
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm.memberFilterType === 1
                            ? _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "mb-0 font-weight-bold pb-3" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "teamMemberNotArchiveText"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3097715428
                ),
              })
            : _vm.memberFilterType === 2
            ? _c("List", {
                key: "pending-team-members",
                attrs: {
                  listItems: _vm.pendingTeamMemberList,
                  containsAction: "",
                },
                on: {
                  setFilterOptionsAndGetListData:
                    _vm.setFilterOptionsAndGetTeamMembers,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "list-heading",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "width-perc-20" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("inviteeName"))),
                          ]),
                          _c("div", { staticClass: "width-perc-20" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("inviteeEmail"))
                            ),
                          ]),
                          _c("div", { staticClass: "width-perc-20" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("inviteeOn"))),
                          ]),
                          _c("div", { staticClass: "width-perc-20" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("expiredOn"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "width-perc-20 text-right" },
                            [_vm._v(_vm._s(_vm.$stringConstants("action")))]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "list-item",
                      fn: function (ref) {
                        var listItem = ref.listItem
                        return [
                          _c(
                            "div",
                            { staticClass: "width-perc-20" },
                            [
                              _c("Avatar", {
                                staticClass: "mr-2",
                                attrs: { initials: listItem.inviteeFirstName },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    listItem.inviteeFirstName +
                                      " " +
                                      listItem.inviteeLastName
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "width-perc-20" }, [
                            _c("div", { staticClass: "tr-6p" }, [
                              _vm._v(_vm._s(listItem.inviteeEmail)),
                            ]),
                          ]),
                          _c("div", { staticClass: "width-perc-20" }, [
                            _c("div", { staticClass: "tr-6p" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getDateTimeFormat(listItem.timestamp)
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "width-perc-20" }, [
                            _c("div", { staticClass: "tr-6p" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateTimeFormat(listItem.expiry)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "width-perc-20 text-right pt-3 position-relative",
                            },
                            [
                              _c(
                                VMenu,
                                {
                                  attrs: {
                                    bottom: "",
                                    "min-width": "120",
                                    left: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "triple-dot-menu",
                                                    attrs: { icon: "" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    VList,
                                    [
                                      _c(
                                        VListItem,
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteInviteConnection(
                                                listItem.code
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            VListItemTitle,
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass: "icon-size pr-2",
                                                },
                                                [_vm._v("mdi-delete")]
                                              ),
                                              _vm._v("Delete "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "zero-state",
                      fn: function () {
                        return [
                          _c(
                            "p",
                            { staticClass: "mb-0 font-weight-bold pb-3" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$stringConstants("emptyInvitaionList")
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1929400189
                ),
              })
            : _vm._e(),
          _c("ActionItemDialog", {
            attrs: { item: _vm.archiveDialog },
            on: {
              cancel: _vm.cancelTeamMemberArchiving,
              proceed: _vm.proceedTeamMemberArchiving,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("teamMemberArchivePromtTitle")
                          ) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants(
                              "teamMemberArchivePromtSubtitle"
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3503699522
            ),
          }),
          _c("ActionItemDialog", {
            attrs: { item: _vm.restoreDialog },
            on: {
              cancel: _vm.cancelTeamMemberArchiving,
              proceed: _vm.proceedTeamMemberRestoration,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("teamMemberRestorePromtTitle")
                          ) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants(
                              "teamMemberRestorePromtSubtitle"
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.$stringConstants("teamMemberRestoreBilling")
                          ) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              782884124
            ),
          }),
          _c("ChangeRoleDialog", {
            attrs: {
              item: _vm.showChangeRole,
              firstActionText: "Cancel",
              secondActionText: "Update",
            },
            on: {
              cancel: _vm.cancelChangeRoleDialog,
              proceed: _vm.changeRoleAsync,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [_vm._v(" Change role ")]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("span", { staticStyle: { "line-height": "20px" } }, [
                        _vm._v(
                          " Please select a new role for " +
                            _vm._s(_vm.selectedItems.length) +
                            " selected team members. "
                        ),
                      ]),
                      _c("div", { staticClass: "field-input-label mt-4" }, [
                        _vm._v(" New role "),
                      ]),
                      _c(VSelect, {
                        attrs: {
                          items: _vm.enumMemberRoles,
                          placeholder: _vm.$stringConstants(
                            "teamMemberRoleLabel"
                          ),
                          "item-text": "name",
                          "item-value": "type",
                          outlined: "",
                          dense: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.showTeamMemberRoleFromType(
                                          item.type
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.showTeamMemberRoleFromType(
                                          item.type
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1063325596
                        ),
                        model: {
                          value: _vm.selectedTeamMemberRole,
                          callback: function ($$v) {
                            _vm.selectedTeamMemberRole = $$v
                          },
                          expression: "selectedTeamMemberRole",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2504561851
            ),
          }),
        ],
        1
      )
    : _c("div", { staticClass: "pt-4 pl-10" }, [
        _vm._v(
          " " + _vm._s(_vm.$stringConstants("teamMemberListDisabled")) + " "
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }