import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VSnackbar,
    _vm._b(
      {
        staticClass: "flyout-snackbar",
        attrs: { right: "", timeout: _vm.timeout },
        scopedSlots: _vm._u(
          [
            {
              key: "action",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _vm._t("action", [
                    _vm.showArchiveOrDelete
                      ? _c(
                          "span",
                          [
                            _vm.status == 0 &&
                            _vm.showUpdateButton &&
                            _vm.integrationType !== null
                              ? _c(
                                  VBtn,
                                  _vm._b(
                                    {
                                      staticClass: "mr-10",
                                      attrs: {
                                        color: "#fff",
                                        text: "",
                                        disabled:
                                          _vm.loaderState ||
                                          _vm.bulkActionLoader,
                                      },
                                      on: { click: _vm.updateItems },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [
                                    _vm.loaderState
                                      ? _c(
                                          VIcon,
                                          { staticClass: "rotating pr-2" },
                                          [_vm._v(" mdi-loading ")]
                                        )
                                      : _c(VIcon, { attrs: { left: "" } }, [
                                          _vm._v(" mdi-account "),
                                        ]),
                                    _vm._v(
                                      " Update from " +
                                        _vm._s(_vm.integrationType) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.status == 0 && _vm.showRoleChange
                              ? _c(
                                  VBtn,
                                  _vm._b(
                                    {
                                      staticClass: "mr-10",
                                      attrs: {
                                        color: "#fff",
                                        text: "",
                                        disabled:
                                          _vm.loaderState ||
                                          _vm.bulkActionLoader,
                                      },
                                      on: { click: _vm.changeRole },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [
                                    _vm.loaderState
                                      ? _c(
                                          VIcon,
                                          { staticClass: "rotating pr-2" },
                                          [_vm._v(" mdi-loading ")]
                                        )
                                      : _c(VIcon, { attrs: { left: "" } }, [
                                          _vm._v(" mdi-account "),
                                        ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("roleChangeText")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.status == 1 && !_vm.showOnlyDelete
                              ? _c(
                                  VBtn,
                                  _vm._b(
                                    {
                                      staticClass: "mr-10",
                                      attrs: {
                                        color: "#fff",
                                        text: "",
                                        disabled:
                                          _vm.loaderState ||
                                          _vm.bulkActionLoader,
                                      },
                                      on: { click: _vm.restoreSelectedRows },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [
                                    _vm.bulkActionLoader &&
                                    _vm.listActionType == _vm.listAction.Restore
                                      ? _c(
                                          VIcon,
                                          { staticClass: "rotating pr-2" },
                                          [_vm._v(" mdi-loading ")]
                                        )
                                      : _c(VIcon, { attrs: { left: "" } }, [
                                          _vm._v(" mdi-restore "),
                                        ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("restoreText")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm.status == 0 && !_vm.showOnlyDelete
                              ? _c(
                                  VBtn,
                                  _vm._b(
                                    {
                                      staticClass: "mr-10",
                                      attrs: {
                                        color: "#fff",
                                        text: "",
                                        disabled:
                                          _vm.loaderState ||
                                          _vm.bulkActionLoader,
                                      },
                                      on: { click: _vm.archiveSelectedRows },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [
                                    _vm.bulkActionLoader &&
                                    (_vm.listActionType ==
                                      _vm.listAction.Delete ||
                                      _vm.listActionType ==
                                        _vm.listAction.Archive)
                                      ? _c(
                                          VIcon,
                                          { staticClass: "rotating pr-2" },
                                          [_vm._v(" mdi-loading ")]
                                        )
                                      : _c(VIcon, { attrs: { left: "" } }, [
                                          _vm._v(" mdi-archive-arrow-down "),
                                        ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "archiveOrDeleteText"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _vm.status == 1 && !_vm.showOnlyDelete
                              ? _c(
                                  VBtn,
                                  _vm._b(
                                    {
                                      staticClass: "mr-10",
                                      attrs: {
                                        color: "#fff",
                                        text: "",
                                        disabled:
                                          _vm.loaderState ||
                                          _vm.bulkActionLoader,
                                      },
                                      on: { click: _vm.restoreSelectedRows },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [
                                    _vm.bulkActionLoader &&
                                    _vm.listActionType == _vm.listAction.Restore
                                      ? _c(
                                          VIcon,
                                          { staticClass: "rotating pr-2" },
                                          [_vm._v(" mdi-loading ")]
                                        )
                                      : _c(VIcon, { attrs: { left: "" } }, [
                                          _vm._v(" mdi-restore "),
                                        ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("restoreText")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm.status == 0 && !_vm.showOnlyDelete
                              ? _c(
                                  VBtn,
                                  _vm._b(
                                    {
                                      staticClass: "mr-10",
                                      attrs: {
                                        color: "#fff",
                                        text: "",
                                        disabled:
                                          _vm.loaderState ||
                                          _vm.bulkActionLoader,
                                      },
                                      on: { click: _vm.archiveSelectedRows },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [
                                    _vm.bulkActionLoader &&
                                    _vm.listActionType == _vm.listAction.Archive
                                      ? _c(
                                          VIcon,
                                          { staticClass: "rotating pr-2" },
                                          [_vm._v(" mdi-loading ")]
                                        )
                                      : _c(VIcon, { attrs: { left: "" } }, [
                                          _vm._v(" mdi-archive-arrow-down "),
                                        ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("archiveText")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              VBtn,
                              _vm._b(
                                {
                                  staticClass: "mr-10",
                                  attrs: {
                                    color: "#fff",
                                    text: "",
                                    disabled:
                                      _vm.loaderState || _vm.bulkActionLoader,
                                  },
                                  on: { click: _vm.deleteSectedRows },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              [
                                _vm.bulkActionLoader &&
                                _vm.listActionType == _vm.listAction.Delete
                                  ? _c(
                                      VIcon,
                                      { staticClass: "rotating pr-2" },
                                      [_vm._v(" mdi-loading ")]
                                    )
                                  : _c(VIcon, { attrs: { left: "" } }, [
                                      _vm._v(" mdi-archive "),
                                    ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$stringConstants("listItemDelete")
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.snackbarShow,
          callback: function ($$v) {
            _vm.snackbarShow = $$v
          },
          expression: "snackbarShow",
        },
      },
      "v-snackbar",
      _vm.$attrs,
      false
    ),
    [
      _c("div", { staticClass: "fn-lg font-weight-medium" }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }