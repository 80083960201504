import { SubHeaderPrimary } from "@/components/shared/headers/index";
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { formFieldPb } from "@/utils/uiconstants";
import TeamJobsAddressesFlyoutAddressInstructions from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-addresses-instructions.vue';
export default {
  name: "TeamJobsCustomAddress",
  mixins: [TeamJobsComputed],
  data: function data() {
    return {
      formFieldPb: formFieldPb
    };
  },
  components: {
    SubHeaderPrimary: SubHeaderPrimary,
    TeamJobsAddressesFlyoutAddressInstructions: TeamJobsAddressesFlyoutAddressInstructions
  }
};