import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "py-8" }, [
      _c("div", { staticClass: "pb-10" }, [
        _c("div", { staticClass: "tj-cp-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("teamJobExtraInformationTitle")) +
              " "
          ),
        ]),
        _c("div", { staticClass: "tj-cp-subtitle" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("teamJobExtraInformationSubTitle")) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            VRow,
            {
              staticClass: "jb-dt-hd jb-operators-list-head",
              attrs: { col: "12" },
            },
            [
              _c("div", { staticClass: "width-perc-55" }, [
                _vm._v(" " + _vm._s(_vm.$stringConstants("title")) + " "),
              ]),
              _c("div", { staticClass: "width-perc-20" }, [
                _vm._v(" " + _vm._s(_vm.$stringConstants("value")) + " "),
              ]),
              _c("div", { staticClass: "width-perc-20 pl-1" }, [
                _vm._v(" " + _vm._s(_vm.$stringConstants("unit")) + " "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
        },
        _vm._l(_vm.teamJobMetaDataList, function (loadInfo, j) {
          return _c(
            "div",
            {
              key: j,
              staticClass: "px-7 py-3",
              staticStyle: {
                border: "0.5px solid #eee",
                "border-top": "hidden",
              },
            },
            [
              _c(VRow, { attrs: { col: "12" } }, [
                _c("div", { staticClass: "width-perc-55" }, [
                  _c("div", { staticClass: "section-sub-title pt-3 fw-6" }, [
                    _vm._v(_vm._s(loadInfo.title)),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-20 pr-1",
                    class: {
                      "red-border":
                        loadInfo.unit !== "none" && isNaN(loadInfo.value),
                    },
                  },
                  [
                    _c(VTextField, {
                      staticClass: "hide_input_details slot-m0",
                      class: { "light-input": _vm.isDetailsView },
                      attrs: {
                        outlined: "",
                        placeholder: _vm.$stringConstants("value"),
                        disabled:
                          !_vm.teamJobViewPreference.metaData.isEditable,
                        type: loadInfo.unit === "none" ? "text" : "number",
                        height: "48",
                        dense: "",
                        required: "",
                      },
                      on: { input: _vm.onChangeInMetaData },
                      model: {
                        value: loadInfo.value,
                        callback: function ($$v) {
                          _vm.$set(loadInfo, "value", $$v)
                        },
                        expression: "loadInfo.value",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "width-perc-20 pl-1" },
                  [
                    _c(VSelect, {
                      staticClass: "hide_input_details slot-m0",
                      class: { "dark-input": _vm.isDetailsView },
                      attrs: {
                        height: "48",
                        items: _vm.enumListLoadInfoUnits,
                        disabled:
                          !_vm.teamJobViewPreference.metaData.isEditable,
                        placeholder: _vm.$stringConstants("unit"),
                        "item-value": "unit",
                        "item-text": "title",
                        outlined: "",
                        attach: "",
                        dense: "",
                      },
                      on: { change: _vm.onChangeInMetaData },
                      model: {
                        value: loadInfo.unit,
                        callback: function ($$v) {
                          _vm.$set(loadInfo, "unit", $$v)
                        },
                        expression: "loadInfo.unit",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "width-perc-5 text-right" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.teamJobViewPreference.metaData.isEditable,
                          expression:
                            "teamJobViewPreference.metaData.isEditable",
                        },
                      ],
                      staticClass: "pt-3 ib cursor-pointer",
                      attrs: { title: "Remove extra information" },
                      on: {
                        click: function ($event) {
                          return _vm.removeSelectedMetaDataFromList(
                            j,
                            _vm.teamJobMetaDataList
                          )
                        },
                      },
                    },
                    [
                      _c(VIcon, { staticClass: "job-add-list-rm" }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.teamJobsLoader,
              expression: "teamJobsLoader",
            },
          ],
        },
        _vm._l(2, function (loadInfo, j) {
          return _c(
            "div",
            {
              key: j,
              staticClass: "px-7 py-3",
              staticStyle: {
                border: "0.5px solid #eee",
                "border-top": "hidden",
              },
            },
            [
              _c(VRow, { attrs: { col: "12" } }, [
                _c("div", { staticClass: "width-perc-55" }, [
                  _c(
                    "div",
                    { staticClass: "section-sub-title pt-3 fw-6" },
                    [
                      _c(VSkeletonLoader, {
                        attrs: { width: "100", height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "width-perc-20 pr-1" },
                  [
                    _c(VSkeletonLoader, {
                      attrs: { width: "100", height: "30", type: "card" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "width-perc-20 pl-1" },
                  [
                    _c(VSkeletonLoader, {
                      attrs: { width: "100", height: "30", type: "card" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "width-perc-5 text-right" }),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
          staticClass: "px-6 py-4 bt-0",
          staticStyle: { border: "0.5px solid #eee" },
        },
        [
          _vm.showAddMetaDataTeamJob
            ? _c(
                "div",
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "8 pt-0 pb-0 pl-0" } },
                        [
                          _c("div", { staticClass: "field-input-label" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$stringConstants("loadName")) +
                                " "
                            ),
                          ]),
                          _c(VTextField, {
                            attrs: {
                              outlined: "",
                              placeholder: _vm.$stringConstants("loadName"),
                              dense: "",
                              required: "",
                            },
                            model: {
                              value: _vm.metaDataTitleTeamJob,
                              callback: function ($$v) {
                                _vm.metaDataTitleTeamJob = $$v
                              },
                              expression: "metaDataTitleTeamJob",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "3 pt-0 pb-0 pl-0 pr-0" } },
                        [
                          _c("div", { staticClass: "field-input-label" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$stringConstants("unit")) + " "
                            ),
                          ]),
                          _c(VSelect, {
                            staticClass: "slot-m0",
                            attrs: {
                              items: _vm.enumListLoadInfoUnits,
                              placeholder: _vm.$stringConstants("unit"),
                              "item-value": "value",
                              "item-text": "title",
                              outlined: "",
                              attach: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.metaDataUnitForTeamJob,
                              callback: function ($$v) {
                                _vm.metaDataUnitForTeamJob = $$v
                              },
                              expression: "metaDataUnitForTeamJob",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("Button", {
                        attrs: {
                          variant: "list",
                          color: "primary",
                          text: "",
                          textValue: "Save",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.addNewMetaDataInList()
                          },
                        },
                      }),
                      _c("Button", {
                        staticClass: " f-nn ml-2",
                        attrs: {
                          variant: "list",
                          text: "",
                          textValue: _vm.$stringConstants("cancel"),
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.cancelAddNewMetaData()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showBtnAddMetaDataTeamJob
            ? _c("div", [
                _c("div", { staticClass: "ib py-3 width-perc-200" }, [
                  _c(
                    "div",
                    {
                      staticClass: "cursor-pointer pr-8",
                      on: { click: _vm.showAddMetaDataComponent },
                    },
                    [
                      _vm.teamJobMetaDataList.length == 0 &&
                      _vm.teamJobDetails &&
                      _vm.teamJobDetails.isApprovedForInvoicing
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants(
                                    "teamJobZeroMetaDataText"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", { staticClass: "jb-add-ln-btn" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants(
                                    "teamJobAddExtraInformationText"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }