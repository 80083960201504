import { User } from '@/data/models';
export var state = {
  contactLoader: false,
  contactDetailsLoader: false,
  contactProfile: new User(),
  contactFieldList: [],
  contactOnGoingJobList: [],
  contactFarmAddresses: []
};
export var getters = {
  contactLoader: function contactLoader(state) {
    return state.contactLoader;
  },
  contactProfile: function contactProfile(state) {
    return state.contactProfile;
  },
  contactDetailsLoader: function contactDetailsLoader(state) {
    return state.contactDetailsLoader;
  },
  contactFieldList: function contactFieldList(state) {
    return state.contactFieldList;
  },
  contactOnGoingJobList: function contactOnGoingJobList(state) {
    return state.contactOnGoingJobList;
  },
  contactFarmAddresses: function contactFarmAddresses(state) {
    return state.contactFarmAddresses;
  }
};
export var mutations = {
  setContactProfile: function setContactProfile(state, data) {
    state.contactProfile = new User().getUserProfileDetails(data);
  },
  resetContactProfile: function resetContactProfile(state) {
    state.contactProfile = new User();
  },
  setContactFieldList: function setContactFieldList(state, data) {
    state.contactFieldList = data;
  },
  setContactOnGoingJobsList: function setContactOnGoingJobsList(state, data) {
    state.contactOnGoingJobList = data;
  },
  setContactFarmsList: function setContactFarmsList(state, data) {
    state.contactFarmAddresses = [];
    state.contactFarmAddresses = data;
  }
};