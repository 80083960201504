import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import { closeOverlay } from "@/utils/helpers";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import ExternalContactsSearch from "@/components/invoices-external/invoices-external-contacts/invoices-external-contacts-search.vue";
import InvoicesExternalComputedMixin from "@/components/invoices-external/invoices-external-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import EllipsisLoader from "@/components/shared/loaders/ellipsis-loader.vue";
import ConstantValues from '@/plugins/constantValues';
import ListLoader from "@/components/shared/loaders/list-loader.vue";
export default {
  name: "CustomerListVue",
  mixins: [permissionsMixin, InvoicesExternalComputedMixin],
  data: function data() {
    return {
      externalContact: null,
      showLinkWarning: false,
      dialogMessage: null
    };
  },
  components: {
    ExternalContactsSearch: ExternalContactsSearch,
    Button: Button,
    EllipsisLoader: EllipsisLoader,
    ListLoader: ListLoader
  },
  mounted: function mounted() {
    this.externalContactIdToLink = null;
    this.clearContactList();
    if (this.integrationType != null) {
      this.setFilterOptionsAndGetContacts();
    }
    this.changeDefaultPageLimitSize();
  },
  beforeDestroy: function beforeDestroy() {
    this.clearContactList();
    this.externalContact = null;
    this.showLinkWarning = false;
    this.dialogMessage = null;
  },
  destroyed: function destroyed() {
    this.clearContactList();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    clearContactList: function clearContactList() {
      this.clearFilterOptions();
      this.resetPaging();
      this.fetchingintergrationDataProgress = false;
      this.contacts = [];
      this.contactSearchText = "";
      this.filtersApplied = false;
      this.loadedContacts = 0;
      this.allCustomersLoaded = false;
    },
    localCheckContactLinkStatus: function localCheckContactLinkStatus() {
      var _this = this;
      if (this.invoiceDraftPreviewDetailsExternal && this.invoiceDraftPreviewDetailsExternal.customerId) {
        this.invoiceLoaderExternal = true;
        this.$store.dispatch("checkContactLinkStatus", [this.integrationType, this.invoiceDraftPreviewDetailsExternal.customerId]).then(function (res) {
          _this.invoiceLoaderExternal = false;
        });
      }
    },
    linkCustomerCheck: function linkCustomerCheck() {
      var _a, _b, _c;
      if (this.externalContact && this.externalContact.customer) {
        this.dialogMessage = "".concat(((_a = this.externalContact) === null || _a === void 0 ? void 0 : _a.companyName) || 'Contact ', " \n                ").concat(this.$stringConstants('invoiceExternalAlreadyLinkedWarningText1'), " \n                ").concat(((_c = (_b = this.externalContact) === null || _b === void 0 ? void 0 : _b.customer) === null || _c === void 0 ? void 0 : _c.firstName) || '', ". \n                ").concat(this.$stringConstants('invoiceExternalAlreadyLinkedWarningText2'), " \n                ").concat(this.invoiceDraftPreviewDetailsExternal.customerName, " instead?");
        this.showLinkWarning = true;
      } else {
        this.linkCustomer();
      }
    },
    unlinkAndLinkContact: function unlinkAndLinkContact() {
      var _this2 = this;
      this.dialogMessage = null;
      this.showLinkWarning = false;
      this.invoiceLoaderExternal = true;
      this.$store.dispatch('unlinkContact', [this.integrationType, this.externalContact.customer.id, this.externalContact.id]).then(function () {
        _this2.linkCustomer();
      });
    },
    linkCustomer: function linkCustomer() {
      var _this3 = this;
      var externalContactId = this.externalContactIdToLink;
      var internalCustomerId = this.invoiceDraftPreviewDetailsExternal.customerId;
      if (externalContactId && internalCustomerId) {
        this.invoiceLoaderExternal = true;
        this.closeContactLinkFlyout();
        this.$store.dispatch("linkContact", [this.integrationType, internalCustomerId, externalContactId]).then(function (unlinkResult) {
          _this3.invoiceLoaderExternal = false;
          if (unlinkResult[0].length == 0) {
            _this3.localCheckContactLinkStatus();
          }
        });
      }
    },
    getSelectedCustomer: function getSelectedCustomer(contact) {
      this.externalContact = contact;
      this.externalContactIdToLink = this.externalContact.id;
    },
    closeContactLinkFlyout: function closeContactLinkFlyout() {
      this.clearContactList();
      this.externalContactIdToLink = null;
      closeOverlay("contactsLinkFormOverlay");
      this.$emit('linkingContactDone');
    },
    setFilterOptionsAndGetContacts: function setFilterOptionsAndGetContacts() {
      var _this4 = this;
      var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSizeCustomer];
      this.$store.dispatch("setContactsSearchFilter", filters).then(function () {
        _this4.$store.dispatch("getExternalInvoicingServiceContacts", _this4.integrationType);
      });
    },
    changeDefaultPageLimitSize: function changeDefaultPageLimitSize() {
      this.$store.commit('setDefaultPageLimitSize', ConstantValues.defaultPageLimitSizeCustomer);
    }
  })
};