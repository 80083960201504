import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "pt-10", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants("farmsHeading1"),
                  textValue2: _vm.contactProfile.isInternal
                    ? _vm.$stringConstants("farmsHeading2")
                    : _vm.$stringConstants("farmsExternalContactHeading"),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "ac-fr-lt", attrs: { cols: "7" } },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VExpansionPanels,
                        {
                          attrs: {
                            accordion: "",
                            hover: "",
                            flat: "",
                            focussable: "",
                            disabled: !_vm.contactProfile.isInternal,
                          },
                        },
                        _vm._l(_vm.contactFarmAddresses, function (farm, i) {
                          return _c(
                            VExpansionPanel,
                            { key: i },
                            [
                              _c(VExpansionPanelHeader, [
                                _c("div", { staticClass: "sh-adr" }, [
                                  _c("div", { staticClass: "farm-h fw-5" }, [
                                    _vm._v(_vm._s(farm.title)),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "farm-t op-6 pt-2" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getFarmAddressInLine(farm))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "hd-adr pt-2" }, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.$stringConstants("editFarm"))
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                VExpansionPanelContent,
                                [
                                  _c(
                                    VRow,
                                    { staticClass: "px-6" },
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "px-0 pb-6",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "addressesLine1"
                                                            )
                                                          ) +
                                                          " * "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      rows: "2",
                                                      "row-height": "15",
                                                      maxlength:
                                                        _vm.$constantValues(
                                                          "maxLengthLong1"
                                                        ),
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value: farm.addressLine1,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          farm,
                                                          "addressLine1",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "farm.addressLine1",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "addressesLine2"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      rows: "2",
                                                      "row-height": "15",
                                                      maxlength:
                                                        _vm.$constantValues(
                                                          "maxLengthLong1"
                                                        ),
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value: farm.addressLine2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          farm,
                                                          "addressLine2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "farm.addressLine2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "py-0 pl-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "townAndCity"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      maxlength:
                                                        _vm.$constantValues(
                                                          "maxLengthShort1"
                                                        ),
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        return _vm.checkKeyDownAlpha(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: farm.city,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          farm,
                                                          "city",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "farm.city",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "py-0 pr-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "stateAndCounty"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      maxlength:
                                                        _vm.$constantValues(
                                                          "maxLengthShort1"
                                                        ),
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        return _vm.checkKeyDownAlpha(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: farm.state,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          farm,
                                                          "state",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "farm.state",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "py-0 pl-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "country"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VAutocomplete, {
                                                    attrs: {
                                                      items:
                                                        _vm.globalCountriesList,
                                                      "item-text": "name",
                                                      "item-value": "name",
                                                      outlined: "",
                                                      placeholder:
                                                        _vm.$stringConstants(
                                                          "countryPlaceholder"
                                                        ),
                                                      dense: "",
                                                      flat: "",
                                                      attach: "",
                                                      solo: "",
                                                    },
                                                    model: {
                                                      value: farm.country,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          farm,
                                                          "country",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "farm.country",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "py-0 pr-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "postalCode"
                                                            )
                                                          ) +
                                                          " * "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      maxlength:
                                                        _vm.$constantValues(
                                                          "maxLengthShort1"
                                                        ),
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        return _vm.checkKeyDownAlphaNumeric(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: farm.postalCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          farm,
                                                          "postalCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "farm.postalCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VRow,
                                            [
                                              _c("Button", {
                                                staticClass:
                                                  "ib float-right btn-gr f-nn",
                                                attrs: {
                                                  variant: "secondary",
                                                  color: "primary",
                                                  outlined: "",
                                                  outlineColor: "primary",
                                                  textValue:
                                                    _vm.$stringConstants(
                                                      "listItemDelete"
                                                    ),
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.removeSelectedAddress(
                                                      farm.id
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("Button", {
                                                staticClass:
                                                  "ib float-right ml-2 f-nn py-2",
                                                attrs: {
                                                  variant: "secondary",
                                                  color: "primary",
                                                  disabled:
                                                    !_vm.isAddressValid(farm),
                                                  textValue:
                                                    _vm.$stringConstants(
                                                      "update"
                                                    ),
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.Update(farm.id)
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.contactProfile.isInternal
                    ? _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _vm.showFarmAddressAddFields
                            ? _c(
                                VRow,
                                { staticClass: "pt-4 pb-0" },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pt-0 px-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(VRow, { staticClass: "pb-2" }, [
                                            _c(
                                              "div",
                                              { staticClass: "ad-fm" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$stringConstants(
                                                        "addFarm"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "px-0 py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "addressesLine1"
                                                            )
                                                          ) +
                                                          " * "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      outlined: "",
                                                      placeholder:
                                                        _vm.$stringConstants(
                                                          "addressLineOnePlaceholder"
                                                        ),
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value: _vm.addressLine1,
                                                      callback: function ($$v) {
                                                        _vm.addressLine1 = $$v
                                                      },
                                                      expression:
                                                        "addressLine1",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "px-0 py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "addressesLine2"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      outlined: "",
                                                      placeholder:
                                                        _vm.$stringConstants(
                                                          "addressLineTwoPlaceholder"
                                                        ),
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value: _vm.addressLine2,
                                                      callback: function ($$v) {
                                                        _vm.addressLine2 = $$v
                                                      },
                                                      expression:
                                                        "addressLine2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "pb-0 pt-0 pl-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "townAndCity"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      outlined: "",
                                                      placeholder:
                                                        _vm.$stringConstants(
                                                          "cityPlaceholder"
                                                        ),
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        return _vm.checkKeyDownAlpha(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.city,
                                                      callback: function ($$v) {
                                                        _vm.city = $$v
                                                      },
                                                      expression: "city",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "pb-0 pt-0 pr-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "stateAndCounty"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      outlined: "",
                                                      placeholder:
                                                        _vm.$stringConstants(
                                                          "countyPlaceholder"
                                                        ),
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        return _vm.checkKeyDownAlpha(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.state,
                                                      callback: function ($$v) {
                                                        _vm.state = $$v
                                                      },
                                                      expression: "state",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "pb-0 pt-0 pl-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "country"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VAutocomplete, {
                                                    attrs: {
                                                      items:
                                                        _vm.globalCountriesList,
                                                      "item-text": "name",
                                                      "item-value": "name",
                                                      outlined: "",
                                                      placeholder:
                                                        _vm.$stringConstants(
                                                          "countryPlaceholder"
                                                        ),
                                                      dense: "",
                                                      flat: "",
                                                      attach: "",
                                                      solo: "",
                                                    },
                                                    model: {
                                                      value: _vm.country,
                                                      callback: function ($$v) {
                                                        _vm.country = $$v
                                                      },
                                                      expression: "country",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "pb-0 pt-0 pr-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "field-input-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "postalCode"
                                                            )
                                                          ) +
                                                          " * "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    class: _vm.formFieldPb,
                                                    attrs: {
                                                      outlined: "",
                                                      placeholder:
                                                        _vm.$stringConstants(
                                                          "postalCodePlaceholder"
                                                        ),
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        return _vm.checkKeyDownAlphaNumeric(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.postalCode,
                                                      callback: function ($$v) {
                                                        _vm.postalCode = $$v
                                                      },
                                                      expression: "postalCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VRow,
                                            { staticClass: "pt-2" },
                                            [
                                              _c("Button", {
                                                staticClass:
                                                  "ib float-right btn-gr f-nn",
                                                attrs: {
                                                  variant: "secondary",
                                                  color: "primary",
                                                  outlined: "",
                                                  outlineColor: "primary",
                                                  textValue:
                                                    _vm.$stringConstants(
                                                      "cancel"
                                                    ),
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.hideAddFarmAddressFields()
                                                  },
                                                },
                                              }),
                                              _c("Button", {
                                                staticClass:
                                                  "ib float-right ml-2 f-nn",
                                                attrs: {
                                                  variant: "secondary",
                                                  color: "primary",
                                                  textValue:
                                                    _vm.$stringConstants(
                                                      "addItemBtnText"
                                                    ),
                                                  disabled:
                                                    !_vm.isNewFarmFieldsValid,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.addNewAddress(
                                                      false
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "4 pb-16 px-0" } },
                                    [
                                      _c("Button", {
                                        staticClass: "border-light",
                                        attrs: {
                                          variant: "secondary",
                                          color: "#000000",
                                          outlined: "",
                                          outlineColor: "#dedfe1",
                                          textValue: "+ Add",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.displayAddFarmAddressFields()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }