import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hide-scroll",
      staticStyle: { height: "calc(100vh - 70px)" },
    },
    [
      !_vm.contactDetailsLoader
        ? _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "px-9 py-0", attrs: { cols: "12" } },
                [
                  _c("PersonalDetails"),
                  _c("BusinessDetails"),
                  _c("farmsDetails", { attrs: { contactId: _vm.resId } }),
                ],
                1
              ),
              _vm.contactFieldList.length > 0
                ? _c(
                    VCol,
                    { staticClass: "pa-0", attrs: { cols: "12" } },
                    [
                      _c(
                        VRow,
                        {
                          staticClass: "pt-10 mt-8 pb-7",
                          staticStyle: {
                            "border-top": "0.5px solid #e0e0e0",
                            "border-bottom": "0.5px solid #e0e0e0",
                          },
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            VCol,
                            { staticClass: "px-9 py-0", attrs: { cols: "12" } },
                            [_c("fieldDetails")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contactOnGoingJobList.length > 0
                ? _c(
                    VCol,
                    { staticClass: "pa-0", attrs: { cols: "12" } },
                    [_c("onGoingJobsView")],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.contactDetailsLoader
        ? _c("div", { staticClass: "text-center" }, [_c("ListLoader")], 1)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }