import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { routeFields } from '@/utils/endpoints';
import requests from '@/utils/requests';
export default {
  name: 'RequestsCancellation',
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (from.path.includes(routeFields)) {
      this.$store.commit('setDrawerOpen', false);
      this.$store.dispatch('cleanUpMapAndFieldForm');
    }
    next && requests.initializeCancelRequests(next);
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next && requests.initializeAbortController(next);
  }
};