import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "pt-10", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants("personalDetailsTitle1"),
                  textValue2: _vm.$stringConstants(
                    "customerPersonalDetailsSubTitle"
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "5" } },
            [
              _c(
                VRow,
                {
                  staticClass: "pb-8",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants(
                            "accountDetailsNameLabel"
                          ),
                          detailsValue: _vm.contactProfile.firstName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "4" } },
                    [
                      _c("div", { staticClass: "rd-label pb-4" }, [
                        _vm._v(
                          _vm._s(_vm.$stringConstants("defaultFieldColor")) +
                            " *"
                        ),
                      ]),
                      _c(VTextField, {
                        staticClass: "ma-0 pa-0 hide_input_details slot-m0",
                        attrs: { outlined: "", readonly: "", dense: "" },
                        on: { change: _vm.updateCustomerColor },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  VMenu,
                                  {
                                    attrs: {
                                      top: "",
                                      "nudge-bottom": "105",
                                      "nudge-left": "16",
                                      "close-on-content-click": false,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                { style: _vm.swatchStyle },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.menuColor,
                                      callback: function ($$v) {
                                        _vm.menuColor = $$v
                                      },
                                      expression: "menuColor",
                                    },
                                  },
                                  [
                                    _c(
                                      VCard,
                                      [
                                        _c(
                                          VCardText,
                                          { staticClass: "pa-0" },
                                          [
                                            _c(VColorPicker, {
                                              attrs: {
                                                "hide-mode-switch": "",
                                                mode: "hexa",
                                                flat: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.contactProfile.colorCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contactProfile,
                                                    "colorCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contactProfile.colorCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.contactProfile.colorCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactProfile,
                              "colorCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "contactProfile.colorCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "pb-8",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants(
                            "accountDetailsEmailLabel"
                          ),
                          detailsValue: _vm.contactProfile.email,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants(
                            "accountDetailsPhoneLabel"
                          ),
                          detailsValue: _vm.contactProfile.primaryContactNumber,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "pb-8",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants(
                            "accountDetailsSecondaryNameLabel"
                          ),
                          detailsValue: _vm.contactProfile.secondaryFirstName
                            ? _vm.contactProfile.secondaryFirstName
                            : "-",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("labelAndDetailsReadonly", {
                        attrs: {
                          labelValue: _vm.$stringConstants(
                            "accountDetailsSecondaryContactLabel"
                          ),
                          detailsValue: _vm.contactProfile
                            .secondaryContactNumber
                            ? _vm.contactProfile.secondaryContactNumber
                            : "-",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "4" } },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0 px-0", attrs: { cols: "12" } },
                    [
                      !_vm.contactProfile.isArchived
                        ? _c("Button", {
                            staticClass: "ib float-right f-nn add-btn op-6",
                            attrs: {
                              variant: "secondary",
                              color: _vm.buttonDeleteColor,
                              outlined: "",
                              outlineColor: _vm.buttonDeleteColor,
                              textValue: _vm.$stringConstants("listItemDelete"),
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.confirmDeleteFarmer(
                                  _vm.contactProfile.id
                                )
                              },
                            },
                          })
                        : _c("Button", {
                            staticClass: "ib float-right f-nn add-btn op-6",
                            attrs: {
                              variant: "secondary",
                              color: "#000000",
                              outlined: "",
                              outlineColor: "#000000",
                              textValue: _vm.$stringConstants("restore"),
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.confirmRestoreFarmer(
                                  _vm.contactProfile.id
                                )
                              },
                            },
                          }),
                      _vm.contactProfile.isInternal
                        ? _c("Button", {
                            staticClass:
                              "ib float-right btn-gr f-nn add-btn mr-2",
                            attrs: {
                              variant: "secondary",
                              color: "primary",
                              outlined: "",
                              outlineColor: "primary",
                              textValue: _vm.$stringConstants("listItemEdit"),
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openContactEditForm(
                                  _vm.contactProfile.id
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogFarmer },
        on: {
          cancel: function ($event) {
            _vm.dialogFarmer = false
          },
          proceed: _vm.proceedActionOnFarmer,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm.contactProfile.isArchived
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("farmersRestorePromtTitle")
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("farmersDeletePromtTitle")
                          ) +
                          " "
                      ),
                    ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.contactProfile.isArchived
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("farmersRestorePromtSubtitle")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants("farmersDeletePromtSubtitle")
                      ) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }