import _toConsumableArray from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import $ from 'jquery';
import { hideScrollBars as _hideScrollBars, showScrollBars as _showScrollBars } from '@/utils/helpers/gmaps-helpers';
export default {
  methods: {
    reloadScrollBars: function reloadScrollBars() {
      $('.scroll-view').css({
        'overflow-y': 'scroll'
      });
    },
    unloadScrollBars: function unloadScrollBars() {
      $('.scroll-view').css({
        'overflow-y': 'hidden'
      });
    },
    scrollToLastTarget: function scrollToLastTarget(targetContainer, innerContainer) {
      var $target = $(targetContainer);
      var scrollFromPos = $(innerContainer).innerHeight() / 2;
      if (scrollFromPos > 0) {
        $target.scrollTop(scrollFromPos);
      }
    },
    scrollToTop: function scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToToForm: function scrollToToForm() {
      var _this = this;
      setTimeout(function () {
        _this.scrollToTopElement('form-scroll-view');
      }, 100);
    },
    scrollToTopElement: function scrollToTopElement(classNameOrId) {
      var detectedElements = document.getElementById(classNameOrId) || document.getElementsByClassName(classNameOrId);
      var targetElements = _toConsumableArray(detectedElements);
      if (targetElements.length > 0) {
        for (var i = 0; i < targetElements.length; i++) {
          targetElements[i].scrollTop = 0;
        }
      }
    },
    scrollToBottom: function scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    hideScrollBars: function hideScrollBars(scrollerClass) {
      _hideScrollBars(scrollerClass);
    },
    showScrollBars: function showScrollBars(scrollerClass) {
      _showScrollBars(scrollerClass);
    }
  }
};