import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import { closeOverlay } from "@/utils/helpers";
import ImportData from "@/components/shared/import/import-csv-list.vue";
import { mapMutations } from "vuex";
import machinesComputedMixin from "@/components/machines/machines-computed";
import { MachineType } from "@/enum/machineType";
export default {
  name: "MachinesImportForm",
  mixins: [machinesComputedMixin],
  components: {
    ImportData: ImportData
  },
  computed: {
    getMachineTypeName: function getMachineTypeName() {
      return this.machineType === MachineType.Vehicle ? ' vehicles' : 'implements';
    },
    getImportActionName: function getImportActionName() {
      return this.machineType === MachineType.Vehicle ? 'importVehiclesExcel' : 'importImplementsExcel';
    },
    getUploadNotes: function getUploadNotes() {
      var uploadNotes = [this.$stringConstants('uploadNotesDeleteColumnWarning'), this.$stringConstants('uploadNotesCommaWarning')];
      var requiredColumnsText = 'Required columns: ';
      if (this.machineType === MachineType.Vehicle) {
        requiredColumnsText += 'name, make, model and registration.';
      } else if (this.machineType === MachineType.Implement) {
        requiredColumnsText += 'name, make and model.';
      }
      uploadNotes.push(requiredColumnsText);
      return uploadNotes;
    },
    getImportResourceType: function getImportResourceType() {
      return this.machineType === MachineType.Vehicle ? MachineType.Vehicle : MachineType.Implement;
    },
    getRequiredImportFields: function getRequiredImportFields() {
      var requiredImportFields = ['name', 'make', 'model'];
      return this.machineType === MachineType.Vehicle ? requiredImportFields.concat('registration') : requiredImportFields;
    },
    getMachinesSampleData: function getMachinesSampleData() {
      var machineInfo = {
        titleField: JSON.parse('"name*"'),
        makeField: JSON.parse('"make*"'),
        modelField: JSON.parse('"model*"'),
        registrationField: JSON.parse('"registration*"'),
        widthField: JSON.parse('"width_in_metres"'),
        lengthField: JSON.parse('"length_in_metres"'),
        weightField: JSON.parse('"weight_in_kg"')
      };
      var machineObj = {};
      machineObj[machineInfo.titleField] = '';
      machineObj[machineInfo.makeField] = '';
      machineObj[machineInfo.modelField] = '';
      machineObj[machineInfo.registrationField] = '';
      machineObj[machineInfo.widthField] = '';
      machineObj[machineInfo.lengthField] = '';
      machineObj[machineInfo.weightField] = '';
      if (this.machineType === MachineType.Implement) {
        delete machineObj['registration*'];
      }
      return [machineObj];
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearMachinesFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging",
    resetImportForm: "resetImportDataDetails"
  })), {}, {
    closeImportMachinesForm: function closeImportMachinesForm() {
      closeOverlay("importMachinesFormOverlay");
    },
    refreshMachinesList: function refreshMachinesList() {
      this.resetPaging();
      this.clearMachinesFilterOption();
      this.$store.commit('setClearMachinesList');
      this.$store.dispatch('getMachineListFiltered');
    }
  })
};