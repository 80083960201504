import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "pb-6" },
        [
          _c("SubHeaderPrimary", {
            attrs: {
              textValue: _vm.$stringConstants("selectFarmsAddressPlaceholder"),
            },
          }),
        ],
        1
      ),
      _c(VAutocomplete, {
        attrs: {
          items: _vm.teamJobAllAddresses,
          "item-text": "addressLine1",
          placeholder: _vm.$stringConstants("selectFarmsAddressPlaceholder"),
          outlined: "",
          clearable: "",
          dense: "",
          attach: "",
          flat: "",
          solo: "",
          "return-object": "",
        },
        model: {
          value: _vm.teamJobFarmAddressesSelected,
          callback: function ($$v) {
            _vm.teamJobFarmAddressesSelected = $$v
          },
          expression: "teamJobFarmAddressesSelected",
        },
      }),
      _c("TeamJobsAddressesFlyoutAddressInstructions"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }