//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { jobsMapLabelColorBackground } from "@/utils/uiconstants";
export default {
  props: ["fieldName"],
  data: function data() {
    return {
      jobsMapLabelColorBackground: jobsMapLabelColorBackground
    };
  }
};