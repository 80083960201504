export var ResourceType;
(function (ResourceType) {
  ResourceType[ResourceType["User"] = 0] = "User";
  ResourceType[ResourceType["Field"] = 1] = "Field";
  ResourceType[ResourceType["Implement"] = 2] = "Implement";
  ResourceType[ResourceType["Vehicle"] = 3] = "Vehicle";
  ResourceType[ResourceType["Product"] = 4] = "Product";
  ResourceType[ResourceType["Operation"] = 5] = "Operation";
  ResourceType[ResourceType["Job"] = 6] = "Job";
  ResourceType[ResourceType["Timesheet"] = 7] = "Timesheet";
})(ResourceType || (ResourceType = {}));