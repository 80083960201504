import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-1" },
    _vm._l(_vm.loaderRows, function (loader, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          { staticClass: "mt-3" },
          [
            _c(VSkeletonLoader, {
              attrs: { width: "70", height: "20", type: "card" },
            }),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }