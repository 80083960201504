import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginRegistrationWrapper", [
    _c("div", { staticClass: "centered" }, [
      _c(
        "form",
        { staticClass: "login-box" },
        [
          _c("div", { staticClass: "logo-wrapper" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("../../assets/ag-drive-logo.svg") },
            }),
          ]),
          _c("div", { staticClass: "pb-9" }, [
            _c("span", { staticClass: "d-block title" }, [
              _vm._v(_vm._s(_vm.$stringConstants("createPasswordTitle"))),
            ]),
            _c("span", { staticClass: "d-block subtitle" }, [
              _vm._v(_vm._s(_vm.$stringConstants("resetPwdText"))),
              _c("span", { staticClass: "font-weight-medium" }, [
                _vm._v(_vm._s(_vm.emailAddress)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "field-input-label" }, [
            _vm._v(_vm._s(_vm.$stringConstants("createPasswordInputLabel"))),
          ]),
          _c(VTextField, {
            staticClass: "password-input-field",
            attrs: {
              outlined: "",
              "error-messages": _vm.generateValidationPasswordErrors(
                _vm.$stringConstants("passWordField"),
                _vm.$stringConstants("requiredField"),
                _vm.$stringConstants("minLengthField"),
                _vm.$stringConstants("maxLengthField"),
                _vm.$stringConstants("uppercaseConstraint"),
                _vm.$stringConstants("lowercaseConstraint"),
                _vm.$stringConstants("numericConstraint"),
                _vm.$stringConstants("userRequiredPassword"),
                _vm.$stringConstants("userMinlengthPassword"),
                _vm.$stringConstants("userMaxlengthPassword"),
                _vm.$stringConstants("uppercaseConstraintErrorMessage"),
                _vm.$stringConstants("lowercaseConstraintErrorMessage"),
                _vm.$stringConstants("numericConstraintErrorMessage")
              ),
              placeholder: _vm.$stringConstants("enterPasswordPlaceholder"),
              "append-icon": _vm.value ? "mdi-eye-off" : "mdi-eye",
              type: _vm.value ? "password" : "text",
              dense: "",
              required: "",
              autocomplete: "off",
            },
            on: {
              "click:append": function () {
                return (_vm.value = !_vm.value)
              },
              focus: _vm.handlePasswordFieldFocus,
              input: _vm.handlePasswordFieldInput,
              blur: _vm.handlePasswordFieldBlur,
            },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function () {
                  return [
                    _c(
                      VTooltip,
                      {
                        attrs: {
                          color: "black",
                          "open-on-hover": false,
                          "open-on-focus": true,
                          attach: "",
                          left: "",
                          "content-class": "password-tooltip-content",
                        },
                        model: {
                          value: _vm.showPasswordRequirementsTooltip,
                          callback: function ($$v) {
                            _vm.showPasswordRequirementsTooltip = $$v
                          },
                          expression: "showPasswordRequirementsTooltip",
                        },
                      },
                      [
                        _c("div", { staticClass: "f-11" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$stringConstants("passwordValidationText")
                            )
                          ),
                        ]),
                        _vm._l(
                          _vm.validPasswordRequirements,
                          function (requirement, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "grey--text f-11 d-flex align-center",
                              },
                              [
                                _c(
                                  VIcon,
                                  {
                                    attrs: {
                                      color:
                                        requirement.status !== null
                                          ? requirement.status
                                            ? "green"
                                            : "red"
                                          : "white",
                                      "x-small": "",
                                      left: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          requirement.status !== false
                                            ? "mdi-check"
                                            : "mdi-close"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("span", [_vm._v(_vm._s(requirement.text))]),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.passWord,
              callback: function ($$v) {
                _vm.passWord = $$v
              },
              expression: "passWord",
            },
          }),
          _c("div", { staticClass: "field-input-label" }, [
            _vm._v(_vm._s(_vm.$stringConstants("confirmPasswordInputLabel"))),
          ]),
          _c(VTextField, {
            staticClass: "pT-8",
            attrs: {
              outlined: "",
              "error-messages": _vm.generateValidationErrors(
                _vm.$stringConstants("confirmPassWordField"),
                _vm.$stringConstants("requiredField"),
                _vm.$stringConstants("sameAsPasswordField"),
                _vm.$stringConstants("userRequiredConfirmPassword"),
                _vm.$stringConstants("userSameAsPassword")
              ),
              placeholder: _vm.$stringConstants("confirmPasswordPlaceholder"),
              "append-icon": _vm.valueConfirm ? "mdi-eye-off" : "mdi-eye",
              type: _vm.valueConfirm ? "password" : "text",
              dense: "",
              required: "",
              autocomplete: "off",
            },
            on: {
              "click:append": function () {
                return (_vm.valueConfirm = !_vm.valueConfirm)
              },
              input: function ($event) {
                return _vm.$v.confirmPassWord.$touch()
              },
              blur: function ($event) {
                return _vm.$v.confirmPassWord.$touch()
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.submit($event)
              },
            },
            model: {
              value: _vm.confirmPassWord,
              callback: function ($$v) {
                _vm.confirmPassWord = $$v
              },
              expression: "confirmPassWord",
            },
          }),
          _c("Button", {
            staticClass: "mt-4 mb-5",
            attrs: {
              variant: "primary",
              color: "primary",
              textValue: "Confirm",
              rightIcon: "mdi-arrow-right",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.submit($event)
              },
            },
          }),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "router-link",
                {
                  staticClass: "no-decoration ml-1",
                  attrs: { to: _vm.routeLogin },
                },
                [
                  _c("span", { staticClass: "font-weight-medium fn-sm" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("logInText"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }