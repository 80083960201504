import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapMutations, mapActions } from "vuex";
import moment from "moment";
import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { InvoiceStatusType } from "@/enum/invoiceStatusType";
export default {
  data: function data() {
    return {
      polling: null,
      showStartTrialWarning: false,
      InvoiceStatusType: InvoiceStatusType
    };
  },
  mixins: [subscriptionsComputedMixin],
  computed: {
    getTrialEndDate: function getTrialEndDate() {
      if (this.userProfile.businessProfile && this.userProfile.businessProfile.trialEnd) {
        return this.getLocalDate(this.userProfile.businessProfile.trialEnd);
      } else {
        return "";
      }
    }
  },
  components: {
    Button: Button
  },
  mounted: function mounted() {
    this.subscriptionPlanLoader = true;
    this.setClearPromoCodeInfo();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapMutations({
    setClearPromoCodeInfo: "setClearPromoCodeInfo"
  })), mapActions({
    getPromoCodeDetailsAsync: "getPromoCodeDetails"
  })), {}, {
    checkPaymentStatus: function checkPaymentStatus() {
      var _this = this;
      this.polling = setInterval(function () {
        if (_this.subscriptionDetails && _this.subscriptionDetails.latestInvoice != null && _this.subscriptionDetails.latestInvoice.status == InvoiceStatusType.Open) {
          _this.$store.dispatch("getSubscriptionDetails");
        }
      }, 10000);
    },
    cancelSubscription: function cancelSubscription() {
      this.dialogCancelSubscription = false;
      this.$store.dispatch("cancelSubscription");
    },
    beforeDestroy: function beforeDestroy() {
      clearInterval(this.polling);
    },
    getLocalDate: function getLocalDate(date) {
      return moment.utc(date).local().format("DD MMM YYYY HH:mm");
    },
    getSubscriptionStatus: function getSubscriptionStatus(planStatus) {
      switch (planStatus) {
        case 0:
          return "Trialing";
        case 1:
          return "Active";
        case 2:
          return "Pending";
        case 3:
          return "Cancelled";
        case 4:
          return "Expired";
        default:
          return "Unknown";
      }
    },
    getPlanStatusClass: function getPlanStatusClass(planStatus) {
      switch (planStatus) {
        case 0:
          return "sb-plan-status-active";
        case 1:
          return "sb-plan-status-active";
        case 2:
        case 3:
        case 4:
        default:
          return "sb-plan-status-expires";
      }
    },
    startSubscription: function startSubscription() {
      if (!this.isSystemTrialPeriodOver) {
        this.showStartTrialWarning = true;
      } else {
        this.createSubscription();
      }
    },
    createSubscription: function createSubscription() {
      this.showStartTrialWarning = false;
      this.$store.dispatch("createStripeSubscription").then(function () {
        setTimeout(function () {
          location.reload();
        }, 3000);
      });
    },
    withdrawCancellation: function withdrawCancellation() {
      this.$store.dispatch("withdrawCancellation");
    }
  })
};