export var formFieldPb = 'pb-1';
export var containerLeftPad = 'pl-9';
export var containerRightPad = 'pr-9';
export var successColor = '#1d34ad';
export var failureColor = '#e65a5a';
export var brandColor = '#1d34ad';
export var jobsMapLoaderColor = '#eeeeee';
export var jobsMapLabelColorBackground = '#000000';
export var disabledColor = '#afafaf';
export var teamJobOperatorIdleColor = '#B0B4C9';
export var buttonDeleteColor = '#ff0000';
export var defaultScrollViewHeight = 700;
export var defaultScrollViewHeightJobs = 685;
export var defaultScrollViewHeightTimeSheet = 500;
export var defaultJobReportMapWidth = 865;
export var defaultFadeInTimeout = 400;
export var defaultFieldsListVH = 0.75;
export var defaultImagePlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgB7dFBAQAgDAChaf+Qa6I17gEVOLv7how7pAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERLzAR4vBGekHICAAAAAAElFTkSuQmCC";
export var defaultSidebarWidth = 230;
export var defaultScrollAnimationBuffer = 700;
export var paidInvoiceColor = '#b87333';
export var unpaidInvoiceColor = '#ff0000';
export var vdprMaxWidth = '300px';
export var vdprMinWidth = '110px';
export var avatarColors = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#FF9800', '#FF5722'];
export var avatarColorDisabled = '#d8d8d8';
export var defaultFieldStrokeColor = '#ffe600';
export var fieldColors = ['#ffffff', '#00ff37', '#00d0ff', '#ff9900', '#fff700', '#a200ff', '#4800ff'];
export var logCssColors = 'background: #008cf4; color: #ffffff; font-size: 12px; padding: 2px 4px;';
export var logCssErrorColors = 'background: #974855; color: #ffffff; font-size: 12px; padding: 2px 4px;';
export var avatarColorPresets = [{
  group: 'abcde',
  color: '#eb5656'
}, {
  group: 'fghi',
  color: '#56a0eb'
}, {
  group: 'jklm',
  color: '#eb56a6'
}, {
  group: 'nopq',
  color: '#f0a257'
}, {
  group: 'rstu',
  color: '#1d7836'
}, {
  group: 'vwxyz',
  color: '#657efe'
}];
export var wysiwygEditorConfig = [['link']];
//button styles
export var PRIMARY_BUTTON_HEIGHT = 48;
export var SECONDARY_BUTTON_HEIGHT = 40;
export var TERTIARY_BUTTON_HEIGHT = 36;
export var PRIMARY_BUTTON_FONT_SIZE = 15;
export var SECONDARY_BUTTON_FONT_SIZE = 13;
export var TERTIARY_BUTTON_FONT_SIZE = 1;
export var CALENDAR_INFO_WINDOW_WIDTH = 320;
export var CALENDAR_INFO_WINDOW_HEIGHT = 315;