var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticStyle: { width: "100%", "min-height": "100vh" },
    attrs: { src: _vm.jobsReportURI, allow: "fullscreen" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }