import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginRegistrationWrapper", [
    _c("form", { staticClass: "signup-box" }, [
      _c("div", { staticClass: "logo-wrapper" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("../../assets/ag-drive-logo.svg") },
        }),
      ]),
      _c(
        "div",
        [
          _c("div", { staticClass: "title pb-6" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showRoleToggle,
                    expression: "showRoleToggle",
                  },
                ],
              },
              [_vm._v(_vm._s(_vm.$stringConstants("usersSignUpTitle1")))]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showRoleToggle,
                    expression: "!showRoleToggle",
                  },
                ],
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.enumRoleTitles[_vm.roleSelection]) +
                    " " +
                    _vm._s(_vm.$stringConstants("accountSignUpText")) +
                    " "
                ),
              ]
            ),
          ]),
          _vm.showRoleToggle
            ? _c(
                "div",
                { staticClass: "pb-1" },
                [
                  _c(
                    VBtnToggle,
                    {
                      staticClass: "tgl-btn",
                      class: _vm.isUnclickable ? "unclickable" : "",
                      staticStyle: { width: "100%" },
                      attrs: { outlined: "", group: "", mandatory: "" },
                      model: {
                        value: _vm.roleSelection,
                        callback: function ($$v) {
                          _vm.roleSelection = $$v
                        },
                        expression: "roleSelection",
                      },
                    },
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "6 pl-0" } },
                            [
                              _c("Button", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  variant: "secondary",
                                  outlined: "",
                                  outlineColor: "gray",
                                  color:
                                    _vm.roleSelection ==
                                    _vm.UserRoleType.Contractor
                                      ? "primary"
                                      : null,
                                  text: "",
                                  textValue: "Contractor",
                                  rightIcon:
                                    _vm.roleSelection ==
                                    _vm.UserRoleType.Contractor
                                      ? "mdi-check"
                                      : null,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "6 pr-0" } },
                            [
                              _c("Button", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  variant: "secondary",
                                  disabled:
                                    _vm.connType ==
                                    _vm.ExternalIntegrationType.Xero,
                                  outlined: "",
                                  outlineColor: "gray",
                                  color:
                                    _vm.roleSelection == _vm.UserRoleType.Farmer
                                      ? "primary"
                                      : null,
                                  text: "",
                                  textValue: "Farmer",
                                  rightIcon:
                                    _vm.roleSelection == _vm.UserRoleType.Farmer
                                      ? "mdi-check"
                                      : null,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "field-input-label" }, [
            _vm._v(_vm._s(_vm.$stringConstants("fieldLabelName"))),
          ]),
          _c(VTextField, {
            attrs: {
              outlined: "",
              "error-messages": _vm.generateValidationErrors(
                _vm.$stringConstants("firstNameField"),
                _vm.$stringConstants("requiredField"),
                _vm.$stringConstants("minLengthField"),
                _vm.$stringConstants("userRequiredFullName"),
                _vm.$stringConstants("userMinlengthFullName")
              ),
              placeholder: _vm.$stringConstants("namePlaceholder"),
              dense: "",
              required: "",
              autocomplete: "off",
            },
            on: {
              input: function ($event) {
                return _vm.$v.firstName.$touch()
              },
              blur: function ($event) {
                return _vm.$v.firstName.$touch()
              },
            },
            model: {
              value: _vm.firstName,
              callback: function ($$v) {
                _vm.firstName = $$v
              },
              expression: "firstName",
            },
          }),
          _c("div", { staticClass: "field-input-label" }, [_vm._v("Phone *")]),
          _c(VTextField, {
            staticClass: "pT-8",
            attrs: {
              outlined: "",
              "error-messages": _vm.generateValidationErrors(
                _vm.$stringConstants("phoneNumberField"),
                _vm.$stringConstants("requiredField"),
                _vm.$stringConstants("minLengthField"),
                _vm.$stringConstants("userRequiredPhoneNumber"),
                _vm.$stringConstants("userMinlengthPhoneNumber")
              ),
              placeholder: _vm.$stringConstants("phoneNumberPlaceholder"),
              dense: "",
              required: "",
              autocomplete: "off",
            },
            on: {
              keydown: function ($event) {
                return _vm.handlePhoneNumberInput($event)
              },
              blur: function ($event) {
                return _vm.$v.phoneNumber.$touch()
              },
            },
            model: {
              value: _vm.phoneNumber,
              callback: function ($$v) {
                _vm.phoneNumber = $$v
              },
              expression: "phoneNumber",
            },
          }),
          _c("div", { staticClass: "field-input-label" }, [
            _vm._v(_vm._s(_vm.$stringConstants("emailText")) + " *"),
          ]),
          _c(VTextField, {
            staticClass: "pT-8",
            attrs: {
              outlined: "",
              "error-messages": _vm.generateValidationErrors(
                _vm.$stringConstants("emailAddressField"),
                _vm.$stringConstants("requiredField"),
                _vm.$stringConstants("emailRequiredField"),
                _vm.$stringConstants("userRequiredEmailAddress"),
                _vm.$stringConstants("userFormatEmailAddress")
              ),
              placeholder: _vm.$stringConstants("emailPlaceholder"),
              dense: "",
              disabled: !_vm.showRoleToggle || _vm.isEmailDisabled,
              required: "",
              autocomplete: "off",
            },
            on: {
              input: function ($event) {
                return _vm.$v.emailAddress.$touch()
              },
              blur: function ($event) {
                return _vm.$v.emailAddress.$touch()
              },
            },
            model: {
              value: _vm.emailAddress,
              callback: function ($$v) {
                _vm.emailAddress = $$v
              },
              expression: "emailAddress",
            },
          }),
          _c("div", { staticClass: "field-input-label" }, [
            _vm._v(_vm._s(_vm.$stringConstants("createPasswordInputLabel"))),
          ]),
          _c(VTextField, {
            staticClass: "password-input-field",
            attrs: {
              outlined: "",
              "error-messages": _vm.generateValidationPasswordErrors(
                _vm.$stringConstants("passWordField"),
                _vm.$stringConstants("requiredField"),
                _vm.$stringConstants("minLengthField"),
                _vm.$stringConstants("maxLengthField"),
                _vm.$stringConstants("uppercaseConstraint"),
                _vm.$stringConstants("lowercaseConstraint"),
                _vm.$stringConstants("numericConstraint"),
                _vm.$stringConstants("userRequiredPassword"),
                _vm.$stringConstants("userMinlengthPassword"),
                _vm.$stringConstants("userMaxlengthPassword"),
                _vm.$stringConstants("uppercaseConstraintErrorMessage"),
                _vm.$stringConstants("lowercaseConstraintErrorMessage"),
                _vm.$stringConstants("numericConstraintErrorMessage")
              ),
              placeholder: _vm.$stringConstants("createPasswordPlaceholder"),
              "append-icon": _vm.value ? "mdi-eye-off" : "mdi-eye",
              type: _vm.value ? "password" : "text",
              dense: "",
              required: "",
              autocomplete: "off",
            },
            on: {
              "click:append": function () {
                return (_vm.value = !_vm.value)
              },
              focus: _vm.handlePasswordFieldFocus,
              input: _vm.handlePasswordFieldInput,
              blur: _vm.handlePasswordFieldBlur,
            },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function () {
                  return [
                    _c(
                      VTooltip,
                      {
                        attrs: {
                          color: "black",
                          "open-on-hover": false,
                          "open-on-focus": true,
                          attach: "",
                          left: "",
                          "content-class": "password-tooltip-content",
                        },
                        model: {
                          value: _vm.showPasswordRequirementsTooltip,
                          callback: function ($$v) {
                            _vm.showPasswordRequirementsTooltip = $$v
                          },
                          expression: "showPasswordRequirementsTooltip",
                        },
                      },
                      [
                        _c("div", { staticClass: "f-11" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$stringConstants("passwordValidationText")
                            )
                          ),
                        ]),
                        _vm._l(
                          _vm.validPasswordRequirements,
                          function (requirement, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "grey--text f-11 d-flex align-center",
                              },
                              [
                                _c(
                                  VIcon,
                                  {
                                    attrs: {
                                      color:
                                        requirement.status !== null
                                          ? requirement.status
                                            ? "green"
                                            : "red"
                                          : "white",
                                      "x-small": "",
                                      left: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          requirement.status !== false
                                            ? "mdi-check"
                                            : "mdi-close"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("span", [_vm._v(_vm._s(requirement.text))]),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.passWord,
              callback: function ($$v) {
                _vm.passWord = $$v
              },
              expression: "passWord",
            },
          }),
          _c("div", { staticClass: "field-input-label" }, [
            _vm._v(_vm._s(_vm.$stringConstants("confirmPasswordInputLabel"))),
          ]),
          _c(VTextField, {
            staticClass: "pT-8",
            attrs: {
              outlined: "",
              "error-messages": _vm.generateValidationErrors(
                _vm.$stringConstants("confirmPassWordField"),
                _vm.$stringConstants("requiredField"),
                _vm.$stringConstants("sameAsPasswordField"),
                _vm.$stringConstants("userRequiredConfirmPassword"),
                _vm.$stringConstants("userSameAsPassword")
              ),
              placeholder: _vm.$stringConstants(
                "enterPasswordAgainPlaceholder"
              ),
              "append-icon": _vm.valueConfirm ? "mdi-eye-off" : "mdi-eye",
              type: _vm.valueConfirm ? "password" : "text",
              dense: "",
              required: "",
              autocomplete: "off",
            },
            on: {
              "click:append": function () {
                return (_vm.valueConfirm = !_vm.valueConfirm)
              },
              input: function ($event) {
                return _vm.$v.confirmPassWord.$touch()
              },
              blur: function ($event) {
                return _vm.$v.confirmPassWord.$touch()
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.submit($event)
              },
            },
            model: {
              value: _vm.confirmPassWord,
              callback: function ($$v) {
                _vm.confirmPassWord = $$v
              },
              expression: "confirmPassWord",
            },
          }),
          _c(VCheckbox, {
            staticClass: "mt-0 pt-0 pb-5 mr-1",
            attrs: {
              "error-messages": _vm.generateTermsAndPrivacyPolicyError(),
            },
            on: {
              change: function ($event) {
                return _vm.$v.acceptTermsAndPrivacyPolicy.$touch()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-inline-block f-12",
                        on: {
                          mouseup: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$stringConstants("agreeText"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "no-decoration",
                            attrs: {
                              href: _vm.termsOfServiceURL,
                              target: "_blank",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$stringConstants("serviceTermsText"))
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("and")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "no-decoration",
                            attrs: {
                              href: _vm.privacyPolicyURL,
                              target: "_blank",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$stringConstants("privacyPolicyText")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.acceptTermsAndPrivacyPolicy,
              callback: function ($$v) {
                _vm.acceptTermsAndPrivacyPolicy = $$v
              },
              expression: "acceptTermsAndPrivacyPolicy",
            },
          }),
          _c("Button", {
            attrs: {
              variant: "primary",
              color: "primary",
              loading: _vm.usersLoader,
              disabled: _vm.usersLoader || _vm.isFormInvalid,
              textValue: "Create account",
              rightIcon: "mdi-arrow-right",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.submit($event)
              },
            },
          }),
          _c(
            "div",
            { staticClass: "pt-5 text-center" },
            [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("alreadySignedUpText")) + " "
              ),
              _c(
                "router-link",
                {
                  staticClass: "no-decoration ml-1",
                  attrs: { to: _vm.routeLogin },
                },
                [
                  _c(
                    "span",
                    { staticClass: "font-weight-medium d-inline-block" },
                    [_vm._v("Log in")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }