//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import suOverviewComputedMixin from './su-overview-computed';
import DistributionLoaders from './su-overview-distribution-loader.vue';
export default {
  mixins: [suOverviewComputedMixin],
  components: {
    DistributionLoaders: DistributionLoaders
  },
  computed: {
    invoiceDistributions: {
      get: function get() {
        return this.adminOverviewOverallStatistics.businessesDistributionByInvoicingServices;
      }
    }
  }
};