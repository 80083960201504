import $ from 'jquery';
import ProductsListView from './products-list.vue';
import ProductEditForm from './products-edit-form.vue';
import ProductsImportForm from './products-import.vue';
import { closeOverlay } from '@/utils/helpers';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
export default {
  components: {
    ProductsListView: ProductsListView,
    ProductEditForm: ProductEditForm,
    ProductsImportForm: ProductsImportForm
  },
  mixins: [requestsCancellationMixin],
  mounted: function mounted() {
    this.$store.state.dashboardTitle = this.$stringConstants('productsTitle');
    $('body').on('click', '#overlay', function () {
      closeOverlay("editProductFormOverlay");
      closeOverlay("importProductsFormOverlay");
    });
  }
};