import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { formFieldPb } from "@/utils/uiconstants";
import { validateRegexNumeric } from '@/utils/helpers';
export default {
  name: "TeamJobsAddressesDropPinCoordinates",
  mixins: [TeamJobsComputed],
  data: function data() {
    return {
      formFieldPb: formFieldPb
    };
  },
  methods: {
    checkKeyDownNumeric: function checkKeyDownNumeric(event) {
      validateRegexNumeric(event);
    }
  }
};