import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { mapMutations, mapActions } from "vuex";
import FieldList from './field-list.vue';
import FieldsBulkUploadForm from './fields-bulk-upload-form.vue';
import Drawer from '@/components/shared/drawer.vue';
import FieldForm from './field-form.vue';
import FieldDetails from './field-details.vue';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import { routeFields, routeFieldsAdd, routeFieldsEdit } from "@/utils/endpoints";
import BulkActionResponseDialog from '@/components/shared/dialogs/bulk-action-response-dialog.vue';
export default {
  mixins: [requestsCancellationMixin],
  components: {
    FieldList: FieldList,
    FieldsBulkUploadForm: FieldsBulkUploadForm,
    Drawer: Drawer,
    FieldForm: FieldForm,
    FieldDetails: FieldDetails,
    BulkActionResponseDialog: BulkActionResponseDialog
  },
  computed: {
    drawerIsOpen: function drawerIsOpen() {
      return this.$store.state.drawerOpen;
    },
    showEditableFields: function showEditableFields() {
      return this.$store.state.fieldsModule.showEditableFields;
    },
    fieldDetails: function fieldDetails() {
      return this.$store.state.fieldsModule.fieldDetails;
    }
  },
  mounted: function mounted() {
    this.$store.state.dashboardTitle = this.$stringConstants('fieldsTitle');
    this.addNativeBrowserNavigationListener();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapMutations({
    resetFieldDetails: "resetFieldDetails",
    setJobsByField: "setJobsByField"
  })), mapActions({
    resetAreaToInitialState: "resetAreaToInitialState",
    cleanUpMapAndFieldForm: "cleanUpMapAndFieldForm"
  })), {}, {
    addNativeBrowserNavigationListener: function addNativeBrowserNavigationListener() {
      var _this = this;
      window.addEventListener('popstate', function (event) {
        if (location.pathname.includes(routeFieldsAdd) || location.pathname.includes(routeFieldsEdit)) {
          history.replaceState({}, null, routeFields);
        }
        _this.$store.commit('setDrawerOpen', false);
        _this.cleanUpMapAndFieldForm();
      });
    },
    resetFieldsPage: function resetFieldsPage() {
      this.$store.state.dialogBulkActionResponse = false;
      location.reload();
    }
  })
};