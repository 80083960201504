import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogDeleteCard },
        on: {
          cancel: _vm.cancelCardDeletion,
          proceed: function ($event) {
            return _vm.proceedDeleteCard()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("subscriptionCardDeleteTitle")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("subscriptionCardDeleteConfirm")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.showPromoCodeApplyDialog,
            callback: function ($$v) {
              _vm.showPromoCodeApplyDialog = $$v
            },
            expression: "showPromoCodeApplyDialog",
          },
        },
        [
          _c(
            VCard,
            {
              staticClass: "dlt-diag py-2",
              staticStyle: { height: "auto", "min-height": "260px" },
            },
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("applyPromoCodeText")) + " "
                ),
              ]),
              _c(VCardText, [
                _c(
                  "div",
                  { staticClass: "ib", staticStyle: { width: "80%" } },
                  [
                    _c(VTextField, {
                      staticClass: "pb-2",
                      attrs: {
                        label: "Enter promo code",
                        disabled: _vm.subscriptionPromocodeApplied,
                        outlined: "",
                        dense: "",
                      },
                      model: {
                        value: _vm.enteredPromoCodeValue,
                        callback: function ($$v) {
                          _vm.enteredPromoCodeValue =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "enteredPromoCodeValue",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ib", staticStyle: { width: "20%" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ml-3",
                        staticStyle: { transform: "translateY(-1px)" },
                      },
                      [
                        _c("ButtonMiniTertiaryDark", {
                          class: {
                            couponApplied: _vm.subscriptionPromocodeApplied,
                          },
                          style: {
                            "background-color": _vm.subscriptionPromocodeApplied
                              ? "#a20000 !important"
                              : _vm.enteredPromoCodeValue
                              ? "#1d34ad !important"
                              : "#767676 !important",
                            color: "#ffffff !important",
                          },
                          attrs: {
                            textValue: _vm.subscriptionPromocodeApplied
                              ? "Remove"
                              : "Apply",
                            height: 40,
                            width: 80,
                            disabled:
                              _vm.cardsLoader || !_vm.enteredPromoCodeValue,
                            loading: _vm.cardsLoader,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.applyPromoCode($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.subscriptionPromocodeApplied
                  ? _c(
                      "div",
                      {
                        staticClass: "pb-2",
                        staticStyle: { transform: "translateY(-20px)" },
                      },
                      [
                        _c("div", { staticClass: "pb-8" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.subscriptionSuccessDescription) +
                              " "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("div", [
                  _vm.subscriptionPromocodeApplied
                    ? _c(
                        "div",
                        {
                          staticClass: "ib fl-rt",
                          staticStyle: { transform: "translateX(10px)" },
                        },
                        [
                          _c("ButtonMiniTertiaryDark", {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              height: 40,
                              width: 140,
                              textValue: "Start subscription",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.initiateSubscription($event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "ib fl-rt",
                      staticStyle: { transform: "translateX(10px)" },
                    },
                    [
                      _vm.subscriptionPromocodeApplied
                        ? _c("ButtonMiniPrimary", {
                            attrs: { textValue: "Close" },
                            nativeOn: {
                              click: function ($event) {
                                _vm.showPromoCodeApplyDialog = false
                              },
                            },
                          })
                        : _c("ButtonMiniPrimary", {
                            staticStyle: { right: "1px", bottom: "-55px" },
                            attrs: { textValue: "Close" },
                            nativeOn: {
                              click: function ($event) {
                                _vm.showPromoCodeApplyDialog = false
                              },
                            },
                          }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "pt-0 pb-0" },
        [
          _c(
            VCol,
            { staticClass: "px-0 pt-0", attrs: { cols: "12" } },
            [
              !_vm.cardsLoader &&
              !_vm.subscriptionPlanLoader &&
              _vm.cardsList.length &&
              _vm.subscriptionDetails == null
                ? _c(
                    VRow,
                    { staticClass: "pt-0 pb-0 px-12" },
                    [
                      !_vm.subscriptionPromocodeApplied
                        ? _c(
                            VCol,
                            {
                              staticClass: "pt-0",
                              staticStyle: { "max-width": "170px" },
                              attrs: { cols: "12" },
                              on: {
                                click: function ($event) {
                                  _vm.showPromoCodeApplyDialog = true
                                },
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "sb-ap-pr-c-link",
                                    staticStyle: { width: "160px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "applyPromoCodeText"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.subscriptionPromocodeApplied &&
                      _vm.subscriptionSuccessDescription
                        ? _c(
                            VCol,
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "promo-container position-relative",
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.subscriptionSuccessDescription
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "reset-promocode-button" },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "oy-cl",
                                          attrs: { small: "" },
                                          on: { click: _vm.removePromoCode },
                                        },
                                        [_vm._v(" mdi-close-thick ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSystemTrialPeriodOver &&
              !_vm.cardsLoader &&
              !_vm.subscriptionPlanLoader &&
              _vm.cardsList.length &&
              _vm.subscriptionDetails &&
              _vm.subscriptionDetails.status ==
                _vm.SubscriptionStatusType.Active
                ? _c(
                    VRow,
                    { staticClass: "pt-0 pb-0 px-12" },
                    [
                      _c(
                        VCol,
                        { staticClass: "pr-0 py-0", attrs: { cols: "12" } },
                        [
                          _vm.subscriptionDetails.upcomingInvoice &&
                          _vm.subscriptionDetails.upcomingInvoice.status ==
                            _vm.InvoiceStatusType.Open
                            ? _c("div", { staticClass: "sb-pay-lnk" }, [
                                _c("div", { staticClass: "ib width-perc-70" }, [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "sb-pay-lnk-ttl" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "amountDueText"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "pt-2 fw-6" }, [
                                      _vm._v(
                                        " £" +
                                          _vm._s(
                                            _vm.subscriptionDetails
                                              .upcomingInvoice.totalAmount
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ib width-perc-30 text-right",
                                  },
                                  [
                                    _c("Button", {
                                      attrs: {
                                        variant: "list",
                                        color: "primary",
                                        text: "",
                                        textValue: "Pay",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.payBill(
                                            _vm.subscriptionDetails
                                              .upcomingInvoice.url
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSystemTrialPeriodOver &&
              !_vm.cardsLoader &&
              !_vm.subscriptionPlanLoader &&
              _vm.cardsList.length &&
              _vm.subscriptionDetails &&
              (_vm.subscriptionDetails.status ==
                _vm.SubscriptionStatusType.Pending ||
                _vm.subscriptionDetails.status ==
                  _vm.SubscriptionStatusType.Cancelled)
                ? _c(
                    VRow,
                    { staticClass: "pt-0 pb-0 px-12" },
                    [
                      _c(
                        VCol,
                        { staticClass: "pr-0 py-0", attrs: { cols: "12" } },
                        [
                          _vm.subscriptionDetails.latestInvoice &&
                          _vm.subscriptionDetails.latestInvoice.status ==
                            _vm.InvoiceStatusType.Open
                            ? _c("div", { staticClass: "sb-pay-lnk" }, [
                                _c("div", { staticClass: "ib width-perc-70" }, [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "sb-pay-lnk-ttl" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "amountDueText"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "pt-2 fw-6" }, [
                                      _vm._v(
                                        " £" +
                                          _vm._s(
                                            _vm.subscriptionDetails
                                              .latestInvoice.totalAmount
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ib width-perc-30 text-right",
                                  },
                                  [
                                    _c("Button", {
                                      attrs: {
                                        variant: "list",
                                        color: "primary",
                                        text: "",
                                        textValue: "Pay",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.payBill(
                                            _vm.subscriptionDetails
                                              .latestInvoice.url
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VRow,
                { staticClass: "pt-6 pb-0 px-12" },
                [
                  _c(VCol, { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "sb-pymt-cont" }, [
                      !_vm.cardsLoader && _vm.cardsList.length
                        ? _c(
                            "div",
                            {
                              staticClass: "sb-mng-cards-lnk",
                              on: { click: _vm.toggleManageCardsFormView },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants("manageCradText")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "sb-cs-plan" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("paymentInformationText")
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "pt-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("subscriptionCardAddedText")
                            ) +
                            " "
                        ),
                      ]),
                      _vm.cardsList && _vm.cardsList.length == 0
                        ? _c(
                            "div",
                            { staticClass: "pt-4" },
                            [
                              _c("ButtonMiniPrimary", {
                                attrs: {
                                  textValue: "+ Add card",
                                  width: 120,
                                  height: 40,
                                  disabled: _vm.cardsLoader,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.toggleManageCardsFormView($event)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                VRow,
                { staticClass: "pt-6 pb-0 px-12" },
                [
                  _c(
                    VCol,
                    { staticClass: "pt-0 pr-0", attrs: { cols: "12" } },
                    [
                      _vm.cardsList && _vm.cardsList.length && !_vm.cardsLoader
                        ? _c(
                            "div",
                            { staticClass: "sb-crd-main-container" },
                            _vm._l(_vm.cardsList, function (card, index) {
                              return _c("div", { key: index }, [
                                card.isDefaultCard
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ib",
                                          staticStyle: { width: "120px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sb-card-brand-cont ml-8 mt-6",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    card.brand.length >= 4
                                                      ? card.brand.slice(0, 4)
                                                      : card.brand
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ib sb-card-info-ls",
                                          staticStyle: { width: "auto" },
                                        },
                                        [
                                          _c("div", { staticClass: "mt-4" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$stringConstants(
                                                    "subscriptionCardEndingText"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("strong", [
                                              _vm._v(_vm._s(card.last4)),
                                            ]),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sb-card-info-ls-exp",
                                            },
                                            [
                                              _vm._v(
                                                " Exp " +
                                                  _vm._s(card.expirationMonth) +
                                                  "/" +
                                                  _vm._s(card.expirationYear) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.cardsLoader
                        ? _c("div", { staticClass: "sb-pymt-cont" }, [
                            _c(
                              "div",
                              { staticClass: "sb-crd-main-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ib",
                                    staticStyle: { width: "120px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ml-2 mt-6" },
                                      [
                                        _c(VSkeletonLoader, {
                                          staticClass: "mx-auto",
                                          attrs: {
                                            "max-width": "60",
                                            height: "38",
                                            type: "card",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ib",
                                    staticStyle: {
                                      width: "auto",
                                      transform: "translateY(-4px)",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(VSkeletonLoader, {
                                          attrs: {
                                            width: "200",
                                            height: "20",
                                            type: "card",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          transform: "translateY(4px)",
                                        },
                                      },
                                      [
                                        _c(VSkeletonLoader, {
                                          attrs: {
                                            width: "100",
                                            height: "15",
                                            type: "card",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SubsscriptionAddCardDialog"),
      _c("SubscriptionDefaultCardConfirmDialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }