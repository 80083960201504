export default {
  name: "ImageViewer",
  props: ["imageUrl"],
  computed: {
    showImagePreview: {
      get: function get() {
        return this.$store.getters.showImagePreview;
      },
      set: function set(newVal) {
        this.$store.state.showImagePreview = newVal;
      }
    }
  },
  methods: {
    openImageInNewTab: function openImageInNewTab() {
      window.open(this.imageUrl);
      this.showImagePreview = false;
    }
  }
};