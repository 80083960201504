import $ from 'jquery';
import OperationsListView from '@/components/operations/operations-list.vue';
import OperationsManageForm from '@/components/operations/operations-manage-form.vue';
import SubOperationEditForm from '@/components/operations/sub-operations-edit-form.vue';
import SubOperationAddForm from '@/components/operations/sub-operations-add-form.vue';
import { closeOverlay } from '@/utils/helpers';
import permissionsMixin from '@/mixins/permissions-mixin';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
export default {
  mixins: [permissionsMixin, requestsCancellationMixin],
  components: {
    OperationsListView: OperationsListView,
    OperationsManageForm: OperationsManageForm,
    SubOperationAddForm: SubOperationAddForm,
    SubOperationEditForm: SubOperationEditForm
  },
  data: function data() {
    return {
      activeFlyout: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$store.state.dashboardTitle = this.$stringConstants('operationsTitle');
    $('body').on('click', '#overlay', function () {
      closeOverlay("manageOperationsFormOverlay");
      closeOverlay("editOperationFormOverlay");
      closeOverlay("addSubOperationFormOverlay");
      closeOverlay("editSubOperationFormOverlay");
      _this.setActiveFlyout(null);
    });
  },
  methods: {
    setActiveFlyout: function setActiveFlyout(flyout) {
      this.activeFlyout = flyout;
    }
  }
};