import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { mapMutations } from "vuex";
import businessesComputedMixin from "./su-businesses-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import Timers from '@/mixins/timers';
import { getDateDifferenceInMinutes, getDateTimeFormattedLocal as _getDateTimeFormattedLocal, notify } from '@/utils/helpers';
import BusinessesDetailsTable from './su-businesses-details-table.vue';
import BusinessesMonthlySubscriptionChart from './su-businesses-details-subscriptions-chart.vue';
import { SubscriptionStatusTypeLabel } from '@/enum/subscriptionStatusType';
import superAdminAccessMixin from '@/mixins/super-admin-access-mixin';
import { DeleteItemDialog, DeleteItemDialog as ItemDialog } from '@/components/shared/dialogs/index';
import router from '@/router';
import { routeBusinesses } from '@/utils/endpoints';
import { buttonDeleteColor } from '@/utils/uiconstants';
export default {
  name: "BusinessesDetailsView",
  data: function data() {
    return {
      generalInfoTableName: 'General info',
      generalInfoHeader: ['Business Name', 'Type', 'Address'],
      ownerInfoTableName: 'Owner info',
      ownerInfoHeader: ['Name', 'Email Address', 'Phone Number'],
      teamMemberTableName: 'Team members',
      teamMemberHeader: ['Total', 'Active', 'Inactive'],
      subscriptionTableName: 'Subscription',
      subscriptionHeader: ['Start', 'End', 'Status'],
      jobsTableName: 'Jobs',
      jobsHeader: ['Completed', 'Invoiced', 'Total jobs', 'Last job date'],
      resoucesTableName: 'Resources',
      resourcesHeader: ['Fields', 'Vehicles', 'Products', 'Implements', 'Timesheets', 'Operations'],
      invoiceTableName: 'Invoice',
      invoiceHeader: ['Service', 'Invoice count'],
      customerTableName: 'Customers',
      customerHeader: ['Internal', 'External', 'Total'],
      dialogDelete: false,
      dialogChangeRole: false,
      buttonDeleteColor: buttonDeleteColor
    };
  },
  computed: {
    businessId: function businessId() {
      return window.location.pathname.split("/").pop();
    }
  },
  mixins: [businessesComputedMixin, Timers, superAdminAccessMixin],
  components: {
    BusinessesDetailsTable: BusinessesDetailsTable,
    BusinessesMonthlySubscriptionChart: BusinessesMonthlySubscriptionChart,
    Button: Button,
    DeleteItemDialog: DeleteItemDialog,
    ItemDialog: ItemDialog
  },
  beforeCreate: function beforeCreate() {
    this.$store.state.dashboardTitle = this.$stringConstants('businessesDetailsTitle');
  },
  mounted: function mounted() {
    this.showSuperAdminSubMenu = true;
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    resetPaging: "resetPaging"
  })), {}, {
    getDateTimeFormattedLocal: function getDateTimeFormattedLocal(dateTime) {
      return _getDateTimeFormattedLocal(dateTime) || 'N/A';
    },
    getSubscriptionStartDate: function getSubscriptionStartDate(subscriptions) {
      if ((subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.length) > 0 && (subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].currentPeriodStart)) {
        return this.getDateTimeFormattedLocal(subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].currentPeriodStart) || '';
      }
      if ((subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.length) > 0 && (subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].trialStart)) {
        return this.getDateTimeFormattedLocal(subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].trialStart) || '';
      }
    },
    getSubscriptionEndDate: function getSubscriptionEndDate(subscriptions) {
      if ((subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.length) > 0 && (subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].currentPeriodEnd)) {
        return this.getDateTimeFormattedLocal(subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].currentPeriodEnd) || '';
      }
      if ((subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.length) > 0 && (subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].trialEnd)) {
        return this.getDateTimeFormattedLocal(subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].trialEnd) || '';
      }
    },
    getSubscriptionStatus: function getSubscriptionStatus(subscriptions) {
      if ((subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.length) > 0 && (subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].status) !== null) {
        var subscriptionsObj = subscriptions[0];
        if (subscriptionsObj.cancellationScheduledOn) {
          return this.getCancellationScheduledText(subscriptionsObj);
        }
        return subscriptionsObj.status !== null ? SubscriptionStatusTypeLabel.get(subscriptionsObj.status) : '-';
      } else {
        if ((subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.length) > 0 && (subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].status) == null) {
          var _subscriptionsObj = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0];
          if (parseInt(getDateDifferenceInMinutes(_subscriptionsObj.trialEnd)) > 0) {
            return 'Trialing';
          }
          return 'Trial expired';
        }
      }
    },
    getCancellationReasonIfAny: function getCancellationReasonIfAny(subscriptions) {
      if ((subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.length) > 0 && (subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions[0].status) !== null) {
        var subscriptionsObj = subscriptions[0];
        if (subscriptionsObj.cancellationScheduledOn) {
          return subscriptionsObj.cancellationReason;
        }
      }
      return '';
    },
    promtUserDeletion: function promtUserDeletion() {
      this.dialogDelete = true;
    },
    promtUserRoleChange: function promtUserRoleChange() {
      this.dialogChangeRole = true;
    },
    proceedUserDeletion: function proceedUserDeletion() {
      var _this = this;
      this.dialogDelete = false;
      this.$store.dispatch('deleteBusiness', this.businessId).then(function (res) {
        if (res && (res === null || res === void 0 ? void 0 : res.status) == 200) {
          notify(_this.$stringConstants('businessDeleteSuccess'), 'success');
          router.push(routeBusinesses);
        }
      });
    },
    proceedUserRoleChange: function proceedUserRoleChange() {
      var _this2 = this;
      this.dialogChangeRole = false;
      this.$store.dispatch('businessChangeToContractor', this.businessId).then(function (res) {
        if (res) {
          notify(_this2.$stringConstants('businessRoleChangeSuccess'), 'success');
          _this2.$store.dispatch('getBusinessesDetails', _this2.businessId);
        }
      });
    }
  })
};