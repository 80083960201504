import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-9" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(
              VCol,
              { attrs: { cols: "8" } },
              [
                _c("HeaderPrimary", {
                  attrs: {
                    textValue: _vm.$stringConstants("operationManageTitle"),
                  },
                }),
              ],
              1
            ),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    attrs: { small: "" },
                    on: { click: _vm.closeOperationsManageForm },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    !_vm.subOperationLoader
      ? _c(
          "div",
          { staticClass: "form-scroll-view" },
          [
            _c(
              VContainer,
              {
                staticClass: "pt-7 pb-8",
                class: [_vm.containerLeftPad, _vm.containerRightPad],
              },
              [
                _c(
                  "div",
                  { staticClass: "pb-2" },
                  [
                    _c("SubHeaderPrimary", {
                      attrs: {
                        textValue: _vm.$stringConstants(
                          "operationManageSubtitle"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pt-1 pb-3" },
                  [
                    _c("DescriptionPrimary", {
                      attrs: {
                        textValue: _vm.$stringConstants(
                          "operationManageMainDescription"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(
                  _vm.requestOperationTypesList,
                  function (operationType, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pt-2 pl-2 mb-3 mt-1 pt-2 operations-active",
                          class: {
                            "operations-active": operationType.isActive,
                            "operations-inactive": !operationType.isActive,
                          },
                        },
                        [
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "width-perc-85 ib pl-2",
                                attrs: {
                                  title: _vm.getOperationNamesList(
                                    operationType.operations
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(operationType.title) +
                                    " " +
                                    _vm._s(
                                      operationType.operations &&
                                        operationType.operations.length > 0
                                        ? "(" +
                                            operationType.operations.length +
                                            ")"
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "width-perc-15 ib text-right pr-3",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cursor-pointer",
                                    class: {
                                      unselectable: !operationType.isSelectable,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSubOperationActiveToggle(
                                          $event,
                                          operationType
                                        )
                                      },
                                    },
                                  },
                                  [
                                    operationType.isActive
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              VIcon,
                                              {
                                                attrs: {
                                                  title: "Remove",
                                                  color:
                                                    operationType.isSelectable
                                                      ? "#717171"
                                                      : "#dedede",
                                                },
                                              },
                                              [_vm._v(" mdi-delete ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { attrs: { title: "Don't remove" } },
                                          [
                                            _c(VIcon, [
                                              _vm._v(" mdi-refresh "),
                                            ]),
                                          ],
                                          1
                                        ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ])
                  }
                ),
                _vm.addOperationTypesList.length != 0
                  ? _c(
                      "div",
                      { staticClass: "opr-add-bg pl-3 pt-3 mb-2 mt-4" },
                      [
                        _c("div", [
                          _c("h4", { staticClass: "pb-2" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("operationToAddText"))
                            ),
                          ]),
                        ]),
                        _vm._l(
                          _vm.addOperationTypesList,
                          function (addOperationType, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  VRow,
                                  [
                                    _c(
                                      VCol,
                                      {
                                        staticClass: "pt-0 pb-0 pl-0",
                                        attrs: { cols: "9" },
                                      },
                                      [
                                        _c("div", { staticClass: "ib pl-1" }, [
                                          _c("h5", [
                                            _vm._v(
                                              _vm._s(addOperationType.title)
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                addOperationType.description
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      VCol,
                                      {
                                        staticClass:
                                          "pt-0 pb-0 text-right pr-6",
                                        attrs: { cols: "3" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "ib" },
                                          [
                                            _c(
                                              VIcon,
                                              {
                                                attrs: { small: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeOperationType(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" mdi-delete ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _c("div", {
                  staticClass: "manage-operation-line pl-0 pr-0 ml-0 mr-0",
                }),
                _vm.showAddOperationBtn
                  ? _c(
                      "div",
                      { staticClass: "pt-0 h-12" },
                      [
                        _c("DescriptionPrimary", {
                          attrs: {
                            textValue: _vm.$stringConstants(
                              "operationManageAddButtonDescription1"
                            ),
                          },
                        }),
                        _c("DescriptionPrimary", {
                          attrs: {
                            textValue: _vm.$stringConstants(
                              "operationManageAddButtonDescription2"
                            ),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showAddOperationBtn
                  ? _c(
                      VRow,
                      {
                        staticClass: "mb-10 pt-4",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          VCol,
                          { staticClass: "pl-0", attrs: { cols: "4" } },
                          [
                            _c("Button", {
                              attrs: {
                                variant: "secondary",
                                outlined: "",
                                outlineColor: "gray",
                                textValue: "Add",
                                leftIcon: "mdi-plus",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.showAddOperation()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(VCol, { attrs: { cols: "8" } }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showAddOperationFields
                  ? _c(
                      "div",
                      { staticClass: "pl-0 pt-4 pb-4 mb-16 pr-4" },
                      [
                        _c(VTextField, {
                          attrs: { outlined: "", dense: "", required: "" },
                          model: {
                            value: _vm.operationTitle,
                            callback: function ($$v) {
                              _vm.operationTitle = $$v
                            },
                            expression: "operationTitle",
                          },
                        }),
                        _c(
                          VRow,
                          [
                            _c(
                              VCol,
                              {
                                staticClass: "px-0 pt-0 mt-0",
                                attrs: { cols: "9" },
                              },
                              [
                                _c("Button", {
                                  attrs: {
                                    variant: "tertiary",
                                    color: "primary",
                                    textValue: "Add",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.addNewOperationType()
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pl-2 ib cursor-pointer",
                                    on: { click: _vm.hideAddOperation },
                                  },
                                  [_c("ButtonCloseIcon")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "btns-bottom-box px-8 py-4" },
              [
                _c("Button", {
                  attrs: {
                    variant: "primary",
                    color: "primary",
                    disabled: _vm.fieldsInvalid || _vm.operationsLoader,
                    loading: _vm.operationsLoader,
                    textValue: _vm.$stringConstants("buttonNameSaveChanges"),
                    rightIcon: "mdi-arrow-right",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.saveOperations()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.subOperationLoader
      ? _c("div", { staticClass: "text-center" }, [_c("ListLoader")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }