import "core-js/modules/es.array.push.js";
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { ButtonActionPrimary, HoverTextButton } from '@/components/shared/buttons/index';
import { routeJobs } from '@/utils/endpoints';
import router from '@/router/index';
import { getOperatorsWithChecklists } from '@/utils/helpers/jobs-helpers';
export default {
  name: "TeamJobsAdd",
  mixins: [TeamJobsComputed],
  components: {
    ButtonActionPrimary: ButtonActionPrimary,
    HoverTextButton: HoverTextButton
  },
  methods: {
    getOperators: function getOperators() {
      return getOperatorsWithChecklists(this.teamJobOperation, this.teamJobOperators);
    },
    cancelAddTeamJob: function cancelAddTeamJob() {
      router.push(routeJobs);
    }
  }
};