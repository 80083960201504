import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';
export default {
  name: 'ConfirmationDialogBox',
  props: {
    reasonRequired: Boolean,
    value: Boolean,
    loading: Boolean
  },
  data: function data() {
    return {
      cancelTeamJobReason: ""
    };
  },
  components: {
    SecondaryButton: SecondaryButton
  },
  watch: {
    value: function value(newValue) {
      if (!newValue) {
        this.cancelTeamJobReason = "";
      }
    }
  }
};