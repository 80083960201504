import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "border-radius-3 bg-white " }, [
      _c(
        "div",
        {
          staticClass:
            "pL-6 pR-24 mT-none mB-none md-size-100 field-search-container",
          staticStyle: { transform: "translateX(-2px) translateY(-1px)" },
        },
        [
          _c(
            VIcon,
            {
              staticClass: "pt-1 pr-2",
              staticStyle: { "padding-top": "6px !important" },
              attrs: { color: "black" },
            },
            [_vm._v(" mdi-magnify ")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.fieldSearchTextReadOnly,
                expression: "fieldSearchTextReadOnly",
              },
            ],
            staticClass: "input-search fm-light fc-grey fs-16",
            attrs: {
              type: "text",
              disabled: _vm.fieldsListForSearchReadOnly.length == 0,
              placeholder: _vm.$stringConstants("searchFieldPlaceholder"),
              id: "searchFields",
              autocomplete: "off",
            },
            domProps: { value: _vm.fieldSearchTextReadOnly },
            on: {
              keyup: [
                _vm.searchFieldNames,
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchFields($event)
                },
              ],
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.fieldSearchTextReadOnly = $event.target.value
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm.fieldSearchResultsReadOnly && _vm.fieldSearchResultsReadOnly.length > 0
      ? _c(
          "div",
          { staticClass: "hs-drp" },
          _vm._l(
            _vm.fieldSearchResultsReadOnly.slice(0, 4),
            function (result, rindex) {
              return _c("div", { key: rindex, staticClass: "hs-drp-item" }, [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.navigateToField(result.field.id)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(result.title) + " ")]
                ),
              ])
            }
          ),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }