import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ob-pad" }, [
    _c("div", [
      _c("div", { staticClass: "ob-maintitle" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$stringConstants("onboardBusinessTitle1") +
                _vm.userProfile.firstName +
                _vm.$stringConstants("onboardBusinessTitle2")
            ) +
            " "
        ),
      ]),
      _c("div", { staticClass: "ob-description pt-2 pb-6" }, [
        _vm._v(
          " " + _vm._s(_vm.$stringConstants("onboardBusinessSubtitle")) + " "
        ),
      ]),
      _c("div", { staticClass: "pt-3" }, [
        _c(
          "div",
          { staticClass: "ib w-50", staticStyle: { "vertical-align": "top" } },
          [
            _c("div", { staticClass: "ob-subtitle" }, [
              _vm._v(_vm._s(_vm.$stringConstants("businessDetailsText"))),
            ]),
            _c(
              "div",
              { staticClass: "mr-6", staticStyle: { "max-width": "425px" } },
              [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        _vm._s(_vm.$stringConstants("emailAddress")) + " *"
                      ),
                    ]),
                    _c(VTextField, {
                      class: _vm.formFieldPb,
                      attrs: {
                        placeholder: _vm.$stringConstants("emailPlaceholder"),
                        disabled: "",
                        outlined: "",
                        dense: "",
                      },
                      model: {
                        value: _vm.userProfile.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.userProfile, "email", $$v)
                        },
                        expression: "userProfile.email",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        _vm._s(_vm.$stringConstants("companyName")) + " *"
                      ),
                    ]),
                    _c(VTextField, {
                      class: _vm.formFieldPb,
                      attrs: {
                        placeholder: _vm.$stringConstants(
                          "businessTitlePlaceholder"
                        ),
                        outlined: "",
                        dense: "",
                      },
                      on: {
                        keydown: function ($event) {
                          return _vm.updatePrefix($event)
                        },
                      },
                      model: {
                        value: _vm.userProfile.businessProfile.title,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile,
                            "title",
                            $$v
                          )
                        },
                        expression: "userProfile.businessProfile.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "position-relative" },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        _vm._s(_vm.$stringConstants("companyJobCode")) + " *"
                      ),
                    ]),
                    _c(VTextField, {
                      class: _vm.formFieldPb,
                      attrs: {
                        outlined: "",
                        dense: "",
                        maxlength: 3,
                        required: "",
                      },
                      model: {
                        value: _vm.businessProfilePrefixTemp,
                        callback: function ($$v) {
                          _vm.businessProfilePrefixTemp = $$v
                        },
                        expression: "businessProfilePrefixTemp",
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          position: "absolute",
                          right: "0",
                          top: "0",
                        },
                      },
                      [
                        _c(
                          VTooltip,
                          {
                            attrs: {
                              bottom: "",
                              color: "#000",
                              "max-width": "300px",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      VIcon,
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            staticStyle: {
                                              color: "#000 !important",
                                            },
                                            attrs: { small: "" },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("mdi-information")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("span", { staticClass: "tol-cont" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants("jobPrefixText")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "ib w-50", staticStyle: { "vertical-align": "top" } },
          [
            _c("div", { staticClass: "ob-subtitle" }, [
              _vm._v(_vm._s(_vm.$stringConstants("companyAddress"))),
            ]),
            _c("div", { staticStyle: { "max-width": "425px" } }, [
              _c(
                "div",
                [
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(
                      _vm._s(_vm.$stringConstants("addressesLine1")) + " *"
                    ),
                  ]),
                  _c(VTextField, {
                    class: _vm.formFieldPb,
                    attrs: {
                      placeholder: _vm.$stringConstants(
                        "addressLineOnePlaceholder"
                      ),
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value:
                        _vm.userProfile.businessProfile.address.addressLine1,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.userProfile.businessProfile.address,
                          "addressLine1",
                          $$v
                        )
                      },
                      expression:
                        "userProfile.businessProfile.address.addressLine1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("addressesLine2"))),
                  ]),
                  _c(VTextField, {
                    class: _vm.formFieldPb,
                    attrs: {
                      placeholder: _vm.$stringConstants(
                        "addressLineTwoPlaceholder"
                      ),
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value:
                        _vm.userProfile.businessProfile.address.addressLine2,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.userProfile.businessProfile.address,
                          "addressLine2",
                          $$v
                        )
                      },
                      expression:
                        "userProfile.businessProfile.address.addressLine2",
                    },
                  }),
                ],
                1
              ),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "ib pr-2", staticStyle: { width: "50%" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("townAndCity"))),
                    ]),
                    _c(VTextField, {
                      class: _vm.formFieldPb,
                      attrs: {
                        placeholder: _vm.$stringConstants("cityPlaceholder"),
                        outlined: "",
                        dense: "",
                        attach: "",
                      },
                      model: {
                        value: _vm.userProfile.businessProfile.address.city,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.address,
                            "city",
                            $$v
                          )
                        },
                        expression: "userProfile.businessProfile.address.city",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ib pl-2", staticStyle: { width: "50%" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("stateAndCounty"))),
                    ]),
                    _c(VTextField, {
                      class: _vm.formFieldPb,
                      attrs: {
                        placeholder: _vm.$stringConstants("countyPlaceholder"),
                        outlined: "",
                        dense: "",
                        attach: "",
                      },
                      model: {
                        value: _vm.userProfile.businessProfile.address.state,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.address,
                            "state",
                            $$v
                          )
                        },
                        expression: "userProfile.businessProfile.address.state",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "ib pr-2", staticStyle: { width: "50%" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("country"))),
                    ]),
                    _c(VAutocomplete, {
                      attrs: {
                        items: _vm.globalCountriesList,
                        "item-text": "name",
                        "item-value": "name",
                        outlined: "",
                        placeholder: _vm.$stringConstants("countryPlaceholder"),
                        dense: "",
                        flat: "",
                        solo: "",
                      },
                      model: {
                        value: _vm.userProfile.businessProfile.address.country,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.address,
                            "country",
                            $$v
                          )
                        },
                        expression:
                          "userProfile.businessProfile.address.country",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ib pl-2", staticStyle: { width: "50%" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("postalCode")) + " *"),
                    ]),
                    _c(VTextField, {
                      class: _vm.formFieldPb,
                      attrs: {
                        maxlength: "20",
                        placeholder: _vm.$stringConstants(
                          "postalCodePlaceholder"
                        ),
                        outlined: "",
                        dense: "",
                      },
                      on: {
                        keydown: function ($event) {
                          return _vm.checkKeyDownAlphaNumeric($event)
                        },
                      },
                      model: {
                        value:
                          _vm.userProfile.businessProfile.address.postalCode,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.address,
                            "postalCode",
                            $$v
                          )
                        },
                        expression:
                          "userProfile.businessProfile.address.postalCode",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }