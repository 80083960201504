import InvoicesAdd from '@/components/invoices/invoices-add/invoices-add.vue';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';
export default {
  name: 'InvoicesDetails',
  mixins: [breadcrumbsMixin],
  components: {
    InvoicesAdd: InvoicesAdd
  },
  computed: {
    currentInvoiceId: {
      get: function get() {
        return window.location.pathname.split("/").pop();
      }
    },
    invoiceSelectedTab: {
      get: function get() {
        return this.$store.getters.invoiceSelectedTab;
      },
      set: function set(newVal) {
        this.$store.state.invoicesModule.invoiceSelectedTab = newVal;
      }
    },
    invoiceDraftPreviewDetails: {
      get: function get() {
        return this.$store.getters.invoiceDraftPreviewDetails;
      },
      set: function set(newVal) {
        this.$store.state.invoicesModule.invoiceDraftPreviewDetails = newVal;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.setBreadcrumbs('InvoiceDetails');
    this.$store.dispatch('getInvoiceDetails', this.currentInvoiceId).then(function () {
      var _a;
      if ((_a = _this.invoiceDraftPreviewDetails) === null || _a === void 0 ? void 0 : _a.number) {
        _this.setBreadcrumbs('InvoiceDetails', _this.invoiceDraftPreviewDetails.number);
      }
    });
    this.invoiceSelectedTab = 3;
  }
};