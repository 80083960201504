import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-skeleton-loader" },
    _vm._l(3, function (count) {
      return _c(
        VRow,
        {
          key: count,
          staticClass: "lt-vw py-6 px-9 list-min-ht-impl",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _vm._l(_vm.columnWidthsArray, function (ref, index) {
            var width = ref.width
            var left = ref.left
            return _c(
              "div",
              {
                key: index,
                style: { width: width, left: left, position: "absolute" },
              },
              [
                _c(
                  "div",
                  { staticClass: "f-br-wd" },
                  [
                    _c(VSkeletonLoader, {
                      class: "px-0",
                      attrs: {
                        height: "15",
                        "max-width": "100",
                        type: "list-item",
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          _c(
            VCol,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.containsAction,
                  expression: "containsAction",
                },
              ],
              style: {
                width: _vm.actionColumnWidth.width,
                left: _vm.actionColumnWidth.left,
                position: "absolute",
              },
            },
            [
              _c(VSkeletonLoader, {
                class: "ml-auto",
                attrs: { height: "15", "max-width": "100", type: "button" },
              }),
            ],
            1
          ),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }