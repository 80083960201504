import { formFieldPb } from '@/utils/uiconstants';
import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
export default {
  name: 'PersonalDetailsForm',
  mixins: [accountComputedMixin],
  components: {
    SectionLeftHeadings: SectionLeftHeadings
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb
    };
  }
};