import accountComputedMixin from '@/components/account/account-computed';
import { validateRegexAlphaNumeric, validateRegexAlpha } from '@/utils/helpers';
export default {
  name: 'ProfileAddressForm',
  mixins: [accountComputedMixin],
  methods: {
    checkKeyDownAlpha: function checkKeyDownAlpha(event) {
      validateRegexAlpha(event);
    },
    checkKeyDownAlphaNumeric: function checkKeyDownAlphaNumeric(event) {
      validateRegexAlphaNumeric(event);
    }
  }
};