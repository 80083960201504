import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { mapMutations } from 'vuex';
import { openOverlay } from '@/utils/helpers';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'farmersModule': ['addFarmerLoader', 'farmerDetails', 'farmerSearchText', 'farmerFilterType', 'farmerType', 'farmersFilterList', 'farmerConReqList', 'farmerSendReqList', 'isShowConReq', 'pendingFarmersList', 'updateFormEnabled', 'importIntegratedCustomerLoader', 'integrationContactSearchText', 'contactsIntegration', 'allIntegrationCustomersLoaded', 'fetchingintergrationDataProgressCustomer', 'tempIntegratedCustomers', 'intergrationHasDataCustomer', 'customerLoaderImport', 'integratedCustomerImportInProgress', 'farmersLoader', 'farmerStatus'],
  'usersModule': ['userInviteeEmail', 'dialogInvitation', 'invitationId', 'connectionRequestType', 'dialogType'],
  'integrationsModule': ['integrationType', 'xeroAccessState', 'quickbookAccessState', 'sageAccessState', 'enumImportStatus'],
  'root': ['dialogBulkActionResponse', 'dialogBulkActionResponseTitle', 'bulkActionResponse', 'loginUserRole', 'filtersApplied', 'statusFilterTypeList', 'customerTypeFilterList', 'inviteeRole', 'noData', 'listCount', 'totalRowCount', 'conReqLoader', 'conSendLoader', 'isPageRelated', 'enumActiveStatus']
};
export default {
  name: 'FarmersComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    invitationDialogPromptTitle: function invitationDialogPromptTitle() {
      return this.dialogType == 'accept' ? StringConstants.userAcceptInvitationPromptTitle : StringConstants.userDeleteInvitationPromptTitle;
    },
    invitationDialogPromptSubtitle: function invitationDialogPromptSubtitle() {
      return this.dialogType == 'accept' ? StringConstants.userAcceptInvitationPromptSubtitle : StringConstants.userDeleteInvitationPromptSubtitle;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFarmerFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    confirmDeleteInvitation: function confirmDeleteInvitation(id, connectionRequestType) {
      this.invitationId = id;
      this.dialogInvitation = true;
      this.connectionRequestType = connectionRequestType;
      this.dialogType = 'delete';
    },
    confirmAcceptInvitation: function confirmAcceptInvitation(id) {
      this.invitationId = id;
      this.dialogInvitation = true;
      this.dialogType = 'accept';
    },
    acceptConnection: function acceptConnection() {
      var _this = this;
      this.$store.dispatch('acceptUserInvite', this.invitationId).then(function (res) {
        if (res) {
          _this.farmerConReqList = _this.farmerConReqList.filter(function (x) {
            return x.code != _this.invitationId;
          });
          _this.refreshFarmerList();
        }
        _this.dialogInvitation = !_this.dialogInvitation;
      });
    },
    declineConnection: function declineConnection() {
      var _this2 = this;
      this.$store.dispatch('declineUserInvite', this.invitationId).then(function (res) {
        if (res) {
          _this2.farmerConReqList = _this2.farmerConReqList.filter(function (x) {
            return x.code != _this2.invitationId;
          });
        }
        _this2.dialogInvitation = !_this2.dialogInvitation;
      });
    },
    deleteSentConnection: function deleteSentConnection() {
      var _this3 = this;
      this.$store.dispatch('deleteSentConReq', this.invitationId).then(function (res) {
        if (res) {
          _this3.farmerSendReqList = _this3.farmerSendReqList.filter(function (x) {
            return x.code != _this3.invitationId;
          });
        }
        _this3.dialogInvitation = !_this3.dialogInvitation;
      });
    },
    clearFarmerList: function clearFarmerList() {
      this.$store.state.farmersModule.farmersFilterList = [];
      this.clearFarmerFilterOption();
      this.resetPaging();
    },
    refreshFarmerList: function refreshFarmerList() {
      var _this4 = this;
      this.clearFarmerList();
      this.$store.dispatch('setFarmersFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function (res) {
        _this4.$store.dispatch('getFarmersFiltered');
      });
    },
    hideConReqList: function hideConReqList() {
      this.isShowConReq = false;
    },
    getFarmersListView: function getFarmersListView() {
      var _this5 = this;
      var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      this.$store.dispatch('setFarmersFilter', filters).then(function () {
        _this5.$store.dispatch('getFarmersFiltered').then(function () {
          if (_this5.farmerSearchText == '') {
            _this5.clearFarmerFilterOption();
            _this5.filtersApplied = false;
          }
        });
      });
    },
    openManageFarmerForm: function openManageFarmerForm() {
      openOverlay("manageFarmerFormOverlay");
      this.$store.dispatch('getFarmersConReq');
      this.$store.dispatch('getFarmersSendReq');
      this.hideConReqList();
    }
  })
};