//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['loaderRows']
};