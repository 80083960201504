export default {
  data: function data() {
    return {
      tempParams: null
    };
  },
  methods: {
    setLocalParamsMx: function setLocalParamsMx(selectedStateValue, localParamName) {
      if (selectedStateValue || selectedStateValue === false) {
        localStorage.setItem(localParamName, selectedStateValue);
      } else if (selectedStateValue == '' || selectedStateValue == undefined && localStorage.getItem(localParamName)) {
        localStorage.removeItem(localParamName);
      }
    },
    getLocalParamsMx: function getLocalParamsMx(localParamName, defaultStateValue) {
      if (localStorage.getItem(localParamName)) {
        return localStorage.getItem(localParamName);
      } else {
        return defaultStateValue;
      }
    },
    removeLocalParamsMx: function removeLocalParamsMx(localParamName, selectedStateValue) {
      localStorage.removeItem(localParamName);
      return selectedStateValue;
    }
  }
};