import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "su-db-box-wrapper su-db-box-minh" }, [
      _c("div", { staticClass: "su-db-header position-relative" }, [
        _c("div", { staticClass: "ib" }, [
          _c("span", [_vm._v(" Statistics ")]),
          !_vm.adminOverviewCurrentStatisticsDateFilterApplied
            ? _c("span", { staticClass: "su-recent-dt" }, [
                _vm._v(" (Showing data from last 7 days) "),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "ib" }, [
          _c(
            "div",
            { staticClass: "ib current-stats-date position-absolute" },
            [
              _c("DatePicker", {
                ref: "datePicker",
                class: _vm.adminOverviewCurrentStatisticsDateFilterApplied
                  ? "active"
                  : "",
                attrs: {
                  dateInput: _vm.dateInput,
                  format: _vm.format,
                  sameDateFormat: _vm.sameDateFormat,
                  "switch-button-initial": true,
                  "show-helper-buttons": false,
                  language: "en",
                },
                on: { "date-applied": _vm.filterCurrentStatisticsByDateRange },
              }),
            ],
            1
          ),
          _vm.adminOverviewCurrentStatisticsDateFilterApplied
            ? _c("div", { staticClass: "ib" }, [
                _c(
                  "div",
                  {
                    staticClass: "cursor-pointer su-ov-dt-close",
                    on: { click: _vm.clearCurrentStatisticsDateFilter },
                  },
                  [
                    _c(VIcon, { attrs: { small: "" } }, [
                      _vm._v(" mdi-close-thick "),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "ib su-db-container-left",
            staticStyle: { width: "35%", "vertical-align": "top" },
          },
          [
            _c("div", { staticClass: "su-db-title pb-8 pt-3" }, [
              _vm._v(" Registrations "),
            ]),
            !_vm.adminRecentOverviewLoader
              ? _c(
                  "div",
                  [
                    _c("SuDashboardCurrentStatisticsCounter", {
                      attrs: {
                        counterTitle: "New businesses",
                        counterValue:
                          _vm.adminOverviewRecentStatistics.businesses,
                        counterSummary: null,
                      },
                    }),
                  ],
                  1
                )
              : _c("div", [_c("StatsLoader", { attrs: { loaderRows: 2 } })], 1),
            _c("div", { staticClass: "pt-7" }, [
              !_vm.adminRecentOverviewLoader
                ? _c(
                    "div",
                    [
                      _c("SuDashboardCurrentStatisticsCounter", {
                        attrs: {
                          counterTitle: "New users",
                          counterValue: _vm.adminOverviewRecentStatistics.users,
                          counterSummary: null,
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [_c("StatsLoader", { attrs: { loaderRows: 2 } })],
                    1
                  ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "ib su-db-container-right pr-0",
            staticStyle: { width: "65%", "vertical-align": "top" },
          },
          [
            _c("div", { staticClass: "su-db-title pb-8 pt-3" }, [
              _vm._v(" Recent subscription activity "),
            ]),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "ib",
                  staticStyle: { width: "35%", "vertical-align": "top" },
                },
                [
                  !_vm.adminRecentOverviewLoader
                    ? _c(
                        "div",
                        [
                          _c("SuDashboardCurrentStatisticsCounter", {
                            attrs: {
                              counterTitle: "Payments",
                              counterValue:
                                _vm.adminOverviewRecentStatistics
                                  .paidSubscriptions,
                              counterSummary: null,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [_c("StatsLoader", { attrs: { loaderRows: 2 } })],
                        1
                      ),
                  !_vm.adminRecentOverviewLoader
                    ? _c(
                        "div",
                        { staticClass: "pt-7" },
                        [
                          _c("SuDashboardCurrentStatisticsCounter", {
                            attrs: {
                              counterTitle: "Trials",
                              counterValue:
                                _vm.adminOverviewRecentStatistics
                                  .businessesOnTrial,
                              counterSummary: null,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "pt-7" },
                        [_c("StatsLoader", { attrs: { loaderRows: 2 } })],
                        1
                      ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "ib pl-3",
                  staticStyle: { width: "65%", "vertical-align": "top" },
                },
                [
                  !_vm.adminRecentOverviewLoader
                    ? _c(
                        "div",
                        [
                          _c("SuDashboardCurrentStatisticsCounter", {
                            attrs: {
                              counterTitle: "Collection",
                              counterValue:
                                _vm.currencySymbol +
                                _vm.adminOverviewRecentStatistics
                                  .subscriptionsCollection,
                              counterSummary: null,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [_c("StatsLoader", { attrs: { loaderRows: 2 } })],
                        1
                      ),
                  !_vm.adminRecentOverviewLoader
                    ? _c(
                        "div",
                        { staticClass: "pt-7" },
                        [
                          _c("SuDashboardCurrentStatisticsCounter", {
                            attrs: {
                              counterTitle: "Expired or Cancelled",
                              counterValue:
                                _vm.adminOverviewRecentStatistics
                                  .expiredSubscriptions,
                              counterSummary: null,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "pt-7" },
                        [_c("StatsLoader", { attrs: { loaderRows: 2 } })],
                        1
                      ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }