import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "px-9 pt-10", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants(
                    "accountDetailsContactInfoHeading1"
                  ),
                  textValue2: _vm.$stringConstants(
                    "accountDetailsContactInfoHeading2"
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "7" } },
            [
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("phoneNumber")) + " * "
                ),
              ]),
              _c(VTextField, {
                class: _vm.formFieldPb,
                attrs: { type: "number", outlined: "", dense: "" },
                on: {
                  keydown: function ($event) {
                    return _vm.checkPhoneNumber($event)
                  },
                },
                model: {
                  value: _vm.userProfile.primaryContactNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.userProfile, "primaryContactNumber", $$v)
                  },
                  expression: "userProfile.primaryContactNumber",
                },
              }),
              _c(
                VCol,
                { staticClass: "pt-0 px-0 min-hd", attrs: { cols: "12" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("secondaryContact")) + " "
                  ),
                ]
              ),
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(" " + _vm._s(_vm.$stringConstants("name")) + " "),
              ]),
              _c(VTextField, {
                class: _vm.formFieldPb,
                attrs: { outlined: "", dense: "" },
                model: {
                  value: _vm.userProfile.secondaryFirstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.userProfile, "secondaryFirstName", $$v)
                  },
                  expression: "userProfile.secondaryFirstName",
                },
              }),
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(" " + _vm._s(_vm.$stringConstants("phoneNumber")) + " "),
              ]),
              _c(VTextField, {
                class: _vm.formFieldPb,
                attrs: { type: "number", outlined: "", dense: "" },
                on: {
                  keydown: function ($event) {
                    return _vm.checkPhoneNumber($event)
                  },
                },
                model: {
                  value: _vm.userProfile.secondaryContactNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.userProfile, "secondaryContactNumber", $$v)
                  },
                  expression: "userProfile.secondaryContactNumber",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }