var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ImportData", {
        attrs: {
          importListName: "products",
          importOverlayCloseFn: _vm.closeImportProductsForm,
          importSampleData: _vm.productsSampleData,
          importDispatchFn: "importProductsExcel",
          inprogressText1: "Products import in progress",
          inprogressText2: "We're currently importing your products.",
          uploadFileText: "Upload a file for products",
          uploadNoteTitle: "Instructions",
          uploadNotes: _vm.uploadNotes,
          importResourceType: _vm.importResourceType,
          requiredFields: _vm.requiredImportFields,
        },
        on: { "on-success-callback": _vm.refreshProductsList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }