var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.moreData && !_vm.loadInProgress
      ? _c("div", [
          _vm.loadingListLength != 0
            ? _c(
                "div",
                {
                  staticClass: "text-center cursor-pointer load-more-text",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("appendMore")
                    },
                  },
                },
                [_vm._v(" Load more ")]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.loadInProgress && _vm.moreData
      ? _c("div", { staticClass: "text-center" }, [_c("EllipsisMiniLoader")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }