import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { staticClass: "px-9 pt-10 pb-3", attrs: { cols: "12" } },
            [
              _c("div", { staticClass: "ib up-tl" }, [
                _vm._v(
                  "Jobs (" + _vm._s(_vm.contactOnGoingJobList.length) + ")"
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "ib float-right ank-txt",
                  on: {
                    click: function ($event) {
                      return _vm.navigateToJobView(_vm.resId)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$stringConstants("seeAll")) + " ")]
              ),
            ]
          ),
          _c(
            VCol,
            {
              staticClass: "pt-6 pb-5 px-9 lt-vw text-uppercase list-heading",
              attrs: { cols: "12" },
            },
            [
              _c(VRow, { attrs: { "no-gutters": "", cols: "12" } }, [
                _c("div", { staticClass: "ib width-perc-15" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("jobNo"))),
                ]),
                _c("div", { staticClass: "ib width-perc-15" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("operation"))),
                ]),
                _c("div", { staticClass: "ib width-perc-15" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("scheduledFor"))),
                ]),
                _c("div", { staticClass: "ib width-perc-15" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("fields"))),
                ]),
                _c("div", { staticClass: "ib width-perc-15" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("operators"))),
                ]),
                _c("div", { staticClass: "ib width-perc-15" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("billableQuantity"))),
                ]),
                _c("div", { staticClass: "ib width-perc-10 text-right" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("status"))),
                ]),
              ]),
            ],
            1
          ),
          _vm._l(_vm.contactOnGoingJobList, function (job, index) {
            return _c(
              VCol,
              {
                key: index,
                staticClass: "py-6 px-9 lt-vw frm-row",
                attrs: { cols: "12" },
                on: {
                  click: function ($event) {
                    return _vm.openCustomerJobDetails(job.id)
                  },
                },
              },
              [
                _c(VRow, { attrs: { "no-gutters": "", cols: "12" } }, [
                  _c("div", { staticClass: "ib width-perc-15" }, [
                    _vm._v(_vm._s(job.number)),
                  ]),
                  job.operation != null
                    ? _c("div", { staticClass: "ib width-perc-15" }, [
                        _vm._v(" " + _vm._s(job.operation.title) + " "),
                      ])
                    : _c("div", { staticClass: "ib width-perc-15" }, [
                        _vm._v("-"),
                      ]),
                  _c("div", { staticClass: "ib width-perc-15" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          job.scheduledAt != null
                            ? _vm.getDateTimeFormat(job.scheduledAt)
                            : "No date"
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "ib width-perc-15" }, [
                    _c("div", { staticClass: "ib" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            job.fields.length != 0 ? job.fields[0].title : "-"
                          )
                      ),
                      job.fields.length > 1
                        ? _c("span", [
                            _vm._v(", + " + _vm._s(job.fields.length - 1)),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ib width-perc-15" },
                    [
                      _c("Avatar", {
                        staticClass: "mr-2",
                        attrs: { initials: _vm.getAssignedUserName(job) },
                      }),
                      _vm._v(" " + _vm._s(_vm.getAssignedUserName(job))),
                      job.operators.length > 1
                        ? _c("span", [
                            _vm._v(", + " + _vm._s(job.operators.length - 1)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ib width-perc-15" }, [
                    job.operation.billingQuantities.length != 0
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                job.operation.billingQuantities[0]
                                  .billingQuantity
                              ) +
                              " (" +
                              _vm._s(job.operation.billingUnit) +
                              ") "
                          ),
                        ])
                      : _c("div", [_vm._v(" NA ")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ib width-perc-10 text-right",
                      staticStyle: { color: "#0e96fd" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.TeamJobStatusTypeLabel.get(job.status)) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }