import { avatarColorDisabled } from '@/utils/uiconstants';
export default {
  name: 'AvatarDisabled',
  props: ['initials'],
  methods: {
    getInitials: function getInitials(data) {
      if (data != null) {
        var chr;
        var d = data.toUpperCase();
        chr = d.split(" ");
        if (chr.length >= 2) {
          return chr[0][0] + chr[1][0];
        } else {
          return d[0] + d[1];
        }
      }
      return '';
    },
    getDisabledColor: function getDisabledColor() {
      return avatarColorDisabled;
    }
  }
};