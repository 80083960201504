import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-8" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(VCol, { staticClass: "oy-tl", attrs: { cols: "8" } }, [
              _vm._v(" " + _vm._s(_vm.$stringConstants("importText")) + " "),
              _c("span", { staticClass: "text-capitalize" }, [
                _vm._v(_vm._s(_vm.importListName)),
              ]),
            ]),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    staticClass: "oy-cl",
                    attrs: { small: "", id: _vm.importOverlayCloseFn },
                    on: { click: _vm.importOverlayCloseFn },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm.importResponseData == null && !_vm.importInProgress
      ? _c(
          "div",
          {
            staticClass: "pt-5",
            class: [_vm.containerLeftPad, _vm.containerRightPad],
          },
          [
            _c(
              "div",
              { staticClass: "py-2" },
              [
                _c("SubHeaderPrimary", {
                  attrs: { textValue: _vm.uploadFileText },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "pt-1 pb-6" }, [
              _c(
                "span",
                {
                  staticClass: "sample-dn-link",
                  on: { click: _vm.downloadSampleCustomersFile },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("downloadSampleCsvText")) +
                      " "
                  ),
                ]
              ),
              _c("span", { staticClass: "agd-description-primary" }, [
                _vm._v(_vm._s(_vm.$stringConstants("csvRequiredFormatText"))),
              ]),
              _c(
                "span",
                {},
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "", color: "#000" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  VIcon,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "cursor-pointer",
                                        staticStyle: {
                                          color: "#000!important",
                                        },
                                        attrs: { small: "" },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v("mdi-information")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        805669495
                      ),
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "tol-cont" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants("csvRequiredFieldText")
                              )
                          ),
                          _c("br"),
                          _vm._l(_vm.requiredFields, function (item, indx) {
                            return _c("span", { key: indx }, [
                              _vm._v(" " + _vm._s(item) + " "),
                              _c("br"),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.uploadNoteTitle
                ? _c("div", { staticClass: "fw-5 mt-5 pb-2" }, [
                    _vm._v(" " + _vm._s(_vm.uploadNoteTitle) + " "),
                  ])
                : _vm._e(),
              _vm.uploadNotes && _vm.uploadNotes.length
                ? _c(
                    "div",
                    _vm._l(_vm.uploadNotes, function (note, noteIndex) {
                      return _c(
                        "div",
                        { key: noteIndex, staticClass: "fw-5 pb-1" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "agd-description-primary agd-description-primary-imp",
                            },
                            [
                              _vm._v(
                                _vm._s(noteIndex + 1) + ". " + _vm._s(note)
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
    _vm.importResponseData != null
      ? _c("div", [
          _vm.importResponseData.succeeded > 0
            ? _c(
                "div",
                {
                  staticClass: "py-5",
                  staticStyle: {
                    "border-bottom": "1px solid rgb(237, 237, 237)",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "px-8" },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "pr-2",
                          staticStyle: { color: "green" },
                          attrs: { small: "" },
                        },
                        [_vm._v("mdi-checkbox-marked-circle")]
                      ),
                      _vm._v(
                        _vm._s(_vm.importResponseData.succeeded) +
                          " " +
                          _vm._s(_vm.importListName) +
                          " " +
                          _vm._s(
                            _vm.$stringConstants("successfullyImportedText")
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.importResponseData.failed > 0
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "py-5 px-8", staticStyle: { color: "red" } },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          VIcon,
                          {
                            staticClass: "pr-2",
                            staticStyle: { color: "red" },
                            attrs: { small: "" },
                          },
                          [_vm._v("mdi-close-circle")]
                        ),
                        _vm._v(
                          _vm._s(
                            _vm.$stringConstants("errorsImportingStartingText")
                          ) +
                            " " +
                            _vm._s(_vm.importResponseData.failed) +
                            " " +
                            _vm._s(_vm.importListName) +
                            " " +
                            _vm._s(
                              _vm.$stringConstants("errorsImportingEndingText")
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "pl-6 pt-1" }, [
                      _c(
                        "span",
                        {
                          staticClass: "fw-6",
                          staticStyle: {
                            "text-decoration": "underline",
                            cursor: "pointer",
                          },
                          on: { click: _vm.downloadInvalidData },
                        },
                        [_vm._v(_vm._s(_vm.$stringConstants("downloadText")))]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("fixErrorAndUploadAgainText")
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pb-3 px-8", staticStyle: { color: "red" } },
                  [
                    _c(VSimpleTable, {
                      staticStyle: { border: "0.5px solid #eeeeee" },
                      attrs: { "fixed-header": "", height: "300px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      { staticClass: "text-left fn-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$stringConstants("rowNoText")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "th",
                                      { staticClass: "text-left fn-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$stringConstants("errorText")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.getCondensedList(10),
                                    function (invalidCustomer, c) {
                                      return _c("tr", { key: c }, [
                                        _c("td", [_vm._v(_vm._s(c + 2))]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                invalidCustomer.message
                                                  ? invalidCustomer.message
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        97198393
                      ),
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.importInProgress
      ? _c("div", { staticClass: "px-8 py-5" }, [
          _c(
            "div",
            { staticClass: "py-2" },
            [
              _c("SubHeaderPrimary", {
                attrs: { textValue: _vm.inprogressText1 },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pt-1 pb-8" },
            [
              _c("DescriptionPrimary", {
                attrs: { textValue: _vm.inprogressText2 },
              }),
            ],
            1
          ),
          _vm.importInProgress
            ? _c("div", { staticClass: "text-center" }, [_c("ListLoader")], 1)
            : _vm._e(),
        ])
      : _vm._e(),
    !_vm.importInProgress
      ? _c(
          "div",
          {
            staticClass: "pt-2",
            class: [_vm.containerLeftPad, _vm.containerRightPad],
          },
          [
            !_vm.selectedFile
              ? _c("div", [
                  _c(
                    "div",
                    {
                      class: ["dropZone", _vm.dragging ? "dropZone-over" : ""],
                      on: {
                        dragenter: function ($event) {
                          _vm.dragging = true
                        },
                        dragleave: function ($event) {
                          _vm.dragging = false
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dropZone-info",
                          on: { drag: _vm.onChange },
                        },
                        [
                          _c("span", {
                            staticClass: "fa fa-cloud-upload dropZone-title",
                          }),
                          _c("span", { staticClass: "dropZone-title" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("addOrDropFileText"))
                            ),
                          ]),
                        ]
                      ),
                      _c("input", {
                        attrs: { type: "file", accept: ".csv" },
                        on: { change: _vm.onChange },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.fileName
              ? _c(
                  "div",
                  [
                    _c(VTextField, {
                      staticClass: "agd-txtfield",
                      attrs: {
                        height: "42",
                        outlined: "",
                        clearable: "",
                        "prepend-inner-icon": "mdi-file",
                        readonly: "",
                        dense: "",
                      },
                      on: {
                        "click:clear": function ($event) {
                          return _vm.removeFile()
                        },
                      },
                      model: {
                        value: _vm.fileName,
                        callback: function ($$v) {
                          _vm.fileName = $$v
                        },
                        expression: "fileName",
                      },
                    }),
                    _c(
                      VBtn,
                      {
                        staticClass: "f-nn fw-7",
                        attrs: {
                          elevation: "0",
                          disabled: _vm.selectedFile == null,
                          color: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.proceedFileUpload(_vm.importDispatchFn)
                          },
                        },
                      },
                      [_vm._v(" Upload ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }