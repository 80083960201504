export default {
  name: "SkeletonLoader",
  props: {
    'columnWidths': Array,
    'containsAction': Boolean
  },
  data: function data() {
    return {
      columnWidthsArray: [],
      actionColumnWidth: {}
    };
  },
  mounted: function mounted() {
    this.columnWidthsArray = Object.create(this.columnWidths);
    this.reduceWidthsArrayIfListHasActionColumn();
  },
  methods: {
    reduceWidthsArrayIfListHasActionColumn: function reduceWidthsArrayIfListHasActionColumn() {
      if (this.listHasActionColumn()) {
        this.reduceColumnWidthsArrayAndAssignActionColumnWidth();
      }
    },
    listHasActionColumn: function listHasActionColumn() {
      if (this.containsAction) {
        return true;
      }
      return false;
    },
    reduceColumnWidthsArrayAndAssignActionColumnWidth: function reduceColumnWidthsArrayAndAssignActionColumnWidth() {
      this.actionColumnWidth = this.columnWidthsArray.pop();
    }
  }
};