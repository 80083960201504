import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "pt-0 pb-0" },
        [
          _c(
            VCol,
            { staticClass: "px-0", attrs: { cols: "12" } },
            [
              _c(
                VRow,
                { staticClass: "pt-6 pb-0 px-12" },
                [
                  _c(VCol, { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "sb-pymt-cont" }, [
                      _c("div", { staticClass: "sb-cs-plan" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$stringConstants(
                              "subscriptionAvailablePlansTitle"
                            )
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "pt-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("subscriptionDetailsText1") +
                                _vm.$constantValues("defaultCurrencySymbols")
                                  .GBP +
                                _vm.flatPrice +
                                _vm.$stringConstants("subscriptionDetailsText2")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.pricingLoader
                ? _c(
                    VRow,
                    { staticClass: "pt-2 pb-0 px-12" },
                    [
                      _c(
                        VCol,
                        { staticClass: "pt-0", attrs: { cols: "12" } },
                        _vm._l(4, function (planType, p) {
                          return _c("div", { key: p, staticClass: "ib mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "sb-plan-tm-crd" },
                              _vm._l(2, function (planLoader, k) {
                                return _c("div", { key: k }, [
                                  _c("div", { staticClass: "pt-7" }),
                                  _c(
                                    "div",
                                    [
                                      _c(VSkeletonLoader, {
                                        attrs: {
                                          width: "80",
                                          height: "15",
                                          type: "card",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "pt-4" },
                                    [
                                      _c(VSkeletonLoader, {
                                        attrs: {
                                          width: "100",
                                          height: "15",
                                          type: "card",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              0
                            ),
                          ])
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _c(
                    VRow,
                    { staticClass: "pt-2 pb-0 px-12" },
                    [
                      _c(
                        VCol,
                        { staticClass: "pt-0", attrs: { cols: "12" } },
                        _vm._l(
                          _vm.subscriptionPlanTypes,
                          function (planType, p) {
                            return _c(
                              "div",
                              { key: p, staticClass: "ib mt-4" },
                              [
                                _c("SubscriptionPlanCard", {
                                  attrs: {
                                    teamTypeText: planType.title,
                                    membersText: _vm.getMembersCount(
                                      planType.maxUnits
                                    ),
                                    perMemberCostText: planType.pricePerUnit,
                                    perMemberCostSubtitleText: "/member/month",
                                    teamTypeColor: _vm.getPlanTypeColor(
                                      parseInt(p)
                                    ),
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _c(
                        VCol,
                        { staticClass: "pt-2", attrs: { cols: "12" } },
                        [
                          _c("Button", {
                            attrs: {
                              variant: "list",
                              color: "primary",
                              text: "",
                              textValue: "Know more about plans",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.routeToPricing($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }