import $ from 'jquery';
import TimeSheetListView from './timesheets-list.vue';
import TimeSheetAddListView from './timesheets-add.vue';
import TimeSheetEditListView from './timesheets-edit.vue';
import { closeOverlay } from '@/utils/helpers';
export default {
  components: {
    TimeSheetListView: TimeSheetListView,
    TimeSheetAddListView: TimeSheetAddListView,
    TimeSheetEditListView: TimeSheetEditListView
  },
  mounted: function mounted() {
    this.$store.state.dashboardTitle = this.$stringConstants('timesheetsTitle');
    this.$store.dispatch('getHoursList');
    this.$store.dispatch('getMinutesList');
    this.$store.dispatch('getTimesheetsStatistics');
    $('body').on('click', '#overlay', function () {
      closeOverlay("addTimeSheetFormOverlay");
      closeOverlay("editTimeSheetFormOverlay");
    });
  }
};