export var state = {
  adminOverviewRecentStatistics: {
    users: 0,
    businesses: 0,
    paidSubscriptions: 0,
    expiredSubscriptions: 0,
    businessesOnTrial: 0,
    subscriptionsCollection: 0
  },
  adminOverviewRecentStatisticsStartDate: null,
  adminOverviewRecentStatisticsEndDate: null,
  adminOverviewOverallStatistics: {
    totalUsers: 0,
    totalActiveUsers: 0,
    totalBusinesses: 0,
    totalActiveBusinesses: 0,
    averageTeamMembersPerBusiness: 0,
    averageSubscriptionInvoiceAmountPerBusiness: 0,
    businessesDistributionByInvoicingServices: [],
    businessesDistributionByCountry: []
  },
  adminOverviewCurrentStatisticsDateFilterApplied: false,
  adminRecentOverviewLoader: false,
  adminOverallOverviewLoader: false
};
export var getters = {
  adminOverviewRecentStatistics: function adminOverviewRecentStatistics(state) {
    return state.adminOverviewRecentStatistics;
  },
  adminOverviewRecentStatisticsStartDate: function adminOverviewRecentStatisticsStartDate(state) {
    return state.adminOverviewRecentStatisticsStartDate;
  },
  adminOverviewRecentStatisticsEndDate: function adminOverviewRecentStatisticsEndDate(state) {
    return state.adminOverviewRecentStatisticsEndDate;
  },
  adminOverviewOverallStatistics: function adminOverviewOverallStatistics(state) {
    return state.adminOverviewOverallStatistics;
  },
  adminOverviewCurrentStatisticsDateFilterApplied: function adminOverviewCurrentStatisticsDateFilterApplied(state) {
    return state.adminOverviewCurrentStatisticsDateFilterApplied;
  },
  adminRecentOverviewLoader: function adminRecentOverviewLoader(state) {
    return state.adminRecentOverviewLoader;
  },
  adminOverallOverviewLoader: function adminOverallOverviewLoader(state) {
    return state.adminOverallOverviewLoader;
  }
};
export var mutations = {
  setRecentStatistics: function setRecentStatistics(state, data) {
    state.adminOverviewRecentStatistics = data;
  },
  setOverallStatistics: function setOverallStatistics(state, data) {
    state.adminOverviewOverallStatistics = data;
  }
};