import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { HeaderPrimary } from "@/components/shared/headers/index";
import { formFieldPb, containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { Coordinate } from '@/data/models/Coordinate';
import { TeamJobAddress } from '@/data/models/TeamJobAddress';
import { closeOverlay, notify, listContainsItem } from '@/utils/helpers';
import { TeamJobAddressSelectionType } from '@/enum/jobAddressSelectionType';
import TeamJobsAddressesFlyoutAddressToggle from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-address-toggle.vue';
import TeamJobsAddressesFlyoutFarmAddresses from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-farm-address.vue';
import TeamJobsAddressesFlyoutDropPin from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-drop-pin.vue';
import TeamJobsAddressesFlyoutCustomAddress from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-custom-address.vue';
import { isValidLatitude, isValidLongitude } from '@/utils/helpers/gmaps-helpers';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import ConstantValues from '@/plugins/constantValues';
export default {
  name: "TeamJobsAddressesFlyoutForm",
  props: ['addresses'],
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      TeamJobAddressSelectionType: TeamJobAddressSelectionType
    };
  },
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    TeamJobsAddressesFlyoutAddressToggle: TeamJobsAddressesFlyoutAddressToggle,
    TeamJobsAddressesFlyoutFarmAddresses: TeamJobsAddressesFlyoutFarmAddresses,
    TeamJobsAddressesFlyoutDropPin: TeamJobsAddressesFlyoutDropPin,
    TeamJobsAddressesFlyoutCustomAddress: TeamJobsAddressesFlyoutCustomAddress,
    HeaderPrimary: HeaderPrimary,
    Button: Button
  },
  methods: {
    negativeKeydown: function negativeKeydown(e) {
      if (!/[^-+]/.test(e.key)) {
        e.preventDefault();
      }
    },
    closeAddAddressesForm: function closeAddAddressesForm() {
      closeOverlay("jobAddressesFormOverlay");
    },
    clearAddAddressFormData: function clearAddAddressFormData() {
      this.teamJobFarmAddressesSelected = [];
      this.teamJobAddressInstructions = null;
    },
    pushAddress: function pushAddress(address) {
      var teamJobAddress = new TeamJobAddress().getTeamJobAddress(address, this.teamJobAddressInstructions);
      this.teamJobAddresses.push(teamJobAddress);
      this.clearAndCloseForm();
    },
    clearAndCloseForm: function clearAndCloseForm() {
      this.clearAddAddressFormData();
      this.closeAddAddressesForm();
    },
    addFarmAddressToAddresses: function addFarmAddressToAddresses() {
      var _this = this;
      var addressesSelected = Array.isArray(this.teamJobFarmAddressesSelected) ? this.teamJobFarmAddressesSelected : [this.teamJobFarmAddressesSelected];
      if (addressesSelected && addressesSelected.length) {
        addressesSelected.forEach(function (address) {
          if (_this.teamJobAddresses && _this.teamJobAddresses.length) {
            var jobAddressExists = listContainsItem(_this.teamJobAddresses, [ConstantValues.defaultObjectIdentifier], address.id);
            if (!jobAddressExists) {
              _this.pushAddress(address);
            } else {
              notify(_this.$stringConstants('teamJobLocationAlreadyAdded'), 'fail');
              _this.clearAddAddressFormData();
            }
          } else {
            _this.pushAddress(address);
          }
        });
      }
    },
    addTeamJobCustomAddress: function addTeamJobCustomAddress() {
      if (this.teamJobCustomAddress.addressLine1) {
        this.pushAddress(this.teamJobCustomAddress);
      } else {
        notify(this.$stringConstants('teamJobLocationInvalid'), 'fail');
      }
    },
    addDropPinAddressToAddresses: function addDropPinAddressToAddresses() {
      if (isValidLatitude(this.teamJobAddressesDropPinLatitude) && isValidLongitude(this.teamJobAddressesDropPinLongitude)) {
        var teamJobAddress = {
          id: null,
          addressLine1: null,
          addressLine2: null,
          city: null,
          state: null,
          country: null,
          description: this.teamJobAddressInstructions,
          postalCode: null,
          coordinate: new Coordinate().getCoordinate({
            latitude: this.teamJobAddressesDropPinLatitude,
            longitude: this.teamJobAddressesDropPinLongitude
          })
        };
        this.pushAddress(teamJobAddress);
      } else {
        notify(this.$stringConstants('teamJobLocationInvalidCoordinates'), 'fail');
      }
    },
    addAddressToJobLocations: function addAddressToJobLocations() {
      switch (this.teamJobAddressSelectionType) {
        case TeamJobAddressSelectionType.FarmAddress:
          this.addFarmAddressToAddresses();
          this.enableTeamJobDiscard();
          break;
        case TeamJobAddressSelectionType.DropPin:
          this.addDropPinAddressToAddresses();
          this.enableTeamJobDiscard();
          break;
        case TeamJobAddressSelectionType.CustomAddress:
          this.addTeamJobCustomAddress();
          this.enableTeamJobDiscard();
          break;
        default:
          break;
      }
    }
  }
};