import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseFlyout",
    _vm._g(
      _vm._b(
        {
          ref: "flyoutRef",
          attrs: {
            width: "500",
            flyoutClass: "d-flex flex-column justify-content-start",
            value: _vm.value,
          },
          scopedSlots: _vm._u([
            {
              key: "flyout-header",
              fn: function () {
                return [
                  _c("HeaderPrimary", {
                    attrs: {
                      textValue: _vm.isExistingJob
                        ? _vm.$stringConstants("editJob")
                        : _vm.$stringConstants("jobsAddTitle"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "flyout-bottom",
              fn: function () {
                return [
                  _c(VDivider, { attrs: { light: "" } }),
                  _c(
                    "div",
                    { staticClass: "w-100 px-8 py-3" },
                    [
                      _c("Button", {
                        attrs: {
                          variant: "primary",
                          color: "primary",
                          rightIcon: "mdi-arrow-right",
                          textValue: _vm.isExistingJob
                            ? _vm.$stringConstants("updateBtnText")
                            : _vm.$stringConstants("addBtnText"),
                          disabled:
                            !_vm.hasRequiredFields || _vm.isSubmitInProgress,
                          loading: _vm.isSubmitInProgress,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSubmit($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "BaseFlyout",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          staticClass:
            "px-8 py-7 flex-grow-1 d-flex flex-column container-scroll",
          attrs: { "no-gutters": "" },
        },
        [
          _c("span", { staticClass: "fn-lg font-weight-bold mb-7" }, [
            _vm._v(
              _vm._s(_vm.$stringConstants("teamJobGeneralInformationTitle"))
            ),
          ]),
          _c("TeamJobsOwners"),
          _c("TeamJobsOperation", { attrs: { minimal: "" } }),
          _c("TeamJobsScheduledDate", { staticClass: "mb-7" }),
          _vm.teamJobViewPreference.operators.isVisible
            ? _c(
                "div",
                [
                  _c("span", { staticClass: "fn-lg font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("operators"))),
                  ]),
                  _c("TeamJobsOperators", {
                    staticClass: "mt-n4",
                    attrs: { minimal: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }