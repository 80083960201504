import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import onboardComputedMixin from "@/components/onboard/_onboard-computed";
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { checkFieldsValidity, validateRegexPhoneNumber } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
export default {
  mixins: [onboardComputedMixin],
  components: {
    Button: Button
  },
  data: function data() {
    return {
      tempRatePercentage: null,
      tempRateName: null,
      searchTaxType: null
    };
  },
  mounted: function mounted() {
    var _a, _b;
    if (this.userProfile.businessProfile.address.country) {
      var country = this.userProfile.businessProfile.address.country;
      this.getCountryTaxesName(country);
      this.getCountryTaxRates(country);
    }
    if (((_b = (_a = this.userProfile.businessProfile) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.invoiceTermsInDays) == 0) {
      this.userProfile.businessProfile.config.invoiceTermsInDays = ConstantValues.defaultInvoicingTermsInDays;
    }
  },
  computed: {
    isValidTaxRate: function isValidTaxRate() {
      if (checkFieldsValidity([this.tempRatePercentage, this.tempRateName])) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    appendTaxRate: function appendTaxRate() {
      var taxRate = {
        rateName: this.tempRateName,
        rateInPercentage: this.tempRatePercentage
      };
      this.userProfile.businessProfile.config.salesTaxRates.push(taxRate);
      this.resetTempTaxRate();
    },
    resetTempTaxRate: function resetTempTaxRate() {
      this.tempRateName = null;
      this.tempRatePercentage = null;
    },
    removeSelectedTaxRate: function removeSelectedTaxRate(idx) {
      if (idx > -1) {
        this.userProfile.businessProfile.config.salesTaxRates.splice(idx, 1);
      }
    },
    getCountryTaxesName: function getCountryTaxesName(countryName) {
      var _this = this;
      this.$store.dispatch('getCountryTaxesDefaultName', countryName).then(function (res) {
        if (res) {
          _this.userProfile.businessProfile.config.salesTaxName = res;
        }
      });
    },
    getCountryTaxRates: function getCountryTaxRates(countryName) {
      var _this2 = this;
      this.$store.dispatch('getCountryTaxRatesList', countryName).then(function (res) {
        if (res) {
          _this2.userProfile.businessProfile.config.salesTaxRates = res;
        }
      });
    },
    checkTermsNumber: function checkTermsNumber(event) {
      validateRegexPhoneNumber(event);
    }
  }
};