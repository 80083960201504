import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginRegistrationWrapper", [
    _c("div", { staticClass: "centered" }, [
      _c("form", { staticClass: "login-box" }, [
        _c("div", { staticClass: "logo-wrapper" }, [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("../../assets/ag-drive-logo.svg") },
          }),
        ]),
        _c(
          "div",
          [
            _c("div", { staticClass: "title pb-9" }, [
              _c("span", [_vm._v(_vm._s(_vm.$stringConstants("logInText")))]),
            ]),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(_vm._s(_vm.$stringConstants("emailText")) + " *"),
            ]),
            _c(VTextField, {
              attrs: {
                outlined: "",
                "error-messages": _vm.userNameErrors,
                autocomplete: "Email",
                placeholder: _vm.$stringConstants("emailPlaceholder"),
                required: "",
                dense: "",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.userName.$touch()
                },
                blur: function ($event) {
                  return _vm.$v.userName.$touch()
                },
              },
              model: {
                value: _vm.userName,
                callback: function ($$v) {
                  _vm.userName = $$v
                },
                expression: "userName",
              },
            }),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(_vm._s(_vm.$stringConstants("passWordText")) + " *"),
            ]),
            _vm.regularSubmit
              ? _c(VTextField, {
                  attrs: {
                    outlined: "",
                    "error-messages": _vm.passWordErrors,
                    autocomplete: "Password",
                    placeholder: _vm.$stringConstants(
                      "enterPasswordPlaceholder"
                    ),
                    "append-icon": _vm.value ? "mdi-eye-off" : "mdi-eye",
                    type: _vm.value ? "password" : "text",
                    required: "",
                    dense: "",
                  },
                  on: {
                    "click:append": function () {
                      return (_vm.value = !_vm.value)
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submit($event)
                    },
                    input: function ($event) {
                      return _vm.$v.passWord.$touch()
                    },
                    blur: function ($event) {
                      return _vm.$v.passWord.$touch()
                    },
                  },
                  model: {
                    value: _vm.passWord,
                    callback: function ($$v) {
                      _vm.passWord = $$v
                    },
                    expression: "passWord",
                  },
                })
              : _vm._e(),
            _vm.verificationSubmit
              ? _c(VTextField, {
                  staticClass: "pT-12",
                  attrs: {
                    outlined: "",
                    "error-messages": _vm.passWordErrors,
                    label: "Password",
                    "append-icon": _vm.value ? "mdi-eye-off" : "mdi-eye",
                    type: _vm.value ? "password" : "text",
                    required: "",
                    dense: "",
                  },
                  on: {
                    "click:append": function () {
                      return (_vm.value = !_vm.value)
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submitVerification($event)
                    },
                    input: function ($event) {
                      return _vm.$v.passWord.$touch()
                    },
                    blur: function ($event) {
                      return _vm.$v.passWord.$touch()
                    },
                  },
                  model: {
                    value: _vm.passWord,
                    callback: function ($$v) {
                      _vm.passWord = $$v
                    },
                    expression: "passWord",
                  },
                })
              : _vm._e(),
            _c(
              "router-link",
              {
                staticClass: "no-decoration",
                attrs: { to: _vm.routeForgetPassword },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "fn-sm primary--text font-weight-medium mb-10",
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$stringConstants("forgotPassword")) + " "
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              [
                !_vm.verificationSubmit
                  ? _c("Button", {
                      attrs: {
                        variant: "primary",
                        color: "primary",
                        loading: _vm.loginDataLoading,
                        disabled: _vm.loginDataLoading,
                        textValue: "Log in",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.submit($event)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.verificationSubmit
                  ? _c(
                      VBtn,
                      {
                        staticClass: "mr-4 btn-login lg-btn sb-btn",
                        attrs: {
                          block: "",
                          tile: "",
                          disabled: _vm.loginLoader,
                          height: "52",
                          color: "primary",
                          elevation: "0",
                        },
                        on: { click: _vm.submitVerification },
                      },
                      [_vm._v(" login ")]
                    )
                  : _vm._e(),
                _c(
                  VBtn,
                  {
                    class: [{ disabled: _vm.loginDataLoading, "mt-2": true }],
                    style: {
                      backgroundColor: "#FFF !important",
                      color: "#000",
                      border: "1px solid #1d34ad !important",
                      textTransform: "none",
                    },
                    attrs: {
                      variant: "primary",
                      color: "primary",
                      block: "",
                      disabled: _vm.loginDataLoading,
                      height: "44",
                      elevation: "0",
                      rightIcon: "mdi-arrow-right",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.signUpXero($event)
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("userLoginWithXeroText")
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pt-5 text-center" },
              [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("noAccountText")) + " "
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "no-decoration ml-1",
                    attrs: { to: _vm.routeSignUp },
                  },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v("Create"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }