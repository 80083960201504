import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.loaderRows, function (loader, index) {
      return _c("div", { key: index }, [
        _c("div", { staticClass: "my-10 px-7" }, [
          _c(
            "div",
            { staticClass: "ib", staticStyle: { width: "30%" } },
            [
              _c(VSkeletonLoader, {
                attrs: { width: "100%", height: "20", type: "card" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "ib", staticStyle: { width: "60%" } }),
          _c(
            "div",
            { staticClass: "ib", staticStyle: { width: "10%" } },
            [
              _c(VSkeletonLoader, {
                attrs: { width: "100%", height: "20", type: "card" },
              }),
            ],
            1
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }