import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "pt-10", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants(
                    "secondaryContactInfoHeading1"
                  ),
                  textValue2: _vm.$stringConstants(
                    "customerSecondaryContactSubTitle"
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "7" } },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0 pr-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$stringConstants("name")) + " "
                        ),
                      ]),
                      _c(VTextField, {
                        class: _vm.formFieldPb,
                        attrs: {
                          placeholder: _vm.$stringConstants("namePlaceholder"),
                          outlined: "",
                          dense: "",
                        },
                        on: {
                          keydown: function ($event) {
                            return _vm.checkKeyDownAlpha($event)
                          },
                        },
                        model: {
                          value: _vm.contactProfile.secondaryFirstName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactProfile,
                              "secondaryFirstName",
                              $$v
                            )
                          },
                          expression: "contactProfile.secondaryFirstName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("phoneNumber")) +
                            " "
                        ),
                      ]),
                      _c(VTextField, {
                        class: _vm.formFieldPb,
                        attrs: {
                          placeholder: _vm.$stringConstants(
                            "phoneNumberPlaceholder"
                          ),
                          type: "number",
                          "error-messages": _vm.generatePhoneValidationErrors(),
                          outlined: "",
                          dense: "",
                        },
                        on: {
                          keydown: function ($event) {
                            return _vm.checkPhoneNumber($event)
                          },
                        },
                        model: {
                          value: _vm.contactProfile.secondaryContactNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactProfile,
                              "secondaryContactNumber",
                              $$v
                            )
                          },
                          expression: "contactProfile.secondaryContactNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }