import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VBtn,
    {
      staticClass: "ib text-center btn-gray f-nn list-load-more-btn-wd",
      attrs: { outlined: "" },
    },
    [_vm._v(" " + _vm._s(_vm.$stringConstants("listLoadMoreText")) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }