var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "loadMoreDropdownObserver", staticClass: "text-center" },
    [
      _vm.moduleState && !_vm.moduleState.error && _vm.hasMoreData
        ? _c(
            "div",
            { staticClass: "my-2" },
            [
              _vm.showMiniLoader
                ? _c("EllipsisMiniLoader", { staticClass: "mb-1" })
                : !_vm.showMiniLoader
                ? _c(
                    "div",
                    {
                      staticClass: "text-center cursor-pointer load-more-text",
                      on: { click: _vm.handleClick },
                    },
                    [_vm._v(" Load more ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm.moduleState && _vm.moduleState.error
        ? _c("div", { staticClass: "py-2 red--text" }, [
            _vm._v(
              " " +
                _vm._s(_vm.moduleState.errorMessage || "Something went wrong") +
                " "
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }