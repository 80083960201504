import accountComputedMixin from "@/components/account/account-computed";
import AccountProfileForm from "./account-profile.vue";
import AccountBusinessForm from "./account-business.vue";
import FarmsAddressForm from "./account-farms-address.vue";
import { UserRoleType } from "@/enum/userRoleType";
import { AccountTabType } from "@/enum/accountTabType";
import requestsCancellationMixin from '@/mixins/requests-cancellation';
export default {
  mixins: [accountComputedMixin, requestsCancellationMixin],
  data: function data() {
    return {
      UserRoleType: UserRoleType
    };
  },
  components: {
    AccountProfileForm: AccountProfileForm,
    AccountBusinessForm: AccountBusinessForm,
    FarmsAddressForm: FarmsAddressForm
  },
  mounted: function mounted() {
    this.$store.state.dashboardTitle = this.$stringConstants('accountTitle');
    this.$store.dispatch("getGlobalCountriesList");
  },
  methods: {
    handleTabChangeBusiness: function handleTabChangeBusiness(accountTab) {
      if (this.accountTab == AccountTabType.Business) {
        this.$store.dispatch("getGoogleMapToShowDefaultMarker");
      }
    }
  }
};