import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    _vm._g(
      _vm._b(
        {
          attrs: {
            persistent: _vm.loading,
            value: _vm.value,
            "max-width": "460",
          },
        },
        "v-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        VCard,
        { staticClass: "dlt-diag px-3 pt-2 pb-3 d-flex flex-column" },
        [
          _c(VCardTitle, { staticClass: "headline" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$stringConstants("teamJobCancelPromtTitle")) +
                " "
            ),
            _vm.reasonRequired
              ? _c(
                  "span",
                  {
                    staticClass: "pl-1",
                    staticStyle: { "font-size": "12px", opacity: "0.5" },
                  },
                  [
                    _vm._v(
                      " (" +
                        _vm._s(
                          _vm.$stringConstants("teamJobCancelReasonText")
                        ) +
                        ") "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c(
            VCardText,
            [
              _vm.reasonRequired
                ? _c(VTextarea, {
                    attrs: {
                      rows: "4",
                      "row-height": "30",
                      outlined: "",
                      dense: "",
                      required: "",
                    },
                    model: {
                      value: _vm.cancelTeamJobReason,
                      callback: function ($$v) {
                        _vm.cancelTeamJobReason = $$v
                      },
                      expression: "cancelTeamJobReason",
                    },
                  })
                : _vm._t("body", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$stringConstants("teamJobCancelPromtSubtitle")
                        ) +
                        " "
                    ),
                  ]),
            ],
            2
          ),
          _c(
            VCardActions,
            { staticClass: "px-6 mt-auto" },
            [
              _c(VSpacer),
              _vm.reasonRequired
                ? _c(
                    "span",
                    [
                      _c("SecondaryButton", {
                        attrs: {
                          variant: "secondary",
                          text: "",
                          color: "primary",
                          outlined: "",
                          outlineColor: "primary",
                          loading: _vm.loading,
                          disabled: _vm.loading,
                          textValue: _vm.$stringConstants(
                            "deletePromtDisagree"
                          ),
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$emit("cancel")
                          },
                        },
                      }),
                      _c("SecondaryButton", {
                        staticClass: "ml-3",
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          textValue: _vm.$stringConstants("deletePromtAgree"),
                          disabled:
                            _vm.cancelTeamJobReason.trim().length === 0 ||
                            _vm.loading,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$emit("confirm", _vm.cancelTeamJobReason)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "span",
                    [
                      _c("SecondaryButton", {
                        attrs: {
                          variant: "secondary",
                          text: "",
                          color: "primary",
                          outlined: "",
                          outlineColor: "primary",
                          textValue: "Cancel",
                          loading: _vm.loading,
                          disabled: _vm.loading,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$emit("cancel")
                          },
                        },
                      }),
                      _c("SecondaryButton", {
                        staticClass: "ml-3",
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          textValue: "Confirm",
                          disabled: _vm.loading,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$emit("confirm")
                          },
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }