import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "list-container",
      attrs: { id: "invoice-ext-list-container" },
    },
    [
      _c(
        VRow,
        {
          staticClass: "flex-grow-0 px-9",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "7" } },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "pt-5 pb-0 px-0", attrs: { cols: "12" } },
                    [
                      _vm.quickBookAccess[_vm.quickbookAccessState] ==
                        _vm.$constantValues("quickbookAccessStruct")[
                          "quickbookAccess"
                        ] && !_vm.loadingIntegration
                        ? _c(
                            VBtn,
                            {
                              staticClass:
                                "ib float-left btn-gr f-nn ext-btns mr-3",
                              attrs: { elevation: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToQuickbooks()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: require("../../../assets/qb-icon.png"),
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants(
                                      "invoiceExternalQuickbooksTitle"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.xeroAccess[_vm.xeroAccessState] ==
                        _vm.$constantValues("xeroAccessStruct")["xeroAccess"] &&
                      !_vm.loadingIntegration
                        ? _c(
                            VBtn,
                            {
                              staticClass:
                                "ib float-left btn-gr f-nn ext-btns mr-3",
                              attrs: { elevation: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToXero()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: require("../../../assets/xero-icon.png"),
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants(
                                      "invoiceExternalXeroTitle"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.sageAccess[_vm.sageAccessState] ==
                        _vm.$constantValues("sageAccessStruct")["sageAccess"] &&
                      !_vm.loadingIntegration
                        ? _c(
                            VBtn,
                            {
                              staticClass:
                                "ib float-left btn-gr f-nn ext-btns mr-3",
                              attrs: { elevation: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToSage()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: require("../../../assets/sage-icon.png"),
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants(
                                      "invoiceExternalSageTitle"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.loadingIntegration
                        ? _c(VBtn, {
                            staticClass: "ib float-left btn-gr f-nn ext-btns",
                            attrs: {
                              width: "160",
                              loading: _vm.loadingIntegration,
                              disabled: _vm.loadingIntegration,
                            },
                          })
                        : _vm._e(),
                      _vm.loadingIntegration
                        ? _c(VBtn, {
                            staticClass: "ib float-left btn-gr f-nn",
                            attrs: {
                              width: "155",
                              loading: _vm.loadingIntegration,
                              disabled: _vm.loadingIntegration,
                              outlined: "",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showExternalInvoicingFilters,
                              expression: "showExternalInvoicingFilters",
                            },
                          ],
                          staticClass: "ib jb-fltr-1 vertical-align-top",
                        },
                        [
                          _c(VTextField, {
                            staticClass: "agd-txtfield",
                            attrs: {
                              label: "Search invoices",
                              outlined: "",
                              clearable: "",
                              "prepend-inner-icon": "mdi-magnify",
                              dense: "",
                            },
                            on: {
                              "click:clear": _vm.onInvoicesSearchClearClicked,
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.applySearchExternalInvoicesFilter(
                                  $event
                                )
                              },
                            },
                            model: {
                              value: _vm.invoiceSearchTextExternal,
                              callback: function ($$v) {
                                _vm.invoiceSearchTextExternal = $$v
                              },
                              expression: "invoiceSearchTextExternal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showExternalInvoicingFilters,
                              expression: "showExternalInvoicingFilters",
                            },
                          ],
                          staticClass: "ib jb-fltr-1 vertical-align-top pl-2",
                        },
                        [
                          _c(VAutocomplete, {
                            staticClass: "rd-lf-no agd-dropdown",
                            attrs: {
                              items: _vm.customersFilterListForInvoicesList,
                              "search-input": _vm.customersFilterSearchText,
                              "item-text": "businessName",
                              "item-value": "id",
                              label: "Select customer",
                              "no-data-text": _vm.searchNotify,
                              outlined: "",
                              dense: "",
                              "append-icon": "mdi-chevron-down",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.customersFilterSearchText = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.customersFilterSearchText = $event
                              },
                              keyup: [
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchCustomersListFilter($event)
                                },
                                function ($event) {
                                  _vm.searchNotify =
                                    _vm.$stringConstants("enterToSearch")
                                },
                              ],
                              change: _vm.filterInvoicesListByCustomer,
                            },
                            model: {
                              value: _vm.customerForInvoicesList,
                              callback: function ($$v) {
                                _vm.customerForInvoicesList = $$v
                              },
                              expression: "customerForInvoicesList",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showExternalInvoicingFilters,
                              expression: "showExternalInvoicingFilters",
                            },
                          ],
                          staticClass: "ib vertical-align-top pl-2",
                        },
                        [
                          _c("datePicker", {
                            ref: "datePicker",
                            class: _vm.invoiceDateFilterApplied ? "active" : "",
                            attrs: {
                              dateInput: _vm.dateInput,
                              format: _vm.format,
                              sameDateFormat: _vm.sameDateFormat,
                              "switch-button-initial": true,
                              "show-helper-buttons": false,
                              language: "en",
                            },
                            on: {
                              "date-applied": _vm.filterInvoicesByDateRange,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.invoiceDateFilterApplied
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showExternalInvoicingFilters,
                                  expression: "showExternalInvoicingFilters",
                                },
                              ],
                              staticClass: "ib",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "jb-clr",
                                  staticStyle: {
                                    transform: "translate(10px, 8px)",
                                  },
                                  on: { click: _vm.clearInvoicesDateFilter },
                                },
                                [
                                  _c(VIcon, { attrs: { small: "" } }, [
                                    _vm._v(" mdi-close-thick "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "5" } },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "pt-5 px-0", attrs: { cols: "12" } },
                    [
                      _vm.xeroAccess[_vm.xeroAccessState] ==
                        _vm.$constantValues("xeroAccessStruct")["xeroAccess"] &&
                      !_vm.loadingIntegration
                        ? _c("Button", {
                            staticClass: "float-right",
                            attrs: {
                              variant: "secondary",
                              color: "primary",
                              outlined: "",
                              outlineColor: "primary",
                              disabled: _vm.loadingIntegration,
                              textValue: "Create",
                              leftIcon: "mdi-plus",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openAddInvoice("xero")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.quickBookAccess[_vm.quickbookAccessState] ==
                        _vm.$constantValues("quickbookAccessStruct")[
                          "quickbookAccess"
                        ] && !_vm.loadingIntegration
                        ? _c("Button", {
                            staticClass: "float-right",
                            attrs: {
                              variant: "secondary",
                              color: "primary",
                              outlined: "",
                              outlineColor: "primary",
                              disabled: _vm.loadingIntegration,
                              textValue: "Create",
                              leftIcon: "mdi-plus",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openAddInvoice("quickbooks")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.sageAccess[_vm.sageAccessState] ==
                        _vm.$constantValues("sageAccessStruct")["sageAccess"] &&
                      !_vm.loadingIntegration
                        ? _c("Button", {
                            staticClass: "float-right",
                            attrs: {
                              variant: "secondary",
                              color: "primary",
                              outlined: "",
                              outlineColor: "primary",
                              disabled: _vm.loadingIntegration,
                              textValue: "Create",
                              leftIcon: "mdi-plus",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openAddInvoice("sage")
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("List", {
        attrs: {
          listItems: _vm.invoicesListExternal,
          containsAction: "",
          externalList: "",
          moduleNamespace: "invoicesExternalModule",
        },
        on: {
          setFilterOptionsAndGetListData: _vm.setFilterOptionsAndGetInvoices,
          listItemClick: _vm.openInvoiceDetails,
        },
        scopedSlots: _vm._u([
          {
            key: "list-heading",
            fn: function () {
              return [
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("numberText"))),
                ]),
                _c("div", { staticClass: "ib width-perc-20 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("customer"))),
                ]),
                _c("div", { staticClass: "ib width-perc-25 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("itemsText"))),
                ]),
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("jobsText"))),
                ]),
                _c("div", { staticClass: "ib width-perc-15 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("dueDateText"))),
                ]),
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("status"))),
                ]),
                _c("div", { staticClass: "ib width-perc-10 text-right" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("action"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "list-item",
            fn: function (ref) {
              var listItem = ref.listItem
              return [
                _c(
                  "div",
                  {
                    staticClass: "ib fw-5 cursor-pointer width-perc-10 pr-4",
                    on: {
                      click: function ($event) {
                        return _vm.openInvoiceDetails(listItem)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(listItem.number) + " ")]
                ),
                _c("div", { staticClass: "ib width-perc-20 pr-4" }, [
                  _vm._v(" " + _vm._s(listItem.contactName) + " "),
                ]),
                _c("div", { staticClass: "ib width-perc-25" }, [
                  listItem.lineItems.length == 1
                    ? _c("div", { staticClass: "pr-6" }, [
                        _vm._v(
                          " " + _vm._s(listItem.lineItems[0].description) + " "
                        ),
                      ])
                    : listItem.lineItems.length > 1
                    ? _c("div", { staticClass: "pr-8" }, [
                        _vm._v(
                          " " +
                            _vm._s(listItem.lineItems[0].description) +
                            " + " +
                            _vm._s(listItem.lineItems.length - 1) +
                            " others "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "ib width-perc-10" }, [
                  _c("div", { staticClass: "pr-6" }, [
                    listItem.jobIds.length >= 1
                      ? _c(
                          "div",
                          {
                            staticClass: "cursor-pointer inv-jb-count-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openJobsPreview(listItem.jobIds)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(listItem.jobIds.length) +
                                " job" +
                                _vm._s(listItem.jobIds.length > 1 ? "s" : "") +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "ib width-perc-15 pr-4" }, [
                  _vm._v(
                    " " + _vm._s(_vm.getLocalFormat(listItem.dueDate)) + " "
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _vm._v(" " + _vm._s(listItem.status) + " "),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "ib position-relative text-right width-perc-10",
                  },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "f-nn",
                        attrs: { color: "primary", elevation: "0" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openInvoiceDetails(listItem)
                          },
                        },
                      },
                      [_vm._v(" View ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "zero-state",
            fn: function () {
              return [
                _c("p", { staticClass: "mb-0 font-weight-bold pb-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants("invoiceZeroStateTitleText")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$stringConstants("invoiceZeroStateSubtitleText"))
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }