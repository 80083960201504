import "core-js/modules/es.array.push.js";
import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { closeOverlay } from '@/utils/helpers';
import teamMembersComputedMixin from '@/components/team-members/team-members-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import ConstantValues from '@/plugins/constantValues';
import { FeatureTypeLabels } from '@/enum/featureType';
import { ManagerFeatureLabels, TeamLeadFeatureLabels, OperatorFeatureLabels, UserRoleType } from '@/enum/userRoleType';
export default {
  mixins: [validationMixin, teamMembersComputedMixin],
  validations: {
    userInviteeEmail: {
      required: required,
      email: email
    }
  },
  computed: {
    Features: function Features() {
      return FeatureTypeLabels;
    },
    ManagerFeatures: function ManagerFeatures() {
      return ManagerFeatureLabels;
    },
    TeamLeadFeatures: function TeamLeadFeatures() {
      return TeamLeadFeatureLabels;
    },
    OperatorFeatures: function OperatorFeatures() {
      return OperatorFeatureLabels;
    },
    UserRole: function UserRole() {
      return UserRoleType;
    }
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      fieldsInvalid: true
    };
  },
  components: {
    HeaderPrimary: HeaderPrimary,
    Button: Button
  },
  methods: {
    proceedTeamMemberInvitation: function proceedTeamMemberInvitation() {
      var _this = this;
      var newTeamMemberInviteeObj = {
        inviteeEmail: this.userInviteeEmail,
        inviteeRole: this.userInviteeRole
      };
      this.$store.dispatch('sendUserInvite', newTeamMemberInviteeObj).then(function (res) {
        if (res) {
          if (_this.memberFilterType == 2) {
            _this.pendingTeamMemberList = [];
            _this.$store.dispatch('fnSetTeamMembersFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function (res) {
              _this.$store.dispatch('getPendingTeamMembers');
            });
          }
          closeOverlay('addTeamMemberFormOverlay');
        }
      });
    },
    generateValidationErrors: function generateValidationErrors() {
      var errors = [];
      if (!this.$v.userInviteeEmail.$dirty) return errors;
      if (!this.$v.userInviteeEmail.required) {
        errors.push(this.$stringConstants('userRequiredEmailAddress'));
        this.fieldsInvalid = true;
      } else if (!this.$v.userInviteeEmail.email) {
        errors.push(this.$stringConstants('userFormatEmailAddress'));
        this.fieldsInvalid = true;
      } else {
        this.fieldsInvalid = false;
      }
      return errors;
    },
    closeMemberAddForm: function closeMemberAddForm() {
      closeOverlay("addTeamMemberFormOverlay");
    }
  }
};