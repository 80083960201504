import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ob-pad" }, [
    _c("div", [
      _c("div", { staticClass: "ob-maintitle" }, [
        _vm._v(_vm._s(_vm.$stringConstants("onboardPreferenceTitle"))),
      ]),
      _c("div", { staticClass: "ob-description pt-2 pb-6" }, [
        _vm._v(_vm._s(_vm.$stringConstants("onboardPreferenceSubtitle"))),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "ib width-perc-50",
        staticStyle: { "vertical-align": "top" },
      },
      [
        _c(
          "div",
          [
            _c("div", { staticClass: "ob-subtitle" }, [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("defaultSettingsTitle")) + " "
              ),
            ]),
            _c(
              VRow,
              { staticStyle: { "max-width": "460px" } },
              [
                _c(
                  VCol,
                  { staticClass: "pa-0", attrs: { cols: "12" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        _vm._s(_vm.$stringConstants("currencyTitle")) + " *"
                      ),
                    ]),
                    _c(VAutocomplete, {
                      attrs: {
                        items: _vm.globalCurrenciesList,
                        "item-text": "name",
                        outlined: "",
                        placeholder: _vm.$stringConstants(
                          "currencyPlaceholderText"
                        ),
                        dense: "",
                        flat: "",
                        attach: "",
                        "return-object": "",
                        solo: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(data.item.name) +
                                    " (" +
                                    _vm._s(data.item.symbol) +
                                    ") "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("div", { staticClass: "fn-sm fw-5" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.name) +
                                    " (" +
                                    _vm._s(item.symbol) +
                                    ") "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.userProfile.businessProfile.config.currency,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.config,
                            "currency",
                            $$v
                          )
                        },
                        expression:
                          "userProfile.businessProfile.config.currency",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { staticClass: "pa-0", attrs: { cols: "12" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        _vm._s(_vm.$stringConstants("timeZoneTitle")) + " *"
                      ),
                    ]),
                    _c(VAutocomplete, {
                      attrs: {
                        items: _vm.globalTimeZonesList,
                        "item-text": "name",
                        "item-value": "id",
                        outlined: "",
                        placeholder: _vm.$stringConstants(
                          "timeZonePlaceholderText"
                        ),
                        dense: "",
                        flat: "",
                        attach: "",
                        solo: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c("div", [
                                _vm._v(" " + _vm._s(data.item.name) + " "),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("div", { staticClass: "fn-sm fw-5" }, [
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.userProfile.businessProfile.config.timezone,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.config,
                            "timezone",
                            $$v
                          )
                        },
                        expression:
                          "userProfile.businessProfile.config.timezone",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { staticClass: "pa-0", attrs: { cols: "12" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$stringConstants("onboardPreferenceFieldArea")
                        )
                      ),
                    ]),
                    _c(VSelect, {
                      attrs: {
                        items: _vm.areaMeasurementUnitsList,
                        "item-text": "title",
                        "item-value": "value",
                        placeholder: _vm.$stringConstants(
                          "fieldUnitPlaceholder"
                        ),
                        dense: "",
                        outlined: "",
                      },
                      model: {
                        value:
                          _vm.userProfile.businessProfile.config
                            .defaultAreaMeasurementUnit,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.config,
                            "defaultAreaMeasurementUnit",
                            $$v
                          )
                        },
                        expression:
                          "userProfile.businessProfile.config.defaultAreaMeasurementUnit",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { staticClass: "pa-0", attrs: { cols: "12" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$stringConstants("onboardPreferenceFieldColor")
                        )
                      ),
                    ]),
                    _c(VTextField, {
                      staticClass: "ma-0 pa-0 pb-0",
                      attrs: { outlined: "", dense: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                VMenu,
                                {
                                  attrs: {
                                    top: "",
                                    "nudge-bottom": "105",
                                    "nudge-left": "16",
                                    "close-on-content-click": false,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              { style: _vm.swatchStyle },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.menu,
                                    callback: function ($$v) {
                                      _vm.menu = $$v
                                    },
                                    expression: "menu",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    [
                                      _c(
                                        VCardText,
                                        { staticClass: "pa-0" },
                                        [
                                          _c(VColorPicker, {
                                            attrs: {
                                              "hide-mode-switch": "",
                                              mode: "hexa",
                                              flat: "",
                                            },
                                            model: {
                                              value: _vm.onboardConfigTempColor,
                                              callback: function ($$v) {
                                                _vm.onboardConfigTempColor = $$v
                                              },
                                              expression:
                                                "onboardConfigTempColor",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.onboardConfigTempColor,
                        callback: function ($$v) {
                          _vm.onboardConfigTempColor =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "onboardConfigTempColor",
                      },
                    }),
                  ],
                  1
                ),
                _c(VCol, { staticClass: "pa-0", attrs: { cols: "12" } }, [
                  _c("div", { staticClass: "pt-0 px-0 min-hd" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$stringConstants("teamWorkTimingTitle")) +
                        " "
                    ),
                  ]),
                ]),
                _c(
                  VCol,
                  { staticClass: "py-0 pl-0 pr-2", attrs: { cols: "6" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$stringConstants("onboardPreferenceWorkStart")
                        )
                      ),
                    ]),
                    _c("VueCtkDateTimePicker", {
                      staticClass: "sl-time pb-6",
                      attrs: {
                        color: "#1d34ad",
                        noKeyboard: "",
                        format: "HH:mm",
                        formatted: "HH:mm",
                        "append-icon": "mdi-clock-time-four-outline",
                        label: "HH:MM",
                        "no-label": "",
                        "only-time": "",
                        noClearButton: "",
                      },
                      model: {
                        value:
                          _vm.userProfile.businessProfile.config
                            .workingStartTimeConverted,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.config,
                            "workingStartTimeConverted",
                            $$v
                          )
                        },
                        expression:
                          "userProfile.businessProfile.config.workingStartTimeConverted",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { staticClass: "py-0 pl-2 pr-0", attrs: { cols: "6" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        _vm._s(_vm.$stringConstants("onboardPreferenceWorkEnd"))
                      ),
                    ]),
                    _c("VueCtkDateTimePicker", {
                      staticClass: "sl-time pb-6",
                      attrs: {
                        color: "#1d34ad",
                        noKeyboard: "",
                        format: "HH:mm",
                        formatted: "HH:mm",
                        "append-icon": "mdi-clock-time-four-outline",
                        "no-label": "",
                        label: "HH:MM",
                        "only-time": "",
                        noClearButton: "",
                      },
                      model: {
                        value:
                          _vm.userProfile.businessProfile.config
                            .workingEndTimeConverted,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.config,
                            "workingEndTimeConverted",
                            $$v
                          )
                        },
                        expression:
                          "userProfile.businessProfile.config.workingEndTimeConverted",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { staticClass: "py-0 pl-0", attrs: { cols: "6" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("breakTimeMinutes")) +
                          " "
                      ),
                    ]),
                    _c(VTextField, {
                      attrs: {
                        outlined: "",
                        palceholder: "Break time (minutes)",
                        dense: "",
                        type: "number",
                        required: "",
                      },
                      model: {
                        value:
                          _vm.userProfile.businessProfile.config
                            .allowedBreakTimeADayInMinutes,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.userProfile.businessProfile.config,
                            "allowedBreakTimeADayInMinutes",
                            _vm._n($$v)
                          )
                        },
                        expression:
                          "userProfile.businessProfile.config.allowedBreakTimeADayInMinutes",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "ib width-perc-50" }, [
      _c("div", { staticClass: "pl-7" }, [
        _c("div", { staticClass: "ob-subtitle" }, [
          _vm._v(
            " " + _vm._s(_vm.$stringConstants("defaultLocationTitle")) + " "
          ),
        ]),
        _c("div", { staticClass: "position-relative" }, [
          _c(
            "div",
            {
              staticClass: "position-absolute ma-5",
              staticStyle: { "z-index": "1" },
            },
            [_c("OnboardMapSearch")],
            1
          ),
          _c("div", {
            staticClass: "onboard-map",
            attrs: { id: "onboard-map" },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }