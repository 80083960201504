var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "sb-plan-tm-crd" }, [
      _c(
        "div",
        {
          staticClass: "sb-plan-tm-cir mt-2",
          style: {
            backgroundImage:
              "linear-gradient(160deg, " +
              _vm.teamTypeColor +
              "07, " +
              _vm.teamTypeColor +
              " 40%)",
          },
        },
        [_c("div", { staticClass: "sb-plan-tm-cir-inr" })]
      ),
      _c("div", { staticClass: "sb-plan-tm-type pt-7" }, [
        _vm._v(_vm._s(_vm.teamTypeText)),
      ]),
      _c("div", { staticClass: "sb-plan-tm-mem pt-1" }, [
        _vm._v(_vm._s(_vm.membersText)),
      ]),
      _c("div", { staticClass: "sb-plan-tm-prc pt-7" }, [
        _vm._v(
          _vm._s(_vm.$constantValues("defaultCurrencySymbols").GBP) +
            _vm._s(_vm.perMemberCostText)
        ),
      ]),
      _c("div", { staticClass: "sb-plan-tm-subt" }, [
        _vm._v(_vm._s(_vm.perMemberCostSubtitleText)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }