import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { listContainsItem } from '@/utils/helpers';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import { mapMutations } from 'vuex';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import ConstantValues from '@/plugins/constantValues';
import LoadMoreDropdown from "@/components/shared/load-more-jobs-dropdown.vue";
export default {
  name: "TeamJobsProducts",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  data: function data() {
    return {
      searchTimer: null
    };
  },
  components: {
    LoadMoreDropdown: LoadMoreDropdown
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFilterOptions: "setClearFilterOptions"
  })), {}, {
    applySearchJobProductTimeout: function applySearchJobProductTimeout(e) {
      var _this = this;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(function () {
        _this.searchProducts();
      }, ConstantValues.defaultSearchTimeout);
    },
    refeatchProductsList: function refeatchProductsList() {
      this.hasMoreData = true;
    },
    searchProducts: function searchProducts() {
      var _this2 = this;
      this.teamJobProductsList = [];
      this.clearFilterOptions();
      this.$store.dispatch('setTeamJobProductsSearchText').then(function (res) {
        _this2.$store.dispatch('getTeamJobProducts');
        if (_this2.teamJobProductsSearchText == '') {
          _this2.clearFilterOptions();
        }
      });
    },
    removeProductFromProductsList: function removeProductFromProductsList(productIndex) {
      this.teamJobProducts = this.teamJobProducts.filter(function (prd, index) {
        return index !== productIndex;
      });
      this.enableTeamJobDiscard();
    },
    productExistsInProductsList: function productExistsInProductsList() {
      return listContainsItem(this.teamJobProducts, [ConstantValues.defaultObjectIdentifier], this.teamJobProductToAdd.id);
    },
    addProductToProductsList: function addProductToProductsList() {
      var _this3 = this;
      if (this.teamJobProductToAdd) {
        this.toggleProductSelectionDropdown();
        this.teamJobProductsLoader = true;
        setTimeout(function () {
          if (!_this3.productExistsInProductsList()) {
            _this3.teamJobProducts.push(new TeamJobProduct().getTeamJobProduct(_this3.teamJobProductToAdd));
            _this3.enableTeamJobDiscard();
          }
          var element = document.querySelector('#productSelectionMenu .mdi-close');
          element.click();
          _this3.teamJobProductToAdd = new TeamJobProduct();
          _this3.teamJobProductsSearchText = null;
          _this3.teamJobProductsLoader = false;
        }, 200);
      }
    },
    onChangeProductValue: function onChangeProductValue() {
      this.enableTeamJobDiscard();
    },
    toggleProductSelectionDropdown: function toggleProductSelectionDropdown() {
      this.teamJobShowJobAddProductsExpandedView = !this.teamJobShowJobAddProductsExpandedView;
      this.teamJobShowJobAddProductsDropdown = !this.teamJobShowJobAddProductsDropdown;
    },
    toggleDropdownAndOpenSelection: function toggleDropdownAndOpenSelection() {
      if (this.teamJobViewPreference.products.isEditable) {
        this.toggleProductSelectionDropdown();
        setTimeout(function () {
          var dropdownElement = document.getElementById('productSelectionDropdownMenu');
          if (dropdownElement) dropdownElement.click();
        }, 100);
      }
    },
    appendMoreProducts: function appendMoreProducts() {
      var _this4 = this;
      this.clearFilterOptions();
      this.$store.dispatch('setTeamJobProductsSearchText').then(function (res) {
        _this4.$store.dispatch('getTeamJobProducts');
      });
    },
    isProductPresentInSelectedProductList: function isProductPresentInSelectedProductList(product) {
      return listContainsItem(this.teamJobProducts, [ConstantValues.defaultObjectIdentifier], product.id);
    }
  })
};