export default {
  name: 'InvoicesMapExternalMixin',
  data: function data() {
    return {
      mapLoader: false
    };
  },
  computed: {
    invoiceDraftPreviewDetailsExternal: {
      get: function get() {
        return this.$store.getters.invoiceDraftPreviewDetailsExternal;
      },
      set: function set(newVal) {
        this.$store.state.invoicesExternalModule.invoiceDraftPreviewDetailsExternal = newVal;
      }
    },
    selectedJobsForInvoiceExternal: {
      get: function get() {
        return this.$store.getters.selectedJobsForInvoiceExternal;
      },
      set: function set(newVal) {
        this.$store.state.invoicesExternalModule.selectedJobsForInvoiceExternal = newVal;
      }
    }
  }
};