import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import { formFieldPb } from '@/utils/uiconstants';
import { validateRegexAlphaNumeric, validateRegexAlpha, validateRegexPhoneNumber } from '@/utils/helpers';
import StringConstants from '@/plugins/stringConstants';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'contactsModule': ['contactLoader', 'contactFarmAddresses', 'contactProfile', 'contactDetailsLoader', 'contactFieldList', 'contactOnGoingJobList'],
  'root': ['isRoutedFromReferencePage', 'globalCountriesList']
};
export default {
  name: 'ContactComputedMixin',
  data: function data() {
    return {
      formFieldPb: formFieldPb
    };
  },
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    contactPhone: function contactPhone() {
      return this.$store.getters.contactProfile.primaryContactNumber;
    },
    contactEmail: function contactEmail() {
      return this.$store.getters.contactProfile.email;
    },
    businessProfilePrefix: {
      get: function get() {
        return this.$store.getters.contactProfile.businessProfile.shortTitle;
      },
      set: function set(newVal) {
        this.$store.state.contactsModule.contactProfile.businessProfile.shortTitle = newVal;
      }
    }
  }),
  methods: {
    checkKeyDownAlpha: function checkKeyDownAlpha(event) {
      validateRegexAlpha(event);
    },
    checkKeyDownAlphaNumeric: function checkKeyDownAlphaNumeric(event) {
      validateRegexAlphaNumeric(event);
    },
    checkPhoneNumber: function checkPhoneNumber(event) {
      validateRegexPhoneNumber(event);
    },
    getValidatedString: function getValidatedString(rawString) {
      if (rawString) {
        return rawString.toString().replace(/\s+/g, '').replace(/[0-9]/g, '').replace(/\./g, '').toUpperCase();
      }
    },
    generatePhoneValidationErrors: function generatePhoneValidationErrors() {
      var errors = [];
      if (!this.$v.contactPhone.$dirty) return errors;
      if (!this.$v.contactPhone.format && this.contactPhone.length > 0) {
        errors.push(StringConstants.userFormatPhoneNumber);
        this.fieldsInvalid = true;
      } else if (!this.$v.contactPhone.minLength) {
        errors.push(StringConstants.userMinlengthPhoneNumber);
        this.fieldsInvalid = true;
      } else if (!this.$v.contactPhone.maxLength) {
        errors.push(StringConstants.userMaxLengthPhoneNumber);
        this.fieldsInvalid = true;
      }
      return errors;
    }
  }
};