import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import { closeOverlay } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import farmersComputedMixin from '@/components/farmers/farmers-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { UserRoleType } from '@/enum/userRoleType';
export default {
  name: 'inviteFarmer',
  mixins: [validationMixin, farmersComputedMixin],
  validations: {
    userInviteeEmail: {
      required: required,
      email: email
    }
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      fieldsInvalid: true,
      inxClick: null
    };
  },
  components: {
    Button: Button
  },
  mounted: function mounted() {
    this.setClearInviteeProfile();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    setClearInviteeProfile: 'setClearInviteeProfile'
  })), {}, {
    proceedFarmerInvitation: function proceedFarmerInvitation() {
      var _this = this;
      if (this.$v.$invalid) return;
      var newFarmerInviteeObj = {
        inviteeEmail: this.userInviteeEmail,
        inviteeRole: UserRoleType.Farmer
      };
      this.addFarmerLoader = true;
      this.$store.dispatch('sendUserInvite', newFarmerInviteeObj).then(function (res) {
        if (res) {
          closeOverlay('addFarmerFormOverlay');
        }
        _this.addFarmerLoader = false;
      });
    },
    generateValidationErrors: function generateValidationErrors() {
      var errors = [];
      if (!this.$v.userInviteeEmail.$dirty) return errors;
      if (!this.$v.userInviteeEmail.required) {
        errors.push(this.$stringConstants('userRequiredEmailAddress'));
        this.fieldsInvalid = true;
      } else if (!this.$v.userInviteeEmail.email) {
        errors.push(this.$stringConstants('userFormatEmailAddress'));
        this.fieldsInvalid = true;
      } else {
        this.fieldsInvalid = false;
      }
      return errors;
    },
    closeFarmerAddForm: function closeFarmerAddForm() {
      closeOverlay("addFarmerFormOverlay");
    }
  })
};