import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    { staticClass: "fill-height", attrs: { id: "agd-calendar" } },
    [
      _c(
        VCol,
        [
          _c(
            VSheet,
            { attrs: { height: "64" } },
            [
              _c(
                VToolbar,
                { attrs: { flat: "" } },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "mr-4 py-5 px-5",
                      staticStyle: { border: "1px solid #e6e6e6" },
                      attrs: {
                        outlined: "",
                        color: _vm.brandColor,
                        disabled: _vm.jobsCalendarLoader || _vm.listLoading,
                      },
                      on: { click: _vm.setToday },
                    },
                    [
                      _c("span", { staticClass: "text-capitalize" }, [
                        _vm._v("Today"),
                      ]),
                    ]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "py-2 px-2",
                      staticStyle: { border: "1px solid #e6e6e6" },
                      attrs: {
                        color: "grey darken-2",
                        fab: "",
                        text: "",
                        small: "",
                        disabled: _vm.jobsCalendarLoader || _vm.listLoading,
                      },
                      on: { click: _vm.prev },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-chevron-left "),
                      ]),
                    ],
                    1
                  ),
                  _vm.$refs.calendar
                    ? _c(
                        VToolbarTitle,
                        {
                          staticClass: "mx-3",
                          staticStyle: {
                            "font-size": "1em",
                            "font-weight": "500",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    VBtn,
                    {
                      staticClass: "py-2 px-2",
                      staticStyle: { border: "1px solid #e6e6e6" },
                      attrs: {
                        fab: "",
                        text: "",
                        small: "",
                        disabled: _vm.jobsCalendarLoader || _vm.listLoading,
                        color: "grey darken-2",
                      },
                      on: { click: _vm.next },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v(" mdi-chevron-right "),
                      ]),
                    ],
                    1
                  ),
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        outlined: "",
                        color: _vm.brandColor,
                        disabled: _vm.jobsCalendarLoader || _vm.listLoading,
                      },
                      on: { click: _vm.openUnscheduledJobsView },
                    },
                    [
                      _c("span", { staticClass: "text-none" }, [
                        _vm._v("Unscheduled jobs"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VSheet,
            {
              staticClass: "calendar-wrapper",
              class: {
                "overflow-clip": _vm.jobsCalendarLoader || _vm.listLoading,
              },
            },
            [
              _c(VCalendar, {
                ref: "calendar",
                attrs: {
                  events: _vm.calendarViewJobEvents,
                  "event-color": _vm.getEventColorLight,
                  "event-overlap-mode": "stack",
                  "event-more": false,
                  type: _vm.type,
                  "event-height": 54,
                  "show-month-on-first": false,
                },
                on: {
                  "click:event": function (jobEvent, $pointerEvent) {
                    return _vm.showJobInfo($pointerEvent, jobEvent)
                  },
                  "click:more": _vm.viewDay,
                  "click:date": _vm.addEvent,
                  change: _vm.updateRange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "day",
                    fn: function (ref) {
                      var date = ref.date
                      return [
                        _c("CalendarDay", {
                          attrs: {
                            id: date,
                            "is-drop-zone-active": _vm.isDropZoneActive,
                          },
                          on: {
                            "update-job": function (jobId) {
                              return _vm.updateJobScheduleDate(date, jobId)
                            },
                            "add-job": function ($event) {
                              return _vm.openAddJobFlyout(date)
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "event",
                    fn: function (ref) {
                      var event = ref.event
                      return [
                        _c(
                          "CalendarJob",
                          _vm._b(
                            {
                              attrs: {
                                number: event.moreInformation.number,
                                customer:
                                  event.moreInformation.customer.businessName,
                                contractor:
                                  event.moreInformation.contractor.businessName,
                                loading:
                                  (_vm.updatingScheduleForJobId || "") ===
                                  event.id,
                              },
                              on: {
                                "show-job-menu": function (nativeEvent) {
                                  return _vm.showJobMenu({
                                    nativeEvent: nativeEvent,
                                    event: event,
                                  })
                                },
                                dragenter: function ($event) {
                                  $event.preventDefault()
                                  return _vm.activateDropZone($event)
                                },
                                dragend: _vm.deactivateDropZone,
                              },
                            },
                            "CalendarJob",
                            event,
                            false
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.focusMonth,
                  callback: function ($$v) {
                    _vm.focusMonth = $$v
                  },
                  expression: "focusMonth",
                },
              }),
              _c(
                VMenu,
                {
                  attrs: {
                    "close-on-content-click": false,
                    activator: _vm.selectedElement,
                    "offset-y": "",
                    "nudge-bottom": "20",
                    "nudge-left": "180",
                  },
                  model: {
                    value: _vm.selectedOpen,
                    callback: function ($$v) {
                      _vm.selectedOpen = $$v
                    },
                    expression: "selectedOpen",
                  },
                },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "px-0 py-0",
                      attrs: { color: "white", width: "200", flat: "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "event-menu-item",
                          on: { click: _vm.navigateToJobDetail },
                        },
                        [
                          _c(
                            VIcon,
                            { attrs: { size: "18", color: "black" } },
                            [_vm._v("mdi-briefcase")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$stringConstants("menuViewJob")) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "event-menu-item",
                          on: {
                            click: function ($event) {
                              return _vm.openAddJobFlyout()
                            },
                          },
                        },
                        [
                          _c(
                            VIcon,
                            { attrs: { size: "18", color: "black" } },
                            [_vm._v("mdi-pencil")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$stringConstants("menuEditJob")) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _vm.selectedEvent.status ===
                      _vm.TeamJobStatusType.Completed
                        ? _c(
                            "div",
                            {
                              staticClass: "event-menu-item danger--text",
                              on: { click: _vm.handleJobDelete },
                            },
                            [
                              _c(
                                VIcon,
                                { attrs: { size: "18", color: "danger" } },
                                [_vm._v("mdi-delete")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$stringConstants("deleteJob")) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : ![
                            _vm.TeamJobStatusType.AwaitingCompletion,
                            _vm.TeamJobStatusType.InProgress,
                            _vm.TeamJobStatusType.PartCompleted,
                          ].includes(_vm.selectedEvent.status)
                        ? _c(
                            "div",
                            {
                              staticClass: "event-menu-item danger--text",
                              on: { click: _vm.handleJobCancel },
                            },
                            [
                              _c(
                                VIcon,
                                { attrs: { size: "18", color: "danger" } },
                                [_vm._v("mdi-delete")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants("menuCancelJob")
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm.jobsCalendarLoader || _vm.listLoading
                ? _c("div", { staticClass: "calendar-loader-overlay" }, [
                    _c(
                      "div",
                      { staticClass: "grid-container" },
                      _vm._l(35, function (j) {
                        return _c(
                          "div",
                          { key: j + "two", staticClass: "grid-item" },
                          _vm._l(2, function (k) {
                            return _c(
                              "div",
                              {
                                key: k + "three",
                                staticClass:
                                  "d-flex py-3 pl-3 pr-4 justify-space-between calendar-job mb-2 calendar-loader-card-bg",
                              },
                              [
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c(VSkeletonLoader, {
                                      attrs: {
                                        type: "text, text",
                                        width: "100",
                                        height: "14",
                                      },
                                    }),
                                    _c(VSkeletonLoader, {
                                      staticClass: "mt-1",
                                      attrs: {
                                        type: "text, text",
                                        width: "50",
                                        height: "14",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(VSkeletonLoader, {
                                  attrs: {
                                    type: "avatar",
                                    width: "28",
                                    height: "28",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showCalendarInfoWindow
        ? _c("CalendarInfoWindow", {
            ref: "calendarInfoWindow",
            attrs: {
              height: _vm.calendarInfoWindowDimensions.height,
              width: _vm.calendarInfoWindowDimensions.width,
              "selected-event": _vm.selectedEvent,
              "job-info-window-position": _vm.jobInfoWindowPosition,
            },
            on: {
              "show-job-details": function ($event) {
                return _vm.showJobDetails(_vm.selectedEvent)
              },
              "close-job-info-window": _vm.showJobInfo,
            },
          })
        : _vm._e(),
      _c("UnscheduledJobs", {
        on: { submit: _vm.cleanupLoadersAndGetTeamJobsList },
        model: {
          value: _vm.showUnscheduledJobs,
          callback: function ($$v) {
            _vm.showUnscheduledJobs = $$v
          },
          expression: "showUnscheduledJobs",
        },
      }),
      _c("AddJobFlyout", {
        on: { submit: _vm.cleanupLoadersAndGetTeamJobsList },
        model: {
          value: _vm.showAddJobForm,
          callback: function ($$v) {
            _vm.showAddJobForm = $$v
          },
          expression: "showAddJobForm",
        },
      }),
      _c("ConfirmationDialogBox", {
        attrs: {
          "reason-required": _vm.isReasonRequiredForCancellation,
          loading: _vm.isJobCancellationInProgress,
        },
        on: {
          cancel: function ($event) {
            _vm.askDeleteConfirmation = false
          },
          confirm: _vm.deleteJob,
        },
        model: {
          value: _vm.askDeleteConfirmation,
          callback: function ($$v) {
            _vm.askDeleteConfirmation = $$v
          },
          expression: "askDeleteConfirmation",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }