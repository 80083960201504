import { BaseButton as Button } from '@/components/shared/buttons/index';
export default {
  name: 'BulkActionResponseDialog',
  props: ['bulk-action-callback-method', 'hasBulkActionCallbackMethod'],
  computed: {
    bulkActionResponse: {
      get: function get() {
        return this.$store.state.bulkActionResponse;
      },
      set: function set(newValue) {
        this.$store.state.bulkActionResponse = newValue;
      }
    },
    dialogBulkActionResponse: {
      get: function get() {
        return this.$store.state.dialogBulkActionResponse;
      },
      set: function set(newValue) {
        this.$store.state.dialogBulkActionResponse = newValue;
      }
    },
    dialogBulkActionResponseTitle: {
      get: function get() {
        return this.$store.state.dialogBulkActionResponseTitle;
      },
      set: function set(newValue) {
        this.$store.state.dialogBulkActionResponseTitle = newValue;
      }
    },
    enumImportStatus: function enumImportStatus() {
      return this.$store.state.integrationsModule.enumImportStatus;
    }
  },
  components: {
    Button: Button
  },
  methods: {
    closeDialogImportUserResponse: function closeDialogImportUserResponse() {
      this.bulkActionResponse = null;
      this.dialogBulkActionResponse = false;
      this.dialogBulkActionResponseTitle = null;
    },
    bulkActionCallback: function bulkActionCallback() {
      if (this.hasBulkActionCallbackMethod) {
        this.$emit('bulk-action-callback-method');
      } else {
        this.closeDialogImportUserResponse();
      }
    }
  }
};