var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InvitationDialog", {
        staticStyle: { "z-index": "99999" },
        attrs: { item: _vm.dialogInvitation },
        on: { cancel: _vm.handleDialogCancel, proceed: _vm.handleDialogAction },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(_vm._s(_vm.invitationDialogPromptTitle))]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [_vm._v(_vm._s(_vm.invitationDialogPromptSubtitle))]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { attrs: { id: "overlay" } }),
      _c("ContractorListView"),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay position-relative manageContractorFormOverlay",
          attrs: { id: "manageContractorFormOverlay" },
        },
        [_c("ContractorManageForm")],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay position-relative addContractorFormOverlay",
          attrs: { id: "addContractorFormOverlay" },
        },
        [_c("ContractorAddForm")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }