import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hide-scroll lt-box-h", attrs: { id: "account-business" } },
    [
      !_vm.usersLoader
        ? _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("CompanyDetailsForm"),
              _c("CompanyAddressForm"),
              _c("InvoicePaymentsForm"),
              _c("BusinessLocation"),
              _c(
                VRow,
                {
                  staticClass: "btns-bottom-box px-8 py-3 mt-10",
                  staticStyle: { position: "sticky" },
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(VCol, { attrs: { cols: "3" } }),
                  _c(
                    VCol,
                    { staticClass: "px-2", attrs: { cols: "7" } },
                    [
                      _c("ButtonActionPrimary", {
                        staticClass: "fl-rt",
                        attrs: {
                          width: "300px",
                          height: 50,
                          disabled: !_vm.isFieldsValid,
                          textValue: "Save changes",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.updateAccountBusinessProfile()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(VCol, { attrs: { cols: "2" } }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.usersLoader
        ? _c(VRow, [
            _c(
              "div",
              { staticClass: "text-center mx-auto" },
              [_c("Loader")],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }