import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import labelAndDetailsReadonly from '@/components/shared/readonly/labelAndDetails.vue';
export default {
  name: 'ContactPersonalDetailsForm',
  mixins: [contactComputedMixin],
  components: {
    SectionLeftHeadings: SectionLeftHeadings,
    labelAndDetailsReadonly: labelAndDetailsReadonly
  },
  methods: {
    getValidatedString: function getValidatedString(data) {
      if (data) return ' ' + data;
      return '';
    },
    getAddress: function getAddress(data) {
      return this.getValidatedString(data.addressLine1) + this.getValidatedString(data.addressLine2) + this.getValidatedString(data.city) + this.getValidatedString(data.state) + this.getValidatedString(data.country) + this.getValidatedString(data.postalCode);
    }
  }
};