import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-9" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(
              VCol,
              { attrs: { cols: "8" } },
              [_c("HeaderPrimary", { attrs: { textValue: "Add timesheet" } })],
              1
            ),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    staticClass: "oy-cl",
                    attrs: { small: "", id: "closeTimeSheetAddForm" },
                    on: { click: _vm.closeTimeSheetAddForm },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form-scroll-view" },
      [
        _c(
          VRow,
          { staticClass: "px-9 py-7", attrs: { "no-gutters": "" } },
          [
            _c(
              VCol,
              { attrs: { cols: "12 pa-0" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("teamMemberText")) + " * "
                  ),
                ]),
                _c(VAutocomplete, {
                  attrs: {
                    id: "timeSheetType",
                    items: _vm.timesheetsMembersList,
                    "search-input": _vm.timesheetMembersSearchText,
                    clearable: "",
                    "item-text": "name",
                    "item-value": "id",
                    placeholder: _vm.$stringConstants(
                      "teamMemberSelectPlaceholder"
                    ),
                    outlined: "",
                    dense: "",
                  },
                  on: {
                    "update:searchInput": function ($event) {
                      _vm.timesheetMembersSearchText = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.timesheetMembersSearchText = $event
                    },
                    keyup: _vm.applyMembersFilterAddSearchTimer,
                    "click:clear": _vm.applyMembersFilterAddSearchTimer,
                    change: function ($event) {
                      return _vm.proceeedToGetMemberTime(_vm.timeMemberId)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "selection",
                      fn: function (ref) {
                        var item = ref.item
                        var selected = ref.selected
                        return [
                          _c("span", {
                            attrs: { "input-value": selected },
                            domProps: { textContent: _vm._s(item.name) },
                          }),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.timeMemberId,
                    callback: function ($$v) {
                      _vm.timeMemberId = $$v
                    },
                    expression: "timeMemberId",
                  },
                }),
              ],
              1
            ),
            _c(
              VCol,
              { attrs: { cols: "12 pa-0" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("timeSheetTypeText")) +
                      " * "
                  ),
                ]),
                _c(VSelect, {
                  attrs: {
                    items: _vm.timesheetsTypeList,
                    "item-value": "value",
                    "item-text": "title",
                    dense: "",
                    outlined: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.checkTimeSheetType(_vm.timeSheetType)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function (data) {
                        return [
                          _c(
                            VListItemContent,
                            [
                              _c(VListItemTitle, {
                                domProps: {
                                  innerHTML: _vm._s(data.item.title),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getItemText(data.item.title)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.timeSheetType,
                    callback: function ($$v) {
                      _vm.timeSheetType = $$v
                    },
                    expression: "timeSheetType",
                  },
                }),
              ],
              1
            ),
            _c(
              VCol,
              { attrs: { cols: "12 pa-0" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("dateText")) + " * "
                  ),
                ]),
                _c(
                  VMenu,
                  {
                    ref: "menuDate",
                    attrs: {
                      "close-on-content-click": false,
                      "return-value": _vm.timeSheetDate,
                      transition: "scale-transition",
                      "offset-y": "",
                      "min-width": "auto",
                    },
                    on: {
                      "update:returnValue": function ($event) {
                        _vm.timeSheetDate = $event
                      },
                      "update:return-value": function ($event) {
                        _vm.timeSheetDate = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              VTextField,
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      value: _vm.computedDateFormatted,
                                      placeholder: _vm.$stringConstants(
                                        "selectDatePlaceholder"
                                      ),
                                      "append-icon": "mdi-calendar",
                                      readonly: "",
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: {
                                      "click:append": on.click,
                                      "click:clear": function ($event) {
                                        _vm.timeSheetDate = null
                                      },
                                    },
                                  },
                                  "v-text-field",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.menuDate,
                      callback: function ($$v) {
                        _vm.menuDate = $$v
                      },
                      expression: "menuDate",
                    },
                  },
                  [
                    _c(
                      VDatePicker,
                      {
                        attrs: {
                          "no-title": "",
                          color: "#1d34ad",
                          scrollable: "",
                        },
                        model: {
                          value: _vm.timeSheetDate,
                          callback: function ($$v) {
                            _vm.timeSheetDate = $$v
                          },
                          expression: "timeSheetDate",
                        },
                      },
                      [
                        _c(VSpacer),
                        _c(
                          VBtn,
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.menuDate = false
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          VBtn,
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.menuDate.save(
                                  _vm.timeSheetDate
                                )
                              },
                            },
                          },
                          [_vm._v(" OK ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.showTimeSheetFieldByType
              ? _c(
                  VCol,
                  { attrs: { cols: "12 pa-0" } },
                  [
                    _c(
                      "div",
                      { staticClass: "field-input-label pt-2 text-uppercase" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("timeDetailsText")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      VRow,
                      {
                        staticClass: "pt-2",
                        attrs: { "no-gutters": "", cols: "12" },
                      },
                      [
                        _c(
                          VCol,
                          { staticClass: "pb-0 pr-2", attrs: { cols: "6" } },
                          [
                            _c("div", { staticClass: "field-input-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$stringConstants("startTime")) +
                                  " * "
                              ),
                            ]),
                            _c("VueCtkDateTimePicker", {
                              staticClass: "sl-time pb-6",
                              attrs: {
                                color: "#1d34ad",
                                noKeyboard: "",
                                format: "HH:mm",
                                formatted: "HH:mm",
                                "append-icon": "mdi-clock-time-four-outline",
                                "no-label": "",
                                "only-time": "",
                                noClearButton: "",
                              },
                              model: {
                                value: _vm.timeStart,
                                callback: function ($$v) {
                                  _vm.timeStart = $$v
                                },
                                expression: "timeStart",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { staticClass: "pb-0 pl-2", attrs: { cols: "6" } },
                          [
                            _c("div", { staticClass: "field-input-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$stringConstants("endTime")) +
                                  " * "
                              ),
                            ]),
                            _c("VueCtkDateTimePicker", {
                              staticClass: "sl-time pb-6",
                              attrs: {
                                color: "#1d34ad",
                                noKeyboard: "",
                                format: "HH:mm",
                                formatted: "HH:mm",
                                "append-icon": "mdi-clock-time-four-outline",
                                "no-label": "",
                                "only-time": "",
                                noClearButton: "",
                              },
                              model: {
                                value: _vm.timeEnd,
                                callback: function ($$v) {
                                  _vm.timeEnd = $$v
                                },
                                expression: "timeEnd",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showTimeSheetFieldByType
              ? _c(
                  VCol,
                  { attrs: { cols: "12 pa-0" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$stringConstants("travelTime")) + " "
                      ),
                    ]),
                    _c(
                      VRow,
                      { attrs: { "no-gutters": "", cols: "12" } },
                      [
                        _c(
                          VCol,
                          { staticClass: "py-0 pr-2 time-drop" },
                          [
                            _c(VSelect, {
                              attrs: {
                                items: _vm.hoursList,
                                "item-value": "hour",
                                "item-text": "hour",
                                suffix: "Hours",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.travelHours,
                                callback: function ($$v) {
                                  _vm.travelHours = $$v
                                },
                                expression: "travelHours",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { staticClass: "py-0 pl-2 time-drop" },
                          [
                            _c(VSelect, {
                              attrs: {
                                items: _vm.minutesList,
                                "item-value": "minute",
                                "item-text": "minute",
                                suffix: "Mins",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.travelMin,
                                callback: function ($$v) {
                                  _vm.travelMin = $$v
                                },
                                expression: "travelMin",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showTimeSheetFieldByType
              ? _c(
                  VCol,
                  { attrs: { cols: "12 pa-0" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$stringConstants("breakTime")) + " "
                      ),
                    ]),
                    _c(
                      VRow,
                      { attrs: { "no-gutters": "", cols: "12" } },
                      [
                        _c(
                          VCol,
                          { staticClass: "py-0 pr-2 time-drop" },
                          [
                            _c(VSelect, {
                              attrs: {
                                items: _vm.hoursList,
                                "item-value": "hour",
                                "item-text": "hour",
                                suffix: "Hours",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.breakHours,
                                callback: function ($$v) {
                                  _vm.breakHours = $$v
                                },
                                expression: "breakHours",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { staticClass: "py-0 pl-2 time-drop" },
                          [
                            _c(VSelect, {
                              attrs: {
                                items: _vm.minutesList,
                                "item-value": "minute",
                                "item-text": "minute",
                                suffix: "Mins",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.breakMin,
                                callback: function ($$v) {
                                  _vm.breakMin = $$v
                                },
                                expression: "breakMin",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              VCol,
              { attrs: { cols: "12 pa-0" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(" " + _vm._s(_vm.$stringConstants("noteText")) + " "),
                ]),
                _c(VTextarea, {
                  attrs: {
                    outlined: "",
                    placeholder: "Type here",
                    "no-resize": "",
                    rows: "2",
                    "row-height": "15",
                  },
                  model: {
                    value: _vm.timeNote,
                    callback: function ($$v) {
                      _vm.timeNote = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "timeNote",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btns-bottom-box px-8 py-4" },
      [
        _c("Button", {
          attrs: {
            variant: "primary",
            color: "primary",
            disabled: _vm.addTimesheetLoader || _vm.isDisabledBtn,
            loading: _vm.addTimesheetLoader,
            textValue: _vm.$stringConstants("timeSheetAddText"),
            rightIcon: "mdi-arrow-right",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.addTimeSheet()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }