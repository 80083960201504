import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.trim.js";
import { mapMutations } from 'vuex';
import { checkFieldsValidity } from '@/utils/helpers';
import { routeCustomers } from '@/utils/endpoints';
import router from '@/router/index';
import permissionsMixin from '@/mixins/permissions-mixin';
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import ContactPersonalDetailsForm from './internal-contact-personal-details-form.vue';
import SecondaryContactDetailsForm from './internal-contact-secondary-contact-details.vue';
import ContactCompanyDetailsForm from './internal-contact-company-details.vue';
import ContactCompanyAddressForm from './internal-contact-company-address.vue';
import { ButtonActionPrimary, HoverTextButton } from '@/components/shared/buttons/index';
export default {
  mixins: [permissionsMixin, contactComputedMixin],
  components: {
    ContactPersonalDetailsForm: ContactPersonalDetailsForm,
    SecondaryContactDetailsForm: SecondaryContactDetailsForm,
    ContactCompanyDetailsForm: ContactCompanyDetailsForm,
    ContactCompanyAddressForm: ContactCompanyAddressForm,
    ButtonActionPrimary: ButtonActionPrimary,
    HoverTextButton: HoverTextButton
  },
  data: function data() {
    return {
      isEdiablePrefix: true
    };
  },
  computed: {
    isFieldsValid: function isFieldsValid() {
      if (checkFieldsValidity([this.contactProfile.firstName, this.contactProfile.businessProfile.title, this.contactProfile.businessProfile.address.postalCode, this.contactProfile.businessProfile.address.addressLine1, this.businessProfilePrefix])) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function mounted() {
    this.resetContactProfile();
    this.$store.dispatch("getGlobalCountriesList");
    this.$store.state.dashboardTitle = this.$stringConstants('addContactTitle');
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    resetContactProfile: 'resetContactProfile'
  })), {}, {
    cancelSaveContactProfile: function cancelSaveContactProfile() {
      this.resetContactProfile();
      router.push(routeCustomers);
    },
    saveContactProfile: function saveContactProfile() {
      var _a;
      var bussinessAddress = null;
      if (this.contactProfile.businessProfile.address.addressLine1) {
        bussinessAddress = {
          addressLine1: this.contactProfile.businessProfile.address.addressLine1,
          addressLine2: this.contactProfile.businessProfile.address.addressLine2,
          country: this.contactProfile.businessProfile.address.country,
          city: this.contactProfile.businessProfile.address.city,
          state: this.contactProfile.businessProfile.address.state,
          postalCode: this.contactProfile.businessProfile.address.postalCode,
          coordinate: {
            latitude: 0,
            longitude: 0
          }
        };
      }
      var profileContactObj = {
        businessProfile: {
          vatNumber: this.contactProfile.businessProfile.vatNumber,
          address: bussinessAddress,
          registrationNumber: this.contactProfile.businessProfile.registrationNumber,
          title: this.contactProfile.businessProfile.title.trim(),
          shortTitle: this.businessProfilePrefix
        },
        email: this.contactProfile.email,
        firstName: this.contactProfile.firstName.trim(),
        lastName: (_a = this.contactProfile.lastName) === null || _a === void 0 ? void 0 : _a.trim(),
        colorCode: this.contactProfile.colorCode,
        primaryContactNumber: this.contactProfile.primaryContactNumber,
        secondaryContactNumber: this.contactProfile.secondaryContactNumber,
        secondaryFirstName: this.contactProfile.secondaryFirstName
      };
      this.$store.dispatch('addNewContact', profileContactObj).then(function (res) {
        if (res == true) {
          router.push(routeCustomers);
        }
      });
    }
  })
};