import "core-js/modules/es.array.push.js";
import contactComputedMixin from "@/components/internal-contact/internal-contact-computed";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import moment from "moment";
import Avatar from "@/components/shared/avatars/avatar.vue";
import router from "@/router/index";
import { routeJobs, routeTeamJobsDetails } from "@/utils/endpoints";
import { getAssignedUserNameFromJob, removeAllLocalParamsJobs } from "@/utils/helpers/jobs-helpers";
import { TeamJobStatusType, TeamJobStatusTypeLabel } from '@/enum/teamJobStatusType';
export default {
  name: "FieldDetailsView",
  mixins: [contactComputedMixin, filterParamsMixin],
  components: {
    Avatar: Avatar
  },
  created: function created() {
    this.TeamJobStatusType = TeamJobStatusType;
    this.TeamJobStatusTypeLabel = TeamJobStatusTypeLabel;
  },
  beforeDestroy: function beforeDestroy() {
    this.TeamJobStatusType = null;
    this.TeamJobStatusTypeLabel = null;
  },
  computed: {
    resId: function resId() {
      return window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    }
  },
  methods: {
    getDateTimeFormat: function getDateTimeFormat(date) {
      return moment.utc(date).local().format("DD MMM YYYY");
    },
    getAssignedUserName: function getAssignedUserName(job) {
      return getAssignedUserNameFromJob(job);
    },
    navigateToJobView: function navigateToJobView(cusId) {
      this.$store.state.isRoutedFromReferencePage = true;
      removeAllLocalParamsJobs();
      this.setLocalParamsMx(cusId, "jobCustomerIds");
      router.push(routeJobs);
    },
    openCustomerJobDetails: function openCustomerJobDetails(resourceId) {
      var routeData = this.$router.resolve({
        path: routeTeamJobsDetails + resourceId
      });
      window.open(routeData.href, "_blank");
    }
  }
};