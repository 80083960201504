import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-8" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _vm.machineType == _vm.MachineType.Vehicle &&
            _vm.machineDetails.id != null
              ? _c(VCol, { attrs: { cols: "8" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("vehicleEditTitle")) + " "
                  ),
                ])
              : _vm._e(),
            _vm.machineType == _vm.MachineType.Implement &&
            _vm.machineDetails.id != null
              ? _c(VCol, { attrs: { cols: "8" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("implementEditTitle")) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.machineType == _vm.MachineType.Vehicle &&
            _vm.machineDetails.id == null
              ? _c(VCol, { attrs: { cols: "8" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("vehicleAddTitle")) + " "
                  ),
                ])
              : _vm._e(),
            _vm.machineType == _vm.MachineType.Implement &&
            _vm.machineDetails.id == null
              ? _c(VCol, { attrs: { cols: "8" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("implementAddTitle")) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    attrs: { small: "" },
                    on: { click: _vm.closeEditVehiclesForm },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form-scroll-view" },
      [
        _c(
          VContainer,
          {
            staticClass: "pb-0 pt-7",
            class: [_vm.containerLeftPad, _vm.containerRightPad],
          },
          [
            _c(
              "div",
              { staticClass: "pb-2" },
              [
                _vm.machineType == _vm.MachineType.Vehicle
                  ? _c("SubHeaderPrimary", {
                      attrs: {
                        textValue: _vm.$stringConstants("vehicleEditSubtitle"),
                      },
                    })
                  : _vm._e(),
                _vm.machineType == _vm.MachineType.Implement
                  ? _c("SubHeaderPrimary", {
                      attrs: {
                        textValue: _vm.$stringConstants(
                          "implementEditSubtitle"
                        ),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pt-1 pb-6" },
              [
                _vm.machineType == _vm.MachineType.Vehicle
                  ? _c("DescriptionPrimary", {
                      attrs: {
                        textValue: _vm.$stringConstants(
                          "vehicleEditDescription"
                        ),
                      },
                    })
                  : _vm._e(),
                _vm.machineType == _vm.MachineType.Implement
                  ? _c("DescriptionPrimary", {
                      attrs: {
                        textValue: _vm.$stringConstants(
                          "implementEditDescription"
                        ),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("vehicleNameLabel")) + " "
              ),
            ]),
            _c(VTextField, {
              class: _vm.formFieldPb,
              attrs: {
                placeholder: _vm.$stringConstants("machineTitlePlaceholder"),
                outlined: "",
                dense: "",
              },
              model: {
                value: _vm.machineDetails.title,
                callback: function ($$v) {
                  _vm.$set(_vm.machineDetails, "title", $$v)
                },
                expression: "machineDetails.title",
              },
            }),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("vehicleBrandLabel")) + " "
              ),
            ]),
            _c(VTextField, {
              class: _vm.formFieldPb,
              attrs: {
                placeholder: _vm.$stringConstants("machineMakePlaceholder"),
                outlined: "",
                dense: "",
              },
              model: {
                value: _vm.machineDetails.make,
                callback: function ($$v) {
                  _vm.$set(_vm.machineDetails, "make", $$v)
                },
                expression: "machineDetails.make",
              },
            }),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("vehicleModelLabel")) + " "
              ),
            ]),
            _c(VTextField, {
              class: _vm.formFieldPb,
              attrs: {
                placeholder: _vm.$stringConstants("machineModelPlaceholder"),
                outlined: "",
                dense: "",
              },
              model: {
                value: _vm.machineDetails.model,
                callback: function ($$v) {
                  _vm.$set(_vm.machineDetails, "model", $$v)
                },
                expression: "machineDetails.model",
              },
            }),
            _vm.machineType == _vm.MachineType.Vehicle
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants(
                              "vehicleRegistrationNumberLabel"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(VTextField, {
                      class: _vm.formFieldPb,
                      attrs: {
                        placeholder: _vm.$stringConstants(
                          "machineRegistrationPlaceholder"
                        ),
                        outlined: "",
                        dense: "",
                      },
                      on: {
                        keydown: function ($event) {
                          return _vm.validateRegexRegistration($event)
                        },
                      },
                      model: {
                        value: _vm.machineDetails.registration,
                        callback: function ($$v) {
                          _vm.$set(_vm.machineDetails, "registration", $$v)
                        },
                        expression: "machineDetails.registration",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$stringConstants("vehicleOwnershipLabel")) +
                  " "
              ),
            ]),
            _c(VSelect, {
              class: _vm.formFieldPb,
              attrs: {
                items: _vm.enumOwnership,
                placeholder: _vm.$stringConstants("selectOwnershipPlaceholder"),
                "item-value": "value",
                "item-text": "title",
                outlined: "",
                dense: "",
                attach: "",
              },
              model: {
                value: _vm.machineDetails.ownership,
                callback: function ($$v) {
                  _vm.$set(_vm.machineDetails, "ownership", _vm._n($$v))
                },
                expression: "machineDetails.ownership",
              },
            }),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("vehicleWidthLabel")) + " "
              ),
            ]),
            _c(VTextField, {
              class: _vm.formFieldPb,
              staticStyle: { "max-width": "190px" },
              attrs: {
                type: "number",
                suffix: _vm.$constantValues("defaultVehicleLengthUnit"),
                min: 1,
                placeholder: _vm.$stringConstants("zeroQuantityPlaceholder"),
                outlined: "",
                dense: "",
              },
              on: {
                keydown: function ($event) {
                  return _vm.negativeKeydown($event)
                },
              },
              model: {
                value: _vm.machineDetails.width,
                callback: function ($$v) {
                  _vm.$set(_vm.machineDetails, "width", _vm._n($$v))
                },
                expression: "machineDetails.width",
              },
            }),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("vehicleLengthLabel")) + " "
              ),
            ]),
            _c(VTextField, {
              class: _vm.formFieldPb,
              staticStyle: { "max-width": "190px" },
              attrs: {
                type: "number",
                suffix: _vm.$constantValues("defaultVehicleLengthUnit"),
                min: 1,
                placeholder: _vm.$stringConstants("zeroQuantityPlaceholder"),
                outlined: "",
                dense: "",
              },
              on: {
                keydown: function ($event) {
                  return _vm.negativeKeydown($event)
                },
              },
              model: {
                value: _vm.machineDetails.length,
                callback: function ($$v) {
                  _vm.$set(_vm.machineDetails, "length", _vm._n($$v))
                },
                expression: "machineDetails.length",
              },
            }),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("vehicleWeightLabel")) + " "
              ),
            ]),
            _c(VTextField, {
              class: _vm.formFieldPb,
              staticStyle: { "max-width": "190px" },
              attrs: {
                type: "number",
                suffix: _vm.$constantValues("defaultVehicleWeightUnit"),
                min: 1,
                placeholder: _vm.$stringConstants("zeroQuantityPlaceholder"),
                outlined: "",
                dense: "",
              },
              on: {
                keydown: function ($event) {
                  return _vm.negativeKeydown($event)
                },
              },
              model: {
                value: _vm.machineDetails.weight,
                callback: function ($$v) {
                  _vm.$set(_vm.machineDetails, "weight", _vm._n($$v))
                },
                expression: "machineDetails.weight",
              },
            }),
          ],
          1
        ),
        _c(VContainer, { staticClass: "pl-0 pr-0 pb-16 pt-0" }, [
          _c(
            "div",
            { staticClass: "pl-8 pr-8 pt-4 pb-4" },
            [
              _c(
                "div",
                { staticClass: "py-2" },
                [
                  _vm.machineType == _vm.MachineType.Vehicle
                    ? _c("SubHeaderPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "vehicleSafetyChecklistTitle"
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm.machineType == _vm.MachineType.Implement
                    ? _c("SubHeaderPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "implementSafetyChecklistTitle"
                          ),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pt-1 pb-5" },
                [
                  _vm.machineType == _vm.MachineType.Vehicle
                    ? _c("DescriptionPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "vehicleSafetyChecklistDescription"
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm.machineType == _vm.MachineType.Implement
                    ? _c("DescriptionPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "implementSafetyChecklistDescription"
                          ),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.machineDetails.healthSafetyList != null &&
              _vm.machineDetails.healthSafetyList.length != 0
                ? _c(
                    "div",
                    { staticClass: "pt-0 mb-2" },
                    _vm._l(
                      _vm.machineDetails.healthSafetyList,
                      function (vehicleAddCheckListItem, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              VRow,
                              [
                                _c(
                                  VCol,
                                  {
                                    staticClass: "pt-0 pb-0 px-0",
                                    attrs: { cols: "11" },
                                  },
                                  [
                                    _c(
                                      VRow,
                                      [
                                        _c(
                                          VCol,
                                          {
                                            attrs: {
                                              cols: "12 pt-0 pb-0 px-0",
                                            },
                                          },
                                          [
                                            _c(VTextField, {
                                              attrs: {
                                                outlined: "",
                                                placeholder:
                                                  _vm.$stringConstants(
                                                    "checkListItemLabel"
                                                  ),
                                                dense: "",
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  vehicleAddCheckListItem.title,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    vehicleAddCheckListItem,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleAddCheckListItem.title",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { staticClass: "pa-0", attrs: { cols: "1" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt-2 pl-4 ib cursor-pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeVehicleCheckListItem(
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(VIcon, { attrs: { small: "" } }, [
                                          _vm._v("mdi-close"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm.showAddmachineCheckListItemBtn
                ? _c(
                    VRow,
                    { staticClass: "mb-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        VCol,
                        { staticClass: "pt-0 pl-0", attrs: { cols: "4" } },
                        [
                          _c("Button", {
                            attrs: {
                              variant: "secondary",
                              outlined: "",
                              outlineColor: "gray",
                              textValue: "Add",
                              leftIcon: "mdi-plus",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.showAddCheckListItem()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(VCol, {
                        staticClass: "pt-0",
                        attrs: { cols: "8" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showAddmachineCheckListItemFields
                ? _c(
                    "div",
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "pt-0 pb-0 mt-0 px-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(VTextarea, {
                                attrs: {
                                  placeholder: _vm.$stringConstants(
                                    "checkListItemEnterPromtLabel"
                                  ),
                                  dense: "",
                                  required: "",
                                  "auto-grow": "",
                                  outlined: "",
                                  rows: "1",
                                  "row-height": "15",
                                },
                                model: {
                                  value: _vm.checkListItemName,
                                  callback: function ($$v) {
                                    _vm.checkListItemName = $$v
                                  },
                                  expression: "checkListItemName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "px-0 pt-0 mt-0",
                              attrs: { cols: "9" },
                            },
                            [
                              _c("Button", {
                                attrs: {
                                  variant: "tertiary",
                                  color: "primary",
                                  textValue: "Add",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.addNewCheckListItem()
                                  },
                                },
                              }),
                              _vm.checkListItemName &&
                              _vm.checkListItemName.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "pl-4 ib cursor-pointer",
                                      on: { click: _vm.clearCurrentChecklist },
                                    },
                                    [_c("ButtonCloseIcon")],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(VCol, { attrs: { cols: "3" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.machineDetails.healthSafetyList.length ||
              _vm.machineAddCheckListItems.length > 0
                ? _c(
                    "div",
                    { staticClass: "chklist-brdr-container" },
                    [
                      _c(VCheckbox, {
                        staticClass: "chklist-brdr",
                        attrs: {
                          label:
                            _vm.machineType == _vm.MachineType.Vehicle
                              ? _vm.$stringConstants(
                                  "vehicleSafetyChecklistMandatoryText"
                                )
                              : _vm.$stringConstants(
                                  "implementSafetyChecklistMandatoryText"
                                ),
                        },
                        model: {
                          value: _vm.machineDetails.isHealthSafetyListMandatory,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.machineDetails,
                              "isHealthSafetyListMandatory",
                              $$v
                            )
                          },
                          expression:
                            "machineDetails.isHealthSafetyListMandatory",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "btns-bottom-box px-8 py-4 text-center" },
          [
            _c("Button", {
              attrs: {
                variant: "primary",
                color: "primary",
                disabled: _vm.editmachineLoader || _vm.isFieldsValid,
                loading: _vm.editmachineLoader,
                textValue:
                  _vm.machineDetails.id != null
                    ? _vm.$stringConstants("updateBtnText")
                    : _vm.machineType == _vm.MachineType.Vehicle
                    ? "Add Vehicle"
                    : "Add Implement",
                rightIcon: "mdi-arrow-right",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.updateVehicleData()
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }