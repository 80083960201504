var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "up-tl" }, [_vm._v(_vm._s(_vm.textValue1))]),
    _c("div", { staticClass: "pt-1", staticStyle: { "max-width": "240px" } }, [
      _vm._v(_vm._s(_vm.textValue2)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }