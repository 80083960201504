import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bulkActionResponse != null
    ? _c(
        VRow,
        { attrs: { justify: "center" } },
        [
          _c(
            VDialog,
            {
              attrs: { persistent: "", "max-width": "800" },
              model: {
                value: _vm.dialogBulkActionResponse,
                callback: function ($$v) {
                  _vm.dialogBulkActionResponse = $$v
                },
                expression: "dialogBulkActionResponse",
              },
            },
            [
              _c(
                VCard,
                { staticStyle: { "border-radius": "0" } },
                [
                  _vm.dialogBulkActionResponseTitle
                    ? _c(
                        VCardTitle,
                        { staticStyle: { "border-bottom": "1px solid #eee" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.dialogBulkActionResponseTitle) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    VCardText,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { staticClass: "pa-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    VRow,
                                    { staticClass: "fw-7 b-btm py-4 px-4" },
                                    [
                                      _c(VCol, { attrs: { cols: "4" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "blk-res-chip blk-res-chip-total",
                                          },
                                          [
                                            _c(
                                              "span",
                                              [
                                                _c(
                                                  VIcon,
                                                  {
                                                    staticClass: "pr-2",
                                                    staticStyle: {
                                                      color: "blue",
                                                    },
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-file-multiple")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                " Total: " +
                                                  _vm._s(
                                                    _vm.bulkActionResponse.total
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c(VCol, { attrs: { cols: "4" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "blk-res-chip blk-res-chip-success",
                                          },
                                          [
                                            _c(
                                              "span",
                                              [
                                                _c(
                                                  VIcon,
                                                  {
                                                    staticClass: "pr-2",
                                                    staticStyle: {
                                                      color: "green",
                                                    },
                                                    attrs: { small: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-checkbox-marked-circle"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                " Succeeded: " +
                                                  _vm._s(
                                                    _vm.bulkActionResponse
                                                      .succeeded
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c(VCol, { attrs: { cols: "4" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "blk-res-chip blk-res-chip-fail",
                                          },
                                          [
                                            _c(
                                              "span",
                                              [
                                                _c(
                                                  VIcon,
                                                  {
                                                    staticClass: "pr-2",
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-close-circle")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                " Failed: " +
                                                  _vm._s(
                                                    _vm.bulkActionResponse
                                                      .failed
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.bulkActionResponse.failed > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "hide-scroll",
                                      staticStyle: { height: "400px" },
                                    },
                                    _vm._l(
                                      _vm.bulkActionResponse.results,
                                      function (user, index) {
                                        return _c(
                                          "div",
                                          { key: index },
                                          [
                                            user.status != 0
                                              ? _c(
                                                  VRow,
                                                  {
                                                    staticClass: "px-4 exp-otr",
                                                  },
                                                  [
                                                    _c(
                                                      VCol,
                                                      { attrs: { cols: "1" } },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(index + 1) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      VCol,
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "im-er",
                                                            staticStyle: {
                                                              color: "red",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  user.reference
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      VCol,
                                                      { attrs: { cols: "2" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "im-er",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .enumImportStatus[
                                                                    user.status
                                                                  ]
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      VCol,
                                                      { attrs: { cols: "5" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "im-er-msg",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  user.message
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            VCol,
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                VCardActions,
                                { staticClass: "py-5" },
                                [
                                  _c(VSpacer),
                                  _c("Button", {
                                    staticClass: "f-nn ac-bt",
                                    attrs: {
                                      variant: "secondary",
                                      color: "primary",
                                      textValue: "Close",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.bulkActionCallback()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }