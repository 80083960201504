import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "px-9 pt-12", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  id: "invoice-settings",
                  textValue1:
                    _vm.ownerRole == _vm.UserRoleType[_vm.UserRoleType.Farmer]
                      ? _vm.$stringConstants("accountGeneralSettingsHeading")
                      : _vm.$stringConstants("accountCompanyPaymentHeading1"),
                  textValue2:
                    _vm.ownerRole == _vm.UserRoleType[_vm.UserRoleType.Farmer]
                      ? _vm.$stringConstants("accountGeneralSettingsSubHeading")
                      : _vm.$stringConstants("accountCompanyPaymentHeading2"),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "7" } },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("currencyTitle")) +
                            " * "
                        ),
                      ]),
                      _c(VAutocomplete, {
                        staticClass: "pb-1",
                        attrs: {
                          items: _vm.globalCurrenciesList,
                          "item-text": "name",
                          placeholder: _vm.$stringConstants(
                            "currencyPlaceholderText"
                          ),
                          outlined: "",
                          dense: "",
                          flat: "",
                          attach: "",
                          "return-object": "",
                          solo: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.name) +
                                      " (" +
                                      _vm._s(data.item.symbol) +
                                      ") "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c("div", { staticClass: "fn-sm fw-5" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.name) +
                                      " (" +
                                      _vm._s(item.symbol) +
                                      ") "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.accountBusinessProfile.config.currency,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.accountBusinessProfile.config,
                              "currency",
                              $$v
                            )
                          },
                          expression: "accountBusinessProfile.config.currency",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("timeZoneTitle")) +
                            " * "
                        ),
                      ]),
                      _c(VAutocomplete, {
                        staticClass: "pb-1",
                        attrs: {
                          items: _vm.globalTimeZonesList,
                          "item-text": "name",
                          placeholder: _vm.$stringConstants(
                            "timeZonePlaceholderText"
                          ),
                          outlined: "",
                          dense: "",
                          flat: "",
                          attach: "",
                          "return-object": "",
                          solo: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(" " + _vm._s(data.item.name) + " "),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c("div", { staticClass: "fn-sm fw-5" }, [
                                  _vm._v(" " + _vm._s(item.name) + " "),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.accountBusinessProfile.config.timezone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.accountBusinessProfile.config,
                              "timezone",
                              $$v
                            )
                          },
                          expression: "accountBusinessProfile.config.timezone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.ownerRole == _vm.UserRoleType[_vm.UserRoleType.Contractor]
                ? _c(
                    VRow,
                    { attrs: { "no-gutters": "", cols: "12" } },
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("div", { staticClass: "field-input-label" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants("invoiceTermsHeading")
                                ) +
                                " "
                            ),
                          ]),
                          _c(VTextField, {
                            staticClass: "pb-1",
                            attrs: {
                              outlined: "",
                              dense: "",
                              type: "number",
                              required: "",
                            },
                            on: {
                              keydown: function ($event) {
                                return _vm.checkTermsNumber($event)
                              },
                            },
                            model: {
                              value:
                                _vm.accountBusinessProfile.config
                                  .invoiceTermsInDays,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.accountBusinessProfile.config,
                                  "invoiceTermsInDays",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "accountBusinessProfile.config.invoiceTermsInDays",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("div", { staticClass: "field-input-label" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants("paymentInstruction")
                                ) +
                                " "
                            ),
                          ]),
                          _c(VTextarea, {
                            class: _vm.formFieldPb,
                            attrs: {
                              required: "",
                              "auto-grow": "",
                              outlined: "",
                              rows: "4",
                              "row-height": "30",
                              dense: "",
                            },
                            model: {
                              value:
                                _vm.accountBusinessProfile.config
                                  .invoiceInstructions,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.accountBusinessProfile.config,
                                  "invoiceInstructions",
                                  $$v
                                )
                              },
                              expression:
                                "accountBusinessProfile.config.invoiceInstructions",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "pt-0 px-0 pb-4",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("span", { staticClass: "min-hd" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("taxDetailsTitle")) +
                                " "
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                opacity: "0.8",
                              },
                            },
                            [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.$stringConstants("taxUsedOnText")
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "py-0 pl-0 pr-2",
                              attrs: { cols: "6" },
                            },
                            [
                              _c("div", { staticClass: "field-input-label" }, [
                                _vm._v(
                                  _vm._s(_vm.$stringConstants("taxeTypeText"))
                                ),
                              ]),
                              _c(VCombobox, {
                                attrs: {
                                  items: _vm.globalTaxesNameList,
                                  "search-input": _vm.searchTaxType,
                                  id: "taxTypeNameId",
                                  placeholder: _vm.$stringConstants(
                                    "createNewTaxPlaceholder"
                                  ),
                                  attach: "",
                                  outlined: "",
                                  dense: "",
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.searchTaxType = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.searchTaxType = $event
                                  },
                                  change: _vm.resetTaxRegistration,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "no-data",
                                      fn: function () {
                                        return [
                                          _c(VListItem, [
                                            _c(
                                              "span",
                                              { staticClass: "subheading" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$stringConstants(
                                                      "createNewTaxText"
                                                    )
                                                  ) +
                                                    ": " +
                                                    _vm._s(_vm.searchTaxType) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1776911737
                                ),
                                model: {
                                  value:
                                    _vm.accountBusinessProfile.config
                                      .salesTaxName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.accountBusinessProfile.config,
                                      "salesTaxName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "accountBusinessProfile.config.salesTaxName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            {
                              staticClass: "py-0 pl-2 pr-0",
                              attrs: { cols: "6" },
                            },
                            [
                              _c("div", { staticClass: "field-input-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.accountBusinessProfile.config
                                      .salesTaxName
                                      ? _vm.accountBusinessProfile.config
                                          .salesTaxName +
                                          " " +
                                          _vm.$stringConstants("RegNumText")
                                      : _vm.$stringConstants("RegNumTextCaps")
                                  ) + " "
                                ),
                              ]),
                              _c(VTextField, {
                                class: _vm.formFieldPb,
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  disabled:
                                    !_vm.accountBusinessProfile.config
                                      .salesTaxName,
                                },
                                model: {
                                  value:
                                    _vm.accountBusinessProfile.config
                                      .salesTaxRegistrationNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.accountBusinessProfile.config,
                                      "salesTaxRegistrationNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "accountBusinessProfile.config.salesTaxRegistrationNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.accountBusinessProfile.config.salesTaxName
                        ? _c(
                            VCol,
                            {
                              staticClass: "pt-0 px-0 pb-4 min-hd",
                              attrs: { cols: "12" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$stringConstants("taxRateTitle")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.accountBusinessProfile.config.salesTaxName
                        ? _c(
                            VCol,
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0 pl-0 pr-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "field-input-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "rateNameTitle"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0 pl-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "field-input-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "ratePercentageTitle"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.accountBusinessProfile.config.salesTaxRates,
                                function (tRate, idx) {
                                  return _c(
                                    "div",
                                    { key: idx },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticClass:
                                            "position-relative rate-list",
                                        },
                                        [
                                          _c(
                                            VCol,
                                            {
                                              staticClass: "py-0 pl-0 pr-2",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  required: "",
                                                },
                                                model: {
                                                  value: tRate.rateName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      tRate,
                                                      "rateName",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "tRate.rateName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VCol,
                                            {
                                              staticClass: "py-0 pl-2",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  type: "number",
                                                  required: "",
                                                },
                                                model: {
                                                  value: tRate.rateInPercentage,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      tRate,
                                                      "rateInPercentage",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "tRate.rateInPercentage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "rm_tax_btn ib cursor-pointer",
                                              attrs: {
                                                title: "Remove tax rate",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeSelectedTaxRate(
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass:
                                                    "job-add-list-rm",
                                                },
                                                [_vm._v("mdi-close")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                VRow,
                                { staticClass: "pt-2" },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0 pl-0 pr-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          placeholder: _vm.$stringConstants(
                                            "rateNamePlaceholder"
                                          ),
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.tempRateName,
                                          callback: function ($$v) {
                                            _vm.tempRateName = $$v
                                          },
                                          expression: "tempRateName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0 pl-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          placeholder: _vm.$stringConstants(
                                            "ratePercentagePlaceholder"
                                          ),
                                          type: "number",
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.tempRatePercentage,
                                          callback: function ($$v) {
                                            _vm.tempRatePercentage = _vm._n($$v)
                                          },
                                          expression: "tempRatePercentage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("Button", {
                                    attrs: {
                                      variant: "secondary",
                                      outlined: "",
                                      outlineColor: "gray",
                                      color: "primary",
                                      disabled: _vm.isValidTaxRate,
                                      textValue: "Save",
                                      leftIcon: "mdi-plus",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.appendTaxRate($event)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }