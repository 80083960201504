import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getComputedProperties } from "@/utils/helpers/computed-generator";
var computedProperties = {
  'usersModule': ['userInviteeAcceptanceCode', 'invitationType', 'usersLoader'],
  'subscriptionsModule': ['subscriptionPlanLoader'],
  'root': ['loginLoader']
};
export default {
  name: 'LoginComputedMixin',
  computed: _objectSpread({}, getComputedProperties(computedProperties))
};