import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var FeatureType;
(function (FeatureType) {
  FeatureType[FeatureType["Operations"] = 1] = "Operations";
  FeatureType[FeatureType["Fields"] = 2] = "Fields";
  FeatureType[FeatureType["Vehicles"] = 3] = "Vehicles";
  FeatureType[FeatureType["Implements"] = 4] = "Implements";
  FeatureType[FeatureType["Products"] = 5] = "Products";
  FeatureType[FeatureType["CustomersContractors"] = 6] = "CustomersContractors";
  FeatureType[FeatureType["TeamMembers"] = 7] = "TeamMembers";
  FeatureType[FeatureType["AllJobs"] = 8] = "AllJobs";
  FeatureType[FeatureType["OperatorOnJobs"] = 9] = "OperatorOnJobs";
  FeatureType[FeatureType["TeamLeadOnJobs"] = 10] = "TeamLeadOnJobs";
  FeatureType[FeatureType["ApproveJobsForInvoicing"] = 11] = "ApproveJobsForInvoicing";
})(FeatureType || (FeatureType = {}));
export var FeatureTypeLabels = new Map([[FeatureType.Operations, 'Operations'], [FeatureType.Fields, 'Fields'], [FeatureType.Vehicles, 'Vehicles'], [FeatureType.Implements, 'Implements'], [FeatureType.Products, 'Products'], [FeatureType.CustomersContractors, 'Customers/Contractors'], [FeatureType.TeamMembers, 'Team members'], [FeatureType.AllJobs, 'All Jobs'], [FeatureType.TeamLeadOnJobs, 'Team lead on jobs'], [FeatureType.OperatorOnJobs, 'Operator on jobs'], [FeatureType.ApproveJobsForInvoicing, 'Approve jobs for invoicing']]);