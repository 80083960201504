import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VBtn,
    {
      staticClass: "f-nn btn-action-primary",
      class: { disabled: _vm.disabled },
      attrs: {
        border: "0",
        color: "primary",
        tile: "",
        elevation: "0",
        width: _vm.width ? _vm.width : "",
        height: _vm.height ? _vm.height : 48,
        disabled: _vm.disabled,
        loading: _vm.loading,
      },
    },
    [_vm._v(" " + _vm._s(_vm.textValue) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }