import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import ConstantValues from '@/plugins/constantValues';
export default {
  computed: {
    permissionsList: function permissionsList() {
      return this.$store.getters.permissionsList;
    },
    componentsPermissions: function componentsPermissions() {
      return this.$store.getters.componentsPermissions;
    }
  },
  methods: {
    shouldComponentDisplay: function shouldComponentDisplay(componentPermissionName) {
      if (this.permissionsList.includes(ConstantValues.accessAllPermission)) {
        return true;
      }
      if (this.permissionsList.includes(componentPermissionName)) return true;else return false;
    }
  }
};