import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import SubscriptionPlanCard from "@/components/subscriptions/subscription-plan-card.vue";
import { mapMutations } from "vuex";
import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
export default {
  data: function data() {
    return {
      dialogDeleteCard: false,
      cardIdToDelete: null
    };
  },
  components: {
    SubscriptionPlanCard: SubscriptionPlanCard,
    Button: Button
  },
  mixins: [subscriptionsComputedMixin],
  mounted: function mounted() {
    this.setClearCardsList();
    this.$store.dispatch("getCardsList");
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    setClearCardsList: "setClearCardsList"
  })), {}, {
    getMembersCount: function getMembersCount(maxUnits) {
      return maxUnits ? "".concat(maxUnits - 9, "-").concat(maxUnits, " members") : "30+ members";
    },
    getPlanTypeColor: function getPlanTypeColor(planTypeColor) {
      if (planTypeColor != undefined && planTypeColor != null) {
        switch (planTypeColor) {
          case 0:
            return "#ef4545";
          case 1:
            return "#5645ef";
          case 2:
            return "#eba230";
          case 3:
            return "#4592ef";
        }
      }
    },
    routeToPricing: function routeToPricing() {
      window.open("https://ag-drive.com/pricing", "_blank");
    }
  })
};