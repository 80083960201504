//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuDashboardCurrentStatisticsCounter from './su-overview-current-statistics-counter.vue';
import suOverviewComputedMixin from './su-overview-computed';
import StatsLoader from './su-overview-stats-loader.vue';
export default {
  mixins: [suOverviewComputedMixin],
  components: {
    SuDashboardCurrentStatisticsCounter: SuDashboardCurrentStatisticsCounter,
    StatsLoader: StatsLoader
  },
  mounted: function mounted() {
    this.$store.dispatch('getOverallStatistics');
  }
};