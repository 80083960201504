import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "my-8" }, [
      _c("div", { staticClass: "pb-10" }, [
        _c("div", { staticClass: "tj-cp-title" }, [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.$stringConstants("teamJobTimeRecordsTitle")) +
                " "
            ),
          ]),
          _c(
            "span",
            { staticClass: "billing-info-tooltip" },
            [
              _c(
                VTooltip,
                {
                  attrs: { bottom: "", color: "#000", "max-width": "300px" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            VIcon,
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "cursor-pointer info-icon-pos",
                                  attrs: {
                                    small: "",
                                    color: "var(--v-primary-base)",
                                  },
                                },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v("mdi-information")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", { staticClass: "tol-cont" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$stringConstants("teamJobBillingInformation")
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "tj-cp-subtitle" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("teamJobTimeRecordsSubTitle")) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
        },
        [
          _c(
            "div",
            {
              staticClass: "jb-operators-list-head",
              class: {
                "jb-operators-list-head-minimal": _vm.isTimeRecordsSelectable,
              },
            },
            [
              _vm.isTimeRecordsSelectable
                ? _c(
                    "div",
                    { staticClass: "ib width-perc-4 jb-select-all-operators" },
                    [
                      _c(VCheckbox, {
                        staticClass: "mt-0 mb-0",
                        staticStyle: { transform: "translateY(3px)" },
                        attrs: { disabled: false },
                        on: {
                          change: _vm.toggleSelectedTimeRecordsTotalWorkHours,
                        },
                        model: {
                          value: _vm.isAllTimeRecordsSelected,
                          callback: function ($$v) {
                            _vm.isAllTimeRecordsSelected = $$v
                          },
                          expression: "isAllTimeRecordsSelected",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "ib width-perc-20" }, [
                _vm._v(_vm._s(_vm.$stringConstants("startDateAndTime"))),
              ]),
              _c("div", { staticClass: "ib width-perc-20" }, [
                _vm._v(_vm._s(_vm.$stringConstants("endDateAndTime"))),
              ]),
              _c("div", { staticClass: "ib width-perc-35" }, [
                _c("div", { staticClass: "ib ib width-perc-33" }, [
                  _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.$stringConstants("travelTime")) + " "
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "billing-info-tooltip" },
                    [
                      _c(
                        VTooltip,
                        {
                          attrs: {
                            bottom: "",
                            color: "#000",
                            "max-width": "300px",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    VIcon,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass:
                                            "cursor-pointer info-icon-pos info-icon-pos-sm",
                                          attrs: {
                                            small: "",
                                            color: "var(--v-primary-base)",
                                          },
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("mdi-information")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", { staticClass: "tol-cont" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants(
                                    "teamJobTimeRecordsTravelTime"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "ib ib width-perc-33" }, [
                  _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.$stringConstants("breakTime")) + " "
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "billing-info-tooltip" },
                    [
                      _c(
                        VTooltip,
                        {
                          attrs: {
                            bottom: "",
                            color: "#000",
                            "max-width": "300px",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    VIcon,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass:
                                            "cursor-pointer info-icon-pos info-icon-pos-sm",
                                          attrs: {
                                            small: "",
                                            color: "var(--v-primary-base)",
                                          },
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("mdi-information")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", { staticClass: "tol-cont" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants(
                                    "teamJobTimeRecordsBreakTime"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "ib ib width-perc-33" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("downTime"))),
                ]),
              ]),
              _c("div", { staticClass: "ib width-perc-10 text-center" }, [
                _vm._v(_vm._s(_vm.$stringConstants("workHours"))),
              ]),
              _c("div", { staticClass: "ib width-perc-10 text-center" }, [
                _vm._v(_vm._s(_vm.$stringConstants("totalTime"))),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
          attrs: { id: "jb-add-operators-table" },
        },
        [
          _vm._l(_vm.teamJobTimeDetailsList, function (timeRecords, idx) {
            return _c(
              "div",
              {
                key: idx,
                staticClass: "jb-add-operator-cont",
                class: _vm.teamJobDetails.isApprovedForInvoicing
                  ? "unselectable"
                  : "",
              },
              [
                _vm.isTimeRecordsSelectable
                  ? _c("div", { staticClass: "ib width-perc-4 pt-4 pb-4" }, [
                      _c(
                        "div",
                        { staticClass: "ib width-perc-5 pr-2" },
                        [
                          _c(VCheckbox, {
                            style: {
                              transform: "translateY(3px)",
                            },
                            attrs: { value: idx },
                            on: {
                              change:
                                _vm.updateSelectedTimeRecordsTotalWorkHours,
                            },
                            model: {
                              value: _vm.teamJobSelectedTimeRecords,
                              callback: function ($$v) {
                                _vm.teamJobSelectedTimeRecords = $$v
                              },
                              expression: "teamJobSelectedTimeRecords",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "ib width-perc-20 pt-4 pb-4" }, [
                  _c(
                    "div",
                    { staticClass: "width-perc-85" },
                    [
                      _c("VueCtkDateTimePicker", {
                        class: { sdDate: _vm.isDetailsView },
                        attrs: {
                          color: "#1d34ad",
                          formatted: "DD-MM-YYYY HH:mm",
                          format: "YYYY-MM-DD HH:mm",
                          "no-label": "",
                          noClearButton: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChangeStartEndTime(idx)
                          },
                        },
                        model: {
                          value: timeRecords.startTime,
                          callback: function ($$v) {
                            _vm.$set(timeRecords, "startTime", $$v)
                          },
                          expression: "timeRecords.startTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-20" }, [
                  _c(
                    "div",
                    { staticClass: "width-perc-85" },
                    [
                      _c("VueCtkDateTimePicker", {
                        class: { sdDate: _vm.isDetailsView },
                        attrs: {
                          color: "#1d34ad",
                          formatted: "DD-MM-YYYY HH:mm",
                          format: "YYYY-MM-DD HH:mm",
                          "no-label": "",
                          noClearButton: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChangeStartEndTime(idx)
                          },
                        },
                        model: {
                          value: timeRecords.endTime,
                          callback: function ($$v) {
                            _vm.$set(timeRecords, "endTime", $$v)
                          },
                          expression: "timeRecords.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-35" }, [
                  _c(
                    "div",
                    { staticClass: "ib width-perc-33 pt-4 pb-4" },
                    [
                      _c("vue-timepicker", {
                        attrs: {
                          format: "HH:mm",
                          "hide-clear-button": "",
                          "hour-label": "Hours",
                          "minute-label": "Minutes",
                        },
                        on: {
                          open: function ($event) {
                            return _vm.onChangeTimeRecords()
                          },
                        },
                        model: {
                          value: timeRecords.travelTimeFormatted,
                          callback: function ($$v) {
                            _vm.$set(timeRecords, "travelTimeFormatted", $$v)
                          },
                          expression: "timeRecords.travelTimeFormatted",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "ib width-perc-33 pt-4 pb-4" },
                    [
                      _c("vue-timepicker", {
                        attrs: {
                          format: "HH:mm",
                          "hide-clear-button": "",
                          "hour-label": "Hours",
                          "minute-label": "Minutes",
                        },
                        on: {
                          open: function ($event) {
                            return _vm.onChangeTimeRecords()
                          },
                          change: function ($event) {
                            return _vm.handleWorkTimingsManualUpdate(idx)
                          },
                        },
                        model: {
                          value: timeRecords.breakTimeFormatted,
                          callback: function ($$v) {
                            _vm.$set(timeRecords, "breakTimeFormatted", $$v)
                          },
                          expression: "timeRecords.breakTimeFormatted",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "ib width-perc-33 pt-4 pb-4" },
                    [
                      _c("vue-timepicker", {
                        attrs: {
                          format: "HH:mm",
                          "hide-clear-button": "",
                          "hour-label": "Hours",
                          "minute-label": "Minutes",
                        },
                        on: {
                          open: function ($event) {
                            return _vm.onChangeTimeRecords()
                          },
                          change: function ($event) {
                            return _vm.handleWorkTimingsManualUpdate(idx)
                          },
                        },
                        model: {
                          value: timeRecords.downTimeFormatted,
                          callback: function ($$v) {
                            _vm.$set(timeRecords, "downTimeFormatted", $$v)
                          },
                          expression: "timeRecords.downTimeFormatted",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-10 text-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.getTimeInHoursAndMins(timeRecords.workTimeFormatted)
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-10 text-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.getTimeInHoursAndMins(
                          timeRecords.totalTimeFormatted
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            )
          }),
          _vm.isTimeRecordsSelectable && _vm.isAnyTimeRecordSelected
            ? _c(
                "div",
                {
                  staticClass:
                    "field-input-label jb-add-operator-cont pt-4 pb-4 billable-hours-bg",
                },
                [
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$stringConstants("teamJobTotalBillableTime")
                        ) +
                        ": "
                    ),
                    _vm.teamJobOperation.billingUnit === "hour"
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (
                                  _vm.teamJobSelectedTimeRecordsHours / 3600
                                ).toFixed(2)
                              ) +
                              " hours "
                          ),
                        ])
                      : _vm._e(),
                    _vm.teamJobOperation.billingUnit === "minute"
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                Math.ceil(
                                  _vm.teamJobSelectedTimeRecordsHours / 60
                                ).toFixed(0)
                              ) +
                              " minutes "
                          ),
                        ])
                      : _vm._e(),
                    _c("span", [
                      _vm._v(
                        " (" +
                          _vm._s(
                            _vm.getTimeInHoursAndMins(
                              _vm.teamJobSelectedTimeRecordsHours
                            )
                          ) +
                          " hrs) "
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.isTimeRecordsSelectable && _vm.isAnyTimeRecordSelected
            ? _c(
                "div",
                {
                  staticClass:
                    "field-input-label jb-add-operator-cont pt-4 pb-4 billable-hours-add-bg",
                },
                [
                  _c("Button", {
                    style: {
                      border:
                        !_vm.teamJobSelectedTimeRecordsHours ||
                        _vm.teamJobSelectedTimeRecordsHours == 0
                          ? "1px solid #d3d3d3 !important"
                          : "unset",
                    },
                    attrs: {
                      textValue: "Use as billable quantity",
                      disabled:
                        !_vm.teamJobSelectedTimeRecordsHours ||
                        _vm.teamJobSelectedTimeRecordsHours == 0,
                      color: "primary",
                      variant: "secondary",
                      outlined: "",
                      outlineColor: "primary",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.setWorkHoursAsBillableQuantity($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.teamJobsLoader,
              expression: "teamJobsLoader",
            },
          ],
        },
        _vm._l(2, function (loadInfo, j) {
          return _c(
            "div",
            {
              key: j,
              staticClass: "px-7 py-3",
              staticStyle: { border: "0.5px solid #eee" },
            },
            [
              _c("div", [
                _c("div", { staticClass: "ib width-perc-22" }, [
                  _c(
                    "div",
                    { staticClass: "width-perc-85" },
                    [
                      _c(VSkeletonLoader, {
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-22" }, [
                  _c(
                    "div",
                    { staticClass: "width-perc-85" },
                    [
                      _c(VSkeletonLoader, {
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-5" }),
                _c("div", { staticClass: "ib width-perc-35" }, [
                  _c(
                    "div",
                    { staticClass: "ib ib width-perc-33" },
                    [
                      _c(VSkeletonLoader, {
                        staticClass: "pr-4",
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "ib ib width-perc-33" },
                    [
                      _c(VSkeletonLoader, {
                        staticClass: "pr-4",
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "ib ib width-perc-33" },
                    [
                      _c(VSkeletonLoader, {
                        staticClass: "pr-4",
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "ib width-perc-16 text-center" },
                  [
                    _c(VSkeletonLoader, {
                      staticClass: "px-4",
                      attrs: { height: "30", type: "card" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }