import $ from 'jquery';
import MachinesListView from '../machines/machines-list.vue';
import MachinesEditForm from '../machines/machines-edit-form.vue';
import { closeOverlay } from '@/utils/helpers';
import permissionsMixin from '@/mixins/permissions-mixin';
import { routeImplements, routeVehicles } from "../../utils/endpoints";
import { MachineType } from "@/enum/machineType";
import requests from '@/utils/requests';
import MachinesImportForm from '@/components/machines/machines-import.vue';
export default {
  mixins: [permissionsMixin],
  components: {
    MachinesListView: MachinesListView,
    MachinesEditForm: MachinesEditForm,
    MachinesImportForm: MachinesImportForm
  },
  mounted: function mounted() {
    $('body').on('click', '#overlay', function () {
      closeOverlay("editMachineFormOverlay");
      closeOverlay("importMachinesFormOverlay");
    });
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    requests.initializeAbortController(next);
    next(function (that) {
      if (to.path == routeImplements) {
        that.$store.state.machineType = MachineType.Implement;
      } else if (to.path == routeVehicles) {
        that.$store.state.machineType = MachineType.Vehicle;
      }
    });
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (to.path == routeImplements || to.path == routeVehicles) this.$store.state.machineType = MachineType.None;
    requests.initializeCancelRequests(next);
  }
};