import "core-js/modules/es.array.concat.js";
import { closeOverlay } from '@/utils/helpers';
import moment from 'moment';
import ComponentLoader from '@/components/shared/loaders/component-loader-mini.vue';
import { routeTeamJobsDetails } from '@/utils/endpoints';
import invoicesComputedMixin from '@/components/invoices/invoices-computed';
import { getAssignedUserNameFromJob } from '@/utils/helpers/jobs-helpers';
import { BaseButton as Button } from '@/components/shared/buttons/index';
export default {
  name: 'JobsPreviewFlyout',
  mixins: [invoicesComputedMixin],
  components: {
    ComponentLoader: ComponentLoader,
    Button: Button
  },
  methods: {
    closeJobsPreviewForm: function closeJobsPreviewForm() {
      closeOverlay('invoiceJobsPreviewFlyout');
    },
    getAssignedOperator: function getAssignedOperator(job) {
      return getAssignedUserNameFromJob(job);
    },
    getLocalDateFormat: function getLocalDateFormat(date) {
      var formattedDate = moment.utc(date).local().format('DD MMM YYYY');
      return formattedDate;
    },
    routeToJobDetails: function routeToJobDetails(jobId) {
      window.open("".concat(routeTeamJobsDetails).concat(jobId), '_blank');
    }
  }
};