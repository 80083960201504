export default {
  name: 'BaseFlyout',
  props: ['value', 'noOverlay', 'persistent', 'flyoutClass', 'width'],
  methods: {
    handleOutsideClick: function handleOutsideClick(event) {
      if (this.persistent) return;
      var targetElement = event.target;
      if (!(targetElement === null || targetElement === void 0 ? void 0 : targetElement.classList.contains('flyout-overlay'))) return;
      if (this.$listeners['confirm-dismiss']) {
        this.$emit('confirm-dismiss');
      } else {
        this.$emit('input', false);
      }
    }
  }
};