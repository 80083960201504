import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { display: "flex", overflow: "auto" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.teamJobsLoader,
            expression: "!teamJobsLoader",
          },
        ],
        staticStyle: { display: "flex" },
      },
      [
        _c(
          "div",
          { staticClass: "ib add_job_attach pr-4 position-relative" },
          [
            _c(VFileInput, {
              staticClass: "file_input job-attach",
              attrs: {
                accept:
                  "image/png, image/jpeg, image/bmp, image/jpg, image/gif, image/webp, application/pdf",
                "prepend-icon": "mdi-file-upload",
                disabled:
                  _vm.teamJobsLoader ||
                  !_vm.teamJobViewPreference.instructionPhotos.isEditable,
                multiple: "",
              },
              on: { change: _vm.previewImage },
              model: {
                value: _vm.selectedImages,
                callback: function ($$v) {
                  _vm.selectedImages = $$v
                },
                expression: "selectedImages",
              },
            }),
          ],
          1
        ),
        _vm._l(_vm.teamJobAttachments, function (img, imgIdx) {
          return _c("div", { key: imgIdx, staticClass: "ib add_job_attach " }, [
            img.purpose == _vm.JobAttachmentPurposeType.Creation
              ? _c(
                  "div",
                  {
                    staticClass: "mr-4",
                    staticStyle: { width: "100px", position: "relative" },
                  },
                  [
                    img.type == _vm.JobAttachmentType.Pdf
                      ? _c("TeamJobsAttachmentsPdf", {
                          attrs: { "file-url": img.url },
                        })
                      : _c(VImg, {
                          staticClass: "cursor-pointer ",
                          attrs: {
                            "lazy-src": _vm.base64ImagePlaceholder,
                            src: img.url,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showImage(img.url)
                            },
                          },
                        }),
                    _c("div", { staticClass: "jb-img-cls-btn" }, [
                      _c(
                        "div",
                        {
                          staticClass: "jb-img-cls-btn-crs",
                          on: {
                            click: function ($event) {
                              return _vm.removeSelectedImage(img)
                            },
                          },
                        },
                        [_vm._v(" - ")]
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.teamJobsLoader,
            expression: "teamJobsLoader",
          },
        ],
      },
      [
        _c(VSkeletonLoader, {
          staticClass: "mb-4",
          attrs: { width: "100", height: "100", type: "card" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }