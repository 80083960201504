import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { UserRoleType } from "@/enum/userRoleType";
import ConstantValues from "@/plugins/constantValues";
export var getOwnerId = function getOwnerId() {
  return localStorage.getItem(ConstantValues.ownerId);
};
export var getLoggedInUserRole = function getLoggedInUserRole() {
  function isContractorLoggedInCheck() {
    return localStorage.getItem('userRole') == UserRoleType.Contractor.toString();
  }
  function isFarmerLoggedInCheck() {
    return localStorage.getItem('userRole') == UserRoleType.Farmer.toString();
  }
  function isManagerLoggedInCheck() {
    return localStorage.getItem('userRole') == UserRoleType.Manager.toString();
  }
  function isContractorsManagerLoggedInCheck() {
    return localStorage.getItem('ownerRole') == 'Contractor' && localStorage.getItem('userRole') == UserRoleType.Manager.toString();
  }
  function isFarmersManagerLoggedInCheck() {
    return localStorage.getItem('ownerRole') == 'Farmer' && localStorage.getItem('userRole') == UserRoleType.Manager.toString();
  }
  function isOwnerLoggedInFarmerCheck() {
    return localStorage.getItem('ownerRole') == 'Farmer';
  }
  function isOwnerLoggedInContractorCheck() {
    return localStorage.getItem('ownerRole') == 'Contractor';
  }
  function isUserSuperAdminCheck() {
    return localStorage.getItem('isSuperAdmin') == '1';
  }
  var loggedInRoles = {
    isContractorLoggedIn: isContractorLoggedInCheck(),
    isFarmerLoggedIn: isFarmerLoggedInCheck(),
    isManagerLoggedIn: isManagerLoggedInCheck(),
    isContractorsManagerLoggedIn: isContractorsManagerLoggedInCheck(),
    isFarmersManagerLoggedIn: isFarmersManagerLoggedInCheck(),
    isUserOwnerFarmerLoggedIn: isOwnerLoggedInFarmerCheck(),
    isUserOwnerContractorLoggedIn: isOwnerLoggedInContractorCheck(),
    isUserSuperAdminLoggedIn: isUserSuperAdminCheck()
  };
  return loggedInRoles;
};