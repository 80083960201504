import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import { closeOverlay } from "@/utils/helpers";
import ImportData from "@/components/shared/import/import-csv-list.vue";
import { mapMutations } from "vuex";
import farmersComputedMixin from "@/components/farmers/farmers-computed";
import { ImportResourceType } from "@/enum/importResourceType";
var customerInfo = {
  nameField: JSON.parse('"name*"'),
  emailField: JSON.parse('"email_address"'),
  primaryContactNumberField: JSON.parse('"primary_contact_number"'),
  secondaryNameField: JSON.parse('"secondary_name"'),
  secondaryContactNumberField: JSON.parse('"secondary_contact_number"'),
  businessNameField: JSON.parse('"business_name*"'),
  businessJobCodeField: JSON.parse('"business_job_code"'),
  businessAddressLine1: JSON.parse('"business_address_line1*"'),
  businessAddressLine2: JSON.parse('"business_address_line2"'),
  businessAddressCity: JSON.parse('"business_address_city*"'),
  businessAddressStateOrCounty: JSON.parse('"business_address_state_or_county*"'),
  businessAddressCountry: JSON.parse('"business_address_country"'),
  businessAddressPostalCode: JSON.parse('"business_address_postal_code*"'),
  businessRegistrationNumber: JSON.parse('"business_registration_number"'),
  businessTaxNumber: JSON.parse('"business_tax_number"')
};
var customerObj = {};
customerObj[customerInfo.nameField] = '';
customerObj[customerInfo.emailField] = '';
customerObj[customerInfo.primaryContactNumberField] = '';
customerObj[customerInfo.secondaryNameField] = '';
customerObj[customerInfo.secondaryContactNumberField] = '';
customerObj[customerInfo.businessNameField] = '';
customerObj[customerInfo.businessJobCodeField] = '';
customerObj[customerInfo.businessAddressLine1] = '';
customerObj[customerInfo.businessAddressLine2] = '';
customerObj[customerInfo.businessAddressCity] = '';
customerObj[customerInfo.businessAddressStateOrCounty] = '';
customerObj[customerInfo.businessAddressCountry] = '';
customerObj[customerInfo.businessAddressPostalCode] = '';
customerObj[customerInfo.businessRegistrationNumber] = '';
customerObj[customerInfo.businessTaxNumber] = '';
export default {
  name: "FarmerImportForm",
  mixins: [farmersComputedMixin],
  components: {
    ImportData: ImportData
  },
  data: function data() {
    return {
      customerSampleData: [customerObj],
      requiredImportFields: ["name", "business_name", "business_address_line1", "business_address_city", "business_address_state_or_county", "business_address_postal_code"],
      uploadNotes: ['Do not delete any column from the sample file. If you do not wish to provide information in an optional column, leave the data blank.', 'Avoid using comma in any of the row data. This might corrupt the file.', 'Required columns: name, business_name, business_address_line1, business_address_city, business_address_state_or_county, and business_address_postal_code.'],
      importResourceType: ImportResourceType.Customers
    };
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFarmerFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    closeImportFarmerForm: function closeImportFarmerForm() {
      closeOverlay("importCustomerFormOverlay");
    },
    refreshFarmersList: function refreshFarmersList() {
      this.getFarmersListView();
    }
  })
};