import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VSnackbar, {
    class: "f-ns notification-snackbar " + _vm.type,
    attrs: {
      top: "",
      right: "",
      transition: "slide-x-reverse-transition",
      timeout: _vm.hasActionListener ? -1 : 5000,
      elevation: "24",
      outlined: "",
    },
    scopedSlots: _vm._u([
      {
        key: "action",
        fn: function (ref) {
          var attrs = ref.attrs
          return [
            _c(
              VRow,
              { attrs: { justify: "space-between" } },
              [
                _c(
                  VCol,
                  { staticClass: "close-icon-wrapper", attrs: { cols: "6" } },
                  [
                    _c(
                      VIcon,
                      _vm._b(
                        {
                          staticClass: "close-icon mx-4 my-2",
                          on: { click: _vm.dismissNotification },
                        },
                        "v-icon",
                        attrs,
                        false
                      ),
                      [_vm._v(" mdi-close ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "notification-text" },
              [
                _vm.notificationTitle
                  ? _c("h4", { staticClass: "notification-title fw-7" }, [
                      _vm._v(_vm._s(_vm.notificationTitle)),
                    ])
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.notificationMessage) + " "),
                _vm.hasActionListener
                  ? _c(
                      VCol,
                      {
                        attrs: { cols: "6 action-button-wrapper " + _vm.type },
                      },
                      [
                        _vm.hasActionListener
                          ? _c("Button", {
                              attrs: {
                                variant: "secondary",
                                color: "primary",
                                outlineColor: "#000000",
                                outlined: "",
                                textValue: _vm.actionText,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.executeNotificationCallback($event)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showNotification,
      callback: function ($$v) {
        _vm.showNotification = $$v
      },
      expression: "showNotification",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }