import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inviteeProfile.inviter
    ? _c("div", [
        _c("div", { staticClass: "px-12 py-12 my-12 mx-12 invt text-center" }, [
          _vm.inviteeProfile.inviteeEmail == _vm.userProfile.email
            ? _c("div", [
                _c("img", {
                  staticClass: "inv-img",
                  attrs: { src: require("../../assets/inv.png") },
                }),
                _c("h2", { staticClass: "pt-2 pb-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("invitedByText"))),
                ]),
                _vm.inviteeProfile.inviter
                  ? _c("div", [
                      _c("h3", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.inviteeProfile.inviter.firstName) +
                            " " +
                            _vm._s(_vm.inviteeProfile.inviter.lastName) +
                            " "
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getBusinessInfo(
                              _vm.inviteeProfile.inviter.businessProfile
                            )
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " + _vm._s(_vm.inviteeProfile.inviter.email) + " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.inviteeProfile.inviter.primaryContactNumber
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c("span", { staticStyle: { "font-size": "13px" } }, [
                        _c("strong", [
                          _vm._v(
                            "(" +
                              _vm._s(
                                _vm.$stringConstants("invitationExpiresOnText")
                              ) +
                              " " +
                              _vm._s(
                                _vm.getLocalDataTime(_vm.inviteeProfile.expiry)
                              ) +
                              ")"
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "f-ns f-nn pl-10 pr-10 mb-2 mt-6 inv-btns",
                        attrs: { elevation: "0", color: "primary" },
                        on: { click: _vm.acceptInvite },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$stringConstants("acceptText")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      VBtn,
                      {
                        staticClass:
                          "f-ns f-nn pl-10 pr-10 btn-wt-bl-text inv-btns",
                        attrs: { elevation: "0" },
                        on: { click: _vm.declineInvitation },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("noThanksText")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _c("div", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("invalidInvitationText")) +
                    " "
                ),
              ]),
        ]),
      ])
    : _c("div", [
        _c("div", { staticClass: "px-12 py-12 my-12 mx-12 invt text-center" }, [
          _c("img", {
            staticClass: "inv-img",
            attrs: { src: require("../../assets/inv.png") },
          }),
          _c("h2", { staticClass: "pt-2 pb-4" }, [
            _vm._v(
              _vm._s(_vm.$stringConstants("invitationAlreadyExpiredText"))
            ),
          ]),
          _c(
            "div",
            [
              _c(
                VBtn,
                {
                  staticClass: "f-ns f-nn pl-10 pr-10 mb-2 mt-6 inv-btns",
                  attrs: { elevation: "0", color: "primary" },
                  on: { click: _vm.routeToJobs },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("goToJobsText")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }