import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "field-input-label pt-4 pb-5" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$stringConstants("teamJobLocationInstructionTitle")) +
          " "
      ),
    ]),
    _c(
      "div",
      [
        _c(VTextarea, {
          class: _vm.formFieldPb,
          attrs: {
            outlined: "",
            placeholder: "Type here...",
            rows: "5",
            "row-height": "20",
            required: "",
          },
          model: {
            value: _vm.teamJobAddressInstructions,
            callback: function ($$v) {
              _vm.teamJobAddressInstructions = $$v
            },
            expression: "teamJobAddressInstructions",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }