import accountComputedMixin from '@/components/account/account-computed';
import FarmsListView from './account-farms-list.vue';
export default {
  name: 'AccountFarmsAddress',
  mixins: [accountComputedMixin],
  components: {
    FarmsListView: FarmsListView
  },
  mounted: function mounted() {
    this.$store.dispatch('getUserFarmAddress');
  }
};