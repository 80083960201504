import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var SubscriptionStatusType;
(function (SubscriptionStatusType) {
  SubscriptionStatusType[SubscriptionStatusType["Trialing"] = 0] = "Trialing";
  SubscriptionStatusType[SubscriptionStatusType["Active"] = 1] = "Active";
  SubscriptionStatusType[SubscriptionStatusType["Pending"] = 2] = "Pending";
  SubscriptionStatusType[SubscriptionStatusType["Cancelled"] = 3] = "Cancelled";
})(SubscriptionStatusType || (SubscriptionStatusType = {}));
export var SubscriptionStatusTypeLabel = new Map([[SubscriptionStatusType.Trialing, 'Trialing'], [SubscriptionStatusType.Active, 'Active'], [SubscriptionStatusType.Pending, 'Pending'], [SubscriptionStatusType.Cancelled, 'Cancelled']]);