import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.noData
    ? _c(
        "div",
        { ref: "listScrollView", staticClass: "scroll-view" },
        [
          !_vm.noHeader
            ? _c(
                "div",
                {
                  staticClass: "list-heading pt-5 pb-4",
                  style: { top: _vm.listHeaderOffset + "px" },
                },
                [
                  _c(
                    VRow,
                    {
                      ref: "listHeader",
                      staticClass: "px-9",
                      attrs: { "no-gutters": "", cols: "12" },
                    },
                    [_vm._t("list-heading")],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isSkeletonLoaderVisible
            ? _c("SkeletonLoader", {
                attrs: {
                  columnWidths: _vm.columnWidths,
                  containsAction: _vm.containsAction,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "list-content" },
            _vm._l(_vm.listItems, function (item, index) {
              return _c(
                VRow,
                {
                  key: index,
                  class:
                    " " +
                    (_vm.type === "assignment" ? "" : "py-6 px-9") +
                    " lt-vw " +
                    (_vm.hasListItemListener
                      ? "hover_gray-bg cursor-pointer"
                      : ""),
                  attrs: { cols: "12", "data-id": item.id },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("listItemClick", item)
                    },
                  },
                },
                [_vm._t("list-item", null, { listItem: item })],
                2
              )
            }),
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldListLoadMoreItems,
                  expression: "shouldListLoadMoreItems",
                },
              ],
              ref: "loadMoreRef",
              staticClass: "text-center my-7 load-more__control",
            },
            [
              _vm.isEllipsisLoaderVisible
                ? _c("EllipsisLoader")
                : _vm.isLoadMoreVisible
                ? _c("ButtonLoadMore", {
                    staticClass: "max-w-100",
                    nativeOn: {
                      click: function ($event) {
                        return _vm.loadMoreItems($event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "list-spacer" }),
        ],
        1
      )
    : _vm.noData
    ? _c(
        "div",
        [
          _c(
            VRow,
            { staticClass: "px-9" },
            [
              !_vm.filtersApplied
                ? _c(
                    VCol,
                    { staticClass: "col-4 px-0" },
                    [_vm._t("zero-state")],
                    2
                  )
                : _vm.filtersApplied
                ? _vm._t("empty-search-state", [
                    _c(VCol, { staticClass: "col-4 px-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("errorForWrongSearch")) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "list-spacer" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }