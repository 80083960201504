import { render, staticRenderFns } from "./internal-contact-personal-details.vue?vue&type=template&id=312993ba"
import script from "./internal-contact-personal-details.vue?vue&type=script&lang=ts"
export * from "./internal-contact-personal-details.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/src/web/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('312993ba')) {
      api.createRecord('312993ba', component.options)
    } else {
      api.reload('312993ba', component.options)
    }
    module.hot.accept("./internal-contact-personal-details.vue?vue&type=template&id=312993ba", function () {
      api.rerender('312993ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/internal-contact/internal-contact-personal-details.vue"
export default component.exports