import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedEvent && _vm.selectedEvent.event
    ? _c(
        VCard,
        {
          staticClass: "jobs-calendar-info-window",
          style: {
            top: _vm.jobInfoWindowPosition.top + "px",
            left: _vm.jobInfoWindowPosition.left + "px",
          },
          attrs: { width: "320" },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-space-between align-start pt-3 px-3",
            },
            [
              _c(
                "span",
                {
                  staticClass: "selected-event-status",
                  style: {
                    backgroundColor: _vm.getBackgroundColor(
                      _vm.selectedEvent.event.status
                    ),
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.getStatusText(_vm.selectedEvent.event.status)
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                VIcon,
                {
                  attrs: { outlined: "", size: "16", color: "pale_gray--text" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close-job-info-window")
                    },
                  },
                },
                [_vm._v(" mdi-close-circle ")]
              ),
            ],
            1
          ),
          _c(
            VCardTitle,
            { staticClass: "selected-event-operation mb-2 px-0 pt-3 px-3" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.selectedEvent.event.moreInformation.operation.title
                  ) +
                  " "
              ),
            ]
          ),
          _c(VCardSubtitle, { staticClass: "selected-event-number px-3" }, [
            _vm._v(
              " " + _vm._s(_vm.selectedEvent.event.moreInformation.number) + " "
            ),
          ]),
          _c("div", { staticClass: "selected-event-fields pb-2 px-3" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("div", [
                _c("img", {
                  attrs: { src: require("../../assets/job-card-fields.svg") },
                }),
              ]),
              _c("div", { staticClass: "job-card-info-label" }, [
                _vm.selectedEvent.event.moreInformation.fields.length > 0
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getFieldNames(
                              _vm.selectedEvent.event.moreInformation.fields
                            )
                          ) +
                          " (" +
                          _vm._s(
                            _vm.getTotalsFieldsArea(
                              _vm.selectedEvent.event.moreInformation.fields
                            )
                          ) +
                          ") "
                      ),
                    ])
                  : _c("span", [_vm._v(" N/A ")]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "selected-event-start-date pb-2 px-3" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("div", [
                _c("img", {
                  attrs: { src: require("../../assets/job-card-date.svg") },
                }),
              ]),
              _vm.selectedEvent.event.moreInformation.status >=
              _vm.TeamJobStatusType.InProgress
                ? _c("div", { staticClass: "job-card-info-label" }, [
                    _vm._v(
                      " Started on " +
                        _vm._s(
                          _vm.getDateTimeFormat(
                            _vm.selectedEvent.event.moreInformation.startedOn
                          )
                        ) +
                        " "
                    ),
                  ])
                : _c("div", { staticClass: "job-card-info-label" }, [
                    _vm._v(
                      " Scheduled at " +
                        _vm._s(
                          _vm.getDateTimeFormat(
                            _vm.selectedEvent.event.moreInformation.scheduledAt
                          )
                        ) +
                        " "
                    ),
                  ]),
            ]),
          ]),
          _c("div", { staticClass: "selected-event-operators pb-2 px-3" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("div", [
                _c("img", {
                  attrs: { src: require("../../assets/job-card-user.svg") },
                }),
              ]),
              _c("div", { staticClass: "job-card-info-label" }, [
                _vm.selectedEvent.event.moreInformation.operators.length > 0
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getOperatorNames(
                              _vm.selectedEvent.event.moreInformation.operators
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v(" N/A ")]),
              ]),
            ]),
          ]),
          _c(
            VCardActions,
            { staticClass: "job-card-action" },
            [
              _c(
                VBtn,
                {
                  staticClass:
                    "primary--text text-none pa-0 font-weight-regular",
                  attrs: { variant: "text", width: "150", elevation: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("show-job-details")
                    },
                  },
                },
                [_vm._v(" View details ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }