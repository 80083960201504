import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "field-input-label" }, [_vm._v(" Status ")]),
    _vm.teamJobDetails != null && !_vm.teamJobsLoader
      ? _c("div", { staticClass: "pt-1" }, [
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.Pending &&
          _vm.teamJobDetails.contractorApprovalStatus !=
            _vm.ApprovalStatusType.Declined
            ? _c("div", { staticClass: "j-sdul" }, [
                _vm._m(0),
                _c("span", { staticClass: "pl-2 status-v-middle" }, [
                  _vm._v(
                    _vm._s(
                      _vm.TeamJobStatusTypeLabel.get(
                        _vm.TeamJobStatusType.Pending
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.Ready
            ? _c("div", { staticClass: "j-ready" }, [
                _vm._m(1),
                _vm.teamJobDetails.scheduledAt
                  ? _c("span", { staticClass: "pl-2 status-v-middle" }, [
                      _vm._v("Scheduled"),
                    ])
                  : _c("span", { staticClass: "pl-2 status-v-middle" }, [
                      _vm._v("Unscheduled"),
                    ]),
              ])
            : _vm._e(),
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.InProgress
            ? _c("div", { staticClass: "j-pro" }, [
                _vm._m(2),
                _c("span", { staticClass: "pl-2 status-v-middle" }, [
                  _vm._v(
                    _vm._s(
                      _vm.TeamJobStatusTypeLabel.get(
                        _vm.TeamJobStatusType.InProgress
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.AwaitingCompletion
            ? _c("div", { staticClass: "j-fns" }, [
                _vm._m(3),
                _c("span", { staticClass: "pl-2 status-v-middle" }, [
                  _vm._v(
                    _vm._s(
                      _vm.TeamJobStatusTypeLabel.get(
                        _vm.TeamJobStatusType.AwaitingCompletion
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.PartCompleted
            ? _c("div", { staticClass: "j-fns" }, [
                _vm._m(4),
                _c("span", { staticClass: "pl-2 status-v-middle" }, [
                  _vm._v(
                    _vm._s(
                      _vm.TeamJobStatusTypeLabel.get(
                        _vm.TeamJobStatusType.PartCompleted
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.Completed &&
          !_vm.teamJobDetails.isApprovedForInvoicing
            ? _c("div", { staticClass: "j-fns" }, [
                _vm._m(5),
                _c("span", { staticClass: "pl-2 status-v-middle" }, [
                  _vm._v(
                    _vm._s(
                      _vm.TeamJobStatusTypeLabel.get(
                        _vm.TeamJobStatusType.Completed
                      )
                    )
                  ),
                ]),
                _c("br"),
              ])
            : _vm._e(),
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.Cancelled
            ? _c("div", [
                _vm._m(6),
                _c(
                  "span",
                  {
                    staticClass: "pl-2 status-v-middle",
                    staticStyle: { color: "#fa4e4e" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.TeamJobStatusTypeLabel.get(
                          _vm.TeamJobStatusType.Cancelled
                        )
                      )
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.Pending &&
          _vm.teamJobDetails.contractorApprovalStatus ==
            _vm.ApprovalStatusType.Declined
            ? _c(
                "div",
                {
                  staticClass: "cursor-pointer",
                  attrs: {
                    title: _vm.teamJobDetails.contractorDeclineReason || "",
                  },
                },
                [
                  _vm._m(7),
                  _c(
                    "span",
                    {
                      staticClass: "pl-2 status-v-middle",
                      staticStyle: { color: "#fa4e4e" },
                    },
                    [_vm._v(_vm._s(_vm.$stringConstants("declinedText")))]
                  ),
                ]
              )
            : _vm._e(),
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.Completed &&
          _vm.teamJobDetails.isApprovedForInvoicing &&
          !_vm.teamJobDetails.isInvoiced
            ? _c(
                "div",
                {
                  staticClass: "jobDetails-status j-fns pa-0 text-left",
                  staticStyle: { color: "#07AB49" },
                },
                [
                  _vm._m(8),
                  _c("span", { staticClass: "pl-2 status-v-middle" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("approveInvoiceText"))),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.teamJobDetails.status == _vm.TeamJobStatusType.Completed &&
          _vm.teamJobDetails.isApprovedForInvoicing &&
          _vm.teamJobDetails.isInvoiced
            ? _c(
                "div",
                { staticClass: "jobDetails-status j-fns pa-0 text-left j-pro" },
                [
                  _vm._m(9),
                  _c("span", { staticClass: "pl-2 status-v-middle" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("invoiceText"))),
                  ]),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.teamJobsLoader,
            expression: "teamJobsLoader",
          },
        ],
        staticClass: "mb-7",
      },
      [_c(VSkeletonLoader, { attrs: { height: "40", type: "card" } })],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-Pending.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-Ready-new.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-Inprogress.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-complete.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-complete.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-finish.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-Declined.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-Declined.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-invoice.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "status-icon",
        attrs: { src: require("../../../../assets/job-invoice.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }