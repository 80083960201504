export var InvoiceStatusType;
(function (InvoiceStatusType) {
  InvoiceStatusType[InvoiceStatusType["Draft"] = 0] = "Draft";
  InvoiceStatusType[InvoiceStatusType["Open"] = 1] = "Open";
  InvoiceStatusType[InvoiceStatusType["Paid"] = 2] = "Paid";
  InvoiceStatusType[InvoiceStatusType["Void"] = 3] = "Void";
})(InvoiceStatusType || (InvoiceStatusType = {}));
export var InvoiceEmailStatusType;
(function (InvoiceEmailStatusType) {
  InvoiceEmailStatusType[InvoiceEmailStatusType["Pending"] = 1] = "Pending";
  InvoiceEmailStatusType[InvoiceEmailStatusType["Sent"] = 2] = "Sent";
})(InvoiceEmailStatusType || (InvoiceEmailStatusType = {}));