import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import { formFieldPb } from '@/utils/uiconstants';
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { validateRegexPhoneNumber } from '@/utils/helpers';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength } from 'vuelidate/lib/validators';
import ConstantValues from '@/plugins/constantValues';
export default {
  name: 'SecondaryContactDetailsForm',
  mixins: [validationMixin, contactComputedMixin],
  validations: {
    contactPhone: {
      format: function format(phone) {
        return /[0-9]/.test(phone);
      },
      maxLength: maxLength(ConstantValues.maxlengthPhoneNumber),
      minLength: minLength(ConstantValues.minlengthPhoneNumber)
    }
  },
  components: {
    SectionLeftHeadings: SectionLeftHeadings
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb
    };
  },
  methods: {
    checkPhoneNumber: function checkPhoneNumber(event) {
      validateRegexPhoneNumber(event);
    }
  }
};