import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-9" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(
              VCol,
              { attrs: { cols: "8" } },
              [
                _c("HeaderPrimary", {
                  attrs: {
                    textValue: _vm.$stringConstants("teamJobLocationTitle"),
                  },
                }),
              ],
              1
            ),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    attrs: { small: "" },
                    on: { click: _vm.closeAddAddressesForm },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", [
      _c(
        "div",
        { staticClass: "scroll-view", staticStyle: { height: "85vh" } },
        [
          _c("TeamJobsAddressesFlyoutAddressToggle"),
          _c(
            VContainer,
            {
              staticClass: "pb-0 pt-7",
              class: [_vm.containerLeftPad, _vm.containerRightPad],
            },
            [
              _vm.teamJobAddressSelectionType ==
              _vm.TeamJobAddressSelectionType.FarmAddress
                ? _c("div", [_c("TeamJobsAddressesFlyoutFarmAddresses")], 1)
                : _vm._e(),
              _vm.teamJobAddressSelectionType ==
              _vm.TeamJobAddressSelectionType.DropPin
                ? _c("div", [_c("TeamJobsAddressesFlyoutDropPin")], 1)
                : _vm._e(),
              _vm.teamJobAddressSelectionType ==
              _vm.TeamJobAddressSelectionType.CustomAddress
                ? _c("div", [_c("TeamJobsAddressesFlyoutCustomAddress")], 1)
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btns-bottom-box px-8 py-4 text-center" },
        [
          _c("Button", {
            attrs: {
              variant: "primary",
              color: "primary",
              textValue: _vm.$stringConstants("addBtnText"),
              rightIcon: "mdi-arrow-right",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.addAddressToJobLocations()
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }