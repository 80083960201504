import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-8" }, [
    _c("div", { staticClass: "pb-10" }, [
      _c("div", { staticClass: "tj-cp-title" }, [
        _vm._v(
          " " + _vm._s(_vm.$stringConstants("teamJobProductsTitle")) + " "
        ),
      ]),
      _c("div", { staticClass: "tj-cp-subtitle" }, [
        _vm._v(
          " " + _vm._s(_vm.$stringConstants("teamJobProductsSubTitle")) + " "
        ),
      ]),
    ]),
    _c("div", [
      _c("div", { staticClass: "jb-operators-list-head" }, [
        _c("div", { staticClass: "ib width-perc-55 pr-4" }, [
          _vm._v(" " + _vm._s(_vm.$stringConstants("product")) + " "),
        ]),
        _c("div", { staticClass: "ib width-perc-20 pr-1" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.teamJobViewPreference.products.isDetailed,
                  expression: "teamJobViewPreference.products.isDetailed",
                },
              ],
            },
            [_vm._v(" " + _vm._s(_vm.$stringConstants("usage")) + " ")]
          ),
        ]),
        _c("div", { staticClass: "ib width-perc-20 pl-1" }, [
          _vm._v(" " + _vm._s(_vm.$stringConstants("unit")) + " "),
        ]),
        _c("div", { staticClass: "ib width-perc-5 pr-4 text-right" }),
      ]),
    ]),
    _c("div", [
      _vm.teamJobProducts.length
        ? _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.teamJobsLoader,
                    expression: "!teamJobsLoader",
                  },
                ],
                attrs: { id: "jb-add-operators-table" },
              },
              _vm._l(_vm.teamJobProducts, function (product, productIndex) {
                return _c(
                  "div",
                  {
                    key: productIndex,
                    staticClass: "jb-add-operator-cont pb-3 pt-3 bt-0",
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "ib width-perc-55 pr-4 fw-6" }, [
                        _vm._v(" " + _vm._s(product.title) + " "),
                      ]),
                      _c("div", { staticClass: "ib width-perc-20 pr-1" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.teamJobViewPreference.products.isDetailed,
                                expression:
                                  "teamJobViewPreference.products.isDetailed",
                              },
                            ],
                          },
                          [
                            _c(VTextField, {
                              staticClass: "slot-m0",
                              class: { "light-input": _vm.isDetailsView },
                              attrs: {
                                type: "number",
                                disabled:
                                  !_vm.teamJobViewPreference.products
                                    .isEditable,
                                outlined: "",
                                dense: "",
                                height: "48",
                                placeholder: _vm.$stringConstants(
                                  "enterAmountPlaceholder"
                                ),
                              },
                              on: { input: _vm.onChangeProductValue },
                              model: {
                                value: product.usages[0].usage,
                                callback: function ($$v) {
                                  _vm.$set(
                                    product.usages[0],
                                    "usage",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "product.usages[0].usage",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "ib width-perc-20 pl-1" },
                        [
                          _c(VTextField, {
                            staticClass: "hide_input_details rd-lf-no slot-m0",
                            class: { "dark-input": _vm.isDetailsView },
                            attrs: {
                              outlined: "",
                              height: "48",
                              dense: "",
                              disabled: "",
                              required: "",
                            },
                            model: {
                              value: product.unit,
                              callback: function ($$v) {
                                _vm.$set(product, "unit", $$v)
                              },
                              expression: "product.unit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ib width-perc-5 text-right" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.teamJobViewPreference.products.isEditable,
                                expression:
                                  "teamJobViewPreference.products.isEditable",
                              },
                            ],
                            staticClass: "pt-2 pl-4 ib cursor-pointer",
                            attrs: { title: "Remove product" },
                            on: {
                              click: function ($event) {
                                return _vm.removeProductFromProductsList(
                                  productIndex
                                )
                              },
                            },
                          },
                          [
                            _c(VIcon, { staticClass: "job-add-list-rm" }, [
                              _vm._v("mdi-close"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.teamJobsLoader,
              expression: "teamJobsLoader",
            },
          ],
        },
        _vm._l(2, function (p, pi) {
          return _c("div", { key: pi, staticClass: "jb-add-operator-cont" }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "ib width-perc-55 pr-4 pb-4 pt-3 fw-6" },
                [
                  _c(VSkeletonLoader, {
                    attrs: { width: "100", height: "20", type: "card" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.teamJobViewPreference.products.isEditable,
                      expression: "teamJobViewPreference.products.isEditable",
                    },
                  ],
                  staticClass: "ib width-perc-20 pr-1 pb-4 pt-3",
                },
                [
                  _c(VSkeletonLoader, {
                    attrs: { width: "100", height: "30", type: "card" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ib width-perc-20 pl-1 pb-4 pt-3" },
                [
                  _c(VSkeletonLoader, {
                    attrs: { width: "100", height: "30", type: "card" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "ib width-perc-5 pb-4 text-right" }),
            ]),
          ])
        }),
        0
      ),
      _vm.teamJobShowJobAddProductsExpandedView
        ? _c(
            "div",
            {
              staticClass: "jb-add-operator-cont pt-4 pb-4 cursor-pointer bt-0",
              class: {
                "jb-add-operator-cont-another":
                  _vm.teamJobOperators.length == 0,
              },
              on: { click: _vm.toggleDropdownAndOpenSelection },
            },
            [
              _c("div", [
                _c("div", { staticClass: "cursor-pointer pr-8 py-3" }, [
                  _vm.teamJobProducts.length == 0 &&
                  _vm.teamJobDetails &&
                  _vm.teamJobDetails.isApprovedForInvoicing
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("teamJobZeroProductsText")
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", { staticClass: "jb-add-ln-btn" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("teamJobAddProductsText")
                            ) +
                            " "
                        ),
                      ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.teamJobsLoader && _vm.teamJobShowJobAddProductsDropdown,
              expression:
                "!teamJobsLoader && teamJobShowJobAddProductsDropdown",
            },
          ],
          attrs: { id: "productSelectionMenu" },
        },
        [
          _c(VAutocomplete, {
            staticClass: "teamsJobDropDown",
            attrs: {
              id: "productSelectionDropdownMenu",
              items: _vm.teamJobProductsList,
              "item-text": "title",
              placeholder: _vm.$stringConstants("selectProductPlaceholder"),
              "search-input": _vm.teamJobProductsSearchText,
              loading: _vm.teamJobProductsLoader,
              outlined: !_vm.teamJobProductsLoader,
              dense: "",
              clearable: "",
              flat: "",
              solo: "",
              attach: "",
              "return-object": "",
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.teamJobProductsSearchText = $event
              },
              "update:search-input": function ($event) {
                _vm.teamJobProductsSearchText = $event
              },
              change: _vm.addProductToProductsList,
              focus: _vm.refeatchProductsList,
              "click:clear": _vm.applySearchJobProductTimeout,
            },
            nativeOn: {
              input: function ($event) {
                return _vm.applySearchJobProductTimeout($event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "py-2", staticStyle: { width: "100%" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ib",
                            staticStyle: {
                              width: "auto",
                              transform: "translateY(3px)",
                            },
                          },
                          [
                            _c(
                              VListItemTitle,
                              {
                                staticClass: "pl-1",
                                class: {
                                  "jb-add-operator-added":
                                    _vm.isProductPresentInSelectedProductList(
                                      data.item
                                    ),
                                },
                              },
                              [_vm._v(_vm._s(data.item.title))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "append-item",
                fn: function () {
                  return [
                    _c("LoadMoreDropdown", {
                      attrs: {
                        moreData: _vm.hasMoreData,
                        loadInProgress: _vm.teamJobProductsLoader,
                        loadingListLength: _vm.teamJobProductsList.length,
                      },
                      on: { appendMore: _vm.appendMoreProducts },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.teamJobProductToAdd,
              callback: function ($$v) {
                _vm.teamJobProductToAdd = $$v
              },
              expression: "teamJobProductToAdd",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }