import { render, staticRenderFns } from "./subscriptions-plan-details.vue?vue&type=template&id=505cf4de"
import script from "./subscriptions-plan-details.vue?vue&type=script&lang=ts"
export * from "./subscriptions-plan-details.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/src/web/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('505cf4de')) {
      api.createRecord('505cf4de', component.options)
    } else {
      api.reload('505cf4de', component.options)
    }
    module.hot.accept("./subscriptions-plan-details.vue?vue&type=template&id=505cf4de", function () {
      api.rerender('505cf4de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/subscriptions/subscriptions-plan-details.vue"
export default component.exports