import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginRegistrationWrapper", [
    _c("div", { staticClass: "centered" }, [
      _c(
        "form",
        {
          staticClass: "login-box fpwd-box",
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "logo-wrapper" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("../../assets/ag-drive-logo.svg") },
            }),
          ]),
          !_vm.sentCurrentRequest
            ? _c(
                "div",
                [
                  _c("span", { staticClass: "d-block title" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("resetPassword"))),
                  ]),
                  _c("span", { staticClass: "d-block subtitle pb-9" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$stringConstants("resetPasswordInformation")
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("emailText")) + " *"),
                  ]),
                  _c(VTextField, {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "error-messages": _vm.userNameErrors,
                      placeholder: _vm.$stringConstants("emailPlaceholder"),
                      required: "",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submit($event)
                      },
                      input: function ($event) {
                        return _vm.$v.userName.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.userName.$touch()
                      },
                    },
                    model: {
                      value: _vm.userName,
                      callback: function ($$v) {
                        _vm.userName = $$v
                      },
                      expression: "userName",
                    },
                  }),
                  _c("Button", {
                    staticClass: "mt-4",
                    attrs: {
                      variant: "primary",
                      color: "primary",
                      textValue: "Continue",
                      loading: _vm.resendLoading,
                      disabled: _vm.resendButtonDisabled || _vm.isFieldsInvalid,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.submit($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("span", { staticClass: "d-block title" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("forgetPwdTitle"))),
                  ]),
                  _c("span", { staticClass: "d-block subtitle" }, [
                    _vm._v(
                      _vm._s(_vm.$stringConstants("forgetPwdText1")) + " "
                    ),
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(_vm._s(_vm.userName)),
                    ]),
                    _vm._v(_vm._s(_vm.$stringConstants("forgetPwdText2"))),
                  ]),
                  _c("Button", {
                    staticClass: "mt-9",
                    attrs: {
                      variant: "primary",
                      color: "primary",
                      loading: _vm.resendLoading,
                      disabled: _vm.resendButtonDisabled || _vm.isFieldsInvalid,
                      textValue:
                        _vm.resendCountdown > 0
                          ? "Resend in " + _vm.resendCountdown
                          : "Resend",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.submit($event)
                      },
                    },
                  }),
                ],
                1
              ),
          _c(
            "div",
            { staticClass: "pt-5 text-center" },
            [
              _c(
                "router-link",
                { staticClass: "no-decoration", attrs: { to: _vm.routeLogin } },
                [
                  _c("span", { staticClass: "font-weight-medium fn-sm" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("logInText"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }