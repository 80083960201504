import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hide-scroll",
      staticStyle: { height: "calc(100vh - 70px)" },
    },
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "px-9 pb-16 mb-16", attrs: { cols: "12" } },
            [
              _c("ContactPersonalDetailsForm"),
              _c("SecondaryContactDetailsForm"),
              _c("ContactCompanyDetailsForm", {
                attrs: { isEdiablePrefix: _vm.isEdiablePrefix },
              }),
              _c("ContactCompanyAddressForm"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VRow,
        { attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            {
              staticClass: "fix-bar-jb fx-bar-lshft px-0",
              attrs: { cols: "12" },
            },
            [
              _c(
                "div",
                { staticClass: "btm-btn-bg py-0" },
                [
                  _c(
                    VRow,
                    {
                      staticClass: "px-0 py-0",
                      attrs: { "no-gutters": "", cols: "12" },
                    },
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0", attrs: { cols: "10" } },
                        [
                          _c(
                            VRow,
                            { attrs: { "no-gutters": "", cols: "12" } },
                            [
                              _c(VCol, {
                                staticClass: "py-0",
                                attrs: { cols: "4" },
                              }),
                              _c(
                                VCol,
                                { staticClass: "py-0", attrs: { cols: "8" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "jb-mw" },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticClass: "px-0 py-0",
                                          attrs: {
                                            "no-gutters": "",
                                            cols: "12",
                                          },
                                        },
                                        [
                                          _c(
                                            VCol,
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    " py-4 pl-9 pr-16 btn-lt-sht text-right",
                                                },
                                                [
                                                  _c("HoverTextButton", {
                                                    staticClass:
                                                      "f-ns f-nn pl-10 pr-10 ml-6 mt-1 mb-1 btn-wt-bl-text",
                                                    attrs: {
                                                      height: 48,
                                                      textValue:
                                                        _vm.$stringConstants(
                                                          "back"
                                                        ),
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.cancelSaveContactProfile()
                                                      },
                                                    },
                                                  }),
                                                  _c("ButtonActionPrimary", {
                                                    staticClass: "ml-4",
                                                    attrs: {
                                                      width: "275",
                                                      height: 48,
                                                      textValue:
                                                        "Create customer",
                                                      disabled:
                                                        !_vm.isFieldsValid ||
                                                        _vm.contactLoader,
                                                      loading:
                                                        _vm.contactLoader,
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.saveContactProfile()
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(VCol, {
                        staticClass: "py-0",
                        attrs: { cols: "2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }