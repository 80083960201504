import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mx-7 py-4 d-flex align-baseline justify-space-between",
        staticStyle: { "flex-wrap": "unset" },
      },
      [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "div",
              {
                staticClass: "job-tabs d-flex mr-4",
                class: {
                  "job-tabs-disabled":
                    _vm.listLoading ||
                    _vm.teamJobsCustomersDropdownLoader ||
                    _vm.teamJobsTeamMembersDropdownLoader ||
                    _vm.teamJobOperatorToAddLoader ||
                    _vm.teamJobVehiclesListForOperatorsLoader ||
                    _vm.teamJobImplementsListForOperatorsLoader,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "job-tab mr-2",
                    class: { "active-tab": _vm.jobViewType == "List" },
                    attrs: { outlined: "" },
                    on: {
                      click: function ($event) {
                        return _vm.switchToListView()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "text-capitalize" }, [
                      _vm._v("List"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "job-tab",
                    class: { "active-tab": _vm.jobViewType == "Calendar" },
                    attrs: { outlined: "" },
                    on: {
                      click: function ($event) {
                        return _vm.switchToCalendarView()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "text-capitalize" }, [
                      _vm._v("Calendar"),
                    ]),
                  ]
                ),
              ]
            ),
            _c(VTextField, {
              staticClass: "agd-txtfield jobs-search-resp",
              attrs: {
                height: "42px",
                label: _vm.$stringConstants("searchJobsLabel"),
                title: _vm.$stringConstants("searchJobsLabel"),
                outlined: "",
                clearable: "",
                "prepend-inner-icon": "mdi-magnify",
                "hide-details": "",
                dense: "",
              },
              on: {
                input: function ($event) {
                  return _vm.applySearchTimer(_vm.applySearchJobFilter)
                },
              },
              model: {
                value: _vm.teamJobsListSearchText,
                callback: function ($$v) {
                  _vm.teamJobsListSearchText = $$v
                },
                expression: "teamJobsListSearchText",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "ib jb-fltr-1 ml-4 d-flex" }, [
          _c(
            "div",
            { staticClass: "f-flex" },
            [
              _c("Button", {
                class: "mr-2",
                attrs: {
                  variant: "secondary",
                  outlined: "",
                  outlineColor: "gray",
                  disabled:
                    (_vm.jobViewType == "List" && _vm.listLoading) ||
                    (_vm.jobViewType == "Calendar" && _vm.listLoading),
                  textValue: _vm.$stringConstants("refreshText"),
                  leftIcon: "mdi-refresh",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.refreshJobs()
                  },
                },
              }),
              _c("Button", {
                class: "mr-2",
                attrs: {
                  variant: "secondary",
                  outlined: "",
                  outlineColor: "gray",
                  disabled:
                    (_vm.jobViewType == "List" &&
                      (_vm.listLoading || _vm.teamJobsList.length == 0)) ||
                    (_vm.jobViewType == "Calendar" &&
                      (_vm.listLoading || _vm.calendarViewJobs.length == 0)),
                  textValue: _vm.$stringConstants("downloadText"),
                  leftIcon: "mdi-arrow-down",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.initiateExportJobs()
                  },
                },
              }),
              _c("Button", {
                attrs: {
                  variant: "secondary",
                  color: "primary",
                  outlined: "",
                  outlineColor: "primary",
                  textValue: _vm.$stringConstants("addNewText"),
                  disabled: _vm.listLoading,
                  leftIcon: "mdi-plus",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.openAddJobForm()
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "list-container",
        staticStyle: { "max-height": "calc(100vh - 196px)" },
      },
      [
        _c(
          VRow,
          {
            staticClass: "list-filters flex-grow-0 px-7 pt-2 position-relative",
            staticStyle: { "z-index": "5" },
            attrs: { "no-gutters": "", cols: "12" },
          },
          [
            _c(
              VCol,
              { staticClass: "pb-0", attrs: { cols: "12", xl: "12" } },
              [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "px-0 pt-1 pb-0", attrs: { cols: "12" } },
                      [
                        _vm.visibleFilters.status && _vm.jobViewType == "List"
                          ? _c(
                              "div",
                              {
                                staticClass: "ib jb-fltr-2",
                                staticStyle: { width: "140px" },
                              },
                              [
                                _c(VSelect, {
                                  staticClass: "jb-sts-pos agd-dropdown",
                                  attrs: {
                                    items: _vm.teamJobsFiltersStatusList,
                                    label: "Job status",
                                    "item-value": "value",
                                    "item-text": "title",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: { change: _vm.filterJobsByStatus },
                                  model: {
                                    value: _vm.teamJobsFilterStatusSelected,
                                    callback: function ($$v) {
                                      _vm.teamJobsFilterStatusSelected = $$v
                                    },
                                    expression: "teamJobsFilterStatusSelected",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.visibleFilters.customer &&
                        (_vm.loginUserRole == _vm.UserRoleType.Contractor ||
                          (_vm.loginUserRole == _vm.UserRoleType.Manager &&
                            _vm.ownerRole ==
                              _vm.UserRoleType[_vm.UserRoleType.Contractor]))
                          ? _c(
                              "div",
                              { staticClass: "ib jb-fltr-3 jb-ls-srch" },
                              [
                                _c(VAutocomplete, {
                                  staticClass: "agd-dropdown",
                                  attrs: {
                                    label: "Customer",
                                    items: _vm.teamJobsFilterCustomersList,
                                    "search-input":
                                      _vm.teamJobsFilterCustomerSearchText,
                                    loading:
                                      _vm.teamJobsCustomersDropdownLoader,
                                    "item-text": "name",
                                    "item-value": "id",
                                    outlined: "",
                                    "hide-no-data": "",
                                    clearable: "",
                                    dense: "",
                                  },
                                  on: {
                                    "update:searchInput": function ($event) {
                                      _vm.teamJobsFilterCustomerSearchText =
                                        $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.teamJobsFilterCustomerSearchText =
                                        $event
                                    },
                                    "click:clear":
                                      _vm.applyCustomersFilterSearchTimer,
                                    focus: _vm.applyCustomersFilterSearchTimer,
                                    change: function ($event) {
                                      return _vm.getJobsByCustomer()
                                    },
                                  },
                                  nativeOn: {
                                    input: function ($event) {
                                      return _vm.applyCustomersFilterSearchTimer(
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-item",
                                        fn: function () {
                                          return [
                                            _c("LoadMoreItem", {
                                              attrs: {
                                                searchText:
                                                  _vm.teamJobsFilterCustomerSearchText,
                                                loadedListLength:
                                                  _vm
                                                    .teamJobsFilterCustomersList
                                                    .length,
                                                moduleNamespace:
                                                  _vm.$stringConstants(
                                                    "teamJobsFilterCustomersPaginationModule"
                                                  ),
                                              },
                                              on: {
                                                loadMoreItems: function (
                                                  $event
                                                ) {
                                                  return _vm.appendMoreItems(
                                                    "customers"
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    444521072
                                  ),
                                  model: {
                                    value: _vm.teamJobsFilterCustomerValue,
                                    callback: function ($$v) {
                                      _vm.teamJobsFilterCustomerValue = $$v
                                    },
                                    expression: "teamJobsFilterCustomerValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.visibleFilters.operation
                          ? _c(
                              "div",
                              { staticClass: "ib jb-fltr-4 jb-ls-srch mr-1" },
                              [
                                _c(VAutocomplete, {
                                  staticClass: "agd-dropdown",
                                  attrs: {
                                    label: "Operation",
                                    items: _vm.teamJobsFilterOperationsList,
                                    "search-input":
                                      _vm.teamJobsFilterOperationSearchText,
                                    clearable: "",
                                    "item-text": "displayTitle",
                                    "item-value": "id",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    "update:searchInput": function ($event) {
                                      _vm.teamJobsFilterOperationSearchText =
                                        $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.teamJobsFilterOperationSearchText =
                                        $event
                                    },
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.searchOperationsByText($event)
                                    },
                                    change: function ($event) {
                                      return _vm.getJobsByOperation()
                                    },
                                  },
                                  model: {
                                    value: _vm.teamJobsFilterOperationValue,
                                    callback: function ($$v) {
                                      _vm.teamJobsFilterOperationValue = $$v
                                    },
                                    expression: "teamJobsFilterOperationValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.jobViewType == "List" &&
                        _vm.visibleFilters.invoiceStatus &&
                        (_vm.loginUserRole == _vm.UserRoleType.Contractor ||
                          (_vm.loginUserRole == _vm.UserRoleType.Manager &&
                            _vm.ownerRole ==
                              _vm.UserRoleType[_vm.UserRoleType.Contractor]))
                          ? _c(
                              "div",
                              {
                                staticClass: "ib jb-fltr-6",
                                staticStyle: { width: "160px" },
                              },
                              [
                                _c(VSelect, {
                                  staticClass: "jb-sts-pos agd-dropdown",
                                  attrs: {
                                    items: _vm.teamJobsFiltersInvoiceStatusList,
                                    label: "Invoice status",
                                    "item-value": "value",
                                    "item-text": "title",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: { change: _vm.getJobsByInvoiceStatus },
                                  model: {
                                    value: _vm.teamJobsFilterInvoiceStatusValue,
                                    callback: function ($$v) {
                                      _vm.teamJobsFilterInvoiceStatusValue = $$v
                                    },
                                    expression:
                                      "teamJobsFilterInvoiceStatusValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.visibleFilters.operator
                          ? _c(
                              "div",
                              { staticClass: "ib jb-fltr-7 jb-ls-srch" },
                              [
                                _c(VAutocomplete, {
                                  staticClass: "agd-dropdown",
                                  attrs: {
                                    label: "Operator",
                                    items: _vm.teamJobsFilterTeamMembersList,
                                    "search-input":
                                      _vm.teamJobsFilterTeamMemberSearchText,
                                    loading:
                                      _vm.teamJobsTeamMembersDropdownLoader,
                                    clearable: "",
                                    "item-text": "name",
                                    "item-value": "id",
                                    "hide-no-data": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    "update:searchInput": function ($event) {
                                      _vm.teamJobsFilterTeamMemberSearchText =
                                        $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.teamJobsFilterTeamMemberSearchText =
                                        $event
                                    },
                                    "click:clear":
                                      _vm.applyOperatorFilterSearchTimer,
                                    focus: _vm.applyOperatorFilterSearchTimer,
                                    change: function ($event) {
                                      return _vm.getJobsByTeamMember()
                                    },
                                  },
                                  nativeOn: {
                                    input: function ($event) {
                                      return _vm.applyOperatorFilterSearchTimer(
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-item",
                                        fn: function () {
                                          return [
                                            _c("LoadMoreItem", {
                                              attrs: {
                                                searchText:
                                                  _vm.teamJobsFilterTeamMemberSearchText,
                                                loadedListLength:
                                                  _vm
                                                    .teamJobsFilterTeamMembersList
                                                    .length,
                                                moduleNamespace:
                                                  _vm.$stringConstants(
                                                    "teamJobsFilterTeamMembersPaginationModule"
                                                  ),
                                              },
                                              on: {
                                                loadMoreItems: function (
                                                  $event
                                                ) {
                                                  return _vm.appendMoreItems(
                                                    "teamMembers"
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3347078448
                                  ),
                                  model: {
                                    value: _vm.teamJobsFilterTeamMemberValue,
                                    callback: function ($$v) {
                                      _vm.teamJobsFilterTeamMemberValue = $$v
                                    },
                                    expression: "teamJobsFilterTeamMemberValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.visibleFilters.field
                          ? _c(
                              "div",
                              { staticClass: "ib jb-fltr-7 jb-ls-srch" },
                              [
                                _c(VAutocomplete, {
                                  staticClass: "agd-dropdown",
                                  attrs: {
                                    label: "Field",
                                    items: _vm.teamJobsFilterFieldsList,
                                    "search-input":
                                      _vm.teamJobsFilterFieldsSearchText,
                                    loading: _vm.teamJobsFieldsDropdownLoader,
                                    clearable: "",
                                    "item-text": "name",
                                    "item-value": "id",
                                    "hide-no-data": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    "update:searchInput": function ($event) {
                                      _vm.teamJobsFilterFieldsSearchText =
                                        $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.teamJobsFilterFieldsSearchText =
                                        $event
                                    },
                                    "click:clear":
                                      _vm.applyFieldFilterSearchTimer,
                                    focus: _vm.applyFieldFilterSearchTimer,
                                    change: function ($event) {
                                      return _vm.getJobsByField()
                                    },
                                  },
                                  nativeOn: {
                                    input: function ($event) {
                                      return _vm.applyFieldFilterSearchTimer(
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-item",
                                        fn: function () {
                                          return [
                                            _c("LoadMoreItem", {
                                              attrs: {
                                                searchText:
                                                  _vm.teamJobsFilterFieldsSearchText,
                                                loadedListLength:
                                                  _vm.teamJobsFilterFieldsList
                                                    .length,
                                                moduleNamespace:
                                                  _vm.$stringConstants(
                                                    "teamJobsFilterFieldsPaginationModule"
                                                  ),
                                              },
                                              on: {
                                                loadMoreItems: function (
                                                  $event
                                                ) {
                                                  return _vm.appendMoreItems(
                                                    "fields"
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    348795459
                                  ),
                                  model: {
                                    value: _vm.teamJobsFilterFieldValue,
                                    callback: function ($$v) {
                                      _vm.teamJobsFilterFieldValue = $$v
                                    },
                                    expression: "teamJobsFilterFieldValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.visibleFilters.vehicle
                          ? _c(
                              "div",
                              { staticClass: "ib jb-fltr-7 jb-ls-srch" },
                              [
                                _c(VAutocomplete, {
                                  staticClass: "agd-dropdown",
                                  attrs: {
                                    label: "Vehicle",
                                    items: _vm.teamJobsFilterVehiclesList,
                                    "search-input":
                                      _vm.teamJobsFilterVehiclesSearchText,
                                    loading: _vm.teamJobsVehiclesDropdownLoader,
                                    clearable: "",
                                    "item-text": "name",
                                    "item-value": "id",
                                    "hide-no-data": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    "update:searchInput": function ($event) {
                                      _vm.teamJobsFilterVehiclesSearchText =
                                        $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.teamJobsFilterVehiclesSearchText =
                                        $event
                                    },
                                    "click:clear":
                                      _vm.applyVehicleFilterSearchTimer,
                                    focus: _vm.applyVehicleFilterSearchTimer,
                                    change: function ($event) {
                                      return _vm.getJobsByVehicle()
                                    },
                                  },
                                  nativeOn: {
                                    input: function ($event) {
                                      return _vm.applyVehicleFilterSearchTimer(
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-item",
                                        fn: function () {
                                          return [
                                            _c("LoadMoreItem", {
                                              attrs: {
                                                searchText:
                                                  _vm.teamJobsFilterVehiclesSearchText,
                                                loadedListLength:
                                                  _vm.teamJobsFilterVehiclesList
                                                    .length,
                                                moduleNamespace:
                                                  _vm.$stringConstants(
                                                    "teamJobsFilterVehiclesPaginationModule"
                                                  ),
                                              },
                                              on: {
                                                loadMoreItems: function (
                                                  $event
                                                ) {
                                                  return _vm.appendMoreItems(
                                                    "vehicles"
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3545220675
                                  ),
                                  model: {
                                    value: _vm.teamJobsFilterVehicleValue,
                                    callback: function ($$v) {
                                      _vm.teamJobsFilterVehicleValue = $$v
                                    },
                                    expression: "teamJobsFilterVehicleValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.visibleFilters.implement
                          ? _c(
                              "div",
                              { staticClass: "ib jb-fltr-7 jb-ls-srch" },
                              [
                                _c(VAutocomplete, {
                                  staticClass: "agd-dropdown",
                                  attrs: {
                                    label: "Implement",
                                    items: _vm.teamJobsFilterImplementsList,
                                    "search-input":
                                      _vm.teamJobsFilterImplementsSearchText,
                                    loading:
                                      _vm.teamJobsImplementsDropdownLoader,
                                    clearable: "",
                                    "item-text": "name",
                                    "item-value": "id",
                                    "hide-no-data": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    "update:searchInput": function ($event) {
                                      _vm.teamJobsFilterImplementsSearchText =
                                        $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.teamJobsFilterImplementsSearchText =
                                        $event
                                    },
                                    "click:clear":
                                      _vm.applyImplementFilterSearchTimer,
                                    focus: _vm.applyImplementFilterSearchTimer,
                                    change: function ($event) {
                                      return _vm.getJobsByImplement()
                                    },
                                  },
                                  nativeOn: {
                                    input: function ($event) {
                                      return _vm.applyImplementFilterSearchTimer(
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-item",
                                        fn: function () {
                                          return [
                                            _c("LoadMoreItem", {
                                              attrs: {
                                                searchText:
                                                  _vm.teamJobsFilterImplementsSearchText,
                                                loadedListLength:
                                                  _vm
                                                    .teamJobsFilterImplementsList
                                                    .length,
                                                moduleNamespace:
                                                  _vm.$stringConstants(
                                                    "teamJobsFilterImplementsPaginationModule"
                                                  ),
                                              },
                                              on: {
                                                loadMoreItems: function (
                                                  $event
                                                ) {
                                                  return _vm.appendMoreItems(
                                                    "implements"
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4023536611
                                  ),
                                  model: {
                                    value: _vm.teamJobsFilterImplementValue,
                                    callback: function ($$v) {
                                      _vm.teamJobsFilterImplementValue = $$v
                                    },
                                    expression: "teamJobsFilterImplementValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.visibleFilters.product
                          ? _c(
                              "div",
                              { staticClass: "ib jb-fltr-7 jb-ls-srch" },
                              [
                                _c(VAutocomplete, {
                                  staticClass: "agd-dropdown",
                                  attrs: {
                                    label: "Product",
                                    items: _vm.teamJobsFilterProductsList,
                                    "search-input":
                                      _vm.teamJobsFilterProductsSearchText,
                                    loading: _vm.teamJobsProductsDropdownLoader,
                                    clearable: "",
                                    "item-text": "name",
                                    "item-value": "id",
                                    "hide-no-data": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    "update:searchInput": function ($event) {
                                      _vm.teamJobsFilterProductsSearchText =
                                        $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.teamJobsFilterProductsSearchText =
                                        $event
                                    },
                                    "click:clear":
                                      _vm.applyProductFilterSearchTimer,
                                    focus: _vm.applyProductFilterSearchTimer,
                                    change: function ($event) {
                                      return _vm.getJobsByProduct()
                                    },
                                  },
                                  nativeOn: {
                                    input: function ($event) {
                                      return _vm.applyProductFilterSearchTimer(
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-item",
                                        fn: function () {
                                          return [
                                            _c("LoadMoreItem", {
                                              attrs: {
                                                searchText:
                                                  _vm.teamJobsFilterProductsSearchText,
                                                loadedListLength:
                                                  _vm.teamJobsFilterProductsList
                                                    .length,
                                                moduleNamespace:
                                                  _vm.$stringConstants(
                                                    "teamJobsFilterProductsPaginationModule"
                                                  ),
                                              },
                                              on: {
                                                loadMoreItems: function (
                                                  $event
                                                ) {
                                                  return _vm.appendMoreItems(
                                                    "products"
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2217644963
                                  ),
                                  model: {
                                    value: _vm.teamJobsFilterProductValue,
                                    callback: function ($$v) {
                                      _vm.teamJobsFilterProductValue = $$v
                                    },
                                    expression: "teamJobsFilterProductValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.visibleFilters.dateRange &&
                        _vm.jobViewType == "List"
                          ? _c("div", { staticClass: "ib jb-fltr-8" }, [
                              _c("div", { staticClass: "ib" }, [
                                _c(
                                  "div",
                                  { staticClass: "jb-sld-pos" },
                                  [
                                    _c("datePicker", {
                                      ref: "datePicker",
                                      class: _vm.teamJobsDateFilterApplied
                                        ? "active"
                                        : "",
                                      attrs: {
                                        dateInput: _vm.dateInput,
                                        format: _vm.format,
                                        sameDateFormat: _vm.sameDateFormat,
                                        "switch-button-initial": true,
                                        "show-helper-buttons": false,
                                        language: "en",
                                      },
                                      on: {
                                        "date-applied":
                                          _vm.filterJobsByDateRange,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm.teamJobsDateFilterApplied
                                ? _c("div", { staticClass: "ib" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "jb-clr",
                                        staticStyle: {
                                          transform: "translate(20px, -4px)",
                                        },
                                        on: { click: _vm.clearJobsDateFilter },
                                      },
                                      [
                                        _c(VIcon, { attrs: { small: "" } }, [
                                          _vm._v(" mdi-close-thick "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.jobViewType == "List"
          ? _c("List", {
              attrs: { listItems: _vm.teamJobsList, preserveListState: "" },
              on: {
                setFilterOptionsAndGetListData: _vm.setFilterOptionsAndGetJobs,
                listItemClick: _vm.openDetailsJob,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "list-heading",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "ib width-perc-8 item-no-res" },
                          [_vm._v(_vm._s(_vm.$stringConstants("jobNo")) + ".")]
                        ),
                        _c("div", { staticClass: "ib width-perc-12" }, [
                          _vm._v(_vm._s(_vm.$stringConstants("operation"))),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.loginUserRole ==
                                    _vm.UserRoleType.Contractor ||
                                  (_vm.loginUserRole ==
                                    _vm.UserRoleType.Manager &&
                                    _vm.UserRoleType[_vm.ownerRole] ==
                                      _vm.UserRoleType.Contractor),
                                expression:
                                  "loginUserRole == UserRoleType.Contractor || (loginUserRole == UserRoleType.Manager && UserRoleType[ownerRole] == UserRoleType.Contractor)",
                              },
                            ],
                            staticClass: "ib width-perc-15",
                          },
                          [_vm._v(_vm._s(_vm.$stringConstants("customer")))]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.loginUserRole ==
                                    _vm.UserRoleType.Farmer ||
                                  (_vm.loginUserRole ==
                                    _vm.UserRoleType.Manager &&
                                    _vm.UserRoleType[_vm.ownerRole] ==
                                      _vm.UserRoleType.Farmer),
                                expression:
                                  "loginUserRole == UserRoleType.Farmer || (loginUserRole == UserRoleType.Manager &&  UserRoleType[ownerRole] == UserRoleType.Farmer)",
                              },
                            ],
                            staticClass: "ib width-perc-15",
                          },
                          [_vm._v(_vm._s(_vm.$stringConstants("contractor")))]
                        ),
                        _c("div", { staticClass: "ib width-perc-12" }, [
                          _vm._v(_vm._s(_vm.$stringConstants("scheduledFor"))),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "ib",
                            class: _vm.checkLoginUserType()
                              ? "width-perc-12"
                              : "width-perc-15",
                          },
                          [_vm._v(_vm._s(_vm.$stringConstants("fields")))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ib",
                            class: _vm.checkLoginUserType()
                              ? "width-perc-12"
                              : "width-perc-13",
                          },
                          [_vm._v(_vm._s(_vm.$stringConstants("operators")))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ib",
                            class: _vm.checkLoginUserType()
                              ? "width-perc-10"
                              : "width-perc-13",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("billableQuantity"))
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "ib width-perc-12" }, [
                          _vm._v(_vm._s(_vm.$stringConstants("status"))),
                        ]),
                        _vm.loginUserRole == _vm.UserRoleType.Contractor ||
                        (_vm.loginUserRole == _vm.UserRoleType.Manager &&
                          _vm.UserRoleType[_vm.ownerRole] ==
                            _vm.UserRoleType.Contractor)
                          ? _c("div", { staticClass: "ib width-perc-7" }, [
                              _c("div", { staticClass: "pl-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$stringConstants("invoice")) +
                                    " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "list-item",
                    fn: function (ref) {
                      var listItem = ref.listItem
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ib width-perc-8 fw-7 pr-6 item-no-res",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getJobNumberPrefix(listItem.number))
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.getJobNumberSuffix(listItem.number)) +
                                " "
                            ),
                          ]
                        ),
                        listItem.operation != null
                          ? _c(
                              "div",
                              { staticClass: "ib width-perc-12 pr-6" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "word-break",
                                    attrs: { title: listItem.operation.title },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getShortOperationName(
                                            listItem.operation.title
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "ib width-perc-12 pr-6" },
                              [_vm._v("-")]
                            ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.loginUserRole ==
                                    _vm.UserRoleType.Contractor ||
                                  (_vm.loginUserRole ==
                                    _vm.UserRoleType.Manager &&
                                    _vm.UserRoleType[_vm.ownerRole] ==
                                      _vm.UserRoleType.Contractor),
                                expression:
                                  "loginUserRole == UserRoleType.Contractor || (loginUserRole == UserRoleType.Manager && UserRoleType[ownerRole] == UserRoleType.Contractor)",
                              },
                            ],
                            staticClass: "ib width-perc-15 pr-6",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "word-break",
                                attrs: {
                                  title: listItem.customer.businessName,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      listItem.customer != null
                                        ? _vm.getShortCustomerName(
                                            listItem.customer.businessName
                                          )
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.loginUserRole ==
                                    _vm.UserRoleType.Farmer ||
                                  (_vm.loginUserRole ==
                                    _vm.UserRoleType.Manager &&
                                    _vm.UserRoleType[_vm.ownerRole] ==
                                      _vm.UserRoleType.Farmer),
                                expression:
                                  "loginUserRole == UserRoleType.Farmer || (loginUserRole == UserRoleType.Manager &&  UserRoleType[ownerRole] == UserRoleType.Farmer)",
                              },
                            ],
                            staticClass: "ib width-perc-15 pr-6",
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: {
                                  title: listItem.contractor.businessName,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      listItem.contractor != null
                                        ? _vm.getShortCustomerName(
                                            listItem.contractor.businessName
                                          )
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "ib width-perc-12 pr-6" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                listItem.scheduledAt != null
                                  ? _vm.getDateTimeFormat(listItem.scheduledAt)
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "ib fld-ls-brk",
                            class:
                              "pr-6 " +
                              (_vm.checkLoginUserType()
                                ? "width-perc-12"
                                : "width-perc-15"),
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  listItem.fields.length != 0
                                    ? listItem.fields[0].title
                                    : "-"
                                ) +
                                " "
                            ),
                            listItem.fields.length > 1
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#1d34ad" } },
                                  [
                                    _vm._v(
                                      " + " + _vm._s(listItem.fields.length - 1)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ib",
                            class:
                              "pr-6 " +
                              (_vm.checkLoginUserType()
                                ? "width-perc-12"
                                : "width-perc-13"),
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.getOperatorNames(listItem)) + " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ib",
                            class:
                              "pr-6 " +
                              (_vm.checkLoginUserType()
                                ? "width-perc-10"
                                : "width-perc-13"),
                          },
                          [
                            listItem.operation.billingQuantities.length != 0
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getBillingQuantity(
                                          listItem.operation
                                            .billingQuantities[0]
                                            .billingQuantity
                                        )
                                      ) +
                                      " (" +
                                      _vm._s(listItem.operation.billingUnit) +
                                      ") "
                                  ),
                                ])
                              : _c("div", [_vm._v(" - ")]),
                          ]
                        ),
                        _c("div", { staticClass: "ib width-perc-12 pr-6" }, [
                          listItem.status == _vm.TeamJobStatusType.Pending &&
                          listItem.contractorApprovalStatus !=
                            _vm.ApprovalStatusType.Declined
                            ? _c("div", { staticClass: "j-sdul" }, [
                                _c("span", [
                                  _c("img", {
                                    staticClass: "status-icon",
                                    attrs: {
                                      src: require("../../../assets/job-Pending.svg"),
                                    },
                                  }),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "pl-2 status-v-middle" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.TeamJobStatusTypeLabel.get(
                                          _vm.TeamJobStatusType.Pending
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          listItem.status == _vm.TeamJobStatusType.Ready &&
                          listItem.contractorApprovalStatus !=
                            _vm.ApprovalStatusType.Declined
                            ? _c("div", { staticClass: "j-ready" }, [
                                _c("span", [
                                  _c("img", {
                                    staticClass: "status-icon",
                                    staticStyle: { width: "14px" },
                                    attrs: {
                                      src: require("../../../assets/job-Ready-new.svg"),
                                    },
                                  }),
                                ]),
                                listItem.scheduledAt
                                  ? _c(
                                      "span",
                                      { staticClass: "pl-2 status-v-middle" },
                                      [_vm._v("Scheduled")]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "pl-2 status-v-middle" },
                                      [_vm._v("Unscheduled")]
                                    ),
                              ])
                            : _vm._e(),
                          listItem.status == _vm.TeamJobStatusType.InProgress
                            ? _c("div", { staticClass: "j-pro" }, [
                                _c("span", [
                                  _c("img", {
                                    staticClass: "status-icon",
                                    attrs: {
                                      src: require("../../../assets/job-Inprogress.svg"),
                                    },
                                  }),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "pl-2 status-v-middle" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.TeamJobStatusTypeLabel.get(
                                          _vm.TeamJobStatusType.InProgress
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          listItem.status ==
                          _vm.TeamJobStatusType.AwaitingCompletion
                            ? _c("div", { staticClass: "j-fns" }, [
                                _c("span", [
                                  _c("img", {
                                    staticClass: "status-icon",
                                    staticStyle: { width: "14px" },
                                    attrs: {
                                      src: require("../../../assets/job-complete.svg"),
                                    },
                                  }),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "pl-2 status-v-middle" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.TeamJobStatusTypeLabel.get(
                                          _vm.TeamJobStatusType
                                            .AwaitingCompletion
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          listItem.status == _vm.TeamJobStatusType.PartCompleted
                            ? _c("div", { staticClass: "j-fns" }, [
                                _c("span", [
                                  _c("img", {
                                    staticClass: "status-icon",
                                    attrs: {
                                      src: require("../../../assets/job-complete.svg"),
                                    },
                                  }),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "pl-2 status-v-middle" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.TeamJobStatusTypeLabel.get(
                                          _vm.TeamJobStatusType.PartCompleted
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          listItem.status == _vm.TeamJobStatusType.Completed
                            ? _c("div", { staticClass: "j-fns" }, [
                                _c("span", [
                                  _c("img", {
                                    staticClass: "status-icon",
                                    attrs: {
                                      src: require("../../../assets/job-finish.svg"),
                                    },
                                  }),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "pl-2 status-v-middle" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.TeamJobStatusTypeLabel.get(
                                          _vm.TeamJobStatusType.Completed
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "13px",
                                      color: "#000000",
                                      "font-weight": "100",
                                      "padding-left": "22px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getJobCompletionDate(
                                            listItem.workCompletedOn
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          listItem.status == _vm.TeamJobStatusType.Cancelled
                            ? _c("div", [
                                _c("span", [
                                  _c("img", {
                                    staticClass: "status-icon",
                                    attrs: {
                                      src: require("../../../assets/job-Declined.svg"),
                                    },
                                  }),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "pl-2 status-v-middle",
                                    staticStyle: { color: "#fa4e4e" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.TeamJobStatusTypeLabel.get(
                                          _vm.TeamJobStatusType.Cancelled
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          listItem.status <= _vm.TeamJobStatusType.Ready &&
                          listItem.contractorApprovalStatus ==
                            _vm.ApprovalStatusType.Declined
                            ? _c("div", [
                                _c("span", [
                                  _c("img", {
                                    staticClass: "status-icon",
                                    attrs: {
                                      src: require("../../../assets/job-Declined.svg"),
                                    },
                                  }),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "pl-2 status-v-middle",
                                    staticStyle: { color: "#fa4e4e" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$stringConstants("declinedText")
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.loginUserRole == _vm.UserRoleType.Contractor ||
                        (_vm.loginUserRole == _vm.UserRoleType.Manager &&
                          _vm.ownerRole ==
                            _vm.UserRoleType[_vm.UserRoleType.Contractor])
                          ? _c("div", { staticClass: "ib width-perc-7 pl-4" }, [
                              listItem.status ==
                                _vm.TeamJobStatusType.Completed &&
                              listItem.customer.id != _vm.getSenderId
                                ? _c("div", [
                                    listItem.isApprovedForInvoicing &&
                                    listItem.isInvoiced
                                      ? _c(
                                          "div",
                                          { staticClass: "j-fns" },
                                          [
                                            _vm.loginUserRole ===
                                            _vm.UserRoleType.Contractor
                                              ? _c("ListButton", {
                                                  staticStyle: {
                                                    color: "#07AB49 !important",
                                                    "caret-color":
                                                      "#07AB49 !important",
                                                  },
                                                  attrs: {
                                                    textValue:
                                                      _vm.$stringConstants(
                                                        "invoiceText"
                                                      ),
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.handleViewInvoice(
                                                        listItem
                                                      )
                                                    },
                                                  },
                                                })
                                              : _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$stringConstants(
                                                        "invoiceText"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                          },
                                          [
                                            listItem.isApprovedForInvoicing
                                              ? _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$stringConstants(
                                                        "approvedText"
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _c("div", [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("ListButton", {
                                                        attrs: {
                                                          textValue: "Approve",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.approveJobForInvoice(
                                                              listItem.id
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                          ]
                                        ),
                                  ])
                                : _c("div", [_vm._v("-")]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "zero-state",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "mb-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$stringConstants("teamJobZeroStateTitle")
                            )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants(
                                  "teamJobZeroStateDescription"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2080780200
              ),
            })
          : _vm._e(),
        _vm.jobViewType == "Calendar" ? _c("TeamJobsCalendar") : _vm._e(),
        _c(
          VDialog,
          {
            attrs: { persistent: "", "max-width": "460" },
            model: {
              value: _vm.showExportDialog,
              callback: function ($$v) {
                _vm.showExportDialog = $$v
              },
              expression: "showExportDialog",
            },
          },
          [
            _c(
              VCard,
              { staticClass: "dlt-diag py-5" },
              [
                _c(VCardTitle, { staticClass: "headline" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("teamJobLoaderDialogTitle")) +
                      " "
                  ),
                ]),
                _c(
                  VCardText,
                  [
                    _c(VProgressCircular, {
                      staticStyle: {
                        transform: "translateY(-2px) translateX(-2px)",
                      },
                      attrs: {
                        indeterminate: "",
                        width: "2",
                        size: "18",
                        color: "#b3b3b3",
                      },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$stringConstants("teamJobLoaderDialogContent")
                        ) +
                        " (" +
                        _vm._s(_vm.exportDataLoaded) +
                        " / " +
                        _vm._s(_vm.exportDataTotal) +
                        ") "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          VDialog,
          {
            attrs: { persistent: "", "max-width": "400" },
            model: {
              value: _vm.showIntegrationPrompt,
              callback: function ($$v) {
                _vm.showIntegrationPrompt = $$v
              },
              expression: "showIntegrationPrompt",
            },
          },
          [
            _c(
              VCard,
              { staticClass: "dlt-diag px-3 pt-2 pb-3 d-flex flex-column" },
              [
                _c(VCardTitle, { staticClass: "px-0" }, [
                  _vm._v(" Integrate with Xero? "),
                ]),
                _c(VCardText, { staticClass: "px-0" }, [
                  _vm._v(
                    " Account associated with Xero found. Do you want to integrate? "
                  ),
                ]),
                _c(
                  VCardActions,
                  { staticClass: "py-2 px-0" },
                  [
                    _c("Button", {
                      attrs: {
                        variant: "secondary",
                        color: "primary",
                        textValue: "Yes",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.connectToXero()
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "pl-2 m2-2",
                      attrs: {
                        variant: "secondary",
                        color: "primary",
                        text: "",
                        outlined: "",
                        textValue: "No",
                      },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showIntegrationPrompt = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }