var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "su-counter-title pb-4" }, [
      _vm._v(" " + _vm._s(_vm.counterTitle) + " "),
    ]),
    _c("div", { staticClass: "su-counter-value" }, [
      _vm._v(" " + _vm._s(_vm.counterValue) + " "),
    ]),
    _c(
      "div",
      {
        staticClass: "su-counter-summary pt-2",
        staticStyle: { height: "25px" },
      },
      [_vm._v(" " + _vm._s(_vm.counterSummary) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }