var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "44",
        height: "44",
        viewBox: "0 0 44 44",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("operator")]),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "officer",
                transform: "translate(1.000000, 1.000000)",
              },
            },
            [
              _c("circle", {
                attrs: {
                  id: "Oval",
                  stroke: _vm.getFillColor(),
                  "stroke-width": "0.1",
                  fill: _vm.getFillColor(),
                  "fill-rule": "nonzero",
                  cx: "32",
                  cy: "5",
                  r: "6",
                },
              }),
              _c("circle", {
                attrs: {
                  cx: "32",
                  cy: "27",
                  r: "11",
                  stroke: _vm.getFillColor(),
                  "stroke-width": "1",
                  fill: _vm.getFillColor(),
                },
              }),
              _c(
                "text",
                {
                  attrs: {
                    id: "1234567",
                    "font-family": "Helvetica, Arial, sans-serif",
                    "font-size": "11",
                    "font-weight": "400",
                    fill: "#ffffff",
                  },
                },
                [
                  _c(
                    "tspan",
                    { attrs: { "text-anchor": "middle", x: "32", y: "31" } },
                    [_vm._v(_vm._s(_vm.initials))]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }