import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';
import PersonalDetails from './internal-contact-personal-details.vue';
import BusinessDetails from './internal-contact-business-details.vue';
import fieldDetails from './internal-contact-field-details.vue';
import farmsDetails from './internal-contact-farms-list.vue';
import onGoingJobsView from './internal-contact-ongoing-jobs.vue';
import { mapMutations } from 'vuex';
export default {
  mixins: [contactComputedMixin, breadcrumbsMixin],
  components: {
    PersonalDetails: PersonalDetails,
    BusinessDetails: BusinessDetails,
    fieldDetails: fieldDetails,
    ListLoader: ListLoader,
    onGoingJobsView: onGoingJobsView,
    farmsDetails: farmsDetails
  },
  computed: {
    resId: function resId() {
      return window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    }
  },
  mounted: function mounted() {
    this.resetContactProfile();
    this.contactFieldList = [];
    this.$store.dispatch("getGlobalCountriesList");
    this.$store.dispatch('getContactDetailsByAdmin', this.resId);
    this.$store.dispatch('getContactFarmsDetailsByAdmin', this.resId);
    this.$store.dispatch('getContactFieldsDetailsByAdmin', this.resId);
    this.$store.dispatch('getContactOnGoingJobsByAdmin', this.resId);
    this.setBreadcrumbs('CustomerDetails');
  },
  methods: _objectSpread({}, mapMutations({
    resetContactProfile: 'resetContactProfile',
    clearFilterOptions: "setClearFilterOptions"
  }))
};