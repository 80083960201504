import "core-js/modules/es.array.push.js";
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import labelAndDetailsReadonly from '@/components/shared/readonly/labelAndDetails.vue';
import { routeCustomersUpdate, routeCustomers } from '@/utils/endpoints';
import router from '@/router/index';
import { DeleteItemDialog } from '@/components/shared/dialogs/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { buttonDeleteColor } from '@/utils/uiconstants';
export default {
  name: 'ContactPersonalDetailsForm',
  mixins: [contactComputedMixin],
  data: function data() {
    return {
      farmerId: null,
      dialogFarmer: false,
      menuColor: false,
      buttonDeleteColor: buttonDeleteColor
    };
  },
  computed: {
    swatchStyle: function swatchStyle() {
      var menuColor = this.menuColor;
      return {
        backgroundColor: this.contactProfile.colorCode,
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: menuColor ? "50%" : "50px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  },
  components: {
    SectionLeftHeadings: SectionLeftHeadings,
    labelAndDetailsReadonly: labelAndDetailsReadonly,
    DeleteItemDialog: DeleteItemDialog,
    Button: Button
  },
  methods: {
    openContactEditForm: function openContactEditForm(id) {
      router.push(routeCustomersUpdate + '/' + id);
    },
    confirmDeleteFarmer: function confirmDeleteFarmer(id) {
      this.farmerId = id;
      this.dialogFarmer = true;
    },
    confirmRestoreFarmer: function confirmRestoreFarmer(id) {
      this.farmerId = id;
      this.dialogFarmer = true;
    },
    proceedActionOnFarmer: function proceedActionOnFarmer() {
      this.dialogFarmer = false;
      if (this.contactProfile.isArchived) {
        this.$store.dispatch('restoreFarmer', this.farmerId).then(function (res) {
          router.push(routeCustomers);
        });
      } else {
        this.$store.dispatch('deleteFarmer', this.farmerId).then(function (res) {
          router.push(routeCustomers);
        });
      }
    },
    updateCustomerColor: function updateCustomerColor() {
      var colorCode = {
        colourCode: this.contactProfile.colorCode
      };
      this.$store.dispatch('updateExternalCustomerColor', [this.contactProfile.id, colorCode]);
    }
  }
};