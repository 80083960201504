import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import $ from 'jquery';
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
import { clearDateTimePicker } from '@/utils/helpers';
var computedProperties = {
  'adminOverviewModule': ['adminOverviewRecentStatistics', 'adminOverviewOverallStatistics', 'adminOverviewCurrentStatisticsDateFilterApplied', 'adminOverviewRecentStatisticsStartDate', 'adminOverviewRecentStatisticsEndDate', 'adminRecentOverviewLoader', 'adminOverallOverviewLoader'],
  'root': ['isRoutedFromReferencePage']
};
export default {
  name: 'AdminOverviewComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    getSenderId: function getSenderId() {
      return localStorage.getItem(ConstantValues.ownerId);
    },
    currencySymbol: function currencySymbol() {
      return localStorage.getItem("currencySymbol");
    }
  }),
  methods: {
    clearCurrentStatisticsDateFilter: function clearCurrentStatisticsDateFilter() {
      $('.vdpr-datepicker input[type=text]').first().val('');
      clearDateTimePicker(this.$refs.datePicker.$el);
      this.adminOverviewRecentStatisticsStartDate = null;
      this.adminOverviewRecentStatisticsEndDate = null;
      this.adminOverviewCurrentStatisticsDateFilterApplied = false;
      this.adminOverviewRecentStatisticsStartDate = null;
      this.adminOverviewRecentStatisticsEndDate = null;
      this.refreshCurrentStatistics();
    }
  }
};