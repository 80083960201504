import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "pt-10", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants("personalDetailsTitle1"),
                  textValue2: _vm.$stringConstants(
                    "customerPersonalDetailsSubTitle"
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "7" } },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$stringConstants("name")) + " * "
                        ),
                      ]),
                      _c(VTextField, {
                        class: _vm.formFieldPb,
                        attrs: {
                          placeholder: _vm.$stringConstants("namePlaceholder"),
                          outlined: "",
                          required: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.contactProfile.firstName,
                          callback: function ($$v) {
                            _vm.$set(_vm.contactProfile, "firstName", $$v)
                          },
                          expression: "contactProfile.firstName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0 pr-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("emailAddress")) +
                            " "
                        ),
                      ]),
                      _c(VTextField, {
                        class: _vm.formFieldPb,
                        attrs: {
                          placeholder: _vm.$stringConstants("emailPlaceholder"),
                          "error-messages": _vm.generateEmailValidationErrors(),
                          outlined: "",
                          dense: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.contactEmail.$touch()
                          },
                          blur: function ($event) {
                            return _vm.$v.contactEmail.$touch()
                          },
                        },
                        model: {
                          value: _vm.contactProfile.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.contactProfile, "email", $$v)
                          },
                          expression: "contactProfile.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("phoneNumber")) +
                            " "
                        ),
                      ]),
                      _c(VTextField, {
                        class: _vm.formFieldPb,
                        attrs: {
                          placeholder: _vm.$stringConstants(
                            "phoneNumberPlaceholder"
                          ),
                          outlined: "",
                          dense: "",
                        },
                        on: {
                          keydown: function ($event) {
                            return _vm.checkPhoneNumber($event)
                          },
                        },
                        model: {
                          value: _vm.contactProfile.primaryContactNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactProfile,
                              "primaryContactNumber",
                              $$v
                            )
                          },
                          expression: "contactProfile.primaryContactNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.contactProfile.id
                    ? _c(
                        VCol,
                        { staticClass: "py-0 pr-2", attrs: { cols: "6" } },
                        [
                          _c("div", { staticClass: "field-input-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$stringConstants("defaultFieldColor")
                              ) + " *"
                            ),
                          ]),
                          _c(VTextField, {
                            staticClass: "ma-0 pa-0 pb-2",
                            attrs: { outlined: "", readonly: "", dense: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        VMenu,
                                        {
                                          attrs: {
                                            top: "",
                                            "nudge-bottom": "105",
                                            "nudge-left": "16",
                                            "close-on-content-click": false,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        {
                                                          style:
                                                            _vm.swatchStyle,
                                                        },
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1889209126
                                          ),
                                          model: {
                                            value: _vm.menuColor,
                                            callback: function ($$v) {
                                              _vm.menuColor = $$v
                                            },
                                            expression: "menuColor",
                                          },
                                        },
                                        [
                                          _c(
                                            VCard,
                                            [
                                              _c(
                                                VCardText,
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(VColorPicker, {
                                                    attrs: {
                                                      "hide-mode-switch": "",
                                                      mode: "hexa",
                                                      flat: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactProfile
                                                          .colorCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactProfile,
                                                          "colorCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactProfile.colorCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2451919154
                            ),
                            model: {
                              value: _vm.contactProfile.colorCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contactProfile,
                                  "colorCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contactProfile.colorCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }