export default {
  name: "CalendarDay",
  props: {
    date: Date,
    isDropZoneActive: Boolean
  },
  methods: {
    addDropZoneHighlight: function addDropZoneHighlight() {
      this.$refs.calendarDay.style.boxShadow = '0 0 50px 25px rgba(0, 0, 0, 0.1) inset';
      this.$refs.calendarDay.style.zIndex = 1;
    },
    removeDropZoneHighlight: function removeDropZoneHighlight() {
      this.$refs.calendarDay.style.boxShadow = 'none';
      this.$refs.calendarDay.style.zIndex = 'auto';
    },
    handleDragEnter: function handleDragEnter(e) {
      var jobId = e.dataTransfer.getData('application/job-id');
      var draggedJob = document.getElementById("".concat(jobId));
      if (draggedJob) {
        var draggedJobFromDayId = draggedJob.parentElement.closest('.v-calendar-weekly__day').querySelector('.calendar-day').getAttribute('id');
        if (this.$attrs.id === draggedJobFromDayId) return;
      }
      this.addDropZoneHighlight();
    },
    handleDragOver: function handleDragOver(e) {
      // console.log(e);
    },
    handleDragLeave: function handleDragLeave(e) {
      this.removeDropZoneHighlight();
    },
    handleDrop: function handleDrop(e) {
      var jobId = e.dataTransfer.getData('application/job-id');
      if (jobId) {
        var draggedJob = document.getElementById("".concat(jobId)).parentElement;
        var draggedJobFromDay = draggedJob.closest('.v-calendar-weekly__day');
        var draggedJobFromDayId = draggedJobFromDay.querySelector('.calendar-day').getAttribute('id');
        var droppedJobOnDayId = this.$attrs.id;
        if (draggedJobFromDayId === droppedJobOnDayId) return;
        this.$emit('update-job', jobId);
      }
      this.removeDropZoneHighlight();
    }
  }
};