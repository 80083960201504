import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.subOperationLoader
      ? _c("div", [
          _c(
            "div",
            { staticClass: "b-btm py-5 px-9" },
            [
              _c(
                VRow,
                {
                  staticClass: "fn-20",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(
                    VCol,
                    { attrs: { cols: "8" } },
                    [
                      _c("HeaderPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "subOperationEditTitle"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [
                      _c(
                        VIcon,
                        {
                          attrs: { small: "" },
                          on: { click: _vm.closeEditSubOperationsForm },
                        },
                        [_vm._v(" mdi-close-thick ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-scroll-view" },
            [
              _c(
                VContainer,
                {
                  staticClass: "pb-0 pt-7",
                  class: [_vm.containerLeftPad, _vm.containerRightPad],
                },
                [
                  _c(
                    "div",
                    { staticClass: "pb-2" },
                    [
                      _c("SubHeaderPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "subOperationEditSubtitle"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pt-1 pb-7" },
                    [
                      _c("DescriptionPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "subOperationEditDescription"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$stringConstants("fieldLabelName")) + " "
                    ),
                  ]),
                  _c(VTextField, {
                    attrs: { outlined: "", dense: "", required: "" },
                    model: {
                      value: _vm.subOperationName,
                      callback: function ($$v) {
                        _vm.subOperationName = $$v
                      },
                      expression: "subOperationName",
                    },
                  }),
                  _c(
                    VRow,
                    { staticClass: "pb-1" },
                    [
                      _c(
                        VCol,
                        { staticClass: "pl-0", attrs: { cols: "6" } },
                        [
                          _c("div", { staticClass: "field-input-label" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants("fieldLabelBillingCost")
                                ) +
                                " "
                            ),
                          ]),
                          _c(VTextField, {
                            attrs: {
                              outlined: "",
                              type: "number",
                              min: 1,
                              dense: "",
                              required: "",
                              prefix: _vm.currencySymbol,
                            },
                            on: {
                              keydown: function ($event) {
                                return _vm.negativeKeydown($event)
                              },
                            },
                            model: {
                              value: _vm.subOperationBillingCost,
                              callback: function ($$v) {
                                _vm.subOperationBillingCost = _vm._n($$v)
                              },
                              expression: "subOperationBillingCost",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "pr-0", attrs: { cols: "6" } },
                        [
                          _c("div", { staticClass: "field-input-label" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$stringConstants("fieldLabelUnit")) +
                                " "
                            ),
                          ]),
                          _c(VSelect, {
                            attrs: {
                              items: _vm.enumListOperationUnits,
                              "item-value": "value",
                              "item-text": "title",
                              outlined: "",
                              dense: "",
                              attach: "",
                            },
                            model: {
                              value: _vm.subOperationBillingUnit,
                              callback: function ($$v) {
                                _vm.subOperationBillingUnit = $$v
                              },
                              expression: "subOperationBillingUnit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.ownerRole == _vm.UserRoleType[_vm.UserRoleType.Contractor]
                    ? _c(
                        "div",
                        [
                          _c(VCheckbox, {
                            staticClass: "pt-0 pb-0 mt-0 mb-2 d-inline-block",
                            attrs: {
                              label: _vm.$stringConstants(
                                "subOperationTaxText"
                              ),
                            },
                            on: { change: _vm.checkTaxList },
                            model: {
                              value: _vm.subOperationIsTaxable,
                              callback: function ($$v) {
                                _vm.subOperationIsTaxable = $$v
                              },
                              expression: "subOperationIsTaxable",
                            },
                          }),
                          _vm.taxValuesListForInvoice.length !== 0 &&
                          _vm.subOperationIsTaxable
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    { staticClass: "field-input-label" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.taxName) + " (%) "
                                      ),
                                    ]
                                  ),
                                  _c(VCombobox, {
                                    staticClass: "pb-1",
                                    attrs: {
                                      items: _vm.taxValuesListForInvoice,
                                      "item-text": "rateInPercentage",
                                      "item-value": "rateInPercentage",
                                      readonly: !_vm.subOperationIsTaxable,
                                      disabled: !_vm.subOperationIsTaxable,
                                      placeholder: "None",
                                      "return-object": false,
                                      dense: "",
                                      required: "",
                                      outlined: "",
                                      attach: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function (data) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data
                                                      ? _vm.subOperationTaxPercentage
                                                      : ""
                                                  ) +
                                                  _vm._s(
                                                    _vm.$stringConstants(
                                                      "percentageSymbol"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item",
                                          fn: function (data) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getRateNameAndValue(
                                                      data.item.rateName,
                                                      data.item
                                                        .rateInPercentage,
                                                      _vm.subOperationTaxPercentage
                                                    )
                                                  ) +
                                                  _vm._s(
                                                    _vm.$stringConstants(
                                                      "percentageSymbol"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3672683331
                                    ),
                                    model: {
                                      value: _vm.subOperationTaxPercentage,
                                      callback: function ($$v) {
                                        _vm.subOperationTaxPercentage = $$v
                                      },
                                      expression: "subOperationTaxPercentage",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$stringConstants("fieldLabelVehicles")) +
                        " "
                    ),
                  ]),
                  _c(VAutocomplete, {
                    ref: "vehicleFieldEditRef",
                    staticClass: "pb-1",
                    attrs: {
                      items: _vm.subOperationVehiclesList,
                      "search-input": _vm.subOperationVehiclesSearchText,
                      loading: _vm.vehiclesLoading,
                      "item-text": "title",
                      "item-value": "id",
                      placeholder: _vm.$stringConstants(
                        "selectVehiclePlaceholder"
                      ),
                      multiple: "",
                      outlined: "",
                      clearable: "",
                      dense: "",
                      chips: "",
                      "deletable-chips": "",
                      flat: "",
                      solo: "",
                      attach: "",
                      "hide-no-data": !_vm.noVehicles,
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.subOperationVehiclesSearchText = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.subOperationVehiclesSearchText = $event
                      },
                      focus: _vm.getSubOperationVehiclesListAsync,
                      blur: function ($event) {
                        return _vm.handleDropdownBlur(
                          $event,
                          _vm.ResourceType.Vehicle
                        )
                      },
                    },
                    nativeOn: {
                      input: function ($event) {
                        return _vm.applySearchTimer(
                          _vm.searchSubOperationVehicles
                        )
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append-item",
                          fn: function () {
                            return [
                              _c("LoadMoreItem", {
                                attrs: {
                                  searchText:
                                    _vm.subOperationVehiclesSearchText,
                                  loadedListLength:
                                    _vm.subOperationVehiclesList.length,
                                  moduleNamespace: _vm.$stringConstants(
                                    "subOperationVehiclesDropdownModule"
                                  ),
                                },
                                on: {
                                  loadMoreItems: function ($event) {
                                    return _vm.refocusAndAppendMoreResources(
                                      _vm.$options.ResourceType.Vehicle,
                                      _vm.$refs.vehicleFieldEditRef
                                    )
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      527157253
                    ),
                    model: {
                      value: _vm.subOperationVehicles,
                      callback: function ($$v) {
                        _vm.subOperationVehicles = $$v
                      },
                      expression: "subOperationVehicles",
                    },
                  }),
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$stringConstants("fieldLabelImplements")) +
                        " "
                    ),
                  ]),
                  _c(VAutocomplete, {
                    ref: "implementFieldEditRef",
                    staticClass: "pb-1",
                    attrs: {
                      items: _vm.subOperationImplementsList,
                      "search-input": _vm.subOperationImplementsSearchText,
                      loading: _vm.implementsLoading,
                      "item-text": "title",
                      "item-value": "id",
                      placeholder: _vm.$stringConstants(
                        "selectImplementPlaceholder"
                      ),
                      multiple: "",
                      outlined: "",
                      clearable: "",
                      dense: "",
                      chips: "",
                      "deletable-chips": "",
                      flat: "",
                      solo: "",
                      attach: "",
                      "hide-no-data": !_vm.noImplements,
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.subOperationImplementsSearchText = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.subOperationImplementsSearchText = $event
                      },
                      focus: _vm.getSubOperationImplementsListAsync,
                      blur: function ($event) {
                        return _vm.handleDropdownBlur(
                          $event,
                          _vm.ResourceType.Implement
                        )
                      },
                    },
                    nativeOn: {
                      input: function ($event) {
                        return _vm.applySearchTimer(
                          _vm.searchSubOperationImplements
                        )
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append-item",
                          fn: function () {
                            return [
                              _c("LoadMoreItem", {
                                attrs: {
                                  searchText:
                                    _vm.subOperationImplementsSearchText,
                                  loadedListLength:
                                    _vm.subOperationImplementsList.length,
                                  moduleNamespace: _vm.$stringConstants(
                                    "subOperationImplementsDropdownModule"
                                  ),
                                },
                                on: {
                                  loadMoreItems: function ($event) {
                                    return _vm.refocusAndAppendMoreResources(
                                      _vm.$options.ResourceType.Implement,
                                      _vm.$refs.implementFieldEditRef
                                    )
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      450237938
                    ),
                    model: {
                      value: _vm.subOperationImplements,
                      callback: function ($$v) {
                        _vm.subOperationImplements = $$v
                      },
                      expression: "subOperationImplements",
                    },
                  }),
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$stringConstants("fieldLabelProducts")) +
                        " "
                    ),
                  ]),
                  _c(VAutocomplete, {
                    ref: "productFieldEditRef",
                    staticClass: "pb-1",
                    attrs: {
                      items: _vm.subOperationProductsList,
                      "search-input": _vm.subOperationProductsSearchText,
                      loading: _vm.productsLoading,
                      "item-text": "title",
                      "item-value": "id",
                      placeholder: _vm.$stringConstants(
                        "selectProductPlaceholder"
                      ),
                      multiple: "",
                      outlined: "",
                      clearable: "",
                      dense: "",
                      chips: "",
                      "deletable-chips": "",
                      flat: "",
                      solo: "",
                      attach: "",
                      "hide-no-data": !_vm.noProducts,
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.subOperationProductsSearchText = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.subOperationProductsSearchText = $event
                      },
                      focus: _vm.getSubOperationProductsListAsync,
                      blur: function ($event) {
                        return _vm.handleDropdownBlur(
                          $event,
                          _vm.ResourceType.Product
                        )
                      },
                    },
                    nativeOn: {
                      input: function ($event) {
                        return _vm.applySearchTimer(
                          _vm.searchSubOperationProducts
                        )
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append-item",
                          fn: function () {
                            return [
                              _c("LoadMoreItem", {
                                attrs: {
                                  searchText:
                                    _vm.subOperationProductsSearchText,
                                  loadedListLength:
                                    _vm.subOperationProductsList.length,
                                  moduleNamespace: _vm.$stringConstants(
                                    "subOperationProductsDropdownModule"
                                  ),
                                },
                                on: {
                                  loadMoreItems: function ($event) {
                                    return _vm.refocusAndAppendMoreResources(
                                      _vm.$options.ResourceType.Product,
                                      _vm.$refs.productFieldEditRef
                                    )
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3776587222
                    ),
                    model: {
                      value: _vm.subOperationProducts,
                      callback: function ($$v) {
                        _vm.subOperationProducts = $$v
                      },
                      expression: "subOperationProducts",
                    },
                  }),
                  _c("div", { staticClass: "pb-4 mb-2 f-lbl f-11 pt-2" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$stringConstants("loadInformaitionText"))
                      ),
                    ]),
                  ]),
                  _vm.subOperationLoadInfoList.length != 0
                    ? _c(
                        "div",
                        { staticClass: "pt-0" },
                        _vm._l(
                          _vm.subOperationLoadInfoList,
                          function (subOperationLoadInfo, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  VRow,
                                  [
                                    _c(
                                      VCol,
                                      {
                                        staticClass: "pt-0 px-0 pb-2",
                                        attrs: { cols: "11" },
                                      },
                                      [
                                        _c(
                                          VRow,
                                          [
                                            _c(
                                              VCol,
                                              {
                                                staticClass: "pa-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(VTextField, {
                                                  attrs: {
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      subOperationLoadInfo.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        subOperationLoadInfo,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "subOperationLoadInfo.title",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              VCol,
                                              {
                                                staticClass: "pa-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(VSelect, {
                                                  staticClass: "unit-dropdown",
                                                  attrs: {
                                                    items:
                                                      _vm.enumListLoadInfoUnits,
                                                    "item-value": "value",
                                                    "item-text": "title",
                                                    outlined: "",
                                                    dense: "",
                                                    attach: "",
                                                  },
                                                  model: {
                                                    value:
                                                      subOperationLoadInfo.unit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        subOperationLoadInfo,
                                                        "unit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "subOperationLoadInfo.unit",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      VCol,
                                      {
                                        staticClass: "pa-0",
                                        attrs: { cols: "1" },
                                      },
                                      [
                                        subOperationLoadInfo.title &&
                                        subOperationLoadInfo.title.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pl-4 pt-1 ib cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeSubOperationLoadInfo(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_c("ButtonCloseIcon")],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm.showAddLoadInfoFields
                    ? _c(
                        "div",
                        { staticClass: "pt-0 pb-0 pr-4 mb-1" },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                {
                                  staticClass: "pt-0 pb-2 mt-0 px-0",
                                  attrs: { cols: "9" },
                                },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.loadInfoName,
                                      callback: function ($$v) {
                                        _vm.loadInfoName = $$v
                                      },
                                      expression: "loadInfoName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  staticClass: "pa-0 mt-0",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _c(VSelect, {
                                    staticClass: "unit-dropdown",
                                    attrs: {
                                      items: _vm.enumListLoadInfoUnits,
                                      attach: "",
                                      "item-value": "value",
                                      "item-text": "title",
                                      outlined: "",
                                      placeholder: _vm.$stringConstants("unit"),
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.loadInfoUnit,
                                      callback: function ($$v) {
                                        _vm.loadInfoUnit = $$v
                                      },
                                      expression: "loadInfoUnit",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showAddLoadInfoFields
                    ? _c(
                        "div",
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                {
                                  staticClass:
                                    "pr-0 pt-0 pl-0 d-flex align-items-center",
                                  attrs: { cols: "9" },
                                },
                                [
                                  _c("Button", {
                                    attrs: {
                                      variant: "tertiary",
                                      color: "primary",
                                      textValue: "Add",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.addNewLoadInfo()
                                      },
                                    },
                                  }),
                                  _vm.loadInfoName &&
                                  _vm.loadInfoName.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "pl-4 ib cursor-pointer",
                                          on: { click: _vm.hideAddOperation },
                                        },
                                        [_c("ButtonCloseIcon")],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showAddLoadInfoBtn
                    ? _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { staticClass: "pt-0 pl-0", attrs: { cols: "4" } },
                            [
                              _c("Button", {
                                attrs: {
                                  variant: "secondary",
                                  outlined: "",
                                  outlineColor: "gray",
                                  textValue: "Add",
                                  leftIcon: "mdi-plus",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.showAddLoadInfo()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(VCol, {
                            staticClass: "pt-0",
                            attrs: { cols: "8" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "field-input-label pt-7" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$stringConstants("fieldLabelDescription")) +
                        " "
                    ),
                  ]),
                  _c(VTextarea, {
                    staticClass: "pt-0",
                    attrs: {
                      outlined: "",
                      "auto-grow": "",
                      name: "input-7-4",
                      "row-height": "16",
                    },
                    model: {
                      value: _vm.subOperationDescription,
                      callback: function ($$v) {
                        _vm.subOperationDescription = $$v
                      },
                      expression: "subOperationDescription",
                    },
                  }),
                ],
                1
              ),
              _c(VContainer, { staticClass: "pl-0 pr-0 pb-16 pt-0" }, [
                _c(
                  "div",
                  { staticClass: "pl-8 pr-8 pt-4 pb-4" },
                  [
                    _c(
                      "div",
                      { staticClass: "py-2" },
                      [
                        _c("SubHeaderPrimary", {
                          attrs: {
                            textValue: _vm.$stringConstants(
                              "subOperationSafetyChecklistTitle"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-1 pb-6" },
                      [
                        _c("DescriptionPrimary", {
                          attrs: {
                            textValue: _vm.$stringConstants(
                              "subOperationSafetyChecklistDescription"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.subOperationCheckListItems.length != 0
                      ? _c(
                          "div",
                          { staticClass: "pt-0" },
                          _vm._l(
                            _vm.subOperationCheckListItems,
                            function (subOperationCheckListItem, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "pt-0 pb-2 px-0",
                                          attrs: { cols: "11" },
                                        },
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { cols: "12 pa-0" } },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    subOperationCheckListItem.title,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      subOperationCheckListItem,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "subOperationCheckListItem.title",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "1" },
                                        },
                                        [
                                          subOperationCheckListItem.title &&
                                          subOperationCheckListItem.title
                                            .length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pt-1 pl-4 ib cursor-pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeSubOperationCheckListItem(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_c("ButtonCloseIcon")],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm.showAddCheckListItemFields
                      ? _c(
                          "div",
                          { staticClass: "py-0 pr-4 mb-1" },
                          [
                            _c(
                              VRow,
                              [
                                _c(
                                  VCol,
                                  {
                                    staticClass: "pt-0 pb-2 mt-0 px-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(VTextarea, {
                                      attrs: {
                                        dense: "",
                                        required: "",
                                        "auto-grow": "",
                                        outlined: "",
                                        rows: "1",
                                        "row-height": "15",
                                      },
                                      model: {
                                        value: _vm.checkListItemName,
                                        callback: function ($$v) {
                                          _vm.checkListItemName = $$v
                                        },
                                        expression: "checkListItemName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              VRow,
                              [
                                _c(
                                  VCol,
                                  {
                                    staticClass:
                                      "pt-0 px-0 d-flex align-items-center",
                                    attrs: { cols: "9" },
                                  },
                                  [
                                    _c("Button", {
                                      attrs: {
                                        variant: "tertiary",
                                        color: "primary",
                                        textValue: "Add",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.addNewCheckListItem()
                                        },
                                      },
                                    }),
                                    _vm.checkListItemName &&
                                    _vm.checkListItemName.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-4 ib cursor-pointer",
                                            on: {
                                              click: _vm.hideAddCheckListItem,
                                            },
                                          },
                                          [_c("ButtonCloseIcon")],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showAddCheckListItemBtn && _vm.isOpenHealthEditBtn
                      ? _c(
                          VRow,
                          { staticClass: "mb-2" },
                          [
                            _c(
                              VCol,
                              {
                                staticClass: "pt-0 pl-0",
                                attrs: { cols: "4" },
                              },
                              [
                                _c("Button", {
                                  attrs: {
                                    variant: "secondary",
                                    outlined: "",
                                    outlineColor: "gray",
                                    textValue: "Add",
                                    leftIcon: "mdi-plus",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.showAddCheckListItem()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(VCol, {
                              staticClass: "pt-0",
                              attrs: { cols: "8" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.subOperationCheckListItems.length > 0
                      ? _c(
                          "div",
                          { staticClass: "chklist-brdr-container" },
                          [
                            _c(VCheckbox, {
                              staticClass: "chklist-brdr",
                              attrs: {
                                label: _vm.$stringConstants(
                                  "healthSafetyChecklistMandatoryText"
                                ),
                              },
                              model: {
                                value:
                                  _vm.subOperationIsHealthSafetyListMandatory,
                                callback: function ($$v) {
                                  _vm.subOperationIsHealthSafetyListMandatory =
                                    $$v
                                },
                                expression:
                                  "subOperationIsHealthSafetyListMandatory",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "btns-bottom-box px-8 py-4" },
                [
                  _c("Button", {
                    attrs: {
                      variant: "primary",
                      color: "primary",
                      disabled: _vm.editSubOperationLoader || _vm.isFieldsValid,
                      loading: _vm.editSubOperationLoader,
                      textValue: _vm.$stringConstants("subOperationSaveTitle"),
                      rightIcon: "mdi-arrow-right",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.saveSubOperation($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.subOperationLoader
      ? _c("div", { staticClass: "text-center" }, [_c("ListLoader")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }