import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "pt-6": _vm.isDetailsView, "pt-8": !_vm.isDetailsView } },
    [
      _c("div", { staticClass: "pb-6" }, [
        _c("div", { staticClass: "tj-cp-title" }, [
          _vm._v(_vm._s(_vm.$stringConstants("teamJobInstructionsTitle"))),
        ]),
        _c("div", { staticClass: "tj-cp-subtitle" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("teamJobInstructionsSubTitle")) +
              " "
          ),
        ]),
      ]),
      _c("VueEditor", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.teamJobsLoader &&
              _vm.teamJobViewPreference.instructions.isEditable,
            expression:
              "!teamJobsLoader && teamJobViewPreference.instructions.isEditable",
          },
        ],
        staticClass: "mb-4",
        staticStyle: { "border-top": "1px solid #cfcfcf" },
        attrs: { editorToolbar: _vm.customToolbar },
        on: {
          "text-change": _vm.onChangeInstruction,
          keydown: _vm.onChangeInstruction,
        },
        model: {
          value: _vm.tempTeamJobInstructions,
          callback: function ($$v) {
            _vm.tempTeamJobInstructions = $$v
          },
          expression: "tempTeamJobInstructions",
        },
      }),
      !_vm.teamJobViewPreference.instructions.isEditable
        ? _c("div", { staticClass: "team-jobs-instructions--readonly mb-4" }, [
            _c("div", {
              staticClass: "team-jobs-instructions--min-height",
              domProps: { innerHTML: _vm._s(_vm.teamJobInstructions) },
            }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.teamJobsLoader,
              expression: "teamJobsLoader",
            },
          ],
        },
        [
          _c(VSkeletonLoader, {
            staticClass: "mb-8",
            attrs: { height: "70", type: "card" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }