import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-4 px-6" },
    [
      _c(
        VRow,
        { staticClass: "pt-0 pb-0" },
        [
          _c(VCol, { staticClass: "px-0", attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "pb-2", staticStyle: { "max-width": "700px" } },
              [
                _c(VTextField, {
                  attrs: {
                    outlined: "",
                    label: _vm.$stringConstants("name") + "*",
                    dense: "",
                    required: "",
                  },
                  model: {
                    value: _vm.supplierNameForInvoice,
                    callback: function ($$v) {
                      _vm.supplierNameForInvoice = $$v
                    },
                    expression: "supplierNameForInvoice",
                  },
                }),
                _c(VTextField, {
                  attrs: {
                    outlined: "",
                    label: _vm.$stringConstants("emailText") + "*",
                    dense: "",
                    required: "",
                  },
                  model: {
                    value: _vm.supplierEmailForInvoice,
                    callback: function ($$v) {
                      _vm.supplierEmailForInvoice = $$v
                    },
                    expression: "supplierEmailForInvoice",
                  },
                }),
                _c(VTextField, {
                  attrs: {
                    outlined: "",
                    label: _vm.$stringConstants("companyName") + "*",
                    dense: "",
                    required: "",
                  },
                  model: {
                    value: _vm.supplierBusinessProfileForInvoice.title,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.supplierBusinessProfileForInvoice,
                        "title",
                        $$v
                      )
                    },
                    expression: "supplierBusinessProfileForInvoice.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pb-2", staticStyle: { "max-width": "700px" } },
              [
                _c("div", { staticClass: "pb-6 fw-6 color-dgray" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("address"))),
                ]),
                _c(
                  "div",
                  { staticClass: "pt-2 pb-2 mb-4" },
                  [
                    _c(VTextarea, {
                      attrs: {
                        outlined: "",
                        label: _vm.$stringConstants("addressesLine1") + "*",
                        rows: "2",
                        "row-height": "15",
                        required: "",
                      },
                      model: {
                        value:
                          _vm.supplierBusinessProfileForInvoice.address
                            .addressLine1,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.supplierBusinessProfileForInvoice.address,
                            "addressLine1",
                            $$v
                          )
                        },
                        expression:
                          "supplierBusinessProfileForInvoice.address.addressLine1",
                      },
                    }),
                    _c(VTextarea, {
                      attrs: {
                        outlined: "",
                        label: _vm.$stringConstants("addressesLine2"),
                        rows: "2",
                        "row-height": "15",
                        required: "",
                      },
                      model: {
                        value:
                          _vm.supplierBusinessProfileForInvoice.address
                            .addressLine2,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.supplierBusinessProfileForInvoice.address,
                            "addressLine2",
                            $$v
                          )
                        },
                        expression:
                          "supplierBusinessProfileForInvoice.address.addressLine2",
                      },
                    }),
                    _c(
                      VRow,
                      [
                        _c(
                          VCol,
                          {
                            staticClass: "pb-0 pl-0 pt-0",
                            attrs: { cols: "6" },
                          },
                          [
                            _c(VTextField, {
                              attrs: {
                                outlined: "",
                                label: _vm.$stringConstants("city"),
                                dense: "",
                                required: "",
                              },
                              on: {
                                keydown: function ($event) {
                                  return _vm.checkKeyDownAlpha($event)
                                },
                              },
                              model: {
                                value:
                                  _vm.supplierBusinessProfileForInvoice.address
                                    .city,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplierBusinessProfileForInvoice
                                      .address,
                                    "city",
                                    $$v
                                  )
                                },
                                expression:
                                  "supplierBusinessProfileForInvoice.address.city",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          {
                            staticClass: "pb-0 pr-0 pt-0",
                            attrs: { cols: "6" },
                          },
                          [
                            _c(VTextField, {
                              attrs: {
                                outlined: "",
                                label: _vm.$stringConstants("stateAndCounty"),
                                dense: "",
                                required: "",
                              },
                              on: {
                                keydown: function ($event) {
                                  return _vm.checkKeyDownAlpha($event)
                                },
                              },
                              model: {
                                value:
                                  _vm.supplierBusinessProfileForInvoice.address
                                    .state,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplierBusinessProfileForInvoice
                                      .address,
                                    "state",
                                    $$v
                                  )
                                },
                                expression:
                                  "supplierBusinessProfileForInvoice.address.state",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          {
                            staticClass: "pb-0 pl-0 pt-0",
                            attrs: { cols: "6" },
                          },
                          [
                            _c(VTextField, {
                              attrs: {
                                outlined: "",
                                label: _vm.$stringConstants("country"),
                                dense: "",
                                required: "",
                              },
                              on: {
                                keydown: function ($event) {
                                  return _vm.checkKeyDownAlpha($event)
                                },
                              },
                              model: {
                                value:
                                  _vm.supplierBusinessProfileForInvoice.address
                                    .country,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplierBusinessProfileForInvoice
                                      .address,
                                    "country",
                                    $$v
                                  )
                                },
                                expression:
                                  "supplierBusinessProfileForInvoice.address.country",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          {
                            staticClass: "pb-0 pr-0 pt-0",
                            attrs: { cols: "6" },
                          },
                          [
                            _c(VTextField, {
                              attrs: {
                                outlined: "",
                                label: _vm.$stringConstants("postalCode") + "*",
                                dense: "",
                                required: "",
                              },
                              on: {
                                keydown: function ($event) {
                                  return _vm.checkKeyDownAlphaNumeric($event)
                                },
                              },
                              model: {
                                value:
                                  _vm.supplierBusinessProfileForInvoice.address
                                    .postalCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplierBusinessProfileForInvoice
                                      .address,
                                    "postalCode",
                                    $$v
                                  )
                                },
                                expression:
                                  "supplierBusinessProfileForInvoice.address.postalCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }