import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';
export default {
  name: 'DeleteItemDialog',
  props: ['item', 'onClickEvent', 'firstActionText', 'secondActionText'],
  components: {
    SecondaryButton: SecondaryButton
  },
  computed: {
    itemToDelete: {
      get: function get() {
        return this.item;
      },
      set: function set(newVal) {
        this.$emit('change', newVal);
      }
    }
  }
};