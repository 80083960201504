import ConstantValues from "@/plugins/constantValues";
export default {
  data: function data() {
    return {
      timerId: null
    };
  },
  methods: {
    applySearchTimer: function applySearchTimer(callback) {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.timerId = setTimeout(function () {
        callback();
      }, ConstantValues.defaultDropdownSearchTimeout);
    }
  }
};