import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("DeleteItemDialog", {
        attrs: { item: _vm.showDeleteDialog },
        on: { cancel: _vm.cancelUserDelete, proceed: _vm.proceedUserDelete },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("userDeletePromtTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("userDeletePromtSubtitle")) +
                    " " +
                    _vm._s(_vm.userDeleteName) +
                    " ? "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        VRow,
        {
          staticClass: "flex-grow-0 px-9 pt-4",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "5" } },
            [
              _c(
                VRow,
                [
                  _c(VCol, { staticClass: "px-0", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "ib",
                        staticStyle: { "min-width": "350px" },
                      },
                      [
                        _c(VTextField, {
                          staticClass: "rd-rt-no agd-txtfield mr-4",
                          attrs: {
                            placeholder: _vm.$stringConstants(
                              "usersSearchPlaceholder"
                            ),
                            outlined: "",
                            clearable: "",
                            "prepend-inner-icon": "mdi-magnify",
                            dense: "",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.applySearchTimer(_vm.applyUsersFilter)
                            },
                          },
                          model: {
                            value: _vm.usersSearchText,
                            callback: function ($$v) {
                              _vm.usersSearchText = $$v
                            },
                            expression: "usersSearchText",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.totalRowCount
                      ? _c("div", { staticClass: "ib" }, [
                          _c("div", { staticClass: "su-list-count-chip" }, [
                            _vm._v(" " + _vm._s(_vm.totalRowCount) + " users "),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "7" } },
            [
              _c(
                VRow,
                { staticClass: "text-right" },
                [
                  _c(
                    VCol,
                    { staticClass: "px-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.usersListRoles !== null,
                              expression: "usersListRoles !== null",
                            },
                          ],
                          staticClass: "ib",
                        },
                        [
                          _c("div", { staticClass: "btn-filters-chip" }, [
                            _c("div", { staticClass: "ib" }, [
                              _vm._v(" " + _vm._s(_vm.usersListRoles) + " "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "ib btn-filters-chip-clear" },
                              [
                                _c(
                                  VIcon,
                                  {
                                    staticClass: "oy-cl",
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilterStates("userRole")
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-thick ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.usersListStartDate !== null &&
                                _vm.usersListEndDate !== null,
                              expression:
                                "usersListStartDate !== null && usersListEndDate !== null",
                            },
                          ],
                          staticClass: "ib",
                        },
                        [
                          _c("div", { staticClass: "btn-filters-chip" }, [
                            _c("div", { staticClass: "ib" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateTimeFormat(
                                      _vm.usersListStartDate
                                    )
                                  ) +
                                  " - " +
                                  _vm._s(
                                    _vm.getDateTimeFormat(_vm.usersListEndDate)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "ib btn-filters-chip-clear" },
                              [
                                _c(
                                  VIcon,
                                  {
                                    staticClass: "oy-cl",
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilterStates(
                                          "dateRange"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-thick ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          outlined: "",
                          outlineColor: "primary",
                          textValue: "Filter",
                          leftIcon: "mdi-filter-variant",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openUsersFilterForm()
                          },
                        },
                      }),
                      _c("Button", {
                        staticClass: "float-right ml-2",
                        attrs: {
                          variant: "secondary",
                          outlined: "",
                          outlineColor: "gray",
                          disabled: _vm.usersList.length === 0,
                          textValue: _vm.$stringConstants("downloadText"),
                          leftIcon: "mdi-arrow-down",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.initiateUsersExport($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("List", {
        attrs: { listItems: _vm.usersList, containsAction: "" },
        on: { setFilterOptionsAndGetListData: _vm.setFilterOptionsAndGetUsers },
        scopedSlots: _vm._u([
          {
            key: "list-heading",
            fn: function () {
              return [
                _c("div", { staticClass: "width-perc-17 pr-4" }, [
                  _vm._v("Name"),
                ]),
                _c("div", { staticClass: "width-perc-17" }, [
                  _vm._v("Contact"),
                ]),
                _c("div", { staticClass: "width-perc-18" }, [
                  _vm._v("Business name"),
                ]),
                _c("div", { staticClass: "width-perc-10" }, [
                  _vm._v("Onboarded on"),
                ]),
                _c("div", { staticClass: "width-perc-10" }, [_vm._v("Role")]),
                _c("div", { staticClass: "width-perc-10" }, [_vm._v("Status")]),
                _c("div", { staticClass: "width-perc-13" }, [
                  _vm._v("Last active"),
                ]),
                _c("div", { staticClass: "width-perc-5 text-right pr-4" }, [
                  _vm._v("Delete"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "list-item",
            fn: function (ref) {
              var listItem = ref.listItem
              return [
                _c("div", { staticClass: "width-perc-17 pr-4" }, [
                  listItem.name != null
                    ? _c(
                        "div",
                        { staticClass: "fw-6 d-flex align-center" },
                        [
                          _c("Avatar", {
                            staticClass: "mr-2",
                            attrs: {
                              initials: listItem.name,
                              width: "28px",
                              height: "28px",
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "d-inline-block pl-1 pr-8" },
                            [_vm._v(_vm._s(listItem.name))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-17 pr-3",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    _c("div", [
                      _vm._v(" " + _vm._s(listItem.emailAddress) + " "),
                    ]),
                    _c("div", { staticClass: "pt-1" }, [
                      _vm._v(" " + _vm._s(listItem.primaryPhoneNumber) + " "),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "width-perc-18 pr-8" }, [
                  _vm._v(_vm._s(listItem.businessName)),
                ]),
                _c("div", { staticClass: "width-perc-10" }, [
                  _vm._v(_vm._s(_vm.getDateTimeFormat(listItem.createdOn))),
                ]),
                _c("div", { staticClass: "width-perc-10" }, [
                  _vm._v(
                    _vm._s(
                      listItem.roles
                        .map(function (role) {
                          return role + ""
                        })
                        .join(",")
                    )
                  ),
                ]),
                _c("div", { staticClass: "width-perc-10" }, [
                  _vm._v(_vm._s(listItem.isArchived ? "Inactive" : "Active")),
                ]),
                _c("div", { staticClass: "width-perc-13" }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDateTimeFormattedLocal(listItem.lastActiveOn) ||
                        "-"
                    )
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "width-perc-4 text-right position-relative pr-4 mt-1",
                  },
                  [
                    listItem.businessName == "N/A" || listItem.isArchived
                      ? _c(
                          VMenu,
                          {
                            attrs: { bottom: "", "min-width": "120", left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "triple-dot-menu",
                                              attrs: { icon: "" },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-dots-vertical"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              VList,
                              [
                                _c(
                                  VListItem,
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.prmoptUserDelete(
                                          listItem.id,
                                          listItem.name
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VListItemTitle,
                                      [
                                        _c(
                                          VIcon,
                                          { staticClass: "icon-size pr-2" },
                                          [_vm._v("mdi-delete")]
                                        ),
                                        _vm._v("Delete "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", [_vm._v(" - ")]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "zero-state",
            fn: function () {
              return [
                _c("div", [
                  _c("h4", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(_vm.$stringConstants("suUsersZeroStateHeading"))
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.showExportDialog,
            callback: function ($$v) {
              _vm.showExportDialog = $$v
            },
            expression: "showExportDialog",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "dlt-diag py-5" },
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("suUsersLoaderDialogTitle")) +
                    " "
                ),
              ]),
              _c(
                VCardText,
                [
                  _c(VProgressCircular, {
                    staticStyle: {
                      transform: "translateY(-2px) translateX(-2px)",
                    },
                    attrs: {
                      indeterminate: "",
                      width: "2",
                      size: "18",
                      color: "#b3b3b3",
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants("suUsersLoaderDialogContent")
                      ) +
                      " (" +
                      _vm._s(_vm.exportDataLoaded) +
                      " / " +
                      _vm._s(_vm.exportDataTotal) +
                      ") "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }