import "core-js/modules/es.array.push.js";
import TeamJobsComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-computed";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import TeamJobsCommon from "@/components/team-jobs/team-jobs-details/team-jobs-details-common";
import { DeleteItemDialog } from "@/components/shared/dialogs/index";
import { routeJobs } from "@/utils/endpoints";
import router from "@/router/index";
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';
export default {
  name: "TeamJobsCancelOrDelete",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    DeleteItemDialog: DeleteItemDialog,
    SecondaryButton: SecondaryButton
  },
  data: function data() {
    return {
      dialogCancelWithReasonTeamJob: false,
      cancelTeamJobReason: "",
      TeamJobStatusType: TeamJobStatusType,
      dialogForceFinish: false,
      dialogDirectCancelJob: false,
      dialogDeleteJob: false
    };
  },
  methods: {
    proceedJobCancellation: function proceedJobCancellation() {
      var cancelWithReason = false;
      var isJobInternal = this.teamJobDetails.isInternal;
      var isSelfJob = this.teamJobDetails.customer.id === this.teamJobDetails.contractor.id;
      var jobCreatedByCustomerAssignedToContractor = this.teamJobDetails.customer.id === this.teamJobDetails.creatorId && !isSelfJob && !isJobInternal;
      var jobCreatedByContractorAssignedToCustomer = this.teamJobDetails.contractor.id === this.teamJobDetails.creatorId && !isSelfJob && !isJobInternal;
      if (getLoggedInUserRole().isUserOwnerFarmerLoggedIn && jobCreatedByContractorAssignedToCustomer) {
        cancelWithReason = true;
      }
      if (getLoggedInUserRole().isUserOwnerContractorLoggedIn && jobCreatedByCustomerAssignedToContractor) {
        cancelWithReason = true;
      }
      if (cancelWithReason) {
        this.dialogCancelWithReasonTeamJob = true;
      } else {
        this.dialogDirectCancelJob = true;
      }
    },
    closeJobCancelDialog: function closeJobCancelDialog() {
      this.cancelTeamJobReason = "";
      this.dialogCancelWithReasonTeamJob = false;
    },
    proceedToCancelTeamJob: function proceedToCancelTeamJob() {
      var _this = this;
      var obj = {
        resourceId: this.teamJobDetails.id,
        reason: {
          reason: this.cancelTeamJobReason
        }
      };
      this.$store.dispatch("cancelTeamJobByContractor", obj).then(function (response) {
        _this.closeJobCancelDialog();
        if (response) {
          router.back();
        }
      });
    },
    cancelAddTeamJob: function cancelAddTeamJob() {
      router.push(routeJobs);
    },
    deleteJob: function deleteJob() {
      this.dialogDeleteJob = false;
      this.$store.dispatch('deleteTeamJob', this.teamJobDetails.id).then(function (response) {
        if (response) {
          router.back();
        }
      });
    }
  }
};