import { render, staticRenderFns } from "./su-overview-current-statistics.vue?vue&type=template&id=7e07e2e6"
import script from "./su-overview-current-statistics.vue?vue&type=script&lang=js"
export * from "./su-overview-current-statistics.vue?vue&type=script&lang=js"
import style0 from "./su-overview-current-statistics.vue?vue&type=style&index=0&id=7e07e2e6&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/src/web/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e07e2e6')) {
      api.createRecord('7e07e2e6', component.options)
    } else {
      api.reload('7e07e2e6', component.options)
    }
    module.hot.accept("./su-overview-current-statistics.vue?vue&type=template&id=7e07e2e6", function () {
      api.rerender('7e07e2e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/su-dashboard/su-overview-current-statistics.vue"
export default component.exports