import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "b-btm py-5 px-9" },
        [
          _c(
            VRow,
            { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
            [
              _c(
                VCol,
                { attrs: { cols: "8" } },
                [
                  _vm.productDetails.id == null
                    ? _c("HeaderPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants("productAddTitle"),
                        },
                      })
                    : _c("HeaderPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants("productEditTitle"),
                        },
                      }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "text-right", attrs: { cols: "4" } },
                [
                  _c(
                    VIcon,
                    {
                      staticClass: "oy-cl",
                      attrs: { small: "", id: "closeProductEditForm" },
                      on: { click: _vm.closeProductEditForm },
                    },
                    [_vm._v(" mdi-close-thick ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.productDetails != null && !_vm.productLoader
        ? _c(
            VContainer,
            {
              staticClass: "pb-0 pt-7",
              class: [_vm.containerLeftPad, _vm.containerRightPad],
            },
            [
              _c(
                "div",
                { staticClass: "pb-2" },
                [
                  _vm.productDetails.id == null
                    ? _c("SubHeaderPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants("productAddSubtitle"),
                        },
                      })
                    : _c("SubHeaderPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "productEditSubtitle"
                          ),
                        },
                      }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pt-1 pb-7" },
                [
                  _vm.productDetails.id == null
                    ? _c("DescriptionPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "productAddDescription"
                          ),
                        },
                      })
                    : _c("DescriptionPrimary", {
                        attrs: {
                          textValue: _vm.$stringConstants(
                            "productEditDescription"
                          ),
                        },
                      }),
                ],
                1
              ),
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(" " + _vm._s(_vm.$stringConstants("name")) + " * "),
              ]),
              _c(VTextField, {
                class: _vm.formFieldPb,
                attrs: {
                  placeholder: _vm.$stringConstants("productTitlePlaceholder"),
                  outlined: "",
                  dense: "",
                },
                on: {
                  keydown: function ($event) {
                    return _vm.checkKeyDownAlphaNumeric($event)
                  },
                },
                model: {
                  value: _vm.productDetails.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.productDetails, "title", $$v)
                  },
                  expression: "productDetails.title",
                },
              }),
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("chargeableUnitText")) +
                    " * "
                ),
              ]),
              _c(VSelect, {
                class: _vm.formFieldPb,
                attrs: {
                  items: _vm.enumListProductUnits,
                  "item-value": "value",
                  "item-text": "title",
                  placeholder: _vm.$stringConstants("unitPlaceholder"),
                  outlined: "",
                  dense: "",
                  attach: "",
                },
                model: {
                  value: _vm.productDetails.unit,
                  callback: function ($$v) {
                    _vm.$set(_vm.productDetails, "unit", $$v)
                  },
                  expression: "productDetails.unit",
                },
              }),
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("pricePerUnitText")) + " * "
                ),
              ]),
              _c(VTextField, {
                class: _vm.formFieldPb,
                attrs: {
                  id: "productPriceEdit",
                  type: "number",
                  min: "0",
                  outlined: "",
                  dense: "",
                },
                on: {
                  keydown: function ($event) {
                    return _vm.checkKeyDownNumeric($event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "prepend-inner",
                      fn: function () {
                        return [
                          _c(
                            "span",
                            { staticStyle: { "padding-top": "4px" } },
                            [_vm._v(" " + _vm._s(_vm.currencySymbol))]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3873554978
                ),
                model: {
                  value: _vm.productDetails.cost,
                  callback: function ($$v) {
                    _vm.$set(_vm.productDetails, "cost", _vm._n($$v))
                  },
                  expression: "productDetails.cost",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.productLoader
        ? _c("div", { staticClass: "text-center" }, [_c("ListLoader")], 1)
        : _vm._e(),
      _c(
        "div",
        { staticClass: "btns-bottom-box px-8 py-4 text-center" },
        [
          _c("Button", {
            attrs: {
              variant: "primary",
              color: "primary",
              disabled: _vm.editProductLoader || _vm.isDisabledBtn,
              loading: _vm.editProductLoader,
              textValue:
                _vm.productDetails.id != null
                  ? _vm.$stringConstants("updateBtnText")
                  : "Add product",
              rightIcon: "mdi-arrow-right",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.editProductDetails()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }