//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DashboardStaticsCounter",
  props: ['counterTitle', 'counterValue', 'counterSummary']
};