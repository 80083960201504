var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "su-db-box-wrapper su-db-box-wrapper-left" }, [
      _vm._m(0),
      _c("div", { staticClass: "su-layout-dist-cont" }, [
        !_vm.adminOverallOverviewLoader
          ? _c(
              "div",
              _vm._l(_vm.invoiceDistributions, function (distribution, index) {
                return _c("div", { key: index }, [
                  _c("div", { staticClass: "su-layout-dist" }, [
                    _c("div", { staticClass: "ib" }, [
                      _vm._v(_vm._s(distribution.type)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ib", staticStyle: { float: "right" } },
                      [_vm._v(_vm._s(distribution.count))]
                    ),
                  ]),
                ])
              }),
              0
            )
          : _c(
              "div",
              [_c("DistributionLoaders", { attrs: { loaderRows: 3 } })],
              1
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "su-db-header" }, [
      _c("div", { staticClass: "ib" }, [_vm._v(" Service ")]),
      _c("div", { staticClass: "ib", staticStyle: { float: "right" } }, [
        _vm._v(" Businesses "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }