import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-9" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(
              VCol,
              { attrs: { cols: "8" } },
              [
                _c("HeaderPrimary", {
                  attrs: {
                    textValue: _vm.$stringConstants("teamMemberInviteTitle"),
                  },
                }),
              ],
              1
            ),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    staticClass: "oy-cl",
                    attrs: { small: "", id: "closeMemberAddForm" },
                    on: { click: _vm.closeMemberAddForm },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          VContainer,
          {
            staticClass: "pt-7",
            class: [_vm.containerLeftPad, _vm.containerRightPad],
          },
          [
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(" " + _vm._s(_vm.$stringConstants("emailText")) + " * "),
            ]),
            _c(VTextField, {
              class: _vm.formFieldPb,
              attrs: {
                "error-messages": _vm.generateValidationErrors(),
                placeholder: _vm.$stringConstants("emailText"),
                outlined: "",
                dense: "",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.userInviteeEmail.$touch()
                },
                blur: function ($event) {
                  return _vm.$v.userInviteeEmail.$touch()
                },
              },
              model: {
                value: _vm.userInviteeEmail,
                callback: function ($$v) {
                  _vm.userInviteeEmail = $$v
                },
                expression: "userInviteeEmail",
              },
            }),
            _c("div", { staticClass: "field-input-label" }, [
              _vm._v(" " + _vm._s(_vm.$stringConstants("roleText")) + " * "),
            ]),
            _c(VSelect, {
              class: _vm.formFieldPb,
              attrs: {
                items: _vm.enumMemberRoles,
                palceholder: _vm.$stringConstants("roleText"),
                "item-text": "name",
                "item-value": "type",
                outlined: "",
                dense: "",
                attach: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "selection",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.showTeamMemberRoleFromType(item.type)) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.showTeamMemberRoleFromType(item.type)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.userInviteeRole,
                callback: function ($$v) {
                  _vm.userInviteeRole = _vm._n($$v)
                },
                expression: "userInviteeRole",
              },
            }),
            _c(
              VSimpleTable,
              {
                staticClass: "features-user-roles",
                attrs: { "fixed-header": "", dense: "" },
              },
              [
                _c("thead", { staticClass: "header" }, [
                  _c("tr", [
                    _c("th", { staticClass: "py-3 pl-4" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants(
                              "teamMembersRoleChangeTableLabelFeatures"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "th",
                      {
                        class:
                          "py-3 " +
                          (_vm.userInviteeRole === _vm.UserRole.Manager
                            ? "fw-7-imp"
                            : ""),
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants(
                                "teamMembersRoleChangeTableLabelManager"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "th",
                      {
                        class:
                          "py-3 " +
                          (_vm.userInviteeRole === _vm.UserRole.TeamLead
                            ? "fw-7-imp"
                            : ""),
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants(
                                "teamMembersRoleChangeTableLabelTeamLead"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "th",
                      {
                        class:
                          "py-3 " +
                          (_vm.userInviteeRole === _vm.UserRole.Operator
                            ? "fw-7-imp"
                            : ""),
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants(
                                "teamMembersRoleChangeTableLabelOperator"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.Features, function (ref) {
                    var featureKey = ref[0]
                    var featureLabel = ref[1]
                    return _c(
                      "tr",
                      { key: featureKey, attrs: { "data-index": featureKey } },
                      [
                        _c("td", { staticClass: "py-2 pl-4" }, [
                          _vm._v(_vm._s(featureLabel)),
                        ]),
                        _c(
                          "td",
                          {
                            class:
                              "py-2 " +
                              (_vm.userInviteeRole === _vm.UserRole.Manager
                                ? "fw-7"
                                : ""),
                          },
                          [_vm._v(_vm._s(_vm.ManagerFeatures[featureKey]))]
                        ),
                        _c(
                          "td",
                          {
                            class:
                              "py-2 " +
                              (_vm.userInviteeRole === _vm.UserRole.TeamLead
                                ? "fw-7"
                                : ""),
                          },
                          [_vm._v(_vm._s(_vm.TeamLeadFeatures[featureKey]))]
                        ),
                        _c(
                          "td",
                          {
                            class:
                              "py-2 " +
                              (_vm.userInviteeRole === _vm.UserRole.Operator
                                ? "fw-7"
                                : ""),
                          },
                          [_vm._v(_vm._s(_vm.OperatorFeatures[featureKey]))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _c(
              VRow,
              { staticClass: "features-user-roles-table-btm" },
              [
                _c(
                  VCol,
                  {
                    staticClass:
                      "features-user-roles-table-btm-label pa-3 pb-0",
                    staticStyle: { border: "none" },
                    attrs: { cols: "12" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$stringConstants("teamMemberRoleBillingPriceLabel")
                      )
                    ),
                  ]
                ),
                _c(
                  VCol,
                  {
                    staticClass:
                      "features-user-roles-table-btm-label pa-3 pt-1",
                    staticStyle: { border: "none" },
                    attrs: { cols: "12" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$stringConstants(
                          "teamMemberRoleRecordJobsPriceLabel"
                        )
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btns-bottom-box px-9 py-4" },
          [
            _c("Button", {
              class: { disabled: _vm.fieldsInvalid },
              attrs: {
                variant: "primary",
                color: "primary",
                disabled: _vm.fieldsInvalid,
                loading: _vm.usersLoader,
                textValue: "Send invitation",
                rightIcon: "mdi-arrow-right",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.proceedTeamMemberInvitation()
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }