import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container invoices-external-contacts" },
    [
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.showLinkWarning,
            callback: function ($$v) {
              _vm.showLinkWarning = $$v
            },
            expression: "showLinkWarning",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "dlt-diag px-3 pt-2 pb-3 d-flex flex-column" },
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("invoiceExternalLinkWarningTitle")
                    ) +
                    " "
                ),
              ]),
              _c(VCardText, { staticStyle: { "line-height": "20px" } }, [
                _vm._v(" " + _vm._s(_vm.dialogMessage) + " "),
              ]),
              _c(
                VCardActions,
                { staticClass: "px-6 mt-auto" },
                [
                  _c(VSpacer),
                  _c("Button", {
                    attrs: {
                      variant: "secondary",
                      outlined: "",
                      outlineColor: "primary",
                      text: "",
                      color: "primary",
                      textValue: "No",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return (function () {
                          _vm.dialogMessage = null
                          _vm.showLinkWarning = false
                        })($event)
                      },
                    },
                  }),
                  _c("Button", {
                    staticClass: "ml-3",
                    attrs: {
                      variant: "secondary",
                      color: "primary",
                      textValue: "Yes",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.unlinkAndLinkContact($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VRow,
        {
          staticClass: "fn-20 b-btm py-4 px-8 flex-grow-0",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(VCol, { staticClass: "oy-tl", attrs: { cols: "8" } }, [
            _vm._v(
              _vm._s(_vm.$stringConstants("invoiceExternalSelectContactLink"))
            ),
          ]),
          _c(
            VCol,
            { staticClass: "text-right", attrs: { cols: "4" } },
            [
              _c(
                VIcon,
                {
                  staticClass: "oy-cl",
                  attrs: { small: "" },
                  on: { click: _vm.closeContactLinkFlyout },
                },
                [_vm._v(" mdi-close-thick ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ExternalContactsSearch", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.listLoading,
            expression: "!listLoading",
          },
        ],
      }),
      _vm.listLoading
        ? _c("div", { staticClass: "text-center" }, [_c("ListLoader")], 1)
        : _c(
            VRow,
            { staticClass: "px-7 mb-3 pb-4 list-heading" },
            [
              _c(
                VCol,
                { staticClass: "fw-7 py-0 pl-0", attrs: { cols: "" } },
                [_vm._v(" " + _vm._s(_vm.$stringConstants("name")) + " ")]
              ),
              _c(VCol, { staticClass: "py-0 pl-0", attrs: { cols: "" } }, [
                _vm._v(" " + _vm._s(_vm.$stringConstants("emailText")) + " "),
              ]),
              _c(VCol, { staticClass: "py-0", attrs: { cols: "1" } }),
            ],
            1
          ),
      _c(
        VRadioGroup,
        {
          staticClass: "external-contacts-list mt-0 pt-0",
          attrs: { "hide-details": "" },
          model: {
            value: _vm.externalContactRadioGroup,
            callback: function ($$v) {
              _vm.externalContactRadioGroup = $$v
            },
            expression: "externalContactRadioGroup",
          },
        },
        [
          _c(VVirtualScroll, {
            attrs: {
              bench: 0,
              items: _vm.contacts,
              height: "770",
              "item-height": "60",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      VListItem,
                      { key: item.id },
                      [
                        _c(
                          VRow,
                          { staticClass: "px-3 align-center mb-2" },
                          [
                            _c(
                              VCol,
                              {
                                staticClass: "fw-7 py-0 pl-0",
                                attrs: { cols: "" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.companyName
                                        ? item.companyName
                                        : item.name
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              VCol,
                              { staticClass: "py-0 pl-0", attrs: { cols: "" } },
                              [_vm._v(_vm._s(item.emailAddress))]
                            ),
                            _c(
                              VCol,
                              { staticClass: "py-0", attrs: { cols: "1" } },
                              [
                                _c(VRadio, {
                                  key: item.id,
                                  staticClass: "mx-0 my-0",
                                  attrs: { value: item.id },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getSelectedCustomer(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(VDivider),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.fetchingintergrationDataProgress || _vm.customerLoader
        ? _c("div", { staticClass: "text-center" }, [_c("EllipsisLoader")], 1)
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "btns-bottom-box px-8 py-1",
          staticStyle: { width: "633px" },
        },
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "6" } },
                [
                  _c("Button", {
                    attrs: {
                      variant: "primary",
                      color: "primary",
                      textValue: "Cancel",
                      disabled: _vm.invoiceLoaderExternal,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeContactLinkFlyout($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "6" } },
                [
                  _c("Button", {
                    attrs: {
                      variant: "primary",
                      color: "primary",
                      disabled: _vm.externalContactIdToLink == null,
                      loading: _vm.invoiceLoaderExternal,
                      textValue: "Link contact",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.linkCustomerCheck($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }