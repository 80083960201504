import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.teamJobDetails &&
      (_vm.teamJobDetails.status <= _vm.TeamJobStatusType.Ready ||
        _vm.teamJobDetails.status == _vm.TeamJobStatusType.Completed)
        ? _c(
            "span",
            [
              _vm.teamJobDetails.status == _vm.TeamJobStatusType.Ready ||
              (_vm.teamJobDetails.status == _vm.TeamJobStatusType.Pending &&
                _vm.getSenderId == _vm.teamJobDetails.customer.id)
                ? _c(
                    VBtn,
                    {
                      staticClass: "cncl-btn jb-dngr-btn",
                      attrs: {
                        disabled: _vm.teamJobsLoader,
                        elevation: "0",
                        height: "40",
                      },
                      on: { click: _vm.proceedJobCancellation },
                    },
                    [
                      _c(VIcon, { attrs: { left: "" } }, [
                        _vm._v(" mdi-close-circle "),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("teamJobCancelBtnText")) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.teamJobDetails.customer.id ==
                _vm.teamJobDetails.contractor.id ||
                _vm.teamJobDetails.customer.isInternal) &&
              _vm.teamJobDetails.status == _vm.TeamJobStatusType.Completed &&
              !_vm.teamJobDetails.isInvoiced
                ? _c(
                    VBtn,
                    {
                      staticClass: "cncl-btn jb-dngr-btn",
                      attrs: {
                        disabled: _vm.teamJobsLoader,
                        elevation: "0",
                        height: "40",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialogDeleteJob = true
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { left: "" } }, [
                        _vm._v(" mdi-delete-circle "),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("teamJobDeleteBtnText")) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.dialogCancelWithReasonTeamJob,
            callback: function ($$v) {
              _vm.dialogCancelWithReasonTeamJob = $$v
            },
            expression: "dialogCancelWithReasonTeamJob",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "dlt-diag py-5", attrs: { height: "280" } },
            [
              _c(VCardTitle, { staticClass: "headline pt-0" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$stringConstants("teamJobCancelPromtTitle"))
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "pl-1",
                    staticStyle: { "font-size": "12px", opacity: "0.5" },
                  },
                  [
                    _vm._v(
                      " (" +
                        _vm._s(
                          _vm.$stringConstants("teamJobCancelReasonText")
                        ) +
                        ") "
                    ),
                  ]
                ),
              ]),
              _c(VCardText, { staticClass: "pb-0" }, [
                _c(
                  "div",
                  [
                    _c(VTextarea, {
                      attrs: {
                        rows: "4",
                        "row-height": "30",
                        outlined: "",
                        dense: "",
                        required: "",
                      },
                      model: {
                        value: _vm.cancelTeamJobReason,
                        callback: function ($$v) {
                          _vm.cancelTeamJobReason = $$v
                        },
                        expression: "cancelTeamJobReason",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c("SecondaryButton", {
                    attrs: {
                      variant: "secondary",
                      text: "",
                      color: "primary",
                      outlined: "",
                      outlineColor: "primary",
                      textValue: _vm.$stringConstants("deletePromtDisagree"),
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeJobCancelDialog($event)
                      },
                    },
                  }),
                  _c("SecondaryButton", {
                    staticClass: "ml-3",
                    attrs: {
                      variant: "secondary",
                      color: "primary",
                      textValue: _vm.$stringConstants("deletePromtAgree"),
                      disabled: _vm.cancelTeamJobReason.trim().length == 0,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.proceedToCancelTeamJob($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogDirectCancelJob },
        on: {
          cancel: function ($event) {
            _vm.dialogDirectCancelJob = false
          },
          proceed: _vm.proceedToCancelTeamJob,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("teamJobCancelPromtTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("teamJobCancelPromtSubtitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogDeleteJob },
        on: {
          cancel: function ($event) {
            _vm.dialogDeleteJob = false
          },
          proceed: _vm.deleteJob,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("teamJobDeletePromtTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("teamJobDeletePromtSubtitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }