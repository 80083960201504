import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { closeOverlay, checkFieldsValidity } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import moment from 'moment';
import timesheetsComputedMixin from '@/components/timesheets/timesheets-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { TimeSheetType } from '@/enum/timeSheetType';
import ConstantValues from '@/plugins/constantValues';
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
export default {
  name: "TimeSheetAddListView",
  data: function data() {
    return {
      timeMemberId: localStorage.getItem(ConstantValues.ownerId),
      menuDate: false,
      breakMin: 0,
      breakHours: 0,
      travelMin: 0,
      travelHours: 0,
      timeSheetType: TimeSheetType.Working,
      timeSheetTypeTitle: null,
      timeNote: null,
      timeSheetDate: null,
      showTimeSheetFieldByType: true,
      TimeSheetType: TimeSheetType,
      searchTimerMemberAddFilter: null
    };
  },
  components: {
    HeaderPrimary: HeaderPrimary,
    Button: Button,
    VueCtkDateTimePicker: VueCtkDateTimePicker
  },
  mixins: [timesheetsComputedMixin],
  computed: {
    isDisabledBtn: function isDisabledBtn() {
      if (this.showTimeSheetFieldByType != true) {
        if (checkFieldsValidity([this.timeMemberId, this.timeSheetDate])) {
          return false;
        } else {
          return true;
        }
      } else {
        if (checkFieldsValidity([this.timeMemberId, this.timeSheetDate, this.timeStart, this.timeEnd])) {
          return false;
        } else {
          return true;
        }
      }
    },
    computedDateFormatted: function computedDateFormatted() {
      return this.timeSheetDate ? moment(this.timeSheetDate).format('DD MMM YYYY') : '';
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearTimeSheetFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    closeTimeSheetAddForm: function closeTimeSheetAddForm() {
      this.resetAddTimeSheetForm();
      closeOverlay("addTimeSheetFormOverlay");
    },
    applyMembersFilterAddSearchTimer: function applyMembersFilterAddSearchTimer() {
      var _this = this;
      if (this.searchTimerMemberAddFilter) {
        clearTimeout(this.searchTimerMemberAddFilter);
        this.searchTimerMemberAddFilter = null;
      }
      this.searchTimerMemberAddFilter = setTimeout(function () {
        _this.searchTeamMembersFilter();
      }, ConstantValues.defaultDropdownSearchTimeout);
    },
    searchTeamMembersFilter: function searchTeamMembersFilter() {
      var _this2 = this;
      this.clearTimeSheetFilterOption();
      this.$store.dispatch('setSearchTextForTimesheetMembers').then(function (res) {
        _this2.$store.dispatch('getTimesheetMembersList');
        if (_this2.timesheetMembersSearchText == '') {
          _this2.clearTimeSheetFilterOption();
        }
      });
    },
    addTimeSheet: function addTimeSheet() {
      var _this3 = this;
      var startTimeConverted = '';
      var endTimeConverted = '';
      var timeSheetEndDate = this.getTimesheetEndDate(this.timeSheetDate, this.timeStart, this.timeEnd);
      startTimeConverted = moment.utc(moment(this.timeSheetDate + ' ' + this.timeStart).utc()).format();
      endTimeConverted = moment.utc(moment(timeSheetEndDate + ' ' + this.timeEnd).utc()).format();
      var timeSheetDto = {
        userId: this.timeMemberId,
        type: this.timeSheetType,
        startTime: startTimeConverted,
        endTime: endTimeConverted,
        travelTimeInMinutes: this.travelHours * 60 + this.travelMin,
        breakTimeInMinutes: this.breakHours * 60 + this.breakMin,
        note: this.timeNote
      };
      this.$store.dispatch('addNewTimesheet', timeSheetDto).then(function (res) {
        if (res != undefined) {
          _this3.clearTimeSheetFilterOption();
          _this3.resetPaging();
          _this3.resetAddTimeSheetForm();
          _this3.setFilterOptionsAndGetTimesheets();
        }
      });
    },
    setFilterOptionsAndGetTimesheets: function setFilterOptionsAndGetTimesheets() {
      var _this4 = this;
      var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      this.$store.dispatch('setTimesheetsFilter', filters).then(function () {
        _this4.$store.dispatch('getTimesheetsList').then(function () {
          _this4.timesheetsFilterLoader = false;
        });
        _this4.$store.dispatch('getTimesheetsStatistics');
        if (_this4.timesheetsSearchText == '') {
          _this4.clearTimeSheetFilterOption();
          _this4.filtersApplied = false;
        }
      });
    },
    resetAddTimeSheetForm: function resetAddTimeSheetForm() {
      this.timeMemberId = localStorage.getItem(ConstantValues.ownerId);
      this.timeStart = null;
      this.timeEnd = null;
      this.menuDate = false;
      this.breakMin = 0;
      this.breakHours = 0;
      this.travelMin = 0;
      this.travelHours = 0;
      this.timeSheetType = TimeSheetType.Working;
      this.timeNote = null;
      this.timeSheetDate = null;
      this.showTimeSheetFieldByType = true;
    },
    getItemText: function getItemText(title) {
      this.timeSheetTypeTitle = title;
    },
    checkTimeSheetType: function checkTimeSheetType(type) {
      if (type == TimeSheetType.Holiday || type == TimeSheetType.Sick) {
        this.showTimeSheetFieldByType = false;
        this.breakMin = 0;
        this.breakHours = 0;
        this.travelMin = 0;
        this.travelHours = 0;
      } else {
        this.showTimeSheetFieldByType = true;
      }
    },
    proceeedToGetMemberTime: function proceeedToGetMemberTime(id) {
      if (id != undefined) {
        this.$store.dispatch('getTimesheetMembersWorkTime', id);
      }
    }
  })
};