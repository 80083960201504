import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import SubsscriptionAddCardDialog from "@/components/subscriptions/subscriptions-add-card-dialog.vue";
import SubscriptionDefaultCardConfirmDialog from "@/components/subscriptions/subscriptions-default-card-confirm-dialog.vue";
import { mapMutations } from "vuex";
import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";
import { openOverlay } from "@/utils/helpers";
import ButtonMiniTertiaryDark from "@/components/shared/buttons/button-mini-tertiary-dark.vue";
import ButtonMiniPrimary from "@/components/shared/buttons/button-mini-primary.vue";
import { enableDashboardNavigation } from "@/utils/helpers/layout-helpers";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { DeleteItemDialog } from "@/components/shared/dialogs/index";
import { SubscriptionStatusType } from "@/enum/subscriptionStatusType";
import { InvoiceStatusType } from "@/enum/invoiceStatusType";
export default {
  data: function data() {
    return {
      dialogDeleteCard: false,
      cardIdToDelete: null,
      showPromoCodeApplyDialog: false,
      enteredPromoCodeValue: null,
      SubscriptionStatusType: SubscriptionStatusType,
      InvoiceStatusType: InvoiceStatusType
    };
  },
  mixins: [subscriptionsComputedMixin],
  components: {
    SubsscriptionAddCardDialog: SubsscriptionAddCardDialog,
    SubscriptionDefaultCardConfirmDialog: SubscriptionDefaultCardConfirmDialog,
    ButtonMiniTertiaryDark: ButtonMiniTertiaryDark,
    ButtonMiniPrimary: ButtonMiniPrimary,
    Button: Button,
    DeleteItemDialog: DeleteItemDialog
  },
  mounted: function mounted() {
    this.setClearCardsList();
    this.$store.dispatch("getCardsList");
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    setClearCardsList: "setClearCardsList"
  })), {}, {
    proceedDeleteCard: function proceedDeleteCard() {
      var _this = this;
      this.$store.dispatch("deleteCard", this.cardIdToDelete).then(function () {
        _this.$store.dispatch("getCardsList");
      });
      this.dialogDeleteCard = false;
    },
    cancelCardDeletion: function cancelCardDeletion() {
      this.dialogDeleteCard = false;
      this.cardIdToDelete = null;
    },
    toggleManageCardsFormView: function toggleManageCardsFormView() {
      this.enableCardAddButton = false;
      openOverlay("manageCardsFormOverlay");
    },
    applyPromoCode: function applyPromoCode() {
      if (this.subscriptionPromocodeApplied) {
        this.removePromoCode();
      } else {
        this.subscriptionPromocode = this.enteredPromoCodeValue;
        this.$store.dispatch("getPromoCodeDetails");
      }
    },
    checkPaymentStatus: function checkPaymentStatus() {
      var _this2 = this;
      setInterval(function () {
        if (_this2.subscriptionDetails && _this2.subscriptionDetails.latestInvoice != null && _this2.subscriptionDetails.latestInvoice.status == 1) {
          _this2.$store.dispatch("getSubscriptionDetails").then(function (subResponse) {
            if (subResponse) {
              if (subResponse.latestInvoice && subResponse.latestInvoice.status == 2) {
                enableDashboardNavigation();
                location.reload();
              }
            }
          });
        }
      }, 10000);
    },
    payBill: function payBill(invoicePaymentUrl) {
      window.open(invoicePaymentUrl, "_blank");
    },
    removePromoCode: function removePromoCode() {
      this.enteredPromoCodeValue = null;
      this.$store.commit("setClearPromoCodeInfo");
    },
    initiateSubscription: function initiateSubscription() {
      this.showPromoCodeApplyDialog = false;
      this.createSubscription();
    },
    createSubscription: function createSubscription() {
      this.$store.dispatch("createStripeSubscription").then(function () {
        setTimeout(function () {
          location.reload();
        }, 3000);
      });
    }
  })
};