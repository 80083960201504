import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { routeCustomers } from "@/utils/endpoints";
import SubHeaderPrimary from '@/components/shared/headers/subheader-primary.vue';
import DescriptionPrimary from '@/components/shared/headers/description-primary.vue';
import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import integrationsComputedMixin from '@/components/Integrations/integrations-computed';
import integrationCard from '@/components/shared/integration/integration-card.vue';
import { mapMutations } from 'vuex';
import { DeleteItemDialog } from '@/components/shared/dialogs/index';
import { notify } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
export default {
  data: function data() {
    return {
      dialogDisconnectIntegration: false,
      disconnectTypeText: '',
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      routeCustomers: routeCustomers
    };
  },
  components: {
    SubHeaderPrimary: SubHeaderPrimary,
    DescriptionPrimary: DescriptionPrimary,
    integrationCard: integrationCard,
    DeleteItemDialog: DeleteItemDialog
  },
  mixins: [integrationsComputedMixin, requestsCancellationMixin],
  computed: {
    currentPageType: {
      get: function get() {
        return this.integrationType ? this.integrationType.toLowerCase() : '';
      }
    },
    checkIntegration: function checkIntegration() {
      if (this.quickBookAccess[this.quickbookAccessState] == ConstantValues.quickbookAccessStruct['quickbookAccess'] || this.xeroAccess[this.xeroAccessState] == ConstantValues.xeroAccessStruct['xeroAccess'] || this.sageAccess[this.sageAccessState] == ConstantValues.sageAccessStruct['sageAccess']) {
        return true;
      } else {
        return false;
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.loadingIntegrationBuffer = true;
    this.destroyAllState();
  },
  beforeMount: function beforeMount() {
    this.loadingIntegrationBuffer = true;
    this.destroyAllState();
  },
  mounted: function mounted() {
    var _this = this;
    this.$store.state.dashboardTitle = 'Integrations';
    var urlParams = new URLSearchParams(window.location.search);
    var error = urlParams.get('error');
    if (error) {
      notify(error, "fail");
      this.integrationsAuthenticationInProgress = false;
      this.loadingIntegrationBuffer = false;
      return;
    }
    this.bufferServiceConnection();
    this.$store.dispatch("getExternalInvoicingServiceAccess").then(function () {
      if (_this.quickbookAccessState == 2) {
        if (_this.currentPageType != 'quickbooks') {
          _this.integrationType = 'quickbooks';
        }
      }
      if (_this.xeroAccessState == 2) {
        if (_this.currentPageType != 'xero') {
          _this.integrationType = 'xero';
        }
      }
      if (_this.sageAccessState == 2) {
        if (_this.currentPageType != 'sage') {
          _this.integrationType = 'sage';
        }
      }
    });
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    setClearIntegrations: 'setClearIntegrations'
  })), {}, {
    destroyAllState: function destroyAllState() {
      this.integrationType = null;
      this.setClearIntegrations();
      this.xeroAccessState = 0;
      this.quickbookAccessState = 0;
      this.sageAccessState = 0;
    },
    showDialogDisconnectIntegration: function showDialogDisconnectIntegration(dialogType) {
      switch (dialogType) {
        case 'quickbooks':
          this.disconnectTypeText = 'QuickBooks';
          this.dialogDisconnectIntegration = true;
          break;
        case 'xero':
          this.disconnectTypeText = 'Xero';
          this.dialogDisconnectIntegration = true;
          break;
        case 'sage':
          this.disconnectTypeText = 'Sage';
          this.dialogDisconnectIntegration = true;
          break;
      }
    },
    disconnectIntegration: function disconnectIntegration(disconnectType) {
      if (!disconnectType) disconnectType = this.integrationType.toLowerCase();
      this.dialogDisconnectIntegration = false;
      switch (disconnectType) {
        case 'quickbooks':
          this.confirmDisconnectQuickBooks();
          break;
        case 'xero':
          this.confirmDisconnectXero();
          break;
        case 'sage':
          this.confirmDisconnectSage();
          break;
      }
    },
    bufferServiceConnection: function bufferServiceConnection() {
      var _this2 = this;
      this.loadingIntegrationBuffer = true;
      setTimeout(function () {
        _this2.loadingIntegrationBuffer = false;
      }, ConstantValues.defaultIntegrationBufferTimeout);
    },
    confirmDisconnectQuickBooks: function confirmDisconnectQuickBooks() {
      this.$store.dispatch('disconnectIntegration', 'quickbooks');
    },
    confirmDisconnectXero: function confirmDisconnectXero() {
      this.$store.dispatch('disconnectIntegration', 'xero');
    },
    confirmDisconnectSage: function confirmDisconnectSage() {
      this.$store.dispatch('disconnectIntegration', 'sage');
    },
    integrateXero: function integrateXero() {
      this.bufferServiceConnection();
      this.$store.dispatch("startIntegrationAuthentication", 'xero');
    },
    integrateQuickbooks: function integrateQuickbooks() {
      this.bufferServiceConnection();
      this.$store.dispatch("startIntegrationAuthentication", 'quickbooks');
    },
    integrateSage: function integrateSage() {
      this.bufferServiceConnection();
      this.$store.dispatch("startIntegrationAuthentication", 'sage');
    }
  })
};