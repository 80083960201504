import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { Product } from '@/data/models/Product';
import Vue from 'vue';
export var state = {
  productsList: new Array(),
  productDetails: new Product(),
  productLoader: false,
  productSearchText: '',
  addProductLoader: false,
  editProductLoader: false,
  productStatus: 0
};
export var getters = {
  productDetails: function productDetails(state) {
    return state.productDetails;
  },
  productLoader: function productLoader(state) {
    return state.productLoader;
  },
  productSearchText: function productSearchText(state) {
    return state.productSearchText;
  },
  productsList: function productsList(state) {
    return state.productsList;
  },
  addProductLoader: function addProductLoader(state) {
    return state.addProductLoader;
  },
  editProductLoader: function editProductLoader(state) {
    return state.editProductLoader;
  },
  productStatus: function productStatus(state) {
    return state.productStatus;
  }
};
export var mutations = {
  setProductstFilteredList: function setProductstFilteredList(state, data) {
    data.forEach(function (x) {
      return state.productsList.push(new Product().SetData(x));
    });
  },
  setProductDetails: function setProductDetails(state, data) {
    state.productDetails = new Product().SetData(data);
  },
  setAddProduct: function setAddProduct(state, data) {
    state.productsList.unshift(data);
  },
  updateProduct: function updateProduct(state, data) {
    var index = state.productsList.findIndex(function (element, index) {
      if (element.id == data.id) {
        return true;
      }
    });
    if (index >= 0) {
      Vue.set(state.productsList, index, new Product().SetData(data));
    }
  },
  resetProductDetails: function resetProductDetails(state) {
    state.productDetails = new Product();
    state.editProductLoader = false;
  }
};