import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "v-overlay " + (_vm.value ? "flyout-active" : ""),
      attrs: { id: "flyout-container" },
      on: {
        click: _vm.handleOutsideClick,
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.handleOutsideClick($event)
        },
      },
    },
    [
      !_vm.noOverlay ? _c("div", { staticClass: "flyout-overlay" }) : _vm._e(),
      _c(
        "Transition",
        { attrs: { name: "slide" } },
        [
          _vm.value
            ? _c(
                VSheet,
                _vm._b(
                  {
                    class: "flyout " + _vm.flyoutClass,
                    attrs: { width: _vm.width || 500 },
                  },
                  "v-sheet",
                  _vm.$attrs,
                  false
                ),
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between px-8 py-5" },
                    [
                      _vm._t("flyout-header"),
                      _c(
                        VIcon,
                        {
                          attrs: { size: "20", color: "black" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("input", false)
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    2
                  ),
                  _c(VDivider, { attrs: { light: "" } }),
                  _vm._t("default"),
                  _c(
                    "div",
                    { staticClass: "flyout-bottom" },
                    [_vm._t("flyout-bottom")],
                    2
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }