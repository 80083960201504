import "/home/vsts/work/1/s/src/web/dashboard/node_modules/core-js/modules/es.array.iterator.js";
import "/home/vsts/work/1/s/src/web/dashboard/node_modules/core-js/modules/es.promise.js";
import "/home/vsts/work/1/s/src/web/dashboard/node_modules/core-js/modules/es.object.assign.js";
import "/home/vsts/work/1/s/src/web/dashboard/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import { VueEditor } from "vue2-editor";
Vue.config.productionTip = true;
Vue.use(StringConstants);
Vue.use(ConstantValues);
Vue.use(VueEditor);
new Vue({
  router: router,
  store: store,
  vuetify: vuetify,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');
store.dispatch('checkUserLoggedIn');