import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from "vuex";
import EllipsisMiniLoader from "./loaders/ellipsis-loader-mini.vue";
import PaginationModule from "@/store/modules/pagination";
import ConstantValues from '@/plugins/constantValues';
export default {
  name: "LoadMoreItem",
  props: ["searchText", "loadedListLength", "moduleNamespace"],
  components: {
    EllipsisMiniLoader: EllipsisMiniLoader
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    moduleState: function moduleState(state) {
      return state[this.moduleNamespace];
    },
    hasMoreData: function hasMoreData(state, getters) {
      return getters["".concat(this.moduleNamespace, "/showLoadMoreButtonGetter")];
    }
  })), {}, {
    showMiniLoader: function showMiniLoader() {
      return this.moduleState.loading && (this.loadedListLength < this.moduleState.totalRowCount || this.moduleState.totalRowCount == ConstantValues.defaultPageTotalRowCount);
    }
  }),
  created: function created() {
    this.$emit("onMounted");
  },
  beforeDestroy: function beforeDestroy() {
    this.unregisterFilterPaginationModules();
  },
  methods: {
    handleClick: function handleClick() {
      this.$store.commit("".concat(this.moduleNamespace, "/fetchingData"));
      this.$emit("loadMoreItems");
    },
    registerFilterPaginationModules: function registerFilterPaginationModules() {
      if (!this.$store.hasModule(this.moduleNamespace)) {
        this.$store.registerModule(this.moduleNamespace, PaginationModule);
      }
    },
    unregisterFilterPaginationModules: function unregisterFilterPaginationModules() {
      if (this.$store.hasModule(this.moduleNamespace)) {
        this.$store.unregisterModule(this.moduleNamespace);
      }
    }
  }
};