import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "py-8", attrs: { id: "job-billing-information" } },
      [
        _vm.teamJobOperation != null
          ? _c("div", { staticClass: "pb-10" }, [
              _c("div", { staticClass: "tj-cp-title" }, [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("teamJobBillingTitle")) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "tj-cp-subtitle" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("teamJobBillingSubTitle")) +
                    " "
                ),
              ]),
            ])
          : _vm._e(),
        _vm.teamJobOperation != null
          ? _c(
              "div",
              [
                _c(
                  VRow,
                  {
                    staticClass: "jb-dt-hd jb-operators-list-head",
                    staticStyle: { "border-bottom": "none" },
                    attrs: { col: "12" },
                  },
                  [
                    _c("div", { staticClass: "width-perc-60" }, [
                      _vm._v(" " + _vm._s(_vm.$stringConstants("title")) + " "),
                    ]),
                    _c("div", { staticClass: "width-perc-20" }, [
                      _vm._v(" " + _vm._s(_vm.$stringConstants("value")) + " "),
                    ]),
                    _c("div", { staticClass: "width-perc-20 pl-1" }, [
                      _vm._v(" " + _vm._s(_vm.$stringConstants("unit")) + " "),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.teamJobsLoader &&
        _vm.teamJobOperation &&
        _vm.teamJobOperation.billingQuantities.length > 0
          ? _c(
              "div",
              _vm._l(
                _vm.teamJobOperation.billingQuantities,
                function (oprQty, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "px-7 py-3",
                      staticStyle: { border: "0.5px solid #eee" },
                      attrs: { id: "job-billing-information-fields" },
                    },
                    [
                      _c(VRow, { attrs: { col: "12" } }, [
                        _c("div", { staticClass: "width-perc-60" }, [
                          _c(
                            "div",
                            { staticClass: "section-sub-title pt-3 fw-6" },
                            [_vm._v(_vm._s(_vm.teamJobOperation.title))]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "width-perc-20 pr-1" },
                          [
                            _c(VTextField, {
                              staticClass: "hide_input_details",
                              class: { "light-input": _vm.isDetailsView },
                              attrs: {
                                outlined: "",
                                height: "48",
                                placeholder: _vm.$stringConstants("value"),
                                disabled:
                                  !_vm.teamJobViewPreference.billingInformation
                                    .isEditable,
                                dense: "",
                                required: "",
                              },
                              on: { input: _vm.onBillingValueChange },
                              model: {
                                value: oprQty.billingQuantity,
                                callback: function ($$v) {
                                  _vm.$set(
                                    oprQty,
                                    "billingQuantity",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "oprQty.billingQuantity",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "width-perc-20 pl-1" },
                          [
                            _c(VTextField, {
                              staticClass: "hide_input_details rd-lf-no",
                              class: { "dark-input": _vm.isDetailsView },
                              attrs: {
                                outlined: "",
                                height: "48",
                                dense: "",
                                disabled: "",
                                required: "",
                              },
                              on: { input: _vm.onBillingValueChange },
                              model: {
                                value: _vm.teamJobOperation.billingUnit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.teamJobOperation,
                                    "billingUnit",
                                    $$v
                                  )
                                },
                                expression: "teamJobOperation.billingUnit",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                }
              ),
              0
            )
          : _c("div", [
              _c(
                "div",
                {
                  staticClass: "px-7 py-3",
                  staticStyle: { border: "0.5px solid #eee" },
                },
                [
                  _c(VRow, { attrs: { col: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "width-perc-60" },
                      [
                        _c(VSkeletonLoader, {
                          attrs: { width: "200", height: "30", type: "card" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "width-perc-20 pr-1" },
                      [
                        _c(VSkeletonLoader, {
                          attrs: { height: "30", type: "card" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "width-perc-20 pl-1" },
                      [
                        _c(VSkeletonLoader, {
                          attrs: { height: "30", type: "card" },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "width-perc-5 text-right" }),
                  ]),
                ],
                1
              ),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }