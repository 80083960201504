import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1:
                    _vm.$stringConstants("fieldDetailsHeading1") +
                    _vm.contactFieldList.length +
                    ")",
                  textValue2: _vm.$stringConstants("fieldDetailsHeading2"),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                _vm._l(_vm.contactFieldList, function (field, inx) {
                  return _c(
                    "div",
                    { key: inx, staticClass: "pr-6 pb-3 fd-bx-wd d-flex" },
                    [
                      _c("div", {
                        staticClass: "ib v-a-t sm-cir",
                        style: { background: field.colour },
                      }),
                      _c("div", { staticClass: "ib" }, [
                        _c("div", { staticClass: "rd-details" }, [
                          _vm._v(" " + _vm._s(field.title) + " "),
                        ]),
                        _c("div", { staticClass: "rd-label pt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getAreaFormatted(field.areaInSquareMeter)
                              )
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(VCol, { attrs: { cols: "3" } }, [
            _c(
              "div",
              {
                staticClass: "ank-txt",
                on: { click: _vm.openContactFieldsOnMap },
              },
              [_vm._v(_vm._s(_vm.$stringConstants("seeOnMap")))]
            ),
          ]),
        ],
        1
      ),
      _c("MapDialogBox", {
        attrs: { item: _vm.mapDialog, btnText: "Close" },
        on: { proceed: _vm.closeMapDialogBox },
        scopedSlots: _vm._u([
          {
            key: "map-box",
            fn: function () {
              return [
                _c("div", {
                  staticStyle: { height: "520px", width: "900px" },
                  attrs: { id: "contactFieldsOnMap" },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }