import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import { mapMutations } from 'vuex';
import SuDashboardCurrentStatisticsCounter from './su-overview-current-statistics-counter.vue';
import suOverviewComputedMixin from './su-overview-computed';
import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
import StringConstants from '@/plugins/stringConstants';
import StatsLoader from './su-overview-stats-loader.vue';
import moment from 'moment';
import { clearDateTimePicker, initializeDateTimePlaceholders } from '@/utils/helpers';
import { defaultDateFormat } from '@/utils/constants';
export default {
  data: function data() {
    return {
      dateInput: {
        placeholder: StringConstants.selectDatePlaceholder
      },
      format: defaultDateFormat,
      sameDateFormat: {
        from: defaultDateFormat,
        to: defaultDateFormat
      }
    };
  },
  components: {
    SuDashboardCurrentStatisticsCounter: SuDashboardCurrentStatisticsCounter,
    DatePicker: DatePicker,
    StatsLoader: StatsLoader
  },
  mixins: [suOverviewComputedMixin],
  mounted: function mounted() {
    this.clearCurrentStatisticsDateFilter();
    this.getCurrentStatistics();
    initializeDateTimePlaceholders();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    resetPaging: "resetPaging",
    clearCurrentStatisticsFilterOptions: "setClearFilterOptions"
  })), {}, {
    filterCurrentStatisticsByDateRange: function filterCurrentStatisticsByDateRange(start, end) {
      this.adminOverviewRecentStatisticsStartDate = moment(start).utc().format();
      this.adminOverviewRecentStatisticsEndDate = moment(end).utc().format();
      this.refreshCurrentStatistics();
      this.adminOverviewCurrentStatisticsDateFilterApplied = true;
    },
    refreshCurrentStatistics: function refreshCurrentStatistics() {
      this.clearCurrentStatisticsFilterOptions();
      this.resetPaging();
      this.getCurrentStatistics();
    },
    getCurrentStatistics: function getCurrentStatistics() {
      var _this = this;
      this.$store.dispatch('setRecentStatisticsFilter').then(function () {
        _this.$store.dispatch('getRecentStatistics');
      });
    }
  })
};