import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
export default {
  name: "JobsReport",
  computed: {
    jobId: function jobId() {
      return this.$route.params.id;
    }
  },
  data: function data() {
    return {
      jobsReportURI: null
    };
  },
  created: function created() {
    setTimeout(function () {
      var navDrawer = document.querySelector('.v-navigation-drawer');
      navDrawer.style.display = 'none';
      var systemBar = document.querySelector('.v-system-bar');
      systemBar.style.display = 'none';
      document.querySelector('.v-main').classList.remove('pL-main');
    }, 100);
  },
  mounted: function mounted() {
    var reportUrl = new URLSearchParams(window.location.search);
    this.jobsReportURI = reportUrl.get('pdf_url');
  }
};