import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "file-thumbnail-container cursor-pointer",
      on: { click: _vm.openFileInNewTab },
    },
    [
      _c(VIcon, { staticClass: "pdf-icon-size" }, [
        _vm._v(" mdi-file-pdf-box "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }