import { SubscriptionStatusTypeLabel } from "@/enum/subscriptionStatusType";
import { getDateDifferenceInDays, getDateDifferenceInMinutes, getDateTimeFormattedLocal } from "../helpers";
import { cancellationScheduledOnText, cancelledOnText } from "../strings";
export var getSusbcriptionAmountIfExists = function getSusbcriptionAmountIfExists(business, showSymbol) {
  if (business.subscriptions && business.subscriptions.invoices.length > 0 && business.subscriptions.invoices[0].amount) {
    return (showSymbol ? localStorage.getItem("currencySymbol") : '') + business.subscriptions.invoices[0].amount;
  }
  return '';
};
export var getTrialDaysLeft = function getTrialDaysLeft(trialEndDate) {
  var differenceInDays = parseInt(getDateDifferenceInDays(trialEndDate));
  var remainingTrialInMinutes = parseInt(getDateDifferenceInMinutes(trialEndDate));
  var isTrialExpired = remainingTrialInMinutes <= 0;
  var trialingText = 'Trialing (';
  if (isTrialExpired) {
    return 'Trial expired';
  }
  if (differenceInDays > 0) {
    return trialingText + differenceInDays + ' days left)';
  }
  if (differenceInDays == 0) {
    return trialingText + 'Expiring today' + ')';
  }
};
export var getSubscriptionStatusEnum = function getSubscriptionStatusEnum(status) {
  return status !== null ? SubscriptionStatusTypeLabel.get(status) : '-';
};
export var getCancellationDate = function getCancellationDate(subscriptions) {
  if (subscriptions && subscriptions.cancellationScheduledOn) {
    return getCancellationScheduledText(subscriptions);
  }
  return '';
};
export var getCancellationScheduledText = function getCancellationScheduledText(subsciption) {
  if (parseInt(getDateDifferenceInMinutes(subsciption.cancellationScheduledOn)) > 0) {
    return cancellationScheduledOnText + getDateTimeFormattedLocal(subsciption.cancellationScheduledOn);
  }
  return cancelledOnText + getDateTimeFormattedLocal(subsciption.cancellationScheduledOn);
};