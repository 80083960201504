import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import moment from 'moment';
export var getTimeDetailsFormatted = function getTimeDetailsFormatted(time) {
  var timeInMinutes = Math.floor(time / 60);
  var hour = Math.floor(timeInMinutes / 60).toString();
  var min = (timeInMinutes % 60).toString();
  if (hour.length != 2) {
    hour = '0' + hour;
  }
  if (min.length != 2) {
    min = '0' + min;
  }
  return {
    HH: hour,
    mm: min
  };
};
export var getDateFormated = function getDateFormated(date) {
  return moment.utc(date).local().format("YYYY-MM-DD HH:mm");
};
export var getTimeFormated = function getTimeFormated(date) {
  return moment.utc(date).local().format("HH:mm");
};
export var getTimeInSeconds = function getTimeInSeconds(time) {
  var dur = time.split(':');
  var hour = parseInt(dur[0]);
  var min = parseInt(dur[1]);
  var ttlSec = hour * 60 * 60 + min * 60;
  return ttlSec;
};
export var getTimeDetailsRequestFormated = function getTimeDetailsRequestFormated(date) {
  var dT = moment.utc(moment(date)).format();
  return dT;
};
export var getTimeRequestFormated = function getTimeRequestFormated(hoursMins) {
  var sec = parseInt(hoursMins.HH) * 60 * 60 + parseInt(hoursMins.mm) * 60;
  return sec;
};