import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    {
      staticClass: "login-registration-wrapper",
      staticStyle: { "min-height": "100vh" },
      attrs: { id: "agd-login-view" },
    },
    [
      _c(
        VCol,
        {
          attrs: {
            cols: "6 pa-0 d-none d-sm-flex",
            id: "banner-image-wrapper",
          },
        },
        [
          _c("div", { staticClass: "first-gradient" }),
          _c("div", { staticClass: "second-gradient" }),
          _c("div", { staticClass: "tagline-wrapper" }, [
            _c(
              "span",
              { staticClass: "f-28 font-weight-bold d-block white--text pb-3" },
              [_vm._v(_vm._s(_vm.$stringConstants("homepageMainTitle")))]
            ),
            _c("span", { staticClass: "f-16 white--text" }, [
              _vm._v(_vm._s(_vm.$stringConstants("homepageSubtitle"))),
            ]),
          ]),
        ]
      ),
      _c(VCol, { attrs: { cols: "12 d-flex pa-0 scroll-view", sm: "6" } }, [
        _c("div", { staticClass: "form-wrapper" }, [_vm._t("default")], 2),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }