import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { UserRoleType, UserRoleTypeLabel } from "@/enum/userRoleType";
import { getComputedProperties } from "@/utils/helpers/computed-generator";
var computedProperties = {
  'usersModule': ['userInviteeEmail', 'usersLoader', 'userInviteeRole'],
  'teamMembersModule': ['teamMemberDetails', 'teamMembeOldRole', 'teamMembersList', 'pendingTeamMemberList', 'teamMemberSearchText', 'memberFilterType', 'teamMembersLoader'],
  'root': ['enumMemberRoles', 'noData', 'filtersApplied', 'totalRowCount', 'listCount', 'isPageRelated', 'userStatusFilterTypeList', 'enumUserRoles', 'allItemsDeleted', 'allItemsArchived']
};
export default {
  name: 'TeamMembersComputedMixin',
  computed: _objectSpread({}, getComputedProperties(computedProperties)),
  methods: {
    showTeamMemberRoleFromType: function showTeamMemberRoleFromType(roleType) {
      if (roleType != undefined) {
        return UserRoleTypeLabel.get(UserRoleType[UserRoleType[roleType]]);
      }
    },
    showTeamMemberRoleFromRolesList: function showTeamMemberRoleFromRolesList(roles) {
      if (roles && roles.length) {
        return UserRoleTypeLabel.get(UserRoleType[UserRoleType[roles[0].type]]);
      }
    }
  }
};