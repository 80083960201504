import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import onboardComputedMixin from "@/components/onboard/_onboard-computed";
import { formFieldPb } from "@/utils/uiconstants";
import { validateRegexAlphaNumeric } from "@/utils/helpers";
export default {
  mixins: [onboardComputedMixin],
  data: function data() {
    return {
      formFieldPb: formFieldPb
    };
  },
  watch: {
    businessProfilePrefixTemp: function businessProfilePrefixTemp() {
      this.businessProfilePrefixTemp = this.getValidatedString(this.businessProfilePrefixTemp);
    }
  },
  methods: {
    getValidatedString: function getValidatedString(rawString) {
      return rawString.toString().replace(/\s+/g, "").replace(/[0-9]/g, "").replace(/\./g, "").toUpperCase();
    },
    updatePrefix: function updatePrefix(event) {
      var shortenedBusinessName = this.getValidatedString(event.currentTarget.value).substring(0, 3);
      if (shortenedBusinessName.length == 3 && (this.userProfile.businessProfile == null || this.userProfile.businessProfile && (this.userProfile.businessProfile.shortTitle == null || this.userProfile.businessProfile.shortTitle.length == 0))) {
        this.businessProfilePrefixTemp = shortenedBusinessName;
      }
    },
    checkKeyDownAlphaNumeric: function checkKeyDownAlphaNumeric(event) {
      validateRegexAlphaNumeric(event);
    }
  }
};