import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-3", staticStyle: { display: "inline-grid" } },
    [
      _c(
        "div",
        {
          staticClass: "crd-sl crd-qb",
          class: _vm.showDisconnectBtn ? "crd-active" : "",
        },
        [
          _c("div", { staticClass: "pb-4" }, [
            _c(
              "span",
              { staticClass: "d-inline-block integration-card__image" },
              [
                !(_vm.loadingIntegration || _vm.loadingIntegrationBuffer)
                  ? _c("span", [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/" + _vm.imageUrl),
                          width: "48",
                        },
                      }),
                    ])
                  : _c("span", [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/integration-loader-circle.png"),
                        },
                      }),
                    ]),
              ]
            ),
            _c(
              "span",
              { staticClass: "float-right" },
              [
                !(_vm.loadingIntegration || _vm.loadingIntegrationBuffer)
                  ? _c(
                      "span",
                      [
                        !_vm.showDisconnectBtn && !_vm.isAnyIntegration
                          ? _c("Button", {
                              staticClass: "ml-3",
                              attrs: {
                                variant: "integrationConnect",
                                color: "tertiary",
                                textValue: "Connect",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.$emit("connectService")
                                },
                              },
                            })
                          : _vm.showDisconnectBtn
                          ? _c("Button", {
                              attrs: {
                                variant: "integration",
                                color: "#fa4e4e",
                                outlined: "",
                                outlineColor: "#fa4e4e",
                                textValue: _vm.disconnectText,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.$emit("disconnectService")
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.isAnyIntegration && !_vm.showDisconnectBtn
                          ? _c(
                              "span",
                              [
                                _c(
                                  VTooltip,
                                  {
                                    attrs: {
                                      bottom: "",
                                      color: "#000",
                                      "max-width": "300px",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                VIcon,
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      staticStyle: {
                                                        color:
                                                          "#000 !important",
                                                      },
                                                      attrs: { small: "" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2551011642
                                    ),
                                  },
                                  [
                                    _c("span", { staticClass: "tol-cont" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$stringConstants(
                                              "disconnectOtherServicesStartingText"
                                            )
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$stringConstants(
                                              "disconnectOtherServicesEndingText"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(VSkeletonLoader, {
                      attrs: {
                        type: "button",
                        "max-height": "32",
                        loading:
                          _vm.loadingIntegration ||
                          _vm.loadingIntegrationBuffer,
                      },
                    }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "fw-7 text-uppercase" },
            [
              !(_vm.loadingIntegration || _vm.loadingIntegrationBuffer)
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.integrationServiceTitle) + " "),
                  ])
                : _c(VSkeletonLoader, {
                    attrs: {
                      loading:
                        _vm.loadingIntegration || _vm.loadingIntegrationBuffer,
                      type: "text",
                      "max-width": "25%",
                    },
                  }),
            ],
            1
          ),
          _vm.showDisconnectBtn &&
          !(_vm.loadingIntegration || _vm.loadingIntegrationBuffer)
            ? _c("div", { staticClass: "pt-3 fn-sm" }, [
                _c("div", [_vm._v(_vm._s(_vm.integrationAccount.accountName))]),
                _c("div", { staticClass: "pt-1" }, [
                  _vm._v(
                    _vm._s(_vm.$stringConstants("connectedOnText")) +
                      " " +
                      _vm._s(
                        _vm.getDateTimeFormat(
                          _vm.integrationAccount.connectedOn
                        )
                      )
                  ),
                ]),
              ])
            : _vm.loadingIntegration || _vm.loadingIntegrationBuffer
            ? _c(
                "div",
                { staticClass: "pt-3" },
                [
                  _c(VSkeletonLoader, {
                    staticClass: "pt-2",
                    attrs: {
                      "max-width": "25%",
                      type: "text",
                      loading:
                        _vm.loadingIntegration || _vm.loadingIntegrationBuffer,
                    },
                  }),
                  _c(VSkeletonLoader, {
                    attrs: {
                      type: "text",
                      "max-width": "50%",
                      loading:
                        _vm.loadingIntegration || _vm.loadingIntegrationBuffer,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }