import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-9" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(
              VCol,
              { attrs: { cols: "8" } },
              [_c("HeaderPrimary", { attrs: { textValue: "Edit timesheet" } })],
              1
            ),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    staticClass: "oy-cl",
                    attrs: { small: "", id: "closeTimeSheetEditForm" },
                    on: { click: _vm.closeTimeSheetEditForm },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form-scroll-view" },
      [
        _c(
          VRow,
          { staticClass: "px-9 py-7", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(
              VCol,
              { attrs: { cols: "12 pa-0" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("memberNameText")) + " * "
                  ),
                ]),
                _c(VTextField, {
                  attrs: { readonly: "", outlined: "", dense: "" },
                  model: {
                    value: _vm.timesheetDetails.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.timesheetDetails, "name", $$v)
                    },
                    expression: "timesheetDetails.name",
                  },
                }),
              ],
              1
            ),
            _c(
              VCol,
              { attrs: { cols: "12 pa-0" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("timeSheetTypeText")) +
                      " * "
                  ),
                ]),
                _c(VSelect, {
                  attrs: {
                    items: _vm.timesheetsTypeList,
                    "item-value": "value",
                    "item-text": "title",
                    placeholder: "Select type",
                    dense: "",
                    outlined: "",
                  },
                  model: {
                    value: _vm.timesheetDetails.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.timesheetDetails, "type", $$v)
                    },
                    expression: "timesheetDetails.type",
                  },
                }),
              ],
              1
            ),
            _c(
              VCol,
              { attrs: { cols: "12 pa-0" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("dateText")) + " * "
                  ),
                ]),
                _c(
                  VMenu,
                  {
                    ref: "menuDate",
                    attrs: {
                      "close-on-content-click": false,
                      "return-value": _vm.timesheetDetails.date,
                      transition: "scale-transition",
                      "offset-y": "",
                      "min-width": "auto",
                    },
                    on: {
                      "update:returnValue": function ($event) {
                        return _vm.$set(_vm.timesheetDetails, "date", $event)
                      },
                      "update:return-value": function ($event) {
                        return _vm.$set(_vm.timesheetDetails, "date", $event)
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              VTextField,
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      value: _vm.computedDateFormatted,
                                      placeholder: _vm.$stringConstants(
                                        "selectDatePlaceholder"
                                      ),
                                      "append-icon": "mdi-calendar",
                                      readonly: "",
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: {
                                      "click:append": on.click,
                                      "click:clear": function ($event) {
                                        _vm.timesheetDetails.date = null
                                      },
                                    },
                                  },
                                  "v-text-field",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.menuDate,
                      callback: function ($$v) {
                        _vm.menuDate = $$v
                      },
                      expression: "menuDate",
                    },
                  },
                  [
                    _c(
                      VDatePicker,
                      {
                        attrs: {
                          "no-title": "",
                          color: "#1d34ad",
                          scrollable: "",
                        },
                        model: {
                          value: _vm.timesheetDetails.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.timesheetDetails, "date", $$v)
                          },
                          expression: "timesheetDetails.date",
                        },
                      },
                      [
                        _c(VSpacer),
                        _c(
                          VBtn,
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.menuDate = false
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          VBtn,
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.menuDate.save(
                                  _vm.timesheetDetails.date
                                )
                              },
                            },
                          },
                          [_vm._v(" OK ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.timesheetDetails.type != _vm.TimeSheetType.Holiday &&
            _vm.timesheetDetails.type != _vm.TimeSheetType.Sick
              ? _c(
                  VCol,
                  { attrs: { cols: "12 pa-0" } },
                  [
                    _c(
                      "div",
                      { staticClass: "field-input-label pt-2 text-uppercase" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("timeDetailsText")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      VRow,
                      {
                        staticClass: "pt-2",
                        attrs: { "no-gutters": "", cols: "12" },
                      },
                      [
                        _c(
                          VCol,
                          { staticClass: "pb-0 pr-2", attrs: { cols: "6" } },
                          [
                            _c("div", { staticClass: "field-input-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$stringConstants("startTime")) +
                                  " * "
                              ),
                            ]),
                            _c("VueCtkDateTimePicker", {
                              staticClass: "sl-time pb-6",
                              attrs: {
                                color: "#1d34ad",
                                noKeyboard: "",
                                format: "HH:mm",
                                formatted: "HH:mm",
                                "append-icon": "mdi-clock-time-four-outline",
                                "no-label": "",
                                "only-time": "",
                                noClearButton: "",
                              },
                              model: {
                                value: _vm.timesheetDetails.startTimeConverted,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetails,
                                    "startTimeConverted",
                                    $$v
                                  )
                                },
                                expression:
                                  "timesheetDetails.startTimeConverted",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { staticClass: "pb-0 pl-2", attrs: { cols: "6" } },
                          [
                            _c("div", { staticClass: "field-input-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$stringConstants("endTime")) +
                                  " * "
                              ),
                            ]),
                            _c("VueCtkDateTimePicker", {
                              staticClass: "sl-time pb-6",
                              attrs: {
                                color: "#1d34ad",
                                noKeyboard: "",
                                format: "HH:mm",
                                formatted: "HH:mm",
                                "append-icon": "mdi-clock-time-four-outline",
                                "no-label": "",
                                "only-time": "",
                                noClearButton: "",
                              },
                              model: {
                                value: _vm.timesheetDetails.endTimeConverted,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetails,
                                    "endTimeConverted",
                                    $$v
                                  )
                                },
                                expression: "timesheetDetails.endTimeConverted",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.timesheetDetails.type != _vm.TimeSheetType.Holiday &&
            _vm.timesheetDetails.type != _vm.TimeSheetType.Sick
              ? _c(
                  VCol,
                  { attrs: { cols: "12 pa-0" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$stringConstants("travelTime")) + " "
                      ),
                    ]),
                    _c(
                      VRow,
                      { attrs: { "no-gutters": "", cols: "12" } },
                      [
                        _c(
                          VCol,
                          { staticClass: "py-0 pr-2 time-drop" },
                          [
                            _c(VSelect, {
                              attrs: {
                                items: _vm.hoursList,
                                "item-value": "hour",
                                "item-text": "hour",
                                suffix: "Hours",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value:
                                  _vm.timesheetDetails
                                    .travelTimeInMinutesConverted.hours,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetails
                                      .travelTimeInMinutesConverted,
                                    "hours",
                                    _vm._n($$v)
                                  )
                                },
                                expression:
                                  "timesheetDetails.travelTimeInMinutesConverted.hours",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { staticClass: "py-0 pl-2 time-drop" },
                          [
                            _c(VSelect, {
                              attrs: {
                                items: _vm.minutesList,
                                "item-value": "minute",
                                "item-text": "minute",
                                suffix: "Mins",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value:
                                  _vm.timesheetDetails
                                    .travelTimeInMinutesConverted.minute,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetails
                                      .travelTimeInMinutesConverted,
                                    "minute",
                                    _vm._n($$v)
                                  )
                                },
                                expression:
                                  "timesheetDetails.travelTimeInMinutesConverted.minute",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.timesheetDetails.type != _vm.TimeSheetType.Holiday &&
            _vm.timesheetDetails.type != _vm.TimeSheetType.Sick
              ? _c(
                  VCol,
                  { attrs: { cols: "12 pa-0" } },
                  [
                    _c("div", { staticClass: "field-input-label" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$stringConstants("breakTime")) + " "
                      ),
                    ]),
                    _c(
                      VRow,
                      { attrs: { "no-gutters": "", cols: "12" } },
                      [
                        _c(
                          VCol,
                          { staticClass: "py-0 pr-2 time-drop" },
                          [
                            _c(VSelect, {
                              attrs: {
                                items: _vm.hoursList,
                                "item-value": "hour",
                                "item-text": "hour",
                                suffix: "Hours",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value:
                                  _vm.timesheetDetails
                                    .breakTimeInMinutesConverted.hours,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetails
                                      .breakTimeInMinutesConverted,
                                    "hours",
                                    _vm._n($$v)
                                  )
                                },
                                expression:
                                  "timesheetDetails.breakTimeInMinutesConverted.hours",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { staticClass: "py-0 pl-2 time-drop" },
                          [
                            _c(VSelect, {
                              attrs: {
                                items: _vm.minutesList,
                                "item-value": "minute",
                                "item-text": "minute",
                                suffix: "Mins",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value:
                                  _vm.timesheetDetails
                                    .breakTimeInMinutesConverted.minute,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetails
                                      .breakTimeInMinutesConverted,
                                    "minute",
                                    _vm._n($$v)
                                  )
                                },
                                expression:
                                  "timesheetDetails.breakTimeInMinutesConverted.minute",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              VCol,
              { attrs: { cols: "12 pa-0" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(" " + _vm._s(_vm.$stringConstants("noteText")) + " "),
                ]),
                _c(VTextarea, {
                  attrs: {
                    outlined: "",
                    placeholder: "Type here",
                    "no-resize": "",
                    rows: "2",
                    "row-height": "15",
                  },
                  model: {
                    value: _vm.timesheetDetails.note,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.timesheetDetails,
                        "note",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "timesheetDetails.note",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btns-bottom-box px-8 py-4" },
      [
        _c("Button", {
          attrs: {
            variant: "primary",
            color: "primary",
            disabled: _vm.editTimesheetLoader || _vm.isDisabledBtn,
            loading: _vm.editTimesheetLoader,
            textValue: "Save changes",
            rightIcon: "mdi-arrow-right",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.editTimeSheet()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }