import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import { ResourceType } from "@/enum/resourceType";
import { subOperationImplementsDropdownModule, subOperationProductsDropdownModule, subOperationVehiclesDropdownModule } from "@/utils/strings";
import { getComputedProperties } from "@/utils/helpers/computed-generator";
import { routeAccount } from "@/utils/endpoints";
import router from "@/router";
import { AccountTabType } from "@/enum/accountTabType";
import ConstantValues from '@/plugins/constantValues';
var computedProperties = {
  'operationsModule': ['subOperationVehiclesList', 'editSubOperationLoader', 'subOperationImplementsList', 'subOperationProductsList', 'subOperationTypeId', 'showAddLoadInfoBtn', 'showAddCheckListItemBtn', 'showAddCheckListItemFields', 'showAddLoadInfoFields', 'selectedSubOperationName', 'selectedSubOperationId', 'subOperationName', 'subOperationBillingCost', 'subOperationBillingUnit', 'subOperationTaxPercentage', 'subOperationIsTaxable', 'subOperationVehicles', 'subOperationImplements', 'subOperationProducts', 'subOperationLoadInfoList', 'subOperationDescription', 'subOperationCheckListItems', 'subOperationVehiclesSearchText', 'subOperationImplementsSearchText', 'subOperationProductsSearchText', 'subOperationIsHealthSafetyListMandatory', 'subOperationLoader', 'operationTypesList', 'addOperationTypesList', 'requestOperationTypesList', 'showAddOperationFields', 'showAddOperationBtn', 'operationsLoader', 'operationSearchText', 'addSubOperationLoader', 'subOperationVehiclesListSize', 'subOperationImplementsListSize', 'subOperationProductsListSize', 'subOperationVehiclesDropdownLoader', 'subOperationImplementsDropdownLoader', 'subOperationProductsDropdownLoader', 'subOperationTaxWarningVisible'],
  'root': ['enumListLoadInfoUnits', 'enumListOperationUnits', 'noData', 'filtersApplied', 'isPageRelated', 'accountTab', 'isRoutedToAccountForTaxUpdate', 'loginUserRole']
};
export default {
  name: 'OperationsComputedMixin',
  created: function created() {
    this.$options.ResourceType = ResourceType;
  },
  methods: {
    appendMoreResources: function appendMoreResources(resourceType) {
      var _this = this;
      switch (resourceType) {
        case ResourceType.Vehicle:
          {
            this.$store.dispatch('setResourcesFilterOptions', {
              resourceType: ResourceType.Vehicle,
              paginationOffset: this.vehiclesDropdownOffset
            }).then(function () {
              _this.$store.dispatch('getSubOperationVehiclesList').then(function (response) {
                _this.$store.dispatch("".concat(subOperationVehiclesDropdownModule, "/updateStates"), response);
              });
            });
            break;
          }
        case ResourceType.Implement:
          {
            this.$store.dispatch('setResourcesFilterOptions', {
              resourceType: ResourceType.Implement,
              paginationOffset: this.implementsDropdownOffset
            }).then(function () {
              _this.$store.dispatch('getSubOperationImplementsList').then(function (response) {
                _this.$store.dispatch("".concat(subOperationImplementsDropdownModule, "/updateStates"), response);
              });
            });
            break;
          }
        case ResourceType.Product:
          {
            this.$store.dispatch('setResourcesFilterOptions', {
              resourceType: ResourceType.Product,
              paginationOffset: this.productsDropdownOffset
            }).then(function () {
              _this.$store.dispatch('getSubOperationProductsList').then(function (response) {
                _this.$store.dispatch("".concat(subOperationProductsDropdownModule, "/updateStates"), response);
              });
            });
            break;
          }
        default:
          return;
      }
    },
    getRateNameAndValue: function getRateNameAndValue(rateName, rateInPercentage, currentTaxValue) {
      if (rateName) return "".concat(rateName, " - ").concat(rateInPercentage);
      return "Default - ".concat(currentTaxValue);
    },
    checkTaxList: function checkTaxList() {
      var _this2 = this;
      this.$store.dispatch("getInvoiceConfiguration").then(function (res) {
        var _a, _b, _c;
        if (((_c = (_b = (_a = res === null || res === void 0 ? void 0 : res.businessProfile) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.salesTaxRates) === null || _c === void 0 ? void 0 : _c.length) === 0) {
          _this2.subOperationIsTaxable = false;
          _this2.subOperationTaxWarningVisible = true;
        }
      });
    },
    routeToUpdateTax: function routeToUpdateTax() {
      var _this3 = this;
      this.subOperationTaxWarningVisible = false;
      this.isRoutedToAccountForTaxUpdate = true;
      router.push(routeAccount).then(function () {
        _this3.accountTab = AccountTabType.Business;
        setTimeout(function () {
          var _a;
          (_a = document.getElementById('account-business')) === null || _a === void 0 ? void 0 : _a.scrollBy({
            top: 700,
            behavior: 'smooth'
          });
        }, ConstantValues.defaultScrollToPositionTimeout);
      });
    },
    cancelTaxSetup: function cancelTaxSetup() {
      this.subOperationTaxWarningVisible = false;
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    vehiclesDropdownOffset: function vehiclesDropdownOffset(state, getters) {
      return state["".concat(subOperationVehiclesDropdownModule)].totalResponseRowCount;
    },
    implementsDropdownOffset: function implementsDropdownOffset(state, getters) {
      return state["".concat(subOperationImplementsDropdownModule)].totalResponseRowCount;
    },
    productsDropdownOffset: function productsDropdownOffset(state, getters) {
      return state["".concat(subOperationProductsDropdownModule)].totalResponseRowCount;
    }
  })), getComputedProperties(computedProperties)), {}, {
    taxValuesListForInvoice: function taxValuesListForInvoice() {
      return this.$store.getters.invoiceConfiguration.salesTaxRates;
    },
    taxName: function taxName() {
      return this.$store.getters.invoiceConfiguration.salesTaxName;
    },
    currencySymbol: function currencySymbol() {
      return localStorage.getItem("currencySymbol");
    },
    ownerRole: {
      get: function get() {
        return localStorage.getItem("ownerRole");
      }
    }
  })
};