import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import LoginRegistrationWrapper from '@/components/layouts/login-registration-wrapper.vue';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import loginComputedMixin from '@/components/login/login-computed';
import { routeLogin } from '@/utils/endpoints';
import ConstantValues from '@/plugins/constantValues';
export default {
  mixins: [validationMixin, loginComputedMixin],
  validations: {
    passWord: {
      required: required,
      uppercaseConstraint: function uppercaseConstraint(password) {
        return /[A-Z]/.test(password);
      },
      lowercaseConstraint: function lowercaseConstraint(password) {
        return /[a-z]/.test(password);
      },
      numericConstraint: function numericConstraint(password) {
        return /[0-9]/.test(password);
      },
      minLength: minLength(ConstantValues.minlengthPassword),
      maxLength: maxLength(ConstantValues.maxlengthPassword)
    },
    confirmPassWord: {
      required: required,
      sameAsPassword: sameAs('passWord')
    }
  },
  components: {
    LoginRegistrationWrapper: LoginRegistrationWrapper,
    Button: Button
  },
  data: function data() {
    return {
      passWord: '',
      confirmPassWord: '',
      verificationSubmit: false,
      value: String,
      valueConfirm: String,
      routeLogin: routeLogin,
      passwordFieldIsFocused: false,
      validPasswordRequirements: [{
        key: "minLength",
        status: null,
        text: 'At least 8 characters'
      }, {
        key: "uppercaseConstraint",
        status: null,
        text: 'At least 1 uppercase letter'
      }, {
        key: "lowercaseConstraint",
        status: null,
        text: 'At least 1 lowercase letter'
      }, {
        key: "numericConstraint",
        status: null,
        text: 'At least 1 number'
      }]
    };
  },
  computed: {
    showPasswordRequirementsTooltip: {
      get: function get() {
        return this.passwordFieldIsFocused;
      },
      set: function set(value) {
        this.passwordFieldIsFocused = value;
      }
    },
    isFormInvalid: {
      get: function get() {
        return this.$v.$invalid;
      }
    },
    emailAddress: function emailAddress() {
      var urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('email');
    }
  },
  methods: {
    submit: function submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.proceedToResetPassword();
    },
    proceedToResetPassword: function proceedToResetPassword() {
      var urlParams = new URLSearchParams(window.location.search);
      var token = urlParams.get('token');
      var email = urlParams.get('email');
      if (token != null && email != null) {
        var formData = new FormData();
        formData.append('Token', token);
        formData.append('Username', email);
        formData.append('NewPassword', this.passWord);
        this.$store.dispatch('sendResetPasswordRequest', formData);
      }
    },
    generateValidationErrors: function generateValidationErrors(propertyName, validationName1, validationName2, userError1, userError2) {
      var errors = [];
      if (!this.$v["".concat(propertyName)].$dirty) return errors;
      if (!this.$v["".concat(propertyName)]["".concat(validationName1)]) {
        errors.push(userError1);
      } else if (!this.$v["".concat(propertyName)]["".concat(validationName2)]) {
        errors.push(userError2);
      }
      return errors;
    },
    generateValidationPasswordErrors: function generateValidationPasswordErrors(propertyName, validationName1, validationName2, validationName3, validationName4, validationName5, validationName6, userError1, userError2, userError3, userError4, userError5, userError6) {
      var errors = [];
      if (!this.$v["".concat(propertyName)].$dirty) return errors;
      if (!this.$v["".concat(propertyName)]["".concat(validationName1)]) {
        errors.push(userError1);
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName2)]) {
        errors.push(userError2);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName2 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName2 ? requirement.status = true : null;
        });
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName3)]) {
        errors.push(userError3);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName3 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName3 ? requirement.status = true : null;
        });
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName4)]) {
        errors.push(userError4);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName4 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName4 ? requirement.status = true : null;
        });
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName5)]) {
        errors.push(userError5);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName5 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName5 ? requirement.status = true : null;
        });
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName6)]) {
        errors.push(userError6);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName6 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName6 ? requirement.status = true : null;
        });
      }
      return errors;
    },
    handlePasswordFieldInput: function handlePasswordFieldInput() {
      if (this.$v.passWord.$model.length > 0) {
        this.$v.passWord.$touch();
      } else {
        this.validPasswordRequirements.map(function (requirement) {
          return requirement.status == null;
        });
        this.$v.passWord.$reset();
      }
    },
    handlePasswordFieldBlur: function handlePasswordFieldBlur() {
      if (!this.$v.passWord.$invalid || this.$v.passWord.$model.length == 0) {
        this.showPasswordRequirementsTooltip = false;
      }
    },
    handlePasswordFieldFocus: function handlePasswordFieldFocus() {
      this.showPasswordRequirementsTooltip = true;
      if (this.$v.passWord.$model.length > 0) {
        this.$v.passWord.$touch();
      } else {
        this.$v.passWord.$reset();
      }
    }
  }
};