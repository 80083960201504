import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { checkFieldsValidity, validateRegexPhoneNumber } from '@/utils/helpers';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { UserRoleType } from "@/enum/userRoleType";
import ConstantValues from '@/plugins/constantValues';
export default {
  name: 'InvoicePaymentsForm',
  mixins: [accountComputedMixin],
  components: {
    SectionLeftHeadings: SectionLeftHeadings,
    Button: Button
  },
  data: function data() {
    return {
      tempRatePercentage: null,
      tempRateName: null,
      searchTaxType: null,
      UserRoleType: UserRoleType
    };
  },
  computed: {
    isValidTaxRate: function isValidTaxRate() {
      if (checkFieldsValidity([this.tempRatePercentage, this.tempRateName])) {
        return false;
      } else {
        return true;
      }
    },
    ownerRole: {
      get: function get() {
        return localStorage.getItem("ownerRole");
      }
    }
  },
  mounted: function mounted() {
    if (this.isRoutedToAccountForTaxUpdate) {
      setTimeout(function () {
        var _a;
        (_a = document.getElementById('taxTypeNameId')) === null || _a === void 0 ? void 0 : _a.click();
      }, ConstantValues.defaultScrollToPositionClickTimeout);
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.isRoutedToAccountForTaxUpdate = false;
  },
  methods: {
    appendTaxRate: function appendTaxRate() {
      var taxRate = {
        rateName: this.tempRateName,
        rateInPercentage: this.tempRatePercentage
      };
      this.accountBusinessProfile.config.salesTaxRates.push(taxRate);
      this.resetTempTaxRate();
    },
    resetTempTaxRate: function resetTempTaxRate() {
      this.tempRateName = null;
      this.tempRatePercentage = null;
    },
    removeSelectedTaxRate: function removeSelectedTaxRate(idx) {
      if (idx > -1) {
        this.accountBusinessProfile.config.salesTaxRates.splice(idx, 1);
      }
    },
    resetTaxRegistration: function resetTaxRegistration() {
      this.accountBusinessProfile.config.salesTaxRegistrationNumber = null;
    },
    checkTermsNumber: function checkTermsNumber(event) {
      validateRegexPhoneNumber(event);
    }
  }
};