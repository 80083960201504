import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import router from '../router/index';
import { getCookie } from '@/utils/helpers';
import { routeLogin } from '../utils/endpoints';
import { MachineType } from '../enum/machineType';
import { listAction } from '../enum/listAction';
import ConstantValues from '@/plugins/constantValues';
import { Currency } from '@/data/models/Currency';
import { InvoiceConfiguration } from '@/data/models/InvoiceConfiguration';
import { UserRoleType, UserRoleTypeLabel } from '@/enum/userRoleType';
import { TimeZone } from '@/data/models/TimeZone';
export var state = {
  baseUrl: process.env.VUE_APP_BASE_URL,
  loggedIn: false,
  dashboardTitle: null,
  loader: true,
  tempUrl: '',
  offset: ConstantValues.defaultPageOffsetSize,
  limit: ConstantValues.defaultPageLimitSize,
  listCount: ConstantValues.defaultPageListCount,
  listLoading: false,
  filterOptions: [],
  totalRowCount: ConstantValues.defaultPageTotalRowCount,
  rowCount: ConstantValues.defaultPageRowCount,
  noData: false,
  filtersApplied: false,
  refreshTokenRetries: 0,
  isPermissionsSet: false,
  permissionsList: [],
  isListBind: false,
  componentsPermissions: ConstantValues.allComponentsPermissions,
  hoursList: [],
  minutesList: [],
  loginUserRole: null,
  loginUserName: null,
  loginUserBusinessName: null,
  machineType: MachineType.None,
  listActionType: listAction.Delete,
  enumOwnership: [{
    title: "Rented",
    value: 0
  }, {
    title: "Owned",
    value: 1
  }, {
    title: "Other",
    value: 2
  }],
  enumMemberRoles: [{
    name: "Operator",
    type: 4
  }, {
    name: "TeamLead",
    type: 3
  }, {
    name: "Manager",
    type: 2
  }],
  enumUserRoles: [{
    name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.Contractor]]),
    type: UserRoleType.Contractor
  }, {
    name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.Farmer]]),
    type: UserRoleType.Farmer
  }, {
    name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.Manager]]),
    type: UserRoleType.Manager
  }, {
    name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.Operator]]),
    type: UserRoleType.Operator
  }, {
    name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.TeamLead]]),
    type: UserRoleType.TeamLead
  }],
  searchNotify: 'Press enter to search',
  jobAssignmentLevel: {
    "primaryAssignedUserLevel": 0,
    "secondaryAssignedUserLevel": 1
  },
  enumPreference: {
    'NotArchived': 0,
    'Archived': 1,
    'All': 2
  },
  enumSubscriptionStatus: {
    'Trialing': 0,
    'Active': 1,
    'Pending': 2,
    'Cancelled': 3
  },
  statusFilterTypeList: [{
    title: 'Accepted',
    value: 0
  }, {
    title: 'Pending',
    value: 1
  }],
  userStatusFilterTypeList: [{
    title: 'Active',
    value: 0
  }, {
    title: 'Inactive',
    value: 1
  }, {
    title: 'Pending',
    value: 2
  }],
  customerTypeFilterList: [{
    title: 'All',
    value: null
  }, {
    title: 'Internal',
    value: true
  }, {
    title: 'External',
    value: false
  }],
  enumListProductUnits: [],
  enumListLoadInfoUnits: [],
  enumListOperationUnits: [],
  loginLoader: false,
  disabledNavigation: false,
  hasBusinessProfile: false,
  enumActiveStatus: [{
    title: 'Active',
    value: 0
  }, {
    title: 'Inactive',
    value: 1
  }],
  routedFromTimesheets: false,
  showImagePreview: false,
  tempImageUrl: '',
  areaMeasurementUnitsList: [{
    title: 'Hectare',
    value: 0
  }, {
    title: 'Acre',
    value: 1
  }],
  showAllDashboardViews: true,
  snackbarShow: false,
  allItemsDeleted: false,
  dialogSelectedRows: false,
  allItemsArchived: false,
  archiveDialogSelectedRows: false,
  allItemsRestored: false,
  restoreDialogSelectedRows: false,
  selectedItems: [],
  conReqLoader: false,
  conSendLoader: false,
  inviteeRole: 0,
  maxCompanyNameLength: 40,
  maxAddressLength: 70,
  importSelectedFile: null,
  importResponseData: null,
  importInProgress: false,
  importfileName: null,
  importInvalidData: [],
  sentCurrentRequest: false,
  hasBusinessConfig: false,
  teamJobDetailsUpdatedRealTime: false,
  isPageRelated: false,
  isRoutedFromReferencePage: false,
  globalCurrenciesList: [],
  globalTimeZonesList: [],
  hasMoreData: false,
  globalTaxesNameList: [],
  globalCountriesList: [],
  invoiceConfiguration: new InvoiceConfiguration(),
  accountTab: null,
  isRoutedToAccountForTaxUpdate: false,
  drawerOpen: false,
  abortController: null,
  collapsedNavigation: false,
  notification: {
    title: null,
    message: null,
    callback: null,
    actionText: null,
    type: null
  },
  activeRouteIndex: null,
  showSuperAdminSubMenu: false,
  breadcrumbsItems: [],
  bulkActionResponse: null,
  dialogBulkActionResponse: false,
  dialogBulkActionResponseTitle: null,
  bulkActionLoader: false,
  isJobBeingAdded: false
};
export var getters = {
  loggedIn: function loggedIn(state) {
    return state.loggedIn;
  },
  dashboardTitle: function dashboardTitle(state) {
    return state.dashboardTitle;
  },
  noData: function noData(state) {
    return state.noData;
  },
  filterOptions: function filterOptions(state) {
    return state.filterOptions;
  },
  listCount: function listCount(state) {
    return state.listCount;
  },
  listLoading: function listLoading(state) {
    return state.listLoading;
  },
  filtersApplied: function filtersApplied(state) {
    return state.filtersApplied;
  },
  refreshTokenRetries: function refreshTokenRetries(state) {
    return state.refreshTokenRetries;
  },
  isPermissionsSet: function isPermissionsSet(state) {
    return state.isPermissionsSet;
  },
  permissionsList: function permissionsList(state) {
    return state.permissionsList;
  },
  loginUserRole: function loginUserRole(state) {
    return state.loginUserRole;
  },
  isListBind: function isListBind(state) {
    return state.isListBind;
  },
  componentsPermissions: function componentsPermissions(state) {
    return state.componentsPermissions;
  },
  enumOwnership: function enumOwnership(state) {
    return state.enumOwnership;
  },
  searchNotify: function searchNotify(state) {
    return state.searchNotify;
  },
  enumMemberRoles: function enumMemberRoles(state) {
    return state.enumMemberRoles;
  },
  enumUserRoles: function enumUserRoles(state) {
    return state.enumUserRoles;
  },
  enumRoleValuesString: function enumRoleValuesString(state) {
    return state.enumRoleValuesString;
  },
  jobAssignmentLevel: function jobAssignmentLevel(state) {
    return state.jobAssignmentLevel;
  },
  minutesList: function minutesList(state) {
    return state.minutesList;
  },
  hoursList: function hoursList(state) {
    return state.hoursList;
  },
  enumPreference: function enumPreference(state) {
    return state.enumPreference;
  },
  loginUserName: function loginUserName(state) {
    return state.loginUserName;
  },
  loginUserBusinessName: function loginUserBusinessName(state) {
    return state.loginUserBusinessName;
  },
  enumListProductUnits: function enumListProductUnits(state) {
    return state.enumListProductUnits;
  },
  enumListLoadInfoUnits: function enumListLoadInfoUnits(state) {
    return state.enumListLoadInfoUnits;
  },
  enumListOperationUnits: function enumListOperationUnits(state) {
    return state.enumListOperationUnits;
  },
  enumSubscriptionStatus: function enumSubscriptionStatus(state) {
    return state.enumSubscriptionStatus;
  },
  loginLoader: function loginLoader(state) {
    return state.loginLoader;
  },
  disabledNavigation: function disabledNavigation(state) {
    return state.disabledNavigation;
  },
  hasBusinessProfile: function hasBusinessProfile(state) {
    return state.hasBusinessProfile;
  },
  statusFilterTypeList: function statusFilterTypeList(state) {
    return state.statusFilterTypeList;
  },
  enumActiveStatus: function enumActiveStatus(state) {
    return state.enumActiveStatus;
  },
  routedFromTimesheets: function routedFromTimesheets(state) {
    return state.routedFromTimesheets;
  },
  customerTypeFilterList: function customerTypeFilterList(state) {
    return state.customerTypeFilterList;
  },
  showImagePreview: function showImagePreview(state) {
    return state.showImagePreview;
  },
  tempImageUrl: function tempImageUrl(state) {
    return state.tempImageUrl;
  },
  areaMeasurementUnitsList: function areaMeasurementUnitsList(state) {
    return state.areaMeasurementUnitsList;
  },
  totalRowCount: function totalRowCount(state) {
    return state.totalRowCount;
  },
  showAllDashboardViews: function showAllDashboardViews(state) {
    return state.showAllDashboardViews;
  },
  snackbarShow: function snackbarShow(state) {
    return state.snackbarShow;
  },
  allItemsDeleted: function allItemsDeleted(state) {
    return state.allItemsDeleted;
  },
  selectedItems: function selectedItems(state) {
    return state.selectedItems;
  },
  dialogSelectedRows: function dialogSelectedRows(state) {
    return state.dialogSelectedRows;
  },
  conReqLoader: function conReqLoader(state) {
    return state.conReqLoader;
  },
  conSendLoader: function conSendLoader(state) {
    return state.conSendLoader;
  },
  inviteeRole: function inviteeRole(state) {
    return state.inviteeRole;
  },
  maxCompanyNameLength: function maxCompanyNameLength(state) {
    return state.maxCompanyNameLength;
  },
  maxAddressLength: function maxAddressLength(state) {
    return state.maxAddressLength;
  },
  importSelectedFile: function importSelectedFile(state) {
    return state.importSelectedFile;
  },
  importResponseData: function importResponseData(state) {
    return state.importResponseData;
  },
  importInProgress: function importInProgress(state) {
    return state.importInProgress;
  },
  importfileName: function importfileName(state) {
    return state.importfileName;
  },
  importInvalidData: function importInvalidData(state) {
    return state.importInvalidData;
  },
  allItemsArchived: function allItemsArchived(state) {
    return state.allItemsArchived;
  },
  archiveDialogSelectedRows: function archiveDialogSelectedRows(state) {
    return state.archiveDialogSelectedRows;
  },
  sentCurrentRequest: function sentCurrentRequest(state) {
    return state.sentCurrentRequest;
  },
  allItemsRestored: function allItemsRestored(state) {
    return state.allItemsRestored;
  },
  restoreDialogSelectedRows: function restoreDialogSelectedRows(state) {
    return state.restoreDialogSelectedRows;
  },
  userStatusFilterTypeList: function userStatusFilterTypeList(state) {
    return state.userStatusFilterTypeList;
  },
  hasBusinessConfig: function hasBusinessConfig(state) {
    return state.hasBusinessConfig;
  },
  machineType: function machineType(state) {
    return state.machineType;
  },
  listActionType: function listActionType(state) {
    return state.listActionType;
  },
  teamJobDetailsUpdatedRealTime: function teamJobDetailsUpdatedRealTime(state) {
    return state.teamJobDetailsUpdatedRealTime;
  },
  isPageRelated: function isPageRelated(state) {
    return state.isPageRelated;
  },
  isRoutedFromReferencePage: function isRoutedFromReferencePage(state) {
    return state.isRoutedFromReferencePage;
  },
  globalCurrenciesList: function globalCurrenciesList(state) {
    return state.globalCurrenciesList;
  },
  globalTimeZonesList: function globalTimeZonesList(state) {
    return state.globalTimeZonesList;
  },
  hasMoreData: function hasMoreData(state) {
    return state.hasMoreData;
  },
  limit: function limit(state) {
    return state.limit;
  },
  offset: function offset(state) {
    return state.offset;
  },
  globalTaxesNameList: function globalTaxesNameList(state) {
    return state.globalTaxesNameList;
  },
  invoiceConfiguration: function invoiceConfiguration(state) {
    return state.invoiceConfiguration;
  },
  globalCountriesList: function globalCountriesList(state) {
    return state.globalCountriesList;
  },
  accountTab: function accountTab(state) {
    return state.accountTab;
  },
  isRoutedToAccountForTaxUpdate: function isRoutedToAccountForTaxUpdate(state) {
    return state.isRoutedToAccountForTaxUpdate;
  },
  drawerOpen: function drawerOpen(state) {
    return state.drawerOpen;
  },
  abortController: function abortController(state) {
    return state.abortController;
  },
  collapsedNavigation: function collapsedNavigation(state) {
    return state.collapsedNavigation;
  },
  notification: function notification(state) {
    return state.notification;
  },
  activeRouteIndex: function activeRouteIndex(state) {
    return state.activeRouteIndex;
  },
  showSuperAdminSubMenu: function showSuperAdminSubMenu(state) {
    return state.showSuperAdminSubMenu;
  },
  breadcrumbsItems: function breadcrumbsItems(state) {
    return state.breadcrumbsItems;
  },
  bulkActionResponse: function bulkActionResponse(state) {
    return state.bulkActionResponse;
  },
  dialogBulkActionResponse: function dialogBulkActionResponse(state) {
    return state.dialogBulkActionResponse;
  },
  dialogBulkActionResponseTitle: function dialogBulkActionResponseTitle(state) {
    return state.dialogBulkActionResponseTitle;
  },
  bulkActionLoader: function bulkActionLoader(state) {
    return state.bulkActionLoader;
  },
  isJobBeingAdded: function isJobBeingAdded(state) {
    return state.isJobBeingAdded;
  }
};
export var mutations = {
  loginVerify: function loginVerify(state) {
    state.loggedIn = true;
  },
  setTempUrl: function setTempUrl(state, data) {
    state.tempUrl = data;
  },
  setNoDataStatus: function setNoDataStatus(state, data) {
    state.noData = data != null && data.length == 0;
  },
  setFilterOptions: function setFilterOptions(state, data) {
    state.filterOptions.push(data);
  },
  setClearFilterOptions: function setClearFilterOptions(state) {
    state.filterOptions = [];
  },
  resetPaging: function resetPaging(state) {
    state.offset = ConstantValues.defaultPageOffsetSize;
    state.limit = ConstantValues.defaultPageLimitSize;
    state.listCount = ConstantValues.defaultPageListCount;
    state.totalRowCount = ConstantValues.defaultPageTotalRowCount;
    state.listLoading = false;
    state.noData = false;
    state.hasMoreData = false;
  },
  loggedOut: function loggedOut(state) {
    var _a, _b;
    state.loginLoader = false;
    state.loggedIn = false;
    state.tempUrl = '';
    state.offset = ConstantValues.defaultPageOffsetSize;
    state.limit = ConstantValues.defaultPageLimitSize;
    state.listCount = ConstantValues.defaultPageListCount;
    state.listLoading = false;
    state.filterOptions = [];
    state.totalRowCount = ConstantValues.defaultPageTotalRowCount;
    state.rowCount = ConstantValues.defaultPageRowCount;
    var routerState = router;
    if (((_b = (_a = routerState === null || routerState === void 0 ? void 0 : routerState.history) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.path) !== routeLogin) {
      router.push(routeLogin);
    }
    state.refreshTokenRetries = 0;
    state.isListBind = false;
    state.isPermissionsSet = false;
    state.permissionsList = [];
    state.disabledNavigation = false;
    state.hasBusinessProfile = false;
    state.loginUserRole = null;
    state.showAllDashboardViews = true;
    state.collapsedNavigation = false;
  },
  setPermissionsList: function setPermissionsList(state, data) {
    state.permissionsList = data;
  },
  setLoginUserRole: function setLoginUserRole(state) {
    var role = parseInt(JSON.parse(getCookie('role')));
    state.loginUserRole = role;
  },
  setHoursList: function setHoursList(state, data) {
    state.hoursList.push(data);
  },
  setMinutesList: function setMinutesList(state, data) {
    state.minutesList.push(data);
  },
  setProductUnits: function setProductUnits(state, data) {
    state.enumListProductUnits = [];
    data.map(function (productUnit) {
      state.enumListProductUnits.push({
        title: productUnit,
        value: productUnit
      });
    });
  },
  setOperationUnits: function setOperationUnits(state, data) {
    state.enumListOperationUnits = [];
    data && data.map(function (operationUnit) {
      state.enumListOperationUnits.push({
        title: operationUnit,
        value: operationUnit
      });
    });
  },
  setLoadInfoUnits: function setLoadInfoUnits(state, data) {
    state.enumListLoadInfoUnits = [];
    data && data.map(function (loadInfoUnit) {
      state.enumListLoadInfoUnits.push({
        title: loadInfoUnit,
        value: loadInfoUnit
      });
    });
  },
  setInvoiceConfiguration: function setInvoiceConfiguration(state, data) {
    var _a;
    if ((_a = data === null || data === void 0 ? void 0 : data.businessProfile) === null || _a === void 0 ? void 0 : _a.config) {
      var config = data.businessProfile.config;
      state.invoiceConfiguration = new InvoiceConfiguration().getInvoiceConfiguration(config);
    }
  },
  resetInvoiceConfiguration: function resetInvoiceConfiguration(state, _) {
    state.invoiceConfiguration = new InvoiceConfiguration();
  },
  setOwnerDetailsLocal: function setOwnerDetailsLocal(state, data) {
    var _a, _b, _c;
    if (data) {
      var fullOwnerBusinessName = '';
      var fullOwnerName = (data.firstName ? data.firstName : '') + ' ' + (data.lastName ? data.lastName : '');
      if (data.businessProfile) {
        localStorage.setItem('businessId', data.businessProfile.id);
      }
      if (data.businessProfile && data.businessProfile.title) {
        var title = data.businessProfile.title;
        fullOwnerBusinessName = title;
      } else {
        var firstName = data.firstName,
          lastName = data.lastName;
        fullOwnerBusinessName = (firstName ? firstName : '') + ' ' + (lastName ? lastName : '');
      }
      localStorage.setItem('fullOwnerBusinessName', fullOwnerBusinessName);
      state.loginUserBusinessName = fullOwnerBusinessName;
      localStorage.setItem('fullOwnerName', fullOwnerName);
      localStorage.setItem('colorCode', data.colorCode);
      if (data.businessProfile && data.businessProfile.config) {
        localStorage.setItem('defaultStartingLat', data.businessProfile.config.defaultLatitude);
        localStorage.setItem('defaultStartingLng', data.businessProfile.config.defaultLongitude);
        localStorage.setItem('defaultAreaMeasurementUnit', data.businessProfile.config.defaultAreaMeasurementUnit);
        if ((_b = (_a = data.businessProfile.config) === null || _a === void 0 ? void 0 : _a.currency) === null || _b === void 0 ? void 0 : _b.symbol) {
          localStorage.setItem('currencySymbol', data.businessProfile.config.currency.symbol);
        }
        if ((_c = data.businessProfile.address) === null || _c === void 0 ? void 0 : _c.country) {
          localStorage.setItem('country', data.businessProfile.address.country);
        }
      }
    }
  },
  clearSnackbarDetails: function clearSnackbarDetails(state) {
    state.snackbarShow = false;
    state.allItemsDeleted = false;
    state.allItemsArchived = false;
    state.allItemsRestored = false;
    state.selectedItems = [];
    state.dialogSelectedRows = false;
  },
  setClearUserInvitationState: function setClearUserInvitationState(state) {
    state.inviteeRole = 0;
  },
  resetImportDataDetails: function resetImportDataDetails(state) {
    state.importSelectedFile = null;
    state.importResponseData = null;
    state.importInProgress = false;
    state.importfileName = null;
    state.importInvalidData = [];
  },
  setSentCurrentRequest: function setSentCurrentRequest(state, data) {
    state.sentCurrentRequest = data;
  },
  setPageOffset: function setPageOffset(state, data) {
    state.offset = state.offset + data;
  },
  setDefaultPageLimitSize: function setDefaultPageLimitSize(state, limit) {
    state.limit = limit;
  },
  setGlobalCurrenciesList: function setGlobalCurrenciesList(state, data) {
    state.globalCurrenciesList = [];
    data.forEach(function (x) {
      return state.globalCurrenciesList.push(new Currency().setCurrency(x));
    });
  },
  setGlobalTimeZonesList: function setGlobalTimeZonesList(state, data) {
    state.globalTimeZonesList = [];
    data.forEach(function (tz) {
      return state.globalTimeZonesList.push(new TimeZone().setTimeZone(tz));
    });
  },
  setGlobalCountriesList: function setGlobalCountriesList(state, data) {
    state.globalCountriesList = [];
    state.globalCountriesList = data;
  },
  setGlobalTaxesNameList: function setGlobalTaxesNameList(state, data) {
    state.globalTaxesNameList = [];
    state.globalTaxesNameList = data;
  },
  setDrawerOpen: function setDrawerOpen(state, data) {
    state.drawerOpen = data;
  },
  setDashboardTitle: function setDashboardTitle(state, title) {
    state.dashboardTitle = title;
  },
  setCollapsedNavigation: function setCollapsedNavigation(state, data) {
    state.collapsedNavigation = data;
  },
  setNotification: function setNotification(state, notificationObject) {
    state.notification = notificationObject;
  },
  cleanUpNotification: function cleanUpNotification(state) {
    state.notification = null;
  },
  resetBreadcrumbsItems: function resetBreadcrumbsItems(state) {
    state.dashboardTitle = null;
    state.breadcrumbsItems = [];
  },
  updateScrollToItem: function updateScrollToItem(state, itemId) {
    state.scrollToItem = itemId;
  },
  updatePagingAfterListItemRemoval: function updatePagingAfterListItemRemoval(state) {
    state.listCount--;
    state.totalRowCount--;
    state.noData = state.listCount === 0;
  },
  toggleIsJobBeingAdded: function toggleIsJobBeingAdded(state) {
    state.isJobBeingAdded = !state.isJobBeingAdded;
  }
};