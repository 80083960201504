import { getCookie } from '@/utils/helpers';
import { superAdminAccessCodePrompt } from '@/utils/strings';
import { devSuperAdminAccessCode } from '@/utils/constants';
var enteredAccessCode = null;
export default {
  name: 'SuperAdminAccessMixin',
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    function promtUserToEnterCode() {
      if (process.env.NODE_ENV !== 'production') {
        enteredAccessCode = devSuperAdminAccessCode;
      } else {
        enteredAccessCode = prompt(superAdminAccessCodePrompt, "");
      }
      if (enteredAccessCode && (enteredAccessCode === null || enteredAccessCode === void 0 ? void 0 : enteredAccessCode.length) > 0) {
        document.cookie = "accessCode=" + enteredAccessCode + '; max-age=86400; path=/; SameSite=Strict';
      } else {
        next({
          path: from.fullPath
        });
      }
    }
    if (getCookie("accessCode") && getCookie("accessCode").length > 0) {
      next();
    } else {
      promtUserToEnterCode();
    }
  }
};