import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    { staticClass: "hide-scroll", attrs: { "no-gutters": "", cols: "12" } },
    [
      _c(VCol, { staticClass: "fix-bar-jb px-0", attrs: { cols: "12" } }, [
        _c(
          "div",
          { staticClass: "btm-btn-bg py-0" },
          [
            _c(
              VRow,
              { staticClass: "px-0 py-0" },
              [
                _c(
                  VCol,
                  {
                    staticClass: "py-0 px-10 jobs-max-width",
                    attrs: { cols: "9" },
                  },
                  [
                    _c("div", { staticClass: "jb-mw" }, [
                      _c(
                        "div",
                        {
                          staticClass: " py-4 pl-9 pr-0 btn-lt-sht text-right",
                        },
                        [
                          _c("HoverTextButton", {
                            staticClass:
                              "f-ns f-nn pl-10 pr-10 ml-6 mt-1 mb-1 btn-wt-bl-text",
                            attrs: {
                              elevation: "0",
                              height: "36",
                              textValue: _vm.$stringConstants("cancel"),
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.cancelAddTeamJob($event)
                              },
                            },
                          }),
                          _c("ButtonActionPrimary", {
                            staticClass: "ml-4",
                            class: { disabled: true },
                            attrs: {
                              width: "175",
                              height: "36",
                              loading: _vm.teamJobsAddLoader,
                              disabled: _vm.teamJobsAddLoader || _vm.isDisabled,
                              textValue: "Create",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.saveTeamJob()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(VCol, { attrs: { cols: "3" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }