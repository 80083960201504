var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InvitationDialog", {
        staticStyle: { "z-index": "99999" },
        attrs: { item: _vm.dialogInvitation },
        on: { cancel: _vm.handleDialogCancel, proceed: _vm.handleDialogAction },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(_vm._s(_vm.invitationDialogPromptTitle))]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [_vm._v(_vm._s(_vm.invitationDialogPromptSubtitle))]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { attrs: { id: "overlay" } }),
      _c("div", { attrs: { id: "overlayPermanent" } }),
      _c("FarmersListView"),
      _c(
        "div",
        {
          staticClass: "sidebar-overlay position-relative addFarmerFormOverlay",
          attrs: { id: "addFarmerFormOverlay" },
        },
        [_c("inviteFarmer")],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay position-relative manageFarmerFormOverlay",
          attrs: { id: "manageFarmerFormOverlay" },
        },
        [_c("manageFarmer")],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay position-relative importCustomerFormOverlay",
          attrs: { id: "importCustomerFormOverlay" },
        },
        [_c("importFarmer")],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay position-relative importIntegratedCustomerFormOverlay",
          attrs: { id: "importIntegratedCustomerFormOverlay" },
        },
        [
          _c("importIntegratedCustomer", {
            attrs: { intgrationType: _vm.integrationType },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }