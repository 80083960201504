var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "overlay" } }),
      _c("ProductsListView"),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay sidebar-overlay-sm position-relative editProductFormOverlay",
          attrs: { id: "editProductFormOverlay" },
        },
        [_c("ProductEditForm")],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay position-relative importProductsFormOverlay",
          attrs: { id: "importProductsFormOverlay" },
        },
        [_c("ProductsImportForm")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }