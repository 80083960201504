import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "overlay" } }),
      _c(
        "div",
        {
          staticClass: "px-9 pt-8 ib",
          staticStyle: { width: "60%", "max-width": "1024px" },
        },
        [
          _c("BusinessesDetailsTable", {
            attrs: {
              tableName: _vm.generalInfoTableName,
              noOfRows: 3,
              headerData: _vm.generalInfoHeader,
              rowData: [
                _vm.businessesDetails.name,
                _vm.businessesDetails.type,
                _vm.businessesDetails.address,
              ],
            },
          }),
          _c("BusinessesDetailsTable", {
            attrs: {
              tableName: _vm.ownerInfoTableName,
              noOfRows: 3,
              headerData: _vm.ownerInfoHeader,
              rowData: [
                _vm.businessesDetails.ownerName,
                _vm.businessesDetails.email,
                _vm.businessesDetails.phoneNumber,
              ],
            },
          }),
          _c("BusinessesDetailsTable", {
            attrs: {
              tableName: _vm.teamMemberTableName,
              noOfRows: 3,
              headerData: _vm.teamMemberHeader,
              rowData: [
                _vm.businessesDetails.teamMembers.total,
                _vm.businessesDetails.teamMembers.active,
                _vm.businessesDetails.teamMembers.archived,
              ],
            },
          }),
          _c("div", { staticStyle: { "max-width": "1024px" } }, [
            _c("div", { staticClass: "pb-6" }, [
              _c("div", { staticClass: "su-buisiness-table-title" }, [
                _vm._v(" " + _vm._s(_vm.subscriptionTableName) + " "),
              ]),
              _c(
                "div",
                { staticClass: "su-buisiness-details-header pl-6 py-3" },
                _vm._l(_vm.subscriptionHeader, function (data) {
                  return _c(
                    "div",
                    {
                      key: data,
                      staticClass: "ib su-buisiness-details-header-st",
                      style: {
                        width:
                          parseInt(100 / _vm.subscriptionHeader.length) + "%",
                      },
                    },
                    [_vm._v(" " + _vm._s(data || "-") + " ")]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "su-buisiness-details-container pl-6" },
                [
                  _vm.listLoading
                    ? _c(
                        "div",
                        _vm._l(3, function (count) {
                          return _c(
                            "div",
                            {
                              key: count,
                              staticClass:
                                "ib py-4 pr-4 su-buisiness-details-header-st",
                              style: { width: "33%" },
                            },
                            [
                              _c(VSkeletonLoader, {
                                attrs: {
                                  width: "120",
                                  height: "30",
                                  type: "card",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ib py-4 pr-4 su-buisiness-details-header-st",
                            style: { width: parseInt(100 / 3) + "%" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getSubscriptionStartDate(
                                    _vm.businessesDetails.subscriptions
                                  ) || "-"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ib py-4 pr-4 su-buisiness-details-header-st",
                            style: { width: parseInt(100 / 3) + "%" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getSubscriptionEndDate(
                                    _vm.businessesDetails.subscriptions
                                  ) || "-"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ib py-4 pr-4 su-buisiness-details-header-st",
                            style: {
                              width: parseInt(100 / 3) + "%",
                              color: _vm.getCancellationReasonIfAny(
                                _vm.businessesDetails.subscriptions
                              )
                                ? "red"
                                : "#000",
                            },
                            attrs: {
                              title:
                                _vm.getCancellationReasonIfAny(
                                  _vm.businessesDetails.subscriptions
                                ) || "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getSubscriptionStatus(
                                    _vm.businessesDetails.subscriptions
                                  ) || "-"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                ]
              ),
            ]),
          ]),
          _c("BusinessesMonthlySubscriptionChart"),
          _c("BusinessesDetailsTable", {
            attrs: {
              tableName: _vm.jobsTableName,
              noOfRows: 4,
              headerData: _vm.jobsHeader,
              rowData: [
                _vm.businessesDetails.jobs.completed,
                _vm.businessesDetails.jobs.invoiced,
                _vm.businessesDetails.jobs.total,
                _vm.getDateTimeFormattedLocal(
                  _vm.businessesDetails.jobs.lastJobCreatedOn
                ),
              ],
            },
          }),
          _c("BusinessesDetailsTable", {
            attrs: {
              tableName: _vm.resoucesTableName,
              noOfRows: 4,
              headerData: _vm.resourcesHeader,
              rowData: [
                _vm.businessesDetails.totalFieldsCount,
                _vm.businessesDetails.totalVehiclesCount,
                _vm.businessesDetails.totalProductsCount,
                _vm.businessesDetails.totalImplementsCount,
                _vm.businessesDetails.totalTimesheetsCount,
                _vm.businessesDetails.totalOperationsCount,
              ],
            },
          }),
          _c("BusinessesDetailsTable", {
            attrs: {
              tableName: _vm.invoiceTableName,
              noOfRows: 3,
              headerData: _vm.invoiceHeader,
              rowData: [
                _vm.businessesDetails.activeInvoicingService,
                _vm.businessesDetails.totalInvoicesCount,
              ],
            },
          }),
          _c("BusinessesDetailsTable", {
            attrs: {
              tableName: _vm.customerTableName,
              noOfRows: 3,
              headerData: _vm.customerHeader,
              rowData: [
                _vm.businessesDetails.connectedBusinesses.internal,
                _vm.businessesDetails.connectedBusinesses.external,
                _vm.businessesDetails.connectedBusinesses.total,
              ],
            },
          }),
          _c("div", { staticClass: "py-10" }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "ib py-8 pr-12",
          staticStyle: { float: "right", "vertical-align": "top" },
        },
        [
          _c("Button", {
            staticClass: "ib float-right f-nn add-btn op-6",
            attrs: {
              variant: "secondary",
              color: _vm.buttonDeleteColor,
              outlined: "",
              outlineColor: _vm.buttonDeleteColor,
              textValue: _vm.$stringConstants("listItemDelete"),
            },
            nativeOn: {
              click: function ($event) {
                return _vm.promtUserDeletion($event)
              },
            },
          }),
          _vm.businessesDetails.type == "Farmer"
            ? _c("Button", {
                staticClass: "ib float-right btn-gr f-nn mr-2 add-btn",
                attrs: {
                  variant: "secondary",
                  color: "primary",
                  outlined: "",
                  outlineColor: "primary",
                  textValue: _vm.$stringConstants("makeContractorText"),
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.promtUserRoleChange($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogDelete },
        on: {
          cancel: function ($event) {
            _vm.dialogDelete = false
          },
          proceed: _vm.proceedUserDeletion,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("businessesDeleteTitle")) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("businessesDeleteSubtitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("ItemDialog", {
        attrs: { item: _vm.dialogChangeRole },
        on: {
          cancel: function ($event) {
            _vm.dialogChangeRole = false
          },
          proceed: _vm.proceedUserRoleChange,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants("businessesRoleChangeTitle")
                      ) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants("businessesRoleChangeSubtitle")
                      ) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }