import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        ref: "calendarDay",
        staticClass: "calendar-day",
        style: { zIndex: _vm.isDropZoneActive ? 1 : "auto" },
        on: {
          dragenter: function ($event) {
            $event.preventDefault()
            return _vm.handleDragEnter($event)
          },
          dragover: function ($event) {
            $event.preventDefault()
            return _vm.handleDragOver($event)
          },
          dragleave: _vm.handleDragLeave,
          drop: function ($event) {
            $event.preventDefault()
            return _vm.handleDrop($event)
          },
        },
      },
      "div",
      _vm.$attrs,
      false
    ),
    [
      _c(
        VIcon,
        {
          staticClass: "add-event ma-4",
          attrs: { size: "16", color: "white" },
          on: {
            click: function ($event) {
              return _vm.$emit("add-job")
            },
          },
        },
        [_vm._v(" mdi-plus ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }