import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-8" }, [
    _c("div", { attrs: { id: "overlay" } }),
    _c("div", [
      _c("div", { staticClass: "pb-10" }, [
        _c("div", { staticClass: "tj-cp-title" }, [
          _vm._v(
            " " + _vm._s(_vm.$stringConstants("teamJobLocationTitle")) + " "
          ),
        ]),
        _c("div", { staticClass: "tj-cp-subtitle" }, [
          _vm._v(
            " " + _vm._s(_vm.$stringConstants("teamJobLocationSubTitle")) + " "
          ),
        ]),
      ]),
      _c("div", [
        _vm.teamJobAddresses.length
          ? _c("div", [
              _c("div", { staticClass: "jb-loc-cnt jb-loc-cnt-first" }, [
                _c(
                  "div",
                  { staticClass: "ib width-perc-100 jb-list-head-text" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$stringConstants("addresses")) + " "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.teamJobsLoader,
                expression: "!teamJobsLoader",
              },
            ],
          },
          _vm._l(_vm.teamJobAddresses, function (address, index) {
            return _c("div", { key: index }, [
              _c("div", { staticClass: "jb-loc-cnt" }, [
                _c(
                  "div",
                  { staticClass: "ib width-perc-5 vertical-align-top" },
                  [
                    _c("div", { staticClass: "jb-loc-icn" }, [
                      _vm._v(" " + _vm._s(index + 1) + " "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ib width-perc-90 vertical-align-top",
                    attrs: { title: _vm.getAddressTitle(address) },
                  },
                  [
                    address.addressLine1
                      ? _c("div", { staticClass: "jb-loc-title" }, [
                          _vm._v(" " + _vm._s(address.addressLine1) + " "),
                        ])
                      : _c("div", { staticClass: "jb-loc-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants(
                                  "teamJobLocationDropPinText"
                                )
                              ) +
                              " (" +
                              _vm._s(address.coordinate.latitude.toFixed(6)) +
                              ", " +
                              _vm._s(address.coordinate.longitude.toFixed(6)) +
                              ") "
                          ),
                        ]),
                    _c("div", { staticClass: "jb-loc-ins pt-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            address.instructions ? address.instructions : ""
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "ib width-perc-5 vertical-align-top" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.teamJobViewPreference.locations.isEditable,
                            expression:
                              "teamJobViewPreference.locations.isEditable",
                          },
                        ],
                        staticClass: "pt-2 pl-4 ib cursor-pointer",
                        attrs: { title: "Remove address" },
                        on: {
                          click: function ($event) {
                            return _vm.removeAddress(index)
                          },
                        },
                      },
                      [
                        _c(VIcon, { staticClass: "job-add-list-rm" }, [
                          _vm._v("mdi-close"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ])
          }),
          0
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.teamJobsLoader,
                expression: "teamJobsLoader",
              },
            ],
          },
          _vm._l(2, function (adr, ai) {
            return _c("div", { key: ai, staticClass: "jb-loc-cnt" }, [
              _c(
                "div",
                { staticClass: "ib width-perc-5 vertical-align-top" },
                [
                  _c(VSkeletonLoader, {
                    attrs: { width: "20", height: "30", type: "card" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ib width-perc-95 vertical-align-top" },
                [
                  _c(
                    "div",
                    { staticClass: "jb-loc-title" },
                    [
                      _c(VSkeletonLoader, {
                        attrs: { width: "200", height: "20", type: "card" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "jb-loc-ins py-3 pt-3" },
                    [
                      _c(VSkeletonLoader, {
                        attrs: { width: "400", height: "10", type: "card" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ])
          }),
          0
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.teamJobsLoader &&
                  _vm.teamJobViewPreference.locations.isEditable,
                expression:
                  "!teamJobsLoader && teamJobViewPreference.locations.isEditable",
              },
            ],
            staticClass:
              "field-input-label jb-add-operator-cont pt-4 pb-4 cursor-pointer",
            class: {
              "jb-add-operator-cont-another": _vm.teamJobAddresses.length == 0,
            },
            on: { click: _vm.openAddAddressesForm },
          },
          [
            _c("div", [
              _c(
                "div",
                { staticClass: "cursor-pointer jb-add-ln-btn pr-8 py-3" },
                [
                  _vm.teamJobAddresses.length
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("teamJobLocationAddText")
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("teamJobLocationAddText")
                            ) +
                            " "
                        ),
                      ]),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "sidebar-overlay sidebar-overlay-sm position-relative jobAddressesFormOverlay",
            attrs: { id: "jobAddressesFormOverlay" },
          },
          [_c("TeamJobAddressesFlyoutForm")],
          1
        ),
      ]),
      _vm.teamJobAddresses.length == 0 &&
      !_vm.teamJobViewPreference.locations.isEditable
        ? _c(
            "div",
            [
              _c(VCol, { staticClass: "pa-0", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "jb-dt-hd jb-operators-list-head bb-0" },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$stringConstants("addresses"))),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "px-7 py-6",
                    staticStyle: { border: "0.5px solid rgb(238, 238, 238)" },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("teamJobZeroAddressText")
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }