import "core-js/modules/es.object.values.js";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
export default {
  name: "UnscheduledJobsDatepicker",
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker
  },
  props: ['position', 'topOffset', 'value'],
  data: function data() {
    return {
      jobsScheduledDate: null
    };
  },
  computed: {
    overshootsViewportBottom: function overshootsViewportBottom() {
      if (!this.$refs.datepickerRef || !this.position || !Object.values(this.position).length) return;
      var datepickerHeight = this.$refs.datepickerRef.clientHeight;
      if (this.position.bottom + datepickerHeight > window.innerHeight) return true;
      return false;
    },
    calculatedStyle: function calculatedStyle() {
      if (!this.position || !Object.values(this.position).length) return {
        opacity: 0,
        visibility: 'hidden'
      };
      if (this.overshootsViewportBottom) return {
        bottom: '100%'
      };
      return {
        top: '100%'
      };
    }
  },
  methods: {
    emitSelectedDatetime: function emitSelectedDatetime() {
      var _a;
      var jobId = (_a = this.$refs.datepickerRef.closest('.datepicker-trigger')) === null || _a === void 0 ? void 0 : _a.getAttribute('data-id');
      this.$emit('update', this.jobsScheduledDate, jobId);
    }
  },
  watch: {
    value: function value(updatedValue) {
      this.jobsScheduledDate = updatedValue;
    }
  }
};