import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    {
      class: [_vm.containerLeftPad, _vm.containerRightPad],
      attrs: { cols: "12" },
    },
    [
      _c(
        VCol,
        { staticClass: "d-flex align-center px-0 py-0", attrs: { cols: "8" } },
        [
          _c("Avatar", {
            staticClass: "mr-2",
            attrs: {
              initials:
                _vm.currentTab == 0
                  ? _vm.item.inviter.businessProfile.title
                  : "" + _vm.item.inviteeEmail,
            },
          }),
          _c(VCol, { staticClass: "px-1" }, [
            _c("span", { staticClass: "d-block rd-details" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.currentTab == 0
                      ? _vm.item.inviter.businessProfile.title
                      : _vm.item.inviteeFirstName +
                          " " +
                          _vm.item.inviteeLastName
                  ) +
                  " "
              ),
            ]),
            _c("span", { staticClass: "rd-label" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.currentTab == 0
                      ? _vm.item.inviter.email
                      : _vm.item.inviteeEmail
                  ) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        VCol,
        { staticClass: "d-flex align-items-center", attrs: { cols: "4" } },
        [
          _c(
            VRow,
            { attrs: { "no-gutters": "", justify: "end", align: "center" } },
            [
              _vm.currentTab == 0
                ? _c("Button", {
                    staticClass: "ib float-right ml-2 f-nn",
                    attrs: { color: "primary", textValue: "Accept" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.confirmAcceptInvitation(_vm.item.code)
                      },
                    },
                  })
                : _vm._e(),
              _c(
                VIcon,
                {
                  staticClass: "icon-size cursor-pointer ml-4",
                  attrs: { large: "" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDeleteInvitation(
                        _vm.item.code,
                        _vm.currentTab == 0 ? "received" : "sent"
                      )
                    },
                  },
                },
                [_vm._v("mdi-delete")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VCol,
        { attrs: { cols: "12 px-0 pt-2 pb-0 op-25" } },
        [_c(VDivider, { attrs: { light: "" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }