import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c(
        VRow,
        {
          staticClass: "flex-grow-0 px-9 pt-4",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "5" } },
            [
              _c(
                VRow,
                [
                  _c(VCol, { staticClass: "px-0", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "ib",
                        staticStyle: { "min-width": "350px" },
                      },
                      [
                        _c(VTextField, {
                          staticClass: "rd-rt-no agd-txtfield mr-4",
                          attrs: {
                            placeholder: _vm.$stringConstants(
                              "businessesSearchPlaceholder"
                            ),
                            outlined: "",
                            clearable: "",
                            "prepend-inner-icon": "mdi-magnify",
                            dense: "",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.applySearchTimer(
                                _vm.applyBusinessesFilter
                              )
                            },
                          },
                          model: {
                            value: _vm.businessesSearchText,
                            callback: function ($$v) {
                              _vm.businessesSearchText = $$v
                            },
                            expression: "businessesSearchText",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.totalRowCount
                      ? _c("div", { staticClass: "ib" }, [
                          _c("div", { staticClass: "su-list-count-chip" }, [
                            _vm._v(
                              " " + _vm._s(_vm.totalRowCount) + " businesses "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "7" } },
            [
              _c(
                VRow,
                { staticClass: "text-right" },
                [
                  _c(
                    VCol,
                    { staticClass: "px-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.businessCountry !== null,
                              expression: "businessCountry !== null",
                            },
                          ],
                          staticClass: "ib",
                        },
                        [
                          _c("div", { staticClass: "btn-filters-chip" }, [
                            _c("div", { staticClass: "ib" }, [
                              _vm._v(" " + _vm._s(_vm.businessCountry) + " "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "ib btn-filters-chip-clear" },
                              [
                                _c(
                                  VIcon,
                                  {
                                    staticClass: "oy-cl",
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilterStates(
                                          "businessCountry"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-thick ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.businessType !== null,
                              expression: "businessType !== null",
                            },
                          ],
                          staticClass: "ib",
                        },
                        [
                          _c("div", { staticClass: "btn-filters-chip" }, [
                            _c("div", { staticClass: "ib" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getBusinessType(_vm.businessType)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "ib btn-filters-chip-clear" },
                              [
                                _c(
                                  VIcon,
                                  {
                                    staticClass: "oy-cl",
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilterStates(
                                          "businessType"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-thick ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.businessSubscriptionStatus !== null,
                              expression: "businessSubscriptionStatus !== null",
                            },
                          ],
                          staticClass: "ib",
                        },
                        [
                          _c("div", { staticClass: "btn-filters-chip" }, [
                            _c("div", { staticClass: "ib" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getSubscriptionStatusType(
                                      _vm.businessSubscriptionStatus
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "ib btn-filters-chip-clear" },
                              [
                                _c(
                                  VIcon,
                                  {
                                    staticClass: "oy-cl",
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilterStates(
                                          "businessSubscriptionStatus"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-thick ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.businessesStartDate !== null &&
                                _vm.businessesEndDate !== null,
                              expression:
                                "businessesStartDate !== null && businessesEndDate !== null",
                            },
                          ],
                          staticClass: "ib",
                        },
                        [
                          _c("div", { staticClass: "btn-filters-chip" }, [
                            _c("div", { staticClass: "ib" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateTimeFormat(
                                      _vm.businessesStartDate
                                    )
                                  ) +
                                  " - " +
                                  _vm._s(
                                    _vm.getDateTimeFormat(_vm.businessesEndDate)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "ib btn-filters-chip-clear" },
                              [
                                _c(
                                  VIcon,
                                  {
                                    staticClass: "oy-cl",
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilterStates(
                                          "dateRange"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-thick ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          outlined: "",
                          outlineColor: "primary",
                          textValue: "Filter",
                          leftIcon: "mdi-filter-variant",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openBusinessesFilterForm()
                          },
                        },
                      }),
                      _c("Button", {
                        staticClass: "float-right ml-2",
                        attrs: {
                          variant: "secondary",
                          outlined: "",
                          outlineColor: "gray",
                          disabled: _vm.businesses.length === 0,
                          textValue: _vm.$stringConstants("downloadText"),
                          leftIcon: "mdi-arrow-down",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.initiateBusinessesExport($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("List", {
        attrs: { listItems: _vm.businesses, containsAction: "" },
        on: {
          setFilterOptionsAndGetListData: _vm.setFilterOptionsAndGetBusinesses,
          listItemClick: _vm.openBusinessDetailsView,
        },
        scopedSlots: _vm._u([
          {
            key: "list-heading",
            fn: function () {
              return [
                _c("div", { staticClass: "width-perc-15" }, [
                  _vm._v("Business name"),
                ]),
                _c("div", { staticClass: "width-perc-10" }, [
                  _vm._v("Country"),
                ]),
                _c("div", { staticClass: "width-perc-12" }, [
                  _vm._v("Owner name"),
                ]),
                _c("div", { staticClass: "width-perc-13" }, [
                  _vm._v("Owner contact"),
                ]),
                _c("div", { staticClass: "width-perc-7" }, [_vm._v("Type")]),
                _c("div", { staticClass: "width-perc-10" }, [
                  _vm._v("Onboarded on"),
                ]),
                _c("div", { staticClass: "width-perc-6" }, [_vm._v("Jobs")]),
                _c("div", { staticClass: "width-perc-6" }, [
                  _vm._v("Active members"),
                ]),
                _c("div", { staticClass: "width-perc-14" }, [
                  _vm._v("Subscription"),
                ]),
                _c("div", { staticClass: "width-perc-6 text-right" }, [
                  _vm._v("Amount"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "list-item",
            fn: function (ref) {
              var listItem = ref.listItem
              return [
                _c("div", { staticClass: "width-perc-15" }, [
                  listItem.name != null
                    ? _c(
                        "div",
                        { staticClass: "fw-6 d-flex align-center" },
                        [
                          _c("Avatar", {
                            staticClass: "mr-2",
                            attrs: {
                              initials: listItem.name,
                              width: "28px",
                              height: "28px",
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "d-inline-block pl-1 pr-8" },
                            [_vm._v(_vm._s(listItem.name))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-10 pr-6",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    _c("div", [
                      _vm._v(" " + _vm._s(listItem.country || "-") + " "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-12 pr-6",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    _c("div", [
                      _vm._v(" " + _vm._s(listItem.ownerName || "-") + " "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-13 pr-6",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: listItem.ownerEmail,
                              expression: "listItem.ownerEmail",
                            },
                          ],
                        },
                        [_vm._v(" " + _vm._s(listItem.ownerEmail) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: listItem.ownerContact,
                              expression: "listItem.ownerContact",
                            },
                          ],
                        },
                        [_vm._v(" " + _vm._s(listItem.ownerContact) + " ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-7 pr-6",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    _c("div", [
                      _vm._v(" " + _vm._s(listItem.businessType || "-") + " "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-10 pr-6",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getDateTimeFormat(listItem.createdOn)) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-6 pr-6",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [_c("div", [_vm._v(" " + _vm._s(listItem.totalJobs) + " ")])]
                ),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-6 pr-6",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(listItem.teamMembers.active) +
                          "/" +
                          _vm._s(listItem.teamMembers.total) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-14 pr-2",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    _c("div", [
                      listItem.subscriptions.status !== null
                        ? _c("div", [
                            !listItem.subscriptions.cancellationScheduledOn
                              ? _c("span", [
                                  listItem.subscriptions.status ==
                                  _vm.SubscriptionStatusType.Trialing
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            class: [
                                              _vm.boldIfTrialTimeRemaining(
                                                listItem.subscriptions.trialEnd
                                              ),
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getTrialDaysLeft(
                                                    listItem.subscriptions
                                                      .trialEnd
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c("span", [
                                        listItem.subscriptions.status ==
                                        _vm.SubscriptionStatusType.Active
                                          ? _c("span", [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getSubscriptionStatusEnum(
                                                        listItem.subscriptions
                                                          .status
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("span", [
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      listItem.subscriptions
                                                        .currentPeriodStart
                                                        ? _vm.getDateTimeFormat(
                                                            listItem
                                                              .subscriptions
                                                              .currentPeriodStart
                                                          )
                                                        : "N/A"
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      listItem.subscriptions
                                                        .currentPeriodEnd
                                                        ? _vm.getDateTimeFormat(
                                                            listItem
                                                              .subscriptions
                                                              .currentPeriodEnd
                                                          )
                                                        : "N/A"
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ])
                                          : listItem.subscriptions.status ==
                                            _vm.SubscriptionStatusType.Cancelled
                                          ? _c(
                                              "span",
                                              { style: { color: "red" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getSubscriptionStatusEnum(
                                                        listItem.subscriptions
                                                          .status
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("span", [
                                                  _c("br"),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getDateTimeFormattedLocal(
                                                          listItem.subscriptions
                                                            .cancelledAt
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getSubscriptionStatusEnum(
                                                      listItem.subscriptions
                                                        .status
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                      ]),
                                  listItem.subscriptions.status ==
                                  _vm.SubscriptionStatusType.Trialing
                                    ? _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              listItem.subscriptions.trialStart
                                                ? _vm.getDateTimeFormat(
                                                    listItem.subscriptions
                                                      .trialStart
                                                  )
                                                : "N/A"
                                            ) +
                                            " - " +
                                            _vm._s(
                                              listItem.subscriptions.trialEnd
                                                ? _vm.getDateTimeFormat(
                                                    listItem.subscriptions
                                                      .trialEnd
                                                  )
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.getCancellationDate(
                                    listItem.subscriptions
                                  )
                                    ? "red"
                                    : "#000",
                                },
                                attrs: {
                                  title: _vm.getCancellationReason(
                                    listItem.subscriptions
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getCancellationDate(
                                        listItem.subscriptions
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        : _c("div", [
                            listItem.subscriptions.trialStart ||
                            listItem.subscriptions.trialEnd
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.boldIfTrialTimeRemaining(
                                          listItem.subscriptions.trialEnd
                                        ),
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getTrialDaysLeft(
                                              listItem.subscriptions.trialEnd
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          listItem.subscriptions.trialStart
                                            ? _vm.getDateTimeFormat(
                                                listItem.subscriptions
                                                  .trialStart
                                              )
                                            : "N/A"
                                        ) +
                                        " - " +
                                        _vm._s(
                                          listItem.subscriptions.trialEnd
                                            ? _vm.getDateTimeFormat(
                                                listItem.subscriptions.trialEnd
                                              )
                                            : "N/A"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "width-perc-6 pr-6 text-right",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getSusbcriptionAmountIfExists(listItem) || "-"
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "zero-state",
            fn: function () {
              return [
                _c("div", [
                  _c("h4", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$stringConstants("suBusinessesZeroStateHeading")
                      )
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.showExportDialog,
            callback: function ($$v) {
              _vm.showExportDialog = $$v
            },
            expression: "showExportDialog",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "dlt-diag py-5" },
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("suBusinessesLoaderDialogTitle")
                    ) +
                    " "
                ),
              ]),
              _c(
                VCardText,
                [
                  _c(VProgressCircular, {
                    staticStyle: {
                      transform: "translateY(-2px) translateX(-2px)",
                    },
                    attrs: {
                      indeterminate: "",
                      width: "2",
                      size: "18",
                      color: "#b3b3b3",
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants("suBusinssesLoaderDialogContent")
                      ) +
                      " (" +
                      _vm._s(_vm.exportDataLoaded) +
                      " / " +
                      _vm._s(_vm.exportDataTotal) +
                      ") "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }