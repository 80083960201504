import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pl-9 pr-8 pb-6" }, [
    _c(
      "div",
      [
        _c(VRow, { staticClass: "align-center" }, [
          _c("div", { staticClass: "py-1 text--black f-22 font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.invoiceDraftPreviewDetails &&
                    _vm.invoiceDraftPreviewDetails.number != null
                    ? _vm.invoiceDraftPreviewDetails.number
                    : ""
                ) +
                " "
            ),
          ]),
          _c(
            "div",
            {
              class:
                "email-status-wrapper d-flex fw-6 f-12 ml-4 align-center " +
                _vm.invoiceStatus.toLowerCase() +
                " " +
                _vm.invoiceEmailStatus,
            },
            [
              _c("span", { staticClass: "invoice-status px-2 py-1" }, [
                _vm._v(_vm._s(_vm.invoiceStatus)),
              ]),
              _vm.isInvoiceStatusOpen
                ? _c("img", {
                    staticClass: "pl-3 pr-1",
                    attrs: {
                      src: require("../../../assets/email-" +
                        _vm.invoiceEmailStatus +
                        ".svg"),
                    },
                  })
                : _vm._e(),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isInvoiceStatusOpen
                      ? "Email " + _vm.invoiceEmailStatus
                      : ""
                  ) +
                  " "
              ),
            ]
          ),
        ]),
        _c(VRow, { staticClass: "pb-4 pt-7" }, [
          _c("div", { staticClass: "ib pt-0 pl-0 mr-4 inc-crd-min-w" }, [
            _c("div", { staticClass: "pb-2" }, [
              _c("div", { staticClass: "fw-6 inv-title-fn color-dgray py-6" }, [
                _vm._v(_vm._s(_vm.$stringConstants("contractor"))),
              ]),
              _vm.invoiceDraftPreviewDetails &&
              _vm.invoiceDraftPreviewDetails.supplier
                ? _c(
                    "div",
                    {
                      staticClass: "inv-int-crd inv-text-sm",
                      staticStyle: { "font-size": "14px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "py-1 pl-6 pb-2 position-relative" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ib",
                              staticStyle: { width: "8px" },
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "\n                                            inv-crd-icn inv-crd-ofc-icn\n                                            pr-2\n                                            inv-id-icon-pos inv-id-icon-pos-int\n                                            ",
                                attrs: {
                                  src: require("../../../assets/icon-contractor.svg"),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "ib inv-text-sm fw-5",
                              attrs: {
                                title:
                                  _vm.invoiceDraftPreviewDetails.supplier.name,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.invoiceDraftPreviewDetails.supplier.name.substring(
                                      0,
                                      _vm.maxCompanyNameLength
                                    )
                                  ) +
                                  " "
                              ),
                              _vm.invoiceDraftPreviewDetails.supplier.name
                                .length > _vm.maxCompanyNameLength
                                ? _c("span", [_vm._v(" ... ")])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _c("div", [
                        _c("div", {
                          staticClass: "ib",
                          staticStyle: { width: "32px" },
                        }),
                        _c(
                          "div",
                          { staticClass: "ib", staticStyle: { width: "90%" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "py-1 inv-text-xsm",
                                attrs: {
                                  title: _vm.getAddressString(
                                    _vm.invoiceDraftPreviewDetails.supplier
                                      .address
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .getAddressString(
                                          _vm.invoiceDraftPreviewDetails
                                            .supplier.address
                                        )
                                        .substring(0, _vm.maxAddressLength)
                                    ) +
                                    " "
                                ),
                                _vm.getAddressString(
                                  _vm.invoiceDraftPreviewDetails.supplier
                                    .address
                                ).length > _vm.maxAddressLength
                                  ? _c("span", [_vm._v(" ... ")])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c("div", {
                          staticClass: "ib",
                          staticStyle: { width: "32px" },
                        }),
                        _c(
                          "div",
                          { staticClass: "ib", staticStyle: { width: "90%" } },
                          [
                            _vm.invoiceDraftPreviewDetails.supplier.emailAddress
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "py-1 inv-text-xsm" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.invoiceDraftPreviewDetails
                                              .supplier.emailAddress
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.invoiceDraftPreviewDetails
                                              .supplier.phoneNumber
                                              ? ", " +
                                                  _vm.invoiceDraftPreviewDetails
                                                    .supplier.phoneNumber
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c("div", {
                          staticClass: "ib",
                          staticStyle: { width: "32px" },
                        }),
                        _c(
                          "div",
                          { staticClass: "ib", staticStyle: { width: "90%" } },
                          [
                            _vm.taxName
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.taxName) +
                                      " " +
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "invoiceTaxTypeNumberText"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.getSalesTaxRegistrationNumber() ||
                                          ""
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .$stringConstants("taxText")
                                          .toUpperCase()
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "invoiceTaxTypeNumberText"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.getSalesTaxRegistrationNumber() ||
                                          ""
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "ib py-0 ml-2 mr-6 inv-sp-cont" }),
          _c("div", { staticClass: "ib pt-0 pl-0 mr-4 inc-crd-min-w" }, [
            _c("div", { staticClass: "pb-2" }, [
              _c("div", { staticClass: "fw-6 inv-title-fn color-dgray py-6" }, [
                _vm._v(_vm._s(_vm.$stringConstants("customer"))),
              ]),
              _vm.invoiceDraftPreviewDetails &&
              _vm.invoiceDraftPreviewDetails.supplier
                ? _c(
                    "div",
                    {
                      staticClass: "inv-int-crd inv-text-sm",
                      staticStyle: { "font-size": "14px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "py-1 pl-6 pb-2 position-relative" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ib",
                              staticStyle: { width: "8px" },
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "\n                                            inv-crd-icn inv-crd-ofc-icn\n                                            pr-2\n                                            inv-id-icon-pos inv-id-icon-pos-int\n                                            ",
                                attrs: {
                                  src: require("../../../assets/icon-customers.svg"),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "ib inv-text-sm fw-5",
                              attrs: {
                                title:
                                  _vm.invoiceDraftPreviewDetails.customer.name,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.invoiceDraftPreviewDetails.customer.name.substring(
                                      0,
                                      _vm.maxCompanyNameLength
                                    )
                                  ) +
                                  " "
                              ),
                              _vm.invoiceDraftPreviewDetails.customer.name
                                .length > _vm.maxCompanyNameLength
                                ? _c("span", [_vm._v(" ... ")])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _c("div", [
                        _c("div", {
                          staticClass: "ib",
                          staticStyle: { width: "32px" },
                        }),
                        _c(
                          "div",
                          { staticClass: "ib", staticStyle: { width: "90%" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "py-1 inv-text-xsm",
                                attrs: {
                                  title: _vm.getAddressString(
                                    _vm.invoiceDraftPreviewDetails.customer
                                      .address
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .getAddressString(
                                          _vm.invoiceDraftPreviewDetails
                                            .customer.address
                                        )
                                        .substring(0, _vm.maxAddressLength)
                                    ) +
                                    " "
                                ),
                                _vm.getAddressString(
                                  _vm.invoiceDraftPreviewDetails.customer
                                    .address
                                ).length > _vm.maxAddressLength
                                  ? _c("span", [_vm._v(" ... ")])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c("div", {
                          staticClass: "ib",
                          staticStyle: { width: "32px" },
                        }),
                        _c(
                          "div",
                          { staticClass: "ib", staticStyle: { width: "90%" } },
                          [
                            _vm.invoiceDraftPreviewDetails.customer.emailAddress
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "py-1 inv-text-xsm" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.invoiceDraftPreviewDetails
                                              .customer.emailAddress
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.invoiceDraftPreviewDetails
                                              .customer.phoneNumber
                                              ? ", " +
                                                  _vm.invoiceDraftPreviewDetails
                                                    .customer.phoneNumber
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm.invoiceDraftPreviewDetails
          ? _c(
              "div",
              { staticClass: "py-4", staticStyle: { "font-size": "14px" } },
              [
                _vm.invoiceDraftPreviewDetails.lineItems.length > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          VRow,
                          {
                            staticClass:
                              "px-0 inv-items-header mb-4 color-dgray",
                          },
                          [
                            _c(
                              VCol,
                              {
                                staticClass: "pl-6 pr-0 py-2",
                                attrs: { cols: "1" },
                              },
                              [_vm._v(_vm._s(_vm.$stringConstants("jobNo")))]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "pl-4 pr-0 py-2",
                                attrs: { cols: "1" },
                              },
                              [_vm._v("Date")]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "px-0 py-2",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$stringConstants("particularsText")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              VCol,
                              { staticClass: "py-2", attrs: { cols: "1" } },
                              [_vm._v(_vm._s(_vm.$stringConstants("rateText")))]
                            ),
                            _c(
                              VCol,
                              { staticClass: "py-2", attrs: { cols: "2" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$stringConstants("quantityText"))
                                ),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "py-2 pl-0",
                                attrs: { cols: "1" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$stringConstants("amountText"))
                                ),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "py-2",
                                attrs: { cols: "2", xl: "2" },
                              },
                              [
                                _c("div", { staticClass: "ib" }, [
                                  _vm.taxName
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.taxName) +
                                            "(" +
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "percentageSymbol"
                                              )
                                            ) +
                                            ") "
                                        ),
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants("taxText")
                                            ) +
                                            "(" +
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "percentageSymbol"
                                              )
                                            ) +
                                            ") "
                                        ),
                                      ]),
                                ]),
                                _c("div", { staticClass: "ib" }, [
                                  _vm.taxValuesListForInvoice.length === 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "invoice-tax-warning-icon",
                                          on: { click: _vm.routeToAccount },
                                        },
                                        [
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: {
                                                bottom: "",
                                                color: "#000",
                                                "max-width": "300px",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          VIcon,
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "cursor-pointer",
                                                                staticStyle: {
                                                                  color:
                                                                    "#000 !important",
                                                                },
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-information"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1718874519
                                              ),
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "tol-cont" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$stringConstants(
                                                          "invoiceNoTaxRatesWarning"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "py-2 pl-0",
                                attrs: { cols: "1", xl: "1" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.taxName
                                      ? _vm.taxName
                                      : _vm.$stringConstants("taxText")
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.$stringConstants("taxAmountText")
                                    )
                                ),
                              ]
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "py-2 pl-0",
                                attrs: { cols: "1", xl: "1" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$stringConstants("totalAmountText")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.invoiceLineItemJobNumbersDistinct,
                          function (distinctJobNumber, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "inv-br-btm" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "position-relative inv-jb-no-pos inv-jb-no-res inv-jb-color cursor-pointer",
                                    staticStyle: { "z-index": "1" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.routeToJobDetails(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(distinctJobNumber) + " "
                                    ),
                                  ]
                                ),
                                _vm.invoiceDraftPreviewDetails.lineItems
                                  .length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "inv-mn-items inv-text-sm pr-2",
                                      },
                                      _vm._l(
                                        _vm.invoiceDraftPreviewDetails
                                          .lineItems,
                                        function (lineItem, index) {
                                          return _c("div", { key: index }, [
                                            lineItem.reference ==
                                            distinctJobNumber
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      VRow,
                                                      {
                                                        staticClass:
                                                          "px-0 position-relative inv-jb-data-row pb-4",
                                                      },
                                                      [
                                                        _c(VCol, {
                                                          staticClass:
                                                            "py-0 pr-0 py-0",
                                                          attrs: { cols: "1" },
                                                        }),
                                                        _c(
                                                          VCol,
                                                          {
                                                            staticClass:
                                                              "py-0 pr-0 py-0 pl-4 pr-2",
                                                            attrs: {
                                                              cols: "1",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getDateFormatted(
                                                                  lineItem.date
                                                                ) || "-"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          VCol,
                                                          {
                                                            staticClass:
                                                              "px-0 fw-5 py-0",
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  lineItem.title
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          VCol,
                                                          {
                                                            staticClass: "py-0",
                                                            attrs: {
                                                              cols: "1",
                                                            },
                                                          },
                                                          [
                                                            _c(VTextField, {
                                                              staticClass:
                                                                "inv-fld-pos rmv-prfx-pd",
                                                              attrs: {
                                                                prefix:
                                                                  _vm.invoiceCurrencySymbol,
                                                                type: "number",
                                                                disabled:
                                                                  _vm
                                                                    .invoiceDraftPreviewDetails
                                                                    .status !=
                                                                  0,
                                                                outlined: "",
                                                                dense: "",
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.negativeKeydown(
                                                                      $event
                                                                    )
                                                                  },
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleLineItemPricing(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  lineItem.rate,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      lineItem,
                                                                      "rate",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "lineItem.rate",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          VCol,
                                                          {
                                                            staticClass: "py-0",
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ib",
                                                              },
                                                              [
                                                                _c(
                                                                  VTextField,
                                                                  {
                                                                    staticClass:
                                                                      "input-with-unit px-0 inv-fld-pos",
                                                                    attrs: {
                                                                      min: "0",
                                                                      type: "number",
                                                                      disabled:
                                                                        _vm
                                                                          .invoiceDraftPreviewDetails
                                                                          .status !=
                                                                        0,
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleQuantityChange(
                                                                            $event,
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "append",
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class:
                                                                                      "unit-bg-color text--disabled align-self-center pl-2 pr-3",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            lineItem.unit
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        lineItem.quantity,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            lineItem,
                                                                            "quantity",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "lineItem.quantity",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          VCol,
                                                          {
                                                            staticClass:
                                                              "py-0 break-word",
                                                            attrs: {
                                                              cols: "1",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.invoiceCurrencySymbol
                                                                ) +
                                                                _vm._s(
                                                                  parseFloat(
                                                                    (
                                                                      lineItem.rate *
                                                                      lineItem.quantity
                                                                    ).toString()
                                                                  ).toFixed(2)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          VCol,
                                                          {
                                                            staticClass:
                                                              "py-0 inv-mn-items-tax",
                                                            attrs: {
                                                              cols: "2",
                                                              xl: "2",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  VCombobox,
                                                                  {
                                                                    staticClass:
                                                                      "inv-fld-pos",
                                                                    attrs: {
                                                                      items:
                                                                        _vm.taxValuesListForInvoice,
                                                                      "item-text":
                                                                        "rateInPercentage",
                                                                      "item-value":
                                                                        "rateInPercentage",
                                                                      disabled:
                                                                        _vm
                                                                          .invoiceDraftPreviewDetails
                                                                          .status !=
                                                                        0,
                                                                      outlined:
                                                                        "",
                                                                      "return-object": false,
                                                                      dense: "",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleLineItemPricing(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "selection",
                                                                            fn: function (
                                                                              data
                                                                            ) {
                                                                              return [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      data
                                                                                        ? lineItem
                                                                                            .taxAmounts[0]
                                                                                            .salesTaxRateInPercentage
                                                                                        : ""
                                                                                    ) +
                                                                                    _vm._s(
                                                                                      _vm.$stringConstants(
                                                                                        "percentageSymbol"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                          {
                                                                            key: "item",
                                                                            fn: function (
                                                                              data
                                                                            ) {
                                                                              return [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.getRateNameAndValue(
                                                                                        data
                                                                                          .item
                                                                                          .rateName,
                                                                                        data
                                                                                          .item
                                                                                          .rateInPercentage,
                                                                                        lineItem
                                                                                          .taxAmounts[0]
                                                                                          .salesTaxRateInPercentage
                                                                                      )
                                                                                    ) +
                                                                                    _vm._s(
                                                                                      _vm.$stringConstants(
                                                                                        "percentageSymbol"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        lineItem
                                                                          .taxAmounts[0]
                                                                          .salesTaxRateInPercentage,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            lineItem
                                                                              .taxAmounts[0],
                                                                            "salesTaxRateInPercentage",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "lineItem.taxAmounts[0].salesTaxRateInPercentage",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          VCol,
                                                          {
                                                            staticClass:
                                                              "py-0 break-word",
                                                            attrs: {
                                                              cols: "1",
                                                              xl: "1",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.invoiceCurrencySymbol
                                                                ) +
                                                                _vm._s(
                                                                  parseFloat(
                                                                    lineItem
                                                                      .taxAmounts[0]
                                                                      .amount
                                                                  ).toFixed(2)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          VCol,
                                                          {
                                                            staticClass:
                                                              "py-0 break-word",
                                                            attrs: {
                                                              cols: "1",
                                                              xl: "1",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.invoiceCurrencySymbol
                                                                ) +
                                                                _vm._s(
                                                                  parseFloat(
                                                                    _vm.getLineItemTotal(
                                                                      lineItem
                                                                    )
                                                                  ).toFixed(2)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        lineItem.type != 0 &&
                                                        _vm
                                                          .invoiceDraftPreviewDetails
                                                          .status == 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cursor-pointer remove-icon",
                                                                attrs: {
                                                                  title:
                                                                    "Remove product",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeInternalLineItem(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "CloseButton"
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                !_vm.showAddLineitemsForInternalInvoice[
                                  index
                                ] && _vm.invoiceDraftPreviewDetails.status == 0
                                  ? _c("div", { staticClass: "mb-6 ml-4" }, [
                                      _c(
                                        "div",
                                        [
                                          _c("Button", {
                                            attrs: {
                                              variant: "secondary",
                                              outlined: "",
                                              outlineColor: "gray",
                                              textValue: "+ Add",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.showAddLineItemViewInternal(
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.showAddLineitemsForInternalInvoice[index]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "inv-lineitem-add mb-6 ml-4",
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ib width-perc-20 pr-4 vertical-align-top",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb-2" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$stringConstants(
                                                          "addNewProductText"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  _c(VAutocomplete, {
                                                    staticClass: " pb-1",
                                                    attrs: {
                                                      items:
                                                        _vm.productListForInternalInvoice,
                                                      "search-input":
                                                        _vm.invoiceInternalLineItemSearchText,
                                                      "item-text": "title",
                                                      placeholder:
                                                        _vm.$stringConstants(
                                                          "invoiceExternalProductSearchTitle"
                                                        ),
                                                      outlined: "",
                                                      loading:
                                                        _vm.productFeatchingLoader,
                                                      dense: "",
                                                      attach: "",
                                                      "hide-no-data": "",
                                                      clearable: "",
                                                      "return-object": "",
                                                      flat: "",
                                                      solo: "",
                                                    },
                                                    on: {
                                                      "update:searchInput":
                                                        function ($event) {
                                                          _vm.invoiceInternalLineItemSearchText =
                                                            $event
                                                        },
                                                      "update:search-input":
                                                        function ($event) {
                                                          _vm.invoiceInternalLineItemSearchText =
                                                            $event
                                                        },
                                                      focus:
                                                        _vm.applyProductFilterSearchTimerFocus,
                                                      change:
                                                        _vm.setProductInfoInLineItem,
                                                      "click:clear":
                                                        _vm.applyProductFilterSearchTimer,
                                                    },
                                                    nativeOn: {
                                                      input: function ($event) {
                                                        return _vm.applyProductFilterSearchTimer(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "append-item",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "LoadMoreItem",
                                                                {
                                                                  attrs: {
                                                                    searchText:
                                                                      _vm.invoiceInternalLineItemSearchText,
                                                                    loadedListLength:
                                                                      _vm
                                                                        .productListForInternalInvoice
                                                                        .length,
                                                                    moduleNamespace:
                                                                      _vm.$stringConstants(
                                                                        "invoicesProductListFilter"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    loadMoreItems:
                                                                      _vm.appendMoreProducts,
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.newProductForInternalInvoice,
                                                      callback: function ($$v) {
                                                        _vm.newProductForInternalInvoice =
                                                          $$v
                                                      },
                                                      expression:
                                                        "newProductForInternalInvoice",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ib width-perc-15 pr-4 vertical-align-top",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb-2" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$stringConstants(
                                                          "rateText"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(VTextField, {
                                                attrs: {
                                                  min: "0",
                                                  prefix:
                                                    _vm.invoiceCurrencySymbol,
                                                  type: "number",
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm
                                                      .newLineItemsInitialsForInternal
                                                      .rate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newLineItemsInitialsForInternal,
                                                      "rate",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "newLineItemsInitialsForInternal.rate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ib width-perc-15 pr-4 vertical-align-top",
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "pb-2" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$stringConstants(
                                                              "quantityText"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(VTextField, {
                                                    staticClass:
                                                      "input-with-unit px-0",
                                                    attrs: {
                                                      type: "number",
                                                      disabled:
                                                        _vm
                                                          .invoiceDraftPreviewDetails
                                                          .status != 0,
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "append",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  class:
                                                                    "unit-bg-color text--disabled align-self-center pl-2 pr-3",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .newLineItemsInitialsForInternal
                                                                          .unit
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm
                                                          .newLineItemsInitialsForInternal
                                                          .quantity,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newLineItemsInitialsForInternal,
                                                          "quantity",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "newLineItemsInitialsForInternal.quantity",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c("Button", {
                                              attrs: {
                                                variant: "list",
                                                color: "primary",
                                                text: "",
                                                disabled: _vm.validateAddItem,
                                                textValue: "Add",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.addNewLineItemInternal(
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              VBtn,
                                              {
                                                staticClass:
                                                  "btn-gray-light-bl-text f-nn ml-2",
                                                attrs: { elevation: "0" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.cancelNewLineItemAdd()
                                                  },
                                                },
                                              },
                                              [_vm._v(" Cancel ")]
                                            ),
                                            _vm.isProductInList
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "ib pl-6",
                                                    staticStyle: {
                                                      color: "red",
                                                      "font-weight": "600",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$stringConstants(
                                                            "productInListText"
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        _c(
                          VRow,
                          [
                            _c(
                              VCol,
                              {
                                staticClass: "px-0 pt-8 pr-6",
                                attrs: { cols: "6" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pb-10 inv-text-xsm fw-6 color-dgray ltr-spc text-uppercase",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "invoiceDraftDueDate"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      VMenu,
                                      {
                                        ref: "menuDate",
                                        attrs: {
                                          "close-on-content-click": false,
                                          "return-value": _vm.invoiceDate,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "min-width": "auto",
                                        },
                                        on: {
                                          "update:returnValue": function (
                                            $event
                                          ) {
                                            _vm.invoiceDate = $event
                                          },
                                          "update:return-value": function (
                                            $event
                                          ) {
                                            _vm.invoiceDate = $event
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    VTextField,
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            value:
                                                              _vm.computedDateFormatted,
                                                            label:
                                                              _vm.$stringConstants(
                                                                "invoiceDraftDueDateLabel"
                                                              ),
                                                            "append-icon":
                                                              "mdi-calendar",
                                                            readonly: "",
                                                            clearable: "",
                                                            outlined: "",
                                                            disabled:
                                                              _vm
                                                                .invoiceDraftPreviewDetails
                                                                .status != 0,
                                                            dense: "",
                                                          },
                                                          on: {
                                                            "click:append":
                                                              on.click,
                                                            "click:clear":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.invoiceDate =
                                                                  null
                                                              },
                                                          },
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          315715836
                                        ),
                                        model: {
                                          value: _vm.menuDate,
                                          callback: function ($$v) {
                                            _vm.menuDate = $$v
                                          },
                                          expression: "menuDate",
                                        },
                                      },
                                      [
                                        _c(
                                          VDatePicker,
                                          {
                                            attrs: {
                                              "no-title": "",
                                              color: "#182879",
                                              scrollable: "",
                                            },
                                            model: {
                                              value: _vm.invoiceDate,
                                              callback: function ($$v) {
                                                _vm.invoiceDate = $$v
                                              },
                                              expression: "invoiceDate",
                                            },
                                          },
                                          [
                                            _c(VSpacer),
                                            _c("HoverTextButton", {
                                              attrs: {
                                                color: "primary",
                                                textValue: "Cancel",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.menuDate = false
                                                },
                                              },
                                            }),
                                            _c("HoverTextButton", {
                                              attrs: {
                                                color: "primary",
                                                textValue: "OK",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.$refs.menuDate.save(
                                                    _vm.invoiceDate
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(VTextarea, {
                                  attrs: {
                                    label: "Instructions",
                                    dense: "",
                                    required: "",
                                    "auto-grow": "",
                                    outlined: "",
                                    disabled:
                                      _vm.invoiceDraftPreviewDetails.status !=
                                      0,
                                    rows: "5",
                                    "row-height": "25",
                                  },
                                  model: {
                                    value:
                                      _vm.invoiceDraftPreviewDetails
                                        .instructions,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoiceDraftPreviewDetails,
                                        "instructions",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "invoiceDraftPreviewDetails.instructions",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              VCol,
                              {
                                staticClass: "px-0 pb-8",
                                attrs: { cols: "5" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-6 pb-2 inv-text-xsm fw-6 color-dgray ltr-spc text-uppercase",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "invoiceDraftBilling"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-left py-6 px-6 inv-ttl",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pb-2",
                                        staticStyle: { "font-size": "15px" },
                                      },
                                      [
                                        _c(
                                          VRow,
                                          { staticClass: "px-0" },
                                          [
                                            _c(
                                              VCol,
                                              {
                                                staticClass:
                                                  "px-0 py-2 break-word",
                                                attrs: { cols: "8" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$stringConstants(
                                                        "invoiceDraftBillingSubtotal"
                                                      )
                                                    ) +
                                                    " (excluding " +
                                                    _vm._s(
                                                      _vm.taxName
                                                        ? _vm.taxName
                                                        : _vm.$stringConstants(
                                                            "taxText"
                                                          )
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              VCol,
                                              {
                                                staticClass:
                                                  "px-0 py-2 fw-7 break-word",
                                                attrs: { cols: "4" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.invoiceCurrencySymbol
                                                    ) +
                                                    _vm._s(_vm.getSubtotal()) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pb-2",
                                        staticStyle: {
                                          "font-size": "15px",
                                          "border-bottom": "1px solid #e1e1e1",
                                        },
                                      },
                                      [
                                        _c(
                                          VRow,
                                          [
                                            _c(
                                              VCol,
                                              {
                                                staticClass:
                                                  "px-0 py-2 break-word",
                                                attrs: { cols: "8" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$stringConstants(
                                                      "invoiceDraftBillingTotalTax"
                                                    )
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.$stringConstants(
                                                        "taxText"
                                                      )
                                                    ) +
                                                    " amount (" +
                                                    _vm._s(
                                                      _vm.taxName
                                                        ? _vm.taxName
                                                        : _vm.$stringConstants(
                                                            "taxText"
                                                          )
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              VCol,
                                              {
                                                staticClass:
                                                  "px-0 py-2 fw-7 break-word",
                                                attrs: { cols: "4" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.invoiceCurrencySymbol
                                                    ) +
                                                    _vm._s(_vm.getVatTotal()) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pb-2 pt-4",
                                        staticStyle: { "font-size": "15px" },
                                      },
                                      [
                                        _c(
                                          VRow,
                                          [
                                            _c(
                                              VCol,
                                              {
                                                staticClass: "px-0 py-2 pt-3",
                                                attrs: { cols: "8" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$stringConstants(
                                                        "invoiceDraftBillingTotalPayable"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              VCol,
                                              {
                                                staticClass:
                                                  "px-0 py-2 fw-7 break-word",
                                                staticStyle: {
                                                  "font-size": "20px",
                                                },
                                                attrs: { cols: "4" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.invoiceCurrencySymbol
                                                    ) +
                                                    _vm._s(
                                                      (
                                                        parseFloat(
                                                          _vm.getSubtotal()
                                                        ) +
                                                        parseFloat(
                                                          _vm.getVatTotal()
                                                        )
                                                      ).toFixed(2)
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.invoiceDraftPreviewDetails.attachments.length > 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "pb-4 inv-text-xsm fw-6 color-dgray ltr-spc text-uppercase",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "invoiceDraftAttachments"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  VRow,
                                  { staticClass: "pb-12" },
                                  [
                                    _c(
                                      VCol,
                                      {
                                        staticClass: "px-0",
                                        attrs: { cols: "6" },
                                      },
                                      _vm._l(
                                        _vm.invoiceDraftPreviewDetails
                                          .attachments,
                                        function (reportUrl, r) {
                                          return _c(
                                            VRow,
                                            {
                                              key: r,
                                              staticClass: "mx-0 px-0",
                                            },
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "pb-0 px-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ib fw-7 cursor-pointer attach-crd",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.viewReport(
                                                            reportUrl.url
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "ib" },
                                                        [
                                                          _c(
                                                            VIcon,
                                                            {
                                                              staticClass:
                                                                "icon-size",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-file "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ib pl-2 attach-crd-ref-txt",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getJobNumber(
                                                                  r
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c(VCol, {
                                      staticClass: "px-0",
                                      attrs: { cols: "6" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm.invoiceJobReportUrls.length > 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "pb-4 inv-text-xsm fw-6 color-dgray ltr-spc text-uppercase",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "invoiceDraftAttachments"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  VRow,
                                  { staticClass: "pb-12" },
                                  [
                                    _c(
                                      VCol,
                                      {
                                        staticClass: "px-0",
                                        attrs: { cols: "6" },
                                      },
                                      _vm._l(
                                        _vm.invoiceJobReportUrls,
                                        function (reportUrl, r) {
                                          return _c(
                                            VRow,
                                            {
                                              key: r,
                                              staticClass: "mx-0 px-0",
                                            },
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  staticClass: "pb-0 px-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ib fw-7 cursor-pointer attach-crd",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.viewReport(
                                                            reportUrl.url
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "ib" },
                                                        [
                                                          _c(
                                                            VIcon,
                                                            {
                                                              staticClass:
                                                                "icon-size",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-file "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ib pl-2 attach-crd-ref-txt",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getJobNumber(
                                                                  r
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ib fw-7 pl-2 cursor-pointer attach-crd-crs-wd",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeAttachment(
                                                            r
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "icon-size",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-close-circle "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c(VCol, {
                                      staticClass: "px-0",
                                      attrs: { cols: "6" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }