import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { notify } from "@/utils/helpers";
import SubHeaderPrimary from "@/components/shared/headers/subheader-primary.vue";
import DescriptionPrimary from "@/components/shared/headers/description-primary.vue";
import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import { mapMutations } from "vuex";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import { excelParser } from '@/utils/helpers/excel-parser-helpers';
import { ImportStatusType } from "@/enum/importStatusType";
export default {
  name: "ImportCSVForm",
  props: ["importOverlayCloseFn", "importSampleData", "importDispatchFn", "importListName", "inprogressText1", "inprogressText2", "uploadFileText", "requiredFields", "uploadNoteTitle", "uploadNotes", "importResourceType"],
  components: {
    SubHeaderPrimary: SubHeaderPrimary,
    DescriptionPrimary: DescriptionPrimary,
    ListLoader: ListLoader
  },
  data: function data() {
    return {
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      dragging: false,
      fileType: ["csv"],
      sampleFileName: "sample_file",
      errorFileName: "file_with_errors",
      exportTypeExtension: 'csv'
    };
  },
  computed: {
    selectedFile: {
      get: function get() {
        return this.$store.getters.importSelectedFile;
      },
      set: function set(newVal) {
        this.$store.state.importSelectedFile = newVal;
      }
    },
    importResponseData: {
      get: function get() {
        return this.$store.getters.importResponseData;
      },
      set: function set(newVal) {
        this.$store.state.importResponseData = newVal;
      }
    },
    importInProgress: {
      get: function get() {
        return this.$store.getters.importInProgress;
      },
      set: function set(newVal) {
        this.$store.state.importInProgress = newVal;
      }
    },
    fileName: {
      get: function get() {
        return this.$store.getters.importfileName;
      },
      set: function set(newVal) {
        this.$store.state.importfileName = newVal;
      }
    },
    importInvalidData: {
      get: function get() {
        return this.$store.getters.importInvalidData;
      },
      set: function set(newVal) {
        this.$store.state.importInvalidData = newVal;
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFarmerFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    downloadSampleCustomersFile: function downloadSampleCustomersFile() {
      excelParser().exportDataFromJSON(this.importSampleData, this.sampleFileName, this.exportTypeExtension);
    },
    onChange: function onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files[0]);
    },
    createFile: function createFile(file) {
      var type = file.name.split(".").pop().toLowerCase();
      if (!this.fileType.includes(type)) {
        notify("please select a valid file", "fail");
        this.dragging = false;
        return;
      }
      this.fileName = file.name;
      this.selectedFile = file;
      this.dragging = false;
    },
    removeFile: function removeFile() {
      this.selectedFile = null;
      this.fileName = null;
    },
    proceedFileUpload: function proceedFileUpload(onImportAction) {
      var _this = this;
      if (this.selectedFile != null) {
        this.importInProgress = true;
        this.importResponseData = null;
        var formData = new FormData();
        formData.append("file", this.selectedFile);
        this.$store.dispatch(onImportAction, formData).then(function (importResponse) {
          _this.importInProgress = false;
          if (importResponse) {
            _this.importResponseData = importResponse;
            _this.importInvalidData = importResponse.results.filter(function (res) {
              return res.status == ImportStatusType.Failed || res.status == ImportStatusType.Declined;
            });
            if (importResponse.succeeded > 0) {
              _this.$emit('on-success-callback');
            }
          }
          _this.removeFile();
        });
      }
    },
    downloadInvalidData: function downloadInvalidData() {
      var data = this.importInvalidData.map(function (cd, i) {
        delete cd.data.id;
        return cd.data;
      });
      excelParser().exportDataFromJSON(data, this.errorFileName, this.exportTypeExtension);
    },
    getCondensedList: function getCondensedList(condensedListSize) {
      return this.importInvalidData;
    }
  })
};