export default {
  name: 'TeamJobsCompletionInformationComputedMixin',
  computed: {
    teamJobNotes: {
      get: function get() {
        return this.$store.getters.teamJobNotes;
      },
      set: function set(newVal) {
        this.$store.state.teamJobsModule.teamJobNotes = newVal;
      }
    }
  }
};