import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import $ from 'jquery';
import { closeOverlay, clearDateTimePicker, initializeDateTimePlaceholders } from '@/utils/helpers';
import { formFieldPb, containerLeftPad, containerRightPad, vdprMaxWidth, vdprMinWidth } from '@/utils/uiconstants';
import businessesComputedMixin from './su-businesses-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import ConstantValues from '@/plugins/constantValues';
import { mapMutations } from "vuex";
import moment from 'moment';
import StringConstants from '@/plugins/stringConstants';
import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
import { defaultDateFormat } from '@/utils/constants';
export default {
  components: {
    Button: Button,
    HeaderPrimary: HeaderPrimary,
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      filterDateInput: {
        placeholder: StringConstants.selectDatePlaceholder
      },
      filterFormat: defaultDateFormat,
      filterSameDateFormat: {
        from: defaultDateFormat,
        to: defaultDateFormat
      }
    };
  },
  mixins: [businessesComputedMixin],
  computed: {
    isDisabledBtn: function isDisabledBtn() {
      return false;
    }
  },
  mounted: function mounted() {
    this.$store.dispatch('getGlobalCountriesList');
    initializeDateTimePlaceholders();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearBusinessesFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    closeBusinessesFilterForm: function closeBusinessesFilterForm() {
      closeOverlay("businessesFilterFormOverlay");
    },
    appplyFilter: function appplyFilter() {
      var _this = this;
      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      this.businesses = [];
      this.clearBusinessesFilterOptions();
      this.resetPaging();
      this.$store.dispatch('setBusinessesListFilter', filter).then(function () {
        _this.$store.dispatch('getBusinessesList');
      });
      this.closeBusinessesFilterForm();
    },
    setDateRangeFilterForBusinesses: function setDateRangeFilterForBusinesses(start, end) {
      $('.vdpr-datepicker input').first().css({
        width: vdprMaxWidth
      });
      this.businessesStartDate = moment(start).utc().format();
      this.businessesEndDate = moment(end).utc().format();
    },
    clearDateRange: function clearDateRange() {
      $('.vdpr-datepicker input[type=text]').first().val('');
      $('.vdpr-datepicker input').first().css({
        width: vdprMinWidth
      });
      clearDateTimePicker(this.$refs.datePicker.$el);
      this.businessesStartDate = this.businessesEndDate = null;
    }
  })
};