import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import { closeOverlay } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import teamMembersComputedMixin from '@/components/team-members/team-members-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import ConstantValues from '@/plugins/constantValues';
import { FeatureTypeLabels } from '@/enum/featureType';
import { ManagerFeatureLabels, TeamLeadFeatureLabels, OperatorFeatureLabels, UserRoleType } from '@/enum/userRoleType';
export default {
  computed: {
    Features: function Features() {
      return FeatureTypeLabels;
    },
    ManagerFeatures: function ManagerFeatures() {
      return ManagerFeatureLabels;
    },
    TeamLeadFeatures: function TeamLeadFeatures() {
      return TeamLeadFeatureLabels;
    },
    OperatorFeatures: function OperatorFeatures() {
      return OperatorFeatureLabels;
    },
    UserRole: function UserRole() {
      return UserRoleType;
    }
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad
    };
  },
  components: {
    HeaderPrimary: HeaderPrimary,
    Button: Button
  },
  mixins: [teamMembersComputedMixin],
  methods: _objectSpread(_objectSpread({}, mapMutations({
    resetPaging: 'resetPaging',
    setClearFilterOptions: 'setClearFilterOptions',
    setClearTeamMembersList: 'setClearTeamMembersList',
    resetSnackbarDetails: "clearSnackbarDetails"
  })), {}, {
    updateTeamMemberRole: function updateTeamMemberRole() {
      var _this = this;
      closeOverlay("editTeamMemberFormOverlay");
      var updateRoleObj = {
        oldRole: this.teamMembeOldRole,
        newRole: this.teamMemberDetails.roles.type
      };
      this.$store.dispatch('updateTeamMemberRole', [this.teamMemberDetails.id, updateRoleObj]).then(function (res) {
        _this.resetSnackbarDetails();
        _this.setClearFilterOptions();
        _this.resetPaging();
        _this.setClearTeamMembersList();
        _this.$store.dispatch('fnSetTeamMembersFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function (rs) {
          _this.$store.dispatch('getTeamMembers');
        });
      });
    },
    closeMemberEditForm: function closeMemberEditForm() {
      closeOverlay("editTeamMemberFormOverlay");
    }
  })
};