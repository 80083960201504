import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.string.trim.js";
import { mapMutations } from 'vuex';
import permissionsMixin from '@/mixins/permissions-mixin';
import InvoicesExternalComputedMixin from '@/components/invoices-external/invoices-external-computed';
import ConstantValues from '@/plugins/constantValues';
export default {
  name: "CustomerListVue",
  mixins: [permissionsMixin, InvoicesExternalComputedMixin],
  data: function data() {
    return {
      contactSearchTempText: ''
    };
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    getExternalContactsList: function getExternalContactsList() {
      var _this = this;
      this.clearFilterOptions();
      this.resetPaging();
      this.contacts = [];
      this.filtersApplied = true;
      this.$store.dispatch('setContactsSearchFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function (res) {
        _this.loadedContacts = 0;
        _this.$store.dispatch('getExternalInvoicingServiceContacts', _this.integrationType).then(function () {
          if (_this.contactSearchText == '') {
            _this.clearFilterOptions();
            _this.filtersApplied = false;
          }
        });
      });
    },
    applySearchContactsFilter: function applySearchContactsFilter() {
      this.resetContactsPaging();
      if (this.contactSearchText.trim()) {
        this.contactSearchTempText = this.contactSearchText;
        this.getExternalContactsList();
      } else {
        this.resetExternalContactsList();
      }
    },
    resetExternalContactsList: function resetExternalContactsList() {
      this.resetContactsPaging();
      this.getExternalContactsList();
    },
    resetContactsPaging: function resetContactsPaging() {
      this.clearFilterOptions();
      this.resetPaging();
      this.tempIntegratedContacts = [];
      this.contacts = [];
    }
  })
};