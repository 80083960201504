import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
import moment from 'moment';
var computedProperties = {
  'timeSheetsModule': ['timesheetMembersSearchText', 'timesheetsMembersList', 'addTimesheetLoader', 'timesheetsTypeList', 'timesheetDetails', 'editTimesheetLoader', 'timesheetsList', 'timesheetsStatistics', 'timesheetsFilterTypeList', 'timesheetListFilterType', 'timesheetsFilterLoader', 'timesheetsLoader', 'timesheetsSearchText', 'timesheetsFilterStartDate', 'timesheetsFilterEndDate', 'timesheetDateFilterApplied', 'timesheetsSelectedMemberId', 'timesheetDataListOffset', 'timesheetDataListTotalCount', 'timesheetDataListLoadedCount', 'timesheetDataListForExport'],
  'root': ['hoursList', 'minutesList', 'searchNotify', 'loginUserRole', 'noData', 'filtersApplied', 'loginUserName', 'listCount', 'routedFromTimesheets', 'totalRowCount', 'snackbarShow', 'allItemsDeleted', 'selectedItems', 'isRoutedFromReferencePage']
};
export default {
  name: 'TimesheetsComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    timeStart: {
      get: function get() {
        return this.$store.getters.memberDefaultWorkStartTime;
      },
      set: function set(newVal) {
        this.$store.state.timeSheetsModule.memberDefaultWorkStartTime = newVal;
      }
    },
    timeEnd: {
      get: function get() {
        return this.$store.getters.memberDefaultWorkEndTime;
      },
      set: function set(newVal) {
        this.$store.state.timeSheetsModule.memberDefaultWorkEndTime = newVal;
      }
    },
    getSenderId: function getSenderId() {
      return localStorage.getItem(ConstantValues.ownerId);
    }
  }),
  methods: {
    getTimesheetEndDate: function getTimesheetEndDate(timesheetStartDate, startTime, endTime) {
      var timeSheetEndDate = timesheetStartDate;
      if (new Date("0001-01-01T".concat(startTime, ":00")) > new Date("0001-01-01T".concat(endTime, ":00"))) timeSheetEndDate = moment(timesheetStartDate).add(1, 'day').format('YYYY-MM-DD');
      return timeSheetEndDate;
    }
  }
};