import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "b-btm py-5 px-9" },
      [
        _c(
          VRow,
          { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(VCol, { staticClass: "oy-tl", attrs: { cols: "8" } }, [
              _vm._v(_vm._s(_vm.$stringConstants("update")) + " "),
              _c("span", { staticClass: "text-capitalize" }, [
                _vm._v(_vm._s(_vm.intgrationType)),
              ]),
              _vm._v(" " + _vm._s(_vm.$stringConstants("customer"))),
            ]),
            _c(
              VCol,
              { staticClass: "text-right", attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    staticClass: "oy-cl cursor-pointer",
                    attrs: { small: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$emit("proceed")
                      },
                    },
                  },
                  [_vm._v(" mdi-close-thick ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "pt-7 hide-scroll",
        staticStyle: { height: "calc(100vh - 180px)" },
      },
      [
        _c(
          VRow,
          { staticClass: "px-9" },
          [
            _c(
              VCol,
              { attrs: { cols: "12 pa-0" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(" " + _vm._s(_vm.$stringConstants("name")) + " * "),
                ]),
                _c(VTextField, {
                  class: _vm.formFieldPb,
                  attrs: {
                    placeholder: _vm.$stringConstants("namePlaceholder"),
                    outlined: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.farmerDetails.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.farmerDetails, "name", $$v)
                    },
                    expression: "farmerDetails.name",
                  },
                }),
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("companyName")) + " * "
                  ),
                ]),
                _c(VTextField, {
                  class: _vm.formFieldPb,
                  attrs: {
                    placeholder: _vm.$stringConstants("companyNamePlaceholder"),
                    outlined: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.farmerDetails.companyName,
                    callback: function ($$v) {
                      _vm.$set(_vm.farmerDetails, "companyName", $$v)
                    },
                    expression: "farmerDetails.companyName",
                  },
                }),
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("emailAddress")) + " "
                  ),
                ]),
                _c(VTextField, {
                  class: _vm.formFieldPb,
                  attrs: {
                    placeholder: _vm.$stringConstants("emailPlaceholder"),
                    outlined: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.farmerDetails.emailAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.farmerDetails, "emailAddress", $$v)
                    },
                    expression: "farmerDetails.emailAddress",
                  },
                }),
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("phoneNumber")) + " "
                  ),
                ]),
                _c(VTextField, {
                  class: _vm.formFieldPb,
                  attrs: {
                    placeholder: _vm.$stringConstants("phoneNumberPlaceholder"),
                    outlined: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.farmerDetails.phoneNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.farmerDetails, "phoneNumber", $$v)
                    },
                    expression: "farmerDetails.phoneNumber",
                  },
                }),
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("addressesLine1")) + " * "
                  ),
                ]),
                _c(VTextField, {
                  class: _vm.formFieldPb,
                  attrs: {
                    outlined: "",
                    placeholder: _vm.$stringConstants(
                      "addressLineOnePlaceholder"
                    ),
                    rows: "2",
                    "row-height": "15",
                    dense: "",
                    required: "",
                  },
                  model: {
                    value: _vm.farmerDetails.address.addressLine1,
                    callback: function ($$v) {
                      _vm.$set(_vm.farmerDetails.address, "addressLine1", $$v)
                    },
                    expression: "farmerDetails.address.addressLine1",
                  },
                }),
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("addressesLine2")) + " "
                  ),
                ]),
                _c(VTextField, {
                  class: _vm.formFieldPb,
                  attrs: {
                    rows: "2",
                    placeholder: _vm.$stringConstants(
                      "addressLineTwoPlaceholder"
                    ),
                    "row-height": "15",
                    required: "",
                    outlined: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.farmerDetails.address.addressLine2,
                    callback: function ($$v) {
                      _vm.$set(_vm.farmerDetails.address, "addressLine2", $$v)
                    },
                    expression: "farmerDetails.address.addressLine2",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          VRow,
          { staticClass: "px-9" },
          [
            _c(
              VCol,
              { staticClass: "py-0 pr-2 pl-0", attrs: { cols: "6" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("townAndCity")) + " "
                  ),
                ]),
                _c(VTextField, {
                  class: _vm.formFieldPb,
                  attrs: {
                    placeholder: _vm.$stringConstants("cityPlaceholder"),
                    required: "",
                    outlined: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.farmerDetails.address.city,
                    callback: function ($$v) {
                      _vm.$set(_vm.farmerDetails.address, "city", $$v)
                    },
                    expression: "farmerDetails.address.city",
                  },
                }),
              ],
              1
            ),
            _c(
              VCol,
              { staticClass: "py-0 pl-2 pr-0", attrs: { cols: "6" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(" " + _vm._s(_vm.$stringConstants("country")) + " "),
                ]),
                _c(VTextField, {
                  class: _vm.formFieldPb,
                  attrs: {
                    placeholder: _vm.$stringConstants("countryPlaceholder"),
                    required: "",
                    outlined: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.farmerDetails.address.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.farmerDetails.address, "country", $$v)
                    },
                    expression: "farmerDetails.address.country",
                  },
                }),
              ],
              1
            ),
            _c(
              VCol,
              { staticClass: "py-0 pr-2 pl-0", attrs: { cols: "6" } },
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$stringConstants("postalCode")) + " "
                  ),
                ]),
                _c(VTextField, {
                  class: _vm.formFieldPb,
                  attrs: {
                    placeholder: _vm.$stringConstants("postalCodePlaceholder"),
                    required: "",
                    outlined: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.farmerDetails.address.postalCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.farmerDetails.address, "postalCode", $$v)
                    },
                    expression: "farmerDetails.address.postalCode",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btns-bottom-box px-9 py-6 " },
      [
        _c("Button", {
          attrs: {
            variant: "primary",
            color: "primary",
            disabled: !_vm.isFieldsValid,
            loading: _vm.updateCustomerLoader,
            textValue: _vm.$stringConstants("update"),
            rightIcon: "mdi-arrow-right",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.editfarmerDetails(_vm.intgrationType)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }