import "core-js/modules/es.array.concat.js";
import { TaxType } from "@/enum/taxType";
// api endpoints
export var apiAccount = 'account';
export var apiUsers = 'users';
export var apiLogin = 'account/login';
export var apiForgetPassword = '/account/reset_password';
export var apiRefreshToken = "".concat(apiAccount, "/access/renew");
export var apiVehicles = '/vehicles';
export var apiProducts = '/products';
export var apiProductUnits = "".concat(apiProducts, "/units");
export var apiFields = '/fields';
export var apiImplements = '/implements';
export var apiPermissions = "".concat(apiAccount, "/permissions");
export var apiTeamMembers = 'members';
export var apiFarmers = '/farmers';
export var apiContractors = '/contractors';
export var apiOperationTypes = '/operation/types';
export var apiTaxes = '/taxes';
export var apiTaxesVat = "".concat(apiTaxes, "/").concat(TaxType.vat);
export var apiInvoiceConfigutation = "".concat(apiUsers, "/owner");
export var apiDefaultTaxes = "".concat(apiTaxes, "/default-names");
export var apiDefaultTaxesName = '/default-name';
export var apiDefaultTaxesRate = '/default-rates';
export var apiTaxesVatDescription = "".concat(apiTaxes, "/").concat(TaxType.vat, "/description");
export var apiOperations = 'operations';
export var apiTimeSheets = '/timesheets';
export var apiJobs = '/jobs';
export var apiJobForceFinish = '/force-finish';
export var apiJobPartComplete = '/part-complete';
export var apiJobComplete = '/complete';
export var apiWork = '/work';
export var apiJobAccept = '/accept';
export var apiJobDecline = '/decline';
export var apiJobCancel = '/cancel';
export var apiCards = '/cards';
export var apiSubscriptions = '/subscription';
export var apiSubscriptionsPricing = '/subscription/pricing';
export var apiSubscriptionsCancel = '/subscription/cancel';
export var apiSetDefaultCard = '/set_default';
export var apiInvoices = '/invoices';
export var apiInvoicesApprove = '/approve-for-invoicing';
export var apiInvoicesDraft = "".concat(apiInvoices, "/draft");
export var apiInvoicesOpen = '/open';
export var apiInvoicesFinalize = '/finalize';
export var apiInvoicesItems = '/items';
export var apiJobLocations = '/locations-trail';
export var apiQuickbooks = '/quickbooks';
export var apiSage = '/sage';
export var apiQuickbooksInvoice = '/quickbooks/invoices';
export var apiSageInvoice = '/sage/invoices';
export var apiXeroInvoice = '/xero/invoices';
export var apiContact = '/contact';
export var apiInvitation = '/invitations';
export var apiInvoicesPaid = '/paid';
export var apiImport = '/import';
export var apiOwner = '/users/owner';
export var apiPromoCode = "".concat(apiSubscriptions, "/promotion-codes?promoCode=");
export var apiBusinessProfile = "".concat(apiUsers, "/business");
export var apiAccountSettings = "".concat(apiUsers, "/settings");
export var apiUsersBusinessConfig = "".concat(apiBusinessProfile, "/config");
export var apiAccountAddress = "".concat(apiBusinessProfile, "/addresses");
export var apiAddresses = '/business/addresses';
export var apiUnlinkUsers = "".concat(apiUsers, "/unlinks");
export var apiGlobalCountries = '/globalization/countries';
export var apiCities = '/cities';
export var apiGlobalTimeZones = '/globalization/timezones';
export var apiGlobalCurrency = '/globalization/currencies';
export var apiColourCode = '/colourCode';
export var apiAdmin = '/admin';
export var apiAdminRecentOverview = "".concat(apiAdmin, "/recent-overview");
export var apiAdminOverallOverview = "".concat(apiAdmin, "/overview");
export var apiAdminUsers = "".concat(apiAdmin, "/users");
export var apiAdminBusinesses = "".concat(apiAdmin, "/businesses");
export var apiAdminBusinessesChangeToContractor = function apiAdminBusinessesChangeToContractor(id) {
  return "".concat(apiAdmin, "/businesses/").concat(id, "/change-to-contractor");
};
export var apiJobsSummary = '/jobs_summary';
export var apiInvoiceUrl = '/';
export var apiContactsImportUrl = '/import-from-integration';
export var apiCustomersUpdateUrl = '/update-from-integration';
export var apiImportFromFile = '/import-from-file';
export var apiCustomersImport = "".concat(apiFarmers).concat(apiImportFromFile);
export var apiCustomersUpdateFromIntegration = "".concat(apiFarmers).concat(apiCustomersUpdateUrl);
export var apiUnapproveForInvoicing = '/unapprove-for-invoicing';
export var apiApproveForInvoicing = '/approve-for-invoicing';
export var apiMarkInvoiced = '/mark-as-invoiced';
export var apiJobsApproveInvoicing = "".concat(apiJobs).concat(apiApproveForInvoicing);
export var apiJobsUnapproveInvoicing = "".concat(apiJobs).concat(apiUnapproveForInvoicing);
export var apiJobsMarkAsInvoiced = "".concat(apiJobs).concat(apiMarkInvoiced);
export var apiJobsExportableColumns = "".concat(apiJobs, "/exportable-columns");
export var apiJobsExport = "".concat(apiJobs, "/export");
// router paths
export var routeRoot = '/';
export var routeAdd = '/add';
export var routeEdit = '/edit';
export var routeDetails = '/details';
export var routeOnboard = '/onboard';
export var routeLogin = '/login';
export var routeForgetPassword = '/forgetpassword';
export var routeResetPassword = '/resetpassword';
export var routeSignUp = '/signup';
export var routeResendEmailVerification = "/resend_email_verification";
export var routeJobs = '/jobs';
export var routeTeamJobs = '/jobs';
export var routeJobsAdd = "".concat(routeTeamJobs).concat(routeAdd);
export var routeTeamJobsAdd = "".concat(routeTeamJobs).concat(routeAdd);
export var routeTeamJobsDetails = "".concat(routeTeamJobs).concat(routeDetails, "/");
export var routeTeamJobDuplicate = "".concat(routeTeamJobs, "/duplicate/");
export var routeFields = '/fields';
export var routeFieldsAdd = "".concat(routeFields).concat(routeAdd);
export var routeFieldsEdit = "".concat(routeFields).concat(routeEdit);
export var routeOperations = '/operations';
export var routeTimesheets = '/timesheets';
export var routeTeamMembers = '/members';
export var routeContractors = '/contractors';
export var routeCustomers = '/customers';
export var routeVehicles = '/vehicles';
export var routeImplements = '/implements';
export var routeProducts = '/products';
export var routeJobDetails = '/jobdetails';
export var routeSubscriptions = '/subscriptions';
export var routeInvoices = '/invoices';
export var routeInvoicesDetails = "".concat(routeInvoices, "/");
export var routeInvoicesAdd = "".concat(routeInvoices).concat(routeAdd);
export var routeInvoicesAddExternal = "".concat(routeInvoices).concat(routeAdd, "/external");
export var routeInvoicesDraft = "".concat(routeInvoices, "/draft");
export var routeIntegrations = '/integrations';
export var routeQuickBooks = "".concat(routeIntegrations, "/quickbooks");
export var routeXero = "".concat(routeIntegrations, "/xero");
export var routeSage = "".concat(routeIntegrations, "/sage");
export var routeContact = '/contact';
export var routeContactAdd = "".concat(routeContact).concat(routeAdd);
export var routeCustomersUpdate = "".concat(routeCustomers).concat(routeEdit);
export var routeCustomersDetails = "".concat(routeCustomers).concat(routeDetails);
export var routeAccount = '/account';
export var routeSeed = '/seed';
export var routeJobsReport = "".concat(routeJobs, "/:id/report");
export var routeOverview = '/overview';
export var routeUsers = '/users';
export var routeBusinesses = '/businesses';
export var routeBusinessesDetails = "".concat(routeBusinesses, "/:id");
// routes for terms of service and privacy policy
export var termsOfServiceURL = 'https://cdn.ag-drive.com/03062021/terms_and_use.pdf';
export var privacyPolicyURL = 'https://cdn.ag-drive.com/03062021/privacy_policy.pdf';