import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { TeamJobMetaData } from '@/data/models/TeamJobMetaData';
export default {
  name: "TeamJobsMetadata",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    Button: Button
  },
  mounted: function mounted() {
    this.$store.dispatch('getLoadInfoUnits');
  },
  data: function data() {
    return {
      showAddMetaDataTeamJob: false,
      showBtnAddMetaDataTeamJob: true,
      metaDataTitleTeamJob: null,
      metaDataUnitForTeamJob: null
    };
  },
  methods: {
    addNewMetaDataInList: function addNewMetaDataInList() {
      if (this.metaDataTitleTeamJob != null && this.metaDataTitleTeamJob.length != 0 && this.metaDataUnitForTeamJob != null && this.metaDataUnitForTeamJob.length != 0) {
        var obj = {
          id: null,
          unit: this.metaDataUnitForTeamJob,
          title: this.metaDataTitleTeamJob,
          value: null
        };
        this.teamJobMetaDataList.push(new TeamJobMetaData().getTeamJobMetaData(obj));
        this.showAddMetaDataTeamJob = false;
        this.showBtnAddMetaDataTeamJob = true;
        this.enableTeamJobDiscard();
      }
      this.metaDataTitleTeamJob = null;
      this.metaDataUnitForTeamJob = null;
    },
    showAddMetaDataComponent: function showAddMetaDataComponent() {
      if (this.teamJobViewPreference.metaData.isEditable) {
        this.showAddMetaDataTeamJob = true;
        this.showBtnAddMetaDataTeamJob = false;
      }
    },
    cancelAddNewMetaData: function cancelAddNewMetaData() {
      this.showAddMetaDataTeamJob = false;
      this.showBtnAddMetaDataTeamJob = true;
      this.metaDataTitleTeamJob = null;
      this.metaDataUnitForTeamJob = null;
    },
    removeSelectedMetaDataFromList: function removeSelectedMetaDataFromList(metaDataIndex, metaList) {
      if (metaDataIndex > -1) {
        metaList.splice(metaDataIndex, 1);
      }
      this.enableTeamJobDiscard();
    },
    onChangeInMetaData: function onChangeInMetaData() {
      this.enableTeamJobDiscard();
    }
  }
};