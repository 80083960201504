import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column invoices-external-add" },
    [
      _c(
        VStepper,
        {
          staticClass: "flex-grow-1",
          model: {
            value: _vm.invoiceSelectedTabExternal,
            callback: function ($$v) {
              _vm.invoiceSelectedTabExternal = $$v
            },
            expression: "invoiceSelectedTabExternal",
          },
        },
        [
          _c(
            VStepperHeader,
            { class: { noStepper: true } },
            [
              _vm._l(_vm.steps, function (n) {
                return [
                  _c(
                    VStepperStep,
                    {
                      key: n + "-step",
                      attrs: {
                        color: "#182879",
                        complete: _vm.invoiceSelectedTabExternal > n,
                        step: n,
                        editable: true,
                      },
                    },
                    [
                      n == 1
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$stringConstants("jobsText"))),
                          ])
                        : _vm._e(),
                      n == 2
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$stringConstants("invoice"))),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  n !== _vm.steps ? _c(VDivider, { key: n }) : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            VStepperItems,
            {
              ref: "stepperRef",
              staticClass: "h-percent-100 stepper-scroll",
              staticStyle: { "overflow-y": "scroll" },
            },
            [
              _c(
                VStepperContent,
                {
                  staticClass: "px-0 pb-0 h-percent-100",
                  attrs: { step: "1" },
                },
                [
                  _c("div", { staticClass: "inv-title pl-9 pt-3 pb-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$stringConstants("invoiceAddJobsSelection")
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "pl-9 pt-3 pb-6" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$stringConstants(
                            "invoiceExternalSelectCustomerAndJob"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _vm.stepperContentResized
                    ? _c("JobsListForInvoiceExternal")
                    : _vm._e(),
                ],
                1
              ),
              _c(
                VStepperContent,
                { staticClass: "px-0 pb-0", attrs: { step: "2" } },
                [
                  _c(
                    VRow,
                    [
                      _c(VCol, { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "inv-title pl-6" }, [
                          _vm._v(
                            _vm._s(_vm.$stringConstants("invoiceDetailsText"))
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm.stepperContentResized
                    ? _c("InvoiceDraftPreviewExternal")
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fix-bar-inv-fixed btm-btn-bg py-4 flex-grow-0" },
        _vm._l(_vm.steps, function (n, idx) {
          return _c("div", { key: idx }, [
            n == 1 && n == _vm.invoiceSelectedTabExternal
              ? _c(
                  "div",
                  { staticClass: "px-6 py-0 btn-lt-sht text-right" },
                  [
                    _c("HoverTextButton", {
                      staticClass: "f-nn btn-wt-bl-text ml-2",
                      attrs: { width: 180, height: 36, textValue: "Cancel" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancelDraftInvoice()
                        },
                      },
                    }),
                    n != 1
                      ? _c("ButtonActionPrimary", {
                          staticClass: "f-nn ml-2",
                          attrs: {
                            elevation: "0",
                            width: 180,
                            height: 36,
                            textValue: "Previous",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.previousStep(n)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("ButtonActionPrimary", {
                      staticClass: "f-nn ml-2",
                      attrs: {
                        elevation: "0",
                        width: 180,
                        height: 36,
                        textValue: _vm.$stringConstants("previewInvoiceText"),
                        loading: _vm.invoiceLoaderExternal,
                        disabled: _vm.isNextDisabled(n),
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.createExternalInvoiceDraftPreview()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.invoiceSelectedTabExternal == 2 && n == 2
              ? _c(
                  "div",
                  { staticClass: "px-6 py-0 btn-lt-sht text-right" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ib",
                        staticStyle: { position: "absolute", left: "33px" },
                      },
                      [
                        _vm.linkedContactToExternalService &&
                        !_vm.invoiceReportUrlGenerationInProgress
                          ? _c("ButtonActionPrimary", {
                              staticClass: "f-nn mr-2",
                              attrs: {
                                elevation: "0",
                                textValue: _vm.$stringConstants(
                                  "invoiceExternalGenerateAndAttachJobReports"
                                ),
                                height: 40,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.promtGenerateInvoiceReport($event)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.invoiceReportUrlGenerationInProgress
                          ? _c("ButtonActionPrimary", {
                              staticClass: "f-nn mr-2",
                              attrs: {
                                elevation: "0",
                                disabled:
                                  _vm.invoiceReportUrlGenerationInProgress,
                                textValue: _vm.$stringConstants(
                                  "invoiceExternalGeneratingReports"
                                ),
                                loading:
                                  _vm.invoiceReportUrlGenerationInProgress,
                                height: 40,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("ButtonActionPrimary", {
                      staticClass: "f-nn mr-2",
                      attrs: {
                        disabled:
                          _vm.anyLineItemInvalid ||
                          _vm.invoiceLoaderExternal ||
                          (!_vm.linkedContactToExternalService &&
                            !_vm.invoiceReportUrlGenerationInProgress) ||
                          _vm.invoiceGenerationInProgressExternal,
                        width: 140,
                        elevation: "0",
                        loading:
                          _vm.invoiceLoaderExternal ||
                          _vm.invoiceGenerationInProgressExternal,
                        textValue: "Create",
                        height: 40,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.createExternalInvoiceFinalize()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
        0
      ),
      _c("ReportDialog", {
        attrs: { item: _vm.regenerateJobReport, "max-width": "460" },
        on: {
          cancel: function ($event) {
            return _vm.cancelReportRegeneration()
          },
          proceed: function ($event) {
            return _vm.proceedReportRegeneration()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "invoiceExternalRegenerateJobReports"
                      )
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "invoiceExternalRegenerateJobReportsMessage"
                      )
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }