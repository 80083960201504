import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-8" }, [
    _c(
      "div",
      [
        _c(
          VRow,
          [
            _c(
              VCol,
              { staticClass: " pt-0 pl-0 pr-0 pb-10", attrs: { cols: "12" } },
              [
                _c("span", { staticClass: "tj-cp-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("teamJobFieldsTitle")) +
                      " "
                  ),
                ]),
                _vm.teamJobFieldsLoaded != _vm.teamJobFieldsTotal
                  ? _c(
                      "span",
                      { staticClass: "text-right pt-3 fn-smm fl-rt" },
                      [
                        _c(VProgressCircular, {
                          staticStyle: {
                            transform: "translateY(-2px) translateX(-2px)",
                          },
                          attrs: {
                            indeterminate: "",
                            width: "2",
                            size: "18",
                            color: "#b3b3b3",
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants("teamJobFieldsLoadingText")
                              ) +
                              " " +
                              _vm._s(_vm.teamJobFieldsLoaded) +
                              " / " +
                              _vm._s(_vm.teamJobFieldsTotal)
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "tj-cp-subtitle" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("teamJobFieldsSubTitle")) +
                      " "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _vm.teamJobViewPreference.fields.isEditable
          ? _c(
              VCol,
              {
                staticClass: " pt-0 pl-0 pr-0 pb-0 position-relative",
                attrs: { cols: "12" },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      "z-index": "1",
                      width: "80%",
                    },
                  },
                  [
                    _c(
                      VRow,
                      [
                        _vm.isfieldOwnerFilter
                          ? _c(
                              VCol,
                              {
                                staticClass: "pr-0 pt-4",
                                attrs: { cols: "5" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "pb-2" },
                                  [
                                    _c(VAutocomplete, {
                                      attrs: {
                                        items: _vm.teamJobUsersList,
                                        "item-text": "businessName",
                                        height: "42",
                                        "item-value": "id",
                                        placeholder: _vm.$stringConstants(
                                          "selectCustomerPlaceholder"
                                        ),
                                        "search-input":
                                          _vm.teamJobFieldsCustomerSearchText,
                                        "menu-props": {
                                          contentClass: "customMenu",
                                          minWidth: "520",
                                        },
                                        disabled:
                                          _vm.teamJobFieldsLoaded !=
                                          _vm.teamJobFieldsTotal,
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        flat: "",
                                        attach: "",
                                        "allow-overflow": "",
                                        solo: "",
                                      },
                                      on: {
                                        "update:searchInput": function (
                                          $event
                                        ) {
                                          _vm.teamJobFieldsCustomerSearchText =
                                            $event
                                        },
                                        "update:search-input": function (
                                          $event
                                        ) {
                                          _vm.teamJobFieldsCustomerSearchText =
                                            $event
                                        },
                                        change:
                                          _vm.filterTeamJobFieldsByCustomer,
                                        "click:clear":
                                          _vm.searchCustomerForTeamJobField,
                                        focus: function ($event) {
                                          return _vm.refeatchUserListForFields(
                                            $event
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        input: function ($event) {
                                          return _vm.applySearchFieldUserTimeout(
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      padding: "5px 0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "ib" },
                                                      [
                                                        _c("OwnerAvatar", {
                                                          staticClass: "mr-2",
                                                          staticStyle: {
                                                            "font-size": "13px",
                                                          },
                                                          attrs: {
                                                            width: "28px",
                                                            height: "28px",
                                                            isCircular: true,
                                                            isThemeColor: true,
                                                            isDark: false,
                                                            initials:
                                                              data.item
                                                                .businessName,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ib acmp-txwrp",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .businessName
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      padding: "5px 0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ib acmp-init",
                                                      },
                                                      [
                                                        _c("OwnerAvatar", {
                                                          staticClass: "mr-2",
                                                          staticStyle: {
                                                            "font-size": "13px",
                                                          },
                                                          attrs: {
                                                            width: "28px",
                                                            height: "28px",
                                                            isCircular: true,
                                                            isThemeColor: false,
                                                            isDark: false,
                                                            initials:
                                                              item.businessName,
                                                            backgroundColor:
                                                              item.colorCode,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "ib",
                                                        attrs: {
                                                          title:
                                                            item.businessName,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getShortBusinessName(
                                                                item.businessName
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "append-item",
                                            fn: function () {
                                              return [
                                                _c("LoadMoreDropdown", {
                                                  staticClass: "pb-3",
                                                  attrs: {
                                                    moreData: _vm.hasMoreData,
                                                    loadInProgress:
                                                      _vm.teamJobUsersListLoader,
                                                    loadingListLength:
                                                      _vm.teamJobUsersList
                                                        .length,
                                                  },
                                                  on: {
                                                    appendMore:
                                                      _vm.appendMoreCustomersForFields,
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        946736569
                                      ),
                                      model: {
                                        value: _vm.teamJobFieldCustomerId,
                                        callback: function ($$v) {
                                          _vm.teamJobFieldCustomerId = $$v
                                        },
                                        expression: "teamJobFieldCustomerId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          VCol,
                          { staticClass: "pt-4", attrs: { cols: "4" } },
                          [
                            _c(VAutocomplete, {
                              attrs: {
                                items: _vm.teamJobFieldsList,
                                "item-text": "title",
                                height: "42",
                                multiple: "",
                                outlined: "",
                                "persistent-placeholder": "",
                                label: _vm.$stringConstants(
                                  "selectFieldPlaceholder"
                                ),
                                placeholder: _vm.$stringConstants(
                                  "selectFieldPlaceholder"
                                ),
                                "menu-props": {
                                  contentClass: "menuMulti",
                                },
                                dense: "",
                                disabled:
                                  _vm.teamJobFieldsLoaded !=
                                    _vm.teamJobFieldsTotal ||
                                  !_vm.teamJobViewPreference.fields.isEditable,
                                solo: "",
                                flat: "",
                                attach: "",
                                "return-object": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function (ref) {
                                      var item = ref.item
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          VListItem,
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "acm-fld",
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.checkedFieldsForTeamJob(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              VListItemAction,
                                              [
                                                _c(
                                                  VCheckbox,
                                                  _vm._b(
                                                    {},
                                                    "v-checkbox",
                                                    attrs,
                                                    false
                                                  )
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              VListItemContent,
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.title) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "sel-fld-chip" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(data ? "" : "") + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1925109206
                              ),
                              model: {
                                value: _vm.teamJobSelectedFieldsForList,
                                callback: function ($$v) {
                                  _vm.teamJobSelectedFieldsForList = $$v
                                },
                                expression: "teamJobSelectedFieldsForList",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", {
                  staticStyle: { height: "520px" },
                  attrs: { id: "teamJobsFieldMap" },
                }),
              ]
            )
          : _vm._e(),
        _vm.teamJobSelectedFields.length != 0
          ? _c(
              VRow,
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.teamJobsLoader,
                    expression: "!teamJobsLoader",
                  },
                ],
              },
              [
                _c(
                  VCol,
                  { staticClass: "pa-0", attrs: { cols: "12" } },
                  [
                    _c(
                      "div",
                      { staticClass: "jb-dt-hd jb-operators-list-head bb-0" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$stringConstants("teamJobFieldsTitle"))
                          ),
                        ]),
                        _c("span", { staticClass: "ft-rt" }, [
                          _c("span", { staticStyle: { color: "#000" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$stringConstants("teamJobFieldsTotalArea")
                              ) + " "
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "fw-7",
                              staticStyle: { color: "#000" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getTotalFieldArea(
                                    _vm.teamJobSelectedFields
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._l(
                      _vm.teamJobSelectedFields,
                      function (selectedField, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "px-7 py-3",
                            staticStyle: {
                              border: "0.5px solid rgb(238, 238, 238)",
                            },
                          },
                          [
                            _c(
                              VRow,
                              [
                                _c(
                                  VCol,
                                  {
                                    staticClass:
                                      "px-0  section-sub-title inline fw-6",
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(selectedField.title) +
                                        " (" +
                                        _vm._s(
                                          _vm.getTotalFieldArea([selectedField])
                                        ) +
                                        ")"
                                    ),
                                  ]
                                ),
                                _vm.teamJobViewPreference.fields.isEditable
                                  ? _c(
                                      VCol,
                                      {
                                        staticClass: "px-0 ib text-right",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            staticClass: "job-add-list-rm",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFromSelectedFieldsTeamJobs(
                                                  selectedField
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-close")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.teamJobSelectedFields.length == 0 &&
        !_vm.teamJobViewPreference.locations.isEditable
          ? _c(
              VRow,
              [
                _c(VCol, { staticClass: "pa-0", attrs: { cols: "12" } }, [
                  _c(
                    "div",
                    { staticClass: "jb-dt-hd jb-operators-list-head bb-0" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$stringConstants("teamJobFieldsTitle"))
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "px-7 py-6",
                      staticStyle: { border: "0.5px solid rgb(238, 238, 238)" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("teamJobZeroFieldText")
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.teamJobsLoader,
                expression: "teamJobsLoader",
              },
            ],
          },
          _vm._l(2, function (loadInfo, j) {
            return _c(
              "div",
              {
                key: j,
                staticClass: "px-7 py-3",
                staticStyle: {
                  border: "0.5px solid #eee",
                  "border-top": "hidden",
                },
              },
              [
                _c(VRow, { attrs: { col: "12" } }, [
                  _c("div", { staticClass: "width-perc-80" }, [
                    _c(
                      "div",
                      { staticClass: "section-sub-title pt-3 fw-6" },
                      [
                        _c(VSkeletonLoader, {
                          attrs: { width: "200", height: "30", type: "card" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "width-perc-20 pr-1" },
                    [
                      _c(VSkeletonLoader, {
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }