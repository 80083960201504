import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-10" }, [
    _c(
      "div",
      { staticClass: "lt-vw" },
      [
        _c(
          VRow,
          { staticClass: "px-9", attrs: { "no-gutters": "", cols: "12" } },
          [
            _c(VCol, { attrs: { cols: "8" } }, [
              _c("div", { staticClass: "con-ttl" }, [
                _vm._v(
                  _vm._s(_vm.$stringConstants("farmerConnectionRequestText")) +
                    " (" +
                    _vm._s(_vm.farmerConReqList.length) +
                    ")"
                ),
              ]),
            ]),
            _c(
              VCol,
              { attrs: { cols: "4" } },
              [
                _c(
                  VIcon,
                  {
                    staticClass: "float-right icon-size pl-3 cursor-pointer",
                    staticStyle: { left: "4px" },
                    on: { click: _vm.hideConReqList },
                  },
                  [_vm._v("mdi-close")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { background: "#f6f7f9" } },
      [
        _vm._l(_vm.farmerConReqList, function (conReq, index) {
          return _c(
            "div",
            { key: index, staticClass: "lt-vw" },
            [
              index < 3
                ? _c(
                    VRow,
                    {
                      staticClass: "py-4 px-9",
                      attrs: { "no-gutters": "", cols: "12" },
                    },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "8" } },
                        [
                          _c(
                            VRow,
                            { attrs: { "no-gutters": "", cols: "12" } },
                            [
                              _c(
                                "div",
                                { staticClass: "ib pr-3" },
                                [
                                  conReq.inviter.businessProfile.title != null
                                    ? _c("Avatar", {
                                        staticClass: "mt-1",
                                        attrs: {
                                          initials:
                                            conReq.inviter.businessProfile
                                              .title,
                                        },
                                      })
                                    : _c("AvatarDisabled", {
                                        staticClass: "mr-2",
                                      }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ib" }, [
                                _c("div", { staticClass: "rd-details" }, [
                                  _vm._v(
                                    _vm._s(
                                      conReq.inviter.businessProfile.title
                                    ) + " "
                                  ),
                                ]),
                                _c("div", { staticClass: "pt-1 rd-label" }, [
                                  _vm._v(_vm._s(conReq.inviter.email)),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(VCol, { attrs: { cols: "4" } }, [
                        _c(
                          "div",
                          { staticClass: "float-right" },
                          [
                            _c("Button", {
                              staticClass: "ib  btn-gr f-nn",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                outlineColor: "primary",
                                textValue: "Accept",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.confirmAcceptInvitation(
                                    conReq.code
                                  )
                                },
                              },
                            }),
                            _c(
                              VIcon,
                              {
                                staticClass:
                                  "btn icon-size pl-3 cursor-pointer",
                                staticStyle: { left: "4px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDeleteInvitation(
                                      conReq.code,
                                      "received"
                                    )
                                  },
                                },
                              },
                              [_vm._v("mdi-delete")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        _vm.farmerConReqList.length >= 4
          ? _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.openManageFarmerForm()
                  },
                },
              },
              [
                _c(
                  VRow,
                  {
                    staticClass: "py-2 px-9 fw-5",
                    staticStyle: { color: "#1d34ad", cursor: "pointer" },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.farmerConReqList.length - 3) +
                          " " +
                          _vm._s(_vm.$stringConstants("moreRequest"))
                      ),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }