import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { mapMutations } from 'vuex';
import router from '@/router/index';
import moment from 'moment';
import { routeJobs, apiJobs } from '@/utils/endpoints';
import userComputedMixin from '@/components/users/users-computed';
export default {
  computed: {
    inviteeCode: {
      get: function get() {
        return window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
      }
    }
  },
  mixins: [userComputedMixin],
  mounted: function mounted() {
    var _this = this;
    this.$store.state.dashboardTitle = 'Inviter profile';
    this.setClearUserProfile();
    this.$store.dispatch('getUserProfile').then(function () {
      _this.$store.dispatch('getInviteeProfile', _this.inviteeCode);
    });
    this.inviteeProfile.code = this.inviteeCode;
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    setClearUserProfile: 'setClearUserProfile'
  })), {}, {
    acceptInvite: function acceptInvite() {
      this.$store.dispatch('acceptUserInvite', this.inviteeProfile.code).then(function () {
        router.push(apiJobs);
      });
    },
    declineInvitation: function declineInvitation() {
      this.$store.dispatch('declineUserInvite', this.inviteeProfile.code).then(function (res) {
        if (res) {
          router.push(routeJobs);
        }
      });
    },
    getLocalDataTime: function getLocalDataTime(dateTime) {
      var localDateTime = moment.utc(dateTime).local().format("MMM D, YYYY h:mm A");
      return localDateTime;
    },
    getBusinessInfo: function getBusinessInfo(businessProfile) {
      return businessProfile && businessProfile.title ? businessProfile.title : '';
    },
    routeToJobs: function routeToJobs() {
      router.push(routeJobs);
    }
  })
};