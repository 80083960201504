import contactComputedMixin from "@/components/internal-contact/internal-contact-computed";
import SectionLeftHeadings from "@/components/shared/headers/section-left-headings.vue";
import ConstantValues from '@/plugins/constantValues';
export default {
  name: "ContactCompanyDetailsForm",
  mixins: [contactComputedMixin],
  props: ["isEdiablePrefix"],
  watch: {
    businessProfilePrefix: function businessProfilePrefix() {
      this.businessProfilePrefix = this.getValidatedString(this.businessProfilePrefix);
    }
  },
  components: {
    SectionLeftHeadings: SectionLeftHeadings
  },
  methods: {
    updatePrefix: function updatePrefix(event) {
      if (this.isEdiablePrefix) {
        if (event.currentTarget.value) {
          var shortenedBusinessName = this.getValidatedString(event.currentTarget.value).substring(0, ConstantValues.defaultBusinessProfilePrefixLength);
          if (shortenedBusinessName.length == ConstantValues.defaultBusinessProfilePrefixLength) {
            this.businessProfilePrefix = shortenedBusinessName;
          }
        }
      }
    }
  }
};