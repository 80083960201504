import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import MapDialogBox from '@/components/shared/dialogs/map-dialog.vue';
import { getConvertedAreaUnit } from '@/utils/helpers';
export default {
  name: 'FieldDetailsView',
  mixins: [contactComputedMixin],
  components: {
    SectionLeftHeadings: SectionLeftHeadings,
    MapDialogBox: MapDialogBox
  },
  data: function data() {
    return {
      mapDialog: false
    };
  },
  methods: {
    getAreaFormatted: function getAreaFormatted(fieldArea) {
      return getConvertedAreaUnit(fieldArea);
    },
    openContactFieldsOnMap: function openContactFieldsOnMap() {
      this.mapDialog = true;
      this.$store.dispatch('getAllContactFieldOnMap', this.contactFieldList);
    },
    closeMapDialogBox: function closeMapDialogBox() {
      this.mapDialog = false;
    }
  }
};