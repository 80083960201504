import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.trim.js";
import { formFieldPb, containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import { checkFieldsValidity, closeOverlay } from "@/utils/helpers";
import { mapMutations } from "vuex";
import MachinesComputedMixin from "@/components/machines/machines-computed";
import SubHeaderPrimary from "@/components/shared/headers/subheader-primary.vue";
import DescriptionPrimary from "@/components/shared/headers/description-primary.vue";
import { BaseButton as Button, ButtonCloseIcon } from "@/components/shared/buttons/index";
import { MachineType } from "@/enum/machineType";
export default {
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      checkListItemName: "",
      MachineType: MachineType
    };
  },
  components: {
    SubHeaderPrimary: SubHeaderPrimary,
    DescriptionPrimary: DescriptionPrimary,
    ButtonCloseIcon: ButtonCloseIcon,
    Button: Button
  },
  mixins: [MachinesComputedMixin],
  computed: {
    isFieldsValid: function isFieldsValid() {
      var validationProperties = [this.machineDetails.title, this.machineDetails.make, this.machineDetails.model];
      if (this.machineType == MachineType.Vehicle && checkFieldsValidity([].concat(validationProperties, [this.machineDetails.registration]))) {
        return false;
      } else if (this.machineType == MachineType.Implement && checkFieldsValidity([].concat(validationProperties))) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    resetPaging: "resetPaging",
    setClearFilterOptions: "setClearFilterOptions",
    setClearMachinesList: "setClearMachinesList",
    resetMachineDetails: "resetMachineDetails"
  })), {}, {
    negativeKeydown: function negativeKeydown(e) {
      if (!/[^-+]/.test(e.key)) {
        e.preventDefault();
      }
    },
    validateRegexRegistration: function validateRegexRegistration(event) {
      if (!/[a-zA-Z0-9\s]/.test(event.key)) {
        event.preventDefault();
      }
    },
    addNewCheckListItem: function addNewCheckListItem() {
      if (this.checkListItemName.length != 0) {
        this.machineDetails.healthSafetyList.push({
          title: this.checkListItemName
        });
        this.checkListItemName = "";
      }
      this.hideAddCheckListItem();
    },
    removeVehicleCheckListItem: function removeVehicleCheckListItem(checkListIndex) {
      if (checkListIndex > -1) {
        this.machineDetails.healthSafetyList.splice(checkListIndex, 1);
      }
    },
    showAddCheckListItem: function showAddCheckListItem() {
      this.checkListItemName = "";
      this.showAddmachineCheckListItemFields = true;
      this.showAddmachineCheckListItemBtn = false;
    },
    hideAddCheckListItem: function hideAddCheckListItem() {
      this.showAddmachineCheckListItemFields = false;
      this.showAddmachineCheckListItemBtn = true;
    },
    updateVehicleData: function updateVehicleData() {
      var vehicleDto = {
        id: this.machineDetails.id != null ? this.machineDetails.id.trim() : this.machineDetails.id,
        title: this.machineDetails.title.trim(),
        make: this.machineDetails.make.trim(),
        model: this.machineDetails.model.trim(),
        registration: this.machineDetails.registration != null ? this.machineDetails.registration.trim() : this.machineDetails.registration,
        width: this.machineDetails.width != null && this.machineDetails.width != "" ? this.machineDetails.width : 0,
        length: this.machineDetails.length != null && this.machineDetails.length != "" ? this.machineDetails.length : 0,
        weight: this.machineDetails.weight != null && this.machineDetails.weight != "" ? this.machineDetails.weight : 0,
        ownership: this.machineDetails.ownership,
        healthSafetyList: this.machineDetails.healthSafetyList,
        isHealthSafetyListMandatory: this.machineDetails.isHealthSafetyListMandatory
      };
      if (this.machineDetails.id == null) {
        this.$store.dispatch("addMachine", vehicleDto);
      } else {
        this.$store.dispatch("updateMachine", [vehicleDto, this.machineDetails.id]);
      }
    },
    closeEditVehiclesForm: function closeEditVehiclesForm() {
      this.resetMachineDetails();
      closeOverlay("editMachineFormOverlay");
    },
    clearCurrentChecklist: function clearCurrentChecklist() {
      this.checkListItemName = "";
      this.hideAddCheckListItem();
    }
  })
};