import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "py-8" }, [
      !_vm.minimal
        ? _c("div", { staticClass: "pb-10" }, [
            _c("div", { staticClass: "tj-cp-title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$stringConstants("teamJobOperatorsTitle")) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "tj-cp-subtitle" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$stringConstants("teamJobOperatorsSubTitle")) +
                  " "
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
        },
        [
          _c("div", { attrs: { id: "team-job-operators" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.teamJobViewPreference.operators.isDetailed,
                    expression: "!teamJobViewPreference.operators.isDetailed",
                  },
                ],
              },
              [
                _vm.teamJobOperators.length && !_vm.minimal
                  ? _c("div", [
                      _c("div", { staticClass: "jb-operators-list-head" }, [
                        _c("div", { staticClass: "ib width-perc-30 pr-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants("teamJobOperatorsTitle")
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$stringConstants("teamLead")) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "ib width-perc-25 pr-4" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$stringConstants("vehicle")) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "ib width-perc-25 pr-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$stringConstants("implement")) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "ib width-perc-10 pr-4" }),
                      ]),
                    ])
                  : _vm._e(),
                _vm.minimal
                  ? _c(
                      VExpansionPanels,
                      {
                        staticClass: "minimal jb-add-operators-table mb-7",
                        attrs: { accordion: "" },
                        model: {
                          value: _vm.activeOperatorIndex,
                          callback: function ($$v) {
                            _vm.activeOperatorIndex = $$v
                          },
                          expression: "activeOperatorIndex",
                        },
                      },
                      _vm._l(
                        _vm.teamJobOperators,
                        function (operator, operatorIndex) {
                          return _c(
                            VExpansionPanel,
                            {
                              key: operatorIndex,
                              staticClass:
                                "jb-add-operator-cont pt-2 pb-2 minimal",
                            },
                            [
                              _c(
                                VExpansionPanelHeader,
                                { staticClass: "pa-0" },
                                [
                                  _c("OperatorAvatar", {
                                    staticClass: "mr-2 flex-grow-0",
                                    attrs: {
                                      width: "34px",
                                      height: "34px",
                                      isCircular: true,
                                      isThemeColor: false,
                                      isDark: false,
                                      backgroundColor: operator.operatorDetails
                                        .colorCode
                                        ? operator.operatorDetails.colorCode
                                        : _vm.teamJobOperatorIdleColor,
                                      initials:
                                        operator.operatorDetails.firstName,
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        title:
                                          operator.operatorDetails.firstName,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getShortenedName(
                                              operator.operatorDetails.firstName
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VIcon,
                                    {
                                      staticClass: "flex-grow-0 mr-4",
                                      attrs: { size: "20" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.removeOperatorFromOperatorsList(
                                            operatorIndex
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                              _c(VExpansionPanelContent, [
                                _c(
                                  "div",
                                  { staticClass: "pr-4 d-flex align-center" },
                                  [
                                    _c(VCheckbox, {
                                      staticClass: "mt-1 pt-0",
                                      attrs: {
                                        "hide-details": "",
                                        color: "primary",
                                        disabled: _vm.disableCheckBox(operator),
                                      },
                                      model: {
                                        value: operator.isTeamLead,
                                        callback: function ($$v) {
                                          _vm.$set(operator, "isTeamLead", $$v)
                                        },
                                        expression: "operator.isTeamLead",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "fn-smm align-self-end" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$stringConstants(
                                              "addJobMakeTeamLeadLabel"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mt-6" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field-input-label" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants("vehicle")
                                            ) +
                                            " * "
                                        ),
                                      ]
                                    ),
                                    _c(VAutocomplete, {
                                      attrs: {
                                        items:
                                          _vm.teamJobVehiclesListForOperators,
                                        "item-text": "title",
                                        placeholder: _vm.$stringConstants(
                                          "searchTextPlaceholder"
                                        ),
                                        "prepend-inner-icon": "mdi-magnify",
                                        outlined: "",
                                        clearable: "",
                                        dense: "",
                                        "deletable-chips": "",
                                        flat: "",
                                        attach: "",
                                        "return-object": "",
                                        solo: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateOperatorChecklist(
                                            operator.operatorDetails,
                                            _vm
                                              .TeamJobOperatorChecklistResourceType
                                              .Vehicle
                                          )
                                        },
                                        "click:clear": function ($event) {
                                          return _vm.updateSearchVehiclesText(
                                            $event,
                                            operatorIndex
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        input: function ($event) {
                                          return _vm.updateSearchVehiclesText(
                                            $event,
                                            operatorIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          operator.operatorDetails
                                            .defaultVehicle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            operator.operatorDetails,
                                            "defaultVehicle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "operator.operatorDetails.defaultVehicle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mt-6" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field-input-label" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants("implement")
                                            ) +
                                            " * "
                                        ),
                                      ]
                                    ),
                                    _c(VAutocomplete, {
                                      attrs: {
                                        items:
                                          _vm.teamJobImplementsListForOperators,
                                        "item-text": "title",
                                        placeholder: _vm.$stringConstants(
                                          "searchTextPlaceholder"
                                        ),
                                        "prepend-inner-icon": "mdi-magnify",
                                        outlined: "",
                                        clearable: "",
                                        dense: "",
                                        "deletable-chips": "",
                                        "return-object": "",
                                        attach: "",
                                        flat: "",
                                        solo: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateOperatorChecklist(
                                            operator.operatorDetails,
                                            _vm
                                              .TeamJobOperatorChecklistResourceType
                                              .Implement
                                          )
                                        },
                                        "click:clear": function ($event) {
                                          return _vm.updateSearchImplementsText(
                                            $event,
                                            operatorIndex
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        input: function ($event) {
                                          return _vm.updateSearchImplementsText(
                                            $event,
                                            operatorIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          operator.operatorDetails
                                            .defaultImplement,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            operator.operatorDetails,
                                            "defaultImplement",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "operator.operatorDetails.defaultImplement",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "jb-add-operators-table" },
                      _vm._l(
                        _vm.teamJobOperators,
                        function (operator, operatorIndex) {
                          return _c(
                            "div",
                            {
                              key: operatorIndex,
                              staticClass: "jb-add-operator-cont pt-2 pb-2",
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ib pr-4 width-perc-30",
                                    staticStyle: {
                                      transform: "translateY(-3.5px)",
                                    },
                                  },
                                  [
                                    _c("OperatorAvatar", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        width: "34px",
                                        height: "34px",
                                        isCircular: true,
                                        isThemeColor: false,
                                        isDark: false,
                                        backgroundColor: operator
                                          .operatorDetails.colorCode
                                          ? operator.operatorDetails.colorCode
                                          : _vm.teamJobOperatorIdleColor,
                                        initials:
                                          operator.operatorDetails.firstName,
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          title:
                                            operator.operatorDetails.firstName,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getShortenedName(
                                                operator.operatorDetails
                                                  .firstName
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ib pr-4 width-perc-10" },
                                  [
                                    _c(VCheckbox, {
                                      staticStyle: {
                                        transform: "translateY(-2px)",
                                      },
                                      attrs: {
                                        color: "primary",
                                        disabled: _vm.disableCheckBox(operator),
                                      },
                                      model: {
                                        value: operator.isTeamLead,
                                        callback: function ($$v) {
                                          _vm.$set(operator, "isTeamLead", $$v)
                                        },
                                        expression: "operator.isTeamLead",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ib pr-4 width-perc-25" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field-input-label" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants("vehicle")
                                            ) +
                                            " * "
                                        ),
                                      ]
                                    ),
                                    _c(VAutocomplete, {
                                      attrs: {
                                        items:
                                          _vm.teamJobVehiclesListForOperators,
                                        "item-text": "title",
                                        placeholder: _vm.$stringConstants(
                                          "searchTextPlaceholder"
                                        ),
                                        "prepend-inner-icon": "mdi-magnify",
                                        outlined: "",
                                        clearable: "",
                                        dense: "",
                                        "deletable-chips": "",
                                        flat: "",
                                        attach: "",
                                        "return-object": "",
                                        solo: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateOperatorChecklist(
                                            operator.operatorDetails,
                                            _vm
                                              .TeamJobOperatorChecklistResourceType
                                              .Vehicle
                                          )
                                        },
                                        "click:clear": function ($event) {
                                          return _vm.updateSearchVehiclesText(
                                            $event,
                                            operatorIndex
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        input: function ($event) {
                                          return _vm.updateSearchVehiclesText(
                                            $event,
                                            operatorIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          operator.operatorDetails
                                            .defaultVehicle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            operator.operatorDetails,
                                            "defaultVehicle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "operator.operatorDetails.defaultVehicle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ib pr-4 width-perc-25" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field-input-label" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$stringConstants("implement")
                                            ) +
                                            " * "
                                        ),
                                      ]
                                    ),
                                    _c(VAutocomplete, {
                                      attrs: {
                                        items:
                                          _vm.teamJobImplementsListForOperators,
                                        "item-text": "title",
                                        placeholder: _vm.$stringConstants(
                                          "searchTextPlaceholder"
                                        ),
                                        "prepend-inner-icon": "mdi-magnify",
                                        outlined: "",
                                        clearable: "",
                                        dense: "",
                                        "deletable-chips": "",
                                        "return-object": "",
                                        attach: "",
                                        flat: "",
                                        solo: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateOperatorChecklist(
                                            operator.operatorDetails,
                                            _vm
                                              .TeamJobOperatorChecklistResourceType
                                              .Implement
                                          )
                                        },
                                        "click:clear": function ($event) {
                                          return _vm.updateSearchImplementsText(
                                            $event,
                                            operatorIndex
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        input: function ($event) {
                                          return _vm.updateSearchImplementsText(
                                            $event,
                                            operatorIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          operator.operatorDetails
                                            .defaultImplement,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            operator.operatorDetails,
                                            "defaultImplement",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "operator.operatorDetails.defaultImplement",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ib width-perc-10 pr-4 text-right",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt-2 pl-4 ib cursor-pointer",
                                        attrs: { title: "Remove operator" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeOperatorFromOperatorsList(
                                              operatorIndex
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            staticClass: "job-add-list-rm",
                                            staticStyle: {
                                              transform: "translateY(-3px)",
                                            },
                                          },
                                          [_vm._v("mdi-close")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                _vm.teamJobShowJobAddOperatorExpandedView && !_vm.minimal
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "field-input-label jb-add-operator-cont pt-4 pb-4 cursor-pointer",
                        class: {
                          "jb-add-operator-cont-another":
                            _vm.teamJobOperators.length == 0,
                        },
                        on: { click: _vm.showOperatorDropdown },
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "cursor-pointer jb-add-ln-btn pr-8 py-3",
                            },
                            [
                              _vm.teamJobOperators.length
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "teamJobAddAnotherText"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "teamJobAddOperatorText"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm.teamJobShowJobAddOperatorExpandedView && _vm.minimal
                  ? _c(
                      VBtn,
                      {
                        staticClass:
                          "text-none fn-smm font-weight-medium add-operator-minimal",
                        attrs: { outlined: "", color: "primary", height: "40" },
                        on: { click: _vm.showOperatorDropdown },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("teamJobAddOperatorText")
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.teamJobShowOperatorSelectionDropdown
                  ? _c(
                      "div",
                      { attrs: { id: "operators-selection-menu" } },
                      [
                        _vm.teamJobOperators.length == 0
                          ? _c("div", { staticClass: "field-input-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants(
                                      "teamJobOperatorsTitle"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _c(VAutocomplete, {
                          ref: "addJobOperatorSelectorRef",
                          staticClass: "jb-atcp-zindex",
                          attrs: {
                            id: "operatorSelectionMenuCompact",
                            items: _vm.teamJobsTeamMembersListForOperators,
                            "item-text": "firstName",
                            placeholder: _vm.$stringConstants(
                              "selectOperatorPlaceholder"
                            ),
                            loading: _vm.teamJobOperatorToAddLoader,
                            outlined: !_vm.teamJobOperatorToAddLoader,
                            dense: "",
                            flat: "",
                            attach: "",
                            solo: "",
                            "return-object": "",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.refeatchOperatorList($event)
                            },
                            change: _vm.addOperatorToOperatorsList,
                          },
                          nativeOn: {
                            input: function ($event) {
                              return _vm.updateSearchOperatorText($event)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "py-2",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ib",
                                              staticStyle: {
                                                width: "40px !important",
                                              },
                                            },
                                            [
                                              _c("OperatorAvatar", {
                                                attrs: {
                                                  width: "34px",
                                                  height: "34px",
                                                  isCircular: true,
                                                  initials: data.item.firstName,
                                                  isThemeColor: false,
                                                  backgroundColor:
                                                    data.item.colorCode,
                                                  isDisabled:
                                                    _vm.isOperatorPresentInOperatorsList(
                                                      _vm.teamJobOperators,
                                                      data.item.id
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ib",
                                              staticStyle: {
                                                width: "auto",
                                                transform: "translateY(3px)",
                                              },
                                            },
                                            [
                                              _c(VListItemTitle, {
                                                class: {
                                                  "jb-add-operator-added":
                                                    _vm.isOperatorPresentInOperatorsList(
                                                      _vm.teamJobOperators,
                                                      data.item.id
                                                    ),
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    data.item.firstName +
                                                      (_vm.isOperatorPresentInOperatorsList(
                                                        _vm.teamJobOperators,
                                                        data.item.id
                                                      )
                                                        ? " (added)"
                                                        : "")
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "append-item",
                                fn: function () {
                                  return [
                                    _c("LoadMoreDropdown", {
                                      attrs: {
                                        moreData: _vm.hasMoreData,
                                        loadInProgress:
                                          _vm.teamJobOperatorToAddLoader,
                                        loadingListLength:
                                          _vm
                                            .teamJobsTeamMembersListForOperators
                                            .length,
                                      },
                                      on: {
                                        appendMore: _vm.appendMoreOperator,
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2957556516
                          ),
                          model: {
                            value: _vm.teamJobOperatorToAdd,
                            callback: function ($$v) {
                              _vm.teamJobOperatorToAdd = $$v
                            },
                            expression: "teamJobOperatorToAdd",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.teamJobViewPreference.operators.isDetailed,
                    expression: "teamJobViewPreference.operators.isDetailed",
                  },
                ],
              },
              [
                _vm.teamJobOperators.length
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "jb-operators-list-head",
                          class: {
                            "jb-operators-list-head-minimal":
                              _vm.isTeamJobOperatorsSelectable,
                          },
                        },
                        [
                          _vm.isTeamJobOperatorsSelectable
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ib width-perc-5 pr-2 jb-select-all-operators",
                                },
                                [
                                  _c(VCheckbox, {
                                    staticClass: "mt-0 mb-0",
                                    staticStyle: {
                                      transform: "translateY(3px)",
                                    },
                                    attrs: { disabled: false },
                                    on: {
                                      change:
                                        _vm.toggleSelectedOperatorsTotalWorkHours,
                                    },
                                    model: {
                                      value: _vm.isAllOperatorsSelected,
                                      callback: function ($$v) {
                                        _vm.isAllOperatorsSelected = $$v
                                      },
                                      expression: "isAllOperatorsSelected",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "ib width-perc-20 pr-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants("teamJobOperatorsTitle")
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$stringConstants("teamLead")) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-20 pr-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$stringConstants("vehicle")) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-20 pr-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$stringConstants("implement")) +
                                " "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "ib width-perc-11 pr-4 pl-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$stringConstants("checkList")) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "ib width-perc-12 pr-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$stringConstants("timeDuration")) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "ib width-perc-2 pr-4" }),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { attrs: { id: "jb-add-operators-table" } },
                  _vm._l(
                    _vm.teamJobOperators,
                    function (operator, operatorIndex) {
                      return _c(
                        "div",
                        {
                          key: operatorIndex,
                          staticClass: "jb-add-operator-cont pt-2 pb-4",
                        },
                        [
                          _c(
                            "div",
                            { class: _vm.minimal ? "d-flex flex-column" : "" },
                            [
                              _vm.isTeamJobOperatorsSelectable
                                ? _c(
                                    "div",
                                    { staticClass: "ib width-perc-5 pr-2" },
                                    [
                                      _c(VCheckbox, {
                                        style: {
                                          transform: "translateY(3px)",
                                        },
                                        attrs: {
                                          value: operator.operatorDetails.id,
                                        },
                                        on: {
                                          change:
                                            _vm.updateSelectedOperatorsTotalWorkHours,
                                        },
                                        model: {
                                          value: _vm.teamJobSelectedOperators,
                                          callback: function ($$v) {
                                            _vm.teamJobSelectedOperators = $$v
                                          },
                                          expression:
                                            "teamJobSelectedOperators",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "ib pr-4 width-perc-20" },
                                [
                                  _c("OperatorAvatar", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      isCircular: true,
                                      width: "34px",
                                      height: "34px",
                                      isThemeColor: false,
                                      backgroundColor: operator.operatorDetails
                                        .colorCode
                                        ? operator.operatorDetails.colorCode
                                        : _vm.teamJobOperatorIdleColor,
                                      initials:
                                        operator.operatorDetails.firstName,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "ib vertical-align-top" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fw-6 jb-op-ttl",
                                          attrs: {
                                            title:
                                              operator.operatorDetails
                                                .firstName,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getShortenedName(
                                                  operator.operatorDetails
                                                    .firstName
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "fn-sm jb-op-sts pt-1" },
                                        [
                                          _c("div", { staticClass: "ib" }, [
                                            _c("img", {
                                              attrs: {
                                                src: require("../../../assets/working-icon.svg"),
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getWorkStatus(
                                                    operator.operatorDetails
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "ib pr-4 width-perc-10" },
                                [
                                  _c(VCheckbox, {
                                    staticStyle: {
                                      transform: "translateY(3px)",
                                    },
                                    attrs: {
                                      disabled:
                                        _vm.disableCheckBox(operator) ||
                                        !_vm.teamJobViewPreference.operators
                                          .isEditable,
                                    },
                                    on: {
                                      change:
                                        _vm.enableDiscardButtonFromOperators,
                                    },
                                    model: {
                                      value: operator.isTeamLead,
                                      callback: function ($$v) {
                                        _vm.$set(operator, "isTeamLead", $$v)
                                      },
                                      expression: "operator.isTeamLead",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "ib pr-4 width-perc-20" },
                                [
                                  _c(VAutocomplete, {
                                    class: { "light-input": _vm.isDetailsView },
                                    attrs: {
                                      items:
                                        _vm.teamJobVehiclesListForOperators,
                                      "item-text": "title",
                                      placeholder: _vm.$stringConstants(
                                        "searchTextPlaceholder"
                                      ),
                                      height: "48",
                                      "search-input":
                                        _vm
                                          .teamJobVehiclesSearchInputListForOperators[
                                          operatorIndex
                                        ],
                                      disabled:
                                        !_vm.teamJobViewPreference.operators
                                          .isEditable,
                                      outlined: "",
                                      clearable: "",
                                      dense: "",
                                      "deletable-chips": "",
                                      flat: "",
                                      attach: "",
                                      "return-object": "",
                                      solo: "",
                                    },
                                    on: {
                                      "update:searchInput": function ($event) {
                                        return _vm.$set(
                                          _vm.teamJobVehiclesSearchInputListForOperators,
                                          operatorIndex,
                                          $event
                                        )
                                      },
                                      "update:search-input": function ($event) {
                                        return _vm.$set(
                                          _vm.teamJobVehiclesSearchInputListForOperators,
                                          operatorIndex,
                                          $event
                                        )
                                      },
                                      change: function ($event) {
                                        return _vm.updateOperatorChecklist(
                                          operator.operatorDetails,
                                          _vm
                                            .TeamJobOperatorChecklistResourceType
                                            .Vehicle
                                        )
                                      },
                                      "click:clear": function ($event) {
                                        return _vm.updateSearchVehiclesText(
                                          $event,
                                          operatorIndex
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      input: function ($event) {
                                        return _vm.updateSearchVehiclesText(
                                          $event,
                                          operatorIndex
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        operator.operatorDetails.defaultVehicle,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          operator.operatorDetails,
                                          "defaultVehicle",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "operator.operatorDetails.defaultVehicle",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "ib pr-4 width-perc-20" },
                                [
                                  _c(VAutocomplete, {
                                    class: { "light-input": _vm.isDetailsView },
                                    attrs: {
                                      items:
                                        _vm.teamJobImplementsListForOperators,
                                      "item-text": "title",
                                      height: "48",
                                      placeholder: _vm.$stringConstants(
                                        "searchTextPlaceholder"
                                      ),
                                      disabled:
                                        !_vm.teamJobViewPreference.operators
                                          .isEditable,
                                      outlined: "",
                                      clearable: "",
                                      dense: "",
                                      "deletable-chips": "",
                                      "return-object": "",
                                      attach: "",
                                      flat: "",
                                      solo: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateOperatorChecklist(
                                          operator.operatorDetails,
                                          _vm
                                            .TeamJobOperatorChecklistResourceType
                                            .Implement
                                        )
                                      },
                                      "click:clear": function ($event) {
                                        return _vm.updateSearchImplementsText(
                                          $event,
                                          operatorIndex
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      input: function ($event) {
                                        return _vm.updateSearchImplementsText(
                                          $event,
                                          operatorIndex
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        operator.operatorDetails
                                          .defaultImplement,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          operator.operatorDetails,
                                          "defaultImplement",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "operator.operatorDetails.defaultImplement",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "ib width-perc-11 pr-4 pl-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ib vertical-align-middle pt-1",
                                    },
                                    [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.hasOperatorCompletedChecklists(
                                                operator.operatorDetails
                                              ),
                                            expression:
                                              "hasOperatorCompletedChecklists(operator.operatorDetails)",
                                          },
                                        ],
                                        attrs: {
                                          src: require("../../../assets/checklist.svg"),
                                        },
                                      }),
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.hasOperatorCompletedChecklists(
                                                operator.operatorDetails
                                              ),
                                            expression:
                                              "!hasOperatorCompletedChecklists(operator.operatorDetails)",
                                          },
                                        ],
                                        attrs: {
                                          src: require("../../../assets/checklist-pending.svg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ib vertical-align-middle pl-2",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            "jb-chk-cnt-color-done":
                                              _vm.hasOperatorCompletedChecklists(
                                                operator.operatorDetails
                                              ),
                                            "jb-chk-cnt-color-pending":
                                              !_vm.hasOperatorCompletedChecklists(
                                                operator.operatorDetails
                                              ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getChecklistsCount(
                                                  operator.operatorDetails
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "ib width-perc-12 pr-4" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getWorkTimeDuration(operator)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "ib width-perc-2 pr-4" },
                                [
                                  !_vm.checkOperatorHasWorked(operator) &&
                                  _vm.teamJobViewPreference.operators.isEditable
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "pt-2 ib cursor-pointer",
                                          attrs: { title: "Remove operator" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeOperatorFromOperatorsList(
                                                operatorIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            {
                                              staticClass: "job-add-list-rm",
                                              staticStyle: {
                                                transform: "translateY(-3px)",
                                              },
                                            },
                                            [_vm._v("mdi-close")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
                _vm.teamJobShowJobAddOperatorExpandedView &&
                _vm.teamJobViewPreference.operators.isEditable &&
                !_vm.isAnyOperatorSelected
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "field-input-label jb-add-operator-cont pt-4 pb-4 cursor-pointer",
                        class: {
                          "jb-add-operator-cont-another":
                            _vm.teamJobOperators.length == 0,
                        },
                        on: { click: _vm.showOperatorDropdown },
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "cursor-pointer jb-add-ln-btn pr-8 py-3",
                            },
                            [
                              _vm.teamJobOperators.length
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "teamJobAddAnotherText"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "teamJobAddOperatorText"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.isTeamJobOperatorsSelectable && _vm.isAnyOperatorSelected
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "field-input-label jb-add-operator-cont pt-4 pb-4 billable-hours-bg",
                      },
                      [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants("teamJobTotalBillableTime")
                              ) +
                              ": "
                          ),
                          _vm.teamJobOperation.billingUnit === "hour"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.teamJobSelectedOperatorsHours / 3600
                                      ).toFixed(2)
                                    ) +
                                    " hours "
                                ),
                              ])
                            : _vm._e(),
                          _vm.teamJobOperation.billingUnit === "minute"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      Math.ceil(
                                        _vm.teamJobSelectedOperatorsHours / 60
                                      ).toFixed(0)
                                    ) +
                                    " minutes "
                                ),
                              ])
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm.getTimeInHoursAndMins(
                                    _vm.teamJobSelectedOperatorsHours
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.isTeamJobOperatorsSelectable && _vm.isAnyOperatorSelected
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "field-input-label jb-add-operator-cont pt-4 pb-4 billable-hours-add-bg",
                      },
                      [
                        _c("Button", {
                          style: {
                            border:
                              !_vm.teamJobSelectedOperatorsHours ||
                              _vm.teamJobSelectedOperatorsHours == 0
                                ? "1px solid #d3d3d3 !important"
                                : "unset",
                          },
                          attrs: {
                            textValue: "Use as billable quantity",
                            disabled:
                              !_vm.teamJobSelectedOperatorsHours ||
                              _vm.teamJobSelectedOperatorsHours == 0,
                            color: "primary",
                            variant: "secondary",
                            outlined: "",
                            outlineColor: "primary",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.setWorkHoursAsBillableQuantity($event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.teamJobShowOperatorSelectionDropdown
                  ? _c(
                      "div",
                      [
                        _vm.teamJobOperators.length == 0
                          ? _c("div", { staticClass: "field-input-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants(
                                      "teamJobOperatorsTitle"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _c(VAutocomplete, {
                          ref: "addJobOperatorSelectorRef",
                          staticClass: "operatorSelection",
                          attrs: {
                            id: "operatorSelectionMenuDetailed",
                            items: _vm.teamJobsTeamMembersListForOperators,
                            "item-text": "firstName",
                            placeholder: _vm.$stringConstants(
                              "selectOperatorPlaceholder"
                            ),
                            loading: _vm.teamJobOperatorToAddLoader,
                            outlined: !_vm.teamJobOperatorToAddLoader,
                            dense: "",
                            flat: "",
                            attach: "",
                            solo: "",
                            "return-object": "",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.refeatchOperatorList($event)
                            },
                            change: _vm.addOperatorToOperatorsList,
                          },
                          nativeOn: {
                            input: function ($event) {
                              return _vm.updateSearchOperatorText($event)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "py-2",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ib",
                                              staticStyle: {
                                                width: "40px !important",
                                              },
                                            },
                                            [
                                              _c("OperatorAvatar", {
                                                attrs: {
                                                  isCircular: true,
                                                  width: "34px",
                                                  height: "34px",
                                                  initials: data.item.firstName,
                                                  isThemeColor: false,
                                                  backgroundColor:
                                                    data.item.colorCode,
                                                  isDisabled:
                                                    _vm.isOperatorPresentInOperatorsList(
                                                      _vm.teamJobOperators,
                                                      data.item.id
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ib",
                                              staticStyle: {
                                                width: "auto",
                                                transform: "translateY(3px)",
                                              },
                                            },
                                            [
                                              _c(VListItemTitle, {
                                                staticClass: "pl-1",
                                                class: {
                                                  "jb-add-operator-added":
                                                    _vm.isOperatorPresentInOperatorsList(
                                                      _vm.teamJobOperators,
                                                      data.item.id
                                                    ),
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    data.item.firstName +
                                                      (_vm.isOperatorPresentInOperatorsList(
                                                        _vm.teamJobOperators,
                                                        data.item.id
                                                      )
                                                        ? " (added)"
                                                        : "")
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            152853180
                          ),
                          model: {
                            value: _vm.teamJobOperatorToAdd,
                            callback: function ($$v) {
                              _vm.teamJobOperatorToAdd = $$v
                            },
                            expression: "teamJobOperatorToAdd",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.teamJobsLoader,
              expression: "teamJobsLoader",
            },
          ],
        },
        [
          _c(VSkeletonLoader, {
            staticClass: "mb-2",
            attrs: { height: "70", type: "card" },
          }),
          _c(VSkeletonLoader, {
            staticClass: "mb-8",
            attrs: { height: "70", type: "card" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }