import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { deleteObjectFromList } from "@/utils/helpers";
import { mapMutations } from "vuex";
import StringConstants from "@/plugins/stringConstants";
import ConstantValues from "@/plugins/constantValues";
import { getComputedProperties } from "@/utils/helpers/computed-generator";
var computedProperties = {
  'usersModule': ['userInviteeEmail', 'usersLoader', 'dialogInvitation', 'invitationId', 'connectionRequestType', 'dialogType'],
  'contractorsModule': ['contractorSearchText', 'contractorFilterType', 'contractorFilterList', 'pendingContractorList', 'noAcceptedContractors', 'noPendingContractors', 'acceptedContractorTotalCount', 'pendingContractorTotalCount', 'connectionRequestsSentList', 'connectionRequestsReceivedList', 'connectionRequestsSentLoader', 'connectionRequestsReceivedLoader'],
  'root': ['filtersApplied', 'statusFilterTypeList', 'noData', 'listCount', 'isPageRelated', 'listLoading']
};
export default {
  name: 'ContractorsComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    invitationDialogPromptTitle: function invitationDialogPromptTitle() {
      return this.dialogType == 'accept' ? StringConstants.userAcceptInvitationPromptTitle : StringConstants.userDeleteInvitationPromptTitle;
    },
    invitationDialogPromptSubtitle: function invitationDialogPromptSubtitle() {
      return this.dialogType == 'accept' ? StringConstants.userAcceptInvitationPromptSubtitle : StringConstants.userDeleteInvitationPromptSubtitle;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearContractorFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    confirmDeleteInvitation: function confirmDeleteInvitation(id, connectionRequestType) {
      this.invitationId = id;
      this.dialogInvitation = true;
      this.connectionRequestType = connectionRequestType;
      this.dialogType = 'delete';
    },
    confirmAcceptInvitation: function confirmAcceptInvitation(id) {
      this.invitationId = id;
      this.dialogInvitation = true;
      this.dialogType = 'accept';
    },
    acceptConnection: function acceptConnection() {
      var _this = this;
      this.$store.dispatch('acceptUserInvite', this.invitationId).then(function (res) {
        if (res) {
          _this.$store.commit('setConnectionRequestsReceivedList', deleteObjectFromList(_this.connectionRequestsReceivedList, _this.invitationId, 'code'));
          if (_this.contractorFilterType == 0) {
            _this.refreshContractorList();
          }
        }
        _this.dialogInvitation = !_this.dialogInvitation;
      });
    },
    declineConnection: function declineConnection() {
      var _this2 = this;
      this.$store.dispatch('declineUserInvite', this.invitationId).then(function (res) {
        if (res) {
          _this2.$store.commit('setConnectionRequestsReceivedList', deleteObjectFromList(_this2.connectionRequestsReceivedList, _this2.invitationId, 'code'));
        }
        _this2.dialogInvitation = !_this2.dialogInvitation;
      });
    },
    deleteSentConnection: function deleteSentConnection() {
      var _this3 = this;
      this.$store.dispatch('deleteSentConReq', this.invitationId).then(function (res) {
        if (res) {
          _this3.$store.commit('setConnectionRequestsSentList', deleteObjectFromList(_this3.connectionRequestsSentList, _this3.invitationId, 'code'));
          if (_this3.contractorFilterType == 1) {
            _this3.refreshPendingContractorList();
          }
        }
        _this3.dialogInvitation = !_this3.dialogInvitation;
      });
    },
    clearContractorList: function clearContractorList() {
      this.contractorFilterList = [];
      this.clearContractorFilterOptions();
      this.resetPaging();
    },
    clearPendingContractorList: function clearPendingContractorList() {
      this.pendingContractorList = [];
      this.clearContractorFilterOptions();
      this.resetPaging();
    },
    refreshContractorList: function refreshContractorList() {
      var _this4 = this;
      this.clearContractorList();
      this.$store.dispatch('setContractorFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function (res) {
        if (res) {
          _this4.$store.dispatch('getContractorFiltered');
        }
      });
    },
    refreshPendingContractorList: function refreshPendingContractorList() {
      var _this5 = this;
      this.clearPendingContractorList();
      this.$store.dispatch('setContractorFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function (res) {
        if (res) {
          _this5.$store.dispatch('getPendingContractorFiltered');
        }
      });
    },
    resetContractorSearch: function resetContractorSearch() {
      this.$store.commit('setClearUserInvitationState');
    }
  })
};