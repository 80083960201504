import { listAction } from '@/enum/listAction';
export default {
  name: 'SnackBarList',
  props: ['deleteApiType', 'allItemsSelected', 'someItemsSelected', 'status', 'showOnlyDelete', 'showArchiveOrDelete', 'showRoleChange', 'change-role-callback', 'showUpdateButton', 'update-items-callback', 'integrationType', 'loaderState'],
  data: function data() {
    return {
      timeout: -1,
      listAction: listAction
    };
  },
  watch: {
    snackbarShow: function snackbarShow(snackBarToggleVisibility) {
      if (snackBarToggleVisibility === false) {
        this.clearAllSelected();
      }
    }
  },
  computed: {
    snackbarShow: {
      get: function get() {
        return this.$store.getters.snackbarShow;
      },
      set: function set(newVal) {
        this.$store.state.snackbarShow = newVal;
      }
    },
    allItemsDeleted: {
      get: function get() {
        return this.$store.getters.allItemsDeleted;
      },
      set: function set(newVal) {
        this.$store.state.allItemsDeleted = newVal;
      }
    },
    allItemsRestored: {
      get: function get() {
        return this.$store.getters.allItemsRestored;
      },
      set: function set(newVal) {
        this.$store.state.allItemsRestored = newVal;
      }
    },
    allItemsArchived: {
      get: function get() {
        return this.$store.getters.allItemsArchived;
      },
      set: function set(newVal) {
        this.$store.state.allItemsArchived = newVal;
      }
    },
    selectedItems: {
      get: function get() {
        return this.$store.getters.selectedItems;
      },
      set: function set(newVal) {
        this.$store.state.selectedItems = newVal;
      }
    },
    totalRowCount: {
      get: function get() {
        return this.$store.getters.totalRowCount;
      },
      set: function set(newVal) {
        this.$store.state.totalRowCount = newVal;
      }
    },
    listActionType: {
      get: function get() {
        return this.$store.getters.listActionType;
      },
      set: function set(newVal) {
        this.$store.state.listActionType = newVal;
      }
    },
    dialogSelectedRows: {
      get: function get() {
        return this.$store.getters.dialogSelectedRows;
      },
      set: function set(val) {
        this.$store.state.dialogSelectedRows = val;
      }
    },
    archiveDialogSelectedRows: {
      get: function get() {
        return this.$store.getters.archiveDialogSelectedRows;
      },
      set: function set(val) {
        this.$store.state.archiveDialogSelectedRows = val;
      }
    },
    restoreDialogSelectedRows: {
      get: function get() {
        return this.$store.getters.restoreDialogSelectedRows;
      },
      set: function set(value) {
        this.$store.state.restoreDialogSelectedRows = value;
      }
    },
    bulkActionLoader: {
      get: function get() {
        return this.$store.getters.bulkActionLoader;
      },
      set: function set(value) {
        this.$store.state.bulkActionLoader = value;
      }
    }
  },
  methods: {
    selectAllItemsData: function selectAllItemsData() {
      this.allItemsDeleted = true;
      this.allItemsArchived = true;
      this.allItemsRestored = true;
    },
    deleteSectedRows: function deleteSectedRows() {
      this.listActionType = listAction.Delete;
      this.dialogSelectedRows = true;
    },
    clearAllSelected: function clearAllSelected() {
      this.selectedItems = [];
      this.allItemsDeleted = false;
      this.allItemsArchived = false;
      this.allItemsRestored = false;
    },
    archiveSelectedRows: function archiveSelectedRows() {
      this.listActionType = listAction.Archive;
      this.dialogSelectedRows = true;
    },
    restoreSelectedRows: function restoreSelectedRows() {
      this.listActionType = listAction.Restore;
      this.dialogSelectedRows = true;
    },
    changeRole: function changeRole() {
      this.$emit('change-role-callback');
    },
    updateItems: function updateItems() {
      this.$emit('update-items-callback');
    }
  }
};