import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "datepickerRef",
      staticClass: "container d-flex flex-column pa-0 white",
      style: _vm.calculatedStyle,
      attrs: { id: "unscheduled-jobs-datepicker" },
    },
    [
      _c("VueCtkDateTimePicker", {
        attrs: {
          "no-header": "",
          "no-label": "",
          "no-button": "",
          inline: "",
          color: "#1d34ad",
          formatted: "DD-MM-YYYY HH:mm",
          format: "YYYY-MM-DD HH:mm:ss",
        },
        model: {
          value: _vm.jobsScheduledDate,
          callback: function ($$v) {
            _vm.jobsScheduledDate = $$v
          },
          expression: "jobsScheduledDate",
        },
      }),
      _c(VDivider, { attrs: { light: "" } }),
      _c(
        "div",
        { staticClass: "actions ml-auto pa-5" },
        [
          _c(
            VBtn,
            {
              staticClass: "white primary--text text-none mr-5",
              attrs: { text: "", width: "85" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$stringConstants("cancel")) + " ")]
          ),
          _c(
            VBtn,
            {
              staticClass: "primary white--text text-none",
              attrs: { width: "85" },
              on: { click: _vm.emitSelectedDatetime },
            },
            [_vm._v(" " + _vm._s(_vm.$stringConstants("doneText")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }