import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import { closeOverlay } from "@/utils/helpers";
import ImportData from "@/components/shared/import/import-csv-list.vue";
import { mapMutations } from "vuex";
import productsComputedMixin from "@/components/products/products-computed";
import { ImportResourceType } from "@/enum/importResourceType";
var productInfo = {
  costField: JSON.parse('"cost*"'),
  titleField: JSON.parse('"name*"'),
  unitField: JSON.parse('"unit*"')
};
var productObj = {};
productObj[productInfo.titleField] = '';
productObj[productInfo.costField] = '';
productObj[productInfo.unitField] = '';
export default {
  name: "ProductsImportForm",
  mixins: [productsComputedMixin],
  components: {
    ImportData: ImportData
  },
  data: function data() {
    return {
      productsSampleData: [productObj],
      requiredImportFields: ["name", "cost", "unit"],
      uploadNotes: [this.$stringConstants('uploadNotesDeleteColumnWarning'), this.$stringConstants('uploadNotesCommaWarning'), 'All columns are required.'],
      importResourceType: ImportResourceType.Products
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$store.dispatch('getProductsUnits').then(function (res) {
      if (res === null || res === void 0 ? void 0 : res.data) {
        var units = res.data.join(', ');
        _this.uploadNotes.push("Supported units are: ".concat(units));
      }
    });
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFarmerFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging",
    resetImportForm: "resetImportDataDetails"
  })), {}, {
    closeImportProductsForm: function closeImportProductsForm() {
      closeOverlay("importProductsFormOverlay");
    },
    refreshProductsList: function refreshProductsList() {
      this.resetPaging();
      this.$store.commit('setClearFilterOptions');
      this.productsList = [];
      this.$store.dispatch('getProductsListFiltered');
    }
  })
};