import "core-js/modules/es.array.push.js";
import { formFieldPb } from '@/utils/uiconstants';
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { validationMixin } from 'vuelidate';
import { email } from 'vuelidate/lib/validators';
export default {
  name: 'ContactPersonalDetailsForm',
  mixins: [validationMixin, contactComputedMixin],
  validations: {
    contactEmail: {
      email: email
    }
  },
  components: {
    SectionLeftHeadings: SectionLeftHeadings
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      fieldsInvalid: false,
      menuColor: false
    };
  },
  computed: {
    swatchStyle: function swatchStyle() {
      var menuColor = this.menuColor;
      return {
        backgroundColor: this.contactProfile.colorCode,
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: menuColor ? "50%" : "50px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  },
  methods: {
    generateEmailValidationErrors: function generateEmailValidationErrors() {
      var errors = [];
      if (!this.$v.contactEmail.$dirty) return errors;
      if (!this.$v.contactEmail.email) {
        errors.push(this.$stringConstants('userFormatEmailAddress'));
        this.fieldsInvalid = true;
      } else {
        this.fieldsInvalid = false;
      }
      return errors;
    }
  }
};