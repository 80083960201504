import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.trim.js";
import { disabledColor } from "@/utils/uiconstants";
import { getColorPreset } from '@/utils/helpers';
export default {
  name: "Avatar",
  props: ["initials", "isCircular", "isThemeColor", "isDisabled", "isListAvatar", "height", "width", "backgroundColor", "contentClass"],
  data: function data() {
    return {
      disabledColor: disabledColor,
      defaultSize: '28px'
    };
  },
  methods: {
    getAvatarStyles: function getAvatarStyles() {
      return {
        backgroundColor: this.isDisabled ? this.disabledColor : this.getInitialColor(),
        width: this.width ? this.width : this.defaultSize,
        height: this.height ? this.height : this.defaultSize
      };
    },
    getInitials: function getInitials(data) {
      if (typeof data === 'number') return data;
      if (data !== null && data !== '' && typeof data === 'string') {
        if (data.includes('+')) return data;
        var chr;
        var d = data.replace(/[^a-zA-Z0-9]/g, ' ');
        d = d.toUpperCase().replace(/\s\s+/g, ' ');
        chr = d.trim().split(" ");
        if (chr.length >= 2) {
          return chr[0][0] + chr[1][0];
        } else {
          return d[0] + d[1];
        }
      }
      return '';
    },
    getInitialColor: function getInitialColor() {
      if (this.backgroundColor) {
        return this.backgroundColor;
      } else {
        return getColorPreset(this.initials);
      }
    }
  }
};