import { VApp } from 'vuetify/lib/components/VApp';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VApp,
        { staticClass: "f-ns" },
        [
          _vm.loggedIn && _vm.showAllDashboardViewsLocal == "1"
            ? _c(
                VSystemBar,
                {
                  class:
                    "page-title pL-main pr-0 " +
                    (_vm.collapsedNavigation ? "pl-14" : ""),
                },
                [
                  _c(
                    VRow,
                    { attrs: { align: "center" } },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "6 py-0" } },
                        [
                          _c(
                            VRow,
                            [
                              _c(VCol, { attrs: { cols: "12" } }, [
                                _vm.dashboardTitle
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          VToolbarTitle,
                                          { staticClass: "dtitle pl-3" },
                                          [_vm._v(_vm._s(_vm.dashboardTitle))]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c(VBreadcrumbs, {
                                          attrs: {
                                            items: _vm.breadcrumbsItems,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "divider",
                                                fn: function () {
                                                  return [
                                                    _c(VIcon, [
                                                      _vm._v(
                                                        "mdi-chevron-right"
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "item",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      VBreadcrumbsItem,
                                                      {
                                                        staticClass:
                                                          "text-subtitle-2 crumb-item",
                                                        attrs: {
                                                          to: item.to,
                                                          disabled:
                                                            item.disabled,
                                                          exact: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.text) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            980250886
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "py-0 pr-8", attrs: { cols: "6" } },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                { staticClass: "pr-0", attrs: { cols: "12" } },
                                [
                                  _vm.shouldShowTrial &&
                                  !_vm.collapsedNavigation
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            "nav-user-nm",
                                            "ds-rmn",
                                            _vm.getTrialClass,
                                            "trial-days",
                                          ],
                                          on: {
                                            click: _vm.routeToSubscription,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trialDaysRemaining +
                                                  " day trial remaining"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "ib",
                                        "video-tutorials",
                                        "cursor-pointer",
                                      ],
                                      on: { click: _vm.openTutorialsLink },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "video-tutorials-text" },
                                        [_vm._v("Tutorials")]
                                      ),
                                      _c("img", {
                                        staticClass: "video-tutorials-img",
                                        attrs: {
                                          src: require("./assets/video-play-icon.svg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("div", { staticClass: "separator" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-right ib usr-profile",
                                    },
                                    [
                                      _vm.drawerIsOpen
                                        ? _c(
                                            "div",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass: "oy-cl",
                                                  attrs: {
                                                    small: "",
                                                    id: "closeFieldAddForm",
                                                  },
                                                  on: {
                                                    click: _vm.closeDrawer,
                                                  },
                                                },
                                                [_vm._v(" mdi-close-thick ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            VMenu,
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "nav-user-nm txt-wrp",
                                                            attrs: {
                                                              title:
                                                                _vm.loginUserName,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getShortName(
                                                                  _vm.loginUserName
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "btn-prf",
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("./assets/profile.svg"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2985803883
                                              ),
                                            },
                                            [
                                              _c(
                                                VList,
                                                [
                                                  _c(
                                                    VListItem,
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.routeToAccount,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VListItemTitle,
                                                        {
                                                          staticClass: "pr-16",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$stringConstants(
                                                                "accountTitle"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.loginUserRole ==
                                                    _vm.UserRoleType
                                                      .Contractor ||
                                                  _vm.loginUserRole ==
                                                    _vm.UserRoleType.Farmer
                                                    ? _c(
                                                        VListItem,
                                                        {
                                                          attrs: {
                                                            to: _vm.routeSubscriptions,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            VListItemTitle,
                                                            {
                                                              staticClass:
                                                                "pr-16",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$stringConstants(
                                                                    "subscriptionsTitle"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    VListItem,
                                                    {
                                                      on: {
                                                        click: _vm.logoutClick,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VListItemTitle,
                                                        {
                                                          staticClass: "pr-16",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$stringConstants(
                                                                "logoutText"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.loggedIn && _vm.showAllDashboardViewsLocal == "1"
            ? _c(
                VNavigationDrawer,
                {
                  attrs: {
                    width: _vm.sidebarWidth,
                    fixed: true,
                    permanent: true,
                    touchless: true,
                    left: "",
                    "mini-variant": _vm.collapsedNavigation,
                    "mini-variant-width": 65,
                  },
                  on: {
                    "update:miniVariant": function ($event) {
                      _vm.collapsedNavigation = $event
                    },
                    "update:mini-variant": function ($event) {
                      _vm.collapsedNavigation = $event
                    },
                  },
                },
                [_c("nav-menu-sidebar")],
                1
              )
            : _vm._e(),
          _c(
            VMain,
            {
              class: {
                "pL-main":
                  _vm.loggedIn && _vm.showAllDashboardViewsLocal == "1",
                "pl-14": _vm.collapsedNavigation,
              },
            },
            [
              _vm.$route.matched.length
                ? [
                    _c("router-view", {
                      key: _vm.uniqueRouteKey,
                      staticClass: "v-main__router-view",
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "modal", attrs: { id: "notiModal" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", { staticClass: "model-heading" }, [
              _vm._v(_vm._s(_vm.$stringConstants("informationText"))),
            ]),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", { staticClass: "message" }),
            _c(
              "div",
              { staticClass: "t-right" },
              [
                _c(
                  VBtn,
                  {
                    attrs: { id: "okay", color: "primary", text: "" },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v(" " + _vm._s(_vm.$stringConstants("okayText")) + " ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "modal", attrs: { id: "notiAutoCloseModal" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "div",
              { staticClass: "model-heading model-heading-auto-close" },
              [_vm._v(_vm._s(_vm.$stringConstants("informationText")))]
            ),
          ]),
          _vm._m(0),
        ]),
      ]),
      _c("Notification"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("p", { staticClass: "message message-auto-close" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }