import { defaultFieldStrokeColor } from "@/utils/uiconstants";
export var state = {
  onboardingStates: {
    userNotVerified: 0,
    userVerified: 1,
    userBusinessInfoFilled: 2,
    userInvoicingInfoFilled: 3
  },
  onboardingStateSelected: 1,
  onboardSelectUserVerified: 1,
  onboardSelectUserBusinessInfoFilled: 2,
  onboardSelectUserInvoicingInfoFilled: 3,
  onboardLoader: false,
  onboardMapSearchValue: null,
  onboardConfigTempColor: defaultFieldStrokeColor
};
export var getters = {
  onboardingStates: function onboardingStates(state) {
    return state.onboardingStates;
  },
  onboardingStateSelected: function onboardingStateSelected(state) {
    return state.onboardingStateSelected;
  },
  onboardSelectUserVerified: function onboardSelectUserVerified(state) {
    return state.onboardSelectUserVerified;
  },
  onboardSelectUserBusinessInfoFilled: function onboardSelectUserBusinessInfoFilled(state) {
    return state.onboardSelectUserBusinessInfoFilled;
  },
  onboardSelectUserInvoicingInfoFilled: function onboardSelectUserInvoicingInfoFilled(state) {
    return state.onboardSelectUserInvoicingInfoFilled;
  },
  onboardLoader: function onboardLoader(state) {
    return state.onboardLoader;
  },
  onboardMapSearchValue: function onboardMapSearchValue(state) {
    return state.onboardMapSearchValue;
  },
  onboardConfigTempColor: function onboardConfigTempColor(state) {
    return state.onboardConfigTempColor;
  }
};
export var mutations = {};