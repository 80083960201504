import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VList,
    {
      staticClass: "d-flex flex-column h-percent-100",
      class: { disableNavigation: _vm.disabledNavigation == "1" },
    },
    [
      _c(
        VListItemGroup,
        [
          _c(
            "div",
            { staticClass: "pb-7 pl-8 pt-4 d-flex justify-space-between" },
            [
              _c("img", {
                class:
                  "brand-logo logo-align-header " +
                  (_vm.collapsedNavigation ? "visibility-hidden" : ""),
                attrs: {
                  src: require("../../assets/ag-drive-logo-sidebar.svg"),
                  alt: "AG Drive logo",
                },
              }),
              _c(
                VIcon,
                {
                  class:
                    "collapser cursor-pointer " +
                    (!_vm.collapsedNavigation
                      ? "rotate-180"
                      : "position-absolute"),
                  attrs: { size: "18", color: "white" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleCollapse($event)
                    },
                  },
                },
                [_vm._v(" mdi-chevron-double-right ")]
              ),
            ],
            1
          ),
          _vm.loginUserRole != null
            ? _c("div", [
                _vm.collapsedNavigation
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-center mb-8 role-style avatar-wrapper",
                      },
                      [
                        _c("Avatar", {
                          attrs: { initials: _vm.getLoggedInUserRole() },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "ml-8 mb-8 mt-3 role-style",
                        attrs: { title: _vm.getLoggedInUserRole() },
                      },
                      [_vm._v(" " + _vm._s(_vm.getLoggedInUserRole()) + " ")]
                    ),
              ])
            : _c(VSkeletonLoader, {
                staticClass: "mx-8 mt-4",
                attrs: { dark: "", type: "text" },
              }),
          _vm.loginUserRole != null
            ? _c(
                "div",
                { ref: "sidebarLinksRef", staticClass: "position-relative" },
                [
                  _vm._l(_vm.visibleRoutes, function (route, index) {
                    return _c(
                      "SidebarLink",
                      _vm._b(
                        {
                          key: index,
                          nativeOn: {
                            click: function ($event) {
                              return _vm.updateActiveRouteIndex(index)
                            },
                          },
                        },
                        "SidebarLink",
                        route,
                        false
                      )
                    )
                  }),
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _vm.isSuperAdminLoggedIn
                        ? _c(
                            "SidebarLink",
                            _vm._b(
                              {
                                key: _vm.visibleSuperAdminRoutes[0].title,
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.updateSuperAdminActiveRouteIndex(
                                      0
                                    )
                                  },
                                },
                              },
                              "SidebarLink",
                              _vm.visibleSuperAdminRoutes[0],
                              false
                            )
                          )
                        : _vm._e(),
                      _vm.isSuperAdminLoggedIn
                        ? _c(
                            "div",
                            { staticClass: "su-expand-arrow" },
                            [
                              _vm.showSuperAdminSubMenu
                                ? _c(
                                    VIcon,
                                    { attrs: { size: "18", color: "white" } },
                                    [_vm._v(" mdi-chevron-up ")]
                                  )
                                : _c(
                                    VIcon,
                                    { attrs: { size: "18", color: "white" } },
                                    [_vm._v(" mdi-chevron-down ")]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.showSuperAdminSubMenu && _vm.isSuperAdminLoggedIn
                    ? _c(
                        "SidebarLink",
                        _vm._b(
                          {
                            key: _vm.visibleSuperAdminRoutes[1].title,
                            staticClass: "su-routes",
                            attrs: { isChildMenu: true },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.updateSuperAdminActiveRouteIndex(1)
                              },
                            },
                          },
                          "SidebarLink",
                          _vm.visibleSuperAdminRoutes[1],
                          false
                        )
                      )
                    : _vm._e(),
                  _vm.showSuperAdminSubMenu && _vm.isSuperAdminLoggedIn
                    ? _c(
                        "SidebarLink",
                        _vm._b(
                          {
                            key: _vm.visibleSuperAdminRoutes[2].title,
                            staticClass: "su-routes",
                            attrs: { isChildMenu: true },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.updateSuperAdminActiveRouteIndex(2)
                              },
                            },
                          },
                          "SidebarLink",
                          _vm.visibleSuperAdminRoutes[2],
                          false
                        )
                      )
                    : _vm._e(),
                  _vm.showSuperAdminSubMenu && _vm.isSuperAdminLoggedIn
                    ? _c(
                        "SidebarLink",
                        _vm._b(
                          {
                            key: _vm.visibleSuperAdminRoutes[3].title,
                            staticClass: "su-routes",
                            attrs: { isChildMenu: true },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.updateSuperAdminActiveRouteIndex(3)
                              },
                            },
                          },
                          "SidebarLink",
                          _vm.visibleSuperAdminRoutes[3],
                          false
                        )
                      )
                    : _vm._e(),
                  _c("div", {
                    class:
                      "" +
                      (_vm.disabledNavigation == "1" ||
                      _vm.activeRouteIndex == -1
                        ? ""
                        : "active-link__background"),
                    style: "top: " + _vm.activeRouteIndex * 56 + "px",
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.loginUserRole == null
            ? _c(
                "div",
                { staticClass: "mx-8 my-8 d-flex flex-column" },
                _vm._l(12, function (index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      staticStyle: { margin: "15px 0" },
                    },
                    [
                      _c(VSkeletonLoader, {
                        staticClass: "mt-1",
                        attrs: {
                          type: "image",
                          width: "8%",
                          height: "15",
                          dark: "",
                        },
                      }),
                      _c(VSkeletonLoader, {
                        staticClass: "my-auto ml-7",
                        staticStyle: { padding: "6px 0 0" },
                        attrs: { type: "text", dark: "", width: "50%" },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }