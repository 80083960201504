var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "overlay" } }),
    _c("div", { staticClass: "px-9 pt-8" }, [
      _c(
        "div",
        { staticClass: "ib su-st-wrapper" },
        [_c("SuOverviewCurrentStatistics")],
        1
      ),
      _c(
        "div",
        { staticClass: "ib su-st-wrapper" },
        [_c("SuOverviewOverallStatistics")],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "ib su-st-wrapper" },
        [
          _c("div", { staticClass: "fw-6 pb-6 fn-lg" }, [
            _vm._v("Invoice distribution"),
          ]),
          _c("SuOverviewInvoiceDistribution"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ib su-st-wrapper" },
        [
          _c("div", { staticClass: "fw-6 fn-lg su-pd-dn" }, [
            _vm._v("Countries distribution"),
          ]),
          _c("SuOverviewCountryDistribution"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-9 mb-7" }, [
      _c("hr", { staticClass: "su-ov-separator" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }