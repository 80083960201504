var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseButton", {
    attrs: {
      textValue: _vm.textValue,
      outlined: _vm.outlined,
      color: _vm.color,
      disabled: _vm.disabled,
      loading: _vm.loading,
      variant: _vm.variant,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "left-icon",
          fn: function () {
            return [_vm._t("left-icon")]
          },
          proxy: true,
        },
        {
          key: "right-icon",
          fn: function () {
            return [_vm._t("right-icon")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }