var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "su-db-box-wrapper su-db-box-wrapper-right su-db-box-minh",
      },
      [
        _vm._m(0),
        _c("div", [
          _c(
            "div",
            {
              staticClass: "ib su-db-container-left",
              staticStyle: { width: "70%", "vertical-align": "top" },
            },
            [
              _c("div", { staticClass: "su-db-title pb-8 pt-3" }, [
                _vm._v(" Businesses "),
              ]),
              _c("div", [
                !_vm.adminOverallOverviewLoader
                  ? _c(
                      "div",
                      [
                        _c("SuDashboardCurrentStatisticsCounter", {
                          attrs: {
                            counterTitle: "Total",
                            counterValue:
                              _vm.adminOverviewOverallStatistics
                                .totalBusinesses,
                            counterSummary:
                              _vm.adminOverviewOverallStatistics
                                .averageTeamMembersPerBusiness +
                              " average members per business",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [_c("StatsLoader", { attrs: { loaderRows: 2 } })],
                      1
                    ),
              ]),
              _c("div", { staticClass: "pt-7" }, [
                _c("div", [
                  !_vm.adminOverallOverviewLoader
                    ? _c(
                        "div",
                        [
                          _c("SuDashboardCurrentStatisticsCounter", {
                            attrs: {
                              counterTitle: "Active or Trialing",
                              counterValue:
                                _vm.adminOverviewOverallStatistics
                                  .totalActiveBusinesses,
                              counterSummary:
                                _vm.currencySymbol +
                                _vm.adminOverviewOverallStatistics
                                  .averageSubscriptionInvoiceAmountPerBusiness +
                                " average subscriptions amount per business",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [_c("StatsLoader", { attrs: { loaderRows: 2 } })],
                        1
                      ),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "ib su-db-container-right",
              staticStyle: { width: "30%", "vertical-align": "top" },
            },
            [
              _c("div", { staticClass: "su-db-title pb-8 pt-3" }, [
                _vm._v(" Users "),
              ]),
              _c("div", [
                _c("div", { staticClass: "ib" }, [
                  _c("div", [
                    !_vm.adminOverallOverviewLoader
                      ? _c(
                          "div",
                          [
                            _c("SuDashboardCurrentStatisticsCounter", {
                              attrs: {
                                counterTitle: "Total",
                                counterValue:
                                  _vm.adminOverviewOverallStatistics.totalUsers,
                                counterSummary: null,
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [_c("StatsLoader", { attrs: { loaderRows: 2 } })],
                          1
                        ),
                  ]),
                  _c("div", { staticClass: "pt-7" }, [
                    !_vm.adminOverallOverviewLoader
                      ? _c(
                          "div",
                          [
                            _c("SuDashboardCurrentStatisticsCounter", {
                              attrs: {
                                counterTitle: "Active",
                                counterValue:
                                  _vm.adminOverviewOverallStatistics
                                    .totalActiveUsers,
                                counterSummary: null,
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [_c("StatsLoader", { attrs: { loaderRows: 2 } })],
                          1
                        ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "su-db-header" }, [
      _c("div", { staticClass: "ib" }, [_vm._v(" Overall statistics ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }