import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/esnext.iterator.map.js";
import { mapMutations } from "vuex";
import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import { closeOverlay } from "@/utils/helpers";
import operationsComputedMixin from "@/components/operations/operations-computed";
import { HeaderPrimary, SubHeaderPrimary, DescriptionPrimary } from "@/components/shared/headers/index";
import { BaseButton as Button, ButtonCloseIcon } from "@/components/shared/buttons/index";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
export default {
  components: {
    SubHeaderPrimary: SubHeaderPrimary,
    DescriptionPrimary: DescriptionPrimary,
    ButtonCloseIcon: ButtonCloseIcon,
    HeaderPrimary: HeaderPrimary,
    Button: Button,
    ListLoader: ListLoader
  },
  data: function data() {
    return {
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      fieldsInvalid: false,
      operationTitle: null,
      operationDescription: null,
      operationSelected: false
    };
  },
  mixins: [operationsComputedMixin],
  mounted: function mounted() {
    this.setClearAddOperationTypesList();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    setClearOperationTypesList: "setClearOperationTypesList",
    setClearAddOperationTypesList: "setClearAddOperationTypesList"
  })), {}, {
    showAddOperation: function showAddOperation() {
      this.operationTitle = null;
      this.operationDescription = null;
      this.operationSelected = false;
      this.showAddOperationFields = true;
      this.showAddOperationBtn = false;
    },
    hideAddOperation: function hideAddOperation() {
      this.showAddOperationFields = false;
      this.showAddOperationBtn = true;
    },
    addNewOperationType: function addNewOperationType() {
      this.addOperationTypesList.push({
        title: this.operationTitle,
        isActive: true,
        isSelected: true
      });
      this.operationTitle = null;
      this.operationDescription = null;
      this.hideAddOperation();
    },
    saveOperations: function saveOperations() {
      var _this = this;
      if (this.addOperationTypesList.length != 0 || this.requestOperationTypesList.length != 0) {
        this.addOperationTypesList.map(function (oprType) {
          _this.requestOperationTypesList.push({
            title: oprType.title,
            isActive: oprType.isActive,
            isSelected: oprType.isSelected
          });
        });
        var requestTypes = [];
        this.requestOperationTypesList.map(function (oprType) {
          if (oprType.isActive) {
            requestTypes.push({
              title: oprType.title,
              isSelected: oprType.isSelected
            });
          }
        });
        this.$store.dispatch("saveOperationCategoriesList", requestTypes).then(function () {
          _this.setClearAddOperationTypesList();
          closeOverlay("manageOperationsFormOverlay");
        });
      }
    },
    removeOperationType: function removeOperationType(operationIndex) {
      if (operationIndex > -1) {
        this.addOperationTypesList.splice(operationIndex, 1);
      }
    },
    closeOperationsManageForm: function closeOperationsManageForm() {
      this.setClearAddOperationTypesList();
      closeOverlay("manageOperationsFormOverlay");
      this.$emit('noActiveFlyout');
    },
    getOperationNamesList: function getOperationNamesList(operations) {
      if (operations && operations.length) {
        return operations.map(function (o) {
          return o.title;
        }).join(',');
      }
      return '';
    },
    handleSubOperationActiveToggle: function handleSubOperationActiveToggle(event, operationType) {
      if (!operationType.isSelectable) {
        event.preventDefault();
      } else {
        operationType.isActive = !operationType.isActive;
      }
    }
  })
};