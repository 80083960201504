import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "px-9 py-8",
        staticStyle: { "overflow-y": "scroll", height: "85vh" },
      },
      [
        _c(
          "div",
          { staticClass: "d-flex flex-column" },
          [
            _c(
              VRow,
              { attrs: { "no-gutters": "", justify: "space-between" } },
              [
                _c(VCol, [
                  _c("label", { staticClass: "field-input-label ar-count" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("fieldAreaText")) + "*"),
                  ]),
                ]),
                _c(VCol, { staticClass: "text-right" }, [
                  _vm.fieldDetails.id
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "cursor-pointer d-inline-flex align-center primary--text font-weight-medium",
                          on: {
                            click: function ($event) {
                              return _vm.toggleFieldsEditable()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$stringConstants("hideDetailsText")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _vm.fieldDetails.areaInSquareMeter == null
              ? _c("div", { staticClass: "pt-2 op-8" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pb-2",
                      staticStyle: { color: "#000", "font-weight": "600" },
                    },
                    [
                      _vm._v(
                        " ← " +
                          _vm._s(
                            _vm.$stringConstants("fieldDrawingOnMapText")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              VRow,
              { staticClass: "pt-2", attrs: { cols: "12", "no-gutter": "" } },
              [
                _c(
                  VCol,
                  { staticClass: "pa-0 ma-0", attrs: { cols: "7" } },
                  [
                    _vm.fieldDetails.areaInSquareMeter != null
                      ? _c(VTextField, {
                          attrs: {
                            placeholder:
                              _vm.$stringConstants("areaPlaceholder"),
                            color: _vm.areaInvalid ? "red" : "green",
                            type: "number",
                            disabled: _vm.fieldReadonly,
                            outlined: "",
                            suffix: _vm.areaUnit,
                            dense: "",
                          },
                          on: {
                            blur: _vm.setManualArea,
                            input: function ($event) {
                              _vm.recalculateButtonVisible = true
                            },
                          },
                          model: {
                            value: _vm.convertedArea,
                            callback: function ($$v) {
                              _vm.convertedArea = $$v
                            },
                            expression: "convertedArea",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { staticClass: "pa-0 ma-0", attrs: { cols: "5" } },
                  [
                    _vm.recalculateButtonVisible
                      ? _c(
                          VTooltip,
                          {
                            attrs: {
                              bottom: "",
                              color: "#000",
                              "content-class": "rounded-0",
                              "max-width": "300px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "cursor-pointer d-inline-block mt-2 ml-5 text-left",
                                              on: {
                                                click: _vm.recalculateAreaOnMap,
                                              },
                                            },
                                            "span",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$stringConstants(
                                                  "fieldsRecalculateArea"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(VIcon, [_vm._v("mdi-reload")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1934408068
                            ),
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants(
                                    "fieldsRecalculateAreaTooltip"
                                  )
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.areaInvalid
              ? _c("div", { staticClass: "error-msg" }, [
                  _vm._v(
                    "* " +
                      _vm._s(_vm.$stringConstants("fieldAreaCannotZeroText"))
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm.loginUserRole == 0 ||
        (_vm.loginUserRole == 2 && _vm.ownerRole == "Contractor")
          ? _c(
              "div",
              [
                _c("div", { staticClass: "field-input-label" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("ownerText")) + "*"),
                ]),
                _c(VAutocomplete, {
                  staticClass: "pb-2",
                  attrs: {
                    id: "fieldOwnerList",
                    items: _vm.fieldOwnerList,
                    "search-input": _vm.fieldFarmerSearchTextInternal,
                    "auto-select-first": false,
                    autofocus: false,
                    clearable: "",
                    "item-text": "firstName",
                    loading: _vm.fieldsCustomersFilterDropdownLoader,
                    "item-value": "id",
                    placeholder: _vm.fieldsCustomersFilterDropdownLoader
                      ? "Loading..."
                      : _vm.$stringConstants("selectOwnerPlaceholder"),
                    "hide-no-data": "",
                    outlined: "",
                    attach: "",
                    dense: "",
                    disabled: _vm.fieldReadonly,
                  },
                  on: {
                    "update:searchInput": function ($event) {
                      _vm.fieldFarmerSearchTextInternal = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.fieldFarmerSearchTextInternal = $event
                    },
                    focus: _vm.resetPagingAndGetOwnerList,
                    change: function ($event) {
                      return _vm.handleOwnerChange(_vm.fieldDetails.owner)
                    },
                    "click:clear": _vm.resetPagingAndGetOwnerList,
                  },
                  nativeOn: {
                    input: function ($event) {
                      return _vm.applyCustomersFilterSearchTimer($event)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c("div", { staticStyle: { width: "100%" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ib",
                                  staticStyle: { width: "40px !important" },
                                },
                                [
                                  _c("CustomerAvatar", {
                                    attrs: {
                                      initials: data.item.firstName,
                                      width: "28px",
                                      height: "28px",
                                      isCircular: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ib",
                                  staticStyle: {
                                    width: "auto",
                                    transform: "translateY(3px)",
                                  },
                                },
                                [
                                  _c(VListItemTitle, {
                                    domProps: {
                                      innerHTML: _vm._s(data.item.firstName),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "append-item",
                        fn: function () {
                          return [
                            _c("LoadMoreItem", {
                              attrs: {
                                searchText: _vm.fieldFarmerSearchTextInternal,
                                loadedListLength: _vm.fieldOwnerList.length,
                                moduleNamespace: _vm.$stringConstants(
                                  "fieldsAddFilterCustomersPaginationModule"
                                ),
                              },
                              on: { loadMoreItems: _vm.appendMoreCustomers },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    236170060
                  ),
                  model: {
                    value: _vm.fieldDetails.owner,
                    callback: function ($$v) {
                      _vm.$set(_vm.fieldDetails, "owner", $$v)
                    },
                    expression: "fieldDetails.owner",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "field-input-label" }, [
          _vm._v(_vm._s(_vm.$stringConstants("fieldNameText")) + "*"),
        ]),
        _c(VTextField, {
          staticClass: "pb-2",
          class: _vm.formFieldPb,
          attrs: {
            placeholder: _vm.$stringConstants("fieldNamePlaceholder"),
            outlined: "",
            dense: "",
            disabled: _vm.fieldReadonly,
          },
          model: {
            value: _vm.fieldDetails.title,
            callback: function ($$v) {
              _vm.$set(
                _vm.fieldDetails,
                "title",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "fieldDetails.title",
          },
        }),
        _c("div", { staticClass: "field-input-label" }, [
          _vm._v(_vm._s(_vm.$stringConstants("ownershipText")) + "*"),
        ]),
        _c(VSelect, {
          staticClass: "pb-2",
          attrs: {
            items: _vm.enumOwnership,
            "item-value": "value",
            "item-text": "title",
            placeholder: _vm.$stringConstants("selectOwnershipPlaceholder"),
            dense: "",
            attach: "",
            outlined: "",
            disabled: _vm.fieldReadonly,
          },
          on: {
            change: function ($event) {
              return _vm.checkOwnerShip(_vm.fieldDetails.ownerShip)
            },
          },
          model: {
            value: _vm.fieldDetails.ownerShip,
            callback: function ($$v) {
              _vm.$set(_vm.fieldDetails, "ownerShip", $$v)
            },
            expression: "fieldDetails.ownerShip",
          },
        }),
        _c("div", { staticClass: "field-input-label" }, [
          _vm._v(
            " " +
              _vm._s(_vm.fieldDetails.fieldYear != null ? "Crop*" : "Crop") +
              " "
          ),
        ]),
        _c(VTextField, {
          staticClass: "pb-2",
          class: _vm.formFieldPb,
          attrs: {
            placeholder: _vm.$stringConstants("cropPlaceholder"),
            outlined: "",
            dense: "",
            disabled: _vm.fieldReadonly,
          },
          model: {
            value: _vm.fieldDetails.name,
            callback: function ($$v) {
              _vm.$set(
                _vm.fieldDetails,
                "name",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "fieldDetails.name",
          },
        }),
        _c("div", { staticClass: "field-input-label" }, [
          _vm._v(
            " " + _vm._s(_vm.fieldDetails.name != null ? "Year*" : "Year") + " "
          ),
        ]),
        _c(VSelect, {
          staticClass: "pb-2",
          attrs: {
            items: _vm.yearList,
            "item-value": "year",
            "item-text": "year",
            clearable: "",
            placeholder: _vm.$stringConstants("yearPlaceholder"),
            dense: "",
            attach: "",
            outlined: "",
            disabled: _vm.fieldReadonly,
          },
          model: {
            value: _vm.fieldDetails.fieldYear,
            callback: function ($$v) {
              _vm.$set(_vm.fieldDetails, "fieldYear", $$v)
            },
            expression: "fieldDetails.fieldYear",
          },
        }),
        _c("div", { staticClass: "field-input-label" }, [
          _vm._v(_vm._s(_vm.$stringConstants("farms"))),
        ]),
        _c(VSelect, {
          staticClass: "pb-2",
          attrs: {
            items: _vm.fieldFarmList,
            "item-value": "id",
            "item-text": "addressLine1",
            placeholder: _vm.$stringConstants("farmPlaceholder"),
            dense: "",
            attach: "",
            clearable: "",
            outlined: "",
            disabled: _vm.fieldReadonly,
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function (data) {
                return [
                  _c(
                    VListItemContent,
                    [
                      _c(VListItemTitle, {
                        domProps: { innerHTML: _vm._s(data.item.addressLine1) },
                      }),
                      _c(VListItemTitle, [
                        _vm._v(
                          " " +
                            _vm._s(data.item.addressLine2) +
                            _vm._s(
                              data.item.city ? ", " + data.item.city : ""
                            ) +
                            " " +
                            _vm._s(
                              data.item.pinCode ? ", " + data.item.pinCode : ""
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.fieldDetails.addressId,
            callback: function ($$v) {
              _vm.$set(_vm.fieldDetails, "addressId", $$v)
            },
            expression: "fieldDetails.addressId",
          },
        }),
        _c("div", { staticClass: "field-input-label" }, [
          _vm._v(_vm._s(_vm.$stringConstants("noteText"))),
        ]),
        _c(VTextarea, {
          attrs: {
            outlined: "",
            placeholder: _vm.$stringConstants("notePlaceholder"),
            height: "100",
            disabled: _vm.fieldReadonly,
          },
          model: {
            value: _vm.fieldDetails.note,
            callback: function ($$v) {
              _vm.$set(_vm.fieldDetails, "note", $$v)
            },
            expression: "fieldDetails.note",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btns-bottom-box px-9 py-4" },
      [
        _c(
          VRow,
          { attrs: { "no-gutters": "" } },
          [
            _vm.userIsEditingField
              ? _c(
                  VCol,
                  { attrs: { cols: "6 pr-2" } },
                  [
                    _vm.fieldDetails.id
                      ? _c("Button", {
                          attrs: {
                            variant: "primary",
                            color: "primary",
                            outlined: "",
                            outlineColor: "primary",
                            textValue: _vm.$stringConstants("cancel"),
                            loading: _vm.addFieldLoader,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.cancelDrawerAction($event)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              VCol,
              { attrs: { cols: _vm.userIsEditingField ? "6 pl-2" : "12" } },
              [
                _c("Button", {
                  class: { disabled: _vm.isDisabledBtn },
                  attrs: {
                    variant: "primary",
                    color: "primary",
                    disabled: _vm.addFieldLoader || _vm.isDisabledBtn,
                    loading: _vm.addFieldLoader,
                    textValue: _vm.fieldDrawerActionString,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.fieldDrawerAction($event)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }