import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { state as _state, getters, mutations } from "./mutations";
import { actions } from "./actions";
export default {
  state: function state() {
    return _objectSpread({}, _state);
  },
  mutations: mutations,
  getters: getters,
  actions: actions,
  namespaced: true
};