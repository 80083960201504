var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "200",
        height: "30",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:svg": "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { staticClass: "layer" }, [
        _c("title", [_vm._v("Field")]),
        _c("path", {
          attrs: {
            d: "m1.88501,27.955l0,-26l195.00001,0l0,26l-195.00001,0z",
            fill: _vm.jobsMapLabelColorBackground,
            id: "svg_3",
            stroke: "#ffffff",
          },
        }),
        _c(
          "text",
          {
            attrs: {
              fill: "#ffffff",
              "font-family": "Helvetica",
              "font-size": "14",
              id: "svg_2",
              stroke: "#000000",
              "stroke-width": "0",
              "text-anchor": "middle",
              transform: "matrix(0.9827 0 0 1 3.23004 0)",
              x: "98.9824",
              "xml:space": "preserve",
              y: "21",
            },
          },
          [_vm._v(" " + _vm._s(_vm.fieldName) + " ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }