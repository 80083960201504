import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-8 px-9 d-flex flex-column field-details" },
    [
      _c("div", { staticClass: "pb-9" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-space-between font-weight-medium" },
          [
            _c(
              "span",
              {
                staticClass:
                  "d-inline-block primary--text tertiary px-2 py-1 rounded f-12",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.convertedArea) +
                    " " +
                    _vm._s(_vm.areaUnit) +
                    " "
                ),
              ]
            ),
            !_vm.fieldReadonly
              ? _c("div", [
                  _c(
                    "span",
                    {
                      staticClass:
                        "f-14 cursor-pointer d-inline-flex align-center danger--text font-weight-500 mr-8",
                      on: { click: _vm.handleDelete },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("listItemDelete")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "f-14 cursor-pointer d-inline-flex align-center primary--text font-weight-500",
                      on: { click: _vm.toggleFieldsEditable },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$stringConstants("listItemEdit")) + " "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _vm.fieldDetails.address
          ? _c(
              "div",
              { staticClass: "f-14 d-flex align-bottom pt-6" },
              [
                _c(VIcon, { attrs: { small: "" } }, [
                  _vm._v("mdi-map-marker"),
                ]),
                _c("span", { staticClass: "pl-2" }, [
                  _vm._v(_vm._s(_vm.getFieldAddress())),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "f-14 d-flex align-bottom pt-5" }, [
          _c("img", {
            attrs: { src: require("../../assets/icon-customers.svg") },
          }),
          _c("span", { staticClass: "pl-2" }, [
            _vm._v(_vm._s(_vm.getOwnerInfo())),
          ]),
        ]),
        _vm.fieldNote
          ? _c("div", { staticClass: "f-14 d-flex align-start pt-5" }, [
              _c("img", {
                staticClass: "pt-1",
                attrs: { src: require("../../assets/icon-note.svg") },
              }),
              _c("span", { staticClass: "pl-2" }, [
                _vm._v(_vm._s(_vm.fieldNote)),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "pt-9" },
        [
          _c(
            "div",
            {
              staticClass:
                "pb-2 d-flex justify-space-between align-center font-weight-medium",
            },
            [
              _c("span", { staticClass: "f-16" }, [
                _vm._v(_vm._s(_vm.$stringConstants("fieldCropHistoryText"))),
              ]),
              !_vm.showAddCropHistoryDialog && !_vm.fieldReadonly
                ? _c(
                    "span",
                    {
                      staticClass:
                        "f-14 primary--text d-flex align-center cursor-pointer",
                      on: {
                        click: function ($event) {
                          _vm.showAddCropHistoryDialog =
                            !_vm.showAddCropHistoryDialog
                        },
                      },
                    },
                    [
                      _c(
                        VIcon,
                        { staticClass: "primary--text", attrs: { size: "13" } },
                        [_vm._v("mdi-plus")]
                      ),
                      _vm._v("Add "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showAddCropHistoryDialog
                ? _c(
                    "span",
                    {
                      staticClass:
                        "primary--text d-flex align-center cursor-pointer",
                      on: {
                        click: function ($event) {
                          _vm.showAddCropHistoryDialog =
                            !_vm.showAddCropHistoryDialog
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  )
                : _vm._e(),
            ]
          ),
          _vm.showAddCropHistoryDialog
            ? _c(
                "div",
                { staticClass: "fld-crp-add-cont" },
                [
                  _c("div", { staticClass: "pt-4 pb-4" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$stringConstants("fieldAddCropHistoryText")
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "field-input-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$stringConstants("fieldCropNameText")) +
                        " "
                    ),
                  ]),
                  _c(VTextField, {
                    attrs: {
                      placeholder: _vm.$stringConstants("cropPlaceholder"),
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.fieldCropNameToAdd,
                      callback: function ($$v) {
                        _vm.fieldCropNameToAdd = $$v
                      },
                      expression: "fieldCropNameToAdd",
                    },
                  }),
                  _c(
                    "div",
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$stringConstants("fieldSelectYearText")
                            ) +
                            " "
                        ),
                      ]),
                      _c(VSelect, {
                        staticClass: "pb-2 pl-0 ml-0",
                        attrs: {
                          placeholder: _vm.$stringConstants("yearPlaceholder"),
                          items: _vm.yearList,
                          "item-value": "year",
                          "item-text": "year",
                          dense: "",
                          attach: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.fieldCropYearToAdd,
                          callback: function ($$v) {
                            _vm.fieldCropYearToAdd = $$v
                          },
                          expression: "fieldCropYearToAdd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "text-right",
                      staticStyle: { transform: "translateY(-10px)" },
                    },
                    [
                      _c("Button", {
                        attrs: {
                          variant: "tertiary",
                          color: "primary",
                          textValue: "Add",
                          width: 100,
                          disabled:
                            !_vm.fieldCropYearToAdd || !_vm.fieldCropYearToAdd,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.addNewCrop()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "pb-8" }, [
            _c("span", { staticClass: "f-14 grey--text" }, [
              _vm._v(
                _vm._s(_vm.$stringConstants("fieldManageCropHistoryText"))
              ),
            ]),
          ]),
          _vm.fieldDetails.cropHistory.length > 0
            ? _c(VSimpleTable, {
                staticClass: "rounded-0 border-light-gray",
                staticStyle: { "max-height": "190px", "overflow-y": "scroll" },
                attrs: { light: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c("tr", { staticClass: "list-heading" }, [
                              _c("th", { staticClass: "text-left px-6" }, [
                                _vm._v(
                                  _vm._s(_vm.$stringConstants("yearText"))
                                ),
                              ]),
                              _c("th", { staticClass: "text-left px-6" }, [
                                _vm._v(
                                  _vm._s(_vm.$stringConstants("cropText"))
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.fieldDetails.cropHistory,
                              function (crop, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "f-13 px-6" }, [
                                    _vm._v(_vm._s(crop.year)),
                                  ]),
                                  _c("td", { staticClass: "f-13 px-6" }, [
                                    _vm._v(_vm._s(crop.name)),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4145141423
                ),
              })
            : _c("div", { staticClass: "text-center" }, [
                _c("span", { staticClass: "f-11" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("fieldNoCropAddedText"))),
                ]),
              ]),
        ],
        1
      ),
      _vm._m(1),
      _vm.fieldOwnerList && _vm.fieldDetails
        ? _c("div", { staticClass: "pt-9" }, [
            _c(
              "div",
              {
                staticClass:
                  "pb-2 d-flex justify-space-between align-center font-weight-medium",
              },
              [
                _c("span", { staticClass: "f-16" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("jobHistory"))),
                ]),
                _vm.fieldInProgressJobs.length > 4 && !_vm.showAllJobs
                  ? _c(
                      "span",
                      {
                        staticClass: "f-14 primary--text cursor-pointer",
                        on: { click: _vm.showJobsList },
                      },
                      [_vm._v(_vm._s(_vm.$stringConstants("seeAll")))]
                    )
                  : _vm._e(),
              ]
            ),
            _c("div", { staticClass: "pb-8" }, [
              _c("span", { staticClass: "f-14 grey--text" }, [
                _vm._v(
                  _vm._s(_vm.$stringConstants("fieldManageJobForFieldText"))
                ),
              ]),
            ]),
            _vm.fieldInProgressJobs.length > 0
              ? _c(
                  "div",
                  { staticClass: "ongoing-jobs-list" },
                  _vm._l(_vm.jobsList, function (job, index) {
                    return _c(
                      VRow,
                      {
                        key: index,
                        staticClass:
                          "border-light-gray mb-5 rounded-0 flex-column",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-5 py-4 cursor-pointer no-decoration",
                            attrs: { to: _vm.routeTeamJobsDetails + job.id },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between align-center",
                              },
                              [
                                _c("span", { staticClass: "f-12 grey--text" }, [
                                  _vm._v(_vm._s(job.number)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c("span", { staticClass: "f-12 mr-3" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getJobOwnerName(job)) +
                                          " "
                                      ),
                                    ]),
                                    _c("CustomerAvatar", {
                                      attrs: {
                                        isCircular: true,
                                        width: "28px",
                                        height: "28px",
                                        initials: _vm.getJobOwnerName(job),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f-15 font-weight-medium mb-4 pt-2",
                              },
                              [_vm._v(" " + _vm._s(job.operation.title) + " ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "f-12 d-flex justify-space-between align-center pale_gray px-5 py-2",
                          },
                          [
                            _c("span", { staticClass: "font-weight-light" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getLocalDate(job.scheduledAt)) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-inline-flex align-center quaternary--text font-weight-medium",
                              },
                              [
                                _c("span", { attrs: { cols: "1" } }, [
                                  _c("span", [
                                    job.status == _vm.TeamJobStatusType.Pending
                                      ? _c("span", { staticClass: "j-pen" }, [
                                          _vm._v("Pending"),
                                        ])
                                      : _vm._e(),
                                    job.status == _vm.TeamJobStatusType.Ready
                                      ? _c("span", { staticClass: "j-fns" }, [
                                          _vm._v("Scheduled"),
                                        ])
                                      : _vm._e(),
                                    job.status ==
                                    _vm.TeamJobStatusType.InProgress
                                      ? _c("span", { staticClass: "j-pro" }, [
                                          _vm._v("In progress"),
                                        ])
                                      : _vm._e(),
                                    job.status ==
                                    _vm.TeamJobStatusType.AwaitingCompletion
                                      ? _c("span", { staticClass: "j-fns" }, [
                                          _vm._v("Awaiting Completion"),
                                        ])
                                      : _vm._e(),
                                    job.status ==
                                    _vm.TeamJobStatusType.PartCompleted
                                      ? _c("span", { staticClass: "j-pen" }, [
                                          _vm._v("Part completed"),
                                        ])
                                      : _vm._e(),
                                    job.status ==
                                    _vm.TeamJobStatusType.Completed
                                      ? _c("span", { staticClass: "j-fns" }, [
                                          _vm._v("Completed"),
                                        ])
                                      : _vm._e(),
                                    job.status ==
                                    _vm.TeamJobStatusType.Cancelled
                                      ? _c("span", { staticClass: "j-fns" }, [
                                          _vm._v("Cancelled"),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c(
                                  VIcon,
                                  {
                                    staticClass: "ml-2 quaternary--text",
                                    attrs: { small: "" },
                                  },
                                  [_vm._v("mdi-dots-horizontal-circle")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c("div", { staticClass: "text-center" }, [
                  _c("span", { staticClass: "f-11" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("fieldNoJobFoundText"))),
                  ]),
                ]),
          ])
        : _vm._e(),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogField },
        on: {
          cancel: function ($event) {
            _vm.dialogField = false
          },
          proceed: _vm.proceedToDelete,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("fieldDeletePromtTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("deleteFieldWaring")) + " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "hr-gray" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "hr-gray" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }