import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { formFieldPb, brandColor } from '@/utils/uiconstants';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
var computedProperties = {
  'onboardModule': ['onboardingStates', 'onboardingStateSelected', 'onboardSelectUserVerified', 'onboardSelectUserBusinessInfoFilled', 'onboardSelectUserInvoicingInfoFilled', 'onboardLoader', 'onboardMapSearchValue', 'onboardConfigTempColor'],
  'usersModule': ['userProfile', 'businessProfilePrefixTemp', 'startingLat', 'startingLng', 'usersLoader', 'shouldCallOnboardUpdate'],
  'root': ['areaMeasurementUnitsList', 'hasBusinessConfig', 'globalCurrenciesList', 'globalTimeZonesList', 'globalTaxesNameList', 'globalCountriesList']
};
export default {
  name: 'OnboardComputedMixin',
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      startTimeMenu: false,
      endTimeMenu: false,
      time: null,
      brandColor: brandColor
    };
  },
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    loginOwnerIsContractor: function loginOwnerIsContractor() {
      return getLoggedInUserRole().isUserOwnerContractorLoggedIn;
    }
  })
};