import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import moment from 'moment';
var _generateRandomHexColorString = function generateRandomHexColorString() {
  var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
  if (randomColor.length != 7) {
    return _generateRandomHexColorString();
  } else {
    return randomColor;
  }
};
export { _generateRandomHexColorString as generateRandomHexColorString };
export var generateRandomFilename = function generateRandomFilename() {
  var userId = localStorage.getItem('userId');
  var currentTimestamp = moment(new Date()).format('MM_DD_YYYY_h_mm_ss');
  var randomNumber = Math.floor(Math.random() * 1000000);
  return userId + '_' + currentTimestamp + '_' + randomNumber;
};