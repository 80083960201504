import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ob-ht" }, [
      _c("div", { staticClass: "ob-container" }, [
        _c("div", { staticClass: "ob-tabs" }, [
          _c("div", { staticClass: "pt-5 position-relative" }, [
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "0px",
                    top: "9px",
                  },
                },
                [
                  _c(
                    VMenu,
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "nav-user-nm txt-wrp",
                                  attrs: { title: _vm.loginUserName },
                                },
                                [_vm._v(" " + _vm._s(_vm.loginUserName) + " ")]
                              ),
                              _c(
                                VBtn,
                                _vm._g(
                                  _vm._b(
                                    { staticClass: "btn-prf" },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/profile.svg"),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        VList,
                        [
                          _c(
                            VListItem,
                            { on: { click: _vm.logoutClick } },
                            [
                              _c(
                                VListItemTitle,
                                { staticClass: "pr-16" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$stringConstants("logoutText"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.onboardingStateSelected == _vm.onboardingStates.userNotVerified
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectVerifyUserTab },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.configStepsText.userVerified) + " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectBusinessInfoTab },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.configStepsText.businessInfo) + " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectLocationInfoTab },
                    },
                    [_vm._v(" " + _vm._s(_vm.configStepsText.config) + " ")]
                  ),
                  _vm.loginOwnerIsContractor
                    ? _c(
                        "div",
                        {
                          staticClass: "ib ob-tabs-text",
                          on: { click: _vm.selectInvoicingInfoTab },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configStepsText.invoicing) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.onboardingStateSelected == _vm.onboardingStates.userVerified
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectVerifyUserTab },
                    },
                    [
                      _vm._m(0),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: { transform: "translate(6px, -3px)" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configStepsText.userVerified) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text fw-7",
                      on: { click: _vm.selectBusinessInfoTab },
                    },
                    [
                      _vm._m(1),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: { transform: "translate(6px, -3px)" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configStepsText.businessInfo) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectLocationInfoTab },
                    },
                    [
                      _vm._m(2),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: { transform: "translate(6px, -3px)" },
                        },
                        [_vm._v(" " + _vm._s(_vm.configStepsText.config) + " ")]
                      ),
                    ]
                  ),
                  _vm.loginOwnerIsContractor
                    ? _c(
                        "div",
                        {
                          staticClass: "ib ob-tabs-text",
                          on: { click: _vm.selectInvoicingInfoTab },
                        },
                        [
                          _vm._m(3),
                          _c(
                            "div",
                            {
                              staticClass: "ib",
                              staticStyle: {
                                transform: "translate(6px, -3px)",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.configStepsText.invoicing) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.onboardingStateSelected ==
            _vm.onboardingStates.userBusinessInfoFilled
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectVerifyUserTab },
                    },
                    [
                      _vm._m(4),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: { transform: "translate(6px, -3px)" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configStepsText.userVerified) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectBusinessInfoTab },
                    },
                    [
                      _vm._m(5),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: { transform: "translate(6px, -3px)" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configStepsText.businessInfo) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text fw-7",
                      on: { click: _vm.selectLocationInfoTab },
                    },
                    [
                      _vm._m(6),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: { transform: "translate(6px, -3px)" },
                        },
                        [_vm._v(" " + _vm._s(_vm.configStepsText.config) + " ")]
                      ),
                    ]
                  ),
                  _vm.loginOwnerIsContractor
                    ? _c(
                        "div",
                        {
                          staticClass: "ib ob-tabs-text",
                          on: { click: _vm.selectInvoicingInfoTab },
                        },
                        [
                          _vm._m(7),
                          _c(
                            "div",
                            {
                              staticClass: "ib",
                              staticStyle: {
                                transform: "translate(6px, -3px)",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.configStepsText.invoicing) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.onboardingStateSelected ==
            _vm.onboardingStates.userInvoicingInfoFilled
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectVerifyUserTab },
                    },
                    [
                      _vm._m(8),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: { transform: "translate(6px, -3px)" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configStepsText.userVerified) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectBusinessInfoTab },
                    },
                    [
                      _vm._m(9),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: { transform: "translate(6px, -3px)" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configStepsText.businessInfo) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ib ob-tabs-text",
                      on: { click: _vm.selectLocationInfoTab },
                    },
                    [
                      _vm._m(10),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: { transform: "translate(6px, -3px)" },
                        },
                        [_vm._v(" " + _vm._s(_vm.configStepsText.config) + " ")]
                      ),
                    ]
                  ),
                  _vm.loginOwnerIsContractor
                    ? _c(
                        "div",
                        {
                          staticClass: "ib ob-tabs-text fw-7",
                          on: { click: _vm.selectInvoicingInfoTab },
                        },
                        [
                          _vm._m(11),
                          _c(
                            "div",
                            {
                              staticClass: "ib",
                              staticStyle: {
                                transform: "translate(6px, -3px)",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.configStepsText.invoicing) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "ob-content" }, [
          _c("div", { staticStyle: { "min-height": "85vh" } }, [
            _vm.onboardingStateSelected == _vm.onboardingStates.userNotVerified
              ? _c("div", [_c("OnboardBusinessProfile")], 1)
              : _vm._e(),
            _vm.onboardingStateSelected == _vm.onboardingStates.userVerified
              ? _c("div", [_c("OnboardBusinessProfile")], 1)
              : _vm._e(),
            _vm.onboardingStateSelected ==
            _vm.onboardingStates.userBusinessInfoFilled
              ? _c("div", [_c("OnBoardLocationInfo")], 1)
              : _vm._e(),
            _vm.onboardingStateSelected ==
              _vm.onboardingStates.userInvoicingInfoFilled &&
            _vm.loginOwnerIsContractor
              ? _c("div", [_c("OnBoardInvoicingInfo")], 1)
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "ob-actions" }, [
          _c(
            "div",
            { staticStyle: { "max-width": "1320px", margin: "0 auto" } },
            [
              _c("div", {
                staticClass: "ib",
                staticStyle: { width: "30%", "vertical-align": "top" },
              }),
              _c(
                "div",
                {
                  staticClass: "ib",
                  staticStyle: { width: "70%", "vertical-align": "top" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-top": "12px",
                        float: "right",
                        padding: "1em 6em",
                      },
                    },
                    [
                      _vm.onboardingStateSelected ==
                      _vm.onboardingStates.userVerified
                        ? _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              _c("ButtonActionPrimary", {
                                attrs: {
                                  width: "205px",
                                  height: 50,
                                  disabled:
                                    _vm.isBusinesProfileFieldsInvalid ||
                                    _vm.onboardLoader ||
                                    _vm.invalidShortCodeLength,
                                  loading: _vm.onboardLoader,
                                  textValue: "Next",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.saveUserBusinessProfile()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.onboardingStateSelected ==
                      _vm.onboardingStates.userBusinessInfoFilled
                        ? _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              _c("ButtonActionPrimary", {
                                attrs: {
                                  width: "205px",
                                  height: 50,
                                  disabled:
                                    _vm.onboardLoader ||
                                    _vm.usersLoader ||
                                    _vm.invalidConfig,
                                  loading: _vm.onboardLoader || _vm.usersLoader,
                                  textValue: "Next",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.saveOnboardPreferences()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.onboardingStateSelected ==
                      _vm.onboardingStates.userInvoicingInfoFilled
                        ? _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              _c("ButtonActionPrimary", {
                                attrs: {
                                  width: "205px",
                                  height: 50,
                                  disabled:
                                    _vm.onboardLoader ||
                                    _vm.usersLoader ||
                                    _vm.invalidConfig,
                                  loading: _vm.onboardLoader || _vm.usersLoader,
                                  textValue: "Finish",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.enableDashboardAccess()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", { attrs: { src: require("../../assets/done-icon.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", {
        attrs: { src: require("../../assets/inprogress-icon.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", {
        attrs: { src: require("../../assets/inprogress-icon.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", {
        attrs: { src: require("../../assets/inprogress-icon.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", { attrs: { src: require("../../assets/done-icon.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", { attrs: { src: require("../../assets/done-icon.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", {
        attrs: { src: require("../../assets/inprogress-icon.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", {
        attrs: { src: require("../../assets/inprogress-icon.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", { attrs: { src: require("../../assets/done-icon.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", { attrs: { src: require("../../assets/done-icon.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", { attrs: { src: require("../../assets/done-icon.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ib" }, [
      _c("img", {
        attrs: { src: require("../../assets/inprogress-icon.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }