import { SubHeaderPrimary } from "@/components/shared/headers/index";
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsAddressesFlyoutAddressInstructions from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-addresses-instructions.vue';
export default {
  name: "TeamJobsFarmAddresses",
  mixins: [TeamJobsComputed],
  components: {
    SubHeaderPrimary: SubHeaderPrimary,
    TeamJobsAddressesFlyoutAddressInstructions: TeamJobsAddressesFlyoutAddressInstructions
  }
};