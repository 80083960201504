var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "400",
        height: "30",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:svg": "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { staticClass: "layer" }, [
        _c("title", [_vm._v("Field")]),
        _c(
          "text",
          {
            staticClass: "pltext",
            staticStyle: {
              "font-family": "Arial, Helvetica, sans-serif",
              "font-weight": "300",
              "font-size": "12px",
            },
            attrs: {
              fill: "#ffffff",
              "text-anchor": "middle",
              transform: "matrix(0.9827 0 0 1 3.23004 0)",
              x: "195.9824",
              "xml:space": "preserve",
              y: "21",
            },
          },
          [_vm._v(_vm._s(_vm.fieldName))]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }