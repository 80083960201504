import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-8" },
    [
      _c("div", { staticClass: "pb-10" }, [
        _c("div", { staticClass: "tj-cp-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("teamJobNoteAndPhotosTitle")) +
              " "
          ),
        ]),
        _c("div", { staticClass: "tj-cp-subtitle" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("teamJobNoteAndPhotosSubTitle")) +
              " "
          ),
        ]),
      ]),
      _c("VueEditor", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.teamJobsLoader &&
              _vm.teamJobViewPreference.completionInformation.isEditable,
            expression:
              "!teamJobsLoader && teamJobViewPreference.completionInformation.isEditable",
          },
        ],
        staticClass: "mb-4",
        staticStyle: { "border-top": "1px solid #cfcfcf" },
        attrs: { editorToolbar: _vm.customToolbar },
        on: { "text-change": _vm.onChangeNotes, keydown: _vm.onChangeNotes },
        model: {
          value: _vm.tempTeamJobNotes,
          callback: function ($$v) {
            _vm.tempTeamJobNotes = $$v
          },
          expression: "tempTeamJobNotes",
        },
      }),
      !_vm.teamJobViewPreference.completionInformation.isEditable
        ? _c("div", { staticClass: "team-jobs-instructions--readonly" }, [
            _c("div", {
              staticClass: "team-jobs-instructions--min-height",
              domProps: { innerHTML: _vm._s(_vm.tempTeamJobNotes) },
            }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
          staticClass: "jb-fnsh-photos",
          staticStyle: { display: "flex", overflow: "auto" },
        },
        [
          _c(
            "div",
            { staticClass: "pl-4 pt-4", staticStyle: { display: "flex" } },
            [
              _c(
                "div",
                { staticClass: "ib add_job_attach pr-4 position-relative" },
                [
                  _c(VFileInput, {
                    staticClass: "file_input job-attach",
                    attrs: {
                      accept:
                        "image/png, image/jpeg, image/bmp, image/jpg, image/gif, image/webp, application/pdf",
                      "prepend-icon": "mdi-file-upload",
                      disabled:
                        !_vm.teamJobViewPreference.instructionPhotos.isEditable,
                      multiple: "",
                    },
                    on: { change: _vm.previewImage },
                    model: {
                      value: _vm.selectedImages,
                      callback: function ($$v) {
                        _vm.selectedImages = $$v
                      },
                      expression: "selectedImages",
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.teamJobAttachments, function (img, imgIdx) {
                return _c(
                  "div",
                  { key: imgIdx, staticClass: "ib add_job_attach " },
                  [
                    img.purpose == _vm.JobAttachmentPurposeType.Completion
                      ? _c(
                          "div",
                          {
                            staticClass: "mr-4",
                            staticStyle: {
                              width: "100px",
                              position: "relative",
                            },
                          },
                          [
                            img.type == _vm.JobAttachmentType.Pdf
                              ? _c("TeamJobsAttachmentsPdf", {
                                  attrs: { "file-url": img.url },
                                })
                              : _c(VImg, {
                                  staticClass: "cursor-pointer",
                                  attrs: {
                                    "lazy-src": _vm.base64ImagePlaceholder,
                                    src: img.url,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImage(img.url)
                                    },
                                  },
                                }),
                            _c("div", { staticClass: "jb-img-cls-btn" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "jb-img-cls-btn-crs",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeSelectedImage(img)
                                    },
                                  },
                                },
                                [_vm._v(" - ")]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.teamJobsLoader,
              expression: "teamJobsLoader",
            },
          ],
        },
        [
          _c(VSkeletonLoader, {
            staticClass: "mb-3",
            attrs: { height: "120", type: "card" },
          }),
          _c(VSkeletonLoader, {
            staticClass: "mb-4",
            attrs: { width: "100", height: "100", type: "card" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }