import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { routeTeamJobsDetails } from "@/utils/endpoints";
import moment from "moment";
import invoicesComputedMixin from "@/components/invoices/invoices-computed";
import { HoverTextButton } from "@/components/shared/buttons/index";
import { getDateFormatted as _getDateFormatted, reduceText } from "@/utils/helpers";
import router from '@/router';
import { routeAccount } from '@/utils/endpoints';
import { AccountTabType } from '@/enum/accountTabType';
import ConstantValues from '@/plugins/constantValues';
import CloseButton from '@/components/shared/buttons/button-close.vue';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import PaginationModule from "@/store/modules/pagination";
import LoadMoreItem from "../../shared/load-more-item.vue";
import { mapMutations } from 'vuex';
import { InvoiceStatusType, InvoiceEmailStatusType } from '@/enum/invoiceStatusType';
export default {
  data: function data() {
    return {
      menuDate: false,
      editorData: "",
      searchTimerProductFilter: null,
      isProductInList: false
    };
  },
  mixins: [invoicesComputedMixin],
  components: {
    HoverTextButton: HoverTextButton,
    CloseButton: CloseButton,
    Button: Button,
    LoadMoreItem: LoadMoreItem
  },
  computed: {
    computedDateFormatted: function computedDateFormatted() {
      return this.invoiceDate ? moment(this.invoiceDate).format("DD MMM YY") : "";
    },
    maxCompanyNameLength: function maxCompanyNameLength() {
      return this.$store.getters.maxCompanyNameLength;
    },
    maxAddressLength: function maxAddressLength() {
      return this.$store.getters.maxAddressLength;
    },
    invoiceCurrencySymbol: function invoiceCurrencySymbol() {
      var _a, _b;
      return ((_b = (_a = this.invoiceDraftPreviewDetails) === null || _a === void 0 ? void 0 : _a.currency) === null || _b === void 0 ? void 0 : _b.symbol) || this.currencySymbol;
    },
    validateAddItem: function validateAddItem() {
      if (!this.newLineItemsInitialsForInternal.title) {
        return true;
      } else {
        return false;
      }
    },
    isInvoiceStatusOpen: function isInvoiceStatusOpen() {
      var _a;
      if (!this.invoiceDraftPreviewDetails) return null;
      return ((_a = this.invoiceDraftPreviewDetails) === null || _a === void 0 ? void 0 : _a.status) === InvoiceStatusType.Open;
    },
    invoiceStatus: function invoiceStatus() {
      var _a;
      if (!this.invoiceDraftPreviewDetails) return '';
      return InvoiceStatusType[(_a = this.invoiceDraftPreviewDetails) === null || _a === void 0 ? void 0 : _a.status].toUpperCase();
    },
    invoiceEmailStatus: function invoiceEmailStatus() {
      var _a, _b;
      if (!this.invoiceDraftPreviewDetails) return null;
      return (_b = InvoiceEmailStatusType[(_a = this.invoiceDraftPreviewDetails) === null || _a === void 0 ? void 0 : _a.emailStatus]) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    }
  },
  mounted: function mounted() {
    this.invoiceJobReportUrls = [];
    this.$store.dispatch("getInvoiceConfiguration");
  },
  beforeDestroy: function beforeDestroy() {
    this.$store.unregisterModule(this.$stringConstants('invoicesProductListFilter'));
  },
  created: function created() {
    this.registerProductModule();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    resetNewTimeLineInitials: "resetNewTimeLineInitials"
  })), {}, {
    handleLineItemPricing: function handleLineItemPricing(index) {
      this.invoiceDraftPreviewDetails.lineItems.map(function (lineItem, idx) {
        if (idx == index) {
          lineItem.amount = 0;
          lineItem.amount = parseFloat(lineItem.rate) * (typeof lineItem.quantity == "string" ? 0 : parseFloat(lineItem.quantity));
          lineItem.taxAmounts[0].amount = parseFloat(lineItem.amount) * (lineItem.taxAmounts[0].salesTaxRateInPercentage / 100);
          lineItem.amount = parseFloat(lineItem.amount);
        }
      });
      this.getSubtotal();
      this.getVatTotal();
      this.getTotalAmount();
    },
    getSubtotal: function getSubtotal() {
      var subTotal = 0;
      this.invoiceDraftPreviewDetails.lineItems.map(function (lineItem) {
        subTotal += parseFloat(lineItem.amount);
      });
      this.invoiceDraftPreviewDetails.subTotal = subTotal;
      return this.invoiceDraftPreviewDetails.subTotal.toFixed(ConstantValues.defaultAmountFixedDigitCount);
    },
    getVatTotal: function getVatTotal() {
      var vatTotal = 0;
      this.invoiceDraftPreviewDetails.lineItems.map(function (lineItem) {
        vatTotal += parseFloat(lineItem.taxAmounts[0].amount);
      });
      return vatTotal.toFixed(ConstantValues.defaultAmountFixedDigitCount);
    },
    getLineItemTotal: function getLineItemTotal(lineItem) {
      return parseFloat(lineItem.amount) + parseFloat(lineItem.taxAmounts[0].amount);
    },
    getTotalAmount: function getTotalAmount() {
      return this.getSubtotal() + this.getVatTotal();
    },
    negativeKeydown: function negativeKeydown(e) {
      if (!/[^-+]/.test(e.key)) {
        e.preventDefault();
      }
    },
    routeToJobDetails: function routeToJobDetails(jobIndex) {
      var jobId = this.invoiceLineItemsJobIds[jobIndex];
      if (jobId) {
        window.open("".concat(routeTeamJobsDetails).concat(jobId), "_blank").focus();
      }
    },
    getAddressString: function getAddressString(address) {
      var line1 = address.addressLine1 ? address.addressLine1 : "";
      var line2 = " " + (address.addressLine2 ? address.addressLine2 : "");
      var city = " " + (address.city ? address.city : "");
      var state = " " + (address.state ? address.state : "");
      var country = " " + (address.country ? address.country : "");
      var postalCode = " " + (address.postalCode ? address.postalCode : "");
      var addressArray = [line1, line2, city, state, country, postalCode];
      return addressArray.filter(function (a) {
        return a && a != ' ';
      }).join();
    },
    viewReport: function viewReport(reportUrl) {
      window.open(reportUrl, "_blank");
    },
    removeAttachment: function removeAttachment(rIndx) {
      this.invoiceJobReportUrls.splice(rIndx, 1);
    },
    handleQuantityChange: function handleQuantityChange(event, index) {
      this.handleLineItemPricing(index);
      this.negativeKeydown(event);
    },
    getJobNumber: function getJobNumber(jobNumberIndex) {
      var _a, _b;
      if (((_b = (_a = this.invoiceDraftPreviewDetails) === null || _a === void 0 ? void 0 : _a.references) === null || _b === void 0 ? void 0 : _b.length) > 0) {
        return this.invoiceDraftPreviewDetails.references[jobNumberIndex].split(' ')[0];
      } else {
        return "Report ".concat(jobNumberIndex);
      }
    },
    getDateFormatted: function getDateFormatted(date) {
      return _getDateFormatted(date);
    },
    getRateNameAndValue: function getRateNameAndValue(rateName, rateInPercentage, currentTaxValue) {
      if (rateName) return "".concat(rateName, " - ").concat(rateInPercentage);
      return "Default - ".concat(currentTaxValue);
    },
    routeToAccount: function routeToAccount() {
      var _this = this;
      router.push(routeAccount);
      setTimeout(function () {
        _this.accountTab = AccountTabType.Business;
      }, ConstantValues.defaultTabSelectionTimeout);
      setTimeout(function () {
        document.getElementById('account-business').scrollBy(0, 700);
      }, ConstantValues.defaultScrollToPositionTimeout);
    },
    getSalesTaxRegistrationNumber: function getSalesTaxRegistrationNumber() {
      var _a, _b, _c;
      return (_c = (_b = (_a = this.invoiceDraftPreviewDetails) === null || _a === void 0 ? void 0 : _a.taxAccounts[0]) === null || _b === void 0 ? void 0 : _b.registrationNumber) !== null && _c !== void 0 ? _c : '';
    },
    removeInternalLineItem: function removeInternalLineItem(lineitemIndex) {
      this.invoiceDraftPreviewDetails.lineItems = this.invoiceDraftPreviewDetails.lineItems.filter(function (item, index) {
        return index != lineitemIndex;
      });
    },
    showAddLineItemViewInternal: function showAddLineItemViewInternal(index) {
      this.isProductInList = false;
      this.registerProductModule();
      this.resetNewTimeLineInitials();
      this.showAddLineitemsForInternalInvoice = this.showAddLineitemsForInternalInvoice.map(function (lineView) {
        return lineView = false;
      });
      this.showAddLineitemsForInternalInvoice[index] = true;
    },
    reduceTextLength: function reduceTextLength(textToReduce, maxTextLength) {
      return textToReduce ? reduceText(textToReduce, maxTextLength) : '';
    },
    registerProductModule: function registerProductModule() {
      this.productDropdownModule = this.$stringConstants('invoicesProductListFilter');
      if (!this.$store.hasModule(this.productDropdownModule)) {
        this.$store.registerModule(this.productDropdownModule, PaginationModule);
      }
    },
    applyProductFilterSearchTimerFocus: function applyProductFilterSearchTimerFocus() {
      if (!this.newProductForInternalInvoice) {
        this.applyProductFilterSearchTimer();
      }
    },
    applyProductFilterSearchTimer: function applyProductFilterSearchTimer() {
      var _this2 = this;
      if (this.searchTimerProductFilter) {
        clearTimeout(this.searchTimerProductFilter);
        this.searchTimerProductFilter = null;
      }
      this.searchTimerProductFilter = setTimeout(function () {
        _this2.searchProductByText();
      }, ConstantValues.defaultDropdownSearchTimeout);
    },
    searchProductByText: function searchProductByText() {
      this.productListForInternalInvoice = [];
      this.appendMoreProducts();
    },
    appendMoreProducts: function appendMoreProducts() {
      var _this3 = this;
      var paginationModule = this.$stringConstants('invoicesProductListFilter');
      this.$store.dispatch('setProductsFilterForInternalInvoice');
      this.$store.dispatch('getProductsListFilteredForInternalInvoice').then(function (response) {
        return _this3.$store.dispatch("".concat(paginationModule, "/updateStates"), response);
      });
    },
    cancelNewLineItemAdd: function cancelNewLineItemAdd() {
      this.showAddLineitemsForInternalInvoice = this.showAddLineitemsForInternalInvoice.map(function (lineItemView) {
        return lineItemView = false;
      });
    },
    setProductInfoInLineItem: function setProductInfoInLineItem() {
      this.isProductInList = false;
      if (this.newProductForInternalInvoice != null) {
        this.newLineItemsInitialsForInternal.title = this.newProductForInternalInvoice.title;
        this.newLineItemsInitialsForInternal.rate = this.newProductForInternalInvoice.cost;
        this.newLineItemsInitialsForInternal.unit = this.newProductForInternalInvoice.unit;
      } else {
        this.resetNewTimeLineInitials();
      }
    },
    addNewLineItemInternal: function addNewLineItemInternal(distinctJobIndex) {
      var _this4 = this;
      var jobReference = this.invoiceLineItemJobNumbersDistinct[distinctJobIndex];
      var lastIndexToAppendTo = 0;
      this.invoiceDraftPreviewDetails.lineItems.forEach(function (item, index) {
        if (item.reference == jobReference) {
          lastIndexToAppendTo = index;
          if (item.title == _this4.newLineItemsInitialsForInternal.title) {
            _this4.isProductInList = true;
          }
        }
      });
      if (!this.isProductInList) {
        this.newLineItemsInitialsForInternal.reference = jobReference;
        this.newLineItemsInitialsForInternal.amount = this.newLineItemsInitialsForInternal.rate * this.newLineItemsInitialsForInternal.quantity;
        this.newLineItemsInitialsForInternal.taxAmounts[0].salesTaxName = this.taxName;
        this.invoiceDraftPreviewDetails.lineItems.splice(lastIndexToAppendTo + 1, 0, this.newLineItemsInitialsForInternal);
        var anyLineItemWithSameJob = this.invoiceDraftPreviewDetails.lineItems.find(function (lineItem) {
          return lineItem.reference == jobReference;
        });
        this.newLineItemsInitialsForInternal.date = anyLineItemWithSameJob && anyLineItemWithSameJob.date ? anyLineItemWithSameJob.date : '';
        this.resetNewTimeLineInitials();
        this.cancelNewLineItemAdd();
      }
    }
  })
};