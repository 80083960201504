import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "pt-10", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants("companyDetailsHeading1"),
                  textValue2: _vm.$stringConstants(
                    "customerBusinessInfoSubTitle"
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "7" } },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0 pr-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          _vm._s(_vm.$stringConstants("companyName")) + " *"
                        ),
                      ]),
                      _c(VTextField, {
                        class: _vm.formFieldPb,
                        attrs: {
                          placeholder: _vm.$stringConstants(
                            "businessTitlePlaceholder"
                          ),
                          required: "",
                          outlined: "",
                          dense: "",
                        },
                        on: {
                          keydown: function ($event) {
                            return _vm.updatePrefix($event)
                          },
                        },
                        model: {
                          value: _vm.contactProfile.businessProfile.title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactProfile.businessProfile,
                              "title",
                              $$v
                            )
                          },
                          expression: "contactProfile.businessProfile.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c(
                        "div",
                        { staticClass: "field-input-label position-relative" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("companyJobCode")) +
                                " *"
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: { position: "absolute", right: "0" },
                            },
                            [
                              _c(
                                VTooltip,
                                {
                                  attrs: {
                                    bottom: "",
                                    color: "#000",
                                    "max-width": "300px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            VIcon,
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "cursor-pointer",
                                                  staticStyle: {
                                                    color: "#000 !important",
                                                  },
                                                  attrs: { small: "" },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-information")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("span", { staticClass: "tol-cont" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants("jobPrefixText")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "biz-short-name" },
                        [
                          _c(VTextField, {
                            class: _vm.formFieldPb,
                            attrs: {
                              outlined: "",
                              placeholder: _vm.$stringConstants(
                                "businessPrefixPlaceholder"
                              ),
                              dense: "",
                              disabled: !_vm.isEdiablePrefix,
                              maxlength: 3,
                              required: "",
                            },
                            model: {
                              value: _vm.businessProfilePrefix,
                              callback: function ($$v) {
                                _vm.businessProfilePrefix = $$v
                              },
                              expression: "businessProfilePrefix",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0 pr-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(_vm._s(_vm.$stringConstants("vatNumber"))),
                      ]),
                      _c(VTextField, {
                        class: _vm.formFieldPb,
                        attrs: {
                          placeholder: _vm.$stringConstants(
                            "vatNumberPlaceholder"
                          ),
                          required: "",
                          outlined: "",
                          dense: "",
                        },
                        on: {
                          keydown: function ($event) {
                            return _vm.checkKeyDownAlphaNumeric($event)
                          },
                        },
                        model: {
                          value: _vm.contactProfile.businessProfile.vatNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactProfile.businessProfile,
                              "vatNumber",
                              $$v
                            )
                          },
                          expression:
                            "contactProfile.businessProfile.vatNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$stringConstants("companyRegistrationNumber")
                          )
                        ),
                      ]),
                      _c(VTextField, {
                        class: _vm.formFieldPb,
                        attrs: {
                          placeholder: _vm.$stringConstants(
                            "registrationNumberPlaceholder"
                          ),
                          required: "",
                          outlined: "",
                          dense: "",
                        },
                        on: {
                          keydown: function ($event) {
                            return _vm.checkKeyDownAlphaNumeric($event)
                          },
                        },
                        model: {
                          value:
                            _vm.contactProfile.businessProfile
                              .registrationNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactProfile.businessProfile,
                              "registrationNumber",
                              $$v
                            )
                          },
                          expression:
                            "contactProfile.businessProfile.registrationNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }