var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "overlay" } }),
      _c("MachinesListView"),
      _vm.shouldComponentDisplay(_vm.componentsPermissions["vehicleUpdate"]) ||
      _vm.shouldComponentDisplay(_vm.componentsPermissions["vehicleCreate"])
        ? _c(
            "div",
            {
              staticClass:
                "sidebar-overlay sidebar-overlay-sm position-relative editMachineFormOverlay",
              attrs: { id: "editMachineFormOverlay" },
            },
            [_c("MachinesEditForm")],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay position-relative importMachinesFormOverlay",
          attrs: { id: "importMachinesFormOverlay" },
        },
        [_c("MachinesImportForm")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }