var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hide-scroll",
      staticStyle: { height: "calc(100vh - 70px)" },
    },
    [
      _c(
        "div",
        { staticClass: "px-10 py-8 jobs-max-width" },
        [
          _c("div", { attrs: { id: "overlay" } }),
          _c("TeamJobsComponents"),
          _c("div", { staticStyle: { "margin-bottom": "100px" } }),
        ],
        1
      ),
      _c("TeamJobsAddSaveButtons"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }