import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import accountComputedMixin from '@/components/account/account-computed';
import { notify } from '@/utils/helpers';
import { isValidLatitude, isValidLongitude } from '@/utils/helpers/gmaps-helpers';
export default {
  mixins: [accountComputedMixin],
  methods: {
    isStringNumeric: function isStringNumeric(stringValue) {
      if (typeof stringValue != "string") return false;
      return !isNaN(stringValue) && !isNaN(parseFloat(stringValue));
    },
    intiatePlacesSearch: function intiatePlacesSearch(latitude, longitude) {
      this.$store.dispatch('navigateToAccountCoordinates', [latitude, longitude]);
    },
    requestPlacesSearchAPI: function requestPlacesSearchAPI() {
      this.$store.dispatch('requestGooglePlacesSearchAccount');
    },
    searchPlaces: function searchPlaces() {
      if (this.accountMapSearchValue != '' && this.accountMapSearchValue != null) {
        if (this.accountMapSearchValue && this.accountMapSearchValue.includes(',')) {
          var searchInputList = this.accountMapSearchValue.split(',');
          var searchInputHasCoordinates = searchInputList.length == 2 && this.isStringNumeric(searchInputList[0]) && this.isStringNumeric(searchInputList[1]);
          if (searchInputHasCoordinates) {
            if (isValidLatitude(searchInputList[0]) && isValidLongitude(searchInputList[1])) {
              this.intiatePlacesSearch(searchInputList[0], searchInputList[1]);
            } else {
              notify(this.$stringConstants('accountInvalidCoordinates'), 'fail');
            }
          } else {
            this.requestPlacesSearchAPI();
          }
        } else {
          this.requestPlacesSearchAPI();
        }
      }
    }
  }
};