import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getConvertedAreaUnit } from "@/utils/helpers";
import fieldsComputedMixin from "@/components/fields/fields-computed";
import { mapMutations } from "vuex";
export default {
  props: ["fieldInfo"],
  mixins: [fieldsComputedMixin],
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFieldDetailsState: "resetFieldDetails"
  })), {}, {
    getAreaFormatted: function getAreaFormatted(fieldAreaInSquareMeters) {
      if (fieldAreaInSquareMeters != undefined && fieldAreaInSquareMeters != null) {
        return getConvertedAreaUnit(fieldAreaInSquareMeters);
      }
      return "";
    },
    getStyledObject: function getStyledObject(fieldColor) {
      return {
        backgroundColor: fieldColor
      };
    },
    getFieldsOwnerListInternal: function getFieldsOwnerListInternal() {
      this.fieldOwnerList = [];
      this.fieldOwnerFilterList = [];
      this.$store.commit("setDefaultOwnerList", this.loginUserBusinessName);
      this.$store.commit("setDefaultOwnerFilterList", this.loginUserBusinessName);
      this.$store.dispatch("getFieldsOwnerListInternal");
    }
  })
};