var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "rd-label" }, [_vm._v(_vm._s(_vm.labelValue))]),
    _c("div", { staticClass: "pt-3 rd-details" }, [
      _vm._v(_vm._s(_vm.detailsValue)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }