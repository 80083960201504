import requestsCancellationMixin from '@/mixins/requests-cancellation';
import superAdminAccessMixin from '@/mixins/super-admin-access-mixin';
import SuOverviewCurrentStatistics from './su-overview-current-statistics.vue';
import SuOverviewOverallStatistics from './su-overview-overall-statistics.vue';
import SuOverviewInvoiceDistribution from './su-overview-invoice-distribution.vue';
import SuOverviewCountryDistribution from './su-overview-country-distribution.vue';
export default {
  name: "SuperAdminDashboard",
  components: {
    SuOverviewCurrentStatistics: SuOverviewCurrentStatistics,
    SuOverviewOverallStatistics: SuOverviewOverallStatistics,
    SuOverviewInvoiceDistribution: SuOverviewInvoiceDistribution,
    SuOverviewCountryDistribution: SuOverviewCountryDistribution
  },
  mixins: [requestsCancellationMixin, superAdminAccessMixin],
  beforeCreate: function beforeCreate() {
    this.$store.state.dashboardTitle = this.$stringConstants('overviewTitle');
  }
};