import _defineProperty from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _ManagerFeatureLabels, _TeamLeadFeatureLabel, _OperatorFeatureLabel;
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { FeatureType } from "./featureType";
export var UserRoleType;
(function (UserRoleType) {
  UserRoleType[UserRoleType["Contractor"] = 0] = "Contractor";
  UserRoleType[UserRoleType["Farmer"] = 1] = "Farmer";
  UserRoleType[UserRoleType["Manager"] = 2] = "Manager";
  UserRoleType[UserRoleType["TeamLead"] = 3] = "TeamLead";
  UserRoleType[UserRoleType["Operator"] = 4] = "Operator";
  UserRoleType[UserRoleType["SuperAdmin"] = 5] = "SuperAdmin";
})(UserRoleType || (UserRoleType = {}));
export var UserRoleTypeLabel = new Map([[UserRoleType.Contractor, 'Contractor'], [UserRoleType.Farmer, 'Farmer'], [UserRoleType.Manager, 'Manager'], [UserRoleType.TeamLead, 'Team Lead'], [UserRoleType.Operator, 'Operator'], [UserRoleType.SuperAdmin, 'Super Admin']]);
export var ManagerFeatureLabels = (_ManagerFeatureLabels = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ManagerFeatureLabels, FeatureType.Operations, 'Full'), FeatureType.Fields, 'Full'), FeatureType.Vehicles, 'Full'), FeatureType.Implements, 'Full'), FeatureType.Products, 'Full'), FeatureType.CustomersContractors, 'Full'), FeatureType.TeamMembers, 'Full'), FeatureType.AllJobs, 'Full'), FeatureType.TeamLeadOnJobs, 'Full'), FeatureType.OperatorOnJobs, 'Full'), _defineProperty(_ManagerFeatureLabels, FeatureType.ApproveJobsForInvoicing, 'Full'));
export var TeamLeadFeatureLabels = (_TeamLeadFeatureLabel = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_TeamLeadFeatureLabel, FeatureType.Operations, 'View only*'), FeatureType.Fields, 'Full'), FeatureType.Vehicles, 'Full'), FeatureType.Implements, 'Full'), FeatureType.Products, 'Full'), FeatureType.CustomersContractors, 'View only'), FeatureType.TeamMembers, 'View only'), FeatureType.AllJobs, 'View only'), FeatureType.TeamLeadOnJobs, 'Full'), FeatureType.OperatorOnJobs, 'Restrictive**'), _defineProperty(_TeamLeadFeatureLabel, FeatureType.ApproveJobsForInvoicing, 'No'));
export var OperatorFeatureLabels = (_OperatorFeatureLabel = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_OperatorFeatureLabel, FeatureType.Operations, 'View only*'), FeatureType.Fields, 'Full'), FeatureType.Vehicles, 'Full'), FeatureType.Implements, 'Full'), FeatureType.Products, 'Full'), FeatureType.CustomersContractors, 'View only'), FeatureType.TeamMembers, 'View only'), FeatureType.AllJobs, 'No'), FeatureType.TeamLeadOnJobs, 'Full'), FeatureType.OperatorOnJobs, 'Restrictive**'), _defineProperty(_OperatorFeatureLabel, FeatureType.ApproveJobsForInvoicing, 'No'));