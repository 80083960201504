import "core-js/modules/es.array.push.js";
import ConstantValues from "@/plugins/constantValues";
var initialState = function initialState() {
  return {
    offset: ConstantValues.defaultPageOffsetSize,
    limit: ConstantValues.defaultPageLimitSize,
    responseRowCount: ConstantValues.defaultPageListCount,
    totalResponseRowCount: ConstantValues.defaultPageListCount,
    loading: false,
    totalRowCount: ConstantValues.defaultPageTotalRowCount,
    noData: false,
    filtersApplied: false,
    error: false,
    errorMessage: '',
    filterOptions: [],
    queryString: null,
    searchText: ''
  };
};
export var state = initialState();
export var getters = {
  offset: function offset(state) {
    return state.offset;
  },
  limit: function limit(state) {
    return state.limit;
  },
  responseRowCount: function responseRowCount(state) {
    return state.responseRowCount;
  },
  loading: function loading(state) {
    return state.loading;
  },
  totalRowCount: function totalRowCount(state) {
    return state.totalRowCount;
  },
  noData: function noData(state) {
    return state.noData;
  },
  filtersApplied: function filtersApplied(state) {
    return state.filtersApplied;
  },
  error: function error(state) {
    return state.error;
  },
  searchText: function searchText(state) {
    return state.searchText;
  },
  showLoadMoreButtonGetter: function showLoadMoreButtonGetter(state) {
    return state.totalResponseRowCount < state.totalRowCount && state.responseRowCount == state.limit;
  }
};
export var mutations = {
  setOffset: function setOffset(state, data) {
    state.offset = data;
  },
  setNoDataStatus: function setNoDataStatus(state, data) {
    state.noData = (data === null || data === void 0 ? void 0 : data.length) == 0;
  },
  resetPaging: function resetPaging(state) {
    Object.assign(state, initialState());
  },
  setResponseRowCount: function setResponseRowCount(state, data) {
    state.responseRowCount = data;
  },
  setTotalResponseRowCount: function setTotalResponseRowCount(state, data) {
    state.totalResponseRowCount = state.totalResponseRowCount + data;
  },
  setTotalRowCount: function setTotalRowCount(state, data) {
    state.totalRowCount = data;
  },
  fetchingData: function fetchingData(state) {
    state.loading = true;
  },
  doneFetchingData: function doneFetchingData(state) {
    state.loading = false;
  },
  resetError: function resetError(state, data) {
    state.error = data;
  },
  apiCallError: function apiCallError(state, _ref) {
    var error = _ref.error,
      response = _ref.response;
    if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
      state.error = true;
      state.errorMessage = error;
    }
  },
  setFilterOptions: function setFilterOptions(state, data) {
    state.filterOptions.push(data);
  },
  setClearFilterOptions: function setClearFilterOptions(state) {
    state.filterOptions = [];
  },
  setQueryString: function setQueryString(state, data) {
    if (!data) return;
    state.queryString = data;
  }
};