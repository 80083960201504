import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export default {
  methods: {
    scriptExistsAlready: function scriptExistsAlready(scriptContent) {
      var containsScript = false;
      var scriptElements = document.getElementsByTagName('script');
      if (scriptElements && scriptElements.length) {
        for (var i = 0; i < scriptElements.length; i++) {
          if (scriptElements[i].src.includes(scriptContent)) {
            containsScript = true;
          }
        }
      }
      return containsScript;
    },
    loadScripts: function loadScripts() {
      if (process.env.NODE_ENV == 'production' && !this.scriptExistsAlready('boat3deer.com')) {
        var boat3Script = document.createElement('script');
        boat3Script.setAttribute('src', 'https://secure.boat3deer.com/js/259765.js');
        document.head.appendChild(boat3Script);
        var noScript = document.createElement('noscript');
        var imgElement = document.createElement('img');
        imgElement.setAttribute('alt', '');
        imgElement.setAttribute('src', 'https://secure.boat3deer.com/259765.png');
        noScript.appendChild(imgElement);
      }
    }
  }
};