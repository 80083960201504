export default {
  name: 'InvoicesMapMixin',
  data: function data() {
    return {
      mapLoader: false
    };
  },
  computed: {
    invoiceDraftPreviewDetails: {
      get: function get() {
        return this.$store.getters.invoiceDraftPreviewDetails;
      },
      set: function set(newVal) {
        this.$store.state.invoicesModule.invoiceDraftPreviewDetails = newVal;
      }
    },
    selectedJobsForInvoice: {
      get: function get() {
        return this.$store.getters.selectedJobsForInvoice;
      },
      set: function set(newVal) {
        this.$store.state.invoicesModule.selectedJobsForInvoice = newVal;
      }
    }
  }
};