import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
export var state = {
  jobsInProgressFields: [],
  fieldShapes: [],
  jobsFieldsMapsLabels: [],
  jobsFieldsMap: null,
  fieldInfowindow: null,
  loadingJobsFields: false
};
export var getters = {
  jobsInProgressFields: function jobsInProgressFields(state) {
    return state.jobsInProgressFields;
  },
  fieldShapes: function fieldShapes(state) {
    return state.fieldShapes;
  },
  jobsFieldsMapsLabels: function jobsFieldsMapsLabels(state) {
    return state.jobsFieldsMapsLabels;
  },
  jobsFieldsMap: function jobsFieldsMap(state) {
    return state.jobsFieldsMap;
  },
  fieldInfowindow: function fieldInfowindow(state) {
    return state.fieldInfowindow;
  },
  loadingJobsFields: function loadingJobsFields(state) {
    return state.loadingJobsFields;
  }
};
export var mutations = {
  setInProgressJobsFields: function setInProgressJobsFields(state, data) {
    data.map(function (job) {
      if (job.fields.length != 0) {
        var jobField = {
          jobNumber: job.number ? job.number : '',
          customer: job.owner.businessProfile.title ? job.owner.businessProfile.title : '',
          operation: job.operation.title ? job.operation.title : '',
          fields: job.fields,
          ownerId: job.owner.id,
          jobId: job.id
        };
        state.jobsInProgressFields.push(jobField);
      }
    });
  }
};