import { render, staticRenderFns } from "./team-members-edit-form.vue?vue&type=template&id=4149e049"
import script from "./team-members-edit-form.vue?vue&type=script&lang=ts"
export * from "./team-members-edit-form.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/src/web/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4149e049')) {
      api.createRecord('4149e049', component.options)
    } else {
      api.reload('4149e049', component.options)
    }
    module.hot.accept("./team-members-edit-form.vue?vue&type=template&id=4149e049", function () {
      api.rerender('4149e049', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/team-members/team-members-edit-form.vue"
export default component.exports