import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'teamJobsModule': ['teamJobUsersList', 'teamJobUserSearchText', 'teamJobUser', 'teamJobOwnerType', 'teamJobContractorId', 'teamJobUsersListSize', 'teamJobUsersListLoader']
};
export default {
  name: 'TeamJobsCustomersComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    isFarmerLoggedIn: function isFarmerLoggedIn() {
      return getLoggedInUserRole().isUserOwnerFarmerLoggedIn;
    }
  })
};